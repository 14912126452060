import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RequestCache, RequestCacheWithMap } from '@cation/core/services/request-cache/request-cache.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppsyncService } from '@cation/core/services/appsync/appsync.service';
import { ComponentsModule } from '@cation/core/modules/components.module';
import { MaterialModule } from '@cation/core/modules/material.module';
import { SharedModule } from '@cation/core/modules/shared.module';
import { ConversationService } from '@cation/core/services/conversation/conversation.service';
import { httpInterceptorProviders } from '../core/interceptors/http-interceptors';
import { CannedResponseComponent } from './canned-response/canned-response.component';
import { ChatAiViewComponent } from './chat-ai-view/chat-ai-view.component';
import { ChatAppRoutingModule } from './chat-app-routing.module';
import { ChatConvoListComponent } from './chat-convo-list/chat-convo-list.component';
import { ChatInputComponent } from './chat-input/chat-input.component';
import { ChatMessageViewComponent } from './chat-message-view/chat-message-view.component';
import { ChatComponent } from './chat/chat.component';
import { CmsActionsComponent } from './cms-actions/cms-actions.component';
import { CmsListTableComponent } from './cms-list-table/cms-list-table.component';
import { EscalateConversationDialogComponent } from './escalate-conversation-dialog/escalate-conversation-dialog.component';
import { BottomSheetActionFindSimilarCaseComponent } from './chat-message-view/bottom-sheet-action-find-similar-case.component';
import { BottomSheetFindSimilarCaseFilterComponent } from './chat-ai-view/bottom-sheet-find-similar-case-filter.component';
import { ProfileComponent } from './profile/profile.component';
import { CapabilitiesListComponent } from './capabilities-list/capabilities-list.component';
import { GamificationEventsListComponent } from './gamification-events-list/gamification-events-list.component';
import { MasterLeagueComponent } from './master-league/master-league.component';
import { LeagueStatisticsComponent } from './league-statistics/league-statistics.component';
import { ChallengeDialogComponent } from './challenge-dialog/challenge-dialog.component';
import { ChallengesListComponent } from './challenges-list/challenges-list.component';
import { ChallengeWagerDialogComponent } from './challenge-wager-dialog/challenge-wager-dialog.component';
import { ChallengeWagersListComponent } from './challenge-wagers-list/challenge-wagers-list.component';
import { ScoreHistoryListComponent } from './score-history-list/score-history-list.component';
import { MyStatisticsComponent } from './my-statistics/my-statistics.component';
import { FeedbackCommentsListComponent } from './feedback-comments-list/feedback-comments-list.component';
import { InviteAgentDialogComponent } from './invite-agent-dialog/invite-agent-dialog.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TeamActivityComponent } from './team-activity/team-activity.component';
import { ConversationStatisticsWidgetComponent } from './conversation-statistics-widget/conversation-statistics-widget.component';
import { StatisticsTotalButtonComponent } from './conversation-statistics-widget/statistics-total-button/statistics-total-button.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ChallengesTableComponent } from './challenges-table/challenges-table.component';
import { WidgetHeaderComponent } from './widget-header/widget-header.component';
import { ConversationsStarsWidgetComponent } from './conversation-statistics-widget/conversations-stars-widget/conversations-stars-widget.component';
import { NumberOfConversationsWidgetComponent } from './conversation-statistics-widget/number-of-conversations-widget/number-of-conversations-widget.component';
import { ConversationsPerHourWidgetComponent } from './conversation-statistics-widget/conversations-per-hour-widget/conversations-per-hour-widget.component';
import { VideoChatComponent } from './video-chat/video-chat.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    ComponentsModule,
    BrowserAnimationsModule,
    ChatAppRoutingModule,
    NgbModule,
    HttpClientModule
  ],
  declarations: [
    ChatComponent,
    ChatInputComponent,
    ChatConvoListComponent,
    ChatMessageViewComponent,
    ChatAiViewComponent,
    CmsActionsComponent,
    CmsListTableComponent,
    EscalateConversationDialogComponent,
    CannedResponseComponent,
    BottomSheetActionFindSimilarCaseComponent,
    BottomSheetFindSimilarCaseFilterComponent,
    ProfileComponent,
    CapabilitiesListComponent,
    GamificationEventsListComponent,
    MasterLeagueComponent,
    LeagueStatisticsComponent,
    ChallengesListComponent,
    ChallengeWagersListComponent,
    ChallengeDialogComponent,
    ChallengeWagerDialogComponent,
    ScoreHistoryListComponent,
    MyStatisticsComponent,
    FeedbackCommentsListComponent,
    InviteAgentDialogComponent,
    DashboardComponent,
    TeamActivityComponent,
    ConversationStatisticsWidgetComponent,
    StatisticsTotalButtonComponent,
    HomePageComponent,
    ChallengesTableComponent,
    WidgetHeaderComponent,
    ConversationsStarsWidgetComponent,
    NumberOfConversationsWidgetComponent,
    ConversationsPerHourWidgetComponent,
    VideoChatComponent
  ]
  // entryComponents: [
  //   InviteAgentDialogComponent,
  //   EscalateConversationDialogComponent,
  //   BottomSheetActionFindSimilarCaseComponent,
  //   BottomSheetFindSimilarCaseFilterComponent,
  //   ChallengeDialogComponent,
  //   ChallengeWagerDialogComponent
  // ]
})
export class ChatAppModule {
  static forRoot(): ModuleWithProviders<ChatAppModule> {
    return {
      ngModule: ChatAppModule,
      providers: [
        AppsyncService,
        ConversationService,
        { provide: RequestCache, useClass: RequestCacheWithMap },
        httpInterceptorProviders
      ]
    };
  }
}
