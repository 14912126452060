<section>
  <div class="header">
    <div class="ctn-title">
      <h1 class="title">{{ 'CHALLENGES.WAGERS_TITLE' | translate }}</h1>
    </div>

    <div class="widget-select">
      <mat-form-field>
        <mat-select [(ngModel)]="selectedTab" name="tabFilter" (selectionChange)="onTabChange($event)">
          <ng-container *ngFor="let tab of tabs">
            <mat-option *ngIf="tab === 'AVAILABLE' ? showChallenges : true" [value]="tab">
              {{ 'CHALLENGES.' + tab + '_TAB_TITLE' | translate }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="ctn-content" #challengeWagerList>
    <div *ngIf="isLoadingChallenges || wagers[selectedTab].isLoading" class="loader" [@fadeInOut]>
      <mat-spinner mode="indeterminate"></mat-spinner>
    </div>

    <ng-container *ngIf="['PROPOSALS', 'ACTIVE', 'HISTORY'].includes(selectedTab)">
      <ng-container *ngTemplateOutlet="challengeWagersTabTemplate; context: { tab: selectedTab }"> </ng-container>
    </ng-container>

    <ng-container *ngIf="selectedTab === 'AVAILABLE'">
      <app-challenges-table
        [selectedTab]="selectedTab"
        [challenges]="challenges"
        [isLoading]="isLoadingChallenges"
        [isLoadMore]="challengesLastKey"
        (onMakeWager)="toMakeWager($event)"
        (onLoadMore)="getGamificationChallenges(true)"
      ></app-challenges-table>
    </ng-container>
  </div>
</section>

<!-- Challenge Wagers tab template -->
<ng-template #challengeWagersTabTemplate let-tab="tab">
  <table>
    <thead>
      <tr>
        <th>
          {{ 'CHALLENGES.STATUS_TITLE' | translate }}
        </th>
        <th>
          {{ 'CHALLENGES.OPPONENTS_TITLE' | translate }}
        </th>
        <th>
          {{ 'CHALLENGES.BET_INITIATOR' | translate }}
        </th>
        <th>
          {{ 'CHALLENGES.RATE_TITLE' | translate }}
        </th>
        <th>
          {{ 'CHALLENGES.BET_OPPONENT' | translate }}
        </th>
        <th *ngIf="'PROPOSALS' === tab">
          {{ 'CHALLENGES.ACTIONS_TITLE' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let wager of wagers[tab].items; let i = index; trackBy: trackItem"
        [@listFadeInOut]="wagers[tab].items.length"
        [class.challenge-wager__item__winner]="wager.winnerId && wager.winnerId === authHelper.userProfile.cognitoId"
        [class.challenge-wager__item__looser]="wager.winnerId && wager.winnerId !== authHelper.userProfile.cognitoId"
      >
        <td>
          <span class="challenge-wager__item__status" [matTooltip]="wager.status | lowercase">
            <mat-icon [ngClass]="mapChallengeStatusToIcon[wager.status]">{{
              mapChallengeStatusToIcon[wager.status]
            }}</mat-icon>
          </span>
        </td>

        <td>
          <div class="d-flex align-items-center">
            <div class="ctn-icons ctn-2-icons">
              <div>
                <app-s3-image
                  [isRounded]="true"
                  [image]="wager.initiator?.avatarUrl"
                  [avatarName]="wager.initiator?.username"
                ></app-s3-image>
              </div>
              <div>
                <app-s3-image
                  [isRounded]="true"
                  [image]="wager.opponent?.avatarUrl"
                  [avatarName]="wager.opponent?.username"
                ></app-s3-image>
              </div>
            </div>

            <div class="ctn-users-names">
              <span class="d-inline-block">{{ wager.initiator?.username }}</span>
              <span class="d-inline-block">{{ wager.opponent?.username }}</span>
            </div>
          </div>
        </td>

        <td>
          <div
            class="challenge-wager__item__vote"
            [class.challenge-wager__item__vote__winner]="wager.initiatorId === authHelper.userProfile.cognitoId"
            [class.challenge-wager__item__vote__looser]="wager.opponentId === authHelper.userProfile.cognitoId"
          >
            <app-user-template
              class="icon-30"
              [user]="wager.initiatorVote"
              tooltipField="userData.status"
            ></app-user-template>
          </div>
        </td>

        <td>
          <span>
            <span *ngIf="wager.winnerId">{{ wager.winnerId === authHelper.userProfile.cognitoId ? '+' : '-' }}</span>
            {{ wager.cost }}
          </span>
        </td>

        <td>
          <div
            class="challenge-wager__item__vote"
            [class.challenge-wager__item__vote__winner]="wager.opponentId === authHelper.userProfile.cognitoId"
            [class.challenge-wager__item__vote__looser]="wager.initiatorId === authHelper.userProfile.cognitoId"
          >
            <app-user-template
              class="icon-30"
              [user]="wager.opponentVote"
              tooltipField="userData.status"
            ></app-user-template>
          </div>
        </td>

        <td>
          <div
            class="d-flex flex-row"
            *ngIf="
              'PROPOSALS' === tab &&
              wager.status === 'PENDING' &&
              authHelper.userProfile.cognitoId === wager.opponent.cognitoId
            "
          >
            <button
              mat-icon-button
              [tabIndex]="-1"
              color="primary"
              (click)="changeChallengeWagerStatus(tab, wager.id, wager.challenge.id, 'ACCEPTED')"
              [matTooltip]="'BUTTON.ACCEPT' | translate"
            >
              <mat-icon>check_circle_outline</mat-icon>
            </button>
            <button
              mat-icon-button
              [tabIndex]="-1"
              color="warn"
              (click)="changeChallengeWagerStatus(tab, wager.id, wager.challenge.id, 'REJECTED')"
              [matTooltip]="'BUTTON.REJECT' | translate"
            >
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <p *ngIf="!wagers[tab].items.length" class="empty-message">{{ 'CHALLENGES.NO_WAGERS' | translate }}</p>

  <div class="loader-more" #loading>
    <span *ngIf="wagers[tab].items && wagers[tab].items.length && wagers[tab].isLoading"
      >{{ 'GENERAL.LOADING' | translate }}...</span
    >
  </div>
</ng-template>
