import User from '@cation/core/types/user';
import Conversation from '@cation/core/types/conversation';
import { IChallengeItem } from '@cation/core/types/api/gamification-challenge';
import { IChallengeWagerItem } from '@cation/core/types/api/gamification-challenge-wager';

export enum USER_EVENT_TYPE {
  LEVEL_UP = 'USER_EVENT_LEVEL_UP',
  ADD_EXP_POINTS = 'USER_EVENT_EXP_POINTS_ADD',
  ADD_ACHIEVEMENT = 'USER_EVENT_ACHIEVEMENT_ADD',
  ADD_SCORE = 'USER_EVENT_SCORE_ADD',
  SPEND_SCORE = 'USER_EVENT_SCORE_SPEND'
}

export enum TEAM_EVENT_TYPE {
  LEVEL_UP = 'TEAM_EVENT_LEVEL_UP',
  ADD_EXP_POINTS = 'TEAM_EVENT_EXP_POINTS_ADD',
  ADD_ACHIEVEMENT = 'TEAM_EVENT_ACHIEVEMENT_ADD',

  NEW_MEMBER = 'TEAM_EVENT_MEMBER_NEW',
  LEFT_MEMBER = 'TEAM_EVENT_MEMBER_LEFT',
  MEMBER_LEVEL_UP = 'TEAM_EVENT_MEMBER_LEVEL_UP',
  MEMBER_ADD_ACHIEVEMENT = 'TEAM_EVENT_MEMBER_ACHIEVEMENT_ADD',

  NEW_CHALLENGE = 'TEAM_EVENT_CHALLENGE_NEW',
  WON_CHALLENGE = 'TEAM_EVENT_CHALLENGE_WON',
  DRAW_CHALLENGE = 'TEAM_EVENT_CHALLENGE_DRAW',
  EXPIRED_CHALLENGE = 'TEAM_EVENT_CHALLENGE_DRAW',

  NEW_CHALLENGE_WAGER = 'TEAM_EVENT_CHALLENGE_WAGER_NEW',
  WON_CHALLENGE_WAGER = 'TEAM_EVENT_CHALLENGE_WAGER_WON',
  DRAW_CHALLENGE_WAGER = 'TEAM_EVENT_CHALLENGE_WAGER_DRAW',
  EXPIRED_CHALLENGE_WAGER = 'TEAM_EVENT_CHALLENGE_WAGER_DRAW'
}

export const EVENT_TYPE = {
  USER: USER_EVENT_TYPE,
  TEAM: TEAM_EVENT_TYPE
};

interface IEventLevel {
  name: string;
  icon: string;
}

interface IEventAchievement {
  description: string;
  groupId: string;
  icon: string;
  id: string;
  title: string;
  worth: number;
}

interface IEventBaseItem {
  memberId: string;
  data: object;
}

interface IScoreDataAchievement {
  achievementId: string;
  achievement: IEventAchievement;
}

interface IScoreDataChallenge {
  challengeId: string;
  challenge: IChallengeItem;
}

interface IScoreDataChallengeWager {
  challengeWagerId: string;
  challengeWager: IChallengeWagerItem;
}

interface IScoreDataConversationFeedback {
  conversationId: string;
  conversation: Conversation;
}

export type IScoreData =
  | IScoreDataAchievement
  | IScoreDataChallenge
  | IScoreDataChallengeWager
  | IScoreDataConversationFeedback;

export interface IEventScore extends IEventBaseItem {
  type: USER_EVENT_TYPE.ADD_SCORE | USER_EVENT_TYPE.SPEND_SCORE;
  data: IScoreData;
}

export interface IEventMemberLevelUp extends IEventBaseItem {
  member: User;
  type: TEAM_EVENT_TYPE.MEMBER_LEVEL_UP;
  data: {
    level: IEventLevel;
  };
}

export interface IEventAddPoints extends IEventBaseItem {
  member: User;
  type: USER_EVENT_TYPE.ADD_EXP_POINTS;
  data: {
    points: number;
  };
}

export interface IEventAddAchievement extends IEventBaseItem {
  type: USER_EVENT_TYPE.ADD_ACHIEVEMENT;
  data: {
    achievementId: string;
    description: string;
    extraPoints: number;
    achievement: IEventAchievement;
  };
}

export interface IEventNewMember extends IEventBaseItem {
  type: TEAM_EVENT_TYPE.NEW_MEMBER;
  data: {
    memberId: string;
    member: User;
  };
}

export interface IEventLeftMember extends IEventBaseItem {
  type: TEAM_EVENT_TYPE.LEFT_MEMBER;
  data: {
    memberId: string;
    member: User;
  };
}

export interface IEventLevelUp extends IEventBaseItem {
  member: User;
  type: USER_EVENT_TYPE.LEVEL_UP;
  data: {
    level: IEventLevel;
  };
}

export interface IEventTeamMemberAchievement extends IEventBaseItem {
  member: User;
  type: TEAM_EVENT_TYPE.MEMBER_ADD_ACHIEVEMENT;
  data: {
    achievementId: string;
    description: string;
    extraPoints: number;
    memberId: string;
    member: User;
    achievement: IEventAchievement;
  };
}

export interface IEventTeamMemberChallenge extends IEventBaseItem {
  type: TEAM_EVENT_TYPE.NEW_CHALLENGE | TEAM_EVENT_TYPE.WON_CHALLENGE | TEAM_EVENT_TYPE.DRAW_CHALLENGE;
  data: {
    challenge: IChallengeItem;
  };
}

export type IEventsItem =
  | IEventScore
  | IEventLevelUp
  | IEventMemberLevelUp
  | IEventAddPoints
  | IEventAddAchievement
  | IEventNewMember
  | IEventLeftMember
  | IEventTeamMemberAchievement
  | IEventTeamMemberChallenge;

export type IEventsResponse = {
  items: IEventsItem[];
  lastKey: object;
};
