import gql from 'graphql-tag';

export default gql`
  mutation createChat($input: CreateChatInput!) {
    createChat(input: $input) {
      __typename
      id
      name
      createdAt
      createdBy
      author {
        id
        name
        avatarUrl
      }
    }
  }
`;
