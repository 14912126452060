import * as update from 'immutability-helper';
import * as _ from 'lodash';
import { getConversationsInQueueQuery as ConvosInQueueQuery } from '@cation/core/graphql/operation-result-types';
import Conversation from '@cation/core/types/conversation';

export function addConversationInQueue(data: ConvosInQueueQuery, c: Conversation): ConvosInQueueQuery {
  if (!data || !_.has(data, 'getConversationsInQueue.conversations')) {
    return {
      getConversationsInQueue: {
        nextToken: null,
        __typename: 'ListConversations',
        conversations: []
      }
    };
  }

  if (data.getConversationsInQueue.conversations.some(_uc => c.id === _uc.id)) {
    return data;
  }

  return update(data, { getConversationsInQueue: { conversations: { $push: [c] } } });
}

export function addConversationsInQueue(
  data: ConvosInQueueQuery,
  c: Conversation[],
  nextToken: string
): ConvosInQueueQuery {
  if (!data || !_.has(data, 'getConversationsInQueue.conversations')) {
    return {
      getConversationsInQueue: {
        nextToken: null,
        __typename: 'ListConversations',
        conversations: []
      }
    };
  }

  const newRecords = _.differenceBy(c, data.getConversationsInQueue.conversations, 'id');

  return update(data, {
    getConversationsInQueue: {
      nextToken: { $set: nextToken },
      conversations: { $push: newRecords }
    }
  });
}

export function updateConversationInQueue(data: ConvosInQueueQuery, c: Conversation): ConvosInQueueQuery {
  if (!data || !_.has(data, 'getConversationsInQueue.conversations')) {
    return {
      getConversationsInQueue: {
        nextToken: null,
        __typename: 'ListConversations',
        conversations: []
      }
    };
  }

  const itemIndex = data.getConversationsInQueue.conversations.findIndex(n => n.id === c.id);

  if (itemIndex === -1) {
    return addConversationInQueue(data, c);
  }

  if (c.isQueued === 'false') {
    return update(data, {
      getConversationsInQueue: { conversations: { $splice: [[itemIndex, 1]] } }
    });
  }

  return update(data, {
    getConversationsInQueue: { conversations: { [itemIndex]: { $set: c } } }
  });
}
