import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { ModalOverlayDataModel } from '@cation/core/models/overlays';
import { ApiService } from '@cation/core/services/api/api.service';
import { ModalOverlayService } from '@cation/core/services/overlays';
import { SharedService } from '@cation/core/services/shared/shared.service';
import * as chime from 'amazon-chime-sdk-js';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-video-chat',
  templateUrl: './video-chat.component.html',
  styleUrls: ['./video-chat.component.scss'],
})
export class VideoChatComponent implements OnInit {
  @Input() conversation;
  @Output() public closeEvent: EventEmitter<void> = new EventEmitter();

  private defaultBrowserBehaviour: chime.DefaultBrowserBehavior = new chime.DefaultBrowserBehavior();
  public ContentShareType = ContentShareType;

  private agent;
  private videoChat;
  private session;

  public audioInputDevices;
  public audioOutputDevices;
  public videoInputDevices;

  constructor(
    private apiService: ApiService,
    private authHelper: AuthHelper,
    private modalOverlayService: ModalOverlayService,
    private sharedService: SharedService
  ) {}

  async ngOnInit() {
    this.agent = this.authHelper.userProfile;

    console.log('starting video chat');
    this.videoChat = await this.apiService
      .startVideoChat({
        conversationId: this.conversation.id,
        agentCognitoId: this.agent.cognitoId,
      })
      .toPromise();
    console.log('videoChat', this.videoChat);
    await this.startMeeting();
  }

  async startMeeting() {
    const observer = {
      audioVideoDidStart: () => {
        console.log('*** audioVideoDidStart ***');
        this.session.audioVideo.startLocalVideoTile();
      },
      videoTileDidUpdate: (tileState) => {
        console.log('*** videoTileDidUpdate ***', tileState);

        if (!tileState.boundAttendeeId) {
          console.log('Ignoring unbound tileState');
          return;
        }

        const videoElement = tileState.localTile
          ? 'ctn-meet-local-video'
          : tileState.isContent
          ? 'ctn-meet-screen-share-video'
          : 'ctn-meet-remote-video';

        const videoEle = document.getElementsByClassName(videoElement)[0];
        console.log('videoEle', videoEle);
        this.session.audioVideo.bindVideoElement(tileState.tileId, videoEle);
        // Set the video visible here
      },
    };

    const logger = new chime.ConsoleLogger('SDK', chime.LogLevel.WARN);
    this.session = new chime.DefaultMeetingSession(
      new chime.MeetingSessionConfiguration(this.videoChat.meeting, this.videoChat.attendee),
      logger,
      new chime.DefaultDeviceController(logger)
    );

    this.session.audioVideo.addObserver(observer);

    this.audioInputDevices = [
      ...(await this.session.audioVideo.listAudioInputDevices()),
      { deviceId: 'none', label: 'Mute Mic' },
    ];
    this.audioOutputDevices = [
      ...(await this.session.audioVideo.listAudioOutputDevices()),
      { deviceId: 'none', label: 'Mute Speaker' },
    ];
    this.videoInputDevices = [
      ...(await this.session.audioVideo.listVideoInputDevices()),
      { deviceId: 'none', label: 'Stop My Video' },
    ];

    console.log('audioInputDevices', this.audioInputDevices);
    console.log('audioOutputDevices', this.audioOutputDevices);
    console.log('videoInputDevices', this.videoInputDevices);

    if (this.audioInputDevices.length) {
      // Inform user doesn't have audio device
      console.warn('^^^ NO AUDIO DEVICES FOUND ^^^');
    }

    if (this.videoInputDevices.length) {
      // Inform user doesn't have video device
      console.warn('^^^ NO VIDEO DEVICES FOUND ^^^');
    }

    await this.selectAudioInputDevice(this.audioInputDevices[0].deviceId);
    await this.selectAudioOutputDevice(this.audioOutputDevices[0].deviceId);
    await this.selectVideoInputDevice(this.videoInputDevices[0].deviceId);

    await this.session.audioVideo.start();

    this.sharedService.videoChatStatus$.next(true);
  }

  async selectAudioInputDevice(audioInputDevice) {
    console.log('Selected audioInputDevice -', audioInputDevice);
    if (audioInputDevice.deviceId === 'none') {
      this.session.audioVideo.realtimeMuteLocalAudio();
    } else {
      this.session.audioVideo.realtimeUnmuteLocalAudio();
      await this.session.audioVideo.startAudioInput(audioInputDevice);
    }
  }

  async selectAudioOutputDevice(audioOutputDevice) {
    console.log('Selected audioOutputDevice -', audioOutputDevice);
    if (audioOutputDevice.deviceId === 'none') {
      this.session.audioVideo.unbindAudioElement();
    } else {
      this.session.audioVideo.bindAudioElement(document.getElementsByClassName('ctn-meet-audio')[0]);
      await this.session.audioVideo.chooseAudioOutput(audioOutputDevice);
    }
  }

  async selectVideoInputDevice(videoInputDevice) {
    console.log('Selected videoInputDevice -', videoInputDevice);
    if (videoInputDevice.deviceId === 'none') {
      this.session.audioVideo.stopVideoInput();
    } else {
      this.session.audioVideo.startLocalVideoTile();
      await this.session.audioVideo.startVideoInput(videoInputDevice);
    }
  }

  async endMeeting() {
    console.log('end video chat');

    const sub: Subscription = this.modalOverlayService
      .openConfirmModal(new ModalOverlayDataModel('', 'CONFIRMATION_MESSAGE.VIDEO_CHAT_MESSAGE'))
      .subscribe(async (v: boolean) => {
        sub.unsubscribe();
        if (v === true) {
          const out = await this.apiService
            .endVideoChat({
              conversationId: this.conversation.id,
              agentCognitoId: this.agent.cognitoId,
              meetingId: this.session.configuration.meetingId,
            })
            .toPromise();
          console.log('endMeeting:out', out);
          this.session.audioVideo.stop();
          this.closeEvent.emit();

          this.sharedService.videoChatStatus$.next(false);
        }
      });
  }

  public async contentShareStart(contentShareType, videoUrl?: string): Promise<void> {
    switch (contentShareType) {
      case ContentShareType.ScreenCapture: {
        try {
          await this.session.audioVideo.startContentShareFromScreenCapture();
        } catch (e) {
          console.log('Could not start content share:', e);
          return;
        }
        break;
      }
      case ContentShareType.VideoFile: {
        const videoFile = document.getElementById('content-share-video') as HTMLVideoElement;
        if (videoUrl) {
          videoFile.src = videoUrl;
        }

        const mediaStream = await this.playToStream(videoFile);
        try {
          // getDisplayMedia can throw.
          await this.session.audioVideo.startContentShare(mediaStream);
        } catch (e) {
          console.log('Could not start content share:', e);
          return;
        }
        break;
      }
    }
  }

  public async contentShareStop(contentShareType): Promise<void> {
    this.session.audioVideo.stopContentShare();

    if (contentShareType === ContentShareType.VideoFile) {
      const videoFile = document.getElementById('content-share-video') as HTMLVideoElement;
      videoFile.pause();
      videoFile.style.display = 'none';
    }
  }

  private async playToStream(videoFile: HTMLVideoElement): Promise<MediaStream> {
    await videoFile.play();

    if (this.defaultBrowserBehaviour.hasFirefoxWebRTC()) {
      // @ts-ignore
      return videoFile.mozCaptureStream();
    }

    // @ts-ignore
    return videoFile.captureStream();
  }
}

export enum ContentShareType {
  ScreenCapture,
  VideoFile,
}
