import Conversation from '@cation/core/types/conversation';
import User from '@cation/core/types/user';

interface IESBaseMessage {
  metricType: string;
  agentId: string;
  conversation: Conversation;
  conversationId: string;
  createdAt: string;
  customerId: string;
  id: string;
  message: string;
  metricId: string;
}

interface IESUserMessage extends IESBaseMessage {
  metricType: 'USER_MESSAGE';
  customer: User;
}

interface IESAgentMessage extends IESBaseMessage {
  metricType: 'AGENT_MESSAGE';
  agent: User;
}

export type IESMessage = IESUserMessage | IESAgentMessage;

export interface IESConversationSearchResponse {
  total: number;
  items: IESMessage[];
}
