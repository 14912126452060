<chat-topbar [users]="chat.associated"></chat-topbar>
<div class="internal-chat-history">
  <div
    #messagesHistory
    [internalChatScroll]="loadMoreMessages"
    [completedFetching]="completedFetching"
    internalChatScrollDirection="up"
    class="internal-chat-history_messages"
  >
    <ul class="internal-chat-messages-list">
      <li *ngFor="let message of messages; trackBy: trackItem">
        <internal-message-balloon [message]="message"></internal-message-balloon>
      </li>
    </ul>
  </div>
  <internal-message-input></internal-message-input>
</div>
