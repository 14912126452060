import { Injectable } from '@angular/core';
import { FetchMoreOptions, FetchMoreQueryOptions, FetchPolicy } from 'apollo-client';
import * as _ from 'lodash';
import { AppsyncOperationService } from './appsync-operation.service';
import * as queries from '../../graphql/queries';
import * as subscriptions from '../../graphql/subscriptions';
import * as mutations from '../../graphql/mutations';
import { getUserChatsConnectionThroughUserQuery as ChatsQuery } from '../../graphql/operation-result-types';
import { constants, updateUserChat, addChat, deleteUserChat, addUserChat } from '../../chat-helper';
import { Chat, DirectChat, User, UserChat } from '../../types';
import { ISubscriptionOptions, IReadMessageInput } from './types';

@Injectable()
export class AppsyncChatService extends AppsyncOperationService {
  getAllChats(userId, observerOrNext, updateQuery, onError = error => {}) {
    const watchQueryOptions = {
      query: queries.getUserChatsConnection,
      variables: { first: constants.chatFirst },
      fetchPolicy: 'network-only' as FetchPolicy
    };

    const watchQuerySubscriptionOptions = { observerOrNext };

    const subscribeToMoreOptions = _.merge(
      { onError },
      {
        document: subscriptions.subscribeToNewUserChats,
        variables: { userId },
        updateQuery
      }
    );

    return this.loadAndSubscribeMore<ChatsQuery>({
      watchQueryOptions,
      watchQuerySubscriptionOptions,
      subscribeToMoreOptions
    });
  }

  loadMoreChats(observedQuery, nextToken: string, fetchMoreOptions) {
    return this.loadMore(observedQuery, nextToken, fetchMoreOptions);
  }

  async updateChatName(chat: Chat, name: string) {
    const {
      data: { updateChatName }
    } = await this.mutate<{ updateChatName: Chat }>({
      mutation: mutations.updateChatName,
      variables: { id: chat.id, name },

      optimisticResponse: () => ({
        updateChatName: {
          ...chat,
          name,
          __typename: 'Chat'
        }
      }),

      update: (proxy, { data: { updateChatName: _chat } }) => {
        this.loggerService.info('updateChatName update with:', _chat);
        const _options = {
          query: queries.getUserChatsConnection,
          variables: { first: constants.chatFirst },
          fetchPolicy: 'network-only' as FetchPolicy
        };
        const prev = proxy.readQuery<ChatsQuery>(_options);
        const updatedUserChat = {
          ...prev.me.chats.userChats.find(({ chatId }) => chatId === _chat.id),
          chat: { ..._chat, __typename: 'Chat' }
        };
        const data = updateUserChat(prev, updatedUserChat as UserChat);

        this.loggerService.info('[updateUserChats data]', { _options, prev, data });

        proxy.writeQuery({ ..._options, data });
      }
    });

    return updateChatName;
  }

  async createUserChats(chat: Chat, agent: User) {
    const input = { chatId: chat.id, userId: agent.id };

    const {
      data: { createUserChats }
    } = await this.mutate<{ createUserChats: UserChat }>({
      mutation: mutations.createUserChats,
      variables: { input }
    });

    return createUserChats;
  }

  async updateLastReadMessageId(input: IReadMessageInput, currentChat) {
    const {
      data: { updateLastReadMessageId }
    } = await this.mutate<{ updateLastReadMessageId: UserChat }>({
      mutation: mutations.updateLastReadMessageId,
      variables: { input },

      optimisticResponse: () => ({
        updateLastReadMessageId: {
          chatId: input.chatId,
          userId: input.userId,
          chat: {
            ...currentChat.chat,
            author: {
              ...currentChat.chat.author,
              avatarUrl: currentChat.associated.find(({ user }) => user.id === currentChat.chat.author.id).user
                .avatarUrl
            }
          },
          associated: currentChat.associated.map(chat => {
            if (chat.userId === input.userId) {
              return {
                ...chat,
                lastReadMessageId: input.lastReadMessageId
              };
            }

            return chat;
          }),
          __typename: 'UserChats'
        }
      }),

      update: (proxy, { data: { updateLastReadMessageId: userChats } }) => {
        this.loggerService.info('[updateLastReadMessageId - update fn]', userChats);
        const _options = {
          query: queries.getUserChatsConnection,
          variables: { first: constants.chatFirst },
          fetchPolicy: 'network-only' as FetchPolicy
        };
        const prev = proxy.readQuery<ChatsQuery>(_options);
        const data = updateUserChat(prev, userChats);

        this.loggerService.info('[updateLastReadMessageId data]', { _options, prev, data });

        proxy.writeQuery({ ..._options, data });
      }
    });

    return updateLastReadMessageId;
  }

  async updateUserChats(input, currentChat) {
    const {
      data: { updateUserChats }
    } = await this.mutate<{ updateUserChats: UserChat }>({
      mutation: mutations.updateUserChats,
      variables: { input },

      optimisticResponse: () => ({
        updateUserChats: {
          chatId: input.chatId,
          userId: input.userId,
          chat: {
            ...currentChat.chat,
            author: {
              ...currentChat.chat.author,
              avatarUrl: currentChat.associated.find(({ user }) => user.id === currentChat.chat.author.id).user
                .avatarUrl
            }
          },
          associated: currentChat.associated,
          __typename: 'UserChats'
        }
      }),

      update: (proxy, { data: { updateUserChats: userChats } }) => {
        this.loggerService.info('[updateUserChats - update fn]', userChats);
        const _options = {
          query: queries.getUserChatsConnection,
          variables: { first: constants.chatFirst },
          fetchPolicy: 'network-only' as FetchPolicy
        };
        const prev = proxy.readQuery<ChatsQuery>(_options);
        const data = updateUserChat(prev, userChats);

        this.loggerService.info('[updateUserChats data]', { _options, prev, data });

        proxy.writeQuery({ ..._options, data });
      }
    });

    return updateUserChats;
  }

  async enterChat(userChat: UserChat) {
    const input = {
      userId: this.me.id,
      chatId: userChat.chatId,
      connected: 'true'
    };

    const {
      data: { updateUserChats }
    } = await this.mutate<{ updateUserChats: UserChat }>({
      mutation: mutations.updateUserChats,
      variables: { input },

      optimisticResponse: () => ({
        updateUserChats: {
          ...userChat,
          chat: {
            ...userChat.chat,
            author: {
              ...userChat.chat.author,
              avatarUrl: this.me.avatarUrl
            }
          },
          associated: userChat.associated.map(user =>
            user.userId === this.me.id ? { ...user, connected: 'true' } : user
          ),
          __typename: 'UserChats'
        }
      }),

      update: (proxy, { data: { updateUserChats: userChats } }) => {
        this.loggerService.info('[enterChat - update fn]', userChats);
        const _options = {
          query: queries.getUserChatsConnection,
          variables: { first: constants.chatFirst },
          fetchPolicy: 'network-only' as FetchPolicy
        };
        const prev = proxy.readQuery<ChatsQuery>(_options);
        const data = addUserChat(prev, userChats);

        this.loggerService.info('[enterChat data]', { _options, prev, data });

        proxy.writeQuery({ ..._options, data });
      }
    });

    return updateUserChats;
  }

  async leaveChat(userChat: UserChat) {
    const input = {
      userId: this.me.id,
      chatId: userChat.chatId,
      connected: 'false'
    };

    const {
      data: { updateUserChats }
    } = await this.mutate<{ updateUserChats: UserChat }>({
      mutation: mutations.updateUserChats,
      variables: { input },

      optimisticResponse: () => ({
        updateUserChats: {
          ...userChat,
          chat: {
            ...userChat.chat,
            author: {
              ...userChat.chat.author,
              avatarUrl: this.me.avatarUrl
            }
          },
          associated: userChat.associated.map(user =>
            user.userId === this.me.id ? { ...user, connected: 'false' } : user
          ),
          __typename: 'UserChats'
        }
      }),

      update: (proxy, { data: { updateUserChats: userChats } }) => {
        this.loggerService.info('[leaveChat - update fn]', userChats);
        const _options = {
          query: queries.getUserChatsConnection,
          variables: { first: constants.chatFirst },
          fetchPolicy: 'network-only' as FetchPolicy
        };
        const prev = proxy.readQuery<ChatsQuery>(_options);
        const data = deleteUserChat(prev, userChats);

        this.loggerService.info('[leaveChat data]', { _options, prev, data });

        proxy.writeQuery({ ..._options, data });
      }
    });

    return updateUserChats;
  }

  async subscribeToUpdateChatName(
    userChat: UserChat,
    params?: ISubscriptionOptions
  ): Promise<ZenObservable.Subscription> {
    const options = _.merge(
      {
        onComplete: () => null,
        onError: (...args) => {
          this.loggerService.error('[AppsyncChatService subscribeToUpdateChatName:error]', args);
          params.onError && params.onError(args);
        }
      },
      params
    );

    const realtimeResults = result => {
      const {
        data: { subscribeToUpdateChatName: _chat }
      } = result;
      this.loggerService.info('[subscribeToUpdateChatName realtimeResults] complete', result);

      const newUCs = { ...userChat, chat: _chat };

      this.updateUserChatsLocally(newUCs);

      return options.realtimeResults(result);
    };

    this.loggerService.info('[AppsyncChatService subscribeToUpdateChatName]');

    return this.subscription({
      query: subscriptions.subscribeToUpdateChatName,
      variables: { id: userChat.chatId },
      realtimeResults: realtimeResults,
      onComplete: options.onComplete,
      onError: options.onError
    });
  }

  async subscribeToUpdateUserChats(chat: Chat, params?: ISubscriptionOptions): Promise<ZenObservable.Subscription> {
    const options = _.merge(
      {
        onComplete: () => null,
        onError: (...args) => {
          this.loggerService.error('[AppsyncChatService subscribeToUpdateUserChats:error]', args);
          params.onError && params.onError(args);
        }
      },
      params
    );
    const realtimeResults = result => {
      const {
        data: { subscribeToUpdateUserChats: ucs }
      } = result;
      this.loggerService.info('[subscribeToUpdateUserChats realtimeResults] complete', result);

      const assignedToMeChat = {
        data: {
          subscribeToUpdateUserChats: {
            ...ucs,
            userId: this.me.id
          }
        }
      };

      if (ucs.userId !== this.me.id) {
        this.updateUserChatsLocally(assignedToMeChat.data.subscribeToUpdateUserChats);
      }

      return options.realtimeResults(assignedToMeChat);
    };

    this.loggerService.info('[AppsyncChatService subscribeToUpdateUserChats]');

    return this.subscription({
      query: subscriptions.subscribeToUpdateUserChats,
      variables: { chatId: chat.id },
      realtimeResults: realtimeResults,
      onComplete: options.onComplete,
      onError: options.onError
    });
  }

  updateUserChatsLocally(userChats) {
    return this.appsync.hc().then(client => {
      const _options = {
        query: queries.getUserChatsConnection,
        variables: { first: constants.chatFirst },
        fetchPolicy: 'network-only' as FetchPolicy
      };

      const prev = client.readQuery(_options);
      const data = updateUserChat(prev, userChats);

      client.writeQuery({ ..._options, data });

      return data;
    });
  }

  async getDirectChat(firstUserId, secondUserId) {
    const {
      data: { getDirectChat }
    } = await this.query<{ getDirectChat: DirectChat }>({
      query: queries.getDirectChat,
      variables: { firstUserId, secondUserId },
      fetchPolicy: 'network-only' as FetchPolicy
    });

    if (getDirectChat) {
      await this.appsync.hc().then(client => {
        const _options = {
          query: queries.getUserChatsConnection,
          variables: { first: constants.chatFirst },
          fetchPolicy: 'network-only' as FetchPolicy
        };

        const prev = client.readQuery(_options);
        const data = addUserChat(prev, getDirectChat.userChat);

        return client.writeQuery({ ..._options, data });
      });
    }

    return getDirectChat;
  }

  async createDirectChat(input) {
    const {
      data: { createDirectChat }
    } = await this.mutate<{ createDirectChat: DirectChat }>({
      mutation: mutations.createDirectChat,
      variables: { input }
    });

    this.loggerService.info('[AppsyncChatService createDirectChat] created', createDirectChat);

    return createDirectChat;
  }

  async createChat({ userId, chatId, chatName, members }: any): Promise<Chat | undefined> {
    const input = {
      id: chatId,
      name: chatName,
      createdBy: userId
    };

    const {
      data: { createChat }
    } = await this.mutate<{ createChat: Chat }>({
      mutation: mutations.createChat,
      variables: { input: input },

      optimisticResponse: () => ({
        createChat: {
          ...input,
          __typename: 'Chat',
          author: {
            __typename: 'User',
            ...this.me
          },
          createdAt: Math.floor(Date.now() / 1000)
        }
      }),

      update: (proxy, { data: { createChat: chat } }) => {
        const _options = {
          query: queries.getUserChatsConnection,
          variables: { first: constants.chatFirst },
          fetchPolicy: 'network-only' as FetchPolicy
        };
        const userChat: UserChat = {
          __typename: 'UserChats',
          userId: chat.createdBy,
          chatId: chat.id,
          chat,
          associated: members.map(member => ({
            __typename: 'UserChats',
            userId: member.id,
            chatId: chat.id,
            user: {
              __typename: 'User',
              ...member
            },
            typing: false,
            connected: 'true',
            lastReadMessageId: `${new Date(0).toISOString()}_`
          }))
        };

        const prev = proxy.readQuery<ChatsQuery>(_options);
        const data = addChat(prev, userChat);

        proxy.writeQuery({ ..._options, data });
      }
    });

    return createChat;
  }
}
