import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import { Analytics } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import * as _ from 'lodash';
import { AppsyncTeamService } from '@cation/core/services/appsync/appsync-team.service';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { Role } from '@cation/core/auth/role.enum';
import Team from '@cation/core/types/team';

@Component({
  selector: 'app-ctn-add-edit-team-dialog',
  templateUrl: './add-edit-team-dialog.component.html',
  styleUrls: ['./add-edit-team-dialog.component.scss']
})
export class AddEditTeamDialogComponent implements OnInit {
  public team: Partial<Team> = {};
  public errorMessage;
  public isUsersLoading = true;
  public roles: Role[] = [Role.TEAM_LEAD];

  /** control for the selected team leads */
  public teamLeadCtrl: UntypedFormControl = new UntypedFormControl();

  public members = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Team,
    private dialogRef: MatDialogRef<AddEditTeamDialogComponent>,
    private appsyncTeamService: AppsyncTeamService,
    private apiService: ApiService,
    private s3Service: S3Service,
    private logService: LogService
  ) {
    this.updateTeam = this.updateTeam.bind(this);
    this.createTeam = this.createTeam.bind(this);

    if (data && data.id) {
      const { id, teamLeadId, name, description, icon } = data;
      this.team = { id, teamLeadId, name, description, icon };
    }
  }

  ngOnInit() {
    if (this.data) {
      this.members = [...this.data.members];
    }
  }

  addMember(member) {
    this.members = [
      ...this.members,
      {
        __typename: 'User',
        cognitoId: member.cognitoId,
        username: member.username,
        avatarUrl: member.avatarUrl,
        teamId: member.teamId,
        userData: member.userData
      }
    ];
  }

  removeMember(member) {
    this.members = this.members.filter(({ cognitoId }) => cognitoId !== member.cognitoId);
  }

  async submit() {
    const teamLeadId = this.teamLeadCtrl.value.cognitoId;
    const team = { ...this.team, teamLeadId };

    let submitFn = team.id ? this.updateTeam : this.createTeam;

    try {
      await submitFn(team);

      this.dialogRef.close(team);
    } catch (e) {
      this.errorMessage = e.message;
    }
  }

  getMembersToUpdate() {
    if (this.data) {
      const membersToAdd = _.difference(this.members, this.data.members).map(member => ({
        ...member,
        teamId: this.data.id
      }));
      const membersToRemove = _.difference(this.data.members, this.members).map(member => ({
        ...member,
        teamId: 'None'
      }));

      return [...membersToAdd, ...membersToRemove].map(({ cognitoId, teamId, username }) => ({
        cognitoId,
        teamId,
        username
      }));
    }

    return this.members.map(({ cognitoId, teamId, username }) => ({ cognitoId, teamId, username }));
  }

  createTeam(input) {
    const createdAt = new Date().toISOString();
    const id = `${createdAt}_${uuidv4()}`;

    const optimisticTeamLead = {
      cognitoId: this.teamLeadCtrl.value.cognitoId,
      username: this.teamLeadCtrl.value.username,
      avatarUrl: this.teamLeadCtrl.value.avatarUrl
    };

    const membersToUpdate = this.getMembersToUpdate().map(member => ({ ...member, teamId: id }));
    const optimisticMembers = this.members.map(member => ({
      ...member,
      teamId: id
    }));

    this.logService.log('[createTeam input]', input);

    return Promise.all(membersToUpdate.map(member => this.apiService.updateAgent(member).toPromise()))
      .then(() => this.appsyncTeamService.createTeam({ ...input, id }, optimisticMembers, optimisticTeamLead))
      .then(({ data }) => {
        this.logService.log('mutation complete', data);
        return data;
      })
      .catch(err => this.logService.error('Error creating team', err))
      .then(() => Analytics.record('Create Team'));
  }

  updateTeam(input) {
    this.logService.log('[updateTeam input]', input);
    const membersToUpdate = this.getMembersToUpdate();
    const optimisticMembers = this.members.map(member => ({
      ...member,
      teamId: this.data.id
    }));

    console.log(membersToUpdate);

    return Promise.all(membersToUpdate.map(member => this.apiService.updateAgent(member).toPromise()))
      .then(() => this.appsyncTeamService.updateTeam(input, optimisticMembers, this.data.teamLead))
      .then(({ data }) => {
        this.logService.log('mutation complete', data);
        return data;
      })
      .catch(err => this.logService.error('Error updating team', err))
      .then(() => Analytics.record('Update Team'));
  }

  async onTeamIconSelect(icon) {
    this.logService.log('[AddEditTeamDialogComponent onTeamIconSelect:icon]', icon);
    this.team.icon = icon;
  }
}
