import gql from 'graphql-tag';

export default gql`
  subscription subscribeToUpdateConversationQueueStatus {
    subscribeToUpdateConversationQueueStatus {
      __typename
      id
      intentsTriggered
      name
      caseId
      createdAt
      createdBy
      history
      locale
      location
      sentiment
      keywords
      status
      channel
      isQueued
      lastQuestion
      author {
        cognitoId
        username
        userData
      }
    }
  }
`;
