<div class="text-center">
  <h1 mat-dialog-title class="d-inline-block">{{ 'ADD_ACHIEVEMENT_DIALOG.TITLE' | translate }}</h1>
  <button mat-icon-button [tabIndex]="-1" type="button" class="close" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="achievementFormGroup">
      <ng-template matStepLabel>
        {{ 'ADD_ACHIEVEMENT_DIALOG.SELECT_ACHIEVEMENT_STEP.LABEL' | translate }}
      </ng-template>
      <mat-progress-bar class="m-2" *ngIf="isLoading" [mode]="'indeterminate'"></mat-progress-bar>
      <h5 *ngIf="!achievements.length && !isLoading">
        {{ 'ADD_ACHIEVEMENT_DIALOG.SELECT_ACHIEVEMENT_STEP.EMPTY_ACHIEVEMENTS' | translate }}
      </h5>
      <app-achievements-list
        *ngIf="achievements.length"
        [achievements]="achievements"
        [selective]="true"
        (onAchievementSelect)="selectAchievement($event); stepper.next()"
      ></app-achievements-list>
      <div>
        <button mat-button matStepperNext>{{ 'BUTTON.NEXT' | translate }}</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="descriptionFormGroup">
      <form [formGroup]="descriptionFormGroup">
        <ng-template matStepLabel>
          {{ 'ADD_ACHIEVEMENT_DIALOG.DESCRIPTION_STEP.LABEL' | translate }}
        </ng-template>
        <mat-form-field class="w-100">
          <textarea
            matInput
            [placeholder]="'ADD_ACHIEVEMENT_DIALOG.DESCRIPTION_STEP.TEXT_PLACEHOLDER' | translate"
            formControlName="description"
            required
          >
          </textarea>
        </mat-form-field>
        <div>
          <button mat-button matStepperPrevious>{{ 'BUTTON.BACK' | translate }}</button>
          <button mat-button matStepperNext>{{ 'BUTTON.NEXT' | translate }}</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="extraPointsFormGroup">
      <form [formGroup]="extraPointsFormGroup">
        <ng-template matStepLabel>
          {{ 'ADD_ACHIEVEMENT_DIALOG.EXTRA_POINTS_STEP.LABEL' | translate }}
        </ng-template>
        <mat-form-field class="w-100">
          <input
            type="number"
            min="0"
            matInput
            [placeholder]="'ADD_ACHIEVEMENT_DIALOG.EXTRA_POINTS_STEP.TEXT_PLACEHOLDER' | translate"
            formControlName="extraPoints"
            required
          />
        </mat-form-field>
        <b>
          {{
            'ADD_ACHIEVEMENT_DIALOG.POINTS_REMAINING_THIS_MONTH'
              | translate
                : {
                    pointPool: teamMonthlyPointPool,
                    availablePoints: availablePoints
                  }
          }}
        </b>
        <div>
          <button mat-button matStepperPrevious>{{ 'BUTTON.BACK' | translate }}</button>
          <button mat-button matStepperNext>{{ 'BUTTON.NEXT' | translate }}</button>
        </div>
      </form>
    </mat-step>
    <mat-step class="position-relative">
      <ngx-ui-loader [fgsSize]="25" [loaderId]="loaderId"></ngx-ui-loader>
      <ng-template matStepLabel>
        {{ 'ADD_ACHIEVEMENT_DIALOG.FINISH_STEP.LABEL' | translate }}
      </ng-template>
      <div class="d-flex row justify-content-center align-items-center">
        <app-s3-image
          [image]="achievementForm.achievement.value?.icon"
          imageStyle="width: 100px; height: 100px"
        ></app-s3-image>
        <mat-icon>arrow_forward</mat-icon>
        <h3>{{ data?.user?.username }}</h3>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h4>{{ descriptionForm.description.value }}</h4>
        <h4>
          {{
            'ADD_ACHIEVEMENT_DIALOG.FINISH_STEP.PLUS_POINTS' | translate: { value: extraPointsForm.extraPoints.value }
          }}
        </h4>
      </div>
      <div>
        <button mat-button matStepperPrevious>{{ 'BUTTON.BACK' | translate }}</button>
        <button mat-button (click)="stepper.reset(); errorMessage = ''">{{ 'BUTTON.RESET' | translate }}</button>
        <button mat-button (click)="onAddAchievement()">{{ 'BUTTON.ADD' | translate }}</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

  <div *ngIf="errorCode || errorMessage" class="alert alert-danger mt-2" [@fadeInOut]>
    {{
      errorCode
        ? ('ADD_ACHIEVEMENT_DIALOG.ERROR_' + errorCode
          | translate
            : {
                points: extraPointsForm.extraPoints.value,
                availablePoints: availablePoints,
                teamName: data.user?.team?.name
              })
        : errorMessage
    }}
  </div>
</div>
