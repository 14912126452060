import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ICMS } from '@cation/core/interfaces/cms';
import { S3Service } from '@cation/core/services/s3/s3.service';

@Component({
  selector: 'app-chat-cms-view',
  templateUrl: './chat-cms-view.component.html',
  styleUrls: ['./chat-cms-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatCmsViewComponent implements OnInit, OnDestroy {
  loaderId = `ctn-canned-response-${uuidv4()}`;
  cmsUpdateInterval;

  @Input() cms: ICMS;
  @Input() isConversationClosed: boolean;
  @Input() isFlexibleLayout: boolean;
  @Input() readOnly: boolean;
  @Input() changeTab: EventEmitter<ICMS>;

  @ViewChild('scrollCms', { static: true }) private scrollCmsContainer: ElementRef;

  constructor(private ngxLoader: NgxUiLoaderService, private s3Service: S3Service) {}

  ngOnInit(): void {
    this.cms.init();
    this.cms.onAddNewComment.subscribe(() => setTimeout(() => this.scrollToBottom()));
    this.cmsUpdateInterval = setInterval(() => this.cms.getListComments(), 60000);
  }

  ngOnDestroy(): void {
    clearInterval(this.cmsUpdateInterval);
  }

  trackComment(index, item) {
    return item.id;
  }

  private scrollToBottom(): void {
    try {
      this.scrollCmsContainer.nativeElement.scrollTop = this.scrollCmsContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  handleLoader = status => {
    if (status) {
      return this.ngxLoader.startLoader(this.loaderId);
    }
    return this.ngxLoader.stopLoader(this.loaderId);
  };

  async handleLink($event) {
    await this.s3Service.handleLink($event, this.handleLoader);
  }
}
