<div style="display: grid;" class="w-100 h-100">
  <ngx-charts-bar-vertical-stacked
    [scheme]="colorScheme"
    [results]="multi"
    [gradient]="true"
    [xAxis]="true"
    [yAxis]="true"
    [legend]="true"
    [legendTitle]="'MY_STATISTICS.LEGEND_TITLE' | translate"
    [roundDomains]="true"
    [animations]="true"
  >
  </ngx-charts-bar-vertical-stacked>
</div>
