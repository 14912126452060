import { Component, Input } from '@angular/core';
import Conversation from '@cation/core/types/conversation';

@Component({
  selector: 'app-chat-conversation-history',
  templateUrl: './chat-conversation-history.component.html',
  styleUrls: ['./chat-conversation-history.component.scss'],
})
export class ChatConversationHistoryComponent {
  @Input() conversation: Conversation;
  @Input() isHistoryLoading = false;
  @Input() isConversationClosed: boolean;
  @Input() readOnly = false;
  @Input() fullScreen = false;
  @Input() isFlexibleLayout = false;
  @Input() conversationHistory;
  @Input() senderId;

  constructor() {}

  public trackMessage(index, message) {
    return message ? message.id : undefined;
  }

  public fromBot(message): boolean {
    return message.actor === 'BOT';
  }
}
