import User from '@cation/core/types/user';

export enum CHALLENGE_TYPE {
  MOST_POINTS = 'MOST_POINTS',
  MOST_STARS = 'MOST_STARS',
  FIVE_STARS_TO_DATE = 'FIVE_STARS_TO_DATE',
}

type IChallengeStatus = 'PENDING' | 'ACCEPTED' | 'FINISHED' | 'EXPIRED';

interface IChallengeBaseItem {
  id: string;
  initiatorId: string;
  opponentId: string;
  winnerId: string;
  createdAt: string;
  finishDate: string;
  cost: number;
  status: IChallengeStatus;
  data: object;

  initiator: User;
  opponent: User;
}

export interface IChallengeMostPoints extends IChallengeBaseItem {
  type: CHALLENGE_TYPE.MOST_POINTS;
  data: {};
  startDate?;
}

export interface IChallengeMostStars extends IChallengeBaseItem {
  type: CHALLENGE_TYPE.MOST_STARS;
  data: {};
  startDate?;
}

export interface IChallengeFiveStarsToDate extends IChallengeBaseItem {
  type: CHALLENGE_TYPE.FIVE_STARS_TO_DATE;
  data: {
    amount: number;
  };
  startDate?;
}

export type IChallengeItem = IChallengeMostPoints | IChallengeMostStars | IChallengeFiveStarsToDate;

export type IChallengesResponse = {
  items: IChallengeItem[];
  lastKey: object;
};
