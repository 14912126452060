import gql from 'graphql-tag';

export default gql`
  subscription subscribeToNewUCsByConversation($conversationId: ID!) {
    subscribeToNewUCsByConversation(conversationId: $conversationId) {
      __typename
      userId
      conversationId
      conversation {
        __typename
        id
        status
      }
      associated {
        __typename
        userId
        conversationId
        typing
        connected
        role
      }
    }
  }
`;
