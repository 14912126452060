<ngx-loading [show]="apiHook.isLoading()"></ngx-loading>

<mat-card class="ctn-card mt-2">
  <mat-card-header>
    <div mat-card-avatar class="d-flex justify-content-center align-items-center">
      <mat-icon>sports_esports</mat-icon>
    </div>
    <mat-card-title>{{ 'LIST_ACHIEVEMENTS.TITLE' | translate }}</mat-card-title>
    <mat-card-subtitle>{{ 'LIST_ACHIEVEMENTS.DESCRIPTION' | translate }}</mat-card-subtitle>
    <button
      mat-icon-button
      color="primary"
      (click)="onAddAchievement()"
      [title]="'ADMIN_GAMIFICATION_ACHIEVEMENTS.ADD_BUTTON_TEXT' | translate"
    >
      <mat-icon>add_box</mat-icon>
    </button>
  </mat-card-header>
  <mat-card-content>
    <div class="achievements" [@listFadeInOut]="achievements.length">
      <div class="achievement-item-wrapper" *ngFor="let item of achievements; trackBy: trackAchievement">
        <div class="achievement-item" [class.achievement-item_inactive]="!item.isAchieved">
          <app-s3-image [image]="item.icon" imageStyle="width: 140px; height: 140px;"></app-s3-image>
          <div class="achievement__title">{{ item.title }}</div>
          <div class="achievement__description">{{ item.description }}</div>
        </div>

        <div class="achievement-item-overlay">
          <button mat-mini-fab color="primary" (click)="onEditAchievement(item)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-mini-fab color="warn" (click)="onDeleteAchievement(item)">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
