import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdvancedPieChartComponent } from '@swimlane/ngx-charts';
import * as moment from 'moment';
import * as shape from 'd3-shape';
import { IUserConversationStatistics } from '@cation/core/types/api';
import { LocaleUtil } from '@cation/core/util/locale-util';
import { enumToArray } from '@cation/core/util/common-util';
import { Channel } from '@cation/core/enums/channel.enum';
import { chartColorSchemas } from '@cation/core/constants/chart-color-schema';

@Component({
  selector: 'app-multiple-total-conversations-chart',
  templateUrl: './multiple-total-conversations-chart.component.html',
  styleUrls: ['./multiple-total-conversations-chart.component.scss']
})
export class MultipleTotalConversationsChartComponent implements OnInit {
  @Input() xAxisTicksCount: number = 10;
  @Input() metric: 'channel' | 'locale' | 'stars' | 'count-and-duration' = 'channel';
  @Input() showLegend: boolean = true;
  @Input() metricField:
    | 'countConversations'
    | 'countConversationsStars'
    | 'countConversationsPoints'
    | 'conversationsDuration' = 'countConversations';

  multi: object[] = [];
  xAxisTicks: string[] = [];
  curve = shape.curveMonotoneX;
  colorScheme = { domain: chartColorSchemas['green-red'] };

  countConversationsTitle = 'Quantity';
  conversationsDurationTitle = 'Duration';
  ninthPercentileTitle = '90th percentile';

  private channels = enumToArray(Channel);
  private locales = ['en_IE', 'en_GB', 'it_IT', 'es_ES', 'fr_FR', 'de_DE', 'pl_PL'];
  private stars = [5, 4, 3, 2, 1];
  private _statistics: IUserConversationStatistics[];

  @Input() set colorSchemaName(name: 'green-red' | 'blue' | 'material500' | 'contrast') {
    this.colorScheme = { domain: chartColorSchemas[name] };
  }

  @Input() set statistics(statistics: IUserConversationStatistics[]) {
    if (!statistics || !statistics.length) {
      this.multi = [];
      this.xAxisTicks = [];
      return;
    }

    this._statistics = statistics;
    this.xAxisTicks = [];

    if (statistics.length > this.xAxisTicksCount) {
      const xAxisStep = Math.round(statistics.length / this.xAxisTicksCount);
      const xAxisTicks = new Set<string>();
      for (let i = 0; i - xAxisStep < statistics.length; i += xAxisStep) {
        const stats = statistics[i] || statistics[statistics.length - 1];
        xAxisTicks.add(stats.date);
        this.xAxisTicks = Array.from(xAxisTicks.values());
      }
    } else {
      this.xAxisTicks = undefined;
    }

    if (this.metric === 'stars') {
      this.multi = this.generateStarsMetric();
      return;
    }

    if (this.metric === 'count-and-duration') {
      this.multi = this.generateCountAndDurationMetric();
      return;
    }

    const metricItems = this.metric === 'channel' ? this.channels : this.locales;
    const metricsArray = {};

    this.multi = metricItems.reduce((res, item) => {
      const name = this.metric === 'locale' ? this.localeUtil.getName(item) : item;
      const valueFn =
        this.metricField === 'conversationsDuration'
          ? this.getStatisticsAverageDurationByMetric
          : this.getStatisticsMetricFieldByMetric;
      const series = statistics.map(statistic => {
        const data = { name: statistic.date, value: valueFn(statistic, item) };

        if (!metricsArray[statistic.date]) {
          metricsArray[statistic.date] = {};
        }
        metricsArray[statistic.date][name] = data.value;

        return data;
      });

      res.push({ name, series });

      return res;
    }, []);

    const series = [];
    for (const [date, items] of Object.entries(metricsArray)) {
      const values = Object.values(items).sort();
      const index = Math.floor(values.length * 0.9) - 1;
      series.push({ name: date, value: values[index] });
    }
    this.multi.push({ name: this.ninthPercentileTitle, series });
  }

  @ViewChild('chart', { static: true }) chart: AdvancedPieChartComponent;

  constructor(private localeUtil: LocaleUtil, private translate: TranslateService) {
    const translateKeys = [
      'MY_STATISTICS.NUMBER_CONVERSATIONS_TITLE',
      'MY_STATISTICS.DURATION_CONVERSATIONS_TITLE',
      'MY_STATISTICS.NINTH_PERCENTILE_TITLE'
    ];
    this.translate.stream(translateKeys).subscribe(values => {
      this.countConversationsTitle = values['MY_STATISTICS.NUMBER_CONVERSATIONS_TITLE'];
      this.conversationsDurationTitle = values['MY_STATISTICS.DURATION_CONVERSATIONS_TITLE'];
      this.ninthPercentileTitle = values['MY_STATISTICS.NINTH_PERCENTILE_TITLE'];

      this.statistics = this._statistics;
    });
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => {
      this.chart.update();

      if (this.metric !== 'locale') {
        return;
      }
      this.statistics = this._statistics;
    });
  }

  onValueFormat = value => {
    if (this.metricField !== 'conversationsDuration') {
      return value;
    }

    return !value
      ? '0'
      : moment
          .duration(value, 'second')
          .locale(this.translate.currentLang)
          .humanize();
  };

  generateStarsMetric() {
    return this.stars.reduce((res, item) => {
      res.push({
        name: `${item} ⭐️`,
        series: this._statistics.map(statistic => ({
          name: statistic.date,
          value:
            (+statistic[`countConversationFeedback${item}Stars`] || 0) +
            (+statistic[`countConversations${item}Stars`] || 0)
        }))
      });
      return res;
    }, []);
  }

  generateCountAndDurationMetric() {
    const isDuration = this.metricField === 'conversationsDuration';
    let name = isDuration ? this.conversationsDurationTitle : this.countConversationsTitle;
    let valueFn = isDuration ? this.getStatisticsAverageDuration : this.getStatisticsMetricField;

    const multi = [
      {
        name,
        series: this._statistics.map(statistic => ({
          name: statistic.date,
          value: valueFn(statistic)
        }))
      }
    ];

    if (isDuration) {
      const series = [];
      for (const statistic of this._statistics) {
        const values = Object.values(statistic.conversationsDurations || []).sort();
        let value = 0;
        if (values.length) {
          const index = Math.floor(values.length * 0.9) - 1;
          value = values[index] || 0;
        }
        series.push({ name: statistic.date, value });
      }
      multi.push({ name: this.ninthPercentileTitle, series });
    }

    return multi;
  }

  getStatisticsMetricField = statistic => statistic[this.metricField] || 0;

  getStatisticsMetricFieldByMetric = (statistic, item) => statistic[`${this.metricField}${item}`] || 0;

  getStatisticsAverageDuration = statistic => {
    const res = Math.round((statistic.conversationsDuration || 0) / (statistic.countConversations || 0)) || 0;
    return !isFinite(res) ? 0 : res;
  };

  getStatisticsAverageDurationByMetric = (statistic, item) => {
    const res =
      Math.round((statistic[`conversationsDuration${item}`] || 0) / (statistic[`countConversations${item}`] || 0)) || 0;
    return !isFinite(res) ? 0 : res;
  };
}
