<div class="dashboard">
  <div class="dashboard_column">
    <!-- Conversations statistics -->
    <div class="dashboard_item">
      <app-conversation-statistics-widget [userId]="me?.cognitoId"></app-conversation-statistics-widget>
    </div>
  </div>

  <div class="dashboard_column">
    <!-- Team activity -->
    <div class="dashboard_item team-activity">
      <app-team-activity class="team-activity_dashboard" [teamId]="me?.teamId"></app-team-activity>
    </div>

    <!-- Battles -->
    <div class="dashboard_item challenges">
      <app-challenges-list
        (onAddChallengeWager)="addChallengeWager()"
        class="challenges-list_dashboard"
        [autoRefresh]="autoRefresh"
      ></app-challenges-list>
    </div>

    <!-- Challenge wagers -->
    <div class="dashboard_item challenge-wagers">
      <app-challenge-wagers-list
        class="challenge-wagers_dashboard"
        [showChallenges]="false"
        [autoRefresh]="autoRefresh"
      ></app-challenge-wagers-list>
    </div>
  </div>
</div>
