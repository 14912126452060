import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';

@Component({
  selector: 'app-ctn-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent {
  public user: any = {};
  public showPwdTxt: boolean;
  public errorMessage;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    private dialogRef: MatDialogRef<AddUserComponent>,
    private apiService: ApiService,
    private logService: LogService
  ) {}

  async addUser() {
    const data: any = await this.apiService.addUser(this.user).toPromise();
    this.logService.log('[AddUserComponent addUser:data]', data);

    if (data.statusCode === 200 && data.response && data.response.User) {
      this.dialogRef.close(data.response.User);
    } else {
      // data.code can be used to check "UsernameExistsException", "InvalidParameterException"
      this.errorMessage = data.message;
    }
  }
}
