import gql from 'graphql-tag';

export default gql`
  subscription subscribeToUserNotifications($userId: ID!) {
    subscribeToUserNotifications(userId: $userId) {
      __typename
      id
      userId
      isRead
      icon
      title
      description
    }
  }
`;
