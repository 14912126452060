<div class="s3-image-component">
  <img
    *ngIf="image"
    src="{{ image }}"
    class="s3-image"
    draggable="false"
    [class.s3-image__fit-contain]="fitContain"
    [class.s3-image__rounded]="isRounded"
    [style]="GetStyle(imageStyle)"
    alt="image"
  />
  <span
    *ngIf="!image && avatarName"
    class="s3-image__fake-avatar"
    [ngStyle]="{ backgroundColor: textToColor(avatarTextFromName(avatarName)) }"
  >
    {{ avatarTextFromName(avatarName).toUpperCase() }}
  </span>
</div>
