import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subscription, timer } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { LogService } from '@cation/core/services/log/log.service';
import { AppsyncConversationService } from '@cation/core/services/appsync/appsync-conversation.service';
import { AppsyncConversationQueueService } from '@cation/core/services/appsync/appsync-conversation-queue.service';
import { enumToArray, prepareConversation } from '@cation/core/util/common-util';
import { getConversationsInQueueQuery as ConvosInQueueQuery } from '@cation/core/graphql/operation-result-types';
import { addConversationInQueue, addConversationsInQueue } from '@cation/core/util/chat-helper';
import { LocaleUtil } from '@cation/core/util/locale-util';
import { Channel, classChannelIcon } from '@cation/core/enums/channel.enum';
import { ApiService } from '@cation/core/services/api/api.service';
import { chartColorSchemas } from '@cation/core/constants/chart-color-schema';
import { mapSentimentToIcon } from '@cation/core/constants/sentiments';
import ConversationQueue from '@cation/core/types/conversationQueue';
import Conversation from '@cation/core/types/conversation';
import { listFadeInOut } from '@cation/core/animations';

@Component({
  selector: 'ctn-conversation-queue',
  templateUrl: './conversation-queue.component.html',
  styleUrls: ['./conversation-queue.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [listFadeInOut],
})
export class ConversationQueueComponent implements OnInit, OnDestroy {
  isLoading = false;

  colorScheme = { domain: chartColorSchemas['blue'] };

  _conversationQueues: ConversationQueue[] = [];
  count = 0;
  localeMetrics = [];
  channelMetrics = [];
  keywordsMetrics = [];

  private channels = enumToArray(Channel);
  private locales = ['en_IE', 'en_GB', 'it_IT', 'es_ES', 'fr_FR', 'de_DE', 'pl_PL'];
  private keywords = ['Any'];
  selectedTab = new UntypedFormControl(0);

  _conversations: (Conversation & { waitingTime: string })[] = [];
  nextToken;
  observedQuery;

  classIcon = classChannelIcon;
  mapSentimentToIcon = mapSentimentToIcon;

  subscription: Subscription;
  everySecond: Observable<number> = timer(0, 1000);

  constructor(
    private appsyncConversationQueueService: AppsyncConversationQueueService,
    private appsyncConversationService: AppsyncConversationService,
    private translate: TranslateService,
    private apiService: ApiService,
    private logService: LogService,
    public localeUtil: LocaleUtil
  ) {}

  async ngOnInit() {
    await this.getAllKeywords();
    this.loadConversationQueues();
    this.getConversationsInQueue();

    this.translate.onLangChange.subscribe(() => (this.conversationQueues = this._conversationQueues));

    this.subscription = this.everySecond.subscribe(() => {
      this.conversations = this._conversations;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  get conversationQueues() {
    return this._conversationQueues;
  }

  get conversations() {
    return this._conversations;
  }

  set conversations(conversations) {
    this._conversations = conversations.map(this.calcConversationWaitingTime);
  }

  set conversationQueues(conversationQueues: ConversationQueue[]) {
    this._conversationQueues = conversationQueues;
    const localeMetrics = {};
    const channelMetrics = {};
    const keywordsMetrics = {};
    this.count = 0;
    for (const item of conversationQueues) {
      const [locale, channel, ...keywords] = item.key.split('-');

      this.count += item.count;
      _.set(localeMetrics, locale, (localeMetrics[locale] || 0) + item.count);
      _.set(channelMetrics, channel, (channelMetrics[channel] || 0) + item.count);
      _.map(keywords, (k) => _.set(keywordsMetrics, k, (keywordsMetrics[k] || 0) + item.count));
    }

    this.localeMetrics = this.prepareMetricsByType(this.locales, localeMetrics, (l) => this.localeUtil.getName(l));
    this.channelMetrics = this.prepareMetricsByType(this.channels, channelMetrics);
    this.keywordsMetrics = this.prepareMetricsByType(this.keywords, keywordsMetrics);
  }

  public trackConversation(index, conversation) {
    return conversation ? conversation.id : undefined;
  }

  private prepareMetricsByType(items, values, prepareName = (name) => name) {
    return items.map((item) => ({ name: prepareName(item), value: values[item] || 0 }));
  }

  private async getAllKeywords() {
    this.isLoading = true;
    try {
      const data = await this.apiService.getKeywords().toPromise();

      this.logService.log('[getAllKeywords data]', data);

      this.keywords = ['Any', ...data.map((e) => e.PrimaryTopic)];
    } catch (e) {
      this.logService.error('[getAllKeywords error]', e);
    }
    this.isLoading = false;
  }

  private async loadConversationQueues() {
    this.isLoading = true;
    const observerOrNext = async ({ data }) => {
      this.logService.log('[loadConversationQueues subscribe]', data);
      if (!data) {
        return this.logService.log('[loadConversationQueues - no data]');
      }

      this.conversationQueues = data.getConversationQueues;

      this.isLoading = false;
    };

    await this.appsyncConversationQueueService.getConversationQueues(observerOrNext);

    const realtimeResults = ({ data: { subscribeToUpdateConversationQueueCount: cqc } }) => {
      this.logService.log('[subscribeToUpdateConversationQueueCount realtimeResults] complete', cqc);

      const mewArray = this.conversationQueues;
      for (const i in mewArray) {
        if (mewArray[i].key === cqc.key) {
          mewArray[i] = cqc;
          break;
        }
      }
      this.conversationQueues = mewArray;
    };

    await this.appsyncConversationQueueService.subscribeToUpdateConversationQueueCount({ realtimeResults });
  }

  private async getConversationsInQueue() {
    const observerOrNext = async ({ data }) => {
      this.logService.log('[getConversationsInQueue data]', data);
      if (!data || !data.getConversationsInQueue) {
        return this.logService.log('[getConversationsInQueue: no data]');
      }

      this.conversations = data.getConversationsInQueue.conversations.map(prepareConversation);

      this.nextToken = data.getConversationsInQueue.nextToken;
      this.logService.log('[getConversationsInQueue conversations]', this.conversations);
    };

    const updateQuery = (prev: ConvosInQueueQuery, { subscriptionData }) => {
      const {
        data: { subscribeToCreateConversationByQueue: conv },
      } = subscriptionData;
      this.logService.log('[subscribeToCreateConversationByQueue updateQuery]', conv);

      return addConversationInQueue(prev, conv);
    };

    const data = await this.appsyncConversationService.getConversationsInQueue(observerOrNext, updateQuery);
    this.observedQuery = data.observedQuery;

    const realtimeResults = ({ data: { subscribeToUpdateConversationQueueStatus: c } }) => {
      this.logService.log('[subscribeToUpdateConversationQueueStatus realtimeResults] complete', c);
      if (c.isQueued === 'false') {
        this.conversations = this.conversations.filter((conv) => conv.id !== c.id);
      }
    };

    await this.appsyncConversationService.subscribeToUpdateConversationQueueStatus({ realtimeResults });
  }

  public loadMoreConversations(event = null) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    const updateQuery = (prev, options) => {
      const { fetchMoreResult } = options;

      if (!fetchMoreResult) {
        return prev;
      }

      const moreConversations = fetchMoreResult.getConversationsInQueue.conversations.map(prepareConversation);
      const nextToken = fetchMoreResult.getConversationsInQueue.nextToken;
      this.logService.log('[loadMoreConversations]', moreConversations);

      const _res = addConversationsInQueue(prev as ConvosInQueueQuery, moreConversations, nextToken);
      this.nextToken = fetchMoreResult.getConversationsInQueue.nextToken;

      return _res;
    };

    return this.appsyncConversationService.loadMoreConversations(this.observedQuery, this.nextToken, { updateQuery });
  }

  private calcConversationWaitingTime(conversation: Conversation): Conversation & { waitingTime: string } {
    return {
      ...conversation,
      waitingTime: moment
        .utc(moment.duration(moment().diff(+conversation.createdAt), 'millisecond').asMilliseconds())
        .format('HH:mm:ss'),
    };
  }
}
