<div class="wrapper">
  <div class="content">
    <h1 class="content__title">
      {{ data?.title | translate }}
    </h1>
    <p class="content__description">
      {{ data?.description | translate }}
    </p>
  </div>

  <div>
    <button type="button" class="btn-cancel action-button" (click)="confirmEvent.emit(true)">
      {{ 'BUTTON.YES' | translate }}
    </button>
    <div class="btn-separator"></div>
    <button type="button" class="btn-save action-button" (click)="confirmEvent.emit(false)">
      {{ 'BUTTON.NO' | translate }}
    </button>
  </div>
</div>
