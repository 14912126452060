import gql from 'graphql-tag';

export default gql`
  mutation updateProfile($keywords: [String!]!, $locales: [String!]!) {
    updateProfile(keywords: $keywords, locales: $locales) {
      __typename
      cognitoId
      username
      registered
      userData
      keywords
      locales
    }
  }
`;
