import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { EMPTY, from } from 'rxjs';
import { ObservableQuery } from 'apollo-client';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ICMS } from '@cation/core/interfaces/cms';
import { CmsService } from '@cation/core/services/cms';
import { prepareConversation } from '@cation/core/util/common-util';
import { LocaleUtil } from '@cation/core/util/locale-util';
import { CMS_TYPE } from '@cation/core/enums/cms-type.enum';
import { LogService } from '@cation/core/services/log/log.service';
import { mapSentimentToIcon } from '@cation/core/constants/sentiments';
import { getConversationHistory } from '@cation/core/util/shared-functions';
import { AppsyncService } from '@cation/core/services/appsync/appsync.service';
import { SharedService } from '@cation/core/services/shared/shared.service';
import Conversation from '@cation/core/types/conversation';
import * as queries from '@cation/core/graphql/queries';
import Message from '@cation/core/types/message';
import Note from '@cation/core/types/note';
import {
  getConversationMessagesQuery as MessagesQuery,
  getConversationNotesQuery as NotesQuery,
} from '@cation/core/graphql/operation-result-types';
import { constants, pushMessages, pushNotes } from '@cation/core/util/chat-helper';

interface IInfinityScrollData<ItemType, ObservableQueryType> {
  items: ItemType[];
  nextToken: string;
  observedQuery: ObservableQuery<ObservableQueryType>;
  subscription: ZenObservable.Subscription;
  fetchingMore: boolean;
  completedFetching: boolean;
}

@Component({
  selector: 'app-view-customer-history-dialog',
  templateUrl: './view-customer-history-dialog.component.html',
  styleUrls: ['./view-customer-history-dialog.component.scss'],
})
export class ViewCustomerHistoryDialogComponent implements OnDestroy {
  loaderId = 'view-customer-history-dialog-loader';

  _conversation: Conversation;

  private defaultInfinityScrollData = {
    items: [],
    nextToken: null,
    observedQuery: null,
    subscription: null,
    fetchingMore: false,
    completedFetching: false,
  };

  messagesData: IInfinityScrollData<Message, MessagesQuery> = { ...this.defaultInfinityScrollData };
  notesData: IInfinityScrollData<Note, NotesQuery> = { ...this.defaultInfinityScrollData };

  lastMessage: Message;
  firstMessage: Message;

  _conversationHistory = [];
  runningLine = '';
  errorMessage = '';

  isHistoryLoading = false;

  mapSentimentToIcon = mapSentimentToIcon;

  @ViewChild('scrollMe') myScrollContainer: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { conversationId: string },
    public dialogRef: MatDialogRef<ViewCustomerHistoryDialogComponent>,
    private ngxLoader: NgxUiLoaderService,
    private logService: LogService,
    private cmsService: CmsService,
    private appsync: AppsyncService,
    private sharedService: SharedService,
    public localeUtil: LocaleUtil
  ) {
    this.logService.debug('[ViewCustomerHistoryDialogComponent]', this.data);
    this.loadMoreNotes = this.loadMoreNotes.bind(this);
    this.loadMoreMessages = this.loadMoreMessages.bind(this);
    this.getConversationById();
  }

  ngOnDestroy(): void {
    if (this.messagesData.subscription) {
      this.messagesData.subscription.unsubscribe();
    }
    if (this.notesData.subscription) {
      this.notesData.subscription.unsubscribe();
    }
  }

  private getConversationById() {
    this.ngxLoader.startLoader(this.loaderId);
    this.logService.log('[ViewCustomerHistoryDialogComponent getConversationById]');
    return this.appsync.hc().then((client) => {
      return client
        .query({
          query: queries.getConversationById,
          fetchPolicy: 'no-cache',
          variables: { id: this.data.conversationId },
        })
        .then(({ data: { getConversationById: conversation } }) => {
          if (!conversation) {
            this.errorMessage = '[Conversation Not Found]';
            throw new Error('[Conversation Not Found]');
          }
          this.logService.log('[ViewCustomerHistoryDialogComponent conversation]', conversation);
          this.conversation = prepareConversation(conversation);
          conversation.cms.map((cms) => {
            const cmsInstance = this.cmsService.createCMS(cms.type as CMS_TYPE, { lazyLoad: true, id: cms.id });
            const key = `${cms.type}:${cms.id}`;

            this.sharedService.addCmsSystem(key, cmsInstance);
          });
        })
        .then(() => this.getConversationHistory())
        .then(() => this.loadMessages())
        .then(() => this.loadNotes())
        .catch((e) => this.logService.error('[ViewCustomerHistoryDialogComponent getConversationById]', e.message))
        .finally(() => this.ngxLoader.stopLoader(this.loaderId));
    });
  }

  private getConversationHistory() {
    if (this.conversation.history && this.conversation.history.length) {
      this.conversationHistory = this.conversation.history;
      return undefined;
    }

    this.isHistoryLoading = true;

    return getConversationHistory(this.conversation.botSessionId)
      .then((data) => {
        this.logService.log('[getConversationHistory]', data);
        this.conversationHistory = data.messages;
      })
      .catch((error) => {
        this.logService.error('[getConversationHistory Error]', error);
      })
      .then(() => (this.isHistoryLoading = false));
  }

  set conversation(conversation: Conversation) {
    this.logService.debug('setConversation - ', conversation);

    if (!conversation) {
      this.runningLine = '';
      return;
    }

    this._conversation = conversation;
    this._conversation.cms = _.orderBy(conversation.cms, 'isCore', 'desc');
  }

  get conversation(): Conversation {
    return this._conversation;
  }

  get conversationHistory() {
    return this._conversationHistory;
  }

  set conversationHistory(messages) {
    const primaryTopics = messages.reduce((res, value, key) => {
      this._conversationHistory.push({
        ...value,
        isSent: true,
        content: value.message,
        id: `history-${key}`,
        createdAt: `${value.timestamp}_`,
      });
      if (value.primaryTopic) {
        res.push(value.primaryTopic);
      }
      return res;
    }, []);

    this.runningLine = _.uniq(primaryTopics).join(', ');

    if (this._conversation.mailData && this._conversation.mailData.subject) {
      this.runningLine = 'Subject: ' + this._conversation.mailData.subject;
    }

    this.runningLine = this.runningLine || '-';
  }

  private scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  public fromAgent(message): boolean {
    return message.sender !== this.conversation.createdBy;
  }

  public trackItem(index, item) {
    return item ? item.id : undefined;
  }

  public getCmsInstance({ id, type }): ICMS {
    return this.sharedService.cmsSystems.get(`${type}:${id}`);
  }

  public messageAdded(isFirst = false, message: Message) {
    if (isFirst) {
      if (!this.firstMessage) {
        this.firstMessage = message;
      } else if (this.firstMessage.id !== message.id) {
        setTimeout(() => {
          this.messagesData.completedFetching = this.messagesData.fetchingMore;
          this.messagesData.fetchingMore = false;
        });
      }
    } else {
      if (!this.lastMessage || this.lastMessage.id !== message.id) {
        setTimeout(() => this.scrollToBottom());
      }
      this.lastMessage = message;
    }
  }

  /**
   * Messages
   */

  private loadMessages() {
    if (this.messagesData.items.length) {
      return undefined;
    }
    const innerObservable = this.appsync.hc().then((client) => {
      this.logService.log('[loadMessages]', this.conversation.id);
      const options = {
        query: queries.getConversationMessages,
        fetchPolicy: 'cache-and-network',
        variables: {
          conversationId: this.conversation.id,
          first: constants.messageFirst,
        },
      };

      const observable: ObservableQuery<MessagesQuery> = client.watchQuery(options);

      const subscription = observable.subscribe(
        ({ data }) => {
          this.logService.log('[loadMessages subscribe]', data);
          if (!data) {
            return this.logService.log('[loadMessages - no data]');
          }

          this.messagesData = {
            ...this.messagesData,
            items: data.allMessageConnection.messages.sort((m1, m2) => (m1.createdAt < m2.createdAt ? -1 : 1)),
            nextToken: data.allMessageConnection.nextToken,
          };

          this.logService.log('[loadMessages]: nextToken is now', data.allMessageConnection.nextToken ? 'set' : 'null');
        },
        (error) => {
          this.logService.error('[loadMessages subscribe]', error);
        }
      );

      this.messagesData = { ...this.messagesData, observedQuery: observable, subscription };

      return observable;
    });
    return from(innerObservable);
  }

  public loadMoreMessages(event = null) {
    this.logService.log('[loadMoreMessages]: nextToken', this.messagesData.nextToken ? 'set' : 'null');
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!this.messagesData.nextToken) {
      return EMPTY;
    }
    const result = this.messagesData.observedQuery.fetchMore({
      variables: { after: this.messagesData.nextToken },
      updateQuery: (prev, { fetchMoreResult }) => {
        this.logService.log('[loadMoreMessages prev fetchMoreResult]', prev, fetchMoreResult);
        if (!fetchMoreResult) {
          return prev;
        }
        const _res = pushMessages(
          prev as MessagesQuery,
          fetchMoreResult.allMessageConnection.messages,
          fetchMoreResult.allMessageConnection.nextToken
        );
        this.messagesData = {
          ...this.messagesData,
          nextToken: fetchMoreResult.allMessageConnection.nextToken,
          completedFetching: false,
          fetchingMore: true,
        };
        return _res;
      },
    });
    return from(result);
  }

  /**
   * Notes
   */

  private loadNotes() {
    if (this.notesData.items.length) {
      return undefined;
    }
    const innerObservable = this.appsync.hc().then((client) => {
      this.logService.log('[loadNotes]', this.conversation.id);
      const options = {
        query: queries.getConversationNotes,
        fetchPolicy: 'cache-and-network',
        variables: {
          conversationId: this.conversation.id,
          first: constants.notesFirst,
        },
      };

      this.logService.log('[loadNotes options]', options);

      const observable: ObservableQuery<NotesQuery> = client.watchQuery(options);

      const subscription = observable.subscribe(
        ({ data }) => {
          this.logService.log('[loadNotes subscribe]', data);
          if (!data) {
            return this.logService.log('[loadNotes - no data]');
          }

          this.notesData = {
            ...this.notesData,
            items: data.getConversationNotes.notes,
            nextToken: data.getConversationNotes.nextToken,
          };

          this.logService.log('[loadNotes nextToken]', data.getConversationNotes.nextToken ? 'set' : 'null');
        },
        (error) => {
          this.logService.error('[loadNotes subscribe]', error);
        }
      );

      this.notesData = { ...this.notesData, observedQuery: observable, subscription };

      return observable;
    });
    return from(innerObservable);
  }

  public loadMoreNotes(event = null) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!this.notesData.nextToken) {
      return EMPTY;
    }

    this.notesData = { ...this.notesData, completedFetching: false, fetchingMore: true };

    const result = this.notesData.observedQuery.fetchMore({
      variables: { after: this.notesData.nextToken },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        const _res = pushNotes(
          prev as NotesQuery,
          fetchMoreResult.getConversationNotes.notes,
          fetchMoreResult.getConversationNotes.nextToken
        );
        this.notesData = {
          ...this.notesData,
          nextToken: fetchMoreResult.getConversationNotes.nextToken,
          completedFetching: true,
          fetchingMore: false,
        };
        return _res;
      },
    });
    return from(result);
  }
}
