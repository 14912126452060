import gql from 'graphql-tag';

export default gql`
  mutation createConversation($id: ID!, $name: String!, $subject: ConversationSubject!, $createdAt: String) {
    createConversation(id: $id, name: $name, subject: $subject, createdAt: $createdAt) {
      __typename
      id
      name
      subject
      createdAt
    }
  }
`;
