import { UserListItemModel } from '@cation/core/models/index';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import User from '@cation/core/types/user';
import Team from '@cation/core/types/team';

type Agent = User & { team: Team };

@Component({
  selector: 'app-ctn-review-user',
  templateUrl: './review-user.component.html',
  styleUrls: ['./review-user.component.scss']
})
export class ReviewUserComponent implements OnInit {
  public selectedTab = new UntypedFormControl(0);
  public user;
  public serverAgents: Agent[] = [];
  public listAgents: UserListItemModel[] = [];
  public achievements = [];
  public isLoading = true;
  public isList = true;
  public searchValue = '';

  constructor(
    public auth: AuthHelper,
    private logService: LogService,
    private authHelper: AuthHelper,
    private apiService: ApiService
  ) {}

  async ngOnInit() {
    await this.getAgents();
  }

  async getAgents() {
    try {
      const body = Object.assign(
        { isOnlineOnly: false },
        {},
        { cognitoId: this.authHelper.userProfile.cognitoId },
        { teamLeadId: this.auth.userProfile.cognitoId }
      );
      this.setAgents(await this.apiService.getListAgents(body));
      this.isLoading = false;
    } catch (e) {
      this.logService.error('searchAgents:error', e);
    }
  }

  private setAgents(serverAgents) {
    this.serverAgents = serverAgents;
    this.logService.log('[AgentInputAutocompleteComponent searchAgents:agents]', serverAgents);

    this.listAgents = serverAgents.map((data: Agent) => {
      return {
        uid: data.cognitoId,
        avatarUrl: data.avatarUrl,
        username: data.username,
        nickname: data.userData && data.userData.nickname ? data.userData.nickname : '',
        roles: data.roles,
        teamName: data.team && data.team.name ? data.team.name : '',
        teamDescription: data.team && data.team.description ? data.team.description : '',
        teamImageUrl: data.team && data.team.icon ? data.team.icon : ''
      };
    });
  }

  toggleView(user: UserListItemModel) {
    if (user) {
      const selectedUser = this.serverAgents.find((serverAgent: Agent) => serverAgent.cognitoId === user.uid);
      if (selectedUser) {
        this.logService.log('onSelectUser', selectedUser);
        this.user = selectedUser;
        this.isList = !this.isList;
      }
    } else {
      this.isList = !this.isList;
    }
  }

  onSearch($event) {
    this.searchValue = $event;
  }
}
