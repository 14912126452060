<div
  [internalChatScroll]="loadMoreChats"
  [completedFetching]="completedFetching"
  internalChatScrollDirection="down"
  class="internal-chats-list"
>
  <ul>
    <li *ngFor="let chat of chats">
      <internal-chat-info
        [chat]="chat"
        (click)="selectChat($event, chat)"
        [unreadMessages]="countUnreadMessages[chat.chatId]"
      ></internal-chat-info>
    </li>
  </ul>
</div>
