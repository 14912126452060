import { Injectable } from '@angular/core';
import { FetchMoreOptions, FetchMoreQueryOptions, FetchPolicy } from 'apollo-client';
import * as _ from 'lodash';
import {
  constants,
  deleteConversation,
  updateConversationInQueue,
  addCmsIntoConversation,
  updateUserConversations,
  deleteCmsFromConversation,
} from '@cation/core/util/chat-helper';
import { AppsyncOperationService } from '@cation/core/services/appsync/appsync-operation.service';
import * as subscriptions from '@cation/core/graphql/subscriptions';
import * as mutations from '@cation/core/graphql/mutations';
import Conversation from '@cation/core/types/conversation';
import * as queries from '@cation/core/graphql/queries';
import {
  getUserConversationConnectionByCognitoIdQuery as UserConvosQuery,
  getUserConversationConnectionThroughUserQuery as ConvosQuery,
} from '@cation/core/graphql/operation-result-types';
import { ISubscriptionOptions } from './types';

@Injectable({
  providedIn: 'root',
})
export class AppsyncConversationService extends AppsyncOperationService {
  subscribeToUpdateUserConversations(
    conversation: Conversation,
    params: ISubscriptionOptions
  ): Promise<ZenObservable.Subscription> {
    const options = _.merge({ onComplete: () => null, onError: () => this.forceReloadApp() }, params);

    if (conversation.status === 'Closed') {
      return undefined;
    }

    this.logService.log('[SubscriptionService subscribeToUpdateUserConversations]');

    return this.subscription({
      query: subscriptions.subscribeToUpdateUserConversations,
      variables: { conversationId: conversation.id },
      realtimeResults: options.realtimeResults,
      onComplete: options.onComplete,
      onError: options.onError,
    });
  }

  subscribeToUpdateConversationQueueStatus(params: ISubscriptionOptions): Promise<ZenObservable.Subscription> {
    const realtimeResults = ({ data: { subscribeToUpdateConversationQueueStatus: cq } }) => {
      return this.appsync.hc().then((client) => {
        const _options = {
          query: queries.getConversationsInQueue,
          variables: { first: constants.conversationFirst },
          fetchPolicy: 'cache-and-network' as FetchPolicy,
        };

        const prev = client.readQuery(_options);
        const data = updateConversationInQueue(prev, cq);

        params.realtimeResults({ data: { subscribeToUpdateConversationQueueStatus: cq } });

        return client.writeQuery({ ..._options, data });
      });
    };

    const options = _.merge({ onComplete: () => null, onError: () => this.forceReloadApp() }, params);
    this.logService.log('[AppsyncConversationService subscribeToUpdateConversationQueueStatus]');

    return this.subscription({
      query: subscriptions.subscribeToUpdateConversationQueueStatus,
      realtimeResults: realtimeResults,
      onComplete: options.onComplete,
      onError: options.onError,
    });
  }

  subscribeToNewUCsByConversation(conversationId, params: ISubscriptionOptions): Promise<ZenObservable.Subscription> {
    const options = _.merge({ onComplete: () => null, onError: () => this.forceReloadApp() }, params);

    this.logService.log('[AppsyncConversationService subscribeToNewUCsByConversation]');

    return this.subscription({
      query: subscriptions.subscribeToNewUCsByConversation,
      variables: { conversationId },
      realtimeResults: params.realtimeResults,
      onComplete: options.onComplete,
      onError: options.onError,
    });
  }

  getConversationById(conversationId) {
    return this.query({
      query: queries.getConversationById,
      fetchPolicy: 'no-cache',
      variables: { id: conversationId },
    });
  }

  getAllConversations(userId, observerOrNext, updateQuery) {
    const watchQueryOptions = {
      query: queries.getUserConversationsConnection,
      variables: { first: constants.conversationFirst },
      fetchPolicy: 'cache-and-network' as FetchPolicy,
    };

    const watchQuerySubscriptionOptions = { observerOrNext };

    const subscribeToMoreOptions = _.merge(
      {
        onError: () => {
          console.log('ERROR1:getAllConversations');
          this.forceReloadApp();
        },
      },
      {
        document: subscriptions.subscribeToNewUserConversations,
        variables: { userId },
        updateQuery,
      }
    );

    return this.loadAndSubscribeMore<ConvosQuery>({
      watchQueryOptions,
      watchQuerySubscriptionOptions,
      subscribeToMoreOptions,
    });
  }

  getConversationsInQueue(observerOrNext, updateQuery) {
    const watchQueryOptions = {
      query: queries.getConversationsInQueue,
      variables: { first: constants.conversationFirst },
      fetchPolicy: 'cache-and-network' as FetchPolicy,
    };

    const watchQuerySubscriptionOptions = { observerOrNext };

    const subscribeToMoreOptions = _.merge(
      { onError: () => this.forceReloadApp() },
      {
        document: subscriptions.subscribeToCreateConversationByQueue,
        variables: { isQueued: 'true' },
        updateQuery,
      }
    );

    return this.loadAndSubscribeMore<ConvosQuery>({
      watchQueryOptions,
      watchQuerySubscriptionOptions,
      subscribeToMoreOptions,
    });
  }

  getAllConversationsByUser(userId, observerOrNext, updateQuery) {
    const watchQueryOptions = {
      query: queries.getUserConversationsConnectionsByCognitoId,
      variables: { first: constants.conversationFirst, cognitoId: userId },
      fetchPolicy: 'cache-and-network' as FetchPolicy,
    };

    const watchQuerySubscriptionOptions = { observerOrNext };

    const subscribeToMoreOptions = _.merge(
      {
        onError: () => {
          console.log('ERROR2:getAllConversations');
          this.forceReloadApp();
        },
      },
      {
        document: subscriptions.subscribeToNewUserConversations,
        variables: { userId },
        updateQuery,
      }
    );

    return this.loadAndSubscribeMore<UserConvosQuery>({
      watchQueryOptions,
      watchQuerySubscriptionOptions,
      subscribeToMoreOptions,
    });
  }

  loadMoreConversations(observedQuery, nextToken: string, fetchMoreOptions) {
    return this.loadMore(observedQuery, nextToken, fetchMoreOptions);
  }

  addCmsIntoConversation(newCms) {
    return this.appsync.hc().then((client) => {
      const _options = {
        query: queries.getUserConversationsConnection,
        variables: { first: constants.conversationFirst },
      };

      const prev = client.readQuery(_options);
      const data = addCmsIntoConversation(prev, newCms);

      return client.writeQuery({ ..._options, data });
    });
  }

  deleteCmsFromConversation(cms) {
    return this.appsync.hc().then((client) => {
      const _options = {
        query: queries.getUserConversationsConnection,
        variables: { first: constants.conversationFirst },
      };
      const prev = client.readQuery(_options);
      const data = deleteCmsFromConversation(prev, cms);

      return client.writeQuery({ ..._options, data });
    });
  }

  async detachCms(conversationId, createdAt) {
    const input = { conversationId, createdAt };
    this.logService.log('[AppsyncOperationService detachCms', { conversationId, createdAt });
    const options = {
      mutation: mutations.deleteConversationCms,
      variables: { input },
    };
    const {
      data: { deleteConversationCms },
    } = await this.mutate<{ deleteConversationCms: any }>(options);
    this.deleteCmsFromConversation(deleteConversationCms);
  }

  closeConversation(conversationId, userId) {
    this.logService.log('[AppsyncOperationService closeConversation]', { conversationId, userId });

    const options = {
      mutation: mutations.closeConversation,
      variables: { id: conversationId, userId },
      update: (proxy, { data: { closeConversation: conv } }) => {
        this.logService.log('[AppsyncOperationService closeConversation:update]:', conv);
        const _options = {
          query: queries.getUserConversationsConnection,
          variables: { first: constants.conversationFirst },
        };

        const prev = proxy.readQuery(_options);
        const data = deleteConversation(prev, conv.id);
        proxy.writeQuery({ ..._options, data });
      },
    };
    return this.mutate(options);
  }

  disconnectConversation(conversationId, userId) {
    this.logService.log('[AppsyncOperationService disconnectConversation]', { conversationId, userId });

    const options = {
      mutation: mutations.disconnectConversation,
      variables: { id: conversationId, userId },
      update: (proxy, { data: { disconnectConversation: conv } }) => {
        this.logService.log('[AppsyncOperationService disconnectConversation:update]:', conv);
        const _options = {
          query: queries.getUserConversationsConnection,
          variables: { first: constants.conversationFirst },
        };

        const prev = proxy.readQuery(_options);
        const data = deleteConversation(prev, conv.id);
        proxy.writeQuery({ ..._options, data });
      },
    };
    return this.mutate(options);
  }

  deleteConversationLocal(conversationId) {
    return this.appsync.hc().then((client) => {
      const _options = {
        query: queries.getUserConversationsConnection,
        variables: { first: constants.conversationFirst },
      };

      const prev = client.readQuery(_options);

      const data = deleteConversation(prev, conversationId);

      client.writeQuery({ ..._options, data });

      return data;
    });
  }

  updateUserConversations(conversationId, userId, typing = false, connected = false) {
    this.logService.log('[AppsyncConversationService disconnectConversation]', { conversationId, userId });

    const input = { conversationId, userId, typing, connected };

    const options = {
      mutation: mutations.updateUserConversations,
      variables: { input },
      update: (proxy, { data: { updateUserConversations: uc } }) => {
        this.logService.log('[AppsyncConversationService disconnectConversation:update]:', uc);
        const _options = {
          query: queries.getUserConversationsConnection,
          variables: { first: constants.conversationFirst },
        };

        const prev = proxy.readQuery(_options);
        const data = updateUserConversations(prev, uc);
        proxy.writeQuery({ ..._options, data });
      },
    };
    return this.mutate(options);
  }
}
