<mat-nav-list>
  <h6 class="text-center">
    {{ 'CONVERSATION_NOTE_VIEW.BOTTOM_SHEET_FIND_SIMILAR_CASE.TITLE' | translate }}
  </h6>

  <a *ngFor="let filter of filters" mat-list-item (click)="findSimilarCase(filter.createdAt)">
    <span mat-line>{{ filter.title }}</span>
  </a>
  <a mat-list-item (click)="picker.open()">
    <mat-form-field class="w-100">
      <input
        matInput
        [placeholder]="'CONVERSATION_NOTE_VIEW.BOTTOM_SHEET_FIND_SIMILAR_CASE.DATE_PICKER_PLACEHOLDER' | translate"
        [satDatepicker]="picker"
        [(ngModel)]="date"
        (dateChange)="findSimilarCaseCustomRange()"
      />
      <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
      <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
    </mat-form-field>
  </a>
</mat-nav-list>
