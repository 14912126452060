import { Component, Inject } from '@angular/core';
import * as moment from 'moment';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { LogService } from '@cation/core/services/log/log.service';

@Component({
  selector: 'app-bottom-sheet-action-find-similar-case',
  templateUrl: './bottom-sheet-action-find-similar-case.component.html'
})
export class BottomSheetActionFindSimilarCaseComponent {
  date;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { message: string },
    private bottomSheetRef: MatBottomSheetRef<BottomSheetActionFindSimilarCaseComponent>,
    private logService: LogService,
    private sharedService: SharedService
  ) {}

  filters = [
    {
      title: 'All time',
      createdAt: null
    },
    {
      title: '6 months',
      createdAt: {
        from: moment()
          .add(-6, 'months')
          .format(),
        to: moment().format()
      }
    },
    {
      title: '1 month (30 days)',
      createdAt: {
        from: moment()
          .add(-30, 'days')
          .format(),
        to: moment().format()
      }
    },
    {
      title: '7 days',
      createdAt: {
        from: moment()
          .add(-7, 'days')
          .format(),
        to: moment().format()
      }
    }
  ];

  findSimilarCaseCustomRange(): void {
    this.findSimilarCase({
      from: moment(this.date.begin).format(),
      to: moment(this.date.end).format()
    });
  }

  findSimilarCase(createdAt) {
    this.logService.log('searchSimilarCases');

    this.sharedService.searchSimilarCases(this.data.message, createdAt);

    this.bottomSheetRef.dismiss();
  }
}
