import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { fadeInOut } from '@cation/core/animations';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { Locale as LocaleConst } from '@cation/core/enums/locale';
import { ApiHook } from '@cation/core/services/api/api-hook';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { LocaleUtil } from '@cation/core/util/locale-util';
import { ISettingsLevel } from '@cation/core/types/api';
import IAchievement from '@cation/core/types/achievement';
import User from '@cation/core/types/user';
import { ChallengeWagersListComponent } from '../challenge-wagers-list/challenge-wagers-list.component';

interface ICapability {
  icon: string;
  name: string;
  isAvailable: boolean;
}

@Component({
  selector: 'ctn-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInOut]
})
export class ProfileComponent implements OnInit {
  selectedIndex = 0;

  private locales = LocaleConst;
  primaryTopics: { PrimaryTopic: string; PrimaryTopic_icon: string }[] = [];
  localeCapabilities: ICapability[] = [];
  keywordsCapabilities: ICapability[] = [];
  userProfile: User;

  achievementProgress = {};
  achievements: [IAchievement[], IAchievement[]] = [[], []];
  level: ISettingsLevel;
  nextLevel: ISettingsLevel;
  points: number;

  public isLoading = false;
  public errorMessage;

  @ViewChild(ChallengeWagersListComponent, { static: true }) challengeWagersList: ChallengeWagersListComponent;

  constructor(
    private apiService: ApiService,
    private logService: LogService,
    private authHelper: AuthHelper,
    private localeUtil: LocaleUtil,
    private dialog: MatDialog,
    public apiHook: ApiHook
  ) {}

  ngOnInit() {
    this.authHelper.currentUserProfile$.subscribe(data => {
      this.userProfile = _.cloneDeep(data);
      this.updateCapabilities();
    });

    this.getRewards();
    this.getAllKeywords();
  }

  updateCapabilities() {
    this.updateLocalesCapabilities();
    this.updateKeywordsCapabilities();
  }

  updateLocalesCapabilities() {
    const items = [];
    for (const locale of this.locales) {
      const isAvailable = (this.userProfile.locales || []).includes(locale.code);
      const code = locale.code.split('_')[0];
      items.push({
        name: 'LOCALE.' + this.localeUtil.getName(locale.code),
        icon: '../../../assets/img/' + code + '.svg',
        isAvailable
      });
    }
    this.localeCapabilities = items;
  }

  updateKeywordsCapabilities() {
    const items = [];
    for (const { PrimaryTopic, PrimaryTopic_icon } of this.primaryTopics) {
      const isAvailable = this.userProfile.keywords.includes(PrimaryTopic);
      items.push({
        name: PrimaryTopic,
        icon: PrimaryTopic_icon,
        isAvailable
      });
    }
    this.keywordsCapabilities = items;
  }

  async getAllKeywords() {
    this.isLoading = true;
    try {
      const data: any = await this.apiService.getKeywords().toPromise();
      this.primaryTopics = data;
      this.updateKeywordsCapabilities();
    } catch (e) {
      this.logService.error('[ProfileComponent getAllKeywords:error]', e);
    }
    this.isLoading = false;
  }

  async getRewards() {
    this.isLoading = true;
    try {
      const rewards = await this.apiService.getRewards();

      this.achievementProgress = rewards.achievementProgress || {};
      this.achievements = _.partition(rewards.achievements, a => a.groupId === 'system');
      this.level = rewards.level;
      this.nextLevel = rewards.nextLevel;
      this.points = rewards.points;
      this.logService.log('[ProfileComponent getRewards]', rewards);
    } catch (e) {
      this.logService.error('[ProfileComponent getRewards:error]', e);
    }
    this.isLoading = false;
  }

  updateUserData(key, value) {
    const { [key]: item, ...userData } = this.userProfile.userData;
    this.userProfile.userData = value ? { ...userData, [key]: value } : userData;
    this.updateAgent();
  }

  async updateAgent() {
    const body = {
      cognitoId: this.userProfile.cognitoId,
      username: this.userProfile.username,
      userData: JSON.stringify(this.userProfile.userData)
    };

    this.logService.log('updateAgent:body', body);

    try {
      await this.apiService.updateAgent(body).toPromise();
      this.authHelper.setUserProfile({ ...this.userProfile, ...body }, true);
    } catch (e) {
      this.logService.error('updateAgent:error', e);
    }
  }

  addChallengeWager() {
    this.challengeWagersList.getAllGamificationChallengeWagers();
  }
}
