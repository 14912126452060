import gql from 'graphql-tag';

export default gql`
  query getConversationQueues {
    getConversationQueues {
      __typename
      key
      count
      lastWaitingTime
      updatedAt
    }
  }
`;
