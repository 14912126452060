import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { EMPTY, from } from 'rxjs';
import { ObservableQuery } from 'apollo-client';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LogService } from '@cation/core/services/log/log.service';
import { AppsyncService } from '@cation/core/services/appsync/appsync.service';
import * as queries from '@cation/core/graphql/queries';
import Conversation from '@cation/core/types/conversation';
import { addCustomerHistory, constants } from '@cation/core/util/chat-helper';
import { getConversationsByCustomerIdQuery as ConvosByCustomerIdQuery } from '@cation/core/graphql/operation-result-types';
import { parseJSON } from '@cation/core/util/common-util';
import { DialogService } from '@cation/core/services/dialog/dialog.service';
import { environment } from '@cation/env/environment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ctn-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss'],
})
export class CustomerInfoComponent {
  loaderId = 'customer-history-loader';
  public googleMapApiKey = environment.googleMapApiKey;

  private _conversation;

  public displayedColumns = ['createdAt', 'intentsTriggered', 'caseId', 'channel', 'actions'];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();

  nextToken: string;
  isLoading = false;
  observedQuery: ObservableQuery<ConvosByCustomerIdQuery>;

  @Input() set conversation(conversation) {
    this.logService.log('**** Setting conversation ****');
    this._conversation = conversation;
    this.location = conversation.location;
    this.logService.log('location', this.location);
    this.loadCustomerHistory();
  }

  get conversation() {
    return this._conversation;
  }

  public location;

  constructor(
    private logService: LogService,
    private ngxLoader: NgxUiLoaderService,
    private appsync: AppsyncService,
    private dialogService: DialogService
  ) {}

  openCustomerHistory(conversationId) {
    this.dialogService.openCustomerHistory(conversationId);
  }

  public loadCustomerHistory() {
    this.ngxLoader.startLoader(this.loaderId);

    const innerObservable = this.appsync.hc().then((client) => {
      this.logService.log('[loadCustomerHistory]', this.conversation.id);
      const options = {
        query: queries.getConversationsByCustomerId,
        fetchPolicy: 'cache-and-network',
        variables: {
          customerId: this.conversation.createdBy,
          first: constants.customerHistoryFirst,
        },
      };

      const observable: ObservableQuery<ConvosByCustomerIdQuery> = client.watchQuery(options);

      observable.subscribe(
        ({ data }) => {
          this.logService.log('[loadCustomerHistory subscribe]', data);
          if (!data) {
            return this.logService.log('[loadCustomerHistory - no data]');
          }

          this.dataSource.data = data.getConversationsByCustomerId.conversations.map(this.prepareConversation);

          this.logService.log(
            '[loadCustomerHistory]: nextToken is now',
            data.getConversationsByCustomerId.nextToken ? 'set' : 'null'
          );

          this.nextToken = data.getConversationsByCustomerId.nextToken;

          this.ngxLoader.stopLoader(this.loaderId);
        },
        (error) => {
          this.logService.error('[loadCustomerHistory subscribe]', error);
        }
      );

      this.observedQuery = observable;

      return observable;
    });
    return from(innerObservable);
  }

  public loadMoreHistory() {
    this.logService.log('[CustomerInfoComponent loadMoreHistory]');
    if (!this.nextToken) {
      return EMPTY;
    }
    this.isLoading = true;
    const result = this.observedQuery.fetchMore({
      variables: { after: this.nextToken },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        this.logService.log('[loadMoreHistory fetchMoreResult]', fetchMoreResult);

        const moreConversations = fetchMoreResult.getConversationsByCustomerId.conversations.map(
          this.prepareConversation
        );

        this.dataSource.data = [...this.dataSource.data, ...moreConversations];

        const _res = addCustomerHistory(
          prev as ConvosByCustomerIdQuery,
          fetchMoreResult.getConversationsByCustomerId.conversations,
          fetchMoreResult.getConversationsByCustomerId.nextToken
        );
        this.nextToken = fetchMoreResult.getConversationsByCustomerId.nextToken;

        this.isLoading = false;

        return _res;
      },
    });
    return from(result);
  }

  prepareConversation(conversation: Conversation) {
    return {
      id: conversation.id,
      channel: conversation.channel,
      caseId: conversation.caseId,
      createdAt: conversation.createdAt,
      history: parseJSON(conversation.history as any),
      intentsTriggered: conversation.intentsTriggered.join(', '),
    };
  }
}
