import gql from 'graphql-tag';

export default gql`
  subscription subscribeToSaveConversationQueueHistory($userId: ID!) {
    subscribeToSaveConversationQueueHistory(userId: $userId) {
      __typename
      startedAt
      finishedAt
      userId
      agentId
      conversationId
      agent {
        __typename
        cognitoId
        avatarUrl
        username
        userData
      }
      conversation {
        __typename
        id
        intentsTriggered
        lastQuestion
        name
        caseId
        createdAt
        createdBy
        history
        locale
        location
        sentiment
        keywords
        status
        channel
      }
    }
  }
`;
