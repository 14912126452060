import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@cation/core/auth/auth-guard.service';
import { AccessDeniedComponent } from '@cation/core/components/access-denied/access-denied.component';
import { GuestHomeComponent } from '@cation/core/components/guest-home/guest-home.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: GuestHomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'guest', component: GuestHomeComponent },
  { path: 'accessdenied', component: AccessDeniedComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class RoutingModule {}
