import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@cation/core/auth/auth-guard.service';
import { Role } from '@cation/core/auth/role.enum';
import { AppSubNavbarComponent } from '@cation/core/components/index';
import { RouteDataModel } from '../core/models';
import { AppSettingsComponent } from './components/app-settings/app-settings.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import {
  conversationsRoutesConfig,
  gamificationRoutesConfig,
  reportsRoutesConfig,
  teamAdminRoutesConfig,
} from './configs/sub-navbar';

const routes: Routes = [
  {
    path: 'admin/dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ADMIN, Role.CONTENT_ADMIN, Role.REPORTS_ADMIN] },
  },
  {
    path: 'admin',
    component: AppSubNavbarComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.ADMIN],
      navbarConfig: teamAdminRoutesConfig,
    } as RouteDataModel,
    children: teamAdminRoutesConfig,
  },
  {
    path: 'admin/reports',
    component: AppSubNavbarComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.ADMIN, Role.REPORTS_ADMIN],
      navbarConfig: reportsRoutesConfig,
    } as RouteDataModel,
    children: reportsRoutesConfig,
  },
  {
    path: 'admin/gamification',
    component: AppSubNavbarComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.ADMIN],
      navbarConfig: gamificationRoutesConfig,
    } as RouteDataModel,
    children: gamificationRoutesConfig,
  },
  {
    path: 'admin/conversation',
    component: AppSubNavbarComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.ADMIN],
      navbarConfig: conversationsRoutesConfig,
    } as RouteDataModel,
    children: conversationsRoutesConfig,
  },
  {
    path: 'admin/settings',
    component: AppSettingsComponent,
    canActivate: [AuthGuard],
    data: { roles: [Role.ADMIN] },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
