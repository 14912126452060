import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { LogService } from '@cation/core/services/log/log.service';
import { ApiService } from '@cation/core/services/api/api.service';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { AppsyncUserNotificationService } from '@cation/core/services/appsync/appsync-user-notification.service';
import { UserNotificationService } from '@cation/core/services/user-notification/user-notification.service';
import { SnackBarErrorComponent } from '@cation/core/components/snack-bar-error/snack-bar-error.component';
import UserNotification from '@cation/core/types/userNotification';
import { fadeInOut } from '@cation/core/animations';
import IUser from '@cation/core/types/user';
import * as _ from 'lodash';
import * as moment from 'moment';

const mapNotificationTypeToGetUserDataFn = {
  CHALLENGE_REQUEST_EXPIRED: (item) => item.data.initiator || item.data.opponent,
  CHALLENGE_REQUEST_PENDING: (item) => item.data.initiator,
  CHALLENGE_REQUEST_ACCEPTED: (item) => item.data.opponent,
  CHALLENGE_REQUEST_REJECTED: (item) => item.data.opponent,
  CHALLENGE_WAGER_REQUEST_EXPIRED: (item) => item.data.initiator || item.data.opponent,
  CHALLENGE_WAGER_REQUEST_PENDING: (item) => item.data.initiator,
  CHALLENGE_WAGER_REQUEST_ACCEPTED: (item) => item.data.opponent,
  CHALLENGE_WAGER_REQUEST_REJECTED: (item) => item.data.opponent,
  CHALLENGE_WON: (item) => item.data.opponent,
  CHALLENGE_DRAW: (item) => item.data.opponent,
  CHALLENGE_LOST: (item) => item.data.opponent,
  CHALLENGE_WAGER_WON: (item) => item.data.opponent,
  CHALLENGE_WAGER_DRAW: (item) => item.data.opponent,
  CHALLENGE_WAGER_LOST: (item) => item.data.opponent,
};

@Component({
  selector: 'app-user-notifications-dialog',
  templateUrl: './user-notifications-dialog.component.html',
  styleUrls: ['./user-notifications-dialog.component.scss'],
  animations: [fadeInOut],
})
export class UserNotificationsDialogComponent implements OnInit {
  public userNotifications: UserNotification[] = [];
  public nextToken: string | null;

  public isLoading = false;
  public errorMessage;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { excludeAchievements: string[]; user: IUser },
    public dialogRef: MatDialogRef<UserNotificationsDialogComponent>,
    private appsyncUserNotificationService: AppsyncUserNotificationService,
    private userNotificationService: UserNotificationService,
    private apiService: ApiService,
    public authHelper: AuthHelper,
    private sharedService: SharedService,
    private logService: LogService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.sharedService.currentUserNotifications.subscribe((data) => {
      this.userNotifications = data.userNotifications;
      this.nextToken = data.nextToken;
    });
  }

  trackNotification(index, notification) {
    return notification ? notification.id : undefined;
  }

  getNotificationDate(id) {
    return id.split('_')[0];
  }

  readUserNotification(notification: UserNotification) {
    this.logService.log('[readUserNotification]', notification);
    return this.appsyncUserNotificationService.readUserNotification(notification);
  }

  loadMoreUserNotifications() {
    return this.userNotificationService.loadMoreUserNotifications();
  }

  getItemTranslate(item) {
    const data = _.cloneDeep(item.data);
    const userDataRef = mapNotificationTypeToGetUserDataFn[item.type](item);

    if (!userDataRef) {
      return data;
    }

    if (item.type.startsWith('CHALLENGE_REQUEST')) {
      data.username = _.get(userDataRef, 'userData.nickname', userDataRef.username);
      data.startDate = moment(item.data.startDate).format('DD MMM HH:mm');
      data.finishDate = moment(item.data.finishDate).format('DD MMM HH:mm');
    }
    if (item.type.startsWith('CHALLENGE_WAGER_REQUEST')) {
      data.amount = _.get(item.data, 'challenge.amount', _.get(item.data, 'challenge.data.amount'));
      data.username = _.get(userDataRef, 'userData.nickname', userDataRef.username);
      data.startDate = moment(item.data.challenge.startDate).format('DD MMM HH:mm');
      data.finishDate = moment(item.data.challenge.finishDate).format('DD MMM HH:mm');
    }
    if (['CHALLENGE_WON', 'CHALLENGE_DRAW', 'CHALLENGE_LOST'].includes(item.type)) {
      data.username = _.get(userDataRef, 'userData.nickname', userDataRef.username);
      data.cost = item.data.cost;
    }
    if (['CHALLENGE_WAGER_WON', 'CHALLENGE_WAGER_DRAW', 'CHALLENGE_WAGER_LOST'].includes(item.type)) {
      data.username = _.get(userDataRef, 'userData.nickname', userDataRef.username);
      data.cost = item.data.cost;
      data.startDate = moment(item.data.challenge.startDate).format('DD MMM HH:mm');
      data.finishDate = moment(item.data.challenge.finishDate).format('DD MMM HH:mm');
    }

    return data;
  }

  getItemIcon(item) {
    let value = item.icon;
    const userDataRef = mapNotificationTypeToGetUserDataFn[item.type](item);

    if (!userDataRef) {
      return value;
    }

    return _.get(userDataRef, 'userData.avatarUrl', userDataRef.avatarUrl);
  }

  getItemUserName(item) {
    let value = '';
    const userDataRef = mapNotificationTypeToGetUserDataFn[item.type](item);

    if (!userDataRef) {
      return value;
    }

    return _.get(userDataRef, 'userData.nickname', userDataRef.username);
  }

  async changeChallengeStatus(item, status: string) {
    this.isLoading = true;
    try {
      await this.apiService.changeGamificationChallengeStatus(item.data.challengeId, { status, type: item.data.type });
      this.readUserNotification(item);
      await this.authHelper.loadRewards();
    } catch (e) {
      this.logService.error('[changeChallengeStatus]', e);
      this.showErrorMessage(e);
    }
    this.isLoading = false;
  }

  async changeChallengeWagerStatus(item, status: string) {
    this.isLoading = true;
    try {
      await this.apiService.changeGamificationChallengeWagerStatus(item.data.challengeWagerId, {
        status,
        challengeId: item.data.challenge.challengeId,
      });
      this.readUserNotification(item);
      await this.authHelper.loadRewards();
    } catch (e) {
      this.logService.error('[changeChallengeWagerStatus]', e);
      this.showErrorMessage(e);
    }
    this.isLoading = false;
  }

  showErrorMessage(e) {
    const message = e.error && e.error.errorCode ? `ERROR_MESSAGE.${e.error.errorCode}` : e.message;

    this.snackBar.openFromComponent(SnackBarErrorComponent, {
      duration: 5000,
      data: { message },
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: ['notification__error'],
    });
  }
}
