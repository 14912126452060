import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { TranslateService } from '@ngx-translate/core';
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components';
import { I18n } from 'aws-amplify';
import { UrlParserService } from '@cation/core/services/url-parser/url-parser.service';

@Component({
  selector: 'ctn-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private isLoggedIn = false;

  constructor(
    private authHelper: AuthHelper,
    private router: Router,
    private translate: TranslateService,
    public urlParserService: UrlParserService,
    private ngZone: NgZone
  ) {}

  async ngOnInit() {
    console.log('Login:ngOnInit');

    onAuthUIStateChange(async (authState: AuthState, user: any) => {
      console.log('LoginComponent:onAuthUIStateChange', authState, user);
      if (authState === AuthState.SignedIn) {
        if (!this.isLoggedIn) {
          this.isLoggedIn = true;
          console.log('LoginComponent:redirecting to /');
          this.ngZone.run(() => {
            this.router.navigate(['/']);
          });
          // Workaround as page is getting kind of hanged after login, once the root cause found this reload can be removed
          // setTimeout(() => location.reload(), 2000);
        }
      }
    });

    if (this.authHelper.isAuthenticated()) {
      console.log('LoginComponent:redirecting to [empty]');
      this.router.navigate(['']);
    }

    const langKeys = [
      'LOGIN.USERNAME',
      'LOGIN.PASSWORD',
      'LOGIN.SIGN_IN',
      'LOGIN.NO_ACCOUNT',
      'LOGIN.CREATE_ACCOUNT',
      'LOGIN.FORGOT_PASSWORD',
      'LOGIN.RESET_PASSWORD',
      'LOGIN.SIGN_IN_TO_YOUR_ACCOUNT'
    ];

    this.translate.stream(langKeys).subscribe(values => {
      I18n.setLanguage('lang');
      const dict = {
        lang: {
          Username: values['LOGIN.USERNAME'],
          'Password *': values['LOGIN.PASSWORD'],
          'Sign In': values['LOGIN.SIGN_IN'],
          'No account?': values['LOGIN.NO_ACCOUNT'],
          'Create account': values['LOGIN.CREATE_ACCOUNT'],
          'Forgot Password?': values['LOGIN.FORGOT_PASSWORD'],
          'Reset password': values['LOGIN.RESET_PASSWORD'],
          'Sign in to your account': values['LOGIN.SIGN_IN_TO_YOUR_ACCOUNT']
        }
      };
      I18n.putVocabularies(dict);
    });
  }
}
