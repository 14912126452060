import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { LogService } from '@cation/core/services/log/log.service';
import { ApiHook } from '@cation/core/services/api/api-hook';
import { ApiService } from '@cation/core/services/api/api.service';

@Component({
  selector: 'app-activity-input-autocomplete',
  templateUrl: './activity-input-autocomplete.component.html',
  styleUrls: ['./activity-input-autocomplete.component.scss']
})
export class ActivityInputAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() hint: string;
  @Input() errorLabel: string;
  @Input() placeholder: string;
  @Input() placeholderLabel: string;
  @Input() required = false;
  @Input() initialActivity: string;

  @Output() selectionChange = new EventEmitter<string>();
  @Output() activitiesLoad = new EventEmitter<string[]>();

  public isLoading = false;
  public isFinding = false;
  public activities: string[] = [];
  /** control for the selected team leads */
  public activityCtrl: UntypedFormControl = new UntypedFormControl();
  /** control for the MatSelect filter keyword */
  public activitiesFilterCtrl: UntypedFormControl = new UntypedFormControl();
  /** list of activities filtered by search keyword */
  public filteredActivities: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  private translatedActivities;
  private translateKeys;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    public apiHook: ApiHook,
    private translate: TranslateService,
    private logService: LogService,
    private apiService: ApiService
  ) {}

  buildTranslatedActivities(values) {
    const translatedActivities = {};
    const valuesNumber = Object.keys(values).length;

    if (valuesNumber) {
      for (let key in values) {
        const translatedKey = values[key].toLowerCase();
        const activityType = key.replace('ADMIN_AGENTS_AUDIT.ACTIVITIES.', '');

        translatedActivities[translatedKey] = activityType;
      }

      this.translatedActivities = translatedActivities;
    }
  }

  async ngOnInit() {
    await this.searchActivityTypes();

    this.translate.stream(this.translateKeys).subscribe(values => this.buildTranslatedActivities(values));

    this.filteredActivities.next(this.activities.slice() || []);

    this.activityCtrl.valueChanges.subscribe(v => this.selectionChange.emit(v));

    if (this.initialActivity) {
      this.activityCtrl.setValue(this.activities.find(activity => activity === this.initialActivity));
    }

    this.activitiesFilterCtrl.valueChanges
      .pipe(
        debounceTime(200),
        takeUntil(this._onDestroy)
      )
      .subscribe(() => this.filterActivities());
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public trackItem(index, item) {
    return item ? item : undefined;
  }

  protected setInitialValue() {
    if (this.initialActivity) {
      this.activityCtrl.setValue(this.initialActivity);
    }
  }

  protected filterActivities() {
    let search = this.activitiesFilterCtrl.value;
    if (!search) {
      this.filteredActivities.next(this.activities.slice());
      return;
    } else {
      search = search.toLowerCase();
      const filteredActivities = Object.keys(this.translatedActivities)
        .filter(key => key.includes(search))
        .map(key => this.translatedActivities[key]);

      this.filteredActivities.next(filteredActivities);
    }
  }

  async searchActivityTypes() {
    try {
      const activities = await this.apiService.findActivityTypes();
      this.activities = activities as string[];
      this.translateKeys = this.activities.map(activity => `ADMIN_AGENTS_AUDIT.ACTIVITIES.${activity}`);
      this.activitiesLoad.emit(this.activities);
      this.logService.log('[ActivityInputAutocompleteComponent] searchActivityTypes:activities', this.activities);
    } catch (e) {
      this.logService.error('searchActivityTypes:error', e);
    }
  }

  resetActivity($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.activityCtrl.setValue(null);
  }
}
