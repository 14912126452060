import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit, OnDestroy {
  @Input() areaLabel = 'Search field';
  @Input() value = '';

  @Output() onSearchEvent = new EventEmitter<string>();

  private searchSub = new Subject<string>();

  constructor() {}

  ngOnInit() {
    this.searchSub.pipe(debounceTime(500)).subscribe(v => this.onSearchEvent.emit(v));
  }

  ngOnDestroy() {
    this.searchSub.unsubscribe();
  }

  onModelChange(value: string) {
    this.searchSub.next(value);
  }
}
