<section>
  <app-widget-header
    [title]="statsTypeEnum.conversationsPerHour | translate"
    [showTotalValue]="false"
  ></app-widget-header>

  <div class="ctn-content">
    <div *ngIf="!chartSingleResult" class="loader">{{ 'GENERAL.LOADING' | translate }}...</div>
    <div *ngIf="chartSingleResult && !chartSingleResult.length" class="no-statistics">
      {{ 'WIDGET.NO_STATISTICS' | translate }}
    </div>

    <div class="ctn-widget-chart" [ngClass]="'bar' + chartSingleResult?.length">
      <ngx-charts-bar-vertical
        [scheme]="color"
        [results]="chartSingleResult"
        [xAxis]="true"
        [gradient]="true"
        [showDataLabel]="true"
        [tooltipDisabled]="true"
      >
        >
      </ngx-charts-bar-vertical>
    </div>
  </div>
</section>
