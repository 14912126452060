import gql from 'graphql-tag';

export default gql`
  mutation createDirectChat($input: CreateDirectChatInput!) {
    createDirectChat(input: $input) {
      __typename
      firstUserId
      secondUserId
      chatId
    }
  }
`;
