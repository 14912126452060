import { Injectable } from '@angular/core';
import { Storage, StorageAccessLevel } from '@aws-amplify/storage';
import { v4 as uuid } from 'uuid';
import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import { LogService } from '@cation/core/services/log/log.service';
import { environment } from '../../../../environments/environment';

const {
  AWSS3: { bucket, region }
} = environment.amplifyConfig.Storage;

interface IUploadFileConfig {
  key: string;
  level: string;
  progressCallback: (progress: { loaded: number; total: number }) => void;
}

@Injectable({
  providedIn: 'root'
})
export class S3Service {
  static s3BucketUrl = `https://s3.${region}.amazonaws.com/${bucket}`;

  private defaultUploadS3Config = {
    key: uuid(),
    level: 'public' as StorageAccessLevel,
    progressCallback(progress) {
      this.logService.info(`Uploaded: ${progress.loaded}/${progress.total}`);
    }
  };

  private defaultGetS3Config = { expires: 60, level: 'public' as StorageAccessLevel };

  constructor(private logService: LogService) {
    this.logService.info('-------S3Service--------');
  }

  isBucketLink(url: string): boolean {
    const S3_URL_PATTERN = new RegExp(`^${S3Service.s3BucketUrl}/(public|private|protected)/.*$`);
    return S3_URL_PATTERN.test(url);
  }

  async updateAccessToImageUrls(text: string, withEncode: boolean = true): Promise<string> {
    const S3_URL_PATTERN = `<img src="${S3Service.s3BucketUrl}/(public|private|protected)/(.*?)">`;

    const matches = [];
    let newText = text;

    _.replace(text, new RegExp(S3_URL_PATTERN, 'g'), (matchStr, level, keyWithParams) => {
      const [key] = keyWithParams.split('?');

      matches.push({ matchStr, key });

      return matchStr;
    });

    await Promise.all(
      matches.map(async ({ key, matchStr }) => {
        let image = (await this.getFileByKey(key)) as string;
        image = withEncode ? encodeURIComponent(image) : image;
        newText = newText.replace(matchStr, `<img src="${image}">`);
      })
    );

    return newText;
  }

  removeSignFromImageUrls(text: string) {
    const S3_URL_PATTERN = `<img src="(${S3Service.s3BucketUrl}/(public|private|protected)/.*?)">`;

    const newText = _.replace(text, new RegExp(S3_URL_PATTERN, 'g'), (matchStr, url) =>
      matchStr.replace(url, url.split('?')[0])
    );

    this.logService.debug('[S3Service removeSignFromImageUrls]', { text, newText });

    return newText;
  }

  removeSignFromUrl(url: string) {
    return url.split('?')[0];
  }

  getFileByKey(key: string, config = this.defaultGetS3Config) {
    // this.logService.debug('[S3Service getFileByKey]', { key, config });
    return Storage.get(key, config);
  }

  getFileByUrl(url: string, config = this.defaultGetS3Config) {
    const newUrl = this.removeSignFromUrl(url);
    const S3_URL_PATTERN = new RegExp(`^${S3Service.s3BucketUrl}/(public|private|protected)/(.*)$`);

    // this.logService.debug('[S3Service getFileByUrl]', { url, newUrl, config, match: newUrl.match(S3_URL_PATTERN) });

    const [, , key] = newUrl.match(S3_URL_PATTERN);

    return this.getFileByKey(key, config);
  }

  async uploadFile(file, defaultConfig: any = this.defaultUploadS3Config) {
    const config = Object.assign({ contentType: file.type }, defaultConfig);

    this.logService.debug('[S3Service uploadFile:config]', { file, config });

    const data: any = await Storage.put(config.key, file, config);

    this.logService.debug('[S3Service uploadFile:data]', data);

    return await this.getFileByKey(data.key, { expires: 60, level: config.level as StorageAccessLevel });
  }

  async uploadImageFromBuffer(event, editorInstance, getConfig) {
    const imageReg = /[a-zA-Z0-9]+\.(png|jpe?g|bmp|gif|x\-icon){1}/;
    const fileProtocolReg = /^file\:\/\/\//;
    const path = event.clipboardData.getData('text');

    if (path && fileProtocolReg.test(path) && imageReg.test(path)) {
      event.preventDefault();
      event.stopPropagation();

      const file = event.clipboardData.files[0];

      const config = await getConfig();
      const url = await this.uploadFile(file, config);

      const range = editorInstance.getSelection();
      editorInstance.insertEmbed(range.index, 'image', url, 'user');
    }
  }

  async handleLink($event, onLoaderChange: (status: boolean) => void = () => ({})) {
    if ($event.target && $event.target.tagName === 'A' && this.isBucketLink($event.target.href)) {
      onLoaderChange(true);

      $event.preventDefault();
      $event.stopPropagation();
      try {
        const url = await this.getFileByUrl($event.target.href);

        const response = await fetch(`${url}`);
        const blob = await response.blob();
        const name = $event.target.text;

        this.logService.info('[handleLink saveFile]', { url, response, blob, name });

        saveAs(blob, name);
      } catch (error) {
        this.logService.error('[saveFile error]', error);
      }

      onLoaderChange(false);
    }
  }

  makeQuillImageHandler(editorInstance, getConfig) {
    return () => {
      let fileInput = editorInstance.container.querySelector('input.ql-image[type=file]');
      if (fileInput == null) {
        fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
        fileInput.style = 'display: none';
        fileInput.addEventListener('change', async () => {
          if (fileInput.files != null && fileInput.files[0] != null) {
            const file = fileInput.files[0];
            const config = await getConfig();
            const url = await this.uploadFile(file, config);

            const range = editorInstance.getSelection();
            editorInstance.insertEmbed(range.index, 'image', url, 'user');
            fileInput.value = '';
          }
        });
        editorInstance.container.appendChild(fileInput);
      }
      fileInput.click();
    };
  }

  makeQuillFileHandler(editorInstance, getConfig) {
    return () => {
      let fileInput = editorInstance.container.querySelector('input.ql-file[type=file]');
      if (fileInput == null) {
        fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.style = 'display: none';
        fileInput.addEventListener('change', async () => {
          if (fileInput.files != null && fileInput.files[0] != null) {
            const file = fileInput.files[0];
            const config = await getConfig();
            const url = await this.uploadFile(file, config);

            const range = editorInstance.getSelection();

            if (['image/png', 'image/gif', 'image/jpeg', 'image/bmp', 'image/x-icon'].includes(file.type)) {
              editorInstance.insertEmbed(range.index, 'image', url, 'user');
              fileInput.value = '';
              return;
            }

            const html = `<a href="${this.removeSignFromUrl(`${url}`)}" target="_blank">${file.name}</a>`.trim();

            editorInstance.clipboard.dangerouslyPasteHTML(range.index, html, 'user');
            fileInput.value = '';
          }
        });
        editorInstance.container.appendChild(fileInput);
      }
      fileInput.click();
    };
  }
}
