import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '@cation/core/services/api/api.service';

@Injectable()
export class HttpCancelInterceptor implements HttpInterceptor {
    constructor(private api: ApiService) { }

    intercept<T>(req: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        return next.handle(req).pipe(takeUntil(this.api.onCancelPendingRequests()));
    }
}