<div class="text-center">
  <h1 mat-dialog-title class="d-inline-block">{{ 'CHALLENGE_WAGER_DIALOG.TITLE' | translate }}</h1>
  <button mat-icon-button [tabIndex]="-1" type="button" class="close" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="voteFormGroup">
      <form [formGroup]="voteFormGroup">
        <ng-template matStepLabel>
          {{ 'CHALLENGE_WAGER_DIALOG.SELECT_WINNER_STEP.LABEL' | translate }}
        </ng-template>
        <mat-radio-group class="type-radio-group" formControlName="vote">
          <mat-radio-button class="type-radio-button" [value]="data.initiatorId">
            <app-user-template [user]="data.initiator" tooltipField="userData.status"></app-user-template>
          </mat-radio-button>
          <mat-radio-button class="type-radio-button" [value]="data.opponentId">
            <app-user-template [user]="data.opponent" tooltipField="userData.status"></app-user-template>
          </mat-radio-button>
        </mat-radio-group>
        <div>
          <button mat-button matStepperNext>{{ 'BUTTON.NEXT' | translate }}</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="costFormGroup">
      <form [formGroup]="costFormGroup">
        <ng-template matStepLabel>
          {{ 'CHALLENGE_WAGER_DIALOG.COST_STEP.LABEL' | translate }}
        </ng-template>
        <mat-form-field class="w-100 pt-2">
          <input
            type="number"
            min="1"
            matInput
            [max]="maxPoints"
            [placeholder]="'CHALLENGE_WAGER_DIALOG.COST_STEP.TEXT_PLACEHOLDER' | translate"
            formControlName="points"
            required
          />
        </mat-form-field>
        <span
          *ngIf="!isCostLoading"
          class="mb-2"
          [innerHTML]="
            'CHALLENGE_DIALOG.COST_STEP.DESCRIPTION'
              | translate: { points: maxPoints, max: authHelper.rewards.score || 0 }
          "
        ></span>
        <mat-progress-bar class="m-2" *ngIf="isCostLoading" [mode]="'indeterminate'" [@fadeInOut]></mat-progress-bar>
        <div>
          <button mat-button matStepperPrevious>{{ 'BUTTON.BACK' | translate }}</button>
          <button mat-button matStepperNext>{{ 'BUTTON.NEXT' | translate }}</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="opponentFormGroup">
      <form [formGroup]="opponentFormGroup">
        <ng-template matStepLabel>
          {{ 'CHALLENGE_WAGER_DIALOG.OPPONENT_STEP.LABEL' | translate }}
        </ng-template>
        <app-agent-input-autocomplete
          (selectionChange)="opponentForm.opponent.setValue($event)"
          (agentsLoad)="isUsersLoading = false"
          [required]="true"
          [excludeIds]="[data.initiator.cognitoId, data.opponent.cognitoId]"
          [placeholder]="'CHALLENGE_WAGER_DIALOG.OPPONENT_STEP.PLACEHOLDER' | translate"
          [placeholderLabel]="'CHALLENGE_WAGER_DIALOG.OPPONENT_STEP.INPUT_PLACEHOLDER' | translate"
          [hint]="'CHALLENGE_WAGER_DIALOG.OPPONENT_STEP.HINT' | translate"
          [errorLabel]="'CHALLENGE_WAGER_DIALOG.OPPONENT_STEP.ERROR' | translate"
        >
        </app-agent-input-autocomplete>
        <mat-progress-bar class="m-2" *ngIf="isUsersLoading" [mode]="'indeterminate'" [@fadeInOut]></mat-progress-bar>
        <div>
          <button mat-button matStepperPrevious>{{ 'BUTTON.BACK' | translate }}</button>
          <button mat-button matStepperNext>{{ 'BUTTON.NEXT' | translate }}</button>
        </div>
      </form>
    </mat-step>
    <mat-step class="position-relative">
      <ngx-ui-loader [fgsSize]="25" [loaderId]="loaderId" [@fadeInOut]></ngx-ui-loader>
      <ng-template matStepLabel>
        {{ 'CHALLENGE_WAGER_DIALOG.FINISH_STEP.LABEL' | translate }}
      </ng-template>
      <div class="d-flex row justify-content-center align-items-center">
        🆚&nbsp;
        <app-user-template [user]="opponentForm.opponent.value" tooltipField="userData.status"></app-user-template>
      </div>
      <div class="d-flex flex-column justify-content-center align-items-center">
        <h4 class="text-center">
          {{ 'CHALLENGE_WAGER_DIALOG.FINISH_STEP.DESCRIPTION' | translate: getDescriptionTranslate() }}
        </h4>
        <h4>
          {{ 'CHALLENGE_WAGER_DIALOG.FINISH_STEP.COST_POINTS' | translate: { value: costForm.points.value } }}
        </h4>
      </div>
      <div>
        <button mat-button matStepperPrevious>{{ 'BUTTON.BACK' | translate }}</button>
        <button mat-button (click)="stepper.reset(); clearError()">{{ 'BUTTON.RESET' | translate }}</button>
        <button mat-button (click)="onAddChallengeWager()">{{ 'BUTTON.MAKE_WAGER' | translate }}</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

  <div *ngIf="errorMessage" class="alert alert-danger mt-2" [@fadeInOut]>
    {{ errorMessage }}
  </div>
</div>
