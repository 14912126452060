import { UserListItemModel } from '../../../models';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-user-list-item',
  templateUrl: './user-list-item.component.html',
  styleUrls: ['./user-list-item.component.scss']
})
export class UserListItemComponent {
  @Input() userData: UserListItemModel = new UserListItemModel();

  @Output() onDelete = new EventEmitter<UserListItemModel>();
  @Output() onUserClick = new EventEmitter<UserListItemModel>();

  constructor() {}

  onDeleteEvent($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.onDelete.emit(this.userData);
  }

  onUserClickEvent($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.onUserClick.emit(this.userData);
  }
}
