import gql from 'graphql-tag';

export default gql`
  subscription subscribeToUpdateAgentByTeamId($teamId: ID!) {
    subscribeToUpdateAgentByTeamId(teamId: $teamId) {
      __typename
      username
      cognitoId
      avatarUrl
      cmsUserData
      keywords
      locales
      roles
      registered
      teamId
      userData
    }
  }
`;
