import { Component, Input, OnInit } from '@angular/core';
import { IUserConversationStatistics } from '@cation/core/types/api';
import { TranslateService } from '@ngx-translate/core';
import { chartColorSchemas } from '@cation/core/constants/chart-color-schema';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-conversations-single-metric-chart',
  templateUrl: './user-conversations-single-metric-chart.component.html',
  styleUrls: ['./user-conversations-single-metric-chart.component.scss']
})
export class UserConversationsSingleMetricChartComponent implements OnInit {
  @Input() metric: 'stars' = 'stars';

  multi: object[];
  _statistics: IUserConversationStatistics;

  colorScheme = {
    domain: chartColorSchemas['material500']
  };

  private stars = [5, 4, 3, 2, 1];

  private conversationFeedbackPointsTitle = 'Feedback Points';
  private conversationFeedbackStarsTitle = 'Feedback Stars';
  private conversationsPointsTitle = 'Conversation Points';
  private conversationsStarsTitle = 'Conversation Stars';
  private conversationsTitle = 'Conversations';
  private feedbackTitle = 'Feedback';

  @Input() set statistics(statistics: IUserConversationStatistics) {
    this._statistics = statistics;

    if (!statistics) {
      this.multi = [];
      return;
    }

    if (this.metric === 'stars') {
      this.multi = this.generateStarsMetric();
      return;
    }
  }

  constructor(private translate: TranslateService) {
    const translateKeys = [
      'MY_STATISTICS.FEEDBACK_POINTS_TITLE',
      'MY_STATISTICS.FEEDBACK_STARS_TITLE',
      'MY_STATISTICS.CONVERSATIONS_POINTS_TITLE',
      'MY_STATISTICS.CONVERSATIONS_STARS_TITLE',
      'MY_STATISTICS.CONVERSATIONS_TITLE',
      'MY_STATISTICS.FEEDBACK_TITLE'
    ];
    this.translate.stream(translateKeys).subscribe(values => {
      this.conversationFeedbackPointsTitle = values['MY_STATISTICS.FEEDBACK_POINTS_TITLE'];
      this.conversationFeedbackStarsTitle = values['MY_STATISTICS.FEEDBACK_STARS_TITLE'];
      this.conversationsPointsTitle = values['MY_STATISTICS.CONVERSATIONS_POINTS_TITLE'];
      this.conversationsStarsTitle = values['MY_STATISTICS.CONVERSATIONS_STARS_TITLE'];
      this.conversationsTitle = values['MY_STATISTICS.CONVERSATIONS_TITLE'];
      this.feedbackTitle = values['MY_STATISTICS.FEEDBACK_TITLE'];

      this.statistics = this._statistics;
    });
  }

  ngOnInit() {}

  generateStarsMetric() {
    const conversationsSeries = [];
    const feedbackSeries = [];

    for (const item of this.stars) {
      const name = `${item} ⭐️`;
      conversationsSeries.push({ name, value: this._statistics[`countConversations${item}Stars`] || 0 });
      feedbackSeries.push({ name, value: this._statistics[`countConversationFeedback${item}Stars`] || 0 });
    }

    return [
      {
        name: `${this.conversationsTitle} (${_.sumBy(conversationsSeries, 'value')})`,
        series: conversationsSeries
      },
      {
        name: `${this.feedbackTitle} (${_.sumBy(feedbackSeries, 'value')})`,
        series: feedbackSeries
      }
    ];
  }
}
