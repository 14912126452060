<form class="internal-message-input" (ngSubmit)="sendMessage()" novalidate>
  <div class="attachment-container">
    <input #fileInput type="file" hidden (change)="onChangeAttachment($any($event.target).files)" />
    <div *ngIf="isUploading" class="attachment-loader">
      <mat-spinner mode="determinate" color="primary" [diameter]="36" [value]="progressPercent"></mat-spinner>
    </div>
    <button
      type="button"
      mat-button
      mat-icon-button
      title="Attach"
      *ngIf="!attachment"
      (click)="onAddAttachment($event)"
    >
      <mat-icon>attachment</mat-icon>
    </button>
    <button
      *ngIf="attachment"
      type="button"
      mat-button
      mat-icon-button
      title="Delete"
      (click)="onDeleteAttachment($event)"
    >
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>

  <div class="send-container">
    <input
      #inputElement
      type="text"
      autocomplete="off"
      name="message"
      placeholder="Message..."
      [(ngModel)]="inputValue"
      autofocus
    />
    <button #emojiButtonElement class="emoji-button" (click)="toggleEmojiBar($event)" title="Emoji" type="button">
      <mat-icon>sentiment_satisfied_alt</mat-icon>
    </button>
    <button type="submit" mat-button mat-icon-button [disabled]="isUploading">
      <mat-icon>send</mat-icon>
    </button>
  </div>
</form>
