export enum StatsFilterOptionEnum {
  today = 'Today',
  sevenDays = '7 days',
  thisMonth = 'This month',

  keywords = 'Keywords',
  channels = 'Channels',
  locale = 'Locale',
  summary = 'Summary'
}
