import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as mutations from '@cation/core/graphql/mutations';
import * as queries from '@cation/core/graphql/queries';
import { AppsyncService } from '@cation/core/services/appsync/appsync.service';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import Conversation from '@cation/core/types/conversation';
import { constants, escapeStr, unshiftNote, updateNote } from '@cation/core/util/chat-helper';
import { Analytics } from 'aws-amplify';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { v4 as uuidv4 } from 'uuid';

interface IEscalateConversationInputData {
  conversation: Conversation;
  senderId: string;
  noteId: string;
  content: string;
}

@Component({
  selector: 'app-chat-create-edit-note-dialog',
  templateUrl: './chat-create-edit-note-dialog.component.html',
  styleUrls: ['./chat-create-edit-note-dialog.component.css'],
})
export class ChatCreateEditNoteDialogComponent {
  loaderId = 'chat-create-edit-note-dialog-loader';

  content = '';
  errorMessage;
  uploadPercent = 100;
  public editorStyles = { height: '250px' };
  public quillModules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [
        { list: 'bullet' },
        { color: [] },
        { background: [] },
        { header: 1 },
        { header: 2 },
        { header: [3, 4, 5, 6, false] },
      ],
      ['link', 'image', 'video', 'file'],
    ],
    handlers: { file: () => {} },
  };

  private editorInstance;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEscalateConversationInputData,
    public dialogRef: MatDialogRef<ChatCreateEditNoteDialogComponent>,
    private ngxLoader: NgxUiLoaderService,
    private logService: LogService,
    private s3Service: S3Service,
    private appsync: AppsyncService
  ) {
    if (this.data.noteId) {
      this.content = this.data.content;
    }
  }

  submitHandler() {
    if (this.data.noteId) {
      return this.updateNote();
    }
    return this.createNote();
  }

  getUploadImageConfig = () => {
    return Promise.resolve({
      key: `img/${this.data.conversation.id}/${uuidv4()}`,
      level: 'public',
      progressCallback: (progress) => (this.uploadPercent = Math.floor((progress.loaded * 100) / progress.total)),
    });
  };

  getUploadFileConfig = () => {
    return Promise.resolve({
      key: `file/${this.data.conversation.id}/${uuidv4()}`,
      level: 'public',
      progressCallback: (progress) => (this.uploadPercent = Math.floor((progress.loaded * 100) / progress.total)),
    });
  };

  createNote() {
    const input = {
      conversationId: this.data.conversation.id,
      id: `${new Date().toISOString()}_${uuidv4()}`,
      content: escapeStr(this.s3Service.removeSignFromImageUrls(this.content)),
      createdBy: this.data.senderId,
    };
    this.logService.log('[createNote input]', input);

    this.ngxLoader.startLoader(this.loaderId);

    return this.appsync.hc().then((client) => {
      return client
        .mutate({
          mutation: mutations.createConversationNote,
          variables: { input },
          update: (proxy, { data: { createConversationNote: note } }) => {
            this.logService.log('[createNote - update fn]', note);
            const _options = {
              query: queries.getConversationNotes,
              variables: {
                conversationId: this.data.conversation.id,
                first: constants.notesFirst,
              },
            };
            const prev = proxy.readQuery(_options);
            const data = unshiftNote(prev, note);

            this.logService.log('[createNote data]', { _options, prev, data });

            proxy.writeQuery({ ..._options, data });

            this.ngxLoader.stopLoader(this.loaderId);

            setTimeout(() => this.dialogRef.close(note), 500);
          },
        })
        .then(({ data }) => {
          this.logService.log('mutation createConversationNote complete', data);
          return data;
        })
        .catch((err) => this.logService.log('Error creating conversation note', err))
        .then(() => Analytics.record('Create Conversation Note'));
    });
  }

  updateNote() {
    const input = {
      id: this.data.noteId,
      conversationId: this.data.conversation.id,
      content: escapeStr(this.s3Service.removeSignFromImageUrls(this.content)),
    };
    this.logService.log('[updateNote input]', input);

    this.ngxLoader.startLoader(this.loaderId);

    return this.appsync.hc().then((client) => {
      return client
        .mutate({
          mutation: mutations.updateConversationNote,
          variables: { input },
          update: (proxy, { data: { updateConversationNote: note } }) => {
            this.logService.log('[updateNote - update fn]', note);
            const _options = {
              query: queries.getConversationNotes,
              variables: {
                conversationId: this.data.conversation.id,
                first: constants.notesFirst,
              },
            };
            const prev = proxy.readQuery(_options);
            const data = updateNote(prev, note);

            this.logService.log('[updateNote data]', { _options, prev, data });

            proxy.writeQuery({ ..._options, data });

            this.ngxLoader.stopLoader(this.loaderId);

            setTimeout(() => this.dialogRef.close(note), 500);
          },
        })
        .then(({ data }) => {
          this.logService.log('mutation updateConversationNote complete', data);
          return data;
        })
        .catch((err) => this.logService.log('Error updating conversation note', err))
        .then(() => Analytics.record('Update Conversation Note'));
    });
  }

  public onImagePaste(e) {
    this.s3Service.uploadImageFromBuffer(e, this.editorInstance, this.getUploadImageConfig);
  }

  editorCreated(editorInstance) {
    this.editorInstance = editorInstance;

    const uploadImageHandler = this.s3Service.makeQuillImageHandler(this.editorInstance, this.getUploadImageConfig);
    this.editorInstance.getModule('toolbar').addHandler('image', uploadImageHandler);

    const uploadFileHandler = this.s3Service.makeQuillFileHandler(this.editorInstance, this.getUploadFileConfig);
    this.editorInstance.getModule('toolbar').addHandler('file', uploadFileHandler);

    const button = this.editorInstance.getModule('toolbar').container.querySelector('button.ql-file');
    button.style = `${button.style}; padding: 0;`;
    const attachFileIcon = document.getElementById('note_attach_file_icon');
    button.appendChild(attachFileIcon);

    setTimeout(() => editorInstance.focus(), 500);
  }
}
