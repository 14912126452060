import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root',
})
export class ApiHook {
    private count: number = 0;

    loading() {
        this.count++;
        console.log('ApiHook::loading', this.count);
    }

    complete() {
        this.count--;
        console.log('ApiHook::complete', this.count);
    }

    isLoading() {
        return this.count > 0;
    }
}
