import { ModuleWithProviders, NgModule } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { TagInputModule } from 'ngx-chips';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxUiLoaderConfig, NgxUiLoaderModule, SPINNER } from 'ngx-ui-loader';
import { InViewportModule } from 'ng-in-viewport';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { CtnPipes } from '@cation/core/modules/pipe.module';
import { InfscrollDirective } from '@cation/core/directives/infscroll.directive';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker-ext';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  hasProgressBar: false,
  overlayColor: 'rgba(33, 37, 41, .4)',
  fgsType: SPINNER.threeBounce,
};

const modules = [] as any[];

@NgModule({
  declarations: [InfscrollDirective],
  imports: [
    CtnPipes,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    TagInputModule,
    FontAwesomeModule,
    NgxPageScrollCoreModule.forRoot({ duration: 400 }),
    NgxPageScrollModule,
    NgxMatSelectSearchModule,
    InViewportModule,
    SatDatepickerModule,
    SatNativeDateModule,
    NgxChartsModule,
    RouterModule,
    InfiniteScrollModule,
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger',
      cancelButtonType: 'success',
    }),
    NgxLoadingModule.forRoot({
      fullScreenBackdrop: true,
    }),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
  ],
  exports: [
    CtnPipes,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule,
    TagInputModule,
    FontAwesomeModule,
    NgxPageScrollCoreModule,
    NgxPageScrollModule,
    NgxMatSelectSearchModule,
    InViewportModule,
    SatDatepickerModule,
    SatNativeDateModule,
    NgxChartsModule,
    RouterModule,
    InfiniteScrollModule,
    InfscrollDirective,
    ConfirmationPopoverModule,
    NgxLoadingModule,
    NgxUiLoaderModule,
  ],
})
export class SharedModule {}
