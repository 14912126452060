import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-team-activity',
  templateUrl: 'team-activity.component.html',
  styleUrls: ['./team-activity.component.scss']
})
export class TeamActivityComponent {
  @Input() teamId;
}
