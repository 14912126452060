import { Injectable } from '@angular/core';
import { LogLevel } from '@cation/core/enums/log.enum';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  // TODO: Change when we go live in future.
  protected level: LogLevel = LogLevel.All; // environment.production ? LogLevel.Off : LogLevel.All;`
  private styles = {
    [LogLevel.All]: '',
    [LogLevel.Debug]: 'color: blue; font-weight: bold;',
    [LogLevel.Info]: 'color: lightblue;',
    [LogLevel.Warn]: 'color: orange; font-weight: bold;',
    [LogLevel.Error]: 'color: red;',
    [LogLevel.Fatal]: 'color: red; font-weight: bold;'
  };

  constructor() {
    this.info('-------LogService--------');
  }

  groupInfo(groupTitle: string, msg, ...optionalParams) {
    console.group(groupTitle);
    this.info(msg, ...optionalParams);
    console.groupEnd();
  }

  groupError(groupTitle: string, msg: string, ...rest) {
    console.group(groupTitle);
    this.error(msg, ...rest);
    console.groupEnd();
  }

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
  }

  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }

  private writeToLog(msg, level: LogLevel, optionalParams) {
    if (!this.shouldLog(level)) {
      return;
    }

    console.log(`%c${msg}`, this.styles[level], ...optionalParams);
  }

  private shouldLog(level: LogLevel): boolean {
    return (level >= this.level && level !== LogLevel.Off) || this.level === LogLevel.All;
  }
}
