import User from '@cation/core/types/user';

type IChallengeWagerStatus = 'PENDING' | 'ACCEPTED' | 'FINISHED' | 'EXPIRED';

export interface IChallengeWagerItem {
  id: string;
  challengeId: string;
  initiatorId: string;
  opponentId: string;
  initiatorVoteId: string;
  opponentVoteId: string;
  winnerId: string;
  cost: number;
  status: IChallengeWagerStatus;

  challenge: User;
  initiator: User;
  opponent: User;
  initiatorVote: User;
  opponentVote: User;
}

export type IChallengeWagersResponse = {
  items: IChallengeWagerItem[];
  lastKey: object;
};
