export enum Channel {
  WEB = 'WEB',
  EMAIL = 'EMAIL',
  MOBILE = 'MOBILE',
  MOBILE_WEB = 'MOBILE_WEB',
  VOICE = 'VOICE',
  SLACK = 'SLACK',
  TWITTER = 'TWITTER',
  FACEBOOK = 'FACEBOOK',
  SKYPE = 'SKYPE',
  VIBER = 'VIBER',
  EHUB = 'EHUB',
  APPLE = 'APPLE',
  WHATSAPP = 'WHATSAPP',
  HELPDESK = 'HELPDESK',
  ZENDESK = 'ZENDESK'
}

export const classChannelIcon = {
  web: 'ion-ios-world-outline',
  twitter: 'ion-social-twitter-outline',
  whatsapp: 'ion-social-whatsapp-outline',
  facebook: 'ion-social-facebook-outline',
  skype: 'ion-social-skype-outline',
  viber: 'ion-chatbubbles',
  email: 'ion-ios-email-outline',
  voice: 'ion-ios-telephone-outline',
  apple: 'ion-social-apple-outline'
};

export const OnlyTextChannels = [
  Channel.TWITTER,
  Channel.FACEBOOK,
  Channel.WHATSAPP,
  Channel.SKYPE,
  Channel.VIBER,
  Channel.EHUB,
  Channel.APPLE,
  Channel.ZENDESK
];
