import { ApiService } from '@cation/core/services/api/api.service';
import { CMS_TYPE } from '@cation/core/enums/cms-type.enum';
import { EventEmitter } from '@angular/core';
import { IAnyObject, ICMS, IComment } from '../../interfaces/cms';

export default class SalesForce implements ICMS {
  type = CMS_TYPE.SALES_FORCE;

  id: string | number;
  name: string;
  title: string;
  description: string;
  comments: IComment[] = [];
  createdAt: number;

  isInitialized = false;
  isDataLoading = false;
  isCommentsLoading = false;
  onAddNewComment = new EventEmitter<IAnyObject>();

  constructor(data: IAnyObject, private api: ApiService) {
    this.id = data.CaseNumber;
    this.name = data.CaseNumber;
    this.title = data.Subject;
    this.description = data.Description; // TODO
  }

  init() {

  }

  addComment() {

  }

  changeStatus() {

  }

  toJSON() {
    return '';
  }
}
