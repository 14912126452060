import { Component, EventEmitter, Input, Output } from '@angular/core';
import { listFadeInOut } from '@cation/core/animations';
import IAchievement from '@cation/core/types/achievement';

@Component({
  selector: 'app-achievements-list',
  templateUrl: './achievements-list.component.html',
  styleUrls: ['./achievements-list.component.scss'],
  animations: [listFadeInOut]
})
export class AchievementsListComponent {
  @Input() achievements: IAchievement[];
  @Input() achievementProgress = {};
  @Input() selective: boolean = false;
  @Input() inline: boolean = false;

  @Output() onAchievementSelect = new EventEmitter<IAchievement>();

  constructor() {}

  trackAchievement(index, achievement) {
    return achievement ? achievement.id : undefined;
  }
}
