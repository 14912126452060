import gql from 'graphql-tag';

export default gql`
  query getUserConversationConnectionByCognitoId($cognitoId: ID!, $after: String, $first: Int) {
    getUserByCognitoId(cognitoId: $cognitoId) {
      cognitoId
      registered
      __typename
      conversations(first: $first, after: $after) {
        __typename
        nextToken
        userConversations {
          __typename
          userId
          conversationId
          associated {
            __typename
            conversationId
            userId
            user {
              __typename
              cognitoId
              username
              registered
              avatarUrl
            }
            typing
            connected
          }
          conversation {
            __typename
            id
            name
            caseId
            subject
            botSessionId
            keywords
            locale
            location
            sentiment
            createdBy
            history
            channel
            createdAt
            intentsTriggered
            lastQuestion
            status
            cms {
              conversationId
              isCore
              type
              id
              createdAt
            }
            transfers {
              conversationId
              createdAt
              userId
              nextUserId
              description
              user {
                cognitoId
                username
              }
              nextUser {
                cognitoId
                username
              }
            }
            author {
              cognitoId
              username
              userData
            }
          }
        }
      }
    }
  }
`;
