import { Component, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { ObservableQuery } from 'apollo-client';
import * as _ from 'lodash';
import { ICMS } from '@cation/core/interfaces/cms';
import { LocaleUtil } from '@cation/core/util/locale-util';
import { LogService } from '@cation/core/services/log/log.service';
import { ApiService } from '@cation/core/services/api/api.service';
import { AppsyncConversationService } from '@cation/core/services/appsync/appsync-conversation.service';
import { getConversationHistory } from '@cation/core/util/shared-functions';
import { prepareConversation } from '@cation/core/util/common-util';
import { mapSentimentToIcon } from '@cation/core/constants/sentiments';
import { SharedService } from '@cation/core/services/shared/shared.service';
import {
  getConversationMessagesQuery as MessagesQuery,
  getConversationNotesQuery as NotesQuery,
} from '@cation/core/graphql/operation-result-types';
import Note from '@cation/core/types/note';
import Message from '@cation/core/types/message';
import Conversation from '@cation/core/types/conversation';
import { constants, pushMessages, pushNotes } from '@cation/core/util/chat-helper';

@Component({
  selector: 'app-message-view',
  templateUrl: './message-view.component.html',
  styleUrls: ['./message-view.component.scss'],
})
export class MessageViewComponent {
  _conversation: Conversation;

  messages: Message[] = [];
  nextToken: string;
  observedQuery: ObservableQuery<MessagesQuery>;
  fetchingMore = false;
  completedFetching = false;

  notes: Note[] = [];
  notesNextToken: string;
  notesObservedQuery: ObservableQuery<MessagesQuery>;
  fetchingNotesMore = false;
  completedNotesFetching = false;

  lastMessage: Message;
  firstMessage: Message;
  subscription: () => void;

  _conversationHistory = [];
  runningLine = '';

  isInitialized = false;
  isHistoryLoading = false;
  hiddenActionPanel = true;

  tabId = 'chat';
  currentCms: ICMS;

  mapSentimentToIcon = mapSentimentToIcon;

  @ViewChild('scrollMe') myScrollContainer: ElementRef;
  @ViewChild('actionPanel') actionPanel: ElementRef;
  @ViewChild('nav', { static: true }) nav;

  @Output() onCloseConversation = new EventEmitter<void>();
  @Output() onUpdateConversation = new EventEmitter<Conversation>();

  @Input() senderId;

  @Input()
  set conversation(convo: Conversation) {
    this.logService.debug('setConversation - ', convo);

    if (!convo) {
      this.runningLine = '';
      return;
    }

    if (_.get(this._conversation, 'id') !== convo.id) {
      this.isInitialized = false;
      this.nextToken = null;
      this.currentCms = null;
      this.hiddenActionPanel = true;
      this.messages = [];
      this._conversationHistory = [];
    }

    this._conversation = convo;
    this._conversation.cms = _.orderBy(convo.cms, 'isCore', 'desc');

    if (this.subscription) {
      this.subscription();
    }

    this.getConversationHistory();
    this.getConversationById();
  }

  get conversation() {
    return this._conversation;
  }

  get conversationHistory() {
    return this._conversationHistory;
  }

  set conversationHistory(messages) {
    const primaryTopics = messages.reduce((res, value, key) => {
      this._conversationHistory.push({
        ...value,
        isSent: true,
        content: value.message,
        id: `history-${key}`,
        createdAt: `${value.timestamp}_`,
      });
      if (value.primaryTopic) {
        res.push(value.primaryTopic);
      }
      return res;
    }, []);

    this.runningLine = _.uniq(primaryTopics).join(', ');

    if (this._conversation.mailData && this._conversation.mailData.subject) {
      this.runningLine = 'Subject: ' + this._conversation.mailData.subject;
    }
    this.runningLine = this.runningLine || '-';
  }

  @Input()
  set messagesData({ messages = [], nextToken, observedQuery }) {
    this.messages = messages;
    this.nextToken = nextToken;
    this.observedQuery = observedQuery;

    const clientMessages = this.messages.filter((m) => m.sender !== this.conversation.createdBy);
    if (clientMessages.length && !this.isConversationClosed() && !this.isInitialized) {
      this.sharedService.searchAi(clientMessages[clientMessages.length - 1].content);
      this.isInitialized = true;
    }
  }

  @Input()
  set notesData({ notes = [], nextToken, observedQuery }) {
    this.notes = notes;
    this.notesNextToken = nextToken;
    this.notesObservedQuery = observedQuery;

    if (this.notes.length < constants.notesFirst && this.notesNextToken) {
      this.loadMoreNotes();
    }
  }

  constructor(
    private appsyncConversationService: AppsyncConversationService,
    private sharedService: SharedService,
    private logService: LogService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private apiService: ApiService,
    public localeUtil: LocaleUtil
  ) {
    this.loadMoreNotes = this.loadMoreNotes.bind(this);
    this.loadMoreMessages = this.loadMoreMessages.bind(this);
    this.sharedService.onCmsChanged.subscribe((key) => this.nav.select(key));
  }

  openModal(content, size?: 'sm' | 'lg') {
    this.modalService
      .open(content, { size })
      .result.then(() => this.logService.info('[modalService Success]'))
      .catch((err) => this.logService.log('[modalService Error]', err));
  }

  public messageAdded(isFirst = false, message: Message) {
    if (isFirst) {
      if (!this.firstMessage) {
        this.firstMessage = message;
      } else if (this.firstMessage.id !== message.id) {
        setTimeout(() => {
          this.completedFetching = this.fetchingMore;
          this.fetchingMore = false;
        });
      }
    } else {
      if (!this.lastMessage || this.lastMessage.id !== message.id) {
        setTimeout(() => this.scrollToBottom(), 1000);
      }
      this.lastMessage = message;
    }
  }

  private scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  public fromMe(message): boolean {
    return message.sender !== this.conversation.createdBy;
  }

  public trackMessage(index, message) {
    return message ? message.id : undefined;
  }

  public trackNote(index, note) {
    return note ? note.id : undefined;
  }

  public isConversationClosed(): boolean {
    return this.conversation.status === 'Closed';
  }

  public getCmsInstance({ id, type }): ICMS {
    return this.sharedService.cmsSystems.get(`${type}:${id}`);
  }

  public tabChange(event) {
    if (event.nextId === 'chat') {
      setTimeout(() => this.scrollToBottom(), 1000);
    }
    this.currentCms = null;
    this.tabId = event.nextId;
    this.hiddenActionPanel = true;
    if (!['chat', 'history'].includes(this.tabId)) {
      const [type, id] = this.tabId.split(':');
      const cms = _.find(this._conversation.cms, { id, type });
      this.currentCms = cms ? this.getCmsInstance(cms) : null;
    }
  }

  public async changeCoreCms(cms) {
    const { id, type } = cms;

    await this.apiService.changeCoreCms({ id, type, conversationId: this.conversation.id }).toPromise();

    const coreCmsIndex = _.findIndex(this.conversation.cms, { isCore: true });
    const currentCmsIndex = _.findIndex(this.conversation.cms, { id, type });

    _.set(this.conversation.cms, `[${coreCmsIndex}].isCore`, false);
    _.set(this.conversation.cms, `[${currentCmsIndex}].isCore`, true);

    this._conversation.cms = _.orderBy(this.conversation.cms, 'isCore', 'desc');
  }

  public loadMoreMessages(event = null) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    const updateQuery = (prev, data) => {
      const { fetchMoreResult } = data;

      if (!fetchMoreResult) {
        return prev;
      }

      const _res = pushMessages(
        prev as MessagesQuery,
        fetchMoreResult.allMessageConnection.messages,
        fetchMoreResult.allMessageConnection.nextToken
      );
      this.nextToken = fetchMoreResult.allMessageConnection.nextToken;
      this.completedFetching = false;
      this.fetchingMore = true;
      return _res;
    };

    return this.appsyncConversationService.loadMoreByObservedQuery(this.observedQuery, this.nextToken, { updateQuery });
  }

  public loadMoreNotes(event = null) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    const updateQuery = (prev, data) => {
      const { fetchMoreResult } = data;

      if (!fetchMoreResult) {
        return prev;
      }
      const _res = pushNotes(
        prev as NotesQuery,
        fetchMoreResult.getConversationNotes.notes,
        fetchMoreResult.getConversationNotes.nextToken
      );

      this.notesNextToken = fetchMoreResult.getConversationNotes.nextToken;
      this.completedNotesFetching = false;
      this.fetchingNotesMore = true;
      return _res;
    };

    return this.appsyncConversationService.loadMoreByObservedQuery(this.notesObservedQuery, this.notesNextToken, {
      updateQuery,
    });
  }

  private getConversationById() {
    return this.appsyncConversationService
      .getConversationById(this._conversation.id)
      .then(({ data: { getConversationById: conversation } }) => {
        this.onUpdateConversation.emit(_.merge(this._conversation, prepareConversation(conversation)));
      });
  }

  private getConversationHistory() {
    if (this.conversation.history && this.conversation.history.length) {
      this.conversationHistory = this.conversation.history;
      return undefined;
    }

    this.isHistoryLoading = true;

    return getConversationHistory(this.conversation.botSessionId)
      .then((data) => {
        this.logService.log('[getConversationHistory]', data);
        this.conversationHistory = data.messages;
      })
      .catch((error) => {
        this.logService.error('[getConversationHistory Error]', error);
      })
      .then(() => (this.isHistoryLoading = false));
  }
}
