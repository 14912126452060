import { Component, Input, ViewEncapsulation } from '@angular/core';
import { RouteService } from '../services/route.service';
import { SharedService } from '../services/shared.service';
import { AppsyncChatService } from '../services/appsync/appsync-chat.service';
import routes from '../routes';

@Component({
  selector: 'chat-topbar',
  templateUrl: './chat-topbar.component.html',
  styleUrls: ['./chat-topbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatTopbarComponent {
  @Input() users = [];

  title: string = '';
  route = '';
  _routes = routes;
  chats = [];
  me;

  mapRoutToTitle = {
    [routes.USERS_LIST]: 'Users',
    [routes.CREATE_CHAT]: 'Users',
    [routes.CHATS_LIST]: 'Chats'
  };

  constructor(
    public routeService: RouteService,
    private sharedService: SharedService,
    private appsyncChatService: AppsyncChatService
  ) {
    this.sharedService.currentUser.subscribe(user => (this.me = user));
    this.routeService.currentRoute.subscribe(value => {
      this.route = value;
      this.setTitle();
    });
    this.sharedService.currentChat.subscribe(userChat => {
      if (this.route === routes.CHAT) {
        this.title = userChat.chat.name;
      }
    });
    this.sharedService.currentUserChats.subscribe(chats => (this.chats = chats));
  }

  private setTitle() {
    this.title =
      this.route === routes.CHAT
        ? (this.title = this.sharedService.openedChat.chat.name)
        : this.mapRoutToTitle[this.route];
  }

  allowLeaveChat() {
    return this.route === routes.CHAT && this.sharedService.openedChat.associated.length !== 1;
  }

  async leaveChat() {
    this.routeService.setRoute(routes.CHATS_LIST);
    this.sharedService.onLeaveChat.emit(this.sharedService.openedChat.chatId);
    await this.appsyncChatService.leaveChat(this.sharedService.openedChat);
  }

  async editChatName(value) {
    await this.appsyncChatService.updateChatName(this.sharedService.openedChat.chat, value);
  }

  get connectedAgents(): number {
    return this.users.filter(u => u.connected === 'true').length;
  }
}
