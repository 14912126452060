import { Injectable } from '@angular/core';
import { FetchMoreOptions, FetchMoreQueryOptions, FetchPolicy } from 'apollo-client';
import * as _ from 'lodash';
import { constants, updateUserNotification } from '@cation/core/util/chat-helper';
import { AppsyncOperationService } from '@cation/core/services/appsync/appsync-operation.service';
import * as subscriptions from '@cation/core/graphql/subscriptions';
import * as mutations from '@cation/core/graphql/mutations';
import UserNotification from '@cation/core/types/userNotification';
import * as queries from '@cation/core/graphql/queries';
import { getUserConversationConnectionThroughUserQuery as ConvosQuery } from '@cation/core/graphql/operation-result-types';

@Injectable({
  providedIn: 'root'
})
export class AppsyncUserNotificationService extends AppsyncOperationService {
  getUserNotifications(userId, observerOrNext, updateQuery) {
    const watchQueryOptions = {
      query: queries.getUserNotifications,
      variables: { first: constants.notificationFirst, userId },
      fetchPolicy: 'cache-and-network' as FetchPolicy
    };

    const watchQuerySubscriptionOptions = { observerOrNext };

    const subscribeToMoreOptions = _.merge(
      { onError: () => this.forceReloadApp() },
      {
        document: subscriptions.subscribeToUserNotifications,
        variables: { userId },
        updateQuery
      }
    );

    return this.loadAndSubscribeMore<ConvosQuery>({
      watchQueryOptions,
      watchQuerySubscriptionOptions,
      subscribeToMoreOptions
    });
  }

  loadMoreUserNotifications(observedQuery, nextToken: string, fetchMoreOptions) {
    return this.loadMore(observedQuery, nextToken, fetchMoreOptions);
  }

  readUserNotification(notification: UserNotification) {
    this.logService.log('[AppsyncUserNotificationService readUserNotification]');

    const options = {
      mutation: mutations.readUserNotification,
      variables: {
        userId: notification.userId,
        id: notification.id,
        data: JSON.stringify(notification.data)
      },

      optimisticResponse: () => ({
        readUserNotification: {
          __typename: 'UserNotification',
          ...notification,
          data: JSON.stringify(notification.data),
          isRead: 1
        }
      }),

      update: (proxy, { data: { readUserNotification: _notification } }) => {
        const _options = {
          query: queries.getUserNotifications,
          variables: { first: constants.notificationFirst, userId: notification.userId }
        };

        const data = proxy.readQuery(_options);
        const _tmp = updateUserNotification(data, _notification);
        proxy.writeQuery({ ..._options, data: _tmp });
      }
    };

    return this.mutate(options);
  }
}
