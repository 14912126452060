<div class="container-fluid align-middle h-100">
  <div class="row">
    <div class="col-md-2 p-1">
      <div class="card ctn-full-height">
        <div class="card-header p-2 text-center">
          <span class="text-capitalize">{{ me?.username }}</span>
        </div>

        <div class="card-body conversation-list-container p-1 d-flex h-100 flex-column">
          <mat-form-field class="ctn-user-select">
            <mat-select
              name="users"
              [placeholder]="'CHAT.FILTER_SELECT.PLACEHOLDER' | translate"
              [(ngModel)]="selectedConversationFilter"
              (selectionChange)="onSelectConversationFilter()"
            >
              <mat-option>{{ 'CHAT.FILTER_SELECT.ALL' | translate }}</mat-option>
              <mat-option *ngFor="let filter of conversationFilters" [value]="filter">
                {{ 'CHAT.FILTER_SELECT.' + filter.toUpperCase() | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="conversation-list-wrapper">
            <mat-accordion [multi]="true" [@listFadeInOut]="teams.length">
              <mat-expansion-panel *ngFor="let team of teams" [expanded]="true">
                <mat-expansion-panel-header class="p-2">
                  <mat-panel-title>
                    <div class="d-flex align-items-center">
                      <div *ngIf="team.icon" class="d-flex justify-content-center align-items-center entity__icon pe-1">
                        <app-s3-image [image]="team.icon"></app-s3-image>
                      </div>
                      {{ team.name }}
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="mat-expansion-panel-body p-1">
                  <mat-accordion [multi]="true" [@listFadeInOut]="teamsData[team.id]?.users?.length">
                    <mat-expansion-panel *ngFor="let user of teamsData[team.id].users" [expanded]="true">
                      <mat-expansion-panel-header class="p-2">
                        <mat-panel-title>
                          <div class="d-flex align-items-center">
                            <div
                              *ngIf="user.userData?.avatarUrl || user.avatarUrl"
                              class="d-flex justify-content-center align-items-center entity__icon me-1"
                            >
                              <app-user-template
                                class="width-100 icon-30"
                                [user]="user"
                                [displayUserName]="false"
                                tooltipField="userData.status"
                              >
                              </app-user-template>
                            </div>
                            {{ user.username }}
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>

                      <div class="mat-expansion-panel-body p-1">
                        <app-convo-list
                          [user]="user"
                          [current]="conversation"
                          (onConvosLoad)="loadConversations($event)"
                          (onConvoClick)="setNewConvo($event)"
                          (onUpdateConversation)="setNewConvo($event)"
                          pageScroll
                          href="#chat"
                        >
                        </app-convo-list>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <div class="d-flex justify-content-center align-items-center pt-2">
              <button
                *ngIf="teamsNextToken"
                type="button"
                class="btn btn-sm btn-outline-primary"
                (click)="loadMoreTeams()"
              >
                {{ 'BUTTON.LOAD_MORE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-10 p-1 ctn-full-height" id="chat">
      <div *ngIf="!!conversation; else instructions" [@fadeInOut]>
        <app-message-view
          [senderId]="me?.cognitoId"
          (onUpdateConversation)="setNewConvo($event)"
          (onCloseConversation)="onCloseConversation()"
          [conversation]="conversation"
          [messagesData]="messagesData[conversation.id]"
          [notesData]="notesData[conversation.id]"
        >
        </app-message-view>
      </div>
    </div>
  </div>
</div>

<ng-template #instructions>
  <div class="ctn-full-height bg-light pt-5 border rounded text-center text-middle instructions h5 instructions-text">
    <p [@fadeInOut]>
      {{ 'CHAT.WELCOME' | translate }},
      <strong class="text-capitalize">
        {{ me?.username }}
      </strong>
      !
    </p>
    <p [@fadeInOut]>{{ 'CHAT.INSTRUCTION' | translate }}</p>
  </div>
</ng-template>
