import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import Team from '@cation/core/types/team';

@Component({
  selector: 'app-review-team-details-view',
  templateUrl: './review-team-details-view.component.html',
  styleUrls: ['./review-team-details-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReviewTeamDetailsViewComponent implements OnInit {
  public selectedTab = new UntypedFormControl(0);
  @Input() team: Team;

  public errorMessage;
  public isLoading = false;

  constructor(public auth: AuthHelper) {}

  async ngOnInit() {}
}
