import { AppService } from '@cation/core/services/app/app.service';
import { ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from '@cation/core/services/log/log.service';
import { ConversationService } from '@cation/core/services/conversation/conversation.service';
import { UserNotificationService } from '@cation/core/services/user-notification/user-notification.service';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import * as _ from 'lodash';
import { ThemeService } from '@cation/core/services/theme/theme.service';
import { UrlParserService } from '@cation/core/services/url-parser/url-parser.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoggedIn = false;

  isInternalChatEnabled = !!_.get(environment, 'internalChatConfig', false);

  @HostBinding('class.engageHub-theme') private engageHubTheme = false;

  // Init Auth helper here so it's constructor subscribe to auth events
  constructor(
    private userNotificationService: UserNotificationService,
    private conversationService: ConversationService,
    public authHelper: AuthHelper,
    private translate: TranslateService,
    private logService: LogService,
    public auth: AuthHelper,
    private router: Router,
    private themeService: ThemeService,
    private urlParserService: UrlParserService,
    private appService: AppService,
    private ref: ChangeDetectorRef
  ) {
    this.urlParserService.parseClient();
    this.engageHubTheme = this.urlParserService.isEngageHub;
    this.setTheme();

    translate.addLangs(['en', 'fr', 'de', 'es', 'it', 'pl']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();

    this.logService.info('[browserLang]', browserLang);

    translate.use(browserLang.match(/en|fr|de|es|it|pl/) ? browserLang : 'en');
  }

  ngOnInit(): void {
    console.log('### AppComponent::ngOnInit');

    this.authHelper.isAuthenticated$.subscribe(isAuthenticated => {
      console.log('### AppComponent::this.authHelper.isAuthenticated$ ###', isAuthenticated);
      if (!this.isLoggedIn && isAuthenticated) setTimeout(() => this.initGlobalServices(), 0);
      this.isLoggedIn = isAuthenticated;
      // this.ref.detectChanges()
    });

    this.authHelper.signOut$.subscribe(() => {
      console.log('### AppComponent::this.authHelper.signOut$ ###');
      this.router.navigate(['/login']);
    });
  }

  initGlobalServices() {
    this.userNotificationService.init();
    this.conversationService.init();
  }

  setTheme() {
    switch (true) {
      case this.engageHubTheme:
        this.themeService.setEngageHubTheme();
        // this.appService.setTitle('Engage Hub');
        this.appService.setTitle('parly engage');
        break;
      default:
        this.themeService.setDefaultTheme();
        this.appService.setTitle('parly engage');
    }
  }
}
