<button
  type="button"
  class="internal-chat-trigger-button"
  [class.internal-chat-trigger-button_inline]="inline"
  (click)="toggleInternalChat($event)"
>
  <div [@openClose]="isChatOpened" class="ctn-icons">
    <mat-icon *ngIf="!isChatOpened">chat</mat-icon>
    <mat-icon *ngIf="isChatOpened" class="close-icon">keyboard_arrow_right</mat-icon>
  </div>

  <span *ngIf="countUnreadMessages" class="internal-chat-trigger-button_badge">{{ countUnreadMessages }}</span>
</button>
