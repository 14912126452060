<div class="ctn">
  <ngx-loading [show]="apiHook.isLoading()"></ngx-loading>

  <div *ngIf="isList">
    <div class="ctn-header">
      <div class="search-field">
        <app-search-field
          [value]="searchValue"
          [areaLabel]="'ADMIN_MANAGE_USER.SEARCH_AREA_LABEL' | translate"
          (onSearchEvent)="onSearch($event)"
        >
        </app-search-field>
      </div>
      <button type="button" class="btn-add" (click)="onAddUser()">
        {{ 'ADMIN_MANAGE_USER.ADD_BUTTON_TEXT' | translate }}
        <span class="btn-add-icon">
          <mat-icon>add_circle</mat-icon>
        </span>
      </button>
    </div>

    <app-users-list
      [setItems]="listUsers"
      [setSearchValue]="searchValue"
      [deleteUserFromList]="deletedUid"
      (onItemClick)="toggleView($event)"
      (onDelete)="onDeleteUser($event)"
    >
    </app-users-list>
  </div>

  <mat-card *ngIf="!isList" class="ctn-card">
    <button type="button" class="btn-back" (click)="toggleView(null)">Back to List</button>
    <form class="ctn-form" #userForm="ngForm">
      <mat-card-content>
        <section *ngIf="user?.cognito">
          <div class="ctn-user-avatar" *ngIf="user?.groups.includes('AGENT') || user?.groups.includes('TEAM_LEAD')">
            <app-image-picker
              [isRounded]="true"
              [keyPrefix]="user.cognitoId"
              [image]="user.avatarUrl"
              (onImageSelect)="onAvatarSelect($event)"
            ></app-image-picker>
          </div>

          <mat-slide-toggle name="userenabled" class="ctn-full-width my-3" [(ngModel)]="user.enabled">
            {{ 'ADMIN_MANAGE_USER.ENABLE_USER_SLIDE_TEXT' | translate }}
          </mat-slide-toggle>

          <mat-form-field class="ctn-user-form">
            <input
              matInput
              required
              name="username"
              [placeholder]="'ADMIN_MANAGE_USER.USERNAME_INPUT_PLACEHOLDER' | translate"
              [(ngModel)]="user.username"
              [disabled]="true"
            />
          </mat-form-field>
          <mat-form-field class="ctn-user-form" *ngIf="user?.groups.includes('AGENT')">
            <input
              matInput
              required
              name="displayname"
              [placeholder]="'ADMIN_MANAGE_USER.DISPLAY_NAME_INPUT_PLACEHOLDER' | translate"
              [(ngModel)]="user.displayName"
            />
          </mat-form-field>
          <!-- <mat-form-field class="ctn-user-form" *ngIf="user?.groups.includes('AGENT')">
            <input matInput placeholder="Avatar" [(ngModel)]="user.avatarUrl">
          </mat-form-field> -->

          <mat-form-field class="ctn-user-form">
            <input
              matInput
              required
              name="email"
              [placeholder]="'ADMIN_MANAGE_USER.EMAIL_INPUT_PLACEHOLDER' | translate"
              [(ngModel)]="user.email"
            />
          </mat-form-field>
          <mat-form-field class="ctn-user-form">
            <input
              matInput
              required
              name="phone"
              [placeholder]="'ADMIN_MANAGE_USER.PHONE_NUMBER_INPUT_PLACEHOLDER' | translate"
              [(ngModel)]="user.phoneNumber"
            />
          </mat-form-field>

          <mat-form-field class="ctn-user-form">
            <mat-select
              multiple
              [placeholder]="'ADMIN_MANAGE_USER.GROUPS_SELECT_PLACEHOLDER' | translate"
              [(value)]="user.groups"
            >
              <mat-option [value]="group" *ngFor="let group of allGroups">{{ group }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="ctn-user-form" *ngIf="user?.groups.includes('AGENT')">
            <mat-select
              multiple
              name="specialization"
              [placeholder]="'ADMIN_MANAGE_USER.SPECIALIZATION_SELECT_PLACEHOLDER' | translate"
              [(ngModel)]="user.keywords"
            >
              <mat-option *ngFor="let topic of allKeywords" [value]="topic">{{ topic }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="ctn-user-form" *ngIf="user?.groups.includes('AGENT')">
            <mat-select
              multiple
              name="locale"
              [placeholder]="'ADMIN_MANAGE_USER.LOCALE_SELECT_PLACEHOLDER' | translate"
              [(ngModel)]="user.locales"
            >
              <mat-option *ngFor="let locale of Locale" [value]="locale.code">
                {{ locale.name }} ({{ locale.code }})
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div
            class="ctn-user-form mat-form-field mat-form-field-appearance-legacy"
            *ngIf="user?.groups.includes('AGENT')"
          >
            <app-team-input-autocomplete [initialTeamId]="user.teamId" (selectionChange)="teamCtrl.setValue($event)">
            </app-team-input-autocomplete>
          </div>

          <!-- <div class="form-group ctn-user-form">
            <label for="keywords" class="text-muted small">Keywords</label>
            <tag-input matInput [(ngModel)]="user.keywords" name="keywords"> </tag-input>
          </div>
          <div class="form-group ctn-user-form">
            <label for="keywords" class="text-muted small">Locales</label>
            <tag-input [(ngModel)]="user.locales" name="locales"> </tag-input>
          </div> -->
        </section>

        <section *ngIf="user?.cognito">
          <h5 class="ctn-h3 ctn-margin">
            <fa-icon [icon]="faTasks"></fa-icon>
            {{ 'ADMIN_MANAGE_USER.VOICE_INTEGRATION.TITLE' | translate }}
          </h5>

          <mat-form-field class="ctn-user-form">
            <input
              matInput
              [placeholder]="'ADMIN_MANAGE_USER.VOICE_INTEGRATION.VOICE_USERNAME_INPUT_PLACEHOLDER' | translate"
              name="voiceUsername"
              [(ngModel)]="user.voiceUsername"
            />
          </mat-form-field>
        </section>

        <section *ngIf="user?.cmsUserData">
          <h5 class="ctn-h3 ctn-margin">
            <fa-icon [icon]="faTasks"></fa-icon> &nbsp;
            {{ 'ADMIN_MANAGE_USER.CMS_INTEGRATIONS.TITLE' | translate }}
            <button
              mat-icon-button
              color="primary"
              [title]="'ADMIN_MANAGE_USER.CMS_INTEGRATIONS.ADD_BUTTON_TEXT' | translate"
              *ngIf="freeCmsTypes.length > 0"
              [matMenuTriggerFor]="menu"
            >
              <mat-icon>add_box</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngFor="let cmsType of freeCmsTypes; let i = index" (click)="onAddCms(cmsType)">
                {{ cmsType }}
              </button>
            </mat-menu>
          </h5>

          <div *ngFor="let cms of user?.cmsUserData; let i = index">
            <mat-form-field class="ctn-user-form">
              <input
                matInput
                required
                [placeholder]="'ADMIN_MANAGE_USER.CMS_INTEGRATIONS.TYPE_INPUT_PLACEHOLDER' | translate"
                [name]="'cmsType' + cms.type"
                [value]="cms.type"
                [disabled]="true"
              />
            </mat-form-field>

            <mat-form-field class="ctn-user-form">
              <input
                matInput
                required
                [placeholder]="'ADMIN_MANAGE_USER.CMS_INTEGRATIONS.USER_ID_INPUT_PLACEHOLDER' | translate"
                [name]="'cmsUserId' + cms.type"
                [(ngModel)]="cms.userId"
              />
            </mat-form-field>

            <button
              mat-icon-button
              color="primary"
              (click)="onDeleteCms(i)"
              [title]="'ADMIN_MANAGE_USER.CMS_INTEGRATIONS.DELETE_BUTTON_TEXT' | translate"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </section>

        <div class="card-group my-3" *ngIf="user?.cognito">
          <!--
          <div class="card">
            <div class="card-header p-1">Phone Verified</div>
            <div class="card-body p-1">{{ getUserAttribute('phone_number_verified') }}</div>
          </div>
          <div class="card">
            <div class="card-header p-1">Email Verified</div>
            <div class="card-body p-1">{{ getUserAttribute('email_verified') }}</div>
          </div>
          -->
          <div class="card">
            <div class="card-header p-1">
              {{ 'ADMIN_MANAGE_USER.CMS_INTEGRATIONS.USER_ID_TITLE' | translate }}
            </div>
            <div class="card-body p-1">{{ user.cognitoId }}</div>
          </div>
          <div class="card">
            <div class="card-header p-1">
              {{ 'ADMIN_MANAGE_USER.CMS_INTEGRATIONS.STATUS_TITLE' | translate }}
            </div>
            <div class="card-body p-1">{{ user.cognito.UserStatus }}</div>
          </div>
          <div class="card">
            <div class="card-header p-1">
              {{ 'ADMIN_MANAGE_USER.CMS_INTEGRATIONS.CREATED_DATE_TITLE' | translate }}
            </div>
            <div class="card-body p-1">{{ user.cognito.UserCreateDate }}</div>
          </div>
          <div class="card">
            <div class="card-header p-1">
              {{ 'ADMIN_MANAGE_USER.CMS_INTEGRATIONS.LAST_MODIFIED_DATE_TITLE' | translate }}
            </div>
            <div class="card-body p-1">{{ user.cognito.UserLastModifiedDate }}</div>
          </div>
        </div>

        <!-- <section *ngIf="user?.cognito" class="mt-3">
          Note:
          <ul>
            <li>
              Todo
            </li>
          </ul>
        </section> -->
      </mat-card-content>

      <div class="alert alert-danger pt-2" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>

      <mat-card-actions *ngIf="selectedCognitoUser" class="pt-2">
        <button
          mat-raised-button
          color="primary"
          [title]="'BUTTON.SAVE' | translate"
          class="me-2"
          (click)="onUpdateUser()"
          [disabled]="userForm.invalid"
        >
          <mat-icon>save</mat-icon>
          {{ 'BUTTON.SAVE' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          (click)="onSetNewPassword()"
          [title]="'ADMIN_MANAGE_USER.SET_NEW_PASSWORD_BUTTON_TEXT' | translate"
        >
          <mat-icon>save</mat-icon>
          {{ 'ADMIN_MANAGE_USER.SET_NEW_PASSWORD_BUTTON_TEXT' | translate }}
        </button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
