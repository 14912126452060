<div style="display: grid;" class="w-100 h-100">
  <ngx-charts-advanced-pie-chart
    #chart
    [valueFormatting]="onValueFormat"
    [scheme]="colorScheme"
    [results]="single"
    [label]="'MY_STATISTICS.TOTAL_LABEL' | translate"
    [gradient]="gradient"
  >
    <ng-template #tooltipTemplate *ngIf="metricField === 'conversationsDuration'" let-model="model">
      <div class="d-flex flex-column p-2">
        <span>{{ model.label }}</span>
        <span>{{ onValueFormat(model.value) }}</span>
      </div>
    </ng-template>
  </ngx-charts-advanced-pie-chart>
</div>
