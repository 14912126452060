import gql from 'graphql-tag';

export default gql`
  query searchUsers($name: String) {
    searchUsers(name: $name) {
      __typename
      id
      name
      avatarUrl
      lastActiveTime
    }
  }
`;
