export class UserListItemModel {
  uid = '';
  avatarUrl = '';
  username = '';
  nickname? = '';
  roles: string[] = [];
  teamImageUrl? = '';
  teamName? = '';
  teamDescription? = '';
  isEnabledField? = false;
  enabled? = false;
  email? = '';
  emailVerified? = '';
  phone? = '';
  phoneVerified? = '';
  canBeDeleted? = false;
}
