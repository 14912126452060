<div class="text-center">
  <h1 mat-dialog-title class="d-inline-block">{{ 'ADMIN_AGENTS_AUDIT.META_DATA_TITLE' | translate }}</h1>
  <button mat-icon-button [tabIndex]="-1" type="button" class="close" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="content-container d-flex justify-content-center align-items-center">
  <pre class="content">{{ data | json }}</pre>
</div>
