import gql from 'graphql-tag';

export default gql`
  query getConversationsByCustomerId($customerId: ID!, $after: String, $first: Int) {
    getConversationsByCustomerId(customerId: $customerId, after: $after, first: $first) {
      __typename
      nextToken
      conversations {
        __typename
        id
        intentsTriggered
        name
        caseId
        createdAt
        createdBy
        history
        locale
        location
        sentiment
        keywords
        status
        lastQuestion
        channel
        mailData
      }
    }
  }
`;
