<div>
  <mat-form-field class="ctn-full-width">
    <mat-select #singleSelect class="p-0" [required]="required" [formControl]="activityCtrl" [placeholder]="placeholder">
      <mat-option>
        <ngx-mat-select-search [formControl]="activitiesFilterCtrl" [placeholderLabel]="placeholderLabel">
          <mat-icon ngxMatSelectSearchClear *ngIf="!isFinding">close</mat-icon>
          <mat-icon ngxMatSelectSearchClear *ngIf="isFinding">
            <mat-progress-spinner matSuffix mode="indeterminate" diameter="23"></mat-progress-spinner>
          </mat-icon>
        </ngx-mat-select-search>
      </mat-option>

      <mat-option
        *ngFor="let activity of filteredActivities | async; trackBy: trackItem"
        [value]="activity"
      >
        {{ 'ADMIN_AGENTS_AUDIT.ACTIVITIES.' + activity | translate }}
      </mat-option>
    </mat-select>
    <button
      mat-button
      *ngIf="activityCtrl.value"
      matSuffix
      mat-icon-button
      [attr.aria-label]="'BUTTON.CLEAR' | translate"
      (click)="resetActivity($event)"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error *ngIf="errorLabel">{{ errorLabel }}</mat-error>
  </mat-form-field>

  <mat-progress-bar class="m-2" *ngIf="isLoading" [mode]="'indeterminate'" [@fadeInOut]></mat-progress-bar>
</div>