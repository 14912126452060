import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ConfirmOverlayComponent, BaseModalOverlayComponent } from '@cation/core/components/overlays';
import { ModalOverlayDataModel } from '@cation/core/models/overlays';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ModalOverlayService {
  private OVERLAY_CONFIG = {
    width: '100%',
    height: '100%'
  };

  private defaultOverlayConfig = {
    hasBackdrop: true
  };
  private overlayRef: OverlayRef | null;
  private modalResult$: Subject<any> = new Subject();

  constructor(private overlay: Overlay) {}

  public openModal(
    component: ComponentType<BaseModalOverlayComponent>,
    overrideConfig: OverlayConfig = {},
    data: ModalOverlayDataModel
  ): Observable<any> {
    this.closeModal(null);

    const configs = new OverlayConfig({
      ...this.defaultOverlayConfig,
      ...overrideConfig
    });

    this.overlayRef = this.overlay.create(configs);

    const instance: BaseModalOverlayComponent = this.overlayRef.attach(new ComponentPortal(component)).instance;

    instance.data = data;
    instance.confirmEvent.subscribe(modalResult => this.closeModal(modalResult));

    return this.modalResult$.pipe(take(1));
  }

  public closeModal(modalResult: any) {
    this.modalResult$.next(modalResult);

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  public openConfirmModal(data: ModalOverlayDataModel): Observable<boolean> {
    return this.openModal(ConfirmOverlayComponent, this.OVERLAY_CONFIG, data);
  }
}
