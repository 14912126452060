import gql from 'graphql-tag';

export default gql`
  mutation readUserNotification($userId: ID!, $id: ID!, $data: AWSJSON, $isRead: Int) {
    readUserNotification(userId: $userId, id: $id, data: $data, isRead: $isRead) {
      __typename
      id
      userId
      isRead
      icon
      title
      type
      data
      description
    }
  }
`;
