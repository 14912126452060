import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiHook } from '@cation/core/services/api/api-hook';
import { delay, finalize, tap } from 'rxjs/operators';
import { LogService } from '@cation/core/services/log/log.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private apiHook: ApiHook, private logService: LogService) {
    this.logService.info('-------LoadingInterceptor-------');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.apiHook.loading();
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          this.logService.debug('[LoadingInterceptorbResponse]', event);
        }
      }),
      delay(0),
      // Log when response observable either completes or errors
      finalize(() => {
        this.apiHook.complete();
      })
    );
  }
}
