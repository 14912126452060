import { Component, Input } from '@angular/core';

@Component({
  selector: 'internal-chat-info',
  templateUrl: 'chat-info.component.html',
  styleUrls: ['./chat-info.component.scss']
})
export class ChatInfoComponent {
  @Input() chat;
  @Input() unreadMessages;
}
