import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { AppsyncService } from '@cation/core/services/appsync/appsync.service';
import { ApiService } from '@cation/core/services/api/api.service';
import User from '@cation/core/types/user';
import { listFadeInOut } from '@cation/core/animations';
import { DialogService } from '@cation/core/services/dialog/dialog.service';

@Component({
  selector: 'app-similar-cases-view',
  templateUrl: './similar-cases-view.component.html',
  styleUrls: ['./similar-cases-view.component.scss'],
  animations: [listFadeInOut]
})
export class SimilarCasesViewComponent implements OnInit {
  @Input() user: User;
  @Input() isFullScreen: boolean = false;
  @Input() withFilters: boolean = false;

  public conversations = {};
  public conversationsLength = 0;
  public date;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public keywordCtrl = new UntypedFormControl();
  public filteredKeywords: Observable<string[]>;
  public allKeywords: string[] = [];
  public searchFilter = { text: '', keywords: [], createdAt: null };
  public isLoading = false;

  @ViewChild('keywordInput') keywordInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private apiService: ApiService,
    private logService: LogService,
    private appsync: AppsyncService,
    private s3Service: S3Service,
    private dialogService: DialogService
  ) {
    this.filteredKeywords = this.keywordCtrl.valueChanges.pipe(
      startWith(),
      map(() => this._filter())
    );
  }

  async ngOnInit() {
    await this.getAllKeywords();
  }

  openCustomerHistory(conversationId) {
    this.dialogService.openCustomerHistory(conversationId);
  }

  async searchConversations() {
    this.logService.info('searchConversations', this.searchFilter);

    const { text, createdAt } = this.searchFilter;

    const inputText = text;

    if (!inputText) {
      return;
    }

    this.isLoading = true;
    this.conversations = {};

    try {
      const body = Object.assign(
        { maxResult: 50, message: inputText, createdAt },
        this.user ? { agentId: this.user.cognitoId } : {}
        // keywords ? { keywords } : {} // TODO: Enable after updating on the backend side
      );

      const { items } = await this.apiService.findSimilarCases(body).toPromise();

      this.logService.info('[searchConversations] Response:', `*${inputText}*`, items);

      const promises = _.map(items, async item => ({
        ...item,
        message: await this.s3Service.updateAccessToImageUrls(item.message)
      }));

      const sources = await Promise.all(promises);

      this.conversations = _.groupBy(sources, 'conversationId');
      this.conversationsLength = Object.keys(this.conversations).length;
    } catch (e) {
      this.logService.info('[searchConversations] Error:', e);
    }

    this.isLoading = false;
  }

  getKeywords(item) {
    return _.get(item, '[0].conversation.keywords', ['Unknown']).join(', ');
  }

  async getAllKeywords() {
    const data: any = await this.apiService.getKeywords().toPromise();
    this.logService.log('[getAllKeywords data]', data);
    this.allKeywords = ['Any', ...data.map(e => e.PrimaryTopic)];
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        this.searchFilter.keywords.push(value.trim());
      }

      if (input) {
        input.value = '';
      }

      this.keywordCtrl.setValue(null);
    }
  }

  remove(keyword: string): void {
    const index = this.searchFilter.keywords.indexOf(keyword);

    if (index >= 0) {
      this.searchFilter.keywords.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.searchFilter.keywords.push(event.option.viewValue);
    this.keywordInput.nativeElement.value = '';
    this.keywordCtrl.setValue(null);
  }

  private _filter(): string[] {
    return this.allKeywords.filter(keyword => !this.searchFilter.keywords.includes(keyword));
  }

  onDateChange() {
    try {
      this.searchFilter.createdAt = {
        from: moment(this.date.begin).format(),
        to: moment(this.date.end).format()
      };
    } catch (e) {
      this.searchFilter.createdAt = null;
    }
  }
}
