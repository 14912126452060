<div class="ctn">
  <div class="card chat-card">
    <div class="card-header chat-header p-2" *ngIf="conversation">
      <div class="chat-header-left-actions text-start">
        <app-cms-actions
          [conversation]="conversation"
          (onAddCmsSystem)="onAddCmsSystem.emit($event)"
          (onCloseConversation)="closeConversation()"
        >
        </app-cms-actions>
        <button mat-icon-button class="me-2 material-button-with-icon" (click)="onOpenInviteAgentModal()">
          <mat-icon [matTooltip]="'CONVERSATION_VIEW.INVITE_AGENT_BUTTON_TOOLTIP' | translate">person_add</mat-icon>
        </button>
        <button
          *ngIf="!!conversation.transfers?.length"
          mat-icon-button
          class="me-2 material-button-with-icon"
          (click)="openModal(listTransfersModal, 'lg'); (false)"
        >
          <mat-icon [matTooltip]="'CONVERSATION_VIEW.TRANSFERS_FILE_BUTTON_TOOLTIP' | translate">
            call_split
          </mat-icon>
        </button>
        <button
          mat-icon-button
          class="me-2 material-button-with-icon"
          [matBadge]="notes.length ? '*' : ''"
          matBadgeColor="accent"
          matBadgeSize="small"
          matBadgePosition="above after"
          (click)="drawer.toggle()"
        >
          <mat-icon [matTooltip]="'CONVERSATION_VIEW.NOTES_BUTTON_TOOLTIP' | translate">sticky_note_2</mat-icon>
        </button>

        <!-- Video chat trigger -->
        <button
          *ngIf="enabledVideoBtn"
          mat-icon-button
          class="me-2 material-button-with-icon"
          (click)="openVideoChat()"
        >
          <mat-icon>videocam</mat-icon>
        </button>
      </div>
      <div class="fw-bold">
        <img
          *ngIf="conversation.author.userData?.profilePicUrl"
          [src]="conversation.author.userData?.profilePicUrl"
          class="avatar"
          alt="avatar"
        />
        {{ conversation?.name }}

        <span class="ps-2">
          <i class="material-icons ctn-icon-middle" [matTooltip]="'SENTIMENT.' + conversation.sentiment | translate">
            {{ mapSentimentToIcon[conversation.sentiment] }}
          </i>
        </span>

        <span class="ps-2">
          <span
            class="flag-icon flag-icon-{{ localeUtil.getRegion(conversation.locale).toLowerCase() }}"
            [matTooltip]="'LOCALE.' + localeUtil.getName(conversation.locale) | translate"
          ></span>
        </span>
      </div>

      <div class="chat-header-right-actions text-end">
        <button
          *ngIf="isMainAgent"
          mat-icon-button
          class="me-2 material-button-with-icon"
          mwlConfirmationPopover
          placement="bottom"
          [popoverTitle]="'CONVERSATION_VIEW.DELETE_POPOVER_TITLE' | translate"
          [popoverMessage]="'CONVERSATION_VIEW.DELETE_POPOVER_MESSAGE' | translate"
          [confirmText]="'BUTTON.YES' | translate"
          [cancelText]="'BUTTON.NO' | translate"
          (confirm)="endConversation()"
          (cancel)="(false)"
        >
          <mat-icon [matTooltip]="'CONVERSATION_VIEW.DELETE_CONVERSATION_TOOLTIP' | translate">done_all</mat-icon>
        </button>
        <button
          *ngIf="!isMainAgent"
          mat-icon-button
          class="me-2 material-button-with-icon"
          (click)="disconnectConversation()"
        >
          <mat-icon [matTooltip]="'CONVERSATION_VIEW.DISCONNECT_CONVERSATION_TOOLTIP' | translate">done_all</mat-icon>
        </button>
        <button mat-icon-button class="me-2 material-button-with-icon" (click)="onCloseConversation.emit()">
          <mat-icon [matTooltip]="'CONVERSATION_VIEW.HIDE_CONVERSATION_TOOLTIP' | translate">close</mat-icon>
        </button>
      </div>
    </div>

    <!-- Video chat -->
    <div *ngIf="showVideoChat">
      <!--
      <div class="d-flex justify-content-end">
        <button mat-icon-button color="primary" (click)="closeVideoChat()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      -->
      <div class="ctn-video m-2">
        <app-video-chat [conversation]="conversation" (closeEvent)="closeVideoChat()"></app-video-chat>
      </div>
    </div>

    <div class="running-line-container d-block fw-bold bg-light text-end rounded p-0" *ngIf="runningLine.length > 75">
      <span class="running-line-block running-line-animate">{{ runningLine }}</span>
    </div>
    <div class="d-block bg-light rounded px-2 py-1 text-center" *ngIf="runningLine.length <= 75">
      {{ runningLine }}
    </div>

    <mat-drawer-container class="drawer-container" autosize>
      <div>
        <ul ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="tabChange($event)">
          <li [ngbNavItem]="'chat'" id="chat">
            <a ngbNavLink class="nav-link" [innerText]="'CONVERSATION_VIEW.CHAT_TAB_TITLE' | translate"></a>
            <ng-template ngbNavContent>
              <div
                #scrollMe
                id="chatBox"
                class="card ctn-scroll chat p-2"
                (mousedown)="resetSelection()"
                [appInfscroll]="loadMoreMessages"
                [completedFetching]="completedFetching"
              >
                <ng-container
                  *ngFor="
                    let message of messages;
                    let i = index;
                    trackBy: trackMessage;
                    last as isLast;
                    first as isFirst
                  "
                >
                  <app-chat-message
                    [id]="message.id"
                    *ngIf="!message.isRead && !fromMe(message)"
                    inViewport
                    [inViewportOptions]="{ threshold: [0, 1] }"
                    (inViewportAction)="onIntersection($event, message)"
                    [index]="messages.length - (i + 1)"
                    [senderId]="senderId"
                    [message]="message"
                    [fromMe]="fromMe(message)"
                    [isLast]="isLast"
                    [isFirst]="isFirst"
                    [isHideMessageAuthor]="isHideMessageAuthor"
                    [conversation]="_conversation"
                    (added)="messageAdded(isFirst, $event)"
                  >
                  </app-chat-message>

                  <app-chat-message
                    [id]="message.id"
                    *ngIf="message.isRead || fromMe(message)"
                    [index]="messages.length - (i + 1)"
                    [senderId]="senderId"
                    [message]="message"
                    [fromMe]="fromMe(message)"
                    [isLast]="isLast"
                    [isFirst]="isFirst"
                    [isHideMessageAuthor]="isHideMessageAuthor"
                    [conversation]="_conversation"
                    (added)="messageAdded(isFirst, $event)"
                  >
                  </app-chat-message>
                </ng-container>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'history'" id="history">
            <a ngbNavLink class="nav-link" [innerText]="'CONVERSATION_VIEW.HISTORY_TAB_TITLE' | translate"></a>
            <ng-template ngbNavContent>
              <app-chat-conversation-history
                [conversation]="_conversation"
                [senderId]="senderId"
                [isHistoryLoading]="isHistoryLoading"
                [isFlexibleLayout]="true"
                [conversationHistory]="conversationHistory"
                [isConversationClosed]="isConversationClosed()"
              >
              </app-chat-conversation-history>
            </ng-template>
          </li>
          <ng-container *ngIf="!!conversation?.cms">
            <li
              [ngbNavItem]="cms.type + ':' + cms.id"
              [id]="cms.type + ':' + cms.id"
              *ngFor="let cms of conversation.cms; let i = index"
            >
              <a ngbNavLink class="nav-link">
                <div class="d-flex justify-content-center align-items-center">
                  {{ getCmsInstance(cms)?.name }}
                  <mat-icon [matTooltip]="'CMS_VIEW.CORE_TICKET_TOOLTIP' | translate" *ngIf="cms.isCore">
                    assignment_late
                  </mat-icon>

                  <button
                    *ngIf="!cms.isCore && currentCms?.type + ':' + currentCms?.id === cms.type + ':' + cms.id"
                    class="btn btn-outline-primary btn-action p-0 ms-1"
                    (click)="changeCoreCms(cms)"
                  >
                    <mat-icon [matTooltip]="'CMS_VIEW.SET_AS_CORE_TOOLTIP' | translate" class="p-0">publish</mat-icon>
                  </button>
                  <button
                    *ngIf="!cms.isCore && currentCms?.type + ':' + currentCms?.id === cms.type + ':' + cms.id"
                    class="btn btn-outline-primary btn-action p-0 ms-1"
                    (click)="detachCms(cms)"
                  >
                    <mat-icon [matTooltip]="'CMS_VIEW.DETACH_CMS' | translate" class="p-0">highlight_off</mat-icon>
                  </button>
                </div>
              </a>
              <ng-template ngbNavContent>
                <app-chat-cms-view
                  [isConversationClosed]="isConversationClosed()"
                  [isFlexibleLayout]="true"
                  [cms]="getCmsInstance(cms)"
                >
                </app-chat-cms-view>
              </ng-template>
            </li>
          </ng-container>
        </ul>
        <div [ngbNavOutlet]="nav" class="h-100"></div>
      </div>

      <mat-drawer #drawer class="drawer-sidenav" mode="side" position="end">
        <div class="notes-header py-2 m-0">
          <h6 class="d-inline">{{ 'CONVERSATION_NOTE_VIEW.TITLE' | translate }}</h6>
          <button
            class="btn btn-outline-primary btn-sm btn-action p-0 btn-note-add"
            (click)="onOpenAddConversationNoteModal()"
          >
            <mat-icon [matTooltip]="'CONVERSATION_NOTE_VIEW.ADD_BUTTON_TOOLTIP' | translate">add</mat-icon>
          </button>
        </div>
        <div
          id="notesBox"
          class="card ctn-scroll notes-box p-2 border-0"
          [appInfscroll]="loadMoreNotes"
          [completedFetching]="completedNotesFetching"
          appInfscrollDirection="down"
        >
          <ng-container *ngFor="let note of notes; trackBy: trackNote">
            <app-chat-note [note]="note" [conversation]="_conversation"></app-chat-note>
          </ng-container>
        </div>
      </mat-drawer>
    </mat-drawer-container>

    <div class="card-footer w-100 p-1 bg-transparent" [class.input_chat__closed]="isConversationClosed()">
      <span *ngIf="isConversationClosed()" class="d-block fw-bold bg-dark text-white text-end rounded p-2">
        {{ 'CONVERSATION_NOTE_VIEW.CONVERSATION_CLOSED_MESSAGE' | translate }}
        <button class="btn btn-success rounded" (click)="disconnectConversation()" type="button">
          {{ 'BUTTON.DISCONNECT' | translate }}
        </button>
      </span>
      <!-- && conversation.channel != 'VOICE' -->
      <app-chat-input
        *ngIf="!isConversationClosed()"
        [conversation]="conversation"
        [currentCms]="currentCms"
        [coreCms]="coreCms"
        [senderId]="senderId"
      >
      </app-chat-input>
    </div>
  </div>

  <div [@openClosePanel]="isSidePanelOpened ? 'open' : 'closed'" class="ctn-info-column chat-panel">
    <div class="ctn-customer-info-box ms-2">
      <ctn-customer-info [conversation]="conversation"></ctn-customer-info>
    </div>
    <div class="ctn-ai-advice-box pt-2 ms-2">
      <app-chat-ai-view [senderId]="senderId" [conversation]="conversation"></app-chat-ai-view>
    </div>
    <div class="ctn-canned-response-box pt-2 ms-2">
      <ctn-canned-response [locale]="conversation?.locale"></ctn-canned-response>
    </div>
  </div>

  <div class="ctn-info-column__sidebar">
    <button mat-icon-button color="primary" class="ctn-info-column__sidebar-btn" (click)="togglePanel()">
      <mat-icon *ngIf="!isSidePanelOpened">menu</mat-icon>
      <mat-icon *ngIf="isSidePanelOpened">menu_open</mat-icon>
    </button>
  </div>
</div>

<ng-template #listTransfersModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'CONVERSATION_NOTE_VIEW.TRANSFERS_MODAL.TITLE' | translate }}</h4>
    <button
      type="button"
      class="close"
      [attr.aria-label]="'BUTTON.CLOSE' | translate"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body p-0">
    <app-transfers-list-table *ngIf="conversation.transfers?.length > 0" [transfers]="conversation.transfers || []">
    </app-transfers-list-table>
  </div>
</ng-template>

<div #actionPanel class="action-control" [hidden]="hiddenActionPanel || !getSelectionText()">
  <button
    [matTooltip]="'CONVERSATION_NOTE_VIEW.ACTION_PANEL.BUTTON_AI_TOOLTIP' | translate"
    class="btn btn-sm btn-primary p-0 px-1"
    (mousedown)="searchAi($event)"
  >
    {{ 'CONVERSATION_NOTE_VIEW.ACTION_PANEL.BUTTON_AI_TEXT' | translate }}
  </button>
  <button
    [matTooltip]="'CONVERSATION_NOTE_VIEW.ACTION_PANEL.BUTTON_FIND_SIMILAR_CASES_TOOLTIP' | translate"
    class="btn btn-sm btn-danger p-0 px-1"
    (mousedown)="openBottomSheet()"
  >
    <i class="ion-search icon"></i>
    {{ 'CONVERSATION_NOTE_VIEW.ACTION_PANEL.BUTTON_FIND_SIMILAR_CASES_TEXT' | translate }}
  </button>
</div>
