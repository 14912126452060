<div style="position: relative">
  <div class="text-center">
    <h1 mat-dialog-title class="d-inline-block">
      {{ 'CONVERSATION_VIEW.CREATE_EDIT_NOTE_DIALOG.' + (data.noteId ? 'TITLE_UPDATE' : 'TITLE_CREATE') | translate }}
    </h1>
    <button mat-icon-button type="button" class="close" (click)="dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="progress-spinner" *ngIf="uploadPercent < 100">
    <mat-progress-spinner color="primary" mode="determinate" [value]="uploadPercent"></mat-progress-spinner>
    <span class="progress-spinner__value">{{ uploadPercent }}%</span>
  </div>

  <div mat-dialog-content class="pb-2">
    <ngx-ui-loader [fgsSize]="25" [loaderId]="loaderId"></ngx-ui-loader>
    <div>
      <quill-editor
        [required]="true"
        [styles]="editorStyles"
        [(ngModel)]="content"
        [modules]="quillModules"
        [placeholder]="'CONVERSATION_VIEW.CREATE_EDIT_NOTE_DIALOG.CONTENT_INPUT_PLACEHOLDER' | translate"
        (onEditorCreated)="editorCreated($event)"
        (paste)="onImagePaste($event)"
      >
      </quill-editor>
      <mat-icon id="note_attach_file_icon">attachment</mat-icon>
    </div>

    <button mat-button (click)="submitHandler()">
      {{ 'BUTTON.' + (data.noteId ? 'UPDATE' : 'CREATE') | translate }}
    </button>

    <div *ngIf="errorMessage" class="alert alert-danger mt-2">
      {{ errorMessage }}
    </div>
  </div>
</div>
