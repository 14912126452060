import { ApiService } from '@cation/core/services/api/api.service';
import { CMS_TYPE } from '@cation/core/enums/cms-type.enum';
import { EventEmitter } from '@angular/core';
import { IAnyObject, ICMS, IComment } from '../../interfaces/cms';

export default class Remedy implements ICMS {
  type = CMS_TYPE.REMEDY;

  id: string | number;
  name: string;
  title: string;
  description: string;
  comments: IComment[] = [];
  createdAt: number;

  isInitialized = false;
  isDataLoading = false;
  isCommentsLoading = false;
  onAddNewComment = new EventEmitter<IAnyObject>();

  constructor(data: IAnyObject, private api: ApiService) {
    this.id = data.Number;
    this.name = data.Number;
    this.title = ''; // TODO
    this.description = data.ShortDescription; // TODO
  }

  init() {

  }

  addComment() {

  }

  changeStatus() {

  }

  toJSON() {
    return '';
  }
}
