<div class="container-fluid">
  <div class="row">
    <div class="card col-lg-7 p-0">
      <div class="card-header p-2" *ngIf="conversation">
        <img
          *ngIf="conversation.author.userData?.profilePicUrl"
          [src]="conversation.author.userData?.profilePicUrl"
          class="avatar"
          alt="avatar"
        />
        {{ 'CONVERSATION_VIEW.TITLE' | translate: { name: conversation?.name } }}

        <span class="ps-2">
          <i class="material-icons ctn-icon-middle" [matTooltip]="'SENTIMENT.' + conversation.sentiment | translate">
            {{ mapSentimentToIcon[conversation.sentiment] }}
          </i>
        </span>

        <span class="ps-2">
          <span
            class="flag-icon flag-icon-{{ localeUtil.getRegion(conversation.locale).toLowerCase() }}"
            [matTooltip]="'LOCALE.' + localeUtil.getName(conversation.locale) | translate"
          ></span>

          <button
            *ngIf="!!conversation.transfers?.length"
            class="btn btn-outline-primary btn-action p-0 ms-1"
            (click)="openModal(listTransfersModal, 'lg'); (false)"
          >
            <mat-icon [matTooltip]="'CONVERSATION_VIEW.TRANSFERS_FILE_BUTTON_TOOLTIP' | translate" class="p-0">
              call_split
            </mat-icon>
          </button>
        </span>

        <div class="raw float-end">
          <button
            class="btn btn-outline-primary btn-action p-0 me-2"
            [matBadge]="notes.length ? '*' : ''"
            matBadgeColor="accent"
            matBadgeSize="small"
            matBadgePosition="above before"
            (click)="drawer.toggle()"
          >
            <mat-icon [matTooltip]="'CONVERSATION_VIEW.NOTES_BUTTON_TOOLTIP' | translate">speaker_notes</mat-icon>
          </button>

          <button type="button" class="close" (click)="onCloseConversation.emit()">
            <i class="ion-close" data-pack="default" data-tags="close"></i>
          </button>
        </div>
      </div>

      <div class="running-line-container d-block fw-bold bg-light text-end rounded p-0" *ngIf="runningLine.length > 75">
        <span class="running-line-block running-line-animate">{{ runningLine }}</span>
      </div>
      <div class="running-line-container d-block fw-bold bg-light rounded px-2 py-1" *ngIf="runningLine.length <= 75">
        {{ runningLine }}
      </div>

      <mat-drawer-container class="drawer-container" autosize>
        <ul ngbNav #nav="ngbNav" (navChange)="tabChange($event)">
          <li [ngbNavItem]="'chat'" id="chat">
            <a ngbNavLink class="nav-link" [innerText]="'CONVERSATION_VIEW.CHAT_TAB_TITLE' | translate"></a>
            <ng-template ngbNavContent>
              <div
                #scrollMe
                id="chatBox"
                class="card ctn-scroll chat p-2"
                [class.chat__closed]="isConversationClosed()"
                [appInfscroll]="loadMoreMessages"
                [completedFetching]="completedFetching"
              >
                <ng-container
                  *ngFor="
                    let message of messages;
                    let i = index;
                    trackBy: trackMessage;
                    last as isLast;
                    first as isFirst
                  "
                >
                  <app-chat-message
                    [id]="message.id"
                    [index]="messages.length - (i + 1)"
                    [senderId]="senderId"
                    [message]="message"
                    [fromMe]="fromMe(message)"
                    [isLast]="isLast"
                    [isFirst]="isFirst"
                    [conversation]="_conversation"
                    (added)="messageAdded(isFirst, $event)"
                  >
                  </app-chat-message>
                </ng-container>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'history'" id="history">
            <a ngbNavLink class="nav-link" [innerText]="'CONVERSATION_VIEW.HISTORY_TAB_TITLE' | translate"></a>
            <ng-template ngbNavContent>
              <app-chat-conversation-history
                [conversation]="_conversation"
                [senderId]="senderId"
                [isHistoryLoading]="isHistoryLoading"
                [conversationHistory]="conversationHistory"
                [isConversationClosed]="isConversationClosed()"
                [fullScreen]="true"
              >
              </app-chat-conversation-history>
            </ng-template>
          </li>
          <ng-container *ngIf="!!conversation?.cms">
            <li
              [ngbNavItem]="cms.type + ':' + cms.id"
              [id]="cms.type + ':' + cms.id"
              *ngFor="let cms of conversation.cms; let i = index"
            >
              <a ngbNavLink class="nav-link d-flex justify-content-center align-items-center">
                {{ getCmsInstance(cms)?.name }}
                <mat-icon [matTooltip]="'CMS_VIEW.CORE_TICKET_TOOLTIP' | translate" *ngIf="cms.isCore">
                  assignment_late
                </mat-icon>

                <button
                  *ngIf="!cms.isCore && currentCms?.type + ':' + currentCms?.id === cms.type + ':' + cms.id"
                  class="btn btn-outline-primary btn-action p-0 ms-1"
                  (click)="changeCoreCms(cms)"
                >
                  <mat-icon [matTooltip]="'CMS_VIEW.SET_AS_CORE_TOOLTIP' | translate" class="p-0">publish</mat-icon>
                </button>
              </a>
              <ng-template ngbTabContent>
                <app-chat-cms-view [isConversationClosed]="isConversationClosed()" [cms]="getCmsInstance(cms)">
                </app-chat-cms-view>
              </ng-template>
            </li>
          </ng-container>
        </ul>
        <div [ngbNavOutlet]="nav" class="h-100"></div>

        <mat-drawer #drawer class="drawer-sidenav" mode="side" position="end">
          <div class="notes-header py-2 m-0">
            <h6 class="d-inline">{{ 'CONVERSATION_NOTE_VIEW.TITLE' | translate }}</h6>
          </div>
          <div
            id="notesBox"
            class="card ctn-scroll chat p-2 border-0"
            [class.chat__closed]="isConversationClosed()"
            [appInfscroll]="loadMoreNotes"
            [completedFetching]="completedNotesFetching"
            appInfscrollDirection="down"
          >
            <ng-container *ngFor="let note of notes; trackBy: trackNote">
              <app-chat-note [note]="note" [conversation]="_conversation" [readOnly]="true"></app-chat-note>
            </ng-container>
          </div>
        </mat-drawer>
      </mat-drawer-container>

      <div
        *ngIf="isConversationClosed()"
        class="card-footer input-container w-100 p-1 bg-transparent"
        [class.input_chat__closed]="true"
      >
        <span class="d-flex h-100 justify-content-center align-items-center fw-bold bg-dark text-white rounded p-2">
          {{ 'CONVERSATION_NOTE_VIEW.CONVERSATION_CLOSED_MESSAGE' | translate }}
        </span>
      </div>
    </div>

    <div class="ctn-info-column col-lg-5 p-0 ps-2">
      <div class="ctn-customer-info-box">
        <ctn-customer-info [conversation]="conversation"></ctn-customer-info>
      </div>
    </div>
  </div>
</div>

<ng-template #listTransfersModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'CONVERSATION_NOTE_VIEW.TRANSFERS_MODAL.TITLE' | translate }}</h4>
    <button
      type="button"
      class="close"
      [attr.aria-label]="'BUTTON.CLOSE' | translate"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body p-0">
    <app-transfers-list-table *ngIf="conversation.transfers?.length > 0" [transfers]="conversation.transfers || []">
    </app-transfers-list-table>
  </div>
</ng-template>
