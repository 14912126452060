<div class="teams-statistics-page">
  <ngx-loading [show]="isLoading"></ngx-loading>

  <mat-card class="ctn-card">
    <mat-card-content>
      <h4 class="d-flex align-items-center ctn-h3 ctn-margin">
        <mat-icon class="me-2">people</mat-icon>
        {{ 'TEAMS_STATISTICS.TITLE' | translate }}
      </h4>

      <hr />

      <section class="d-flex align-items-center">
        <div class="ctn-team-icon me-2" *ngIf="team?.icon">
          <app-s3-image [image]="team?.icon"></app-s3-image>
        </div>

        <app-team-input-autocomplete
          [allOptionValue]="{ id: 'all-teams' }"
          [allOptionTitle]="'TEAMS_STATISTICS.ALL_TEAMS_TITLE'"
          (selectionChange)="onSelectTeam($event)"
          (teamsLoad)="isLoading = false"
        >
        </app-team-input-autocomplete>

        <div class="ms-2">
          <app-user-template [user]="team?.teamLead" tooltipField="userData.status"></app-user-template>
        </div>
      </section>

      <hr />

      <app-review-team-details-view [team]="team" *ngIf="team"></app-review-team-details-view>
    </mat-card-content>
  </mat-card>
</div>
