import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceUnderscores'
})
export class ReplaceUnderscoresPipe implements PipeTransform {
  transform(value: string, replaceValue: string = ''): any {
    if (!value) {
      return;
    }

    return value.replace(/_/g, replaceValue);
  }
}
