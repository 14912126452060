<button mat-icon-button class="me-2 material-button-with-icon" (click)="openModal(loadCmsModal, 'lg'); (false)">
  <mat-icon [matTooltip]="('CMS_ACTIONS.LOAD_TICKET_BUTTON_TITLE' | translate) + hotKeyToString(HotKey.LOAD_CMS)"
    >link</mat-icon
  >
</button>
<button mat-icon-button class="me-2 material-button-with-icon" (click)="openModal(sendCmsModal); (false)">
  <mat-icon [matTooltip]="('CMS_ACTIONS.SEND_TO_CMS_BUTTON_TITLE' | translate) + hotKeyToString(HotKey.SEND_TO_CMS)"
    >archive</mat-icon
  >
</button>
<button *ngIf="isMainAgent" mat-icon-button class="me-2 material-button-with-icon" (click)="onOpenEscalateModal()">
  <mat-icon [matTooltip]="('CMS_ACTIONS.ESCALATE_CASE_BUTTON_TITLE' | translate) + hotKeyToString(HotKey.ESCALATE_CMS)"
    >trending_up</mat-icon
  >
</button>

<ng-template #sendCmsModal let-modal>
  <ngx-ui-loader [fgsSize]="25" [loaderId]="loaderId"></ngx-ui-loader>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'CMS_ACTIONS.SEND_TO_CMS_MODAL.TITLE' | translate }}</h4>
    <button
      type="button"
      class="close"
      [attr.aria-label]="'BUTTON.CLOSE' | translate"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="cmsFormGroup">
      <!--      <div class="form-group d-flex">-->
      <!--        <label for="cmsType" class="col-sm-3 col-form-label">CMS Type</label>-->
      <!--        <div ngbDropdown class="d-inline-block w-100">-->
      <!--          <button class="btn btn-outline-primary w-100" id="cmsType" ngbDropdownToggle-->
      <!--                  [ngClass]="{ 'btn-outline-danger': submitted && !cmsType }">-->
      <!--            {{getCmsName(cmsType) || 'Choose CSM System'}}-->
      <!--          </button>-->
      <!--          <div ngbDropdownMenu aria-labelledby="cmsType" class="w-100">-->
      <!--            <button-->
      <!--                class="dropdown-item w-100"-->
      <!--                *ngFor="let cms of cmsSystems"-->
      <!--                (click)="onSelectCmsType(cms)"-->
      <!--            >{{getCmsName(cms)}}</button>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="form-group d-flex">
        <label for="title" class="col-sm-3 col-form-label">
          {{ 'CMS_ACTIONS.SEND_TO_CMS_MODAL.INPUT_TITLE_LABEL' | translate }}
        </label>
        <input
          id="title"
          type="text"
          formControlName="title"
          class="form-control"
          [placeholder]="'CMS_ACTIONS.SEND_TO_CMS_MODAL.INPUT_TITLE_PLACEHOLDER' | translate"
          [ngClass]="{ 'is-invalid': submitted && cmsForm.title.errors }"
        />
      </div>

      <div class="form-group d-flex">
        <label for="description" class="col-sm-3 col-form-label">
          {{ 'CMS_ACTIONS.SEND_TO_CMS_MODAL.INPUT_DESCRIPTION_LABEL' | translate }}
        </label>
        <input
          id="description"
          type="text"
          formControlName="description"
          class="form-control"
          [placeholder]="'CMS_ACTIONS.SEND_TO_CMS_MODAL.INPUT_DESCRIPTION_PLACEHOLDER' | translate"
          [ngClass]="{ 'is-invalid': submitted && cmsForm.description.errors }"
        />
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-warning" (click)="modal.dismiss('Cancel')">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
    <button type="button" class="btn btn-success" (click)="sendCms()">
      {{ 'BUTTON.SEND' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #loadCmsModal let-modal>
  <ngx-ui-loader [fgsSize]="25" [loaderId]="loadCmsLoaderId"></ngx-ui-loader>
  <div class="modal-header">
    <h4 class="modal-title">{{ 'CMS_ACTIONS.LOAD_CMS_MODAL.TITLE' | translate }}</h4>
    <button
      type="button"
      class="close"
      [attr.aria-label]="'BUTTON.CLOSE' | translate"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <ngb-accordion [closeOthers]="true" activeIds="load-by-id" (panelChange)="clearForms()">
    <ngb-panel id="load-by-id" [title]="'CMS_ACTIONS.LOAD_CMS_MODAL.PANEL_LOAD_BY_ID.TITLE' | translate">
      <ng-template ngbPanelContent>
        <div class="modal-body p-0">
          <form>
            <div class="form-group d-flex">
              <label for="id" class="col-sm-3 col-form-label">
                {{ 'CMS_ACTIONS.LOAD_CMS_MODAL.PANEL_LOAD_BY_ID.ID_INPUT_LABEL' | translate }}
              </label>
              <input
                id="id"
                type="text"
                [formControl]="loadCmsIdCtrl"
                class="form-control"
                [placeholder]="'CMS_ACTIONS.LOAD_CMS_MODAL.PANEL_LOAD_BY_ID.ID_INPUT_PLACEHOLDER' | translate"
                [ngClass]="{ 'is-invalid': submitted && loadCmsIdCtrl.errors }"
              />
            </div>
            <div *ngIf="loadCmsIdCtrl.errors" class="error d-flex justify-content-center">
              {{ loadCmsIdCtrl.errors.message }}
            </div>
          </form>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-warning" (click)="modal.dismiss('Cancel')">
            {{ 'BUTTON.CANCEL' | translate }}
          </button>
          <button type="button" class="btn btn-success" (click)="loadCms()">
            {{ 'BUTTON.LOAD' | translate }}
          </button>
        </div>
      </ng-template>
    </ngb-panel>

    <ngb-panel id="load-with-filter" [title]="'CMS_ACTIONS.LOAD_CMS_MODAL.PANEL_FIND_BY_FILTER.TITLE' | translate">
      <ng-template ngbPanelContent>
        <div class="modal-body p-0">
          <form>
            <div class="form-group d-flex">
              <label for="filter" class="col-sm-3 col-form-label">
                {{ 'CMS_ACTIONS.LOAD_CMS_MODAL.PANEL_FIND_BY_FILTER.FILTER_INPUT_LABEL' | translate }}
              </label>
              <input
                id="filter"
                type="text"
                [formControl]="loadCmsFilterCtrl"
                class="form-control"
                [placeholder]="'CMS_ACTIONS.LOAD_CMS_MODAL.PANEL_FIND_BY_FILTER.FILTER_INPUT_PLACEHOLDER' | translate"
                [ngClass]="{ 'is-invalid': submitted && loadCmsFilterCtrl.errors }"
              />
            </div>
            <div *ngIf="loadCmsFilterCtrl.errors" class="error d-flex justify-content-center">
              {{ loadCmsFilterCtrl.errors.message }}
            </div>
          </form>

          <app-cms-list-table
            *ngIf="loadCmsByFilterResult?.results.length > 0"
            [tickets]="loadCmsByFilterResult?.results"
            (onSelectTicket)="selectTicket($event)"
          >
          </app-cms-list-table>

          <div *ngIf="loadCmsByFilterResult?.results.length === 0" class="error d-flex justify-content-center">
            {{ 'CMS_ACTIONS.LOAD_CMS_MODAL.PANEL_FIND_BY_FILTER.EMPTY_MESSAGE' | translate }}
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-warning" (click)="modal.dismiss('Cancel')">
            {{ 'BUTTON.CANCEL' | translate }}
          </button>
          <button type="button" class="btn btn-success" (click)="searchTickets()">
            {{ 'BUTTON.SEARCH' | translate }}
          </button>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</ng-template>
