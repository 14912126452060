<ngx-loading [show]="isLoading"></ngx-loading>

<mat-card class="ctn-card">
  <mat-card-content>
    <div class="d-flex align-items-center">
      <h4 class="d-flex align-items-center ctn-h3 ctn-margin">
        <mat-icon class="me-2">people</mat-icon>
        {{ 'ADMIN_MANAGE_TEAM.TITLE' | translate }}
      </h4>
      <button
        mat-icon-button
        color="primary"
        (click)="onAddTeam()"
        [title]="'ADMIN_MANAGE_TEAM.ADD_BUTTON_TEXT' | translate"
      >
        <mat-icon>add_box</mat-icon>
      </button>
    </div>

    <hr />

    <section class="d-flex flex-wrap" [@listFadeInOut]="teams.length">
      <mat-card class="ctn-card team-card mt-2 me-2" *ngFor="let team of teams; let i = index; trackBy: trackTeam">
        <mat-card-content>
          <div class="team-item-container">
            <div class="team-item">
              <mat-card-header>
                <div mat-card-avatar class="d-flex justify-content-center align-items-center">
                  <app-s3-image [image]="team.icon" imageStyle="width: 100%; height: 100%"></app-s3-image>
                </div>
                <mat-card-title>{{ team.name }}</mat-card-title>
                <mat-card-subtitle *ngIf="team.members.length === 1"
                  >{{ team.members.length }}
                  {{ 'ADMIN_MANAGE_TEAM.MEMBERS.MEMBERS_SINGLE' | translate }}</mat-card-subtitle
                >
                <mat-card-subtitle *ngIf="team.members.length !== 1"
                  >{{ team.members.length }}
                  {{ 'ADMIN_MANAGE_TEAM.MEMBERS.MEMBERS_MULTI' | translate }}</mat-card-subtitle
                >
                <mat-card-subtitle>{{ team.description }}</mat-card-subtitle>
              </mat-card-header>
              <div class="d-flex row justify-content-center align-items-center">
                <div mat-card-avatar class="d-flex justify-content-center align-items-center">
                  <app-s3-image
                    [isRounded]="true"
                    [image]="team.teamLead.avatarUrl"
                    imageStyle="width: 40px; height: 40px; margin-right: 20px"
                  ></app-s3-image>
                </div>
                <h6 class="ctn-h3 ctn-margin team-item_team-lead_name">
                  {{ team.teamLead.username }}
                </h6>
              </div>
            </div>
            <div class="team-item-overlay">
              <button mat-mini-fab color="primary" (click)="onEditTeam(team)">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-mini-fab color="warn" (click)="onDeleteTeam(team)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </section>
  </mat-card-content>

  <div *ngIf="nextToken" class="d-flex flex-row justify-content-center align-items-center">
    <button mat-raised-button color="primary" (click)="loadMoreTeams()">
      {{ 'BUTTON.LOAD_MORE' | translate }}
    </button>
  </div>

  <div class="alert alert-danger pt-2" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
</mat-card>
