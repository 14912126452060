import gql from 'graphql-tag';

export default gql`
  subscription subscribeToNewUserChats($userId: ID!) {
    subscribeToNewUserChats(userId: $userId) {
      __typename
      userId
      chatId
      chat {
        __typename
        id
        name
        createdBy
        createdAt
        author {
          id
          name
          avatarUrl
        }
      }
      associated {
        __typename
        chatId
        userId
        user {
          __typename
          id
          name
          avatarUrl
        }
        typing
        connected
        lastReadMessageId
      }
    }
  }
`;
