<div class="position-relative">
  <div *ngIf="!events.length && isLoading" class="loader-start">
    <mat-spinner mode="indeterminate"></mat-spinner>
  </div>

  <div
    class="gamification-events-list"
    [class.pb-2]="lastKey"
    [@listFadeInOut]="events.length"
    infinite-scroll
    [scrollWindow]="false"
    [infiniteScrollDistance]="1"
    [infiniteScrollThrottle]="150"
    (scrolled)="onScrollDown()"
  >
    <div *ngFor="let event of events; last as isLast; first as isFirst" class="gamification-events-list__item py-1">
      <div [ngSwitch]="event.type" class="content-container">
        <div *ngSwitchCase="EVENT_TYPE.TEAM.MEMBER_ADD_ACHIEVEMENT">
          <ng-container *ngTemplateOutlet="teamMemberAchievementTemplate; context: event"></ng-container>
        </div>
        <div *ngSwitchCase="EVENT_TYPE.TEAM.MEMBER_LEVEL_UP">
          <ng-container *ngTemplateOutlet="teamMemberLevelUpTemplate; context: event"></ng-container>
        </div>
        <div *ngSwitchCase="EVENT_TYPE.TEAM.NEW_MEMBER">
          <ng-container *ngTemplateOutlet="newMemberTemplate; context: event"></ng-container>
        </div>
        <div *ngSwitchCase="EVENT_TYPE.TEAM.LEFT_MEMBER">
          <ng-container *ngTemplateOutlet="leftMemberTemplate; context: event"></ng-container>
        </div>
        <div *ngSwitchCase="EVENT_TYPE.TEAM.LEVEL_UP">
          <ng-container *ngTemplateOutlet="levelUpTemplate; context: event"></ng-container>
        </div>
        <div *ngSwitchCase="EVENT_TYPE.TEAM.ADD_ACHIEVEMENT">
          <ng-container *ngTemplateOutlet="addAchievementTemplate; context: event"></ng-container>
        </div>
        <div *ngSwitchCase="EVENT_TYPE.TEAM.NEW_CHALLENGE">
          <ng-container *ngTemplateOutlet="newChallengeTemplate; context: event"></ng-container>
        </div>
        <div *ngSwitchCase="EVENT_TYPE.TEAM.WON_CHALLENGE">
          <ng-container *ngTemplateOutlet="wonChallengeTemplate; context: event"></ng-container>
        </div>
        <div *ngSwitchCase="EVENT_TYPE.TEAM.DRAW_CHALLENGE">
          <ng-container *ngTemplateOutlet="drawChallengeTemplate; context: event"></ng-container>
        </div>
        <div *ngSwitchDefault>
          {{ 'GAMIFICATION_EVENTS_LIST.TYPE_IS_NOT_SUPPORTED' | translate: { type: event.type } }}
        </div>
      </div>

      <ng-container *ngTemplateOutlet="eventDateTemplate; context: event"></ng-container>
    </div>
  </div>

  <div *ngIf="events.length && isLoading" class="loader-more">
    <span>{{ 'GENERAL.LOADING' | translate }}...</span>
  </div>
</div>

<ng-template #teamMemberAchievementTemplate let-id="id" let-data="data">
  <ng-container *ngTemplateOutlet="userIconTemplate; context: data"></ng-container>
  <span
    class="gamification-events-list__item__message"
    [innerHTML]="
      'GAMIFICATION_EVENTS_LIST.TEAM_MEMBER_WAS_AWARDED'
        | translate: { username: data?.member?.userData?.nickname || data?.member?.username }
    "
  ></span>
  <div class="achievement-icon" *ngIf="data?.achievement?.icon" [matTooltip]="data.description">
    <app-s3-image [image]="data?.achievement?.icon"></app-s3-image>
  </div>
</ng-template>

<ng-template #teamMemberLevelUpTemplate let-id="id" let-data="data">
  <ng-container *ngTemplateOutlet="userIconTemplate; context: data"></ng-container>
  <span
    class="gamification-events-list__item__message"
    [innerHTML]="
      'GAMIFICATION_EVENTS_LIST.TEAM_MEMBER_HAS_REACHED_LEVEL'
        | translate: { username: data?.member?.userData?.nickname || data?.member?.username, name: data.level.name }
    "
  ></span>
  <div class="achievement-icon" *ngIf="data?.level?.icon" [matTooltip]="data.level.name">
    <app-s3-image [image]="data?.level?.icon"></app-s3-image>
  </div>
</ng-template>

<ng-template #newMemberTemplate let-id="id" let-data="data">
  <ng-container *ngTemplateOutlet="userIconTemplate; context: data"></ng-container>
  <span
    class="gamification-events-list__item__message"
    [innerHTML]="
      'GAMIFICATION_EVENTS_LIST.JOINED_TO_TEAM'
        | translate: { username: data?.member?.userData?.nickname || data?.member?.username }
    "
  ></span>
</ng-template>

<ng-template #leftMemberTemplate let-id="id" let-data="data">
  <ng-container *ngTemplateOutlet="userIconTemplate; context: data"></ng-container>
  <span
    class="gamification-events-list__item__message"
    [innerHTML]="
      'GAMIFICATION_EVENTS_LIST.HAS_LEFT_TEAM'
        | translate: { username: data?.member?.userData?.nickname || data?.member?.username }
    "
  ></span>
</ng-template>

<ng-template #levelUpTemplate let-id="id" let-data="data">
  <span
    class="gamification-events-list__item__message"
    [innerHTML]="'GAMIFICATION_EVENTS_LIST.TEAM_HAS_REACHED_LEVEL' | translate: { name: data.level.name }"
  ></span>
  <div class="achievement-icon" *ngIf="data?.level?.icon" [matTooltip]="data.level.name">
    <app-s3-image [image]="data?.level?.icon"></app-s3-image>
  </div>
</ng-template>

<ng-template #addAchievementTemplate let-id="id" let-data="data">
  <span class="gamification-events-list__item__message">
    {{ 'GAMIFICATION_EVENTS_LIST.TEAM_WAS_WARDED' | translate }}
  </span>
  <div class="achievement-icon" *ngIf="data?.achievement?.icon" [matTooltip]="data.description">
    <app-s3-image [image]="data?.achievement?.icon"></app-s3-image>
  </div>
</ng-template>

<ng-template #newChallengeTemplate let-id="id" let-data="data">
  <ng-container *ngTemplateOutlet="eventInitiatorOpponentIconTemplate; context: data"></ng-container>
  <span
    class="gamification-events-list__item__message"
    [innerHTML]="
      'GAMIFICATION_EVENTS_LIST.NEW_CHALLENGED'
        | translate
          : {
              initiator: data?.initiator?.userData?.nickname || data?.initiator?.username,
              opponent: data?.opponent?.userData?.nickname || data?.opponent?.username
            }
    "
  ></span>
</ng-template>

<ng-template #drawChallengeTemplate let-id="id" let-data="data">
  <ng-container *ngTemplateOutlet="eventInitiatorOpponentIconTemplate; context: data"></ng-container>
  <span
    class="gamification-events-list__item__message"
    [innerHTML]="
      'GAMIFICATION_EVENTS_LIST.DRAW_CHALLENGE'
        | translate
          : {
              initiator: data?.initiator?.userData?.nickname || data?.initiator?.username,
              opponent: data?.opponent?.userData?.nickname || data?.opponent?.username
            }
    "
  ></span>
</ng-template>

<ng-template #wonChallengeTemplate let-id="id" let-data="data">
  <ng-container
    *ngTemplateOutlet="
      eventInitiatorOpponentIconTemplate;
      context: { initiator: data?.winner, opponent: data?.opponent }
    "
  ></ng-container>
  <span
    class="gamification-events-list__item__message"
    [innerHTML]="
      'GAMIFICATION_EVENTS_LIST.WON_CHALLENGE'
        | translate
          : {
              winner: data?.winner?.userData?.nickname || data?.winner?.username,
              opponent: data?.opponent?.userData?.nickname || data?.opponent?.username
            }
    "
  ></span>
</ng-template>

<!-- Date Template -->
<ng-template #eventDateTemplate let-id="id">
  <span class="event-date">{{ id.split('_')[0] | date: 'd-MM-yyyy' }}</span>
</ng-template>

<!-- User Icons Template -->
<ng-template #userIconTemplate let-member="member">
  <div class="ctn-icons ctn-1-icon">
    <div class="gamification-events-list__item__icon">
      <app-s3-image [isRounded]="true" [image]="member?.avatarUrl" [avatarName]="member?.username"></app-s3-image>
    </div>
  </div>
</ng-template>

<!-- Initiator Opponent Icons Template -->
<ng-template #eventInitiatorOpponentIconTemplate let-initiator="initiator" let-opponent="opponent">
  <div class="ctn-icons ctn-2-icons">
    <div class="gamification-events-list__item__icon">
      <app-s3-image [isRounded]="true" [image]="initiator?.avatarUrl" [avatarName]="initiator?.username"></app-s3-image>
    </div>
    <div class="gamification-events-list__item__icon">
      <app-s3-image [isRounded]="true" [image]="opponent?.avatarUrl" [avatarName]="opponent?.username"></app-s3-image>
    </div>
  </div>
</ng-template>
