import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { HotKey } from '@cation/core/enums/hotkey.enum';
import { HotKeys } from '@cation/core/util/hot-keys';
import { SharedService } from '@cation/core/services/shared/shared.service';
import Conversation from '@cation/core/types/conversation';
import { handleErrors } from '@cation/core/util/chat-helper';
import { listFadeInOut, openClose } from '@cation/core/animations';
import { environment } from '../../../environments/environment';
import { ILexResponse } from './types';
import { BottomSheetFindSimilarCaseFilterComponent } from './bottom-sheet-find-similar-case-filter.component';
import { DialogService } from '@cation/core/services/dialog/dialog.service';

const defaultLexAnswer = { message: '' };

@Component({
  selector: 'app-chat-ai-view',
  templateUrl: './chat-ai-view.component.html',
  styleUrls: ['./chat-ai-view.component.scss'],
  animations: [listFadeInOut, openClose],
})
export class ChatAiViewComponent implements OnInit, OnDestroy {
  loaderId = 'ai_advice';

  _conversation;
  _searchCondition = '';
  utterances = [];
  isInputOnFocus = false;
  isShowAutocomplete = true;
  aiSearchConditionCtrl: UntypedFormControl = new UntypedFormControl();
  searchResult: ILexResponse = defaultLexAnswer;

  similarCases = {};

  selectedTab = new UntypedFormControl(0);
  mapSelectedTabToInputPlaceholder = {
    0: 'AI Advice Search',
    1: 'Similar Cases Search',
  };

  showNotification;
  hotkeyAiAdvice: Subscription;
  aiSearchSubscription: Subscription;
  similarCasesSearchSubscription: Subscription;

  filters = { keywords: [], createdAt: null };
  _onDestroy = new Subject<void>();

  @ViewChild('inputElement', { static: true }) private inputElement: ElementRef;

  @Input() senderId: string;

  @Input()
  set conversation(convo: Conversation) {
    this._conversation = convo;
    this.searchResult = defaultLexAnswer;
  }

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private logService: LogService,
    private sharedService: SharedService,
    private apiService: ApiService,
    private hotkeysService: HotKeys,
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private translate: TranslateService,
    private s3Service: S3Service,
    private dialogService: DialogService
  ) {
    this.copyToClipboardHandler = this.copyToClipboardHandler.bind(this);
    this.hotkeyAiAdvice = this.hotkeysService
      .on(HotKey.GO_TO_AI_ADVICE)
      .subscribe(() => this.inputElement.nativeElement.focus());
  }

  ngOnInit(): void {
    this.translate
      .stream(['AI_BLOCK.AI_ADVICE_INPUT_PLACEHOLDER', 'AI_BLOCK.SIMILAR_CASES_INPUT_PLACEHOLDER'])
      .subscribe((values) => {
        const [aiAdvicePlaceholder, similarCasePlaceholder] = Object.values(values);
        this.mapSelectedTabToInputPlaceholder = { 0: aiAdvicePlaceholder, 1: similarCasePlaceholder };
      });
    this.aiSearchSubscription = this.sharedService.getAiSearchEmitter().subscribe((text) => {
      this.selectedTab.setValue(0);
      this.getLexAnswer(text);
    });
    this.similarCasesSearchSubscription = this.sharedService
      .getSimilarCasesSearchEmitter()
      .subscribe(({ message, createdAt }) => {
        this.selectedTab.setValue(1);
        this.getSimilarCases(message, createdAt);
      });
    this.filters.keywords = this._conversation.keywords || [];

    this.aiSearchConditionCtrl.valueChanges
      .pipe(debounceTime(300), takeUntil(this._onDestroy))
      .subscribe(async (v) => this.searchUtterances(v));
  }

  ngOnDestroy(): void {
    this.hotkeyAiAdvice.unsubscribe();
    this.aiSearchSubscription.unsubscribe();
    this.similarCasesSearchSubscription.unsubscribe();
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  set searchCondition(searchCondition: string) {
    this._searchCondition = searchCondition;
  }

  get searchCondition() {
    return this._searchCondition;
  }

  openBottomSheetFilter(): void {
    const bottomSheet = this.bottomSheet.open(BottomSheetFindSimilarCaseFilterComponent, {
      data: { filters: this.filters },
    });

    bottomSheet.afterDismissed().subscribe((filters) => {
      this.logService.log('[openBottomSheetFilter afterDismissed]', filters);
      if (filters) {
        this.filters = filters;
      }
    });
  }

  get filtersTooltip(): string {
    if (!this.isFilterExist()) {
      return 'Filters';
    }

    const { keywords, createdAt } = this.filters;

    const keywordsFilter = keywords.join(', ');
    const createdAtFilter =
      createdAt && createdAt.from && createdAt.to
        ? `(${moment(createdAt.from).format('LLL')} - ${moment(createdAt.to).format('LLL')})`
        : '';

    return (keywordsFilter ? `${keywordsFilter}\n` : '') + createdAtFilter;
  }

  clearFilter(): void {
    this.filters = { keywords: [], createdAt: null };
  }

  isFilterExist(): boolean {
    return !!(this.filters.keywords.length || this.filters.createdAt);
  }

  openCustomerHistory(conversationId, event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.dialogService.openCustomerHistory(conversationId);
  }

  get inputPlaceholder() {
    return this.mapSelectedTabToInputPlaceholder[this.selectedTab.value];
  }

  getLexAnswer(text: string = this.aiSearchConditionCtrl.value) {
    const inputText = text;

    if (!inputText) {
      return undefined;
    }
    this.ngxLoader.startLoader(this.loaderId);
    this.sharedService.goToChatInput();
    const options = {
      method: 'POST',
      body: JSON.stringify({
        userId: this.senderId,
        requestAttributes: { locale: this._conversation.locale || 'en_IE' },
        inputText,
      }),
    };
    return fetch(environment.aiAdviceUrl, options)
      .then(handleErrors)
      .then((data) => {
        this.logService.info('[getLexAnswer] Response:', `*${inputText}*`, data);

        // Showing response on the following conditions
        // Agent Search:
        // - if the agent search something then no check is made and the AI response is shown as it is
        // Based on user input:
        // - If response dialogue type is OPEN DIALOGUE then the response is shown
        // - if the response is session filter then the response is shown
        if (data.intentName === 'getInput' || data.sessionAttributes.dialogueType === 'OPEN_DIALOGUE') {
          this.searchResult = data;
        }
        this.isShowAutocomplete = true;
        this.utterances = [];
        this.aiSearchConditionCtrl.setValue('');
      })
      .catch((error) => {
        this.logService.info('[getLexAnswer] Error:', `*${inputText}*`, error);
        // this.searchResult = error.message;
      })
      .then(() => this.ngxLoader.stopLoader(this.loaderId));
  }

  copyToClipboardHandler(e: ClipboardEvent) {
    e.clipboardData.setData('text/plain', this.searchResult.message);
    e.preventDefault();
    if (this.showNotification) {
      clearTimeout(this.showNotification);
    }
    this.showNotification = setTimeout(() => (this.showNotification = null), 500);
    document.removeEventListener('copy', this.copyToClipboardHandler);
  }

  copyToClipboard() {
    if (!this.searchResult.message) {
      return;
    }
    document.addEventListener('copy', this.copyToClipboardHandler, { once: true });
    document.execCommand('copy');
  }

  async getSimilarCases(
    text: string = this.searchCondition,
    createdAt: object = this.filters.createdAt,
    keywords: string[] = this.filters.keywords
  ) {
    this.logService.info('getSimilarCases', { text, createdAt, keywords });

    const inputText = text;

    if (!inputText) {
      return;
    }
    this.ngxLoader.startLoader(this.loaderId);

    const body = Object.assign(
      { maxResult: 50, message: inputText, createdAt }
      // keywords ? { keywords } : {} // TODO: Enable after updating on the backend side
    );

    const { items: cases } = await this.apiService.findSimilarCases(body).toPromise();

    this.logService.info('[getSimilarCases] Response:', `*${inputText}*`, cases);

    const promises = _.map(cases, async (item) => ({
      ...item,
      message: await this.s3Service.updateAccessToImageUrls(item.message),
    }));
    const sources = await Promise.all(promises);

    this.similarCases = _.groupBy(sources, 'conversationId');

    this.searchCondition = '';

    this.ngxLoader.stopLoader(this.loaderId);
  }

  getKeywords(item) {
    return _.get(item, '[0].conversation.keywords', ['Unknown']).join(', ');
  }

  searchUtterances(text: string) {
    const inputText = text;

    if (!inputText) {
      return undefined;
    }

    const options = {
      method: 'POST',
      body: JSON.stringify({
        inputText,
        // primaryTopic: '',
        // secondaryTopic: '',
        locale: 'en_IE',
      }),
    };
    return fetch(environment.aiAdviceAutocompleteUrl, options)
      .then(handleErrors)
      .then((data) => {
        this.logService.info('[searchUtterances] Response:', `*${inputText}*`, data);

        this.utterances = data;
      })
      .catch((error) => {
        this.logService.info('[searchUtterances] Error:', `*${inputText}*`, error);
      });
  }

  onBlur() {
    setTimeout(() => (this.isInputOnFocus = false), 200);
  }
}
