<nav mat-tab-nav-bar class="subnav">
  <a
    mat-tab-link
    *ngFor="let link of linksConfig"
    [routerLink]="link.path"
    routerLinkActive
    #rla="routerLinkActive"
    [active]="rla.isActive"
  >
    {{ link.data.label | translate }}
  </a>
</nav>

<router-outlet></router-outlet>
