import gql from 'graphql-tag';

export default gql`
  subscription subscribeToReadMessage($conversationId: ID!) {
    subscribeToReadMessage(conversationId: $conversationId) {
      __typename
      conversationId
      attachment {
        key
        type
        size
        name
      }
      createdAt
      id
      sender
      content
      isSent
      isRead
      isQuestion
    }
  }
`;
