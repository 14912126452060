import { Injectable } from '@angular/core';
import { ApiService } from '@cation/core/services/api/api.service';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { ICMSConstructable, ICMS, IAnyObject } from '../../interfaces/cms';
import { CMS_TYPE } from '../../enums/cms-type.enum';
import ServiceNow from './ServiceNow';
import SalesForce from './SalesForce';
import Zendesk from './Zendesk';
import Remedy from './Remedy';
import Jira from './Jira';

const mapTypeToCms: { [prop in CMS_TYPE]: ICMSConstructable } = {
  [CMS_TYPE.SERVICE_NOW]: ServiceNow,
  [CMS_TYPE.SALES_FORCE]: SalesForce,
  [CMS_TYPE.ZENDESK]: Zendesk,
  [CMS_TYPE.REMEDY]: Remedy,
  [CMS_TYPE.JIRA]: Jira
};

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  constructor(
    private api: ApiService,
    private auth: AuthHelper,
    private logService: LogService,
    private s3: S3Service
  ) {
    this.logService.info('-------CmsService--------');
  }

  createCMS(type: CMS_TYPE, data: IAnyObject): ICMS {
    const CMS = mapTypeToCms[type];

    if (!CMS) {
      throw new Error(`Invalid CMS type: ${type}`);
    }

    return new CMS(data, this.api, this.auth, this.s3);
  }

  sendToCms(data: IAnyObject): Promise<IAnyObject> {
    return this.api.sendToCms(data).toPromise();
  }

  findTicketById(id, type: CMS_TYPE): Promise<IAnyObject> {
    return this.api.getCmsById(id, type).toPromise();
  }

  searchTickets(filter: string, type: CMS_TYPE): Promise<IAnyObject> {
    return this.api.searchCms(filter, type).toPromise();
  }

  attachTicketToConversation(conversationId, ticketId, type: CMS_TYPE): Promise<IAnyObject> {
    return this.api.attachCmsToConversation(conversationId, ticketId, type).toPromise();
  }
}
