import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { isNullOrUndefined } from 'util';
import { S3Service } from '../services/s3.service';
import { LoggerService } from '../services/logger.service';

@Component({
  selector: 'internal-chat-s3-image',
  templateUrl: './s3-image.component.html',
  styleUrls: ['./s3-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class S3ImageComponent {
  avatarColors = ['#00AA55', '#009FD4', '#B381B3', '#939393', '#E3BC00', '#D47500', '#DC2A2A'];
  _image: string;

  @Input() imageStyle: string;
  @Input() avatarName: string;
  @Input() fitContain: boolean = false;
  @Input() isRounded: boolean = false;

  @Input() set image(value) {
    if (!value) {
      return;
    }
    if (value.startsWith('http') && !this.s3Service.isBucketLink(value)) {
      this._image = value;
    } else {
      this.getImage(value);
    }
  }

  async getImage(image) {
    try {
      if (this.s3Service.isBucketLink(image)) {
        this._image = (await this.s3Service.getFileByUrl(image)) as string;
      } else {
        this._image = (await this.s3Service.getFileByKey(image)) as string;
      }
      this.cdr.detectChanges();
    } catch (e) {
      this.loggerService.error('[getImage Error]', e);
    }
  }

  constructor(
    private s3Service: S3Service,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private loggerService: LoggerService
  ) {}

  GetStyle(c) {
    if (isNullOrUndefined(c)) {
      return null;
    }
    return this.sanitizer.bypassSecurityTrustStyle(c);
  }

  get image() {
    return this._image;
  }

  avatarTextFromName(name) {
    return name
      .split(' ')
      .slice(0, 2)
      .map(word => word[0])
      .join('');
  }

  textToColor(text) {
    const charCodes = text
      .split('')
      .map(char => char.charCodeAt(0))
      .join('');
    const number = parseInt(charCodes, 10);

    return this.avatarColors[number % this.avatarColors.length];
  }
}
