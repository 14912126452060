import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { fadeInOut, listFadeInOut } from '@cation/core/animations';
import { EVENT_TYPE, IChallengeItem, IEventsItem } from '@cation/core/types/api';
import * as _ from 'lodash';

@Component({
  selector: 'app-score-history-list',
  templateUrl: './score-history-list.component.html',
  styleUrls: ['./score-history-list.component.scss'],
  animations: [fadeInOut, listFadeInOut]
})
export class ScoreHistoryListComponent implements OnInit, OnDestroy {
  Object = Object;
  EVENT_TYPE = EVENT_TYPE;
  isLoading = false;
  errorMessage;
  events: IEventsItem[] = [];
  lastKey: object = null;
  initLoading = true;

  @Input() autoRefresh: boolean = false;

  private interval: NodeJS.Timer;
  private loadIntersectionObserver: IntersectionObserver;

  @ViewChild('ctnContainer', { static: true }) ctnContainer: ElementRef;
  @ViewChild('loading', { static: true }) loading: ElementRef;

  constructor(public authHelper: AuthHelper, private apiService: ApiService, private logService: LogService) {}

  ngOnInit(): void {
    this.getScoreEvents();
    this.createLoadIntersection();
    this.initLoadIntersection();
  }

  ngOnDestroy() {
    this.disconnectLoadIntersection();
  }

  trackItem(index, item) {
    return item ? item.id : undefined;
  }

  getDateFromId(id) {
    return id.split('_')[0];
  }

  startInterval() {
    if (!this.interval) {
      this.interval = setInterval(() => this.autoRefresh && this.getScoreEvents(false, true), 10000);
    }
  }

  destroyInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  async getScoreEvents(isLoadMore = false, isSilent = false) {
    if (!isSilent) {
      this.isLoading = true;
    }

    this.destroyInterval();

    try {
      const startKey = isLoadMore ? this.lastKey : null;

      const options = { limit: 10, startKey: JSON.stringify(startKey), typePrefix: 'USER_EVENT_SCORE_' };
      const memberId = `${this.authHelper.userProfile.cognitoId}_SCORE`;
      const { items, lastKey } = await this.apiService.getEventsByMember(memberId, options);

      if (isLoadMore && startKey) {
        this.events = this.events.concat(items);
      } else {
        this.events = isSilent ? _.merge(this.events, items) : items;
      }
      this.lastKey = isSilent ? this.lastKey : lastKey;

      this.logService.log('[getScoreEvents]', { items, lastKey });
      this.initLoading = false;
    } catch (e) {
      this.errorMessage = e.message;
      this.logService.error('[getScoreEvents Error]', e);
    }

    this.startInterval();

    if (!isSilent) {
      this.isLoading = false;
    }
  }

  isChallenge(eventType: string) {
    const name = 'CHALLENGE';
    return [`NEW_${name}`, `${name}_DRAW`, `${name}_WON`, `${name}_LOST`, `${name}_EXPIRED`].includes(eventType);
  }

  isWager(eventType: string) {
    const name = 'CHALLENGE_WAGER';
    return [`NEW_${name}`, `${name}_DRAW`, `${name}_WON`, `${name}_LOST`, `${name}_EXPIRED`].includes(eventType);
  }

  getChallengeOpponent(challenge: IChallengeItem) {
    return challenge.initiatorId === this.authHelper.userProfile.cognitoId ? challenge.opponent : challenge.initiator;
  }

  private createLoadIntersection() {
    const options = {
      root: this.ctnContainer.nativeElement,
      rootMargin: '0px',
      threshold: 1.0
    };

    const callback = entries => {
      entries.forEach(entry => {
        if (this.isIntersecting(entry) && this.lastKey) {
          this.getScoreEvents(true);
        }
      });
    };

    this.loadIntersectionObserver = new IntersectionObserver(callback, options);
  }

  private initLoadIntersection() {
    this.loadIntersectionObserver.observe(this.loading.nativeElement);
  }

  private disconnectLoadIntersection() {
    if (this.loadIntersectionObserver) {
      this.loadIntersectionObserver.disconnect();
    }
  }

  private isIntersecting(entry: IntersectionObserverEntry): boolean {
    return entry.isIntersecting || entry.intersectionRatio > 0;
  }
}
