<h6 class="text-center">
  {{ data.title | translate: data.titleTranslateData }}
</h6>

<div mat-dialog-content class="d-flex justify-content-center">
  <p>{{ data.message | translate: data.messageTranslateData }}</p>
</div>

<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-button [mat-dialog-close]="dialogValuesEnum.ok" class="btn-save">
    {{ 'BUTTON.YES' | translate }}
  </button>
  <button mat-button mat-dialog-close class="btn-cancel">
    {{ 'BUTTON.NO' | translate }}
  </button>
</div>
