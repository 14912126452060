import { DateRangeModel } from './models/date-range.model';
import * as moment from 'moment';
import { StatsFilterOptionEnum } from '../widget-header/enums/stats-filter-option.enum';

export const statsFilterOptionsMap = new Map<StatsFilterOptionEnum, DateRangeModel>([
  [
    StatsFilterOptionEnum.today,
    {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: ''
    }
  ],
  [
    StatsFilterOptionEnum.sevenDays,
    {
      startDate: moment()
        .add(-7, 'days')
        .format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    }
  ],
  [
    StatsFilterOptionEnum.thisMonth,
    {
      startDate: moment()
        .startOf('month')
        .format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    }
  ]
]);
