import gql from 'graphql-tag';

export default gql`
  mutation updateAgent($input: UpdateAgentInput!) {
    updateAgent(input: $input) {
      __typename
      username
      cognitoId
      avatarUrl
      cmsUserData
      keywords
      locales
      roles
      registered
      userData
    }
  }
`;
