<div class="cms-list-table">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>#ID</th>
      <td mat-cell *matCellDef="let element">
        <button type="button" class="btn btn-outline-primary btn-sm" (click)="onSelectTicket.emit(element)">
          {{ 'CMS_ACTIONS.LOAD_CMS_MODAL.CMS_LIST_TABLE.LOAD_BUTTON_TITLE' | translate: { id: element.id } }}
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'CMS_ACTIONS.LOAD_CMS_MODAL.CMS_LIST_TABLE.TITLE_COLUMN_TITLE' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.subject }}</td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'CMS_ACTIONS.LOAD_CMS_MODAL.CMS_LIST_TABLE.STATUS_COLUMN_TITLE' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>
    <ng-container matColumnDef="created at">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'CMS_ACTIONS.LOAD_CMS_MODAL.CMS_LIST_TABLE.CREATED_AT_COLUMN_TITLE' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.created_at }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSize]="5" showFirstLastButtons></mat-paginator>
</div>
