import { Route } from '@angular/router';
import { Role } from '@cation/core/auth/role.enum';
import { RouteDataModel } from '@cation/core/models/route-data.model';
import { ReportsComponent } from '../../components/reports/reports.component';

export const reportsRoutesConfig: Route[] = [
  {
    path: 'conversation',
    component: ReportsComponent,
    data: {
      roles: [Role.ADMIN, Role.REPORTS_ADMIN],
      label: 'NAVBAR.TIER3_CONVERSATION_REPORT_LINK',
    } as RouteDataModel,
  },
];
