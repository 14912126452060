<div class="text-center">
  <h1 mat-dialog-title class="title">{{ 'CHALLENGES.NEW_CHALLENGE' | translate }}</h1>
  <button mat-icon-button [tabIndex]="-1" type="button" class="close" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div *ngIf="isCostLoading" class="loader" [@fadeInOut]>
  <mat-spinner mode="indeterminate"></mat-spinner>
</div>

<div>
  <span *ngIf="!maxPoints; else form">{{ 'CHALLENGE_DIALOG.NO_POINTS' | translate }}</span>
</div>

<ng-template #form>
  <form [formGroup]="opponentFormGroup" class="ctn-choose-opponent">
    <app-agent-input-autocomplete
      (selectionChange)="opponentForm.opponent.setValue($event)"
      [required]="true"
      [placeholder]="'CHALLENGE_DIALOG.OPPONENT_STEP.PLACEHOLDER' | translate"
      [placeholderLabel]="'CHALLENGE_DIALOG.OPPONENT_STEP.INPUT_PLACEHOLDER' | translate"
      [errorLabel]="'CHALLENGE_DIALOG.OPPONENT_STEP.ERROR' | translate"
    >
    </app-agent-input-autocomplete>
  </form>

  <div class="ctn-contestants">
    <div class="ctn-contestant ctn-initiator">
      <div class="block-linear initiator-block-linear"></div>
      <div class="block-solid initiator-block-solid"></div>
      <span class="vs-letter">V</span>
      <div class="contestant-avatar">
        <app-s3-image
          [image]="authHelper.userProfile?.userData?.avatarUrl || authHelper.userProfile?.avatarUrl"
          [avatarName]="authHelper.userProfile?.userData?.username || authHelper.userProfile?.username"
        >
        </app-s3-image>
      </div>
    </div>

    <div class="ctn-contestant ctn-opponent">
      <div class="block-solid opponent-block-solid"></div>
      <div class="block-linear opponent-block-linear"></div>
      <span class="vs-letter">S</span>
      <div class="contestant-avatar">
        <app-s3-image
          [image]="opponentForm.opponent.value.userData?.avatarUrl || opponentForm.opponent.value.avatarUrl"
          [avatarName]="opponentForm.opponent.value.userData?.username || opponentForm.opponent.value.username"
        ></app-s3-image>
      </div>
    </div>
  </div>

  <div *ngIf="opponentForm.opponent.value">
    <div mat-dialog-content>
      <form [formGroup]="challengeTypeFormGroup">
        <mat-radio-group class="type-radio-group" formControlName="challengeType">
          <mat-radio-button class="type-radio-button" *ngFor="let type of challengeTypes" [value]="type">
            {{ 'CHALLENGE_DIALOG.SELECT_TYPE_STEP.' + type + '_TYPE' | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </form>
      <div *ngIf="challengeTypeForm.challengeType.value === 'FIVE_STARS_TO_DATE'">
        <form [formGroup]="amountFormGroup" class="w-50">
          <mat-form-field class="w-100 pt-2">
            <input
              type="number"
              min="1"
              matInput
              [placeholder]="'CHALLENGE_DIALOG.AMOUNT_STEP.TEXT_PLACEHOLDER' | translate"
              formControlName="amount"
              required
            />
          </mat-form-field>
        </form>
      </div>

      <div class="d-flex align-items-center">
        <form [formGroup]="costFormGroup" class="w-50">
          <mat-form-field class="w-100 pt-2">
            <input
              type="number"
              min="1"
              matInput
              [max]="maxPoints"
              [placeholder]="'CHALLENGE_DIALOG.COST_STEP.TEXT_PLACEHOLDER' | translate"
              formControlName="points"
              required
            />
          </mat-form-field>
        </form>

        <span class="balance">{{ 'CHALLENGE_DIALOG.BALANCE' | translate }}: {{ maxPoints }}</span>
      </div>

      <form [formGroup]="datesFormGroup" class="w-50">
        <mat-form-field class="w-100" (click)="picker.open()">
          <input
            matInput
            [placeholder]="'CHALLENGE_DIALOG.DATES_STEP.LABEL' | translate"
            [min]="getMinDate(1) | date: 'yyyy-MM-dd'"
            [satDatepicker]="picker"
            [required]="true"
            formControlName="date"
          />
          <sat-datepicker #picker [rangeMode]="true" [selectFirstDateOnClose]="true"></sat-datepicker>
          <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
        </mat-form-field>
      </form>
    </div>

    <div class="ctn-actions">
      <button mat-button class="btn-save" (click)="onAddChallenge()">{{ 'BUTTON.CHALLENGE' | translate }}</button>
    </div>
  </div>
</ng-template>
