import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from '@aws-amplify/auth';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { LogService } from '@cation/core/services/log/log.service';
import { environment } from '@cation/env/environment';
import { from } from 'rxjs';
// import * as AWS from "aws-sdk/global";
const AWS = require('aws-sdk');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authHelper: AuthHelper, private logService: LogService) {
    this.logService.info('-------AuthInterceptor-------');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.logService.info('[AuthInterceptor intercept]', req);

    /** Ignore assets */
    if (req.url.startsWith('/assets/')) {
      return next.handle(req);
    }
    // console.log('AuthInterceptor:intercept');

    // Auth.currentCredential call returns promise
    // so invoking it inside async private method below and wrapping with 'from' operator
    // converting promise(i.e, async) to Observable using 'from' operator
    return from(this.handle(req, next));
  }

  private async handle(req: HttpRequest<any>, next: HttpHandler) {
    const headers = await this.sign(req);

    headers['X-Api-Key'] = environment.apiKey;
    // headers.Authorization = this.authHelper.getIdToken().getJwtToken()

    // Clone the request and set the new header in one step.
    const authReq = req.clone({
      setHeaders: headers,
    });

    this.logService.debug('[AuthInterceptor handle:Request]', authReq);

    // send cloned request with header to the next handler.
    return next.handle(authReq).toPromise();
  }

  private async sign(req) {
    const start = new Date().getTime();
    const cred = await Auth.currentCredentials();
    const authToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    this.logService.debug('[AuthInterceptor sign]', {
      timeTaken: new Date().getTime() - start,
      currentCredentials: cred,
      authToken,
    });

    const httpRequest = new AWS.HttpRequest(req.url, environment.amplifyConfig.aws_project_region);
    httpRequest.method = req.method;
    httpRequest.headers.host = new URL(req.url).host;
    httpRequest.headers['X-Authorization'] = authToken;

    if (req.body && typeof req.body === 'object') {
      httpRequest.headers['Content-Type'] = 'application/json';
      httpRequest.body = JSON.stringify(req.body);
    }

    const v4signer = new AWS.Signers.V4(httpRequest, 'execute-api', true);
    v4signer.addAuthorization(cred, AWS.util.date.getDate());

    // Create a headers object from the AWS signed request
    const headers: any = {};
    for (const key of Object.keys(httpRequest.headers)) {
      headers[key] = httpRequest.headers[key];
    }

    // Browser throws a warning if attempting to set host header
    delete headers.host;

    this.logService.debug('[AuthInterceptor sign:httpRequest]', httpRequest);
    this.logService.debug('[AuthInterceptor sign:headers]', headers);

    return headers;
  }
}
