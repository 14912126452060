import { RouteDataModel } from 'src/app/core/models';
import { Role } from '@cation/core/auth/role.enum';
import { GamificationSettingsComponent } from '../../components/gamification-settings/gamification-settings.component';
import { GamificationAchievementsComponent } from '../../components/gamification-achievements/gamification-achievements.component';
import { Route } from '@angular/router';
import { MasterLeagueComponent } from 'src/app/chat-app/master-league/master-league.component';

export const gamificationRoutesConfig: Route[] = [
  {
    path: 'settings',
    component: GamificationSettingsComponent,
    data: {
      roles: [Role.ADMIN],
      label: 'NAVBAR.GAMIFICATION_SETTINGS_LINK',
    },
  },
  {
    path: 'achievements',
    component: GamificationAchievementsComponent,
    data: {
      roles: [Role.ADMIN],
      label: 'NAVBAR.GAMIFICATION_ACHIEVEMENTS_LINK',
    } as RouteDataModel,
  },
  {
    path: 'master-league',
    component: MasterLeagueComponent,
    data: {
      label: 'NAVBAR.GAMIFICATION_MASTER_LEAGUE_LINK',
    } as RouteDataModel,
  },
];
