import { Component, Input, EventEmitter, Output } from '@angular/core';
import User from '@cation/core/types/user';

@Component({
  selector: 'team-members-list',
  templateUrl: './team-members-list.component.html',
  styleUrls: ['./team-members-list.component.scss']
})
export class TeamMembersListComponent {
  @Input() set members(members: User[]) {
    this._members = members;
    this.membersIds = members.map(({ cognitoId }) => cognitoId);
  }
  @Output() onAddMember = new EventEmitter<void>();
  @Output() onRemoveMember = new EventEmitter<void>();

  _members;
  membersIds;

  addMember(member) {
    if (member) {
      this.onAddMember.emit(member);
    }
  }

  removeMember(member) {
    this.onRemoveMember.emit(member);
  }
}
