import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import Conversation from '@cation/core/types/conversation';

interface IInviteAgentInputData {
  conversation: Conversation;
}

@Component({
  selector: 'app-invite-agent-dialog',
  templateUrl: './invite-agent-dialog.component.html',
  styleUrls: ['./invite-agent-dialog.component.scss']
})
export class InviteAgentDialogComponent implements OnInit {
  loaderId = 'invite-agent-dialog-loader';
  isAgentsLoading = true;
  errorMessage;
  agentId;
  memberIds = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IInviteAgentInputData,
    public dialogRef: MatDialogRef<InviteAgentDialogComponent>,
    private ngxLoader: NgxUiLoaderService,
    private api: ApiService,
    private logService: LogService
  ) {}

  onSelectionChange(agent) {
    this.agentId = agent.cognitoId;
  }

  ngOnInit() {
    this.memberIds = _.map(_.filter(this.data.conversation.associated, u => u.connected === 'true'), 'userId');
    this.logService.log('[Conversation Member Ids]', this.memberIds);
  }

  async onAgentInvite() {
    this.ngxLoader.startLoader(this.loaderId);
    try {
      if (this.agentId) {
        const conversationId = this.data.conversation.id;
        await this.api.inviteAgentInConversation(conversationId, this.agentId).toPromise();

        setTimeout(() => this.dialogRef.close(), 500);
      }
    } catch (error) {
      this.errorMessage = error.message;
    }
    this.ngxLoader.stopLoader(this.loaderId);
  }
}
