import { Injectable } from '@angular/core';
import { Theme, defaultTheme, engageHubTheme } from './theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  setDefaultTheme(): void {
    this.setActiveTheme(defaultTheme);
  }

  setEngageHubTheme(): void {
    this.setActiveTheme(engageHubTheme);
  }

  setActiveTheme(theme: Theme): void {
    Object.keys(theme.properties).forEach(property => {
      document.documentElement.style.setProperty(property, theme.properties[property]);
    });
  }
}
