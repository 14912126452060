import gql from 'graphql-tag';

export default gql`
  query getUsersByTeamId($teamId: ID!) {
    getUsersByTeamId(teamId: $teamId) {
      nextToken
      users {
        __typename
        username
        cognitoId
        avatarUrl
        cmsUserData
        keywords
        locales
        roles
        registered
        teamId
        userData
      }
    }
  }
`;
