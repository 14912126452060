import { Component } from '@angular/core';
import { RouteService } from '../services/route.service';
import routes from '../routes';

@Component({
  selector: 'internal-chat-body',
  templateUrl: './chat-body.component.html',
  styleUrls: ['./chat-body.component.scss']
})
export class InternalChatBodyComponent {
  _routes = routes;

  route = '';

  constructor(private routeService: RouteService) {
    this.routeService.currentRoute.subscribe(value => (this.route = value));
  }
}
