<div class="text-center">
  <h1 mat-dialog-title class="d-inline-block">{{ 'NOTIFICATIONS.TITLE' | translate }}</h1>
  <button mat-icon-button [tabIndex]="-1" type="button" class="close" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content [class.pb-1]="nextToken">
  <div *ngIf="isLoading" class="user-notification-loader" [@fadeInOut]>
    <mat-spinner mode="indeterminate"></mat-spinner>
  </div>
  <div
    class="row notification border-bottom pt-2"
    *ngFor="let item of userNotifications; let i = index; trackBy: trackNotification"
  >
    <div [ngSwitch]="item.type" class="content-container d-flex align-items-center w-100">
      <div *ngSwitchCase="'INFO'" class="content-container d-flex align-items-center w-100">
        <ng-container *ngTemplateOutlet="userNotificationInfoTemplate; context: { item: item }"></ng-container>
      </div>
      <div
        *ngSwitchCase="item.type.startsWith('CHALLENGE_REQUEST') ? item.type : ''"
        class="content-container d-flex align-items-center w-100"
      >
        <ng-container *ngTemplateOutlet="userNotificationChallengeRequestTemplate; context: { item: item }">
        </ng-container>
      </div>
      <div
        *ngSwitchCase="['CHALLENGE_WON', 'CHALLENGE_LOST', 'CHALLENGE_DRAW'].includes(item.type) ? item.type : ''"
        class="content-container d-flex align-items-center w-100"
      >
        <ng-container *ngTemplateOutlet="userNotificationChallengeResultTemplate; context: { item: item }">
        </ng-container>
      </div>

      <div
        *ngSwitchCase="item.type.startsWith('CHALLENGE_WAGER_REQUEST') ? item.type : ''"
        class="content-container d-flex align-items-center w-100"
      >
        <ng-container *ngTemplateOutlet="userNotificationChallengeWagerRequestTemplate; context: { item: item }">
        </ng-container>
      </div>
      <div
        *ngSwitchCase="
          ['CHALLENGE_WAGER_WON', 'CHALLENGE_WAGER_LOST', 'CHALLENGE_WAGER_DRAW'].includes(item.type) ? item.type : ''
        "
        class="content-container d-flex align-items-center w-100"
      >
        <ng-container *ngTemplateOutlet="userNotificationChallengeWagerResultTemplate; context: { item: item }">
        </ng-container>
      </div>

      <span class="content-container d-flex align-items-center w-100" *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="userNotificationInfoTemplate; context: { item: item }"></ng-container>
      </span>
    </div>
  </div>

  <div class="d-flex justify-content-center align-items-center pt-2" *ngIf="nextToken">
    <button type="button" class="btn btn-sm btn-outline-primary" (click)="loadMoreUserNotifications()">
      {{ 'BUTTON.LOAD_MORE' | translate }}
    </button>
  </div>
</div>

<ng-template #userNotificationInfoTemplate let-item="item">
  <div class="ms-2 notification__icon" *ngIf="item?.icon">
    <app-s3-image [fitContain]="true" [image]="item?.icon"></app-s3-image>
  </div>
  <div class="notification__content ps-2" [class.notification__content_full]="!item?.icon">
    <span class="notification__title" [innerHTML]="item.title" [matTooltip]="item.title"></span>
    <span class="notification__description" [innerHTML]="item.description" [matTooltip]="item.description"></span>
    <div class="notification__content__right-block">
      <span class="notification__date">{{ getNotificationDate(item.id) | date: 'dd MMM yyyy' }}</span>
      <button
        mat-icon-button
        [tabIndex]="-1"
        color="primary"
        (click)="readUserNotification(item)"
        [disabled]="!!item.isRead"
        [matTooltip]="'NOTIFICATIONS.READ_BUTTON_TITLE' | translate"
      >
        <mat-icon>{{ item.isRead ? 'check' : 'check_circle' }}</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #userNotificationChallengeResultTemplate let-item="item">
  <div class="ms-2 notification__icon" *ngIf="getItemIcon(item)">
    <app-s3-image [isRounded]="true" [avatarName]="getItemUserName(item)" [image]="getItemIcon(item)"></app-s3-image>
  </div>
  <div class="notification__content ps-2" [class.notification__content_full]="!getItemIcon(item)">
    <span
      class="notification__title"
      [innerHTML]="'NOTIFICATIONS.TEMPLATE.' + item.type + '.TITLE' | translate: getItemTranslate(item)"
      [matTooltip]="'NOTIFICATIONS.TEMPLATE.' + item.type + '.TITLE' | translate: getItemTranslate(item)"
    >
    </span>
    <span
      class="notification__description"
      [innerHTML]="'NOTIFICATIONS.TEMPLATE.' + item.type + '.DESCRIPTION' | translate: getItemTranslate(item)"
      [matTooltip]="'NOTIFICATIONS.TEMPLATE.' + item.type + '.DESCRIPTION' | translate: getItemTranslate(item)"
    >
    </span>
    <div class="notification__content__right-block">
      <span class="notification__date">{{ getNotificationDate(item.id) | date: 'dd MMM yyyy' }}</span>
      <button
        mat-icon-button
        [tabIndex]="-1"
        color="primary"
        (click)="readUserNotification(item)"
        [disabled]="!!item.isRead"
        [matTooltip]="'NOTIFICATIONS.READ_BUTTON_TITLE' | translate"
      >
        <mat-icon>{{ item.isRead ? 'check' : 'check_circle' }}</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #userNotificationChallengeWagerResultTemplate let-item="item">
  <div class="ms-2 notification__icon" *ngIf="getItemIcon(item)">
    <app-s3-image [isRounded]="true" [avatarName]="getItemUserName(item)" [image]="getItemIcon(item)"></app-s3-image>
  </div>
  <div class="notification__content ps-2" [class.notification__content_full]="!getItemIcon(item)">
    <span
      class="notification__title"
      [innerHTML]="'NOTIFICATIONS.TEMPLATE.' + item.type + '.TITLE' | translate: getItemTranslate(item)"
      [matTooltip]="'NOTIFICATIONS.TEMPLATE.' + item.type + '.TITLE' | translate: getItemTranslate(item)"
    >
    </span>
    <span
      class="notification__description"
      [matTooltip]="
        'CHALLENGES.' + item?.data?.challenge?.type + '_TYPE_DESCRIPTION' | translate: getItemTranslate(item)
      "
    >
      {{ 'CHALLENGES.' + item?.data?.challenge?.type + '_TYPE_DESCRIPTION' | translate: getItemTranslate(item) }}
    </span>
    <div class="d-flex flex-raw justify-content-around align-items-center">
      <div
        class="w-50 notification__challenge-wager py-1"
        [class.notification__challenge-wager__winner]="item?.data?.initiatorId === authHelper.userProfile.cognitoId"
        [class.notification__challenge-wager__looser]="item?.data?.opponentId === authHelper.userProfile.cognitoId"
      >
        <app-user-template class="width-100" [user]="item?.data?.initiatorVote" [displayTooltip]="false">
        </app-user-template>
      </div>
      <div
        class="w-50 notification__challenge-wager py-1"
        [class.notification__challenge-wager__winner]="item?.data?.opponentId === authHelper.userProfile.cognitoId"
        [class.notification__challenge-wager__looser]="item?.data?.initiatorId === authHelper.userProfile.cognitoId"
      >
        <app-user-template class="width-100" [user]="item?.data?.opponentVote" [displayTooltip]="false">
        </app-user-template>
      </div>
    </div>
    <div class="notification__content__right-block">
      <span class="notification__date">{{ getNotificationDate(item.id) | date: 'dd MMM yyyy' }}</span>
      <button
        mat-icon-button
        [tabIndex]="-1"
        color="primary"
        (click)="readUserNotification(item)"
        [disabled]="!!item.isRead"
        [matTooltip]="'NOTIFICATIONS.READ_BUTTON_TITLE' | translate"
      >
        <mat-icon>{{ item.isRead ? 'check' : 'check_circle' }}</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<ng-template #userNotificationChallengeRequestTemplate let-item="item">
  <div class="ms-2 notification__icon" *ngIf="getItemIcon(item)">
    <app-s3-image [isRounded]="true" [avatarName]="getItemUserName(item)" [image]="getItemIcon(item)"></app-s3-image>
  </div>
  <div class="notification__content ps-2" [class.notification__content_full]="!getItemIcon(item)">
    <span
      class="notification__title"
      [matTooltip]="'NOTIFICATIONS.TEMPLATE.' + item.type + '.TITLE' | translate: getItemTranslate(item)"
    >
      {{ 'NOTIFICATIONS.TEMPLATE.' + item.type + '.TITLE' | translate: getItemTranslate(item) }}
    </span>
    <span
      class="notification__description"
      [matTooltip]="'CHALLENGES.' + item?.data?.type + '_TYPE_DESCRIPTION' | translate: getItemTranslate(item)"
    >
      {{ 'CHALLENGES.' + item?.data?.type + '_TYPE_DESCRIPTION' | translate: getItemTranslate(item) }}
    </span>
    <div class="notification__content__right-block">
      <span class="notification__date">{{ getNotificationDate(item.id) | date: 'dd MMM yyyy' }}</span>
      <button
        *ngIf="item.data.status !== 'PENDING' || !!item.isRead"
        mat-icon-button
        [tabIndex]="-1"
        color="primary"
        (click)="readUserNotification(item)"
        [disabled]="!!item.isRead"
        [matTooltip]="'NOTIFICATIONS.READ_BUTTON_TITLE' | translate"
      >
        <mat-icon *ngIf="!item.isRead">check_circle</mat-icon>
        <mat-icon *ngIf="item.isRead && item.type !== 'CHALLENGE_REQUEST_PENDING'">check</mat-icon>
        <mat-icon *ngIf="item.isRead && item.type === 'CHALLENGE_REQUEST_PENDING'">
          {{ item?.data?.status === 'ACCEPTED' ? 'check_circle' : 'block' }}
        </mat-icon>
      </button>
      <div class="d-flex flex-row" *ngIf="item.data.status === 'PENDING' && !item.isRead">
        <button
          mat-icon-button
          [tabIndex]="-1"
          color="primary"
          (click)="changeChallengeStatus(item, 'ACCEPTED')"
          [matTooltip]="'BUTTON.ACCEPT' | translate"
        >
          <mat-icon>check_circle_outline</mat-icon>
        </button>
        <button
          mat-icon-button
          [tabIndex]="-1"
          color="warn"
          (click)="changeChallengeStatus(item, 'REJECTED')"
          [matTooltip]="'BUTTON.REJECT' | translate"
        >
          <mat-icon>block</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #userNotificationChallengeWagerRequestTemplate let-item="item">
  <div class="ms-2 notification__icon" *ngIf="getItemIcon(item)">
    <app-s3-image [isRounded]="true" [avatarName]="getItemUserName(item)" [image]="getItemIcon(item)"></app-s3-image>
  </div>
  <div class="notification__content ps-2" [class.notification__content_full]="!getItemIcon(item)">
    <span
      class="notification__title"
      [matTooltip]="'NOTIFICATIONS.TEMPLATE.' + item.type + '.TITLE' | translate: getItemTranslate(item)"
    >
      {{ 'NOTIFICATIONS.TEMPLATE.' + item.type + '.TITLE' | translate: getItemTranslate(item) }}
    </span>
    <span
      class="notification__description"
      [matTooltip]="
        'CHALLENGES.' + item?.data?.challenge?.type + '_TYPE_DESCRIPTION' | translate: getItemTranslate(item)
      "
    >
      {{ 'CHALLENGES.' + item?.data?.challenge?.type + '_TYPE_DESCRIPTION' | translate: getItemTranslate(item) }}
    </span>
    <div class="d-flex flex-raw justify-content-around align-items-center">
      <div
        class="w-50 notification__challenge-wager py-1"
        [class.notification__challenge-wager__winner]="item?.data?.initiatorId === authHelper.userProfile.cognitoId"
        [class.notification__challenge-wager__looser]="item?.data?.opponentId === authHelper.userProfile.cognitoId"
      >
        <app-user-template class="width-100" [user]="item?.data?.initiatorVote" [displayTooltip]="false">
        </app-user-template>
      </div>
      <div
        class="w-50 notification__challenge-wager py-1"
        [class.notification__challenge-wager__winner]="item?.data?.opponentId === authHelper.userProfile.cognitoId"
        [class.notification__challenge-wager__looser]="item?.data?.initiatorId === authHelper.userProfile.cognitoId"
      >
        <app-user-template class="width-100" [user]="item?.data?.opponentVote" [displayTooltip]="false">
        </app-user-template>
      </div>
    </div>
    <div class="notification__content__right-block">
      <span class="notification__date">{{ getNotificationDate(item.id) | date: 'dd MMM yyyy' }}</span>
      <button
        *ngIf="item.data.status !== 'PENDING' || !!item.isRead"
        mat-icon-button
        [tabIndex]="-1"
        color="primary"
        (click)="readUserNotification(item)"
        [disabled]="!!item.isRead"
        [matTooltip]="'NOTIFICATIONS.READ_BUTTON_TITLE' | translate"
      >
        <mat-icon *ngIf="!item.isRead">check_circle</mat-icon>
        <mat-icon *ngIf="item.isRead && item.type !== 'CHALLENGE_WAGER_REQUEST_PENDING'">check</mat-icon>
        <mat-icon *ngIf="item.isRead && item.type === 'CHALLENGE_WAGER_REQUEST_PENDING'">
          {{ item?.data?.status === 'ACCEPTED' ? 'check_circle' : 'block' }}
        </mat-icon>
      </button>
      <div class="d-flex flex-row" *ngIf="item.data.status === 'PENDING' && !item.isRead">
        <button
          mat-icon-button
          [tabIndex]="-1"
          color="primary"
          (click)="changeChallengeWagerStatus(item, 'ACCEPTED')"
          [matTooltip]="'BUTTON.ACCEPT' | translate"
        >
          <mat-icon>check_circle_outline</mat-icon>
        </button>
        <button
          mat-icon-button
          [tabIndex]="-1"
          color="warn"
          (click)="changeChallengeWagerStatus(item, 'REJECTED')"
          [matTooltip]="'BUTTON.REJECT' | translate"
        >
          <mat-icon>block</mat-icon>
        </button>
      </div>
    </div>
  </div>
</ng-template>
