<div class="queue-history-page">
  <ngx-loading [show]="isLoading"></ngx-loading>

  <mat-card class="ctn-card">
    <mat-card-content>
      <section class="d-flex align-items-center">
        <app-user-input-autocomplete (selectionChange)="onSelectUser($event)" (usersLoad)="isLoading = false">
        </app-user-input-autocomplete>
      </section>

      <div *ngIf="user" class="queue-history-list" [@listFadeInOut]="history.length">
        <div
          *ngFor="let item of history; trackBy: trackItem"
          class="border rounded p-2 align-items-center"
          [class.queue-history-list__item__progress]="!item?.finishedAt"
        >
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center item-content">
              <i
                [class]="classIcon[item?.conversation?.channel?.toLocaleLowerCase()] + ' icon pe-1'"
                [matTooltip]="item?.conversation?.channel"
              >
              </i>
              <span class="px-2">
                <i
                  class="material-icons ctn-icon-middle"
                  [matTooltip]="'SENTIMENT.' + item?.conversation?.sentiment | translate"
                >
                  {{ mapSentimentToIcon[item?.conversation?.sentiment] }}
                </i>
              </span>
              <div class="pe-2" *ngIf="item?.agent">
                <app-user-template [user]="item?.agent"></app-user-template>
              </div>
              <span
                class="flag-icon flag-icon-{{ localeUtil.getRegion(item?.conversation?.locale).toLowerCase() }}"
                [matTooltip]="'LOCALE.' + localeUtil.getName(item?.conversation?.locale) | translate"
              >
              </span>
              <span class="ps-2">{{ item?.conversation?.name }}</span>
              <span class="ps-2">[ {{ item?.conversation?.keywords }} ]</span>
            </div>
            <span>
              <span class="pe-2">{{ item?.conversation?.createdAt | date: 'short' }}</span>
              <strong>{{ item?.waitingTime }}</strong>
            </span>
          </div>
        </div>

        <div *ngIf="nextToken" class="d-flex flex-row justify-content-center align-items-center my-2">
          <button mat-raised-button color="primary" (click)="loadMoreUsersConversationQueueHistory()">
            {{ 'BUTTON.LOAD_MORE' | translate }}
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
