import gql from 'graphql-tag';

export default gql`
  subscription subscribeToNewUCs($userId: ID!) {
    subscribeToNewUCs(userId: $userId) {
      __typename
      userId
      conversationId
      conversation {
        __typename
        id
        name
        caseId
        subject
        createdBy
        history
        author {
          cognitoId
          username
          userData
        }
        keywords
        locale
        location
        sentiment
        intentsTriggered
        botSessionId
        lastQuestion
        status
        channel
        cms {
          conversationId
          isCore
          type
          id
          createdAt
        }
        transfers {
          conversationId
          createdAt
          userId
          nextUserId
          description
          user {
            cognitoId
            username
          }
          nextUser {
            cognitoId
            username
          }
        }
      }
      associated {
        __typename
        conversationId
        userId
        user {
          __typename
          cognitoId
          username
          registered
          avatarUrl
        }
        typing
        connected
      }
    }
  }
`;
