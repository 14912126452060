<div class="review-feedback">
  <ngx-loading [show]="isLoading"></ngx-loading>

  <mat-card class="ctn-card">
    <mat-card-content>
      <h4 class="ctn-h3 ctn-margin">
        <fa-icon [icon]="faUser"></fa-icon>
        {{ 'ADMIN_REVIEW_FEEDBACK.TITLE' | translate }}
      </h4>

      <hr />

      <section class="d-flex align-items-center">
        <mat-form-field class="ctn-select pe-2">
          <mat-select
            name="status"
            [placeholder]="'ADMIN_REVIEW_FEEDBACK.SELECT_STATUS.PLACEHOLDER' | translate"
            [(ngModel)]="selectedStatus"
            (selectionChange)="onChangeFilters()"
          >
            <mat-option>{{ 'ADMIN_REVIEW_FEEDBACK.SELECT_STATUS.ALL' | translate }}</mat-option>
            <mat-option *ngFor="let status of statuses" [value]="status">
              <div class="d-flex align-items-center">
                {{ 'ADMIN_REVIEW_FEEDBACK.SELECT_STATUS.STATUS_' + status | translate }}
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <app-agent-input-autocomplete
          [teamLeadId]="auth.userProfile.cognitoId"
          [withTeam]="true"
          (selectionChange)="onChangeUser($event)"
          (agentsLoad)="onAgentsLoaded($event)"
          [placeholder]="'ADMIN_REVIEW_FEEDBACK.SELECT_USER.PLACEHOLDER' | translate"
          [placeholderLabel]="'ADMIN_REVIEW_FEEDBACK.SELECT_USER.INPUT_PLACEHOLDER' | translate"
        >
        </app-agent-input-autocomplete>

        <button
          mat-stroked-button
          color="primary"
          *ngIf="pendingFeedbackComments.length"
          (click)="approveAllPendingComments()"
          [title]="'ADMIN_REVIEW_FEEDBACK.COMMENTS.APPROVE_ALL.BUTTON_TEXT' | translate"
        >
          <mat-icon>playlist_add_check</mat-icon>
        </button>
      </section>

      <hr />

      <section>
        <h1 class="review-feedback__table-title">
          {{ 'ADMIN_REVIEW_FEEDBACK.COMMENTS.TITLE' | translate }}
        </h1>
        <div class="review-feedback__table-container" [@listFadeInOut]="feedbackComments.length" #ctnContainer>
          <div
            *ngIf="(!feedbackComments || !feedbackComments.length) && (isLoadingComments || isLoading)"
            class="loader"
          >
            {{ 'GENERAL.LOADING' | translate }}...
          </div>

          <table class="review-feedback__table">
            <thead>
              <tr>
                <th>{{ 'ADMIN_REVIEW_FEEDBACK.COMMENTS.DATE_TITLE' | translate }}</th>
                <th>{{ 'ADMIN_REVIEW_FEEDBACK.COMMENTS.USER_TITLE' | translate }}</th>
                <th>{{ 'ADMIN_REVIEW_FEEDBACK.COMMENTS.STARS_TITLE' | translate }}</th>
                <th>{{ 'ADMIN_REVIEW_FEEDBACK.COMMENTS.CONVERSATION_TITLE' | translate }}</th>
                <th>{{ 'ADMIN_REVIEW_FEEDBACK.COMMENTS.ACTIONS_TITLE' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let comment of feedbackComments; trackBy: trackFeedbackComment"
                class="review-feedback__table-body-row"
                (click)="openCustomerHistory(comment.conversationId)"
              >
                <td>
                  {{ comment.createdAt | date: 'dd MMM yyyy HH:mm' }}
                </td>
                <td>
                  <app-user-template
                    class="width-100"
                    [user]="comment.agent"
                    tooltipField="userData.status"
                  ></app-user-template>
                </td>
                <td>
                  <div class="stars">
                    <span
                      class="stars__item"
                      [class.stars__item_inactive]="i + 1 > +comment.rating"
                      *ngFor="let s of Array(maxStars); let i = index"
                    >
                      <mat-icon>grade</mat-icon>
                    </span>
                  </div>
                </td>
                <td>
                  <div class="review-feedback__table-td-customer">
                    <span
                      class="review-feedback__table-td-customer-name"
                      [matTooltip]="comment?.customer?.username || '-'"
                    >
                      {{ comment?.customer?.username || '-' }}
                    </span>
                    <span class="review-feedback__table-td-customer-message" [matTooltip]="comment.message">
                      {{ comment.message }}
                    </span>
                  </div>
                </td>
                <td>
                  <div *ngIf="comment.status === 'PENDING'" class="review-feedback__table-td-actions">
                    <button
                      mat-icon-button
                      class="review-feedback__table-td-actions-add-ach"
                      (click)="actionButtonEvent($event, comment, actionButtonsTypeEnum.addAchievement)"
                      [matTooltip]="'ADMIN_REVIEW_FEEDBACK.COMMENTS.ADD_ACHIEVEMENT_TITLE' | translate"
                    >
                      <mat-icon>emoji_events</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      color="primary"
                      class="review-feedback__table-td-actions-approve"
                      (click)="actionButtonEvent($event, comment, actionButtonsTypeEnum.approved)"
                      [matTooltip]="'ADMIN_REVIEW_FEEDBACK.COMMENTS.APPROVE_BUTTON_TEXT' | translate"
                    >
                      <mat-icon>check_circle_outline</mat-icon>
                    </button>
                    <button
                      mat-icon-button
                      color="warn"
                      class="review-feedback__table-td-actions-reject"
                      (click)="actionButtonEvent($event, comment, actionButtonsTypeEnum.rejected)"
                      [matTooltip]="'ADMIN_REVIEW_FEEDBACK.COMMENTS.REJECT_BUTTON_TEXT' | translate"
                    >
                      <mat-icon>block</mat-icon>
                    </button>
                  </div>
                  <div
                    *ngIf="comment.status !== 'PENDING'"
                    class="review-feedback__table-td-status"
                    [class.review-feedback__table-td-status_approved]="comment.status === 'APPROVED'"
                    [class.review-feedback__table-td-status_rejected]="comment.status === 'REJECTED'"
                  >
                    <mat-icon>
                      {{ comment.status === 'APPROVED' ? 'check_circle_outline' : 'block' }}
                    </mat-icon>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <p *ngIf="!feedbackComments || !feedbackComments.length" class="empty-message">
            {{ 'ADMIN_REVIEW_FEEDBACK.COMMENTS.NO_FEEDBACKS' | translate }}
          </p>

          <div class="loader-more" #loading>
            <span *ngIf="feedbackComments && feedbackComments.length && isLoadingComments">
              {{ 'GENERAL.LOADING' | translate }}...
            </span>
          </div>
        </div>
      </section>
    </mat-card-content>
  </mat-card>
</div>
