import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { fadeInOut } from '@cation/core/animations';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import IAchievement from '@cation/core/types/achievement';
import IUser from '@cation/core/types/user';

@Component({
  selector: 'app-ctn-add-achievement-dialog',
  templateUrl: './add-achievement-dialog.component.html',
  styleUrls: ['./add-achievement-dialog.component.scss'],
  animations: [fadeInOut]
})
export class AddAchievementDialogComponent implements OnInit {
  loaderId = 'add-achievement-dialog-loader';
  public achievements: IAchievement[] = [];

  achievementFormGroup: UntypedFormGroup;
  descriptionFormGroup: UntypedFormGroup;
  extraPointsFormGroup: UntypedFormGroup;

  public isLoading = false;
  public isLoadingAvailablePoints = false;
  public teamMonthlyPointPool = 0;
  public availablePoints;
  public errorMessage;
  public errorCode;
  public errorAvailablePoints;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { excludeAchievements: string[]; user: IUser },
    public dialogRef: MatDialogRef<AddAchievementDialogComponent>,
    private ngxLoader: NgxUiLoaderService,
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private s3Service: S3Service,
    private logService: LogService
  ) {}

  ngOnInit() {
    this.achievementFormGroup = this.formBuilder.group({
      achievement: ['', Validators.required]
    });

    this.descriptionFormGroup = this.formBuilder.group({
      description: ['', Validators.required]
    });

    this.extraPointsFormGroup = this.formBuilder.group({
      extraPoints: [0, Validators.required]
    });
    this.getAchievements();
    this.getAvailableTeamPoints();

    this.extraPointsFormGroup.get('extraPoints').valueChanges.subscribe(() => this.clearError());
  }

  get achievementForm() {
    return this.achievementFormGroup.controls;
  }

  get descriptionForm() {
    return this.descriptionFormGroup.controls;
  }

  get extraPointsForm() {
    return this.extraPointsFormGroup.controls;
  }

  async selectAchievement(achievement) {
    this.achievementForm.achievement.setValue(achievement);
    this.logService.log('[AddEditAchievementComponent selectAchievement]', achievement);
  }

  async getAchievements() {
    this.isLoading = true;
    try {
      const achievements = await this.apiService.getAchievements();
      if (this.data && this.data.excludeAchievements && Array.isArray(this.data.excludeAchievements)) {
        this.achievements = achievements.filter(a => !this.data.excludeAchievements.includes(a.id));
      }
    } catch (e) {
      this.errorMessage = e.message;
      this.logService.error('[AddEditAchievementComponent getAchievements:error]', e);
    }
    this.isLoading = false;
  }

  async getAvailableTeamPoints() {
    if (!this.data.user.teamId) {
      return;
    }
    this.isLoadingAvailablePoints = true;
    try {
      const settings = await this.apiService.getGamificationSettings();
      this.teamMonthlyPointPool = settings.teamMonthlyPointPool;

      const teamInfo = await this.apiService.getGamificationMemberInfo('TEAM', this.data.user.teamId);

      this.availablePoints = settings.teamMonthlyPointPool - teamInfo.monthlyPointPool;
    } catch (e) {
      this.errorMessage = e.message;
      this.logService.error('[AddEditAchievementComponent getAchievements:error]', e);
    }
    this.isLoadingAvailablePoints = false;
  }

  async onAddAchievement() {
    this.clearError();

    const achievement = {
      userId: this.data.user.cognitoId,
      achievementId: `${this.achievementForm.achievement.value.id}_${new Date().toISOString()}`,
      description: this.descriptionForm.description.value,
      extraPoints: this.extraPointsForm.extraPoints.value
    };
    this.logService.log('[AddEditAchievementComponent achievement]', achievement);

    this.ngxLoader.startLoader(this.loaderId);
    try {
      await this.apiService.addUserAchievement(achievement);
      this.dialogRef.close(achievement);
    } catch (e) {
      this.logService.error('[AddEditAchievementComponent onAddAchievement:error]', e);
      if (e.error.ERROR_CODE) {
        this.errorCode = e.error.ERROR_CODE;
        this.errorAvailablePoints = e.error.limit - e.error.current;
      } else {
        this.errorMessage = e.message;
      }
    }
    this.ngxLoader.stopLoader(this.loaderId);
  }

  clearError() {
    this.errorCode = null;
    this.errorAvailablePoints = null;
    this.errorMessage = null;
  }
}
