<div class="ctn-content" #ctnContainer>
  <table>
    <thead>
      <tr>
        <th>
          {{ 'CHALLENGES.STATUS_TITLE' | translate }}
        </th>
        <th>
          {{ 'CHALLENGES.RATE_TITLE' | translate }}
        </th>
        <th>
          {{ 'CHALLENGES.INITIATOR_TITLE' | translate }}
        </th>
        <th>
          {{ 'CHALLENGES.OPPONENT_TITLE' | translate }}
        </th>
        <th>
          {{ 'CHALLENGES.DESCRIPTION_TITLE' | translate }}
        </th>
        <th *ngIf="['ACTIVE', 'AVAILABLE'].includes(tab)" [matTooltip]="'CHALLENGES.BET_TITLE' | translate">
          {{ 'CHALLENGES.BET_TITLE' | translate }}
        </th>
        <th *ngIf="'PROPOSALS' === tab" [matTooltip]="'CHALLENGES.ACTIONS_TITLE' | translate">
          {{ 'CHALLENGES.ACTIONS_TITLE' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let challenge of challenges; let i = index; trackBy: trackItem"
        [@listFadeInOut]="challenges.length"
        [class.challenge__item__winner]="challenge.winnerId && challenge.winnerId === authHelper.userProfile.cognitoId"
        [class.challenge__item__looser]="challenge.winnerId && challenge.winnerId !== authHelper.userProfile.cognitoId"
      >
        <td>
          <span class="challenge__item__status" [matTooltip]="challenge.status | lowercase">
            <mat-icon [ngClass]="mapChallengeStatusToIcon[challenge.status]">{{
              mapChallengeStatusToIcon[challenge.status]
            }}</mat-icon>
          </span>
        </td>
        <td>
          <span>
            <span *ngIf="challenge.winnerId">{{
              challenge.winnerId === authHelper.userProfile.cognitoId ? '+' : '-'
            }}</span>
            {{ challenge.cost }}
          </span>
        </td>
        <td>
          <app-user-template
            class="icon-30"
            [user]="challenge.initiator"
            tooltipField="userData.status"
          ></app-user-template>
        </td>
        <td>
          <app-user-template
            class="icon-30"
            [user]="challenge.opponent"
            tooltipField="userData.status"
          ></app-user-template>
        </td>
        <td>
          <span>
            {{
              'CHALLENGES.' + challenge.type + '_TYPE_DESCRIPTION'
                | translate
                  : getChallengeTypeTranslateContext({
                      startDate: challenge.startDate,
                      finishDate: challenge.finishDate,
                      data: challenge.data
                    })
            }}
          </span>
        </td>
        <td *ngIf="['ACTIVE', 'AVAILABLE'].includes(tab)">
          <button
            class="mx-2"
            *ngIf="
              challenge.status === 'ACCEPTED' &&
              Date.now() < challenge.startDate &&
              authHelper.userProfile.cognitoId !== challenge.opponent.cognitoId &&
              authHelper.userProfile.cognitoId !== challenge.initiator.cognitoId
            "
            mat-icon-button
            color="primary"
            (click)="makeWager($event, i)"
            [matTooltip]="'BUTTON.ADD' | translate"
          >
            <mat-icon>how_to_vote</mat-icon>
          </button>
        </td>
        <td *ngIf="'PROPOSALS' === tab">
          <div
            class="d-flex flex-row"
            *ngIf="challenge.status === 'PENDING' && authHelper.userProfile.cognitoId === challenge.opponent.cognitoId"
          >
            <button
              mat-icon-button
              [tabIndex]="-1"
              color="primary"
              (click)="changeChallengeStatus($event, tab, challenge.id, challenge.type, 'ACCEPTED')"
              [matTooltip]="'BUTTON.ACCEPT' | translate"
            >
              <mat-icon>check_circle_outline</mat-icon>
            </button>
            <button
              class="ms-1"
              mat-icon-button
              [tabIndex]="-1"
              color="warn"
              (click)="changeChallengeStatus($event, tab, challenge.id, challenge.type, 'REJECTED')"
              [matTooltip]="'BUTTON.REJECT' | translate"
            >
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <p *ngIf="!challenges.length" class="empty-message">{{ 'CHALLENGES.NO_BATTLES' | translate }}</p>

  <div class="loader-more" #loading>
    <span *ngIf="isLoading && challenges && challenges.length">{{ 'GENERAL.LOADING' | translate }}...</span>
  </div>
</div>
