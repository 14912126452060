import { Injectable, EventEmitter, Output, Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import routes from '../routes';

@Directive()
@Injectable()
export class RouteService {
  @Output() routeEmitter: EventEmitter<any> = new EventEmitter();

  private route = new BehaviorSubject(routes.USERS_LIST);
  currentRoute = this.route.asObservable();

  public prevRoute;

  setRoute(route: string) {
    console.log('[RouteService setRoute]', route);
    this.prevRoute = this.route.value;
    this.route.next(route);
  }
}
