import User from '@cation/core/types/user';

interface IAuditItemMetaData {
  [prop: string]: any;
}

export interface IAuditItem {
  activity: string;
  userId: string;
  date: string;
  metaData: IAuditItemMetaData;
  user: User;
}

export type IAuditsResponse = {
  items: IAuditItem[];
  lastKey: object;
};
