import * as update from 'immutability-helper';
import * as _ from 'lodash';
import { getUsersConversationQueueHistoryQuery as QueueHistoryQuery } from '@cation/core/graphql/operation-result-types';
import ConversationQueueHistory from '@cation/core/types/conversationQueueHistory';

const getHistoryKey = item => `${item.userId}-${item.conversationId}-${item.startedAt}`;

export function unshiftConversationQueueHistory(
  data: QueueHistoryQuery,
  c: ConversationQueueHistory
): QueueHistoryQuery {
  if (!data || !_.has(data, 'getUsersConversationQueueHistory.items')) {
    return {
      getUsersConversationQueueHistory: {
        nextToken: null,
        __typename: 'ListConversationQueueHistory',
        items: []
      }
    };
  }

  if (data.getUsersConversationQueueHistory.items.some(item => getHistoryKey(item) === getHistoryKey(c))) {
    return data;
  }

  return update(data, { getUsersConversationQueueHistory: { items: { $unshift: [c] } } });
}

export function addConversationQueueHistories(
  data: QueueHistoryQuery,
  c: ConversationQueueHistory[],
  nextToken: string
): QueueHistoryQuery {
  if (!data || !_.has(data, 'getUsersConversationQueueHistory.items')) {
    return {
      getUsersConversationQueueHistory: {
        nextToken: null,
        __typename: 'ListConversationQueueHistory',
        items: []
      }
    };
  }

  const newRecords = _.differenceBy(c, data.getUsersConversationQueueHistory.items, item => getHistoryKey(item));

  return update(data, {
    getUsersConversationQueueHistory: {
      nextToken: { $set: nextToken },
      items: { $push: newRecords }
    }
  });
}

export function updateConversationQueueHistory(
  data: QueueHistoryQuery,
  c: ConversationQueueHistory
): QueueHistoryQuery {
  if (!data || !_.has(data, 'getUsersConversationQueueHistory.items')) {
    return {
      getUsersConversationQueueHistory: {
        nextToken: null,
        __typename: 'ListConversationQueueHistory',
        items: []
      }
    };
  }

  const itemIndex = data.getUsersConversationQueueHistory.items.findIndex(
    item => getHistoryKey(item) === getHistoryKey(c)
  );

  if (itemIndex === -1) {
    return unshiftConversationQueueHistory(data, c);
  }

  return update(data, {
    getConversationsInQueue: { conversations: { [itemIndex]: { $set: c } } }
  });
}
