import { Injectable } from '@angular/core';
import { Locale } from '../enums/locale';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleUtil {
  private localeMap = new Map();

  constructor(private translate: TranslateService) {
    const locales: { [props: string]: string } = {};

    for (const locale of Locale) {
      this.localeMap.set(locale.code, locale.name);
      locales[locale.code] = `LOCALE.${locale.name}`;
    }

    this.translate.stream(Object.values(locales)).subscribe(values => {
      for (const locale of Locale) {
        this.localeMap.set(locale.code, values[locales[locale.code]]);
      }
    });
  }

  getName(code) {
    return this.localeMap.get(code);
  }

  getRegion(code) {
    return (code && code.includes('_') && code.split('_')[1]) || '';
  }
}
