import { Component, Input } from '@angular/core';

@Component({
  selector: 'internal-messages-counter',
  templateUrl: './messages-counter.component.html',
  styleUrls: ['./messages-counter.component.scss']
})
export class MessagesCounterComponent {
  _unreadMessages: string | number;

  @Input() set unreadMessages(value) {
    if (value > 9) {
      this._unreadMessages = '9+';
    } else {
      this._unreadMessages = value;
    }
  }
}
