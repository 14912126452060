import { Component, Input, ViewChild } from '@angular/core';
import User from '@cation/core/types/user';
import { environment } from '@cation/env/environment';
import { ChallengeWagersListComponent } from '../challenge-wagers-list/challenge-wagers-list.component';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  @Input() me: User;

  @ViewChild(ChallengeWagersListComponent, { static: true }) challengeWagersList: ChallengeWagersListComponent;

  widgets = ['TEAM_ACTIVITY', 'CHALLENGES', 'CONVERSATIONS_STATS', 'WAGERS'];

  public autoRefresh = environment.autoRefreshDashboard;

  addChallengeWager() {
    if (this.challengeWagersList) {
      this.challengeWagersList.getAllGamificationChallengeWagers();
    }
  }
}
