<div class="card mb-2 chat-note">
  <ngx-ui-loader [fgsSize]="25" [loaderId]="loaderId"></ngx-ui-loader>
  <div class="card-body p-2 border rounded">
    <div class="clearfix">
      <h5 class="card-title mb-1 float-start">
        {{ note.author?.username || 'CONVERSATION_NOTE_VIEW.UNKNOWN_AUTHOR' | translate }}
      </h5>
      <h6 class="card-subtitle mb-0 text-muted">&nbsp;{{ createdAt | momentAgo }}</h6>
      <div *ngIf="!readOnly" class="float-end">
        <button class="btn btn-outline-warning btn-sm btn-action p-0 me-1" (click)="onOpenEditConversationNoteModal()">
          <mat-icon [matTooltip]="'CONVERSATION_NOTE_VIEW.EDIT_BUTTON_TOOLTIP' | translate">edit</mat-icon>
        </button>
        <button
          class="btn btn-outline-danger btn-sm btn-action p-0"
          mwlConfirmationPopover
          [popoverTitle]="'CONVERSATION_NOTE_VIEW.DELETE_POPOVER_TITLE' | translate"
          [popoverMessage]="'CONVERSATION_NOTE_VIEW.DELETE_POPOVER_MESSAGE' | translate"
          [confirmText]="'BUTTON.YES' | translate"
          [cancelText]="'BUTTON.NO' | translate"
          placement="bottom"
          (confirm)="deleteNote()"
          (cancel)="(false)"
        >
          <mat-icon [matTooltip]="'CONVERSATION_NOTE_VIEW.DELETE_BUTTON_TOOLTIP' | translate">delete</mat-icon>
        </button>
      </div>
    </div>
    <p class="card-text mb-0 note-content" [innerHTML]="content || '' | trustHtml" (click)="handleLink($event)"></p>
  </div>
</div>
