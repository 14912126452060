import * as update from 'immutability-helper';
import * as _ from 'lodash';
import { getUserNotificationsQuery as UserNotificationsQuery } from '@cation/core/graphql/operation-result-types';
import UserNotification from '@cation/core/types/userNotification';

export function unshiftUserNotification(data: UserNotificationsQuery, un: UserNotification): UserNotificationsQuery {
  if (!data || !_.has(data, 'getUserNotifications.userNotifications')) {
    return {
      getUserNotifications: {
        nextToken: null,
        __typename: 'ListUserNotifications',
        userNotifications: [],
      },
    };
  }

  const itemIndex = data.getUserNotifications.userNotifications.findIndex((n) => n.id === un.id);

  if (itemIndex !== -1) {
    return updateUserNotification(data, un);
  }

  return update(data, { getUserNotifications: { userNotifications: { $unshift: [un] } } });
}

export function addUserNotifications(
  data: UserNotificationsQuery,
  uns: UserNotification[],
  nextToken: string
): UserNotificationsQuery {
  if (!data || !_.has(data, 'getUserNotifications.userNotifications')) {
    return {
      getUserNotifications: {
        nextToken: null,
        __typename: 'ListUserNotifications',
        userNotifications: [],
      },
    };
  }

  const newRecords = _.differenceBy(uns, data.getUserNotifications.userNotifications, 'id');

  return update(data, {
    getUserNotifications: {
      userNotifications: { $push: newRecords },
      nextToken: { $set: nextToken },
    },
  });
}

export function updateUserNotification(data: UserNotificationsQuery, n: UserNotification): UserNotificationsQuery {
  if (!data || !_.has(data, 'getUserNotifications.userNotifications')) {
    return {
      getUserNotifications: {
        nextToken: null,
        __typename: 'ListUserNotifications',
        userNotifications: [],
      },
    };
  }
  const itemIndex = data.getUserNotifications.userNotifications.findIndex((un) => un.id === n.id);

  if (itemIndex === -1) {
    return undefined;
  }

  return update(data, {
    getUserNotifications: { userNotifications: { [itemIndex]: { $set: n } } },
  });
}
