import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Storage } from '@aws-amplify/storage';
import { saveAs } from 'file-saver';
import { SharedService } from '../services/shared.service';
import { LoggerService } from '../services/logger.service';
import Message from '../types/message';

@Component({
  selector: 'internal-message-balloon',
  templateUrl: './message-balloon.component.html',
  styleUrls: ['./message-balloon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageBalloonComponent implements OnInit {
  isWrittenByCurrentUser: boolean;
  isAttachmentLoading: boolean = false;
  userName = '';

  @Input() message;
  @Input() attachment;

  constructor(
    private sharedService: SharedService,
    private loggerService: LoggerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.isWrittenByCurrentUser = this.message.senderId === this.sharedService.userProfile.id;

    if (!this.isWrittenByCurrentUser) {
      const { associated } = this.sharedService.openedChat;
      this.userName = associated.find(({ userId }) => userId === this.message.senderId).user.name;
    }
  }

  async saveFile($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.isAttachmentLoading = true;
    try {
      const { key, name } = this.message.attachment;

      const url: string = (await Storage.get(key, { expires: 60, level: 'public' })) as string;

      const response = await fetch(url);
      const blob = await response.blob();

      this.loggerService.info('[saveFile loaded]', { url, response, blob });

      saveAs(blob, name);
    } catch (error) {
      this.loggerService.error('[saveFile error]', error);
    }
    this.isAttachmentLoading = false;

    this.cdr.detectChanges();
  }
}
