import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HotKeys } from '@cation/core/util/hot-keys';
import { CMS_TYPE } from '@cation/core/enums/cms-type.enum';
import { CmsService } from '@cation/core/services/cms';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { ICMS } from '@cation/core/interfaces/cms';
import { LogService } from '@cation/core/services/log/log.service';
import { HotKey } from '@cation/core/enums/hotkey.enum';
import { AppsyncService } from '@cation/core/services/appsync/appsync.service';
import Conversation from '@cation/core/types/conversation';
import { EscalateConversationDialogComponent } from '../escalate-conversation-dialog/escalate-conversation-dialog.component';

@Component({
  selector: 'app-cms-actions',
  templateUrl: './cms-actions.component.html',
  styleUrls: ['./cms-actions.component.scss'],
})
export class CmsActionsComponent implements OnInit, OnDestroy {
  HotKey = HotKey;

  public isMainAgent = false;
  // public cmsSystems: CMS_TYPE[] = [
  //   CMS_TYPE.JIRA,
  //   CMS_TYPE.ZENDESK,
  //   CMS_TYPE.SERVICE_NOW,
  //   CMS_TYPE.REMEDY,
  //   CMS_TYPE.SALES_FORCE,
  // ];
  public submitted = false;
  public loaderId = 'cms-actions-modal-loader';
  public loadCmsLoaderId = 'cms-actions-load-by-id-modal-loader';

  public cmsFormGroup: UntypedFormGroup;
  public loadCmsIdCtrl: UntypedFormControl = new UntypedFormControl('', Validators.required);
  public loadCmsFilterCtrl: UntypedFormControl = new UntypedFormControl('', Validators.required);
  // private cmsType: CMS_TYPE;

  public loadCmsByFilterResult;
  private hotkeys: Subscription[] = [];

  _conversation: Conversation;
  @Input() set conversation(conv: Conversation) {
    this._conversation = conv;
    this.modalService.dismissAll();
    if (this.escalateCmsDialog) {
      this.escalateCmsDialog.close();
    }
    this.isMainAgent =
      (conv.associated.find((a) => a.userId === this.auth.userProfile.cognitoId) || { role: '' }).role === 'AGENT';
  }

  @Output() onAddCmsSystem = new EventEmitter<ICMS>();
  @Output() onCloseConversation = new EventEmitter<void>();

  @ViewChild('loadCmsModal', { static: true }) private loadCmsModal: ElementRef;
  @ViewChild('sendCmsModal', { static: true }) private sendCmsModal: ElementRef;
  private escalateCmsDialog;

  constructor(
    private cmsService: CmsService,
    private appsync: AppsyncService,
    private auth: AuthHelper,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private ngxLoader: NgxUiLoaderService,
    private dialog: MatDialog,
    private logService: LogService,
    private hotkeysService: HotKeys
  ) {
    this.hotKeyLoadCmsCallback = this.hotKeyLoadCmsCallback.bind(this);
    this.hotKeySendToCmsCallback = this.hotKeySendToCmsCallback.bind(this);
    this.hotKeyEscalateCmsCallback = this.hotKeyEscalateCmsCallback.bind(this);
    this.addHotkey(HotKey.LOAD_CMS, this.hotKeyLoadCmsCallback);
    this.addHotkey(HotKey.SEND_TO_CMS, this.hotKeySendToCmsCallback);
    this.addHotkey(HotKey.ESCALATE_CMS, this.hotKeyEscalateCmsCallback);
  }

  ngOnInit() {
    this.cmsFormGroup = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    this.hotkeys.map((hotkey) => hotkey.unsubscribe());
  }

  get conversation() {
    return this._conversation;
  }

  get cmsForm() {
    return this.cmsFormGroup.controls;
  }

  addHotkey(combo: string, callback: (event?: KeyboardEvent) => boolean) {
    const hotkey = this.hotkeysService
      .on(combo)
      .subscribe((event: KeyboardEvent) => this.hotkeyCallback(event, callback));
    this.hotkeys.push(hotkey);
  }

  hotKeyLoadCmsCallback(): boolean {
    this.openModal(this.loadCmsModal);
    return false;
  }

  hotKeySendToCmsCallback(): boolean {
    this.openModal(this.sendCmsModal);
    return false;
  }

  hotKeyEscalateCmsCallback(): boolean {
    this.onOpenEscalateModal();
    return false;
  }

  hotkeyCallback(event: KeyboardEvent, callback: (event?: KeyboardEvent) => boolean): boolean {
    if (this.modalService.hasOpenModals() || (this.escalateCmsDialog && this.escalateCmsDialog.componentInstance)) {
      return undefined;
    }
    return callback(event);
  }

  hotKeyToString(hotKey: HotKey | string = ''): string {
    return ` (${hotKey.replace(/\./g, '+').toUpperCase()})`;
  }

  clearForms() {
    this.submitted = false;
    // this.cmsType = null;
    this.loadCmsByFilterResult = null;
    this.cmsFormGroup.reset();
    this.loadCmsFilterCtrl.reset();
    this.loadCmsIdCtrl.reset();
  }

  openModal(content, size?: 'sm' | 'lg') {
    this.clearForms();
    this.modalService
      .open(content, { size })
      .result.then(() => this.logService.info('[modalService Success]'))
      .catch((err) => this.logService.log('update profile error', err));
  }

  onOpenEscalateModal() {
    this.escalateCmsDialog = this.dialog.open(EscalateConversationDialogComponent, {
      data: { conversation: this.conversation, onCloseConversation: this.onCloseConversation },
    });

    this.escalateCmsDialog.afterClosed().subscribe(async (result) => {
      this.logService.log('[onOpenEscalateModal result]', result);
    });
  }

  // onSelectCmsType(cms: CMS_TYPE) {
  //   this.cmsType = cms;
  // }

  // getCmsName(cms: CMS_TYPE): string {
  //   return _.upperFirst(_.lowerCase(cms));
  // }

  async loadCms() {
    const id = this.loadCmsIdCtrl.value;

    const type = CMS_TYPE.ZENDESK; // TODO: Will be managed

    this.submitted = true;

    if (this.loadCmsIdCtrl.invalid) {
      return;
    }

    this.ngxLoader.startLoader(this.loadCmsLoaderId);

    try {
      const ticket = await this.cmsService.findTicketById(id, type);
      this.logService.info('[loadCms ticket]', ticket);

      const cms = this.cmsService.createCMS(type, ticket);

      await this.attachCmsToConversation(cms);

      setTimeout(() => {
        this.modalService.dismissAll();
        this.onAddCmsSystem.emit(cms);
      }, 500);
    } catch (e) {
      this.logService.info('!!!Error', e);
      this.loadCmsIdCtrl.setErrors({ message: `Not found ticket #${id}` });
    }

    this.ngxLoader.stopLoader(this.loadCmsLoaderId);
    // cmsClass.
  }

  async searchTickets() {
    const filter = this.loadCmsFilterCtrl.value;

    const type = CMS_TYPE.ZENDESK; // TODO: Will be managed

    this.submitted = true;

    if (this.loadCmsFilterCtrl.invalid) {
      return;
    }

    this.ngxLoader.startLoader(this.loadCmsLoaderId);

    try {
      const result = await this.cmsService.searchTickets(filter, type);
      this.logService.info('[searchTickets result]', result);

      this.loadCmsByFilterResult = result;
      this.loadCmsFilterCtrl.reset();
      this.submitted = false;
    } catch (e) {
      this.logService.info('[searchTickets Error]', e);
      this.loadCmsFilterCtrl.setErrors({ message: `Not found tickets with filter: "${filter}"` });
    }

    this.ngxLoader.stopLoader(this.loadCmsLoaderId);
  }

  async selectTicket(ticket) {
    const type = CMS_TYPE.ZENDESK; // TODO: Will be managed
    const cms = this.cmsService.createCMS(type, ticket);

    await this.attachCmsToConversation(cms);

    setTimeout(() => {
      this.modalService.dismissAll();
      this.onAddCmsSystem.emit(cms);
    }, 500);
  }

  async attachCmsToConversation(cms) {
    try {
      return await this.cmsService.attachTicketToConversation(this.conversation.id, cms.id, cms.type);
    } catch (e) {
      this.logService.info('[attachTicketToConversation Error]', e);
    }

    return undefined;
  }

  async sendCms() {
    this.submitted = true;

    if (this.cmsFormGroup.invalid) {
      return;
    }

    this.ngxLoader.startLoader(this.loaderId);

    await this.sendCmsRequest({
      // type: this.cmsType,
      ...this.cmsFormGroup.value,
    });
  }

  async sendCmsRequest(data) {
    this.ngxLoader.startLoader(this.loaderId);

    try {
      const type = CMS_TYPE.ZENDESK; // TODO;
      const payload = {
        ...data,
        authorId: this.auth.cmsProfiles[type].id,
        conversationId: this.conversation.id,
        type,
      };
      const ticket: any = await this.cmsService.sendToCms(payload);

      this.logService.info('[sendCmsRequest] Response:', ticket);

      const cms = this.cmsService.createCMS(ticket.type, ticket.data);

      this.logService.info('[sendCmsRequest CreateCMS]', cms);

      this.submitted = false;

      setTimeout(() => {
        this.modalService.dismissAll();
        this.onAddCmsSystem.emit(cms);
      }, 500);
    } catch (error) {
      this.logService.info('[sendCmsRequest] Error:', error);
    }
    this.ngxLoader.stopLoader(this.loaderId);
  }
}
