import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICMS } from '@cation/core/interfaces/cms';
import { LogService } from '@cation/core/services/log/log.service';
import Conversation from '@cation/core/types/conversation';
import UserNotification from '@cation/core/types/userNotification';
import { prepareConversation } from '@cation/core/util/common-util';

type UserNotificationData = {
  userNotifications: UserNotification[];
  nextToken: null | string;
};

@Directive()
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  @Output() messageEmitter: EventEmitter<any> = new EventEmitter();
  @Output() aiSearchEmitter: EventEmitter<any> = new EventEmitter();
  @Output() goToChatInputEmitter: EventEmitter<void> = new EventEmitter();
  @Output() similarCasesSearchEmitter: EventEmitter<any> = new EventEmitter();
  
  public videoChatStatus$ = new BehaviorSubject(false);

  private cannedResponses = new BehaviorSubject([]);
  currentCannedResponses = this.cannedResponses.asObservable();

  private conversations = new BehaviorSubject([]);
  currentConversations = this.conversations.asObservable();

  private userNotifications = new BehaviorSubject<UserNotificationData>({ userNotifications: [], nextToken: null });
  currentUserNotifications = this.userNotifications.asObservable();

  private newConversations = new BehaviorSubject([]);
  currentNewConversations = this.newConversations.asObservable();

  private conversation = new BehaviorSubject(null);
  currentConversation = this.conversation.asObservable();

  private messagesData = new BehaviorSubject({});
  currentMessagesData = this.messagesData.asObservable();

  private countUnreadMessages = new BehaviorSubject({});
  currentCountUnreadMessages = this.countUnreadMessages.asObservable();

  private conversationFilter = new BehaviorSubject('');
  currentConversationFilter = this.conversationFilter.asObservable();

  private notesData = new BehaviorSubject({});
  currentNotesData = this.notesData.asObservable();

  private countActiveConversations = new BehaviorSubject(0);
  currentCountActiveConversations = this.countActiveConversations.asObservable();

  private isOnlineStatus = new BehaviorSubject(false);
  currentOnlineStatus = this.isOnlineStatus.asObservable();

  cmsSystems: Map<string, ICMS> = new Map();
  @Output() onCmsChanged: EventEmitter<string> = new EventEmitter();

  constructor(private logService: LogService) {}

  setCannedResponses(data) {
    this.logService.debug('[SharedService setCannedResponses]', data);
    this.cannedResponses.next(data);
  }

  setOnlineStatus(isOnline: boolean) {
    this.logService.debug('[SharedService setOnlineStatus]', isOnline);
    this.isOnlineStatus.next(isOnline);
  }

  setCountActiveConversations(count: number) {
    this.logService.debug('[SharedService setCountActiveConversations]', count);
    this.countActiveConversations.next(count);
  }

  setConversations(conversations: Conversation[]) {
    this.logService.debug('[SharedService setConversations]', conversations);
    this.conversations.next(conversations);
  }

  setUserNotifications(data: UserNotificationData) {
    this.logService.debug('[SharedService setUserNotifications]', data);
    this.userNotifications.next(data);
  }

  setNewConversations(conversations: string[]) {
    this.logService.debug('[SharedService setNewConversations]', conversations);
    this.newConversations.next(conversations);
  }

  setCurrentConversation(conversation: Conversation) {
    this.logService.debug('[SharedService setCurrentConversation]', conversation);
    this.conversation.next(conversation ? prepareConversation(conversation) : conversation);
  }

  setMessagesData(messagesData) {
    this.logService.debug('[SharedService setMessagesData]', messagesData);
    // Remove command messages
    // const id = Object.keys(messagesData)[0]
    // if (messagesData[id].messages) {
    //   messagesData[id].messages = messagesData[id].messages.filter(msg => !msg.content.startsWith('COMMAND:'))
    // }
    this.messagesData.next(messagesData);
  }

  setCountUnreadMessages(countUnreadMessages) {
    this.logService.debug('[SharedService setCountUnreadMessages]', countUnreadMessages);
    this.countUnreadMessages.next(countUnreadMessages);
  }

  setConversationFilter(conversationFilter) {
    this.logService.debug('[SharedService setConversationFilter]', conversationFilter);
    this.conversationFilter.next(conversationFilter);
  }

  setNotesData(notesData) {
    this.logService.debug('[SharedService setNotesData]', notesData);
    this.notesData.next(notesData);
  }

  pasteUserMessage(text: string) {
    this.logService.debug('[SharedService pasteUserMessage]', text);
    this.messageEmitter.emit(text);
  }

  getPastedUserMessageEmitter() {
    this.logService.debug('[SharedService getPastedUserMessageEmitter]');
    return this.messageEmitter;
  }

  /** Ai Advice */

  searchAi(text: string) {
    this.logService.debug('[SharedService searchAi]', text);
    this.aiSearchEmitter.emit(text);
  }

  getAiSearchEmitter() {
    this.logService.debug('[SharedService getAiSearchEmitter]');
    return this.aiSearchEmitter;
  }

  /** Similar Cases */

  searchSimilarCases(message: string, createdAt?: object) {
    this.logService.debug('[SharedService searchSimilarCases]', message);
    this.similarCasesSearchEmitter.emit({ message, createdAt });
  }

  getSimilarCasesSearchEmitter() {
    this.logService.debug('[SharedService getSimilarCasesSearchEmitter]');
    return this.similarCasesSearchEmitter;
  }

  goToChatInput() {
    this.logService.debug('[SharedService goToChatInput]');
    this.goToChatInputEmitter.emit();
  }

  getGoToChatInputEmitter() {
    this.logService.debug('[SharedService getGoToChatInputEmitter]');
    return this.goToChatInputEmitter;
  }

  addCmsSystem(key, cms) {
    this.logService.debug('[SharedService addCmsSystem]', key, cms);

    if (!this.cmsSystems.has(key)) {
      this.cmsSystems.set(key, cms);
    }
  }

  deleteCms(key) {
    this.logService.debug('[SharedService deleteCms]', key);

    if (this.cmsSystems.has(key)) {
      this.cmsSystems.delete(key);
    }
  }
}
