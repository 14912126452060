import { Component, OnInit } from '@angular/core';
import { faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ctn-conversation-search',
  templateUrl: './conversation-search.component.html',
  styleUrls: ['./conversation-search.component.scss']
})
export class ConversationSearchComponent implements OnInit {
  public faUser = faUser
  constructor() {}

  ngOnInit() {}
}
