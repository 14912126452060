import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aOrAn'
})
export class AOrAnPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return !value ? '' : (/^[aeiou]$/i).test(value.charAt(0)) ? 'an ' + value : 'a ' + value;
  }
}
