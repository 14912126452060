import * as update from 'immutability-helper';
import {
  getConversationMessagesQuery as MessagesQuery,
  getConversationNotesQuery as NotesQuery,
  getTeamsQuery as TeamsQuery,
  getUserConversationConnectionThroughUserQuery as ConvosQuery,
  getUserConversationConnectionByCognitoIdThroughUserQuery as ConvosByCognitoIdQuery,
  getAllUsersQuery as UsersQuery,
  getConversationsByCustomerIdQuery as ConvosByCustomerIdQuery,
  getUsersByTeamIdQuery as UsersByTeamIdQuery,
} from '@cation/core/graphql/operation-result-types';
import Message from '@cation/core/types/message';
import Conversation from '@cation/core/types/conversation';
import UserConversation from '@cation/core/types/userConversation';
import User from '@cation/core/types/user';
import Note from '@cation/core/types/note';
import Team from '@cation/core/types/team';

import * as _ from 'lodash';

export * from './userNotification';
export * from './conversation-queue';
export * from './conversation-queue-history';

export const constants = {
  customerHistoryFirst: 20,
  conversationFirst: 20,
  notificationFirst: 20,
  messageFirst: 20,
  notesFirst: 20,
  teamsFirst: 10,
};

/** MESSAGES */

export function unshiftMessage(data: MessagesQuery, message: Message): MessagesQuery {
  if (!data || !_.has(data, 'allMessageConnection.messages')) {
    return {
      allMessageConnection: {
        nextToken: null,
        __typename: 'MessageConnection',
        messages: [],
      },
    };
  }

  if (data.allMessageConnection.messages.some((m) => m.id === message.id)) {
    return data;
  }

  return update(data, {
    allMessageConnection: {
      messages: { $unshift: [message] },
    },
  });
}

export function pushMessages(data: MessagesQuery, messages: Message[], nextToken: string): MessagesQuery {
  if (!data || !_.has(data, 'allMessageConnection.messages')) {
    return {
      allMessageConnection: {
        nextToken: null,
        __typename: 'MessageConnection',
        messages: [],
      },
    };
  }

  return update(data, {
    allMessageConnection: {
      messages: { $push: messages },
      nextToken: { $set: nextToken },
    },
  });
}

/** NOTES */

export function pushNotes(data: NotesQuery, notes: Note[], nextToken: string): NotesQuery {
  if (!data || !_.has(data, 'getConversationNotes.notes')) {
    return {
      getConversationNotes: {
        nextToken: null,
        __typename: 'ListConversationNotes',
        notes: [],
      },
    };
  }

  const newNotes = _.differenceBy(notes, data.getConversationNotes.notes, 'id');

  return update(data, {
    getConversationNotes: {
      notes: { $push: newNotes },
      nextToken: { $set: nextToken },
    },
  });
}

export function unshiftNote(data: NotesQuery, note: Note): NotesQuery {
  if (!data || !_.has(data, 'getConversationNotes.notes')) {
    return {
      getConversationNotes: {
        nextToken: null,
        __typename: 'ListConversationNotes',
        notes: [],
      },
    };
  }

  const itemIndex = data.getConversationNotes.notes.findIndex((n) => n.id === note.id);

  if (itemIndex !== -1) {
    return data;
  }

  return update(data, { getConversationNotes: { notes: { $unshift: [note] } } });
}

export function deleteNote(data: NotesQuery, note: Note): NotesQuery {
  if (!data || !_.has(data, 'getConversationNotes.notes')) {
    return {
      getConversationNotes: {
        nextToken: null,
        __typename: 'ListConversationNotes',
        notes: [],
      },
    };
  }

  const itemIndex = data.getConversationNotes.notes.findIndex((n) => n.id === note.id);

  if (itemIndex === -1) {
    return data;
  }

  return update(data, {
    getConversationNotes: { notes: { $splice: [[itemIndex, 1]] } },
  });
}

export function updateNote(data: NotesQuery, note: Note): NotesQuery {
  if (!data || !_.has(data, 'getConversationNotes.notes')) {
    return {
      getConversationNotes: {
        nextToken: null,
        __typename: 'ListConversationNotes',
        notes: [],
      },
    };
  }
  const itemIndex = data.getConversationNotes.notes.findIndex((n) => n.id === note.id);

  if (itemIndex === -1) {
    return undefined;
  }

  return update(data, {
    getConversationNotes: { notes: { [itemIndex]: { $set: note } } },
  });
}

/** TEAMS */

function updateTeamsMembers(data, _team) {
  return {
    ...data,
    getTeams: {
      ...data.getTeams,
      teams: data.getTeams.teams.map((team) => ({
        ...team,
        members: team.members.filter(
          (member) => !_team.members.find((_member) => _member.cognitoId === member.cognitoId)
        ),
      })),
    },
  };
}

export function pushTeams(data: TeamsQuery, teams: Team[], nextToken: string): TeamsQuery {
  if (!data || !_.has(data, 'getTeams.teams')) {
    return {
      getTeams: {
        nextToken: null,
        __typename: 'ListTeams',
        teams: [],
      },
    };
  }

  const newTeams = _.differenceBy(teams, data.getTeams.teams, 'id');

  return update(data, {
    getTeams: {
      teams: { $push: newTeams },
      nextToken: { $set: nextToken },
    },
  });
}

export function unshiftTeam(data: TeamsQuery, team: Team): TeamsQuery {
  if (!data || !_.has(data, 'getTeams.teams')) {
    return {
      getTeams: {
        nextToken: null,
        __typename: 'ListTeams',
        teams: [],
      },
    };
  }

  if (data.getTeams.teams.some((t) => t.id === team.id)) {
    return data;
  }

  return update(updateTeamsMembers(data, team), {
    getTeams: {
      teams: { $unshift: [team] },
    },
  });
}

export function updateTeam(data: TeamsQuery, team: Team): TeamsQuery {
  if (!data || !_.has(data, 'getTeams.teams')) {
    return {
      getTeams: {
        nextToken: null,
        __typename: 'ListTeams',
        teams: [],
      },
    };
  }
  const itemIndex = data.getTeams.teams.findIndex((n) => n.id === team.id);

  if (itemIndex === -1) {
    return undefined;
  }

  return update(updateTeamsMembers(data, team), {
    getTeams: { teams: { [itemIndex]: { $set: team } } },
  });
}

export function deleteTeam(data: TeamsQuery, team: Team): TeamsQuery {
  if (!data || !_.has(data, 'getTeams.teams')) {
    return {
      getTeams: {
        nextToken: null,
        __typename: 'ListTeams',
        teams: [],
      },
    };
  }

  const itemIndex = data.getTeams.teams.findIndex((n) => n.id === team.id);

  if (itemIndex === -1) {
    return data;
  }

  return update(data, {
    getTeams: { teams: { $splice: [[itemIndex, 1]] } },
  });
}

/** CONVERSATIONS */

export function deleteConversation(data: ConvosQuery, conversationId: string): ConvosQuery {
  if (!data || !_.has(data, 'me.conversations.userConversations')) {
    return {
      me: {
        conversations: {
          nextToken: null,
          __typename: 'UserConversationsConnection',
          userConversations: [],
        },
      },
    };
  }

  const itemIndex = data.me.conversations.userConversations.findIndex((_uc) => conversationId === _uc.conversationId);

  if (itemIndex === -1) {
    return data;
  }

  return update(data, {
    me: { conversations: { userConversations: { $splice: [[itemIndex, 1]] } } },
  });
}

export function addCmsIntoConversation(data: ConvosQuery, cms): ConvosQuery {
  if (!data || !_.get(data, `me.conversations.userConversations`)) {
    return {
      me: {
        conversations: {
          nextToken: null,
          __typename: 'UserConversationsConnection',
          userConversations: [],
        },
      },
    };
  }

  const conversationIndex = data.me.conversations.userConversations.findIndex(
    (_uc) => cms.conversationId === _uc.conversationId
  );

  if (conversationIndex === -1) {
    return data;
  }

  const cmsIndex = data.me.conversations.userConversations[conversationIndex].conversation.cms.findIndex(
    (c) => c.id === cms.id && c.type === cms.type
  );

  if (cmsIndex !== -1) {
    return data;
  }

  return update(data, {
    me: {
      conversations: {
        userConversations: {
          [conversationIndex]: {
            conversation: {
              cms: { $push: [cms] },
            },
          },
        },
      },
    },
  });
}

export function deleteCmsFromConversation(data: ConvosQuery, cms): ConvosQuery {
  if (!data || !_.get(data, `me.conversations.userConversations`)) {
    return {
      me: {
        conversations: {
          nextToken: null,
          __typename: 'UserConversationsConnection',
          userConversations: [],
        },
      },
    };
  }

  const conversationIndex = data.me.conversations.userConversations.findIndex(
    (_uc) => _uc.conversationId === cms.conversationId
  );

  if (conversationIndex === -1) {
    return data;
  }

  const cmsIndex = data.me.conversations.userConversations[conversationIndex].conversation.cms.findIndex(
    (c) => c.id === cms.id && c.type === cms.type
  );

  if (cmsIndex === -1) {
    return data;
  }

  return update(data, {
    me: {
      conversations: {
        userConversations: {
          [conversationIndex]: {
            conversation: {
              cms: { $splice: [[cmsIndex, 1]] },
            },
          },
        },
      },
    },
  });
}

export function addConversation(data: ConvosQuery, uc: UserConversation): ConvosQuery {
  console.log('addConversation1', data, uc);
  if (!data || !_.has(data, 'me.conversations.userConversations')) {
    return {
      me: {
        conversations: {
          nextToken: null,
          __typename: 'UserConversationsConnection',
          userConversations: [],
        },
      },
    };
  }

  console.log('addConversation2', data, uc);

  if (data.me.conversations.userConversations.some((_uc) => uc.conversationId === _uc.conversationId)) {
    return data;
  }

  console.log('addConversation3', data, uc);

  // temp fix - We need to find why createdAt, role is missing. For now just adding it by registered flag
  uc.conversation.createdAt = uc.conversation.createdAt || String(Date.now());
  uc.associated.forEach(
    (associated) =>
      (associated.role = associated.role ? associated.role : associated.user.registered === 'true' ? 'AGENT' : 'AUTHOR')
  );

  const mergedData = update(data, {
    me: { conversations: { userConversations: { $push: [uc] } } },
  });
  console.log('mergedData', mergedData);

  return mergedData;
}

export function addConversationByCognitoId(data: ConvosByCognitoIdQuery, uc: UserConversation): ConvosByCognitoIdQuery {
  if (!data || !_.has(data, 'getUserByCognitoId.conversations.userConversations')) {
    return {
      getUserByCognitoId: {
        conversations: {
          nextToken: null,
          __typename: 'UserConversationsConnection',
          userConversations: [],
        },
      },
    };
  }

  if (data.getUserByCognitoId.conversations.userConversations.some((_uc) => uc.conversationId === _uc.conversationId)) {
    return data;
  }

  return update(data, {
    getUserByCognitoId: { conversations: { userConversations: { $push: [uc] } } },
  });
}

export function addUserByTeamId(data: UsersByTeamIdQuery, u: User): UsersByTeamIdQuery {
  if (!data || !_.has(data, 'getUsersByTeamId.users')) {
    return {
      getUsersByTeamId: {
        __typename: 'ListUsersByTeamId',
        nextToken: null,
        users: [],
      },
    };
  }

  if (data.getUsersByTeamId.users.some((_u) => u.cognitoId === _u.cognitoId)) {
    return data;
  }

  return update(data, {
    getUsersByTeamId: { users: { $push: [u] } },
  });
}

export function addConversations(data: ConvosQuery, ucs: UserConversation[], nextToken: string): ConvosQuery {
  if (!data || !_.has(data, 'me.conversations.userConversations')) {
    return {
      me: {
        conversations: {
          nextToken: null,
          __typename: 'UserConversationsConnection',
          userConversations: [],
        },
      },
    };
  }

  const newRecords = _.differenceBy(ucs, data.me.conversations.userConversations, 'conversationId');

  return update(data, {
    me: {
      conversations: {
        userConversations: { $push: newRecords },
        nextToken: { $set: nextToken },
      },
    },
  });
}

export function updateUserConversations(data: ConvosQuery, uc: UserConversation): ConvosQuery {
  if (!data || !_.has(data, 'me.conversations.userConversations')) {
    return {
      me: {
        conversations: {
          nextToken: null,
          __typename: 'UserConversationsConnection',
          userConversations: [],
        },
      },
    };
  }

  const itemIndex = data.me.conversations.userConversations.findIndex(
    (_uc) => uc.conversationId === _uc.conversationId
  );

  if (itemIndex === -1) {
    return data;
  }

  return update(data, {
    me: { conversations: { userConversations: { $splice: [[itemIndex, 1, uc]] } } },
  });
}

export function addConversationsByCognitoId(
  data: ConvosByCognitoIdQuery,
  ucs: UserConversation[],
  nextToken: string
): ConvosByCognitoIdQuery {
  if (!data || !_.has(data, 'getUserByCognitoId.conversations.userConversations')) {
    return {
      getUserByCognitoId: {
        conversations: {
          nextToken: null,
          __typename: 'UserConversationsConnection',
          userConversations: [],
        },
      },
    };
  }

  const newRecords = _.differenceBy(ucs, data.getUserByCognitoId.conversations.userConversations, 'conversationId');

  return update(data, {
    getUserByCognitoId: {
      conversations: {
        userConversations: { $push: newRecords },
        nextToken: { $set: nextToken },
      },
    },
  });
}

export function addCustomerHistory(
  data: ConvosByCustomerIdQuery,
  ucs: Conversation[],
  nextToken: string
): ConvosByCustomerIdQuery {
  if (!data || !_.has(data, 'getConversationsByCustomerId.conversations')) {
    return {
      getConversationsByCustomerId: {
        __typename: 'ListConversations',
        conversations: [],
        nextToken: null,
      },
    };
  }

  const newRecords = _.differenceBy(ucs, data.getConversationsByCustomerId.conversations, 'id');

  return update(data, {
    getConversationsByCustomerId: {
      conversations: { $push: newRecords },
      nextToken: { $set: nextToken },
    },
  });
}

export function addUser(data: UsersQuery, user: User): UsersQuery {
  if (!data || !data.allUser) {
    return { allUser: [] };
  }

  if (data.allUser.some((_user) => _user.cognitoId === user.cognitoId)) {
    return data;
  }

  return update(data, { allUser: { $push: [user] } });
}

export const delay = (timeout) => new Promise((resolve) => setTimeout(resolve, timeout));

export const escapeStr = (message) =>
  message
    .replace(/[\\"]/g, '\\$&')
    .replace(/\u0000/g, '\\0')
    .replace(/\//gm, '\\/')
    .replace(/\n/g, '\\n')
    .replace(/\t/g, '\\t');

export const handleErrors = (response) => {
  if (!response.ok) {
    throw Error(`Error: ${response.status} ${response.statusText}`);
  }
  return response.json();
};
