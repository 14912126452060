import { Injectable } from '@angular/core';
import { API, graphqlOperation } from 'aws-amplify';
import { parseJSON } from '@cation/core/util/common-util';
import { LogService } from '@cation/core/services/log/log.service';
import * as mutations from '@cation/core/graphql/mutations';
import * as queries from '@cation/core/graphql/queries';
import { ApiHook } from './api-hook';

@Injectable({
  providedIn: 'root'
})
export class AppSyncApiService {
  constructor(private apiHook: ApiHook, private logService: LogService) {
    this.execute = this.execute.bind(this);
    this.executeSilent = this.executeSilent.bind(this);
  }

  private async execute(query, variables = undefined) {
    this.logService.debug('[AppSyncApiService execute]', { query, variables });

    this.apiHook.loading();

    try {
      const res = (await API.graphql(graphqlOperation(query, variables))) as any;
      this.apiHook.complete();
      return res;
    } catch (err) {
      this.apiHook.complete();
      throw err;
    }
  }

  private async executeSilent(query, variables = undefined) {
    this.logService.debug('[AppSyncApiService executeSilent]', { query, variables });

    try {
      return (await API.graphql(graphqlOperation(query, variables))) as any;
    } catch (err) {
      throw err;
    }
  }

  async getAgent(input) {
    const {
      data: { getUserByCognitoId: agent }
    } = await this.execute(queries.getAgents, input);
    if (agent) {
      agent.cmsUserData = (agent.cmsUserData || []).map(parseJSON);
    }
    return agent;
  }

  async updateAgent(input) {
    return this.execute(mutations.updateAgent, input);
  }

  async sendMessage(message) {
    return this.execute(mutations.createMessage, message);
  }

  async updateUserConversation(input) {
    return this.execute(mutations.updateUserConversations, { input });
  }

  async updateActiveTime(variables = {}) {
    return this.executeSilent(mutations.updateActiveTime, variables);
  }

  async searchTeams(name, isSilent = true) {
    const executeFn = isSilent ? this.executeSilent : this.execute;
    const {
      data: { searchTeams }
    } = await executeFn(queries.searchTeams, { name });

    return searchTeams.map(team => ({
      ...team,
      teamLead: {
        ...team.teamLead,
        userData: parseJSON(team.teamLead.userData) || {}
      }
    }));
  }

  async searchUsers(name, isSilent = true) {
    const executeFn = isSilent ? this.executeSilent : this.execute;
    const {
      data: { searchUsers }
    } = await executeFn(queries.searchUsers, { name });

    return searchUsers.map(user => ({ ...user, userData: parseJSON(user.userData) || {} }));
  }
}
