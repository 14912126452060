import { Injectable } from '@angular/core';
import { EMPTY, from } from 'rxjs';
import {
  ObservableQuery,
  FetchMoreOptions,
  WatchQueryOptions,
  FetchMoreQueryOptions,
  SubscribeToMoreOptions,
  MutationOptions,
  ApolloQueryResult
} from 'apollo-client';
// import { FetchResult } from 'apollo-link';
import { AppSyncService } from '../app-sync.service';
import { SharedService } from '../shared.service';
import { ILoadAndSubscribeMoreOptions, ILoadOptions } from './types';
import { LoggerService } from '../logger.service';
import User from '../../types/user';

@Injectable()
export class AppsyncOperationService {
  me: User;

  constructor(
    protected appsync: AppSyncService,
    protected shared: SharedService,
    protected loggerService: LoggerService
  ) {
    this.shared.currentUser.subscribe(value => (this.me = value));
  }

  protected subscription({ query, variables = undefined, realtimeResults, onComplete, onError }) {
    return this.appsync.hc().then(client => {
      const observable = client.subscribe({ query, variables });

      return observable.subscribe({
        next: realtimeResults,
        complete: data => {
          return onComplete(data);
        },
        error: error => {
          return onError(error);
        }
      });
    });
  }

  protected subscribeToMore<T>(observable, options: SubscribeToMoreOptions) {
    const { document, variables, updateQuery, onError } = options;

    return observable.subscribeToMore({
      document,
      variables,
      updateQuery: (prev: T, opts) => {
        return updateQuery(prev, opts);
      },
      onError: (error: Error) => {
        return onError(error);
      }
    });
  }

  protected load<T>({ watchQueryOptions, watchQuerySubscriptionOptions }: ILoadOptions) {
    return this.appsync.hc().then(client => {
      const observedQuery: ObservableQuery<T> = client.watchQuery(watchQueryOptions);

      const { observerOrNext, error, complete } = watchQuerySubscriptionOptions;

      const observableSubscription = observedQuery.subscribe(
        observerOrNext,
        e => {
          return error && error(e);
        },
        () => {
          return complete && complete();
        }
      );

      return { observedQuery, observableSubscription };
    });
  }

  protected loadMore(observedQuery, nextToken, fetchMoreOptions) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!nextToken) {
      return EMPTY;
    }

    const { updateQuery } = fetchMoreOptions;

    const result = observedQuery.fetchMore({
      variables: { after: nextToken },
      updateQuery: (prev, opts) => {
        return updateQuery(prev, opts);
      }
    });
    return from(result);
  }

  protected async loadAndSubscribeMore<T>({
    watchQueryOptions,
    watchQuerySubscriptionOptions,
    subscribeToMoreOptions
  }: ILoadAndSubscribeMoreOptions) {
    const { observedQuery, observableSubscription } = await this.load<T>({
      watchQueryOptions,
      watchQuerySubscriptionOptions
    });

    const subscription = this.subscribeToMore(observedQuery, subscribeToMoreOptions);

    return {
      observedQuery,
      observableSubscription,
      subscription
    };
  }

  protected query<T>(options): ApolloQueryResult<T> {
    return this.appsync.hc().then(client => client.query(options));
  }

  protected watchQuery(options: WatchQueryOptions, observerOrNext) {
    this.appsync.hc().then(client =>
      client.watchQuery(options).subscribe(res => {
        return observerOrNext(res);
      })
    );
  }

  protected mutate<T>(options: MutationOptions<T>): Promise<any> {
    // Promise<FetchResult<T>>
    return this.appsync.hc().then(client => client.mutate(options));
  }

  public loadMoreByObservedQuery(observedQuery, nextToken: string, fetchMoreOptions) {
    return this.loadMore(observedQuery, nextToken, fetchMoreOptions);
  }
}
