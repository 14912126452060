import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ObservableQuery } from 'apollo-client';
import { TranslateService } from '@ngx-translate/core';
import { listFadeInOut, fadeInOut } from '@cation/core/animations';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { LogService } from '@cation/core/services/log/log.service';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { AppsyncUserService } from '@cation/core/services/appsync/appsync-user.service';
import { AppsyncTeamService } from '@cation/core/services/appsync/appsync-team.service';
import {
  getTeamsQuery as TeamsQuery,
  getUsersByTeamIdQuery as UsersQuery,
} from '@cation/core/graphql/operation-result-types';
import { addUserByTeamId, pushTeams } from '@cation/core/util/chat-helper';
import Conversation from '@cation/core/types/conversation';
import User from '@cation/core/types/user';
import Team from '@cation/core/types/team';
import { parseJSON } from '@cation/core/util/common-util';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInOut, listFadeInOut],
})
export class ChatComponent implements OnInit {
  username: string;
  me: User;
  conversation: Conversation;
  messagesData = {};
  notesData = {};
  update: boolean;
  isLoading = false;

  currentConversation: string;
  selectedConversationFilter: string;
  conversationFilters = ['Open', 'Reopen', 'Closed'];

  conversations: { [cognitoId: string]: Conversation[] } = {};

  teams: Team[] = [];
  teamsNextToken: string;
  teamsObservedQuery: ObservableQuery<TeamsQuery>;
  teamsData = {};

  constructor(
    private appsyncUserService: AppsyncUserService,
    private appsyncTeamService: AppsyncTeamService,
    private sharedService: SharedService,
    private auth: AuthHelper,
    private logService: LogService,
    public translate: TranslateService
  ) {
    this.me = auth.userProfile;
    this.getAllUsers = this.getAllUsers.bind(this);
  }

  ngOnInit() {
    this.auth.currentUserProfile$.subscribe((me) => (this.me = me));
    this.sharedService.currentMessagesData.subscribe((messagesData) => (this.messagesData = messagesData));
    this.sharedService.currentNotesData.subscribe((notesData) => (this.notesData = notesData));
    this.loadTeams();
  }

  private async getAllUsers(team) {
    const teamId = team.id;
    if (this.teamsData[teamId]) {
      return;
    }
    const observerOrNext = ({ data }) => {
      if (!data || !data.getUsersByTeamId) {
        return this.logService.log('[ChatComponent getAllUsers] no data');
      }

      const users = data.getUsersByTeamId.users.map((u) => ({ ...u, userData: parseJSON(u.userData) }));

      this.teamsData = {
        ...this.teamsData,
        [teamId]: {
          ...this.teamsData[teamId],
          users,
          nextToken: data.getUsersByTeamId.nextToken,
        },
      };

      this.logService.log('[ChatComponent getAllUsers:users]', users);
    };

    const updateQuery = (
      prev: UsersQuery,
      {
        subscriptionData: {
          data: { subscribeToUpdateAgentByTeamId: user },
        },
      }
    ) => addUserByTeamId(prev, user);

    const { observedQuery } = await this.appsyncUserService.getUsersByTeamId(teamId, observerOrNext, updateQuery);

    this.teamsData = {
      ...this.teamsData,
      [teamId]: {
        ...this.teamsData[teamId],
        observedQuery,
      },
    };
  }

  private async loadTeams() {
    this.isLoading = true;
    const observerOrNext = async ({ data }) => {
      this.logService.log('[loadTeams subscribe]', data);
      if (!data) {
        return this.logService.log('[loadTeams - no data]');
      }

      this.teams = data.getTeams.teams;
      this.teamsNextToken = data.getTeams.nextToken;

      this.teams.map(this.getAllUsers);

      this.isLoading = false;

      this.logService.log('[loadTeams]: nextToken is now', data.getTeams.nextToken ? 'set' : 'null');
    };

    const { observedQuery } = await this.appsyncTeamService.loadTeams(observerOrNext, this.me.cognitoId);
    this.teamsObservedQuery = observedQuery;
  }

  public loadMoreTeams(event = null) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    const updateQuery = (prev, data) => {
      const { fetchMoreResult } = data;

      if (!fetchMoreResult) {
        return prev;
      }

      const _res = pushTeams(prev as TeamsQuery, fetchMoreResult.getTeams.teams, fetchMoreResult.getTeams.nextToken);
      this.teamsNextToken = fetchMoreResult.getTeams.nextToken;

      return _res;
    };

    return this.appsyncTeamService.loadMoreByObservedQuery(this.teamsObservedQuery, this.teamsNextToken, {
      updateQuery,
    });
  }

  public setNewConvo(convo) {
    this.conversation = convo;
    this.currentConversation = JSON.stringify(convo, null, 4);
  }

  public loadConversations({ cognitoId, conversations }) {
    this.conversations[cognitoId] = conversations;
  }

  public onCloseConversation() {
    this.conversation = undefined;
    this.currentConversation = undefined;
  }

  onSelectConversationFilter() {
    this.sharedService.setConversationFilter(this.selectedConversationFilter);
  }
}
