<div class="league-statistics-view">
  <div class="statistics-container d-flex row px-3 pb-2">
    <mat-card class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mat-card mat-card">
      <mat-card-header>
        <mat-card-title class="d-flex align-items-center">
          {{ 'PROFILE.MY_LEAGUE_TITLE' | translate }}
          <a class="nav-link d-flex align-items-center p-0 ms-2" routerLink="/admin/gamification/master-league">
            <mat-icon>emoji_events</mat-icon>
            {{ 'PROFILE.MASTER_LEAGUE_LINK' | translate }}
          </a>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ng-container
          *ngTemplateOutlet="
            statisticsGroupTemplate;
            context: { selectedTab: selectedLeagueStaticsTab, statistics: leagueStatistics, type: 'USER' }
          "
        >
        </ng-container>
      </mat-card-content>
    </mat-card>

    <mat-card class="col-xl-6 col-lg-6 col-md-12 col-sm-12 mat-card mat-card">
      <mat-card-header>
        <mat-card-title class="d-flex align-items-center">
          {{ 'PROFILE.TEAM_LEAGUE_TITLE' | translate }}
          <a class="nav-link d-flex align-items-center p-0 ms-2" routerLink="/admin/gamification/master-league">
            <mat-icon>emoji_events</mat-icon>
            {{ 'PROFILE.MASTER_LEAGUE_LINK' | translate }}
          </a>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <ng-container
          *ngTemplateOutlet="
            statisticsGroupTemplate;
            context: { selectedTab: selectedTeamStaticsTab, statistics: teamStatistics, type: 'TEAM' }
          "
        >
        </ng-container>
      </mat-card-content>
    </mat-card>
  </div>

  <ng-template
    #statisticsItemTemplate
    let-secondImage="secondImage"
    let-image="image"
    let-name="name"
    let-team="team"
    let-points="points"
    let-nickname="nickname"
    let-prevPoints="prevPoints"
    let-type="type"
    let-index="index"
  >
    <div class="d-flex justify-content-center align-items-center">
      <span class="text_gray pe-2">#{{ index + 1 }}</span>
      <div class="tp-wrapper" *ngIf="secondImage">
        <div class="tp-box">
          <div class="front">
            <app-s3-image [isRounded]="type === 'USER'" [image]="image" [avatarName]="name"></app-s3-image>
          </div>
          <div class="back">
            <app-s3-image [isRounded]="type === 'USER'" [image]="secondImage" [avatarName]="name"></app-s3-image>
          </div>
        </div>
      </div>
      <div class="statistics__item__member__icon" *ngIf="!secondImage">
        <app-s3-image [isRounded]="type === 'USER'" [image]="image" [avatarName]="name"></app-s3-image>
      </div>
      <div *ngIf="type === 'USER' && team?.icon" class="ms-2 statistics__item__member__icon" [matTooltip]="team.name">
        <app-s3-image [image]="team.icon"></app-s3-image>
      </div>
      <div class="d-flex flex-column ps-2">
        <span class="statistics__item__member__name">{{ name }}</span>
        <span class="statistics__item__member__status" *ngIf="nickname">{{ nickname }}</span>
      </div>
    </div>
    <span class="statistics__item__points">
      {{ points }}
      <mat-icon
        class="statistics__item__arrow statistics__item__arrow_up"
        [class.statistics__item__arrow_down]="prevPoints > points"
        [matTooltip]="'' + (points - prevPoints)"
      >
        {{ prevPoints > points ? 'arrow_downward' : 'arrow_upward' }}
      </mat-icon>
    </span>
  </ng-template>

  <ng-template #statisticsGroupTemplate let-selectedTab="selectedTab" let-statistics="statistics" let-type="type">
    <mat-tab-group
      [selectedIndex]="selectedTab.value"
      (selectedIndexChange)="selectedTab.setValue($event)"
      class="statistics-tab-group"
      #tabsRef
    >
      <mat-tab [label]="'PROFILE.STATISTICS.' + tab + '_TAB_TITLE' | translate" *ngFor="let tab of tabs">
        <div *ngIf="statistics[tab]?.isLoading" class="statistics-loader" [@fadeInOut]>
          <mat-spinner mode="indeterminate"></mat-spinner>
        </div>

        <div
          *ngIf="!statistics[tab]?.items?.length && !statistics[tab]?.isLoading"
          class="d-flex justify-content-center align-items-center"
        >
          {{ 'PROFILE.STATISTICS.EMPTY_MESSAGE' | translate }}
        </div>

        <div [@listFadeInOut]="statistics[tab]?.items.length">
          <div
            *ngFor="let item of statistics[tab]?.items; let i = index; trackBy: trackStatisticsItem"
            class="row my-2 d-flex justify-content-between align-items-center statistics__item"
            [class.statistics__item_active]="isMine(item)"
          >
            <ng-container *ngTemplateOutlet="statisticsItemTemplate; context: getContext(item, tab, i)"></ng-container>
          </div>
        </div>
        <div *ngIf="statistics[tab]?.lastKey" class="d-flex flex-row justify-content-center align-items-center">
          <button mat-raised-button color="primary" (click)="getGamificationStatistics(type, tab, true)">
            {{ 'BUTTON.LOAD_MORE' | translate }}
          </button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</div>
