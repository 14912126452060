import gql from 'graphql-tag';

export default gql`
  query getMe {
    me {
      __typename
      cognitoId
      username
      registered
      userData
      cmsUserData
      keywords
      locales
      roles
      avatarUrl
      teamId
      voiceUsername
      team {
        id
        name
        icon
        description
        teamLeadId
      }
    }
  }
`;
