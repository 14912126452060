import gql from 'graphql-tag';

export default gql`
  query getConversationById($id: ID!) {
    getConversationById(id: $id) {
      __typename
      id
      name
      caseId
      subject
      botSessionId
      keywords
      locale
      location
      sentiment
      createdBy
      history
      channel
      createdAt
      intentsTriggered
      lastQuestion
      status
      cms {
        conversationId
        isCore
        type
        id
        createdAt
      }
      transfers {
        conversationId
        createdAt
        userId
        nextUserId
        description
        user {
          cognitoId
          username
        }
        nextUser {
          cognitoId
          username
        }
      }
      author {
        cognitoId
        username
        userData
      }
    }
  }
`;
