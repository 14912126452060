import { Injectable } from '@angular/core';
import { FetchPolicy } from 'apollo-client';
import * as _ from 'lodash';
import { AppsyncOperationService } from '@cation/core/services/appsync/appsync-operation.service';
import * as subscriptions from '@cation/core/graphql/subscriptions';
import { constants } from '@cation/core/util/chat-helper';
import * as queries from '@cation/core/graphql/queries';
import { getUsersByTeamIdQuery as UsersByTeamLeadIdQuery } from '@cation/core/graphql/operation-result-types';

@Injectable({
  providedIn: 'root'
})
export class AppsyncUserService extends AppsyncOperationService {
  getUsersByTeamId(teamId, observerOrNext, updateQuery) {
    const watchQueryOptions = {
      query: queries.getUsersByTeamId,
      variables: { first: constants.conversationFirst, teamId },
      fetchPolicy: 'cache-and-network' as FetchPolicy
    };

    const watchQuerySubscriptionOptions = { observerOrNext };

    const subscribeToMoreOptions = _.merge(
      { onError: () => this.forceReloadApp() },
      {
        document: subscriptions.subscribeToUpdateAgentByTeamId,
        variables: { teamId },
        updateQuery
      }
    );

    return this.loadAndSubscribeMore<UsersByTeamLeadIdQuery>({
      watchQueryOptions,
      watchQuerySubscriptionOptions,
      subscribeToMoreOptions
    });
  }

  registerUser(observerOrNext: (res) => any) {
    return this.watchQuery(
      {
        query: queries.getMe,
        fetchPolicy: 'no-cache' as FetchPolicy
      },
      observerOrNext
    );
  }

  checkUser() {
    return this.query({
      query: queries.getMe,
      fetchPolicy: 'no-cache' as FetchPolicy
    });
  }
}
