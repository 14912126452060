<mat-card class="ctn-card">
  <mat-card-content>
    <h4 class="ctn-h3 ctn-margin">
      <fa-icon [icon]="faUser"></fa-icon>
      {{ 'CONVERSATION_SEARCH.TITLE' | translate }}
    </h4>

    <hr />

    <app-similar-cases-view [withFilters]="true" [isFullScreen]="true"></app-similar-cases-view>
  </mat-card-content>
</mat-card>
