<!-- 
<h1 class="ctn__title">
  {{ 'LOGIN.' + (urlParserService.isEngageHub ? 'TITLE_EHUB' : 'TITLE') | translate }}
</h1>
<p class="ctn__description">
  {{ 'LOGIN.DESCRIPTION' | translate }}
</p>
-->

<amplify-authenticator>
  <amplify-sign-in header-text="Cation Customer Support" slot="sign-in" hide-sign-up="true"></amplify-sign-in>
</amplify-authenticator>

<!-- 
<amplify-authenticator [hideSignUp]="true">
  <ng-template amplifySlot="header">
    <h3 class="text-center">Cation Customer Support</h3>
  </ng-template>
</amplify-authenticator>
-->
