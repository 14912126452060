import gql from 'graphql-tag';

export default gql`
  mutation createAttachment($input: CreateAttachmentInput!) {
    createAttachment(input: $input) {
      id
      chatId
      key
      name
      size
      type
    }
  }
`;
