import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogService } from '@cation/core/services/log/log.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private logService: LogService) {
    this.logService.info('-------ErrorInterceptor-------');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req);

    // commenting this as capturing all error in CustomErrorHandler

    /*
    return next.handle(req).pipe(
        // Log when response observable either completes or errors
        catchError((error, caught) => {
            console.log('ErrorInterceptor::intercept::catchError');

            this.notificationService.notify(error, !navigator.onLine
                ? 'No Internet Connection'
                : `${error.status} - ${error.message}`
            );

            error.isHandled = true;

            return Observable.throw(error);
            // return Observable.empty();
        })
    );
    */

    /*
    return next.handle(req).catch(error => {
        console.log('catch error');

        this.notificationService.notify(error, !navigator.onLine
            ? 'No Internet Connection'
            : `${error.status} - ${error.message}`
        );

        return Observable.throw(error);
    });
    */
  }
}
