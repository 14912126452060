<div
  class="d-flex flex-wrap search-conversations align-items-start"
  [class.search-conversations_full-screen]="isFullScreen"
>
  <div *ngIf="withFilters" class="d-flex col-sm-12 col-md-3 align-items-center ps-0">
    <div class="ctn-form d-flex flex-column col-md-10 p-0">
      <mat-form-field class="ctn-full-width mt-2">
        <input
          type="text"
          matInput
          cdkFocusInitial
          [placeholder]="'CONVERSATION_SEARCH.MESSAGE_INPUT_PLACEHOLDER' | translate"
          [(ngModel)]="searchFilter.text"
          (keydown.enter)="searchConversations()"
          name="search-filter-text"
          required="true"
        />
        <button
          mat-button
          *ngIf="searchFilter.text"
          matSuffix
          mat-icon-button
          [attr.aria-label]="'BUTTON.CLEAR' | translate"
          (click)="searchFilter.text = ''"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="mt-2 ctn-full-width">
        <input
          matInput
          [placeholder]="'CONVERSATION_SEARCH.DATE_PICKER_PLACEHOLDER' | translate"
          [satDatepicker]="picker"
          [(ngModel)]="date"
          (keydown.enter)="searchConversations()"
          (dateChange)="onDateChange()"
        />
        <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
        <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
      </mat-form-field>

      <mat-form-field class="mt-2 ctn-full-width">
        <mat-chip-list #chipList>
          <mat-chip
            *ngFor="let keyword of searchFilter.keywords"
            [selectable]="true"
            [removable]="true"
            (removed)="remove(keyword)"
          >
            {{ keyword }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            #keywordInput
            [placeholder]="'CONVERSATION_SEARCH.KEYWORDS_INPUT_PLACEHOLDER' | translate"
            [formControl]="keywordCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="add($event)"
          />
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option *ngFor="let keyword of filteredKeywords | async" [value]="keyword">
            {{ keyword }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <button
      mat-button
      matSuffix
      mat-icon-button
      type="submit"
      class="ms-2 col-md-2"
      [attr.aria-label]="'BUTTON.SEARCH' | translate"
      (click)="searchConversations()"
    >
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <mat-accordion [multi]="true" class="col-sm-12 col-md-9 border-left px-1" [@listFadeInOut]="conversationsLength">
    <mat-progress-bar *ngIf="isLoading" color="primary" mode="query"></mat-progress-bar>
    <mat-expansion-panel
      *ngFor="let item of conversations | keyvalue"
      [expanded]="true"
      [@listFadeInOut]="$any(item.value).length"
    >
      <mat-expansion-panel-header>
        <mat-panel-title [matTooltip]="getKeywords(item.value)">
          <button class="btn btn-sm btn-outline-primary btn-action p-0 m-1" (click)="openCustomerHistory(item.key)">
            <mat-icon [matTooltip]="'BUTTON.VIEW' | translate">pageview</mat-icon>
          </button>
          {{ getKeywords(item.value) }}
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div *ngFor="let value of item.value" class="card mb-2">
        <div
          class="card-body p-2 border rounded"
          [class.border-primary]="value.metricType === 'AGENT_MESSAGE'"
          [class.border-success]="value.metricType === 'USER_MESSAGE'"
        >
          <div class="clearfix">
            <div class="d-flex justify-content-center align-items-center mb-1 float-start">
              <div
                *ngIf="
                  value.metricType === 'AGENT_MESSAGE' && (value?.agent?.userData?.avatarUrl || value?.agent?.avatarUrl)
                "
                class="agent-avatar me-2"
              >
                <app-user-template
                  class="icon-20"
                  [user]="value?.agent"
                  [displayUserName]="false"
                  tooltipField="userData.status"
                >
                </app-user-template>
              </div>

              <small class="text-primary">
                {{ value.metricType === 'AGENT_MESSAGE' ? value?.agent?.username : value?.customer?.username }}
              </small>
            </div>
            <div class="float-end">
              <small class="card-subtitle mb-0 text-muted">{{ value.createdAt | momentAgo }}</small>
            </div>
          </div>
          <p class="card-text message-content mb-0" [innerHTML]="value.message || '' | trustHtml"></p>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
