import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { listFadeInOut } from '@cation/core/animations';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { ApiHook } from '@cation/core/services/api/api-hook';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { IFeedBackComment } from '@cation/core/types/api';
import { DialogService } from '@cation/core/services/dialog/dialog.service';
import { MatSelectChange } from '@angular/material/select';
import { enumToArray } from '@cation/core/util/common-util';
import { FeedbackCommentsFilterOptionEnum } from './enums/feedback-comments-filter-option.enum';
import { feedbackCommentsFilterOptionsMap } from './feedback-comments-filter-options.map';
import { FeedbackCommentsDataModel } from './models/feedback-comments-date-range.model';
import * as moment from 'moment';

@Component({
  selector: 'app-feedback-comments-list',
  templateUrl: './feedback-comments-list.component.html',
  styleUrls: ['./feedback-comments-list.component.scss'],
  animations: [listFadeInOut]
})
export class FeedbackCommentsListComponent implements OnInit, OnDestroy {
  private _isActive = false;

  public Array = Array;
  public maxStars = 5;

  public feedbackComments: IFeedBackComment[] = [];
  public totalComments = 0;
  public isLoading = false;
  public errorMessage;
  public filters = enumToArray(FeedbackCommentsFilterOptionEnum);
  public selectedFilter = FeedbackCommentsFilterOptionEnum.all;
  public filter: FeedbackCommentsDataModel = null;
  public maxDate = moment().format('YYYY-MM-DD');
  public pickerDate: { begin: string; end: string };
  public filterOptionEnum = FeedbackCommentsFilterOptionEnum;

  @Input() set isActive(isActive: boolean) {
    if (!this._isActive && isActive) {
      this.findFeedbackComments();
    }
    this._isActive = isActive;
  }

  private loadIntersectionObserver: IntersectionObserver;

  @ViewChild('ctnContainer', { static: true }) ctnContainer: ElementRef;
  @ViewChild('loading', { static: true }) loading: ElementRef;
  @ViewChild('picker', { static: true }) picker;

  constructor(
    private apiService: ApiService,
    private logService: LogService,
    private authHelper: AuthHelper,
    public apiHook: ApiHook,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.findFeedbackComments();
    this.createLoadIntersection();
    this.initLoadIntersection();
  }

  ngOnDestroy() {
    this.disconnectLoadIntersection();
  }

  trackStatisticsItem(index, item) {
    return item ? item.memberId : undefined;
  }

  openCustomerHistory(conversationId) {
    this.dialogService.openCustomerHistory(conversationId);
  }

  async findFeedbackComments(isLoadMore = false) {
    this.isLoading = true;
    try {
      const createdAt = this.filter && this.filter.createdAt ? JSON.stringify(this.filter.createdAt) : undefined;
      const filter = Object.assign(
        { status: 'APPROVED', limit: 20, exclude: JSON.stringify({ message: '' }) },
        isLoadMore ? { from: this.feedbackComments.length } : {},
        createdAt ? { createdAt } : {}
      );

      const res = await this.apiService.findFeedbackCommentsByAgentId(this.authHelper.userProfile.cognitoId, filter);

      const { total, comments } = res;

      this.totalComments = total;
      this.feedbackComments = isLoadMore ? this.feedbackComments.concat(comments) : comments;

      this.logService.log('[findFeedbackComments]', res, this.feedbackComments);
    } catch (e) {
      this.errorMessage = e.message;
      this.logService.error('[findFeedbackComments Error]', e);
    }
    this.isLoading = false;
  }

  reFetchFeedbackComments() {
    this.feedbackComments = [];
    this.totalComments = 0;
    this.findFeedbackComments();
  }

  onFilterChange($event: MatSelectChange) {
    this.ctnContainer.nativeElement.scrollTop = 0;
    this.selectedFilter = $event.value;

    if ($event.value === FeedbackCommentsFilterOptionEnum.chooseRange) {
      this.picker.open();
    } else {
      this.filter = feedbackCommentsFilterOptionsMap.get($event.value);
      this.reFetchFeedbackComments();
    }
  }

  onDateChange() {
    const from = moment.utc(this.pickerDate.begin);
    const to = moment.utc(this.pickerDate.end);

    if (+from > +to) {
      return;
    }

    this.filter = { createdAt: { from: from.format(), to: to.format() } };
    this.pickerDate = { begin: this.filter.createdAt.from, end: this.filter.createdAt.to };
    this.reFetchFeedbackComments();
  }

  private createLoadIntersection() {
    const options = {
      root: this.ctnContainer.nativeElement,
      rootMargin: '0px',
      threshold: 1.0
    };

    const callback = entries => {
      entries.forEach(entry => {
        if (this.isIntersecting(entry) && this.totalComments > this.feedbackComments.length && !this.isLoading) {
          this.findFeedbackComments(true);
        }
      });
    };

    this.loadIntersectionObserver = new IntersectionObserver(callback, options);
  }

  private initLoadIntersection() {
    this.loadIntersectionObserver.observe(this.loading.nativeElement);
  }

  private disconnectLoadIntersection() {
    if (this.loadIntersectionObserver) {
      this.loadIntersectionObserver.disconnect();
    }
  }

  private isIntersecting(entry: IntersectionObserverEntry): boolean {
    return entry.isIntersecting || entry.intersectionRatio > 0;
  }
}
