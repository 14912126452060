import { Component, Input, OnInit, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { fadeInOut, listFadeInOut } from '@cation/core/animations';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { ApiHook } from '@cation/core/services/api/api-hook';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { LocaleUtil } from '@cation/core/util/locale-util';
import { IStatisticsResponse } from '@cation/core/types/api';
import User from '@cation/core/types/user';
import { DialogService } from '@cation/core/services/dialog/dialog.service';

type IStatistics = { [key: string]: IStatisticsResponse & { isLoading?: boolean } };

@Component({
  selector: 'app-league-statistics',
  templateUrl: './league-statistics.component.html',
  styleUrls: ['./league-statistics.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInOut, listFadeInOut]
})
export class LeagueStatisticsComponent implements OnInit {
  private _isActive: boolean = false;
  userProfile: User;

  selectedLeagueStaticsTab = new UntypedFormControl(0);
  selectedTeamStaticsTab = new UntypedFormControl(0);
  tabs = ['WEEKLY', 'MONTHLY', 'YEARLY', 'ALL'];
  mapTabsToPointsKey = {
    WEEKLY: 'weeklyPoints',
    MONTHLY: 'monthlyPoints',
    YEARLY: 'yearlyPoints',
    ALL: 'points'
  };
  leagueStatistics: IStatistics = {};
  teamStatistics: IStatistics = {};

  public isLoading = false;
  public errorMessage;

  @ViewChildren('tabsRef', { static: false } as any) tabsRef2;

  @Input() set isActive(isActive: boolean) {
    if (!this._isActive && isActive) {
      this.tabsRef2.forEach(tab => tab.realignInkBar());
    }
    this._isActive = isActive;
  }

  constructor(
    private apiService: ApiService,
    private logService: LogService,
    private authHelper: AuthHelper,
    private localeUtil: LocaleUtil,
    private dialog: MatDialog,
    public apiHook: ApiHook,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.authHelper.currentUserProfile$.subscribe(data => {
      this.userProfile = _.cloneDeep(data);
    });

    this.selectedLeagueStaticsTab.valueChanges.pipe().subscribe(newValue => {
      this.getGamificationStatistics('USER', this.tabs[newValue]);
    });

    this.selectedTeamStaticsTab.valueChanges.pipe().subscribe(newValue => {
      this.getGamificationStatistics('TEAM', this.tabs[newValue]);
    });

    this.getGamificationStatistics('USER', this.tabs[0]);
    this.getGamificationStatistics('TEAM', this.tabs[0]);
  }

  trackStatisticsItem(index, item) {
    return item ? item.memberId : undefined;
  }

  openCustomerHistory(conversationId) {
    this.dialogService.openCustomerHistory(conversationId);
  }

  async getGamificationStatistics(type: string, range: string, isLoadMore = false) {
    const statisticsRef = type === 'USER' ? this.leagueStatistics : this.teamStatistics;

    if (statisticsRef[range]) {
      statisticsRef[range].isLoading = true;
    } else {
      statisticsRef[range] = { isLoading: true, items: [], lastKey: null };
    }

    try {
      const startKey = isLoadMore ? _.get(statisticsRef[range], 'lastKey', null) : null;

      const options = { limit: 25, startKey: JSON.stringify(startKey) };
      const statistics = await this.apiService.getGamificationStatistics(type, range, options);

      if (isLoadMore && startKey) {
        statisticsRef[range].items = statisticsRef[range].items.concat(statistics.items);
        statisticsRef[range].lastKey = statistics.lastKey;
      } else {
        statisticsRef[range] = statistics;
      }

      this.logService.log('[getGamificationStatistics]', statistics);
    } catch (e) {
      this.errorMessage = e.message;
      this.logService.error('[getGamificationStatistics Error]', e);
    }

    statisticsRef[range].isLoading = false;
  }

  getContext(item, tab: string, index: number) {
    const pointsField = this.mapTabsToPointsKey[tab];
    const image =
      item.type === 'USER' ? _.get(item.member, 'userData.avatarUrl', _.get(item.member, 'avatarUrl', '')) : _.get(item.member, 'icon', '');
    const secondImage = item.type === 'USER' ? (image !== _.get(item.member, 'avatarUrl', '') ? _.get(item.member, 'avatarUrl', '') : null) : null;

    return {
      index,
      type: item.type,
      points: item[pointsField],
      prevPoints: item[`prev${_.upperFirst(pointsField)}`] || 0,
      image,
      secondImage,
      name: item.type === 'USER' ? _.get(item.member, 'username', '') : _.get(item.member, 'name', ''),
      team: item.type === 'USER' ? _.get(item.member, 'team', {}) : undefined,
      nickname: item.type === 'USER' ? _.get(item.member, 'userData.nickname', '') : ''
    };
  }

  isMine(item) {
    const memberId =
      item.type === 'USER' ? this.authHelper.userProfile.cognitoId : _.get(this.authHelper.userProfile, 'team.id');
    return memberId === item.memberId;
  }
}
