<div class="internal-chat-navbar">
  <internal-nav-item
    [itemType]="itemTypeEnum.users"
    [isActive]="route === _routes.USERS_LIST"
    (onClick)="onNavbarItemClick(_routes.USERS_LIST)"
    [title]="itemTypeEnum.users"
  ></internal-nav-item>
  <internal-nav-item
    [itemType]="itemTypeEnum.chats"
    [isActive]="route === _routes.CHATS_LIST"
    (onClick)="onNavbarItemClick(_routes.CHATS_LIST)"
    [title]="itemTypeEnum.chats"
  ></internal-nav-item>
  <internal-nav-item
    [@inOutAnimation]
    *ngIf="route === _routes.CHATS_LIST || route === _routes.CREATE_CHAT"
    [itemType]="itemTypeEnum.createChat"
    [isActive]="route === _routes.CREATE_CHAT"
    (onClick)="onNavbarItemClick(_routes.CREATE_CHAT)"
    [title]="itemTypeEnum.createChat"
  ></internal-nav-item>
</div>
