import { ActionButtonComponent } from './action-button/action-button.component';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MainFrameComponent } from './main-frame/main-frame.component';
import { TriggerButtonComponent } from './trigger-button/trigger-button.component';
import { ChatTopbarComponent } from './chat-topbar/chat-topbar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { InternalNavItemComponent } from './nav-item/nav-item.component';
import { ChatComponent } from './chat/chat.component';
import { AppSyncService } from './services/app-sync.service';
import { AppsyncOperationService } from './services/appsync/appsync-operation.service';
import { AppsyncUserService } from './services/appsync/appsync-user.service';
import { AppsyncChatService } from './services/appsync/appsync-chat.service';
import { AppSyncClientOptionsService } from './services/app-sync-client-options.service';
import { S3Service } from './services/s3.service';
import { LoggerService } from './services/logger.service';
import { RouteService } from './services/route.service';
import { InternalUserInfoComponent } from './user-info/user-info.component';
import { InternalChatBodyComponent } from './chat-body/chat-body.component';
import { InternalUsersListComponent } from './users-list/users-list.component';
import { S3OptionsService } from './services/s3-options.service';
import { EnvironmentOptionsService } from './services/environment-options.service';
import { S3ImageComponent } from './s3-image/s3-image.component';
import { SharedService } from './services/shared.service';
import { ChatsListComponent } from './chats-list/chats-list.component';
import { ChatInfoComponent } from './chat-info/chat-info.component';
import { ChatHistoryComponent } from './chat-history/chat-history.component';
import { MessageInputComponent } from './message-input/message-input.component';
import { MessageBalloonComponent } from './message-balloon/message-balloon.component';
import { AppsyncMessageService } from './services/appsync/appsync-message.service';
import { InlineEditComponent } from './inline-edit/inline-edit.component';
import { ScrollDirective } from './directives/scroll.directive';
import { MessagesCounterComponent } from './messages-counter/messages-counter.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    PickerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatExpansionModule,
    ClickOutsideModule
  ],
  declarations: [
    ChatComponent,
    S3ImageComponent,
    MainFrameComponent,
    TriggerButtonComponent,
    ChatTopbarComponent,
    NavbarComponent,
    InternalNavItemComponent,
    InternalUserInfoComponent,
    InternalChatBodyComponent,
    InternalUsersListComponent,
    ChatsListComponent,
    ChatInfoComponent,
    ChatHistoryComponent,
    MessageInputComponent,
    MessageBalloonComponent,
    ChatInfoComponent,
    InlineEditComponent,
    ScrollDirective,
    MessagesCounterComponent,
    ActionButtonComponent
  ],
  bootstrap: [ChatComponent],
  exports: [ChatComponent],
  providers: [
    AppSyncService,
    S3Service,
    AppsyncOperationService,
    AppsyncUserService,
    AppsyncChatService,
    RouteService,
    SharedService,
    AppsyncMessageService,
    LoggerService
  ]
})
export class InternalChatModule {
  static forRoot(
    options: {
      appsync: { url: string; region: string };
      s3: { bucket: string; region: string };
      production: boolean;
    } = {
      production: false,
      appsync: { url: undefined, region: undefined },
      s3: { bucket: undefined, region: undefined }
    }
  ): ModuleWithProviders<InternalChatModule> {
    return {
      ngModule: InternalChatModule,
      providers: [
        { provide: AppSyncClientOptionsService, useValue: options ? options.appsync : undefined },
        { provide: S3OptionsService, useValue: options ? options.s3 : undefined },
        { provide: EnvironmentOptionsService, useValue: options ? options.production : undefined }
      ]
    };
  }
}
