<div>
  <iframe
    class="reportContainer"
    *ngIf="quickSightUrl"
    [src]="quickSightUrl | trustResourceUrl"
    frameborder="0"
    webkitallowfullscreen
    mozallowfullscreen
    allowfullscreen
  >
  </iframe>
</div>

<!--
<div *ngIf="!auth.isAdmin()" class="alert alert-info text-center" role="alert">
  <a href="https://us-east-1.quicksight.aws.amazon.com/sn/start/dashboards" target="_blank" class="alert-link">
    <h3>Launch</h3>
  </a>
</div>
-->

<!--
<div>
  <iframe [src]='sanitizer.bypassSecurityTrustResourceUrl(quickSightUrl)' width="100%" height="600px" frameborder="0" webkitallowfullscreen
    mozallowfullscreen allowfullscreen>
  </iframe>
</div>
-->

<!--
<div>
  <object type="text/html" [data]='sanitizer.bypassSecurityTrustResourceUrl(quickSightUrl)' width="100%" height="600px" webkitallowfullscreen mozallowfullscreen allowfullscreen></object>
</div>
-->

<!--
<div>
  <embed [src]="sanitizer.bypassSecurityTrustResourceUrl(quickSightUrl)" width="100%" height="600px" webkitallowfullscreen
    mozallowfullscreen allowfullscreen/>
</div>
-->
