import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { listFadeInOut } from '@cation/core/animations';
import { ApiService } from '@cation/core/services/api/api.service';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { AddAchievementDialogComponent } from '@cation/core/components/add-achievement-dialog/add-achievement-dialog.component';
import Conversation from '@cation/core/types/conversation';
import IAchievement from '@cation/core/types/achievement';
import { DialogService } from '@cation/core/services/dialog/dialog.service';
import { faHistory } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-review-user-details-view',
  templateUrl: './review-user-details-view.component.html',
  styleUrls: ['./review-user-details-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [listFadeInOut],
})
export class ReviewUserDetailsViewComponent implements OnInit {
  faHistory = faHistory;
  public displayedColumns = ['createdAt', 'intentsTriggered', 'caseId', 'channel', 'actions'];
  public dataSource: MatTableDataSource<Conversation> = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  public selectedTab = new UntypedFormControl(0);
  public _user;

  public errorMessage;
  public achievements = [];
  public isLoading = false;

  @Input() set user(user) {
    this._user = user;
    this.achievements = [];
    this.dataSource = new MatTableDataSource([]);

    if (user) {
      this.getUserAdditionalInfo();
    }
  }

  constructor(
    private apiService: ApiService,
    public auth: AuthHelper,
    private logService: LogService,
    public s3Service: S3Service,
    private dialog: MatDialog,
    private dialogService: DialogService
  ) {}

  async ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }

  get cmsIntegrations() {
    return this.user.cmsUserData.map((cms) => cms.type).join(',');
  }

  get user() {
    return this._user;
  }

  async getUserAdditionalInfo() {
    await this.getUserAchievements();
    await this.loadCustomerHistory();
  }

  openCustomerHistory(conversationId) {
    this.dialogService.openCustomerHistory(conversationId);
  }

  async loadCustomerHistory() {
    this.isLoading = true;
    try {
      const conversations: Conversation[] = (await this.apiService
        .getConversationsByUserId(this.user.cognitoId)
        .toPromise()) as Conversation[];

      this.logService.log('[loadCustomerHistory conversations]', conversations);

      this.dataSource = new MatTableDataSource(_.orderBy(conversations, 'createdAt', 'desc'));
      this.dataSource.paginator = this.paginator;
    } catch (e) {
      this.logService.log('[loadCustomerHistory error]', e);
    }
    this.isLoading = false;
  }

  async getUserAchievements() {
    this.isLoading = true;
    try {
      const achievements: IAchievement[] = (await this.apiService.getUserAchievements(
        this.user.cognitoId
      )) as IAchievement[];

      this.logService.log('[getUserAchievements achievements]', achievements);
      this.achievements = achievements;
    } catch (e) {
      this.logService.log('[getUserAchievements error]', e);
    }
    this.isLoading = false;
  }

  onAddAchievement() {
    this.logService.debug('[ReviewUserComponent onAddAchievement]', this.user.cognitoId);
    const dialog = this.dialog.open(AddAchievementDialogComponent, {
      data: { excludeAchievements: [], user: this.user },
    });

    dialog.afterClosed().subscribe(async (achievement) => {
      this.logService.log('[onAddAchievement achievement]', achievement);
      if (achievement) {
        await this.getUserAchievements();
      }
    });
  }
}
