import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent {
  @Input() type = 'button';
  @Input() label = '';

  @Output() clickEvent = new EventEmitter();

  constructor() {}

  onClick($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.clickEvent.emit();
  }
}
