<div class="master-league-page">
  <mat-card class="ctn-card">
    <mat-card-content>
      <h4 class="ctn-h3 ctn-margin">
        <mat-icon>emoji_events</mat-icon>
        {{ 'MASTER_LEAGUE.TITLE' | translate }}
      </h4>

      <hr />

      <div class="d-flex row px-3">
        <mat-card class="ctn-card mt-2 col-md-6 col-sm-12 mat-card mat-card">
          <mat-card-header>
            <mat-card-title>{{ 'MASTER_LEAGUE.USER_LEAGUE_TITLE' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <ng-container
              *ngTemplateOutlet="
                statisticsGroupTemplate;
                context: { selectedTab: selectedUserStaticsTab, statistics: userStatistics, type: 'USER' }
              "
            >
            </ng-container>
          </mat-card-content>
        </mat-card>

        <mat-card class="ctn-card mt-2 col-md-6 col-sm-12 mat-card mat-card">
          <mat-card-header>
            <mat-card-title>{{ 'MASTER_LEAGUE.TEAM_LEAGUE_TITLE' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <ng-container
              *ngTemplateOutlet="
                statisticsGroupTemplate;
                context: { selectedTab: selectedTeamStaticsTab, statistics: teamStatistics, type: 'TEAM' }
              "
            >
            </ng-container>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-card-content>

    <div class="alert alert-danger pt-2" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
  </mat-card>

  <ng-template
    #statisticsItemTemplate
    let-image="image"
    let-secondImage="secondImage"
    let-name="name"
    let-team="team"
    let-points="points"
    let-nickname="nickname"
    let-status="status"
    let-type="type"
    let-index="index"
  >
    <div class="d-flex justify-content-center align-items-center">
      <span class="text_gray pe-2">#{{ index + 1 }}</span>
      <div class="tp-wrapper" *ngIf="secondImage">
        <div class="tp-box">
          <div class="front">
            <app-s3-image [isRounded]="type === 'USER'" [image]="image" [avatarName]="name"></app-s3-image>
          </div>
          <div class="back">
            <app-s3-image [isRounded]="type === 'USER'" [image]="secondImage" [avatarName]="name"></app-s3-image>
          </div>
        </div>
      </div>
      <div class="statistics__item__member__icon" *ngIf="!secondImage">
        <app-s3-image [isRounded]="type === 'USER'" [image]="image" [avatarName]="name"></app-s3-image>
      </div>

      <div *ngIf="type === 'USER' && team?.icon" class="ms-2 statistics__item__member__icon" [matTooltip]="team.name">
        <app-s3-image [image]="team.icon"></app-s3-image>
      </div>
      <div class="d-flex flex-column ps-2">
        <span class="statistics__item__member__name">
          {{ name }}
          <span class="statistics__item__member__status" *ngIf="nickname">[{{ nickname }}]</span>
        </span>
        <span class="statistics__item__member__status" *ngIf="status">{{ status }}</span>
      </div>
    </div>
    <span class="statistics__item__points">
      {{ points }}
    </span>
  </ng-template>

  <ng-template #statisticsGroupTemplate let-selectedTab="selectedTab" let-statistics="statistics" let-type="type">
    <mat-tab-group [selectedIndex]="selectedTab.value" (selectedIndexChange)="selectedTab.setValue($event)">
      <mat-tab [label]="'MASTER_LEAGUE.STATISTICS.' + tab + '_TAB_TITLE' | translate" *ngFor="let tab of tabs">
        <div *ngIf="statistics[tab]?.isLoading" class="statistics-loader" [@fadeInOut]>
          <mat-spinner mode="indeterminate"></mat-spinner>
        </div>

        <div
          *ngIf="!statistics[tab]?.items?.length && !statistics[tab]?.isLoading"
          class="d-flex justify-content-center align-items-center"
        >
          {{ 'MASTER_LEAGUE.STATISTICS.EMPTY_MESSAGE' | translate }}
        </div>

        <div [@listFadeInOut]="statistics[tab]?.items.length">
          <div
            *ngFor="let item of statistics[tab]?.items; let i = index; trackBy: trackStatisticsItem"
            class="row my-2 d-flex justify-content-between align-items-center statistics__item"
            [class.statistics__item_active]="isMine(item)"
          >
            <ng-container *ngTemplateOutlet="statisticsItemTemplate; context: getContext(item, tab, i)"></ng-container>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</div>
