import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { listFadeInOut } from '@cation/core/animations';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { ApiHook } from '@cation/core/services/api/api-hook';
import IAchievement from '@cation/core/types/achievement';
import { PromptDialogComponent } from '@cation/core/dialogs/prompt-dialog/prompt-dialog.component';
import { AddEditAchievementComponent } from '../add-edit-achievement/add-edit-achievement.component';

@Component({
  selector: 'ctn-gamification-achievements',
  templateUrl: './gamification-achievements.component.html',
  styleUrls: ['./gamification-achievements.component.scss'],
  animations: [listFadeInOut]
})
export class GamificationAchievementsComponent implements OnInit {
  achievements = [];

  constructor(
    private apiService: ApiService,
    public apiHook: ApiHook,
    public logService: LogService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.getAchievements();
  }

  trackAchievement(index, achievement) {
    return achievement ? achievement.id : undefined;
  }

  onAddAchievement() {
    const dialogRef = this.dialog.open(AddEditAchievementComponent, {});

    dialogRef.afterClosed().subscribe(achievement => {
      this.logService.log('[GamificationAchievementsComponent onAddAchievement:achievement]', achievement);
      if (achievement) {
        this.getAchievements();
      }
    });
  }

  onEditAchievement(achievement: IAchievement) {
    const dialogRef = this.dialog.open(AddEditAchievementComponent, { data: { ...achievement } });

    dialogRef.afterClosed().subscribe(achievement => {
      this.logService.log('[GamificationAchievementsComponent onEditAchievement:achievement]', achievement);
      if (achievement) {
        this.getAchievements();
      }
    });
  }

  async onDeleteAchievement(achievement: IAchievement) {
    const dialogRef = this.dialog.open(PromptDialogComponent, {
      data: {
        title: 'Are you sure you want to delete the achievement?',
        message: `Type '${achievement.title}' to confirm`,
        pattern: new RegExp(`^${achievement.title}$`, 'g')
      }
    });

    dialogRef.afterClosed().subscribe(async value => {
      this.logService.log('[GamificationAchievementsComponent onDeleteAchievement:value]', value);
      if (!value) {
        return;
      }

      await this.apiService.deleteAchievement(achievement.id);
      this.getAchievements();
    });
  }

  async getAchievements() {
    const achievements = await this.apiService.getAchievements();
    this.logService.log('[GamificationAchievementsComponent getAchievements]', achievements);
    this.achievements = achievements;
  }
}
