import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import AWSAppSyncClient from 'aws-appsync';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { fadeInOut } from '@cation/core/animations';
import { ICMS } from '@cation/core/interfaces/cms';
import { CmsService } from '@cation/core/services/cms';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { CMS_TYPE } from '@cation/core/enums/cms-type.enum';
import { LogService } from '@cation/core/services/log/log.service';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { AppsyncConversationService } from '@cation/core/services/appsync/appsync-conversation.service';
import Conversation from '@cation/core/types/conversation';
import User from '@cation/core/types/user';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [fadeInOut],
})
export class ChatComponent implements OnInit, OnDestroy {
  username: string;
  client: AWSAppSyncClient<any>;
  me: User;
  conversations: Conversation[] = [];
  conversation: Conversation;
  messagesData;
  notesData;
  update: boolean;

  countActiveConversations = 0;

  constructor(
    private swUpdate: SwUpdate,
    private appsyncConversationService: AppsyncConversationService,
    private sharedService: SharedService,
    private cmsService: CmsService,
    private authHelper: AuthHelper,
    private logService: LogService,
    public translate: TranslateService
  ) {}

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload($event) {
    // NOTE: Chrome and Firefox display their own pending changes warning message.
    // https://stackoverflow.com/questions/42142053/candeactivate-confirm-message/42207299#42207299
    const message = 'You have active conversations.\nPlease continue these conversations.';
    if (this.countActiveConversations) {
      ($event || window.event).returnValue = message;
      return message;
    }

    return undefined;
  }

  ngOnInit() {
    this.authHelper.currentUserProfile$.subscribe((me) => (this.me = me));
    this.sharedService.currentConversation.subscribe((conversation) => (this.conversation = conversation));
    this.sharedService.currentConversations.subscribe((conversations) => {
      console.log('Received new set of conversations', conversations);
      this.conversations = conversations;
    });
    this.sharedService.currentMessagesData.subscribe((messagesData) => (this.messagesData = messagesData));
    this.sharedService.currentNotesData.subscribe((notesData) => (this.notesData = notesData));
    this.sharedService.currentCountActiveConversations.subscribe(
      (countActiveConversations) => (this.countActiveConversations = countActiveConversations)
    );
    this.swUpdate.available.subscribe((event) => {
      this.logService.log(
        '[App] Update available: current version is',
        event.current,
        'available version is',
        event.available
      );
      this.update = true;
    });
  }

  ngOnDestroy() {
    this.sharedService.setCurrentConversation(undefined);
  }

  public setNewConvo(convo) {
    this.sharedService.setCurrentConversation(convo);
  }

  public detachCms(cms: ICMS) {
    const key = `${cms.type}:${cms.id}`;

    this.sharedService.deleteCms(key);
    this.sharedService.onCmsChanged.emit(undefined);

    if (_.isArray(this.conversation.cms) && this.conversation.cms.length) {
      this.conversation = {
        ...this.conversation,
        cms: this.conversation.cms.filter((item) => item.id === cms.id && item.type === cms.type),
      };
    }
  }

  public async addCmsSystem(cmsSystem: ICMS) {
    const newCms = {
      id: `${cmsSystem.id}`,
      type: cmsSystem.type,
      conversationId: this.conversation.id,
      createdAt: Date.now(),
      isCore: !this.conversation.cms.length,
      __typename: 'ConversationCms',
    };

    await this.appsyncConversationService.addCmsIntoConversation(newCms);

    const cms = this.cmsService.createCMS(newCms.type as CMS_TYPE, { lazyLoad: true, id: newCms.id });
    const key = `${newCms.type}:${newCms.id}`;

    this.sharedService.addCmsSystem(key, cms);

    if (_.isArray(this.conversation.cms) && this.conversation.cms.length) {
      const existsCms = this.conversation.cms.find((cms) => cms.id === newCms.id && cms.type === newCms.type);
      if (!existsCms) {
        this.conversation = { ...this.conversation, cms: [...this.conversation.cms, newCms] };
      }
    } else {
      this.conversation = { ...this.conversation, cms: [newCms] };
    }

    setTimeout(() => this.sharedService.onCmsChanged.emit(`${newCms.type}:${newCms.id}`));
  }

  public onCloseConversation() {
    this.sharedService.setCurrentConversation(undefined);
  }

  checkForUpdate() {
    this.logService.log('[ChatQL] checkForUpdate started');
    this.swUpdate
      .checkForUpdate()
      .then(() => {
        this.logService.log('[ChatQL] checkForUpdate completed');
      })
      .catch((err) => {
        this.logService.error(err);
      });
  }

  activateUpdate() {
    this.logService.log('[ChatQL] activateUpdate started');
    this.swUpdate
      .activateUpdate()
      .then(() => {
        this.logService.log('[ChatQL] activateUpdate completed');
        location.reload();
      })
      .catch((err) => {
        this.logService.error(err);
      });
    this.update = false;
  }
}
