import { StatsFilterOptionEnum } from '../../widget-header/enums/stats-filter-option.enum';
import { statsFilterOptionsMap } from '../stats-filter-options.map';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUserConversationStatistics } from '@cation/core/types/api';
import { SingleChartModel } from '../models/single-chart.model';
import { enumToArray } from '@cation/core/util/common-util';
import { Channel } from '@cation/core/enums/channel.enum';
import { Locale } from '@cation/core/enums/locale';
import { StatsDataKeyEnum } from '../enums/stats-data-key.enum';
import { MultiChartModel } from '../models/multi-chart.model';
import { chartColorSchemas } from '@cation/core/constants/chart-color-schema';
import { StatsTypeEnum } from '../enums/stats-type.enum';
import * as shape from 'd3-shape';

@Component({
  selector: 'app-number-of-conversations-widget',
  templateUrl: './number-of-conversations-widget.component.html',
  styleUrls: ['./number-of-conversations-widget.component.scss'],
})
export class NumberOfConversationsWidgetComponent {
  private statistics: IUserConversationStatistics[];
  @Input() set setStatistics(data: IUserConversationStatistics[]) {
    this.statistics = data;
    this.setTotalValue();
    this.drawChart();
  }

  @Input() selectedDateFilterValue: StatsFilterOptionEnum;

  @Output() onDataFilterChanged = new EventEmitter<StatsFilterOptionEnum>();

  statsTypeEnum = StatsTypeEnum;

  dateFilterOptions = Array.from(statsFilterOptionsMap.keys());
  chartTypeFilterOptions = [
    StatsFilterOptionEnum.summary,
    StatsFilterOptionEnum.channels,
    StatsFilterOptionEnum.locale,
  ];
  statsFilterOptionEnum = StatsFilterOptionEnum;

  selectedChartTypeFilterValue = StatsFilterOptionEnum.summary;

  totalValue = 0;

  chartSingleResult: SingleChartModel[];
  chartMultiResult: MultiChartModel[];
  colorScheme = { domain: chartColorSchemas['orange-blue'] };
  color = { domain: chartColorSchemas['single-orange-color'] };
  curve = shape.curveMonotoneX;

  private channels = enumToArray(Channel);
  private locales = Locale.map((item) => item.code);

  labelPieFormatting = (l: string) => {
    for (const item of this.chartSingleResult) {
      if (item.name === l) {
        return item.value;
      }
    }

    return undefined;
  };

  constructor() {}

  onDateFilterChange(value: StatsFilterOptionEnum) {
    this.chartSingleResult = null;
    this.chartMultiResult = null;
    this.onDataFilterChanged.emit(value);
  }

  onChartTypeFilterChange(type: StatsFilterOptionEnum) {
    this.selectedChartTypeFilterValue = type;
    this.drawChart();
  }

  private getChartLegendNames(selectedTypeFilter: StatsFilterOptionEnum): string[] {
    switch (selectedTypeFilter) {
      case StatsFilterOptionEnum.channels:
        return this.channels;
      case StatsFilterOptionEnum.locale:
        return this.locales;
      default:
        console.error('Filter option not defined');
        return [];
    }
  }

  private singleSerialize(statistics: IUserConversationStatistics[]): SingleChartModel[] {
    const result = this.getChartLegendNames(this.selectedChartTypeFilterValue);
    const dataKey = StatsDataKeyEnum.conversation;

    if (!dataKey) {
      return undefined;
    }

    return result.map((name) => {
      return {
        name,
        value: this.getTotal(statistics, `${dataKey}${name}`),
      };
    });
  }

  private getTotal(statistics, key): number {
    const numbers = statistics.map((data) => Number(data[`${key}`] || 0));
    return numbers.reduce((a, b) => a + b);
  }

  private multiSerialize(statistics: IUserConversationStatistics[]): MultiChartModel[] {
    const result = this.getChartLegendNames(this.selectedChartTypeFilterValue);
    const dataKey = StatsDataKeyEnum.conversation;

    if (!dataKey) {
      return undefined;
    }

    return result.map((name) => {
      return {
        name,
        series: statistics.map((range) => {
          return {
            name: range.date,
            value: range[`${dataKey}${name}`] || 0,
          };
        }),
      };
    });
  }

  private summarySerialize(statistics: IUserConversationStatistics[]): SingleChartModel[] {
    const dataKey = StatsDataKeyEnum.conversation;
    const result = [];

    if (!dataKey) {
      return undefined;
    }

    for (const stat of statistics) {
      if (stat && stat.date) {
        result.push({
          name: stat.date,
          value: Number(stat[`${dataKey}`] || 0),
        });
      }
    }

    return result;
  }

  private drawChart() {
    if (!this.statistics) {
      return;
    }

    if (this.selectedChartTypeFilterValue === StatsFilterOptionEnum.summary) {
      this.chartSingleResult = this.summarySerialize(this.statistics);
      return;
    }

    if (this.selectedDateFilterValue === StatsFilterOptionEnum.today) {
      this.chartSingleResult = this.singleSerialize(this.statistics);
    } else {
      this.chartMultiResult = this.multiSerialize(this.statistics);
    }
  }

  private setTotalValue() {
    this.totalValue = 0;
    let value = 0;

    if (this.statistics) {
      this.statistics.forEach((data) => {
        value += data.countConversations;
      });
    }

    this.totalValue = value;
  }

  isLoading(): boolean {
    if (
      this.selectedChartTypeFilterValue === StatsFilterOptionEnum.summary ||
      this.selectedDateFilterValue === StatsFilterOptionEnum.today
    ) {
      return !this.chartSingleResult;
    }

    return !this.chartMultiResult;
  }

  isEmptyStatistics(): boolean {
    if (
      this.selectedChartTypeFilterValue === StatsFilterOptionEnum.summary ||
      this.selectedDateFilterValue === StatsFilterOptionEnum.today
    ) {
      return this.chartSingleResult && !this.chartSingleResult.length;
    }

    return this.chartMultiResult && !this.chartMultiResult.length;
  }
}
