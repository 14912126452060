export interface Theme {
  name: string;
  properties: any;
}

export const defaultTheme: Theme = {
  name: 'parlyTheme',
  properties: {
    '--primary-color': '#455a64',
    '--primary-light-color': '#718792',
    '--primary-dark-color': '#1c313a',

    '--secondary-default-color': '#4dd0e1',
    '--secondary-light-color': '#88ffff',
    '--secondary-dark-color': '#009faf',

    '--background-default-color': 'var(--primary-color)',
    '--background-light-color': '#e1ebf1',
    '--background-dark-color': 'var(--primary-light-color)',

    '--navbar': 'var(--primary-dark-color)',
    '--on-navbar': '#ffffff',
    '--navbar-shadow': '0px 4px 7px 0px rgba(28, 49, 58, 0.2)',

    '--sidebar': 'var(--primary-dark-color)',
    '--on-sidebar': '#ffffff',

    '--subnav': 'var(--background-light-color)',
    '--on-subnav': '#000000',

    '--btn-ok': 'var(--secondary-dark-color)',
    '--btn-cancel': 'var(--primary-dark-color)',

    '--conversations-list-item': 'var(--grey-light)',

    '--information-color': '#faff1a',
    '--success-color': '#44d931',
    '--error-color': '#b00020',
    '--warning-color': '#ffb01f',

    '--scroll-light-color': 'rgba(146, 148, 156, 0.4)',
    '--scroll-dark-color': 'rgba(146, 148, 156, 0.8)',
    '--grey-light': '#f5f5f6',
    '--icon-light-color': '#92929ccc',
    '--icon-light-hover-color': '#707079cc',
    '--icon-dark-color': '#4f4f4f'
  }
};

export const engageHubTheme: Theme = {
  name: 'engageHubTheme',
  properties: {
    '--primary-color': '#8CBF5C',
    '--primary-light-color': '#AFD08E',
    '--primary-dark-color': '#2b4d0e',

    '--secondary-default-color': '#4dd0e1', // #2F3740
    '--secondary-light-color': '#414D59',
    '--secondary-dark-color': '#232D38',

    '--background-default-color': '#EAF0F8',
    '--background-light-color': '#EAF0F8',
    '--background-dark-color': '#a9b2bb',

    '--navbar': 'var(--primary-dark-color)', // #ffffff
    '--on-navbar': '#ffffff', // #3C4752
    '--navbar-shadow': '0px 4px 7px 0px rgba(28, 49, 58, 0.2)',

    '--sidebar': '#323d4b',
    '--on-sidebar': '#ffffff',

    '--subnav': '#ffffff',
    '--on-subnav': '#000000',

    '--btn-ok': 'var(--primary-color)',
    '--btn-cancel': '#133853',

    '--conversations-list-item': '#ffffff',

    '--information-color': '#7EBBBE',
    '--success-color': '#44d931',
    '--error-color': '#b00020',
    '--warning-color': '#F6AA44',

    '--scroll-light-color': 'rgba(146, 148, 156, 0.4)',
    '--scroll-dark-color': 'rgba(146, 148, 156, 0.8)',
    '--grey-light': '#f5f5f6',
    '--icon-light-color': '#92929ccc',
    '--icon-light-hover-color': '#707079cc',
    '--icon-dark-color': '#4f4f4f'
  }
};
