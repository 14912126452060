import gql from 'graphql-tag';

export default gql`
  query getMessages($chatId: ID!, $after: String, $first: Int) {
    getMessages(chatId: $chatId, after: $after, first: $first) {
      __typename
      nextToken
      messages {
        __typename
        id
        chatId
        content
        createdAt
        senderId
        attachmentId
        attachment {
          id
          key
          type
          size
          name
        }
      }
    }
  }
`;
