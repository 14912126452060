<h1 mat-dialog-title>{{ 'ADMIN_ADD_TEAM_DIALOG.' + (team.id ? 'UPDATE_TITLE' : 'CREATE_TITLE') | translate }}</h1>

<div mat-dialog-content>
  <div>{{ 'ADMIN_ADD_TEAM_DIALOG.DESCRIPTION' | translate }}</div>

  <form class="ctn-form" #dialogForm="ngForm">
    <div class="ctn-full-width pt-2 d-flex justify-content-center">
      <div class="team__icon">
        <app-image-picker
          keyPrefix="team"
          [image]="team.icon"
          (onImageSelect)="onTeamIconSelect($event)"
        ></app-image-picker>
      </div>
    </div>

    <mat-form-field class="ctn-full-width pt-3">
      <input
        type="text"
        matInput
        cdkFocusInitial
        [placeholder]="'ADMIN_ADD_TEAM_DIALOG.NAME_INPUT.PLACEHOLDER' | translate"
        [(ngModel)]="team.name"
        name="team-name"
        maxlength="20"
        required
      />
      <button
        mat-button
        *ngIf="team.name"
        matSuffix
        mat-icon-button
        [attr.aria-label]="'BUTTON.CLEAR' | translate"
        (click)="team.name = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint>{{ 'ADMIN_ADD_TEAM_DIALOG.NAME_INPUT.HINT' | translate }}</mat-hint>
      <mat-error>{{ 'ADMIN_ADD_TEAM_DIALOG.NAME_INPUT.ERROR' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field class="ctn-full-width pt-3">
      <input
        type="text"
        matInput
        cdkFocusInitial
        [placeholder]="'ADMIN_ADD_TEAM_DIALOG.DESCRIPTION_INPUT.PLACEHOLDER' | translate"
        [(ngModel)]="team.description"
        name="team-description"
        maxlength="100"
        required
      />
      <button
        mat-button
        *ngIf="team.description"
        matSuffix
        mat-icon-button
        [attr.aria-label]="'BUTTON.CLEAR' | translate"
        (click)="team.description = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint>{{ 'ADMIN_ADD_TEAM_DIALOG.DESCRIPTION_INPUT.HINT' | translate }}</mat-hint>
      <mat-error>{{ 'ADMIN_ADD_TEAM_DIALOG.DESCRIPTION_INPUT.ERROR' | translate }}</mat-error>
    </mat-form-field>

    <app-agent-input-autocomplete
      (selectionChange)="teamLeadCtrl.setValue($event)"
      (agentsLoad)="isUsersLoading = false"
      [roles]="roles"
      [excludeMe]="false"
      [initialAgentId]="data?.teamLeadId"
      [placeholder]="'ADMIN_ADD_TEAM_DIALOG.TEAM_LEAD_SELECT.PLACEHOLDER' | translate"
      [placeholderLabel]="'ADMIN_ADD_TEAM_DIALOG.TEAM_LEAD_SELECT.INPUT_PLACEHOLDER' | translate"
      [hint]="'ADMIN_ADD_TEAM_DIALOG.TEAM_LEAD_SELECT.HINT' | translate"
      [errorLabel]="'ADMIN_ADD_TEAM_DIALOG.TEAM_LEAD_SELECT.ERROR' | translate"
    >
    </app-agent-input-autocomplete>

    <team-members-list
      [members]="members"
      (onAddMember)="addMember($event)"
      (onRemoveMember)="removeMember($event)"
    ></team-members-list>
    <mat-progress-bar class="m-2" *ngIf="isUsersLoading" [mode]="'indeterminate'"></mat-progress-bar>

    <div *ngIf="errorMessage" class="alert alert-danger mt-2">
      {{ errorMessage }}
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'BUTTON.CANCEL' | translate }}</button>
  <button
    mat-button
    (click)="submit()"
    [disabled]="dialogForm.invalid || !team.icon || teamLeadCtrl.invalid || isUsersLoading"
  >
    {{ 'ADMIN_ADD_TEAM_DIALOG.' + (team.id ? 'UPDATE_TEAM_BUTTON_TEXT' : 'CREATE_TEAM_BUTTON_TEXT') | translate }}
  </button>
</div>
