<div class="conversations-container">
  <div class="list-group mb-1" [@listFadeInOut]="conversations.length">
    <div
      *ngFor="let convo of conversations; trackBy: trackConversation"
      class="list-group-item"
      [class.unread]="countUnreadMessages[convo.id] || newConversations.includes(convo.id)"
      [class.closed]="convo?.status === 'Closed'"
      [class.active]="convo?.id === current?.id"
      [class.disabled]="convo?.id !== current?.id && !!videoChatStatus"
      (click)="selectConversation(convo); (false)"
    >
      <div class="conversation-container">
        <div class="d-flex">
          <div class="social-avatar">
            <img
              *ngIf="convo.author.userData?.profilePicUrl"
              [src]="convo.author.userData?.profilePicUrl"
              class="avatar me-1"
              alt="avatar"
            />
          </div>
          <div class="conversation-name-container">
            <div class="conversation-name" [title]="convo.name">
              {{ convo.name }}
            </div>
            <i
              *ngIf="!['EHUB', 'ZENDESK'].includes(convo?.channel)"
              [class]="classIcon[convo?.channel?.toLocaleLowerCase()] + ' icon me-1 ms-1'"
            >
            </i>
            <img
              *ngIf="convo?.channel === 'EHUB'"
              src="../../../assets/img/ehub.png"
              alt="ehub"
              class="channel-icon me-1 ms-1"
            />
            <img
              *ngIf="convo?.channel === 'ZENDESK'"
              src="../../../assets/img/zendesk.png"
              alt="zendesk"
              class="channel-icon me-1 ms-1"
            />
          </div>

          <div *ngIf="countUnreadMessages[convo.id]" class="unread-indicator">
            <span *ngIf="prepareCountUnreadMessages(countUnreadMessages[convo.id])" class="unreadMessage-badge">
              {{ prepareCountUnreadMessages(countUnreadMessages[convo.id]) }}
            </span>
          </div>
        </div>

        <div *ngIf="_user" class="conversation-message" [title]="getLastMessage(convo)">
          <span class="sender-name">{{ getSenderName(convo) }}</span>
          {{ getLastMessage(convo) }}
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <button
      *ngIf="nextToken"
      type="button"
      class="btn btn-secondary"
      (click)="conversationService.loadMoreConversations()"
    >
      {{ 'BUTTON.LOAD_MORE' | translate }}
    </button>
  </div>
</div>
