import { Injectable } from '@angular/core';
import { FetchPolicy } from 'apollo-client';
import { AppsyncOperationService } from '@cation/core/services/appsync/appsync-operation.service';
import { constants, unshiftTeam, updateTeam, deleteTeam } from '@cation/core/util/chat-helper';
import { getTeamsQuery as TeamsQuery } from '@cation/core/graphql/operation-result-types';
import * as mutations from '@cation/core/graphql/mutations';
import * as queries from '@cation/core/graphql/queries';
import Team from '@cation/core/types/team';

@Injectable({
  providedIn: 'root'
})
export class AppsyncTeamService extends AppsyncOperationService {
  loadTeams(observerOrNext, teamLeadId?: string) {
    const watchQueryOptions = {
      query: queries.getTeams,
      fetchPolicy: 'cache-and-network' as FetchPolicy,
      variables: Object.assign({ first: constants.teamsFirst }, teamLeadId ? { teamLeadId } : {})
    };

    const watchQuerySubscriptionOptions = { observerOrNext };

    return this.load<TeamsQuery>({ watchQueryOptions, watchQuerySubscriptionOptions });
  }

  createTeam(input, members, teamLead) {
    this.logService.log('[AppsyncOperationService createTeam]', input);

    const options = {
      mutation: mutations.createTeam,
      variables: { input },

      optimisticResponse: () => ({
        createTeam: {
          ...input,
          __typename: 'Team',
          teamLead: {
            __typename: 'User',
            ...teamLead
          },
          members: members.map(member => ({ ...member, __typename: 'User' }))
        }
      }),

      update: (proxy, { data: { createTeam: _team } }) => {
        const _options = {
          query: queries.getTeams,
          variables: { first: constants.teamsFirst }
        };

        const data = proxy.readQuery(_options);
        const _tmp = unshiftTeam(data, _team);
        proxy.writeQuery({ ..._options, data: _tmp });
      }
    };

    return this.mutate(options);
  }

  updateTeam(input, members, teamLead) {
    this.logService.log('[AppsyncOperationService updateTeam]', input);

    const options = {
      mutation: mutations.updateTeam,
      variables: { input },

      optimisticResponse: () => ({
        updateTeam: {
          ...input,
          __typename: 'Team',
          teamLead: {
            __typename: 'User',
            ...teamLead
          },
          members: members.map(member => ({ ...member, __typename: 'User' }))
        }
      }),

      update: (proxy, { data: { updateTeam: _team } }) => {
        const _options = {
          query: queries.getTeams,
          variables: { first: constants.teamsFirst }
        };

        const data = proxy.readQuery(_options);
        const _tmp = updateTeam(data, _team);
        proxy.writeQuery({ ..._options, data: _tmp });
      }
    };

    return this.mutate(options);
  }

  deleteTeam(team: Team) {
    this.logService.log('[AppsyncOperationService deleteTeam]');

    const options = {
      mutation: mutations.deleteTeam,
      variables: { id: team.id },

      optimisticResponse: () => ({
        deleteTeam: { ...team, __typename: 'Team' }
      }),

      update: (proxy, { data: { deleteTeam: _team } }) => {
        const _options = {
          query: queries.getTeams,
          variables: { first: constants.teamsFirst }
        };

        const data = proxy.readQuery(_options);
        const _tmp = deleteTeam(data, _team);
        proxy.writeQuery({ ..._options, data: _tmp });
      }
    };

    return this.mutate(options);
  }
}
