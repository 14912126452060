<div>
  <div class="row mb-3">
    <div class="btn-group me-3">
      <div class="col">
        <div ngbDropdown class="btn-group mx-1 my-2">
          <button
            ngbDropdownToggle
            id="button-microphone"
            type="button"
            class="btn btn-success"
            title="Toggle microphone"
          >
            <img class="ctn-video-tool" src="assets/img/video-chat/microphone.svg" />
          </button>
          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              *ngFor="let audioInputDevice of this.audioInputDevices"
              (click)="selectAudioInputDevice(audioInputDevice)"
            >
              {{ audioInputDevice.label }}
            </button>
          </div>
        </div>

        <div ngbDropdown class="btn-group mx-1 my-2" role="group" aria-label="Toggle speaker">
          <button ngbDropdownToggle id="button-speaker" type="button" class="btn btn-success" title="Toggle speaker">
            <img class="ctn-video-tool" src="assets/img/video-chat/volume-low.svg" />
          </button>
          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              *ngFor="let audioOutputDevice of this.audioOutputDevices"
              (click)="selectAudioOutputDevice(audioOutputDevice)"
            >
              {{ audioOutputDevice.label }}
            </button>
          </div>
        </div>

        <div ngbDropdown class="btn-group mx-1 my-2" role="group" aria-label="Toggle camera">
          <button ngbDropdownToggle id="button-camera" type="button" class="btn btn-success" title="Toggle camera">
            <img class="ctn-video-tool" src="assets/img/video-chat/video.svg" />
          </button>
          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              *ngFor="let videoInputDevice of this.videoInputDevices"
              (click)="selectVideoInputDevice(videoInputDevice)"
            >
              {{ videoInputDevice.label }}
            </button>
          </div>
        </div>

        <!-- 
        <div ngbDropdown class="btn-group mx-1 my-2" role="group" aria-label="Apply filter">
          <button ngbDropdownToggle id="button-video-filter" type="button" class="btn btn-success" title="Apply filter">
            <img class="ctn-video-tool" src="assets/img/video-chat/contrast.svg" />
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem>Item 1</button>
          </div>
        </div>
        -->

        <div ngbDropdown class="btn-group mx-1 my-2" role="group" aria-label="Toggle content share">
          <button
            ngbDropdownToggle
            id="button-content-share"
            type="button"
            class="btn btn-success"
            title="Toggle content share"
          >
            <img class="ctn-video-tool" src="assets/img/video-chat/camera-slr.svg" />
          </button>
          <div ngbDropdownMenu>
            <button ngbDropdownItem (click)="contentShareStart(ContentShareType.ScreenCapture)">Screen Share</button>
            <!-- 
            <button ngbDropdownItem>Test Video</button>
            <button ngbDropdownItem><input id="content-share-item" type="file" /></button>
            -->
            <button ngbDropdownItem (click)="contentShareStop(ContentShareType.ScreenCapture)">Stop Sharing</button>
          </div>
        </div>
        <!-- 
        <button id="pause-share" type="button" class="btn btn-success mx-1 my-2" title="Pause/unpause content share">
          <img class="btn-group ctn-video-tool" src="assets/img/video-chat/media-pause.svg" />
        </button>
        -->

        <!--
        <button
          id="button-meeting-leave"
          type="button"
          class="btn btn-danger mx-1 my-2"
          title="Leave meeting"
          (click)="leaveMeeting()"
        >
          <img class="btn-group ctn-video-tool" src="assets/img/video-chat/account-logout.svg" />
        </button>
        -->

        <button
          id="button-meeting-end"
          type="button"
          class="btn btn-danger mx-1 my-2"
          title="End meeting"
          (click)="endMeeting()"
        >
          <img class="btn-group ctn-video-tool" src="assets/img/video-chat/power-standby.svg" />
        </button>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <audio class="ctn-meet-audio"></audio>
      <video class="ctn-meet-local-video me-2"></video>
      <video class="ctn-meet-remote-video"></video>
      <video class="ctn-meet-screen-share-video"></video>
    </div>
  </div>
</div>
