<div class="transfers-list">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'CONVERSATION_NOTE_VIEW.TRANSFERS_MODAL.USER_COLUMN_TITLE' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.user?.username }}</td>
    </ng-container>
    <ng-container matColumnDef="next user">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'CONVERSATION_NOTE_VIEW.TRANSFERS_MODAL.NEXT_USER_COLUMN_TITLE' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.nextUser?.username }}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'CONVERSATION_NOTE_VIEW.TRANSFERS_MODAL.DESCRIPTION_COLUMN_TITLE' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>
    <ng-container matColumnDef="created at">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'CONVERSATION_NOTE_VIEW.TRANSFERS_MODAL.CREATED_AT_COLUMN_TITLE' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{ element.createdAt | date: 'short' }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [pageSize]="5" showFirstLastButtons></mat-paginator>
</div>
