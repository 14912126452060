import { StatsFilterOptionEnum } from '../../widget-header/enums/stats-filter-option.enum';
import { statsFilterOptionsMap } from '../stats-filter-options.map';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IUserConversationStatistics } from '@cation/core/types/api';
import { MultiChartModel } from '../models/multi-chart.model';
import { chartColorSchemas } from '@cation/core/constants/chart-color-schema';
import { StatsTypeEnum } from '../enums/stats-type.enum';
import { StatsDataKeyEnum } from '../enums/stats-data-key.enum';

@Component({
  selector: 'app-conversations-stars-widget',
  templateUrl: './conversations-stars-widget.component.html',
  styleUrls: ['./conversations-stars-widget.component.scss']
})
export class ConversationsStarsWidgetComponent {
  statistics: IUserConversationStatistics[];
  @Input() set setStatistics(data: IUserConversationStatistics[]) {
    this.statistics = data;
    this.setTotalValue();
    this.drawChart();
  }

  @Input() selectedDateFilterValue: StatsFilterOptionEnum;

  @Output() onDataFilterChanged = new EventEmitter<StatsFilterOptionEnum>();

  statsTypeEnum = StatsTypeEnum;

  totalValue = 0;

  dateFilterOptions = Array.from(statsFilterOptionsMap.keys());
  chartMultiResult: MultiChartModel[];
  colorScheme = { domain: chartColorSchemas['stars-pallet'] };

  private stars = [1, 2, 3, 4, 5];

  constructor() {}

  onDateFilterChange(value: StatsFilterOptionEnum) {
    this.chartMultiResult = null;
    this.onDataFilterChanged.emit(value);
  }

  private serializeStars(statistics: IUserConversationStatistics[]): MultiChartModel[] {
    const dataKey = StatsDataKeyEnum.conversation;
    const result = [];

    for (const data of statistics) {
      if (data.countConversationsStars) {
        result.push({
          name: data.date,
          series: this.stars.map(star => {
            return {
              name: `${star} ⭐️`,
              value: data[`${dataKey}${star}Stars`] || 0
            };
          })
        });
      }
    }

    return result;
  }

  private drawChart() {
    if (!this.statistics) {
      return;
    }

    this.chartMultiResult = this.serializeStars(this.statistics);
  }

  private setTotalValue() {
    this.totalValue = 0;
    let value = 0;

    if (this.statistics && this.statistics.length) {
      this.statistics.forEach(data => {
        if (data.countConversationsStars) {
          value += Number(data.countConversationsStars);
        }
      });
    }

    this.totalValue = value;
  }
}
