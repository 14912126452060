import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { fadeInOut, listFadeInOut } from '@cation/core/animations';
import { IStatisticsResponse } from '@cation/core/types/api';
import * as _ from 'lodash';

type IStatistics = { [key: string]: IStatisticsResponse & { isLoading?: boolean } };

@Component({
  selector: 'ctn-master-league',
  templateUrl: './master-league.component.html',
  styleUrls: ['./master-league.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInOut, listFadeInOut]
})
export class MasterLeagueComponent implements OnInit {
  userStatistics: IStatistics = {};
  teamStatistics: IStatistics = {};

  errorMessage;

  selectedUserStaticsTab = new UntypedFormControl(0);
  selectedTeamStaticsTab = new UntypedFormControl(0);
  tabs = ['PREV_WEEK', 'PREV_MONTH'];
  mapTabsToPointsKey = {
    PREV_WEEK: 'prevWeeklyPoints',
    PREV_MONTH: 'prevMonthlyPoints'
  };

  constructor(private apiService: ApiService, private logService: LogService, private authHelper: AuthHelper) {}

  ngOnInit() {
    this.getGamificationStatistics('USER', 'PREV_WEEK');
    this.getGamificationStatistics('TEAM', 'PREV_WEEK');

    this.selectedUserStaticsTab.valueChanges.pipe().subscribe(newValue => {
      this.getGamificationStatistics('USER', this.tabs[newValue]);
    });

    this.selectedTeamStaticsTab.valueChanges.pipe().subscribe(newValue => {
      this.getGamificationStatistics('TEAM', this.tabs[newValue]);
    });
  }

  trackStatisticsItem(index, item) {
    return item ? item.memberId : undefined;
  }

  async getGamificationStatistics(type: string, range: string) {
    const statisticsRef = type === 'USER' ? this.userStatistics : this.teamStatistics;

    if (statisticsRef[range]) {
      statisticsRef[range].isLoading = true;
    } else {
      statisticsRef[range] = { isLoading: true, items: [], lastKey: null };
    }

    try {
      const statistics = await this.apiService.getGamificationStatistics(type, range, { limit: 5 });

      statisticsRef[range] = statistics;

      this.logService.log('[getGamificationStatistics]', statistics);
    } catch (e) {
      this.errorMessage = e.message;
      this.logService.error('[getGamificationStatistics Error]', e);
    }

    statisticsRef[range].isLoading = false;
  }

  getContext(item, tab: string, index: number) {
    const pointsField = this.mapTabsToPointsKey[tab];
    const image =
      item.type === 'USER' ? _.get(item.member, 'userData.avatarUrl', item.member.avatarUrl) : item.member.icon;
    const secondImage = item.type === 'USER' ? (image !== item.member.avatarUrl ? item.member.avatarUrl : null) : null;

    return {
      index,
      type: item.type,
      points: item[pointsField],
      prevPoints: item[`prev${_.upperFirst(pointsField)}`] || 0,
      image,
      secondImage,
      name: item.type === 'USER' ? item.member.username : item.member.name,
      team: item.type === 'USER' ? item.member.team : undefined,
      nickname: item.type === 'USER' ? _.get(item.member, 'userData.nickname', '') : '',
      status: item.type === 'USER' ? _.get(item.member, 'userData.status', '') : ''
    };
  }

  isMine(item) {
    const memberId =
      item.type === 'USER' ? this.authHelper.userProfile.cognitoId : _.get(this.authHelper.userProfile, 'team.id');
    return memberId === item.memberId;
  }
}
