import gql from 'graphql-tag';

export default gql`
  subscription subscribeToCreateConversationByQueue($isQueued: String!) {
    subscribeToCreateConversationByQueue(isQueued: $isQueued) {
      __typename
      id
      intentsTriggered
      name
      caseId
      createdAt
      createdBy
      history
      locale
      location
      sentiment
      keywords
      status
      channel
      lastQuestion
      author {
        cognitoId
        username
        userData
      }
    }
  }
`;
