<div
  class="card ctn-scroll chat p-2"
  [class.chat__closed]="isConversationClosed"
  [class.chat__flexible]="isFlexibleLayout"
  [class.chat__full-screen]="fullScreen"
  [class.chat__read_only]="readOnly"
>
  <mat-progress-bar *ngIf="isHistoryLoading" [mode]="'indeterminate'"></mat-progress-bar>
  <ng-container *ngIf="!!conversationHistory.length">
    <div class="history-container rounded">
      <app-chat-message
        *ngFor="
          let message of conversationHistory;
          let i = index;
          trackBy: trackMessage;
          last as isLast;
          first as isFirst
        "
        [index]="conversationHistory.length - (i + 1)"
        [senderId]="senderId"
        [message]="message"
        [fromBot]="fromBot(message)"
        [fromMe]="fromBot(message)"
        [fromHistory]="true"
        [isLast]="isLast"
        [isFirst]="isFirst"
        [conversation]="conversation"
      >
      </app-chat-message>
    </div>
  </ng-container>
</div>
