<mat-nav-list>
  <h6 class="text-center">
    {{ 'AI_BLOCK.BOTTOM_SHEET_FILTER.TITLE' | translate }}
  </h6>

  <a mat-list-item class="h-100">
    <mat-form-field class="w-100">
      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let keyword of keywords"
          [selectable]="selectable"
          [removable]="removable"
          (removed)="remove(keyword)"
        >
          {{ keyword }}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
          #keywordInput
          [placeholder]="'AI_BLOCK.BOTTOM_SHEET_FILTER.KEYWORDS_INPUT_PLACEHOLDER' | translate"
          [formControl]="keywordCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="addOnBlur"
          (matChipInputTokenEnd)="add($event)"
        />
      </mat-chip-list>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option *ngFor="let keyword of filteredKeywords | async" [value]="keyword">
          {{ keyword }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </a>

  <a mat-list-item (click)="picker.open()">
    <mat-form-field class="w-100">
      <input
        matInput
        [placeholder]="'AI_BLOCK.BOTTOM_SHEET_FILTER.DATE_PICKER_PLACEHOLDER' | translate"
        [satDatepicker]="picker"
        [(ngModel)]="date"
        (dateChange)="onDateChange()"
      />
      <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
      <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
    </mat-form-field>
  </a>

  <a mat-list-item class="text-center d-flex justify-content-center">
    <button mat-raised-button color="primary" (click)="onSetFilter()">
      {{ 'BUTTON.SET_FILTERS' | translate }}
    </button>
  </a>
</mat-nav-list>
