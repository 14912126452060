import { Injectable, Inject } from '@angular/core';
import { LogLevel } from '../enums/logger.enum';
import { EnvironmentOptionsService } from './environment-options.service';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private productionMode: boolean;
  protected level: LogLevel = this.options ? LogLevel.Error : LogLevel.All;
  private styles = {
    [LogLevel.All]: '',
    [LogLevel.Debug]: 'color: blue; font-weight: bold;',
    [LogLevel.Info]: 'color: lightblue;',
    [LogLevel.Warn]: 'color: orange; font-weight: bold;',
    [LogLevel.Error]: 'color: red;'
  };
  constructor(@Inject(EnvironmentOptionsService) private options) {
    this.info('-------Internal Chat Log Service--------');
    console.log(options);
  }
  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  private writeToLog(msg, level: LogLevel, optionalParams) {
    if (!this.shouldLog(level)) {
      return;
    }

    console.log(`%c${msg}`, this.styles[level], ...optionalParams);
  }

  private shouldLog(level: LogLevel): boolean {
    return level >= this.level || this.level === LogLevel.All;
  }
}
