import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-preview-audit-meta-data-dialog',
  templateUrl: './preview-audit-meta-data-dialog.component.html',
  styleUrls: ['./preview-audit-meta-data-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewAuditMetaDataDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: object,
    public dialogRef: MatDialogRef<PreviewAuditMetaDataDialogComponent>
  ) {}
}
