import gql from 'graphql-tag';

export default gql`
  query getAgents($cognitoId: ID!) {
    getUserByCognitoId(cognitoId: $cognitoId) {
      __typename
      cognitoId
      username
      registered
      keywords
      cmsUserData
      userData
      teamId
      locales
      roles
      avatarUrl
      voiceUsername
    }
  }
`;
