import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "@cation/core/services/api/api.service";
import { ApiHook } from "@cation/core/services/api/api-hook";
import { AuthHelper } from "@cation/core/auth/auth-helper";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"]
})
export class ReportsComponent implements OnInit {
  public quickSightUrl;

  constructor(
    private api: ApiService,
    public apiHook: ApiHook,
    private route: ActivatedRoute,
    public auth: AuthHelper
  ) {}

  ngOnInit() {
    this.route.params.subscribe(async params => {
      console.log("route-params", params);

      // Clear the current report while switching report
      this.quickSightUrl = null;

      let reportName = params.reportName;
      console.log("ngOnInit: this.quickSightUrl", this.quickSightUrl);
      console.log("ngOnInit: this.reportName", reportName);

      let data: any = await this.api.getReportEmbedUrl(reportName).toPromise();
      console.log("quickSight:data", data);
      this.quickSightUrl = data.EmbedUrl;
    });
  }
}
