<div class="conversations-statistics mb-2">
  <mat-card class="h-100">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.CONVERSATIONS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <div class="conversations-statistics_chart-parent">
        <ngx-charts-pie-chart
          [scheme]="colorScheme"
          [results]="totalAndDuration"
          [gradient]="true"
          [legend]="true"
          [legendTitle]="'MY_STATISTICS.LEGEND_TITLE' | translate"
          [labels]="false"
          legendPosition="below"
          style="height: 150px;"
        >
        </ngx-charts-pie-chart>
      </div>
    </mat-card-content>
  </mat-card>
</div>
