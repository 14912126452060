/* tslint:disable */
//  This file was automatically generated and should not be edited.

export type createConversationMutationVariables = {
  id: string;
  name: string;
  subject: string;
  createdAt?: string | null;
  keywords: Array<string>;
  locale: string;
  intentsTriggered: Array<string>;
  botSessionId?: string;
  author?: any;
};

export type createConversationMutation = {
  // Create a Conversation. Use some of the cooked in template functions for UUID and DateTime.
  createConversation: {
    __typename: 'Conversation';
    // A unique identifier for the Conversation.
    id: string;
    // The Conversation's name.
    name: string;
    // The Conversation's subject.
    subject: string;
    // The Conversation's timestamp.
    createdAt: string | null;

    keywords: Array<string>;
    locale: string;
    location: any;
    sentiment: string;
    intentsTriggered: Array<string>;
    botSessionId?: string;
    author?: any;
  } | null;
};

export type createMessageMutationVariables = {
  id: string;
  content?: string | null;
  conversationId: string;
  createdAt: string;
};

export type createMessageMutation = {
  // Create a message in a Conversation.
  createMessage: {
    __typename: 'Message';
    // The id of the Conversation this message belongs to. This is the table primary key.
    conversationId: string;
    // The message timestamp. This is also the table sort key.
    createdAt: string | null;
    // Generated id for a message -- read-only
    id: string;
    sender: string | null;
    // The message content.
    content: string;
    // Flag denoting if this message has been accepted by the server or not.
    isSent: boolean | null;
    isRead: boolean | null;
    isQuestion: boolean | null;
  } | null;
};

export type createUserMutationVariables = {
  username: string;
};

export type createUserMutation = {
  // Put a single value of type 'User'. If an item does not exist with the same key the item will be created. If there exists an item at that key already, it will be updated.
  createUser: {
    __typename: 'User';
    // A unique identifier for the user.
    cognitoId: string;
    // The username
    username: string;
    // Generated id for a user. read-only
    // id: string,
  } | null;
};

export type createUserConversationsMutationVariables = {
  conversationId: string;
  userId: string;
};

export type createUserConversationsMutation = {
  // Put a single value of type 'UserConversations'. If an item does not exist with the same key the item will be created. If there exists an item at that key already, it will be updated.
  createUserConversations: {
    __typename: 'UserConversations';
    userId: string;
    conversationId: string;
    conversation: {
      __typename: 'Conversation';
      // A unique identifier for the Conversation.
      id: string;
      caseId: string;
      // The Conversation's name.
      name: string;
      // The Conversation's subject.
      subject: string;
      channel: string;
      location: any;
      sentiment: string;
    } | null;
  } | null;
};

export type getAllUsersQuery = {
  // Scan through all values of type 'User'. Use the 'after' and 'before' arguments with the 'nextToken' returned by the 'UserConnection' result to fetch pages.
  allUser: Array<{
    __typename: 'User';
    // Generated id for a user. read-only
    // id: string,
    // A unique identifier for the user.
    cognitoId: string;
    // The username
    username: string;
    // registered?
    registered: string;

    avatarUrl: string;
    locales: string[];
    keywords: string[];
    roles: string[];
  } | null> | null;
};

export type getAgentUserVariables = {
  cognitoId: string;
};

export type getAgentUserQuery = {
  getUserByCognitoId: {
    __typename: 'User';
    // id: string,
    username: string;
    avatarUrl: string;
    locales: Array<string>;
    keywords: Array<string>;
  } | null;
};

export type getConversationMessagesQueryVariables = {
  conversationId: string;
  after?: string | null;
  first?: number | null;
};

export type getConversationMessagesQuery = {
  // Scan through all values of type 'MessageConnection'. Use the 'after' and 'before' arguments with the 'nextToken' returned by the 'MessageConnectionConnection' result to fetch pages.
  allMessageConnection: {
    __typename: 'MessageConnection';
    nextToken: string | null;
    messages: Array<{
      __typename: 'Message';
      // Generated id for a message -- read-only
      id: string;
      // The id of the Conversation this message belongs to. This is the table primary key.
      conversationId: string;
      // The message content.
      content: string;
      // The message timestamp. This is also the table sort key.
      createdAt: string | null;
      sender: string | null;
      // Flag denoting if this message has been accepted by the server or not.
      isSent: boolean | null;
      isRead: boolean | null;
      isQuestion: boolean | null;
      attachment: ConversationAttachment | null;
    } | null> | null;
  } | null;
};

export type getConversationsByCustomerIdQuery = {
  // Scan through all values of type 'MessageConnection'. Use the 'after' and 'before' arguments with the 'nextToken' returned by the 'MessageConnectionConnection' result to fetch pages.
  getConversationsByCustomerId: {
    __typename: 'ListConversations';
    nextToken: string | null;
    conversations: Array<{
      __typename: 'Conversation';
      id: string;
      name: string;
      caseId: string;
      subject: string;
      channel: string;
      location: any;
      createdAt: string;
      createdBy: string;
      history: Array<{
        actor: 'USER' | 'BOT';
        actorName: string;
        message: string;
        timestamp: string;
      }> | null;
      sentiment: string;
      avatarUrl?: string;
      lastQuestion?: string;
      keywords?: Array<string>;
      locales?: Array<string>;
      intentsTriggered?: Array<string>;
      status: string;
    } | null> | null;
  } | null;
};

export type getConversationNotesQuery = {
  // Scan through all values of type 'MessageConnection'. Use the 'after' and 'before' arguments with the 'nextToken' returned by the 'MessageConnectionConnection' result to fetch pages.
  getConversationNotes: {
    __typename: 'ListConversationNotes';
    nextToken: string | null;
    notes: Array<{
      __typename: 'ConversationNote';
      // Generated id for a message -- read-only
      id: string;
      // The id of the Conversation this message belongs to. This is the table primary key.
      conversationId: string;
      // The message content.
      content: string;
      createdBy: string;
      author: userFragment;
    } | null> | null;
  } | null;
};

export type getUserNotificationsQuery = {
  getUserNotifications: {
    __typename: 'ListUserNotifications';
    nextToken: string | null;
    userNotifications: Array<{
      __typename: 'UserNotification';
      id: string;
      userId: string;
      isRead: number;
      icon: string;
      title: string;
      description: string;
    } | null> | null;
  } | null;
};

export type getTeamsQuery = {
  // Scan through all values of type 'MessageConnection'. Use the 'after' and 'before' arguments with the 'nextToken' returned by the 'MessageConnectionConnection' result to fetch pages.
  getTeams: {
    __typename: 'ListTeams';
    nextToken: string | null;
    teams: Array<{
      __typename: 'Team';
      id: string;
      icon: string;
      description: string;
      teamLeadId: string;
      name: string;
      teamLead: userFragment;
    } | null> | null;
  } | null;
};

export type getConversationQueuesQuery = {
  getConversationQueues: Array<{
    __typename: 'ConversationQueue';
    key: string;
    count: number;
    lastWaitingTime: number;
    updatedAt: number;
  } | null> | null;
};

export type getUsersConversationQueueHistoryQuery = {
  getUsersConversationQueueHistory: {
    __typename: 'ListConversationQueueHistory';
    nextToken: string | null;
    items: Array<{
      __typename: 'ConversationQueueHistory';
      userId: string;
      conversationId: string;
      agentId: string;
      startedAt: number;
      finishedAt: number;
      agent: userFragment;
      conversation: {
        __typename: 'Conversation';
        id: string;
        name: string;
        caseId: string;
        subject: string;
        channel: string;
        location: any;
        createdAt: string;
        createdBy: string;
        history: Array<{
          actor: 'USER' | 'BOT';
          actorName: string;
          message: string;
          timestamp: string;
        }> | null;
        sentiment: string;
        lastQuestion?: string;
        keywords?: Array<string>;
        locales?: Array<string>;
        intentsTriggered?: Array<string>;
        status: string;
      };
    } | null> | null;
  };
};

export type getUserConversationConnectionThroughUserQueryVariables = {
  after?: string | null;
  first?: number | null;
};

export type getUserConversationConnectionThroughUserQuery = {
  // Get my user.
  me: {
    // A user's enrolled Conversations. This is an interesting case. This is an interesting pagination case.
    conversations: {
      __typename: 'UserConversationsConnection';
      nextToken: string | null;
      cognitoId?: string;
      userConversations: Array<{
        __typename: 'UserConversations';
        conversationId: string;
        associated: {
          __typename: 'UserConversations';
          userId: string;
          user: {
            __typename: 'User';
            // Generated id for a user. read-only
            // id: string,
            // A unique identifier for the user.
            cognitoId: string;
            // The username
            username: string;
            // registered?
            registered: string;

            avatarUrl: string;
            userData: any;
            cmsUserData: string[];
            keywords: string[];
            locales: string[];
            roles: string[];
          };
          typing: boolean;
          connected: string;
        }[];
        conversation: {
          __typename: 'Conversation';
          // A unique identifier for the Conversation.
          id: string;
          // The Conversation's name.
          name: string;
          caseId: string;
          // The Conversation's subject.
          subject: string;
          channel: string;
          location: any;
          sentiment: string;
          avatarUrl?: string;
          lastQuestion?: string;
          history: Array<{
            actor: 'USER' | 'BOT';
            actorName: string;
            message: string;
            timestamp: string;
          }> | null;
          keywords?: Array<string>;
          locales?: Array<string>;
          intentsTriggered?: Array<string>;
          botSessionId?: string;
          author?: userFragment;
          status: string;
          cms: Array<UserConversationsCMS>;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type getUserConversationConnectionByCognitoIdThroughUserQuery = {
  // Get my user.
  getUserByCognitoId: {
    // A user's enrolled Conversations. This is an interesting case. This is an interesting pagination case.
    conversations: {
      __typename: 'UserConversationsConnection';
      nextToken: string | null;
      cognitoId?: string;
      userConversations: Array<{
        __typename: 'UserConversations';
        conversationId: string;
        associated: {
          __typename: 'UserConversations';
          userId: string;
          user: {
            __typename: 'User';
            // Generated id for a user. read-only
            // id: string,
            // A unique identifier for the user.
            cognitoId: string;
            // The username
            username: string;
            // registered?
            registered: string;

            avatarUrl: string;
            userData: any;
            cmsUserData: string[];
            keywords: string[];
            locales: string[];
            roles: string[];
          };
          typing: boolean;
          connected: string;
        }[];
        conversation: {
          __typename: 'Conversation';
          // A unique identifier for the Conversation.
          id: string;
          // The Conversation's name.
          name: string;
          caseId: string;
          // The Conversation's subject.
          subject: string;
          channel: string;
          location: any;
          sentiment: string;
          avatarUrl?: string;
          lastQuestion?: string;
          history: Array<{
            actor: 'USER' | 'BOT';
            actorName: string;
            message: string;
            timestamp: string;
          }> | null;
          keywords?: Array<string>;
          locales?: Array<string>;
          intentsTriggered?: Array<string>;
          botSessionId?: string;
          author?: userFragment;
          status: string;
          cms: Array<UserConversationsCMS>;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type getConversationsInQueueQuery = {
  getConversationsInQueue: {
    __typename: 'ListConversations';
    nextToken: string | null;
    conversations: Array<{
      __typename: 'Conversation';
      // A unique identifier for the Conversation.
      id: string;
      // The Conversation's name.
      name: string;
      caseId: string;
      // The Conversation's subject.
      subject: string;
      channel: string;
      location: any;
      sentiment: string;
      avatarUrl?: string;
      lastQuestion?: string;
      history: Array<{
        actor: 'USER' | 'BOT';
        actorName: string;
        message: string;
        timestamp: string;
      }> | null;
      keywords?: Array<string>;
      locales?: Array<string>;
      intentsTriggered?: Array<string>;
      botSessionId?: string;
      author?: userFragment;
      status: string;
      cms: Array<UserConversationsCMS>;
    } | null>;
  } | null;
};

export type getUserConversationConnectionByCognitoIdQuery = {
  // Get my user.
  getUserByCognitoId: {
    // A user's enrolled Conversations. This is an interesting case. This is an interesting pagination case.
    conversations: {
      __typename: 'UserConversationsConnection';
      nextToken: string | null;
      cognitoId?: string;
      userConversations: Array<{
        __typename: 'UserConversations';
        conversationId: string;
        associated: {
          __typename: 'UserConversations';
          userId: string;
          user: {
            __typename: 'User';
            // Generated id for a user. read-only
            // id: string,
            // A unique identifier for the user.
            cognitoId: string;
            // The username
            username: string;
            // registered?
            registered: string;

            avatarUrl: string;
            userData: any;
            cmsUserData: string[];
            keywords: string[];
            locales: string[];
            roles: string[];
          };
          typing: boolean;
          connected: string;
        }[];
        conversation: {
          __typename: 'Conversation';
          // A unique identifier for the Conversation.
          id: string;
          // The Conversation's name.
          name: string;
          caseId: string;
          // The Conversation's subject.
          subject: string;
          channel: string;
          location: any;
          sentiment: string;
          avatarUrl?: string;
          lastQuestion?: string;
          history: Array<{
            actor: 'USER' | 'BOT';
            actorName: string;
            message: string;
            timestamp: string;
          }> | null;
          keywords?: Array<string>;
          locales?: Array<string>;
          intentsTriggered?: Array<string>;
          botSessionId?: string;
          author?: userFragment;
          status: string;
          cms: Array<UserConversationsCMS>;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};

export type getUsersByTeamIdQuery = {
  // Scan through all values of type 'MessageConnection'. Use the 'after' and 'before' arguments with the 'nextToken' returned by the 'MessageConnectionConnection' result to fetch pages.
  getUsersByTeamId: {
    __typename: 'ListUsersByTeamId';
    nextToken: string | null;
    users:
      | {
          __typename: 'User';
          // Generated id for a user. read-only
          // id: string,
          // A unique identifier for the user.
          cognitoId: string;
          // The username
          username: string;
          // registered?
          registered: string;

          avatarUrl: string;
          userData: any;
          cmsUserData: string[];
          keywords: string[];
          locales: string[];
          roles: string[];
        }[]
      | null;
  } | null;
};

export type ConversationAttachment = {
  key: string;
  type?: string;
  size: number;
  name: string;
};

export type UserConversationsCMS = {
  id: string;
  conversationId: string;
  isCore: boolean;
  type: string;
};

export type subscribeToNewMessageSubscriptionVariables = {
  conversationId: string;
};

export type subscribeToNewMessageSubscription = {
  // Subscribes to all new messages in a given Conversation.
  subscribeToNewMessage: {
    __typename: 'Message';
    // The id of the Conversation this message belongs to. This is the table primary key.
    conversationId: string;
    // The message timestamp. This is also the table sort key.
    createdAt: string | null;
    // Generated id for a message -- read-only
    id: string;
    sender: string | null;
    // The message content.
    content: string;
    // Flag denoting if this message has been accepted by the server or not.
    isSent: boolean | null;
    isRead: boolean | null;
    isQuestion: boolean | null;
    attachment: ConversationAttachment | null;
  } | null;
};

export type userFragment = {
  // A unique identifier for the user.
  cognitoId: string;
  // The username
  username: string;
  userData: any;

  registered: string;
  cmsUserData: string[];
  avatarUrl: string;
  keywords: string[];
  locales: string[];
  roles: string[];
};
