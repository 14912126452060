import User from '@cation/core/types/user';

export type FeedBackStatus = 'PENDING' | 'REJECTED' | 'APPROVED';

export interface IFeedBackCommentCommon {
  id: string;
  index: string;
  createdAt: string;
  message: string;
  customerId: string;
  agentId: string;
  conversationId: string;
  points?: number;
  rating: number;
  status: FeedBackStatus;
}

export interface IFeedBackComment extends IFeedBackCommentCommon {
  customer: User;
  agent: User;
}

export interface IFeedBackResponse {
  total: number;
  comments: IFeedBackComment[];
}
