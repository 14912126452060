<section>
  <div class="header">
    <button type="button" class="btn" (click)="toChallenge()">
      {{ 'CHALLENGES.NEW_CHALLENGE' | translate }}
    </button>
    <div class="ctn-title">
      <h1 class="title">{{ 'CHALLENGES.TITLE' | translate }}</h1>
    </div>

    <div class="widget-select">
      <mat-form-field>
        <mat-select [(ngModel)]="selectedTab" name="tabFilter" (selectionChange)="onTabChange($event)">
          <mat-option *ngFor="let tab of tabs" [value]="tab">
            {{ 'CHALLENGES.' + tab + '_TAB_TITLE' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="ctn-content">
    <div *ngIf="initLoading" class="loader" [@fadeInOut]>
      <mat-spinner mode="indeterminate"></mat-spinner>
    </div>
    <app-challenges-table
      [selectedTab]="selectedTab"
      [challenges]="challenges[selectedTab].items"
      [isLoading]="challenges[selectedTab].isLoading"
      [isLoadMore]="!!challenges[selectedTab].lastKey"
      (onMakeWager)="toMakeWager($event)"
      (onChangeChallengeStatus)="changeChallengeStatus($event)"
      (onLoadMore)="getGamificationChallenges(selectedTab, true)"
    >
    </app-challenges-table>
  </div>
</section>
