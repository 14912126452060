import { Route } from '@angular/router';
import { Role } from '@cation/core/auth/role.enum';
import { RouteDataModel } from '@cation/core/models/route-data.model';
import { ConversationQueueComponent } from '../../components/conversation-queue/conversation-queue.component';
import { ConversationSearchComponent } from '../../components/conversation-search/conversation-search.component';

export const conversationsRoutesConfig: Route[] = [
  {
    path: 'search',
    component: ConversationSearchComponent,
    data: {
      roles: [Role.ADMIN],
      label: 'NAVBAR.SEARCH_CONVERSATIONS_LINK',
    } as RouteDataModel,
  },
  {
    path: 'queue',
    component: ConversationQueueComponent,
    data: {
      roles: [Role.ADMIN],
      label: 'NAVBAR.CONVERSATION_QUEUE_LINK',
    } as RouteDataModel,
  },
];
