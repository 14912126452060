<div class="internal-topbar">
  <div class="internal-topbar_left">
    <div class="internal-topbar_title-container">
      <internal-chat-inline-edit
        *ngIf="route === _routes.CHAT"
        [maxLength]="25"
        name="chat-name"
        [value]="title"
        (change)="editChatName($any($event.target).value)"
      ></internal-chat-inline-edit>
    </div>
    <mat-expansion-panel #expansionPanel>
      <mat-expansion-panel-header [collapsedHeight]="'20px'" [expandedHeight]="'20px'">
        <mat-panel-title>
          <mat-icon>people_outline</mat-icon>
          {{ connectedAgents }} Users
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngFor="let user of users">
        <div class="internal-chat-history-user-panel" *ngIf="user.connected === 'true' && user.user.id !== me.id">
          <div class="internal-chat-expansion-avatar">
            <internal-chat-s3-image [image]="user.user.avatarUrl"></internal-chat-s3-image>
          </div>
          <p>{{ user.user.name }}</p>
        </div>
      </ng-container>
    </mat-expansion-panel>
  </div>

  <action-button *ngIf="allowLeaveChat()" class="btn-action" [label]="'Leave'" (clickEvent)="leaveChat()">
  </action-button>
</div>
