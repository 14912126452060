import gql from 'graphql-tag';

export default gql`
  mutation createUserConversations($conversationId: ID!, $userId: ID!) {
    createUserConversations(conversationId: $conversationId, userId: $userId) {
      __typename
      userId
      conversationId
      conversation {
        __typename
        id
        name
        subject
      }
      associated {
        __typename
        conversationId
        userId
        user {
          __typename
          cognitoId
          username
          registered
          avatarUrl
        }
        typing
        connected
      }
    }
  }
`;
