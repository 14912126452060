<app-navbar></app-navbar>

<app-sidebar *ngIf="isLoggedIn"></app-sidebar>

<main role="main" class="no-select main" [class.main_margin-left]="isLoggedIn">
  <router-outlet></router-outlet>
</main>

<div class="chat-button">
  <internal-chat
    *ngIf="isInternalChatEnabled && isLoggedIn && auth.isRegistered"
    [id]="auth.userProfile.cognitoId"
    [name]="auth.userProfile.username"
    [avatarUrl]="auth.userProfile?.userData?.avatarUrl || auth.userProfile.avatarUrl"
  ></internal-chat>
</div>

<!-- <app-footer></app-footer> -->
