<section>
  <app-widget-header
    [title]="statsTypeEnum.conversations | translate"
    [totalValue]="totalValue"
    [isChartTypeFilter]="true"
    [selectedChartTypeFilterValue]="selectedChartTypeFilterValue"
    [chartTypeFilterOptions]="chartTypeFilterOptions"
    [isDateTypeFilter]="true"
    [selectedDateFilterValue]="selectedDateFilterValue"
    [dateFilterOptions]="dateFilterOptions"
    (onChartTypeFilterChange)="onChartTypeFilterChange($event)"
    (onDateFilterChange)="onDateFilterChange($event)"
  >
  </app-widget-header>

  <div class="ctn-content">
    <div *ngIf="isLoading()" class="loader">{{ 'GENERAL.LOADING' | translate }}...</div>
    <div *ngIf="isEmptyStatistics()" class="no-statistics">{{ 'WIDGET.NO_STATISTICS' | translate }}</div>

    <div
      *ngIf="[statsFilterOptionEnum.locale, statsFilterOptionEnum.channels].includes(selectedChartTypeFilterValue)"
      class="ctn-widget-chart"
    >
      <ngx-charts-pie-chart
        *ngIf="selectedDateFilterValue === statsFilterOptionEnum.today; else lineChart"
        [scheme]="colorScheme"
        [doughnut]="true"
        [results]="chartSingleResult"
        [legend]="true"
        [legendPosition]="'right'"
        [labels]="true"
        [labelFormatting]="labelPieFormatting"
      >
      </ngx-charts-pie-chart>
      <ng-template #lineChart>
        <ngx-charts-line-chart
          [scheme]="colorScheme"
          [legend]="true"
          [legendPosition]="'right'"
          [xAxis]="true"
          [yAxis]="true"
          [timeline]="true"
          [results]="chartMultiResult"
          [curve]="curve"
        >
          <ng-template #tooltipTemplate let-model="model">
            <span
              ><strong>{{ model.series }}</strong> &bull; {{ model.name }}</span
            >
            <br />
            <span>{{ model.value }}</span>
          </ng-template>
        </ngx-charts-line-chart>
      </ng-template>
    </div>

    <div
      *ngIf="statsFilterOptionEnum.summary === selectedChartTypeFilterValue"
      class="ctn-widget-chart"
      [ngClass]="'bar' + chartSingleResult?.length"
    >
      <ngx-charts-bar-vertical
        [scheme]="color"
        [results]="chartSingleResult"
        [xAxis]="true"
        [yAxis]="true"
        [gradient]="true"
      >
      </ngx-charts-bar-vertical>
    </div>
  </div>
</section>
