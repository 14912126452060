import { UserListItemModel } from '../../models/user-list-item.model';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent {
  private inputItems = [];
  private sourceItems = [];
  private sum = 0;
  private filterValue = '';
  public items = [];

  @Input() set setItems(array: UserListItemModel[]) {
    this.inputItems = array;
    this.addItems(this.filterValue);
  }

  @Input() set setSearchValue(value: string) {
    this.filterValue = value;
    this.addItems(this.filterValue);
  }

  @Input() set deleteUserFromList(uid: string) {
    if (uid) {
      this.deleteFromList(uid);
      uid = '';
    }
  }

  @Output() onDelete = new EventEmitter<UserListItemModel>();
  @Output() onItemClick = new EventEmitter<UserListItemModel>();

  constructor() {}

  private deleteFromList(uid) {
    for (let i = 0; i < this.inputItems.length; i++) {
      if (this.inputItems[i].uid === uid) {
        this.inputItems.splice(i, 1);
        break;
      }
    }
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].uid === uid) {
        this.items.splice(i, 1);
        break;
      }
    }

    this.appendItems(this.sum - 1, this.sum);
  }

  private addItems(filterValue) {
    this.sum = 40;
    this.items = [];

    if (filterValue) {
      this.sourceItems = this.filterItems(this.inputItems, filterValue);
    } else {
      this.sourceItems = this.inputItems;
    }

    this.appendItems(0, this.sum);
  }

  private appendItems(startIndex, endIndex) {
    this.items.push(...this.sourceItems.slice(startIndex, endIndex));
  }

  onDeleteEvent(user: UserListItemModel) {
    this.onDelete.emit(user);
  }

  onItemEvent(user: UserListItemModel) {
    this.onItemClick.emit(user);
  }

  onScrollDown() {
    const start = this.sum;
    this.sum += 20;
    this.appendItems(start, this.sum);
  }

  private filterItems(items: UserListItemModel[], value: string): UserListItemModel[] {
    return items.filter((item: UserListItemModel) => {
      for (const property in item) {
        if (typeof item[property] === 'string' && item[property].indexOf(value) !== -1) {
          return true;
        }
      }
      return false;
    });
  }
}
