<div class="user-input-autocomplete">
  <mat-form-field class="ctn-full-width">
    <mat-select
      #singleSelect
      class="p-0"
      [required]="required"
      [formControl]="userCtrl"
      [placeholder]="placeholder | translate"
    >
      <mat-option>
        <ngx-mat-select-search [formControl]="usersFilterCtrl" [placeholderLabel]="placeholderLabel | translate">
          <mat-icon ngxMatSelectSearchClear *ngIf="!isFinding">close</mat-icon>
          <mat-icon ngxMatSelectSearchClear *ngIf="isFinding">
            <mat-progress-spinner matSuffix mode="indeterminate" diameter="23"></mat-progress-spinner>
          </mat-icon>
        </ngx-mat-select-search>
      </mat-option>

      <mat-option [value]="allOptionValue" *ngIf="allOptionValue && allOptionTitle">
        <div class="d-flex justify-content-center align-items-center ps-1">
          {{ allOptionTitle | translate }}
        </div>
      </mat-option>
      <mat-option *ngFor="let user of filteredUsers | async; trackBy: trackItem" [value]="user">
        <div class="d-flex align-items-center">
          <app-user-template [user]="user" tooltipField="userData.status"></app-user-template>
        </div>
      </mat-option>
    </mat-select>
    <button
      mat-button
      *ngIf="userCtrl.value"
      matSuffix
      mat-icon-button
      [attr.aria-label]="'BUTTON.CLEAR' | translate"
      (click)="resetUser($event)"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-error *ngIf="errorLabel">{{ errorLabel | translate }}</mat-error>
  </mat-form-field>

  <mat-progress-bar class="m-2" *ngIf="isLoading" [mode]="'indeterminate'" [@fadeInOut]></mat-progress-bar>
</div>
