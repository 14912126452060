import gql from 'graphql-tag';

export default gql`
  subscription subscribeToUpdateChatName($id: ID!) {
    subscribeToUpdateChatName(id: $id) {
      __typename
      id
      name
      createdAt
      createdBy
      author {
        __typename
        id
        name
        avatarUrl
      }
    }
  }
`;
