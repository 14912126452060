import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { LogService } from '@cation/core/services/log/log.service';
import { AppSyncApiService } from '@cation/core/services/api/appsync-api.service';
import User from '@cation/core/types/user';

@Component({
  selector: 'app-user-input-autocomplete',
  templateUrl: './user-input-autocomplete.component.html',
  styleUrls: ['./user-input-autocomplete.component.scss']
})
export class UserInputAutocompleteComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() hint: string = 'USER_INPUT.HINT';
  @Input() errorLabel: string;
  @Input() allOptionValue: { cognitoId: string };
  @Input() allOptionTitle: string;
  @Input() placeholder: string = 'USER_INPUT.PLACEHOLDER';
  @Input() placeholderLabel: string = 'USER_INPUT.INPUT_PLACEHOLDER';
  @Input() initialUserId: string;
  @Input() required = false;

  @Output() selectionChange = new EventEmitter<User>();
  @Output() usersLoad = new EventEmitter<User[]>();

  public isLoading = false;
  public isFinding = false;
  public users: User[] = [];
  /** control for the selected team leads */
  public userCtrl: UntypedFormControl = new UntypedFormControl();
  /** control for the MatSelect filter keyword */
  public usersFilterCtrl: UntypedFormControl = new UntypedFormControl();
  /** list of users filtered by search keyword */
  public filteredUsers: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(private logService: LogService, private appSyncApi: AppSyncApiService) {}

  async ngOnInit() {
    this.usersLoad.emit(this.users);

    await this.searchUsers('', false);

    if (this.initialUserId) {
      this.userCtrl.setValue(this.users.find(t => t.cognitoId === this.initialUserId));
    }

    this.filteredUsers.next(this.users.slice() || []);

    this.userCtrl.valueChanges.subscribe(v => this.selectionChange.emit(v));

    this.usersFilterCtrl.valueChanges
      .pipe(
        debounceTime(200),
        takeUntil(this._onDestroy)
      )
      .subscribe(async v => {
        await this.searchUsers(v);
        return this.filterUsers();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public trackItem(index, item) {
    return item ? item.cognitoId : undefined;
  }

  protected setInitialValue() {
    this.filteredUsers
      .pipe(
        take(1),
        takeUntil(this._onDestroy)
      )
      .subscribe(() => {
        this.singleSelect.compareWith = (a, b) => a && b && a.cognitoId === b.cognitoId;
      });
  }

  protected filterUsers() {
    let search = this.usersFilterCtrl.value;
    if (!search) {
      this.filteredUsers.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUsers.next(this.users.filter(user => user.username.toLowerCase().includes(search)));
  }

  async searchUsers(filter = '', isSilent = true) {
    this.isFinding = true;
    try {
      const users = await this.appSyncApi.searchUsers(filter.toLowerCase(), isSilent);

      if (this.isAddSelectedItem(users)) {
        users.unshift(this.userCtrl.value);
      }

      this.users = users;

      this.logService.log('[searchUsers:users]', this.users);
    } catch (e) {
      this.logService.error('[searchUsers:error]', e);
    }
    this.isFinding = false;
  }

  resetUser($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.userCtrl.setValue(null);
  }

  isAddSelectedItem(users) {
    return this.userCtrl.value && users.findIndex(u => u.cognitoId === this.userCtrl.value.cognitoId) < 0;
  }
}
