import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import Conversation from '@cation/core/types/conversation';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppsyncService } from '@cation/core/services/appsync/appsync.service';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { ConversationService } from '@cation/core/services/conversation/conversation.service';

interface IEscalateConversationInputData {
  conversation: Conversation;
  onCloseConversation: EventEmitter<void>;
}

@Component({
  selector: 'app-escalate-conversation-dialog',
  templateUrl: './escalate-conversation-dialog.component.html',
  styleUrls: ['./escalate-conversation-dialog.component.scss']
})
export class EscalateConversationDialogComponent implements OnInit {
  loaderId = 'escalate-conversation-dialog-loader';
  isAgentsLoading;

  nextUserFormGroup: UntypedFormGroup;
  descriptionFormGroup: UntypedFormGroup;

  errorMessage;

  messagesData = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IEscalateConversationInputData,
    public dialogRef: MatDialogRef<EscalateConversationDialogComponent>,
    private ngxLoader: NgxUiLoaderService,
    private formBuilder: UntypedFormBuilder,
    private api: ApiService,
    private auth: AuthHelper,
    private logService: LogService,
    private appsync: AppsyncService,
    private sharedService: SharedService,
    private conversationService: ConversationService
  ) {
    this.sharedService.currentMessagesData.subscribe(messagesData => (this.messagesData = messagesData));
  }

  async ngOnInit() {
    this.nextUserFormGroup = this.formBuilder.group({
      nextUser: ['', Validators.required]
    });
    this.descriptionFormGroup = this.formBuilder.group({
      description: ['', Validators.required]
    });
  }

  get nextUserForm() {
    return this.nextUserFormGroup.controls;
  }

  get descriptionForm() {
    return this.descriptionFormGroup.controls;
  }

  async escalate() {
    const payload = {
      userId: this.auth.userProfile.cognitoId,
      nextUserId: this.nextUserFormGroup.value.nextUser.cognitoId,
      description: this.descriptionFormGroup.value.description,
      conversationId: this.data.conversation.id
    };
    this.ngxLoader.startLoader(this.loaderId);

    try {
      const response = await this.conversationService.escalateConversation(payload);
      setTimeout(() => this.dialogRef.close(response), 500);
      this.data.onCloseConversation.emit();
    } catch (error) {
      this.errorMessage = error.message;
    }

    this.ngxLoader.stopLoader(this.loaderId);
  }
}
