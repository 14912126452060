import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { v4 as uuidv4 } from 'uuid';
import { LogService } from '@cation/core/services/log/log.service';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { getLocaleByI18nLocale } from '@cation/core/util/common-util';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { CannedResponseHelper } from './canned-response-helper';

interface ICannedResponse {
  title: string;
  response: string;
}

@Component({
  selector: 'ctn-canned-response',
  templateUrl: './canned-response.component.html',
  styleUrls: ['./canned-response.component.scss']
})
export class CannedResponseComponent implements OnInit, AfterViewInit, OnDestroy {
  loaderId = `ctn-canned-response-${uuidv4()}`;
  _locale: string;

  /** list of cannedResponses */
  protected cannedResponses;

  /** control for the selected cannedResponses */
  public cannedResponsesCtrl: UntypedFormControl = new UntypedFormControl();

  /** control for the MatSelect filter keyword */
  public cannedResponsesFilterCtrl: UntypedFormControl = new UntypedFormControl();

  /** list of cannedResponses filtered by search keyword */
  public filteredCannedResponses: ReplaySubject<ICannedResponse[]> = new ReplaySubject<ICannedResponse[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  @Input() set locale(value: string) {
    if (value !== this._locale) {
      this._locale = value;
    }
  }

  constructor(
    private sharedService: SharedService,
    private cannedResponseHelper: CannedResponseHelper,
    private translate: TranslateService,
    private ngxLoader: NgxUiLoaderService,
    private logService: LogService,
    private s3Service: S3Service
  ) {}

  get selectedCannedResponse() {
    return this.cannedResponsesCtrl.value;
  }

  async ngOnInit() {
    this.cannedResponses = await this.cannedResponseHelper.getAllCannedResponses();
    this.sharedService.setCannedResponses(this.cannedResponses);

    // set initial selection
    this.cannedResponsesCtrl.setValue(this.cannedResponses[0]);

    // load the initial cannedResponses list
    this.filteredCannedResponses.next(this.cannedResponses.slice());

    this.translate.onLangChange.subscribe(({ lang }) => {
      this.locale = getLocaleByI18nLocale(lang);
    });

    // listen for search field value changes
    this.cannedResponsesFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => this.filterCannedResponses());
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  get locale() {
    return this._locale;
  }

  /**
   * Sets the initial value after the filteredCannedResponses are loaded initially
   */
  protected setInitialValue() {
    this.filteredCannedResponses
      .pipe(
        take(1),
        takeUntil(this._onDestroy)
      )
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredCannedResponses are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a, b) => a && b && a.cannedResponseId === b.cannedResponseId;
      });
  }

  protected filterCannedResponses() {
    if (!this.cannedResponses) {
      return;
    }
    // get the search keyword
    let search = this.cannedResponsesFilterCtrl.value;
    if (!search) {
      this.filteredCannedResponses.next(this.cannedResponses.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the cannedResponses
    this.filteredCannedResponses.next(this.cannedResponses.filter(cr => cr.title.toLowerCase().includes(search)));
  }

  async onCannedResponseDoubleClick() {
    // REASON: to pass in quill editor message without URI encoding
    const selectedCannedResponseOrigin = this.cannedResponses.find(
      ({ cannedResponseId }) => cannedResponseId === this.selectedCannedResponse.cannedResponseId
    );
    const message = await this.s3Service.updateAccessToImageUrls(
      this.byLocale(selectedCannedResponseOrigin, 'response'),
      false
    );

    this.sharedService.pasteUserMessage(message);
  }

  async onCannedResponseSelect() {
    const attr = this.attrNameByLocale(this.selectedCannedResponse, 'response');
    const response = await this.s3Service.updateAccessToImageUrls(
      this.byLocale(this.selectedCannedResponse, 'response')
    );

    this.cannedResponsesCtrl.setValue({ ...this.selectedCannedResponse, [attr]: response });
  }

  public byLocale(obj, attr) {
    if (!obj) return '';
    return obj[attr + '_' + this.locale] || obj[attr];
  }

  public attrNameByLocale(obj, attr) {
    const name = attr + '_' + this.locale;
    return obj[name] ? name : attr;
  }

  handleLoader = status => {
    if (status) {
      return this.ngxLoader.startLoader(this.loaderId);
    }
    return this.ngxLoader.stopLoader(this.loaderId);
  };

  async handleLink($event) {
    await this.s3Service.handleLink($event, this.handleLoader);
  }
}
