import { Component, EventEmitter, Input, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss']
})
export class ImagePickerComponent {
  _image: string;
  progressPercent: number;
  isUploading: boolean = false;

  @Input() keyPrefix: string;
  @Input() isRounded: boolean = false;

  @Input() set image(value) {
    if (!value) {
      this._image = '';
      return;
    }
    if (value.startsWith('http') && !this.s3Service.isBucketLink(value)) {
      this._image = value;
    } else {
      this.getImage(value);
    }
  }

  async getImage(image) {
    // this.logService.log('[getImage]', image);
    try {
      if (this.s3Service.isBucketLink(image)) {
        this._image = (await this.s3Service.getFileByUrl(image)) as string;
      } else {
        this._image = (await this.s3Service.getFileByKey(image)) as string;
      }
    } catch (e) {
      this.logService.error('[getImage Error]', e);
    }
  }

  @Output() onImageSelect: EventEmitter<string> = new EventEmitter<string>();

  constructor(private logService: LogService, private s3Service: S3Service) {}

  get image() {
    return this._image;
  }

  async onSelect(e) {
    this.logService.log('[ImagePickerComponent onImageSelect:event]', e);

    const file = e.target.files[0];

    if (!file) {
      return;
    }

    this.isUploading = true;
    this.progressPercent = 0;

    const key = `${this.keyPrefix}/${uuidv4()}`;
    const config = {
      key,
      contentType: file.type,
      level: 'public',
      progressCallback: progress => {
        this.progressPercent = Math.floor((progress.loaded * 100) / progress.total);
      }
    };

    try {
      const url: string = (await this.s3Service.uploadFile(file, config)) as string;
      this.logService.log('Storage.put:url', url);
      this.onImageSelect.emit(key);
      this.image = url;
    } catch (e) {
      this.logService.error('Storage.put:error', e);
    }

    setTimeout(() => (this.isUploading = false), 1000);
  }
}
