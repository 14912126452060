<ngx-ui-loader [fgsSize]="25" [loaderId]="loaderId"></ngx-ui-loader>
<div
  #scrollCms
  class="card ctn-scroll chat p-2"
  [class.chat__closed]="isConversationClosed"
  [class.chat__read_only]="readOnly"
  [class.chat__flexible]="isFlexibleLayout"
>
  <mat-progress-bar *ngIf="cms.isDataLoading" [mode]="'indeterminate'"></mat-progress-bar>
  <ng-container *ngIf="!cms.isDataLoading">
    <h3>{{ cms.title }}</h3>
    <p>{{ cms.description }}</p>
  </ng-container>
  <h4>{{ 'CMS_VIEW.COMMENTS_TITLE' | translate }}</h4>
  <mat-progress-bar *ngIf="cms.isCommentsLoading" [mode]="'indeterminate'"></mat-progress-bar>
  <ng-container *ngIf="cms?.comments?.length">
    <div class="card mb-2 p-2" *ngFor="let comment of cms?.comments; trackBy: trackComment">
      <h6 class="card-title mb-1 text-primary">{{ comment?.author.name }}({{ comment.createdAt }})</h6>
      <div class="card-body p-0 rounded">
        <pre class="message-content" (click)="handleLink($event)" [innerHTML]="comment.body | trustHtml"></pre>
        <h3>{{ 'CMS_VIEW.ATTACHMENTS_TITLE' | translate }}</h3>
        <a
          *ngFor="let attachment of comment.attachments"
          class="attachment-link"
          target="_blank"
          [href]="attachment.url"
          [download]="attachment.name"
        >
          {{ attachment.name }}
        </a>
      </div>
    </div>
  </ng-container>
</div>
