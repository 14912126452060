import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { EVENT_TYPE, IEventsItem } from '@cation/core/types/api';
import User from '@cation/core/types/user';
import { listFadeInOut } from '@cation/core/animations';

@Component({
  selector: 'app-gamification-events-list',
  templateUrl: './gamification-events-list.component.html',
  styleUrls: ['./gamification-events-list.component.scss'],
  animations: [listFadeInOut]
})
export class GamificationEventsListComponent implements OnInit {
  @Input() memberId: string;

  EVENT_TYPE = EVENT_TYPE;
  isLoading = false;
  userProfile: User;
  errorMessage;
  events: IEventsItem[] = [];
  lastKey: object = null;

  private limit = 20;
  private loadMore = true;

  constructor(private authHelper: AuthHelper, private apiService: ApiService, private logService: LogService) {}

  ngOnInit(): void {
    this.authHelper.currentUserProfile$.subscribe(data => (this.userProfile = _.cloneDeep(data)));
    this.getEventsByMember();
  }

  async getEventsByMember(isLoadMore = false) {
    this.isLoading = true;
    try {
      const startKey = isLoadMore ? this.lastKey : null;

      const options = { limit: this.limit, startKey: JSON.stringify(startKey), typePrefix: 'TEAM_EVENT_' };
      const { items, lastKey } = await this.apiService.getEventsByMember(this.memberId, options);

      this.events.push(...items);
      this.lastKey = lastKey;

      if (items.length < this.limit) {
        this.loadMore = false;
      }

      this.logService.log('[getEventsByMember]', { items, lastKey });
    } catch (e) {
      this.errorMessage = e.message;
      this.logService.error('[getEventsByMember Error]', e);
    }
    this.isLoading = false;
  }

  onScrollDown() {
    if (this.loadMore) {
      this.getEventsByMember(true);
    }
  }
}
