<section>
  <div class="feedback__header">
    <div class="feedback__header-title-container">
      <h1 class="feedback__header-title">{{ 'PROFILE.FEEDBACK_COMMENTS.TITLE' | translate }}</h1>
    </div>
    <div
      class="feedback__header-date-container"
      [class.feedback__header-date-container_visible]="selectedFilter === filterOptionEnum.chooseRange"
    >
      <mat-form-field class="feedback__header-date-input" (click)="picker.open()">
        <input
          matInput
          [max]="maxDate"
          [satDatepicker]="picker"
          [(ngModel)]="pickerDate"
          (dateChange)="onDateChange()"
        />
        <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
        <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
      </mat-form-field>
    </div>
    <div class="widget-select feedback__header-select-container">
      <mat-form-field>
        <mat-select [(ngModel)]="selectedFilter" name="tabFilter" (selectionChange)="onFilterChange($event)">
          <ng-container *ngFor="let tab of filters">
            <mat-option [value]="tab">
              {{ 'PROFILE.FEEDBACK_COMMENTS.' + tab + '_FILTER' | translate }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="feedback__content" #ctnContainer>
    <div *ngIf="(!feedbackComments || !feedbackComments.length) && isLoading" class="loader">
      {{ 'GENERAL.LOADING' | translate }}...
    </div>

    <table class="feedback__table">
      <thead>
        <tr>
          <th>{{ 'PROFILE.FEEDBACK_COMMENTS.DATE_TITLE' | translate }}</th>
          <th>{{ 'PROFILE.FEEDBACK_COMMENTS.POINTS_TITLE' | translate }}</th>
          <th>{{ 'PROFILE.FEEDBACK_COMMENTS.STARS_TITLE' | translate }}</th>
          <th>{{ 'PROFILE.FEEDBACK_COMMENTS.CONVERSATION_TITLE' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let comment of feedbackComments; trackBy: trackStatisticsItem"
          class="feedback__table-body-row"
          (click)="openCustomerHistory(comment.conversationId)"
        >
          <td>
            <span class="feedback__table-body-row-date">{{ comment.createdAt | date: 'dd MMM yyyy HH:mm' }}</span>
          </td>
          <td>
            <div class="feedback__table-body-row-points">+{{ comment.points }}</div>
          </td>
          <td>
            <div class="stars">
              <span
                class="stars__item"
                [class.stars__item_inactive]="i + 1 > +comment.rating"
                *ngFor="let s of Array(maxStars); let i = index"
              >
                <mat-icon>grade</mat-icon>
              </span>
            </div>
          </td>
          <td>
            <div class="feedback__table-body-row-customer">
              <span class="comments-item__customer__name" [matTooltip]="comment?.customer?.username || '-'">
                {{ comment?.customer?.username || '-' }}
              </span>
              <span class="feedback__table-body-row-message" [matTooltip]="comment.message">
                {{ comment.message }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <p *ngIf="!feedbackComments || !feedbackComments.length" class="empty-message">
      {{ 'PROFILE.FEEDBACK_COMMENTS.NO_FEEDBACKS' | translate }}
    </p>

    <div class="loader-more" #loading>
      <span *ngIf="feedbackComments && feedbackComments.length && isLoading">
        {{ 'GENERAL.LOADING' | translate }}...
      </span>
    </div>
  </div>
</section>
