<button
  type="button"
  [ngClass]="{
    'internal-chat-nav-item': true,
    'internal-chat-nav-item_active': isActive
  }"
  [disabled]="isActive"
  (click)="onClickEvent($event)"
>
  <mat-icon *ngIf="itemType === itemTypeEnum.users">people</mat-icon>
  <mat-icon *ngIf="itemType === itemTypeEnum.chats">forum</mat-icon>
  <mat-icon *ngIf="itemType === itemTypeEnum.createChat">add_comment</mat-icon>
</button>
