import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ctn-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.scss'],
})
export class FormDialogComponent {
  public showPwdTxt;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FormDialogData,
    private dialogRef: MatDialogRef<FormDialogComponent>
  ) {}

  returnValues() {
    const value = this.data.formFields.reduce((acc, e) => {
      acc[e.name] = e.value;
      return acc;
    }, {});
    this.dialogRef.close(value);
  }
}

export interface FormDialogData {
  title: string;
  message: string;
  formFields: [
    {
      placeholder: string;
      name: string;
      value: string;
      hint: string;
      required: boolean;
      pattern: string | RegExp;
      error: string;
      type?;
      showPwdTxt?;
    }
  ];
}
