<div class="agents-audit">
  <ngx-loading [show]="isLoading"></ngx-loading>

  <mat-card class="ctn-card">
    <mat-card-content>
      <h4 class="ctn-h3 ctn-margin">
        <fa-icon [icon]="faUser"></fa-icon>
        {{ 'ADMIN_AGENTS_AUDIT.TITLE' | translate }}
      </h4>

      <hr />

      <section class="d-flex align-items-center">
        <app-agent-input-autocomplete
          [roles]="roles"
          [excludeMe]="!auth.isAdmin()"
          [teamLeadId]="auth.isAdmin() ? null : auth.userProfile.cognitoId"
          [withTeam]="!auth.isAdmin()"
          (selectionChange)="onChangeUser($event)"
          (agentsLoad)="onAgentsLoaded($event)"
          [placeholder]="'ADMIN_AGENTS_AUDIT.SELECT_AGENT.PLACEHOLDER' | translate"
          [placeholderLabel]="'ADMIN_REVIEW_FEEDBACK.SELECT_USER.INPUT_PLACEHOLDER' | translate"
        >
        </app-agent-input-autocomplete>

        <app-activity-input-autocomplete
          class="ps-2"
          (selectionChange)="onChangeActivity($event)"
          [placeholder]="'ADMIN_AGENTS_AUDIT.SELECT_ACTIVITY.PLACEHOLDER' | translate"
          [placeholderLabel]="'ADMIN_AGENTS_AUDIT.SELECT_ACTIVITY.INPUT_PLACEHOLDER' | translate"
        >
        </app-activity-input-autocomplete>

        <mat-form-field class="ctn-select ps-2">
          <input
            matInput
            [placeholder]="'ADMIN_AGENTS_AUDIT.DATE_PICKER_PLACEHOLDER' | translate"
            [satDatepicker]="picker"
            [(ngModel)]="date"
            [max]="maxDate"
            (keydown.enter)="findAudits()"
            (dateChange)="onChangeFilters()"
          />
          <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
          <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
        </mat-form-field>
      </section>

      <hr />

      <section>
        <h5 class="ctn-h3 ctn-margin">
          <fa-icon [icon]="faList"></fa-icon>
          {{ 'ADMIN_AGENTS_AUDIT.LIST_ACTIVITY_TITLE' | translate }}
        </h5>
        <div class="audits-container" [@listFadeInOut]="audits.length">
          <div *ngFor="let audit of audits; trackBy: trackItem" class="audits-item">
            <div class="audits-item__action-column">
              <span class="audits-item__date">{{ audit.date | date: 'd/M/yy, h:mm a' }}</span>
            </div>

            <div class="d-flex audits-item-wrapper">
              <div class="audits-item__user">
                <app-user-template class="width-100" [user]="audit.user" tooltipField="userData.status">
                </app-user-template>
              </div>
              <div class="audits-item__roles">
                <span class="audits-item__user__message" [matTooltip]="audit.user?.roles?.join()">
                  {{ audit.user?.roles?.join() }}
                </span>
              </div>
              <div class="audits-item__activity">
                <button
                  class="ms-1"
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  [matTooltip]="'ADMIN_AGENTS_AUDIT.ACTIONS_MENU.TITLE' | translate"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    color="primary"
                    (click)="openMetaData(audit.metaData)"
                    [title]="'BUTTON.VIEW' | translate"
                    [matTooltip]="'ADMIN_AGENTS_AUDIT.ACTIONS_MENU.META_DATA_TITLE' | translate"
                  >
                    <mat-icon>pageview</mat-icon>
                    {{ 'ADMIN_AGENTS_AUDIT.ACTIONS_MENU.META_DATA_TITLE' | translate }}
                  </button>
                  <button
                    *ngIf="audit?.metaData?.conversationId"
                    mat-menu-item
                    (click)="openConversation(audit?.metaData?.conversationId)"
                    [matTooltip]="'ADMIN_AGENTS_AUDIT.ACTIONS_MENU.CONVERSATION_TITLE' | translate"
                  >
                    <mat-icon>pageview</mat-icon>
                    {{ 'ADMIN_AGENTS_AUDIT.ACTIONS_MENU.CONVERSATION_TITLE' | translate }}
                  </button>
                </mat-menu>
                <span
                  [matTooltip]="'ADMIN_AGENTS_AUDIT.ACTIVITIES.' + audit.activity | translate"
                  [title]="'ADMIN_AGENTS_AUDIT.ACTIVITIES.' + audit.activity | translate"
                >
                  {{ 'ADMIN_AGENTS_AUDIT.ACTIVITIES.' + audit.activity | translate }}
                </span>
              </div>
            </div>
          </div>

          <div class="d-flex flex-column justify-content-center align-items-center py-2">
            <mat-progress-bar *ngIf="isLoadingAudits" color="primary" mode="query"></mat-progress-bar>
            <button
              *ngIf="lastKey"
              type="button"
              class="btn btn-sm btn-outline-primary"
              (click)="findAudits(true)"
              [disabled]="isLoadingAudits"
            >
              {{ 'BUTTON.LOAD_MORE' | translate }}
            </button>
          </div>
        </div>
      </section>
    </mat-card-content>

    <div class="alert alert-danger pt-2" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
  </mat-card>
</div>
