import { Injectable } from '@angular/core';
import { ObservableQuery } from 'apollo-client';
import { unshiftUserNotification, addUserNotifications } from '@cation/core/util/chat-helper';
import { AppsyncUserNotificationService } from '@cation/core/services/appsync/appsync-user-notification.service';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { LogService } from '@cation/core/services/log/log.service';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { getUserNotificationsQuery as UserNotificationQuery } from '@cation/core/graphql/operation-result-types';
import { parseJSON } from '@cation/core/util/common-util';

@Injectable()
export class UserNotificationService {
  private nextToken: string;
  private observedQuery: ObservableQuery<UserNotificationQuery>;
  private subscription: () => void;

  constructor(
    private authHelper: AuthHelper,
    private appsyncUserNotificationService: AppsyncUserNotificationService,
    private logService: LogService,
    private sharedService: SharedService
  ) {}

  public init() {
    this.getUserNotifications();
  }

  private async getUserNotifications() {
    const cognitoId = this.authHelper.userProfile.cognitoId;
    this.logService.log('[UserNotificationService getUserNotifications:data]', cognitoId);

    const observerOrNext = async ({ data }) => {
      this.logService.log('[UserNotificationService getUserNotifications:data]', data);
      if (!data || !data.getUserNotifications) {
        return this.logService.log('[UserNotificationService getUserNotifications:no data]');
      }
      const { userNotifications: uns, nextToken } = data.getUserNotifications;
      const userNotifications = uns.map(this.prepareUserNotification);
      this.sharedService.setUserNotifications({ userNotifications, nextToken });

      this.nextToken = nextToken;
      this.logService.log('[UserNotificationService getUserNotifications:userNotifications]', userNotifications);
    };

    const updateQuery = (
      prev: UserNotificationQuery,
      {
        subscriptionData: {
          data: { subscribeToUserNotifications: userNotification }
        }
      }
    ) => {
      const notification = this.prepareUserNotification(userNotification);
      this.logService.log('[UserNotificationService getUserNotifications:updateQuery]', notification);

      if (
        [
          'CHALLENGE_DRAW',
          'CHALLENGE_LOST',
          'CHALLENGE_WON',
          'CHALLENGE_REQUEST_ACCEPTED',
          'CHALLENGE_WAGER_REQUEST_ACCEPTED',
          'CHALLENGE_WAGER_DRAW',
          'CHALLENGE_WAGER_LOST',
          'CHALLENGE_WAGER_WON'
        ].includes(notification.type)
      ) {
        this.authHelper.loadRewards();
      }

      return unshiftUserNotification(prev, notification);
    };

    const data = await this.appsyncUserNotificationService.getUserNotifications(cognitoId, observerOrNext, updateQuery);

    this.subscription = data.subscription;
    this.observedQuery = data.observedQuery;
  }

  public loadMoreUserNotifications(event = null) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    const updateQuery = (prev, options) => {
      const { fetchMoreResult } = options;

      if (!fetchMoreResult) {
        return prev;
      }

      const moreNotifications = fetchMoreResult.getUserNotifications.userNotifications.map(
        this.prepareUserNotification
      );
      this.logService.log(
        '!!![loadMoreUserNotifications]',
        moreNotifications,
        fetchMoreResult.getUserNotifications.nextToken
      );

      const _res = addUserNotifications(
        prev as UserNotificationQuery,
        moreNotifications,
        fetchMoreResult.getUserNotifications.nextToken
      );
      this.nextToken = fetchMoreResult.getUserNotifications.nextToken;

      return _res;
    };

    return this.appsyncUserNotificationService.loadMoreUserNotifications(this.observedQuery, this.nextToken, {
      updateQuery
    });
  }

  private prepareUserNotification(n) {
    return { ...n, data: parseJSON(n.data) || n.data };
  }
}
