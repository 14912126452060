import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineEditComponent),
  multi: true
};

@Component({
  selector: 'ctn-inline-edit',
  templateUrl: './inline-edit.component.html',
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
  styleUrls: ['./inline-edit.component.scss']
})
export class InlineEditComponent implements ControlValueAccessor, OnInit {
  private inlineEditControl: ElementRef;

  @ViewChild('inlineEditControl') set content(content: ElementRef) {
    this.inlineEditControl = content;
  }

  @Input() public editing: boolean = false;
  @Input() public required: boolean = false;
  @Input() public name: string;
  @Input() public type: string = 'text';
  @Input() public label: string = '';
  @Input() public disabled: boolean = false;
  @Input() public maxLength: number = 1000;

  get value(): string {
    return this._value;
  }

  @Input() set value(value: string) {
    if (value !== this._value) {
      this._value = value;
      this.onChange(value);
    }
  }

  private _value: string = '';
  private preValue: string = '';

  public onChange: any = Function.prototype; // Transcend the onChange event
  public onTouched: any = Function.prototype; // Transcend the onTouch event

  constructor() {}

  ngOnInit() {}

  onEdit(value) {
    if (this.disabled) {
      return;
    }

    this.preValue = value;
    this.editing = true;
    setTimeout(_ => this.inlineEditControl.nativeElement.focus(), 100);
  }

  onBlur($event: FocusEvent) {
    this.editing = false;
  }

  // Required for ControlValueAccessor interface
  writeValue(value: any) {
    this._value = value;
  }

  // Required forControlValueAccessor interface
  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  // Required forControlValueAccessor interface
  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }
}
