import { Injectable } from '@angular/core';
import { EMPTY, from } from 'rxjs';
import {
  ObservableQuery,
  FetchMoreOptions,
  WatchQueryOptions,
  FetchMoreQueryOptions,
  SubscribeToMoreOptions,
  MutationOptions
} from 'apollo-client';
import { AppsyncService } from '@cation/core/services/appsync/appsync.service';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { LogService } from '@cation/core/services/log/log.service';
import { ILoadAndSubscribeMoreOptions, ILoadOptions } from './types';

// import { API, graphqlOperation } from 'aws-amplify';
// import { Observable } from 'zen-observable-ts';

@Injectable({
  providedIn: 'root'
})
export class AppsyncOperationService {
  constructor(
    protected appsync: AppsyncService,
    protected logService: LogService,
    protected sharedService: SharedService
  ) {}

  protected forceReloadApp(): void {
    this.sharedService.setCountActiveConversations(0);
    location.reload();
  }

  protected async subscription({ query, variables = undefined, realtimeResults, onComplete, onError }) {
    // const observable = API.graphql(graphqlOperation(query, variables)) as Observable<object>;
    // return observable.subscribe({
    //   next: realtimeResults,
    //   complete: () => {
    //     this.logService.log('[SubscriptionService subscribeToUpdateUCs:complete]');
    //     return onComplete();
    //   },
    //   error: error => {
    //     this.logService.log('[SubscriptionService subscribeToUpdateUCs:error]', error);

    //     return onError(error);
    //   }
    // });

    return this.appsync.hc().then(client => {
      const observable = client.subscribe({ query, variables });

      return observable.subscribe({
        next: realtimeResults,
        complete: data => {
          this.logService.log('[SubscriptionService subscribeToUpdateUCs:complete]', data);

          return onComplete(data);
        },
        error: error => {
          this.logService.log('[SubscriptionService subscribeToUpdateUCs:error]', error);

          return onError(error);
        }
      });
    });
  }

  protected subscribeToMore<T>(observable, options: SubscribeToMoreOptions) {
    const { document, variables, updateQuery, onError } = options;

    return observable.subscribeToMore({
      document,
      variables,
      updateQuery: (prev: T, opts) => {
        this.logService.log('[SubscriptionService subscribeToMore:updateQuery]', { prev, opts });

        return updateQuery(prev, opts);
      },
      onError: (error: Error) => {
        this.logService.log('[SubscriptionService subscribeToMore:error]', error);

        return onError(error);
      }
    });
  }

  protected load<T>({ watchQueryOptions, watchQuerySubscriptionOptions }: ILoadOptions) {
    return this.appsync.hc().then(client => {
      const observedQuery: ObservableQuery<T> = client.watchQuery(watchQueryOptions);

      const { observerOrNext, error, complete } = watchQuerySubscriptionOptions;

      const observableSubscription = observedQuery.subscribe(
        observerOrNext,
        e => {
          this.logService.log('[SubscriptionService load:error]', e);
          return error && error(e);
        },
        () => {
          this.logService.log('[SubscriptionService load:complete]');
          return complete && complete();
        }
      );

      return { observedQuery, observableSubscription };
    });
  }

  protected loadMore(observedQuery, nextToken, fetchMoreOptions) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!nextToken) {
      return EMPTY;
    }

    const { updateQuery } = fetchMoreOptions;

    const result = observedQuery.fetchMore({
      variables: { after: nextToken },
      updateQuery: (prev, opts) => {
        this.logService.log('[SubscriptionService loadMore]', { prev, opts });

        return updateQuery(prev, opts);
      }
    });
    return from(result);
  }

  protected async loadAndSubscribeMore<T>({
    watchQueryOptions,
    watchQuerySubscriptionOptions,
    subscribeToMoreOptions
  }: ILoadAndSubscribeMoreOptions) {
    const { observedQuery, observableSubscription } = await this.load<T>({
      watchQueryOptions,
      watchQuerySubscriptionOptions
    });

    const subscription = this.subscribeToMore(observedQuery, subscribeToMoreOptions);

    return {
      observedQuery,
      observableSubscription,
      subscription
    };
  }

  protected query(options) {
    return this.appsync.hc().then(client => client.query(options));
  }

  protected watchQuery(options: WatchQueryOptions, observerOrNext) {
    this.appsync.hc().then(client =>
      client.watchQuery(options).subscribe(res => {
        this.logService.log('[watchQuery subscribe: res]', res.data);
        return observerOrNext(res);
      })
    );
  }

  protected mutate<T>(options: MutationOptions<T>): Promise<any> {
    // Promise<FetchResult<T>>
    return this.appsync.hc().then(client => client.mutate(options));
  }

  public loadMoreByObservedQuery(observedQuery, nextToken: string, fetchMoreOptions) {
    return this.loadMore(observedQuery, nextToken, fetchMoreOptions);
  }
}
