import { Component, Input } from '@angular/core';
import User from '@cation/core/types/user';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-template',
  templateUrl: './user-template.component.html',
  styleUrls: ['./user-template.component.scss']
})
export class UserTemplateComponent {
  @Input() user: User;
  @Input() rightImage: boolean = false;
  @Input() displayUserName: boolean = true;
  @Input() displayTooltip: boolean = true;
  @Input() tooltipField: 'username' | 'userData.nickname' | 'userData.status' = 'username';

  constructor() {}

  get tooltip() {
    return _.get(this.user, this.tooltipField, this.user.username);
  }
}
