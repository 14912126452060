import gql from 'graphql-tag';

export default gql`
  query searchUsers($name: String!) {
    searchUsers(name: $name) {
      __typename
      username
      cognitoId
      avatarUrl
      cmsUserData
      keywords
      locales
      roles
      registered
      teamId
      userData
    }
  }
`;
