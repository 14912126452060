import { Component, OnInit } from '@angular/core';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { LogService } from '@cation/core/services/log/log.service';
import Team from '@cation/core/types/team';

@Component({
  selector: 'app-admin-teams-statistics',
  templateUrl: './teams-statistics.component.html',
  styleUrls: ['./teams-statistics.component.scss']
})
export class TeamsStatisticsComponent implements OnInit {
  public team: Team;
  public isLoading = false;

  constructor(public auth: AuthHelper, private logService: LogService) {}

  async ngOnInit() {}

  async onSelectTeam(selectedTeam) {
    this.logService.log('onSelectTeam', selectedTeam);

    this.team = selectedTeam;
  }
}
