import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { parseJSON } from '@cation/core/util/common-util';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { UserNotificationsDialogComponent } from '@cation/core/components/index';

@Component({
  selector: 'app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.scss']
})
export class AppSidebarComponent implements OnInit {
  isOnline = false;
  countUnreadNotifications = 0;

  constructor(public auth: AuthHelper, private sharedService: SharedService, private dialog: MatDialog) {}

  ngOnInit() {
    console.log('AppSidebarComponent:ngOnInit')
    this.sharedService.currentOnlineStatus.subscribe(isOnline => (this.isOnline = isOnline));

    console.log('AppSidebarComponent:currentUserProfile - Subscribe');
    this.auth.currentUserProfile$.subscribe(userProfile => {
      console.log('AppSidebarComponent:currentUserProfile', userProfile);
      const isOnlineStatus = parseJSON(localStorage.getItem(`${userProfile.cognitoId}-isOnline`)) || {
        isOnline: false
      };

      this.setOnlineStatus(isOnlineStatus.isOnline);
    });

    this.sharedService.currentUserNotifications.subscribe(data => {
      this.countUnreadNotifications = _.filter(data.userNotifications, i => !i.isRead).length;
    });
  }

  setOnlineStatus(status: boolean) {
    this.sharedService.setOnlineStatus(status);
  }

  toggleStatus($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.isOnline = !this.isOnline;
    this.setOnlineStatus(this.isOnline);
    localStorage.setItem(`${this.auth.userProfile.cognitoId}-isOnline`, JSON.stringify({ isOnline: this.isOnline }));
  }

  openUserNotificationsDialog() {
    this.dialog.open(UserNotificationsDialogComponent, {
      position: { right: '30px', top: '56px' },
      autoFocus: false,
      width: '400px',
      maxHeight: '70%'
    });
  }
}
