import { DialogValuesEnums } from './../enums/dialog-values.enum';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  dialogValuesEnum = DialogValuesEnums;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IConfirmationDialogData) {}
}

export interface IConfirmationDialogData {
  title: string;
  message: string;
  titleTranslateData: object;
  messageTranslateData: object;
}
