<div class="container-fluid h-100">
  <div class="row h-100">
    <span *ngIf="errorMessage">{{ errorMessage }}</span>

    <div class="card col-lg-12 p-0 h-100" *ngIf="conversation">
      <div class="card-header p-2" *ngIf="conversation">
        <button
          mat-icon-button
          class="me-2 material-button-with-icon"
          [matBadge]="notesData.items.length ? '*' : ''"
          matBadgeColor="accent"
          matBadgeSize="small"
          matBadgePosition="above before"
          (click)="drawer.toggle()"
        >
          <mat-icon [matTooltip]="'CONVERSATION_VIEW.NOTES_BUTTON_TOOLTIP' | translate">sticky_note_2</mat-icon>
        </button>

        <div>
          <img
            *ngIf="conversation.author?.userData?.profilePicUrl"
            [src]="conversation.author?.userData?.profilePicUrl"
            class="avatar"
            alt="avatar"
          />
          {{ 'CONVERSATION_VIEW.TITLE' | translate: { name: conversation?.name } }}

          <span class="ps-2">
            <i class="material-icons ctn-icon-middle" [matTooltip]="'SENTIMENT.' + conversation.sentiment | translate">
              {{ mapSentimentToIcon[conversation.sentiment] }}
            </i>
          </span>

          <span class="ps-2">
            <span
              class="flag-icon flag-icon-{{ localeUtil.getRegion(conversation.locale).toLowerCase() }}"
              [matTooltip]="'LOCALE.' + localeUtil.getName(conversation.locale) | translate"
            ></span>
          </span>
        </div>

        <button mat-icon-button class="material-button-with-icon" (click)="dialogRef.close()">
          <mat-icon [matTooltip]="'CONVERSATION_VIEW.HIDE_CONVERSATION_TOOLTIP' | translate">close</mat-icon>
        </button>
      </div>

      <div class="running-line-container d-block fw-bold bg-light text-end rounded p-0" *ngIf="runningLine.length > 75">
        <span class="running-line-block running-line-animate">{{ runningLine }}</span>
      </div>
      <div class="d-block fw-bold bg-light rounded px-2 py-1" *ngIf="runningLine.length <= 75">
        {{ runningLine }}
      </div>

      <mat-drawer-container class="drawer-container" autosize>
        <ngx-ui-loader [loaderId]="loaderId"></ngx-ui-loader>
        <ul ngbNav #nav="ngbNav" class="nav-pills">
          <li [ngbNavItem]="'chat'" id="chat">
            <a class="nav-link" ngbNavLink>{{ 'CONVERSATION_VIEW.CHAT_TAB_TITLE' | translate }}</a>
            <ng-template ngbNavContent>
              <div
                #scrollMe
                id="historyChatBox"
                class="card ctn-scroll chat p-2"
                [appInfscroll]="loadMoreMessages"
                [completedFetching]="messagesData.completedFetching"
              >
                <ng-container
                  *ngFor="
                    let message of messagesData.items;
                    let i = index;
                    last as isLast;
                    first as isFirst;
                    trackBy: trackItem
                  "
                >
                  <app-chat-message
                    [id]="message.id"
                    [index]="i"
                    [senderId]=""
                    [message]="message"
                    [fromMe]="fromAgent(message)"
                    [isLast]="isLast"
                    [isFirst]="isFirst"
                    [conversation]="conversation"
                    (added)="messageAdded(isFirst, $event)"
                  >
                  </app-chat-message>
                </ng-container>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'history'" id="history">
            <a class="nav-link" ngbNavLink>{{ 'CONVERSATION_VIEW.HISTORY_TAB_TITLE' | translate }}</a>
            <ng-template ngbNavContent>
              <app-chat-conversation-history
                [conversation]="_conversation"
                [senderId]=""
                [isHistoryLoading]="isHistoryLoading"
                [conversationHistory]="conversationHistory"
                [isConversationClosed]="false"
                [readOnly]="true"
              >
              </app-chat-conversation-history>
            </ng-template>
          </li>
          <ng-container *ngIf="!!conversation?.cms">
            <li
              [ngbNavItem]="cms.type + ':' + cms.id"
              id="cms.type + ':' + cms.id"
              *ngFor="let cms of conversation.cms; let i = index"
            >
              <a class="nav-link" ngbNavLink>
                <ng-template ngbTabTitle>
                  <div class="d-flex justify-content-center align-items-center">
                    {{ getCmsInstance(cms)?.name }}
                    <mat-icon [matTooltip]="'CMS_VIEW.CORE_TICKET_TOOLTIP' | translate" *ngIf="cms.isCore">
                      assignment_late
                    </mat-icon>
                  </div>
                </ng-template>
              </a>
              <ng-template ngbNavContent>
                <app-chat-cms-view [isConversationClosed]="false" [readOnly]="true" [cms]="getCmsInstance(cms)">
                </app-chat-cms-view>
              </ng-template>
            </li>
          </ng-container>
        </ul>
        <div [ngbNavOutlet]="nav" class="h-100"></div>

        <mat-drawer #drawer class="drawer-sidenav" mode="side" position="end">
          <div class="notes-header py-2 m-0">
            <h6 class="d-inline">{{ 'CONVERSATION_NOTE_VIEW.TITLE' | translate }}</h6>
          </div>
          <div
            id="historyNotesBox"
            class="card ctn-scroll chat p-2 border-0"
            [appInfscroll]="loadMoreNotes"
            [completedFetching]="notesData.completedFetching"
            appInfscrollDirection="down"
          >
            <ng-container *ngFor="let note of notesData.items; trackBy: trackItem">
              <app-chat-note [note]="note" [conversation]="conversation" [readOnly]="true"></app-chat-note>
            </ng-container>
          </div>
        </mat-drawer>
      </mat-drawer-container>
    </div>
  </div>
</div>
