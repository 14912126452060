<div class="ctn">
  <ngx-loading [show]="isLoading"></ngx-loading>

  <div *ngIf="isList">
    <div class="ctn-header">
      <app-search-field
        [areaLabel]="'ADMIN_REVIEW_USER.SEARCH_AREA_LABEL' | translate"
        [value]="searchValue"
        (onSearchEvent)="onSearch($event)"
      >
      </app-search-field>
    </div>

    <app-users-list [setItems]="listAgents" [setSearchValue]="searchValue" (onItemClick)="toggleView($event)">
    </app-users-list>
  </div>

  <mat-card *ngIf="!isList" class="ctn-card">
    <button type="button" class="btn-back" (click)="toggleView(null)">Back to List</button>
    <mat-card-content>
      <section class="d-flex align-items-center">
        <div class="ctn-user-avatar me-2" *ngIf="user?.userData?.avatarUrl || user?.avatarUrl">
          <app-user-template [user]="user" [displayUserName]="false" tooltipField="userData.status">
          </app-user-template>
        </div>
      </section>

      <app-review-user-details-view [user]="user"> </app-review-user-details-view>
    </mat-card-content>
  </mat-card>
</div>
