import { Injectable } from '@angular/core';
import { LogService } from '@cation/core/services/log/log.service';
import { MatDialog } from '@angular/material/dialog';
import { ViewCustomerHistoryDialogComponent } from '@cation/core/components/view-customer-history-dialog/view-customer-history-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  constructor(private logService: LogService, private dialog: MatDialog) {}

  openCustomerHistory(conversationId) {
    this.logService.debug('[ReviewFeedbackComponent openCustomerHistory]', conversationId);
    const dialog = this.dialog.open(ViewCustomerHistoryDialogComponent, {
      height: '80%',
      width: '80%',
      panelClass: 'conversation-dialog',
      data: { conversationId }
    });

    dialog.afterClosed().subscribe(async result => {
      this.logService.log('[openCustomerHistory result]', result);
    });
  }
}
