import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import * as moment from 'moment';
import { listFadeInOut } from '@cation/core/animations';
import { ApiService } from '@cation/core/services/api/api.service';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { LogService } from '@cation/core/services/log/log.service';
import { enumToArray } from '@cation/core/util/common-util';
import { Role } from '@cation/core/auth/role.enum';
import { IAuditItem } from '@cation/core/types/api';
import { PreviewAuditMetaDataDialogComponent } from '@cation/core/components/preview-audit-meta-data-dialog/preview-audit-meta-data-dialog.component';
import { DialogService } from '@cation/core/services/dialog/dialog.service';
import { faUser, faList } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-admin-agents-audit',
  templateUrl: './agents-audit.component.html',
  styleUrls: ['./agents-audit.component.scss'],
  animations: [listFadeInOut]
})
export class AgentsAuditComponent implements OnDestroy {
  public faUser = faUser
  public faList = faList
  public lastKey: object = null;
  public date;
  public maxDate = moment().format();
  public roles = enumToArray(Role);
  public audits: IAuditItem[] = [];
  public isLoadingAudits = false;
  public errorMessage;
  public isLoading = true;
  public userCtrl: UntypedFormControl = new UntypedFormControl();
  public activityCtrl: UntypedFormControl = new UntypedFormControl();
  private auditsInterval: NodeJS.Timer;

  constructor(
    private apiService: ApiService,
    public auth: AuthHelper,
    private logService: LogService,
    private dialog: MatDialog,
    private dialogService: DialogService
  ) {
    this.findAudits = this.findAudits.bind(this);
  }

  ngOnDestroy() {
    this.destroyAuditsInterval();
  }

  trackItem(index, item) {
    return item ? `${item.date} ${item.userId} ${item.activity}` : undefined;
  }

  startAuditsInterval() {
    if (!this.auditsInterval) {
      this.auditsInterval = setInterval(() => this.findAudits(false, true), 10000);
    }
  }

  destroyAuditsInterval() {
    if (this.auditsInterval) {
      clearInterval(this.auditsInterval);
      this.auditsInterval = null;
    }
  }

  onChangeFilters() {
    this.audits = [];
    this.lastKey = null;

    this.findAudits();
  }

  onChangeActivity(activity) {
    this.activityCtrl.setValue(activity);
    this.onChangeFilters();
  }

  onChangeUser(user) {
    this.userCtrl.setValue(user);
    this.onChangeFilters();
  }

  async findAudits(isLoadMore = false, silent: boolean = false) {
    if (!silent) {
      this.isLoadingAudits = true;
    }

    this.destroyAuditsInterval();

    try {
      const startKey = isLoadMore ? this.lastKey : null;
      const options = Object.assign(
        {},
        startKey ? { startKey: JSON.stringify(startKey) } : {},
        this.userCtrl.value ? { userId: this.userCtrl.value.cognitoId } : {},
        this.date
          ? {
              date: JSON.stringify({
                from: moment(this.date.begin).format('YYYY-MM-DD'),
                to: moment(this.date.end).format('YYYY-MM-DD')
              })
            }
          : {},
        this.activityCtrl.value ? { activity: this.activityCtrl.value } : {}
      );

      const { items, lastKey } = await this.apiService.findAudits(options);

      if (isLoadMore && startKey) {
        this.audits = this.audits.concat(items);
      } else {
        this.audits = silent ? _.merge(this.audits, items) : items;
      }
      this.lastKey = silent ? this.lastKey : lastKey;

      this.logService.log('[findAudits]', this.audits);
    } catch (e) {
      this.errorMessage = e.message;
      this.logService.error('[findAudits Error]', e);
    }

    if (!silent) {
      this.isLoadingAudits = false;
    }

    this.startAuditsInterval();
  }

  openMetaData(metaData) {
    const dialog = this.dialog.open(PreviewAuditMetaDataDialogComponent, {
      height: '60%',
      width: '60%',
      data: metaData
    });

    dialog.afterClosed().subscribe(async result => {
      this.logService.log('[onOpenPreviewAuditMetaDataDialogComponent result]', result);
    });
  }

  openConversation(conversationId) {
    this.dialogService.openCustomerHistory(conversationId);
  }

  async onAgentsLoaded(agents) {
    this.isLoading = false;

    await this.findAudits();
  }
}
