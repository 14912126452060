export const chartColorSchemas = {
  'green-red': [
    'rgba(72,143,49,1)',
    'rgba(112,163,67,1)',
    'rgba(149,183,87,1)',
    'rgba(185,203,109,1)',
    'rgba(220,224,133,1)',
    'rgba(255,245,159,1)',
    'rgba(251,211,126,1)',
    'rgba(247,176,101,1)',
    'rgba(239,140,85,1)',
    'rgba(228,102,80,1)',
    'rgba(222,66,91,1)'
  ],
  blue: [
    'rgba(5,179,226,1)',
    'rgba(60,195,231,1)',
    'rgba(92,210,237,1)',
    'rgba(120,225,242,1)',
    'rgba(148,240,248,1)',
    'rgba(175,255,255,1)',
    'rgba(114,224,245,1)',
    'rgba(47,192,237,1)',
    'rgba(0,157,227,1)',
    'rgba(0,121,210,1)',
    'rgba(63,81,181,1)'
  ],
  material500: [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#9e9e9e',
    '#607d8b'
  ],
  contrast: [
    '#f44336',
    '#4caf50',
    '#e91e63',
    '#8bc34a',
    '#9c27b0',
    '#cddc39',
    '#673ab7',
    '#ffeb3b',
    '#3f51b5',
    '#ffc107',
    '#2196f3',
    '#ff9800',
    '#03a9f4',
    '#ff5722',
    '#00bcd4',
    '#795548',
    '#009688',
    '#9e9e9e',
    '#607d8b'
  ],
  'orange-blue': [
    '#EB713E',
    '#FC8E5F',
    '#F4A547',
    '#EBCF3E',
    '#3546FA',
    '#4959FF',
    '#8566FF',
    '#828CFA',
    '#57B8F6',
    '#79CAFE',
    '#67E4FF',
    '#C3C9FF',
    '#DDE0FF',
    '#ECEEFF'
  ],
  'single-orange-color': ['#FFB01F'],
  'single-purple-color': ['#8083C5'],
  'stars-pallet': ['#EF7579', '#8A88CA', '#97DCEB', '#6FCE89', '#FFB01F']
};
