import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@cation/core/auth/auth-guard.service';
import { Role } from '@cation/core/auth/role.enum';
import { AppSubNavbarComponent } from '@cation/core/components/index';
import { RouteDataModel } from '../core/models';
import { teamLeadRoutesConfig } from './configs';

const routes: Routes = [
  {
    path: 'team-lead',
    component: AppSubNavbarComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [Role.TEAM_LEAD],
      navbarConfig: teamLeadRoutesConfig,
    } as RouteDataModel,
    children: teamLeadRoutesConfig,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TeamLeadRoutingModule {}
