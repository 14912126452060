<div class="internal-chat-user" title="{{ user.name }}">
  <div class="info">
    <div class="avatar">
      <internal-chat-s3-image [avatarName]="user.name" [image]="user.avatarUrl"></internal-chat-s3-image>
    </div>
    <div
      [ngClass]="{
        'internal-user-status-indicator': true,
        'internal-user-status-indicator_online': this.isOnline,
        'internal-user-status-indicator_offline': !this.isOnline
      }"
    ></div>
    <span class="username">{{ user.name }}</span>
    <internal-messages-counter *ngIf="unreadMessages" [unreadMessages]="unreadMessages"></internal-messages-counter>
  </div>
  <div *ngIf="route === _routes.CREATE_CHAT">
    <label class="ctn-checkbox">
      <input type="checkbox" [name]="user.id" [checked]="checked" />
      <span class="checkmark"></span>
    </label>
  </div>
</div>
