import gql from 'graphql-tag';

export default gql`
  query searchTeams($name: String!) {
    searchTeams(name: $name) {
      __typename
      id
      name
      icon
      description
      teamLeadId
      teamLead {
        __typename
        cognitoId
        username
        userData
        avatarUrl
      }
    }
  }
`;
