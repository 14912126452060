import gql from 'graphql-tag';

export default gql`
  mutation updateChatName($id: ID!, $name: String!) {
    updateChatName(id: $id, name: $name) {
      __typename
      id
      name
      createdAt
      createdBy
      author {
        __typename
        id
        name
        avatarUrl
      }
    }
  }
`;
