import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Storage } from '@aws-amplify/storage';
import { Role } from '@cation/core/auth/role.enum';
import { PromptDialogComponent } from '@cation/core/dialogs/prompt-dialog/prompt-dialog.component';
import { CMS_TYPE } from '@cation/core/enums/cms-type.enum';
import { Locale as LocaleConst } from '@cation/core/enums/locale';
import { UserListItemModel } from '@cation/core/models/index';
import { ApiHook } from '@cation/core/services/api/api-hook';
import { ApiService } from '@cation/core/services/api/api.service';
import { AppSyncApiService } from '@cation/core/services/api/appsync-api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { enumToArray } from '@cation/core/util/common-util';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { AddUserComponent } from '../add-user/add-user.component';

@Component({
  selector: 'ctn-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss'],
})
export class ManageUserComponent implements OnInit, OnDestroy {
  faTasks = faTasks;
  public Locale = [{ code: 'en', name: 'English - Default' }].concat(LocaleConst);

  public allCmsTypes: CMS_TYPE[] = enumToArray(CMS_TYPE);

  private serverUsers;
  public listUsers: UserListItemModel[] = [];

  public allGroups;
  public allKeywords;

  private userCache = new Map();
  private userOrig = new Map();

  public selectedCognitoUser;
  public user;
  public deletedUid: string;

  public errorMessage;

  public isList = true;

  public searchValue = '';

  /** control for the selected team */
  public teamCtrl: UntypedFormControl = new UntypedFormControl();
  @ViewChild('singleSelect') singleSelect: MatSelect;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    private api: ApiService,
    public apiHook: ApiHook,
    private logService: LogService,
    public appSyncApi: AppSyncApiService,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.setUsers(await this.getAllUsers());

    await this.getAllGroups();
    await this.getAllKeywords();
  }

  ngOnDestroy() {
    this.destroyTeamFilter();
  }

  destroyTeamFilter = () => {
    this._onDestroy.next();
    this._onDestroy.complete();
  };

  get freeCmsTypes() {
    const existsCms = this.user.cmsUserData.map((c) => c.type);
    return this.allCmsTypes.filter((t) => !existsCms.includes(t));
  }

  async reset() {
    this.errorMessage = null;
    this.userCache.clear();
    this.userOrig.clear();
    this.selectedCognitoUser = null;
    this.user = null;
  }

  async getAllUsers() {
    const data: any = await this.api.getUsers().toPromise();
    this.logService.log('getUsers:data', data);
    return data && data.Users ? data.Users : [];
  }

  async getAllGroups() {
    const data: any = await this.api.getGroups().toPromise();
    this.logService.log('getGroups:data', data);
    this.allGroups = data.Groups.map((g) => g.GroupName);
  }

  async getAllKeywords() {
    const data: any = await this.api.getKeywords().toPromise();
    this.logService.log('getAllKeywords:data', data);
    this.allKeywords = ['Any', ...data.map((e) => e.PrimaryTopic)];
  }

  private async onSelectUser() {
    this.logService.log('onSelectUser', this.selectedCognitoUser.Username);

    const user = this.userCache.get(this.selectedCognitoUser.Username);
    if (user) {
      this.user = user;
      return;
    }

    this.user = {
      cognito: this.selectedCognitoUser,
      cognitoId: this.getAttribute(this.selectedCognitoUser, 'sub'),
      username: this.selectedCognitoUser.Username,
      email: this.getAttribute(this.selectedCognitoUser, 'email'),
      phoneNumber: this.getAttribute(this.selectedCognitoUser, 'phone_number'),
      groups: this.selectedCognitoUser.Groups,
      enabled: this.selectedCognitoUser.Enabled,
    };

    if (this.user.groups.includes(Role.AGENT) || this.user.groups.includes(Role.TEAM_LEAD)) {
      await this.getAgent();
    }

    this.userCache.set(this.user.username, this.user);
    // Store the original
    this.userOrig.set(this.user.username, Object.assign({}, this.user));
  }

  getAttribute(obj, attr): string {
    // return (obj.Attributes.find(e => e.Name == attr) || { Value: '' })['Value'];
    return (_.find(obj.Attributes, { Name: attr }) || { Value: '' })['Value'];
  }

  private async getAgent() {
    const { cognitoId } = this.user;
    const agent = await this.appSyncApi.getAgent({ cognitoId });
    this.logService.log('getAgent:data', agent);

    if (!agent || !agent.cognitoId) {
      return;
    }

    this.user.displayName = agent.username || this.user.username;
    this.user.avatarUrl = agent.avatarUrl || '';
    this.user.keywords = agent.keywords || [];
    this.user.cmsUserData = agent.cmsUserData;
    this.user.userData = agent.userData;
    this.user.teamId = agent.teamId;
    this.user.locales = agent.locales || [];
    this.user.voiceUsername = agent.voiceUsername;

    this.teamCtrl.setValue(agent.teamId);
  }

  onAddUser() {
    const dialogRef = this.dialog.open(AddUserComponent, {});

    dialogRef.afterClosed().subscribe(async (user) => {
      this.logService.log('prompt:value', user);
      if (user) {
        this.setUsers(await this.getAllUsers());
      }
    });
  }

  // Not used
  // async onEnableUser() {
  //   const { username } = this.user;
  //   this.logService.log('onEnableUser', username);

  //   const data = await this.api.enableUser({ username }).toPromise();
  //   this.logService.log('enableUser:data', data);
  // }

  // Not used
  // async onDisableUser() {
  //   const { username } = this.user;
  //   this.logService.log('onDisableUser', username);

  //   const data = await this.api.disableUser({ username }).toPromise();
  //   this.logService.log('disableUser:data', data);
  // }

  async onDeleteUser(user: UserListItemModel) {
    const username = user.username;
    this.logService.log('onDelete', username);

    const dialogRef = this.dialog.open(PromptDialogComponent, {
      data: {
        title: 'Are you sure you want to delete the user?',
        message: `Type '${username}' to confirm`,
        pattern: new RegExp(`^${username}$`, 'g'),
        confirmBtnName: 'BUTTON.DELETE',
      },
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      this.logService.log('prompt:value', value);
      if (!value) {
        return;
      }

      const data: any = await this.api.deleteUser({ username }).toPromise();
      this.logService.log('deleteUser:data', data);

      if (data && data.statusCode === 200) {
        this.deleteUserFromList(user.uid);
      }
    });
  }

  private deleteUserFromList(uid: string) {
    for (let i = 0; i < this.serverUsers.length; i++) {
      if (this.getAttribute(this.serverUsers[i], 'sub') === uid) {
        this.serverUsers.splice(i, 1);
        this.deletedUid = uid;
        break;
      }
    }
  }

  async onSetNewPassword() {
    const { username } = this.user;
    const dialogRef = this.dialog.open(PromptDialogComponent, {
      data: {
        title: 'Set User Password',
        message: `Enter new password for '${username}'`,
        hint: 'Minimum of 8 chars, Alphanumeric with at least one special character',
        type: 'password',
      },
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      this.logService.log('prompt:value', value);
      if (!value) {
        return;
      }

      await this.api
        .setUserPassword({ username, password: value })
        .toPromise()
        .then((data) => this.handleResult(data, 'setUserPassword'));
    });
  }

  async onUpdateUser() {
    try {
      // TODO: check if it is true functionality
      // for (const user of Array.from(this.userCache.values())) {
      await this.updateUser(this.user);

      if (this.user.groups.includes(Role.AGENT) || this.user.groups.includes(Role.TEAM_LEAD)) {
        const cmsUserData = _.map(this.user.cmsUserData, JSON.stringify);

        await this.updateAgent({ ...this.user, cmsUserData });
        // }
      }

      this.serverUsers = await this.getAllUsers();
      for (let i = 0; i < this.serverUsers.length; i++) {
        if (this.getAttribute(this.serverUsers[i], 'sub') === this.user.cognitoId) {
          this.updateUserInList(this.serverUsers[i]);
          break;
        }
      }
      this.toggleView();
    } catch (err) {
      this.logService.error(err);
    }
  }

  private async updateUser(user) {
    this.logService.log('updateUser', user.username);

    await this.api
      .updateUserAttributes({
        username: user.username,
        email: user.email,
        phoneNumber: user.phoneNumber,
        avatarUrl: user.avatarUrl,
      })
      .toPromise()
      .then((data) => this.handleResult(data, 'updateUserAttribues'));

    const userOrig = this.userOrig.get(user.username);

    const noChange = _.intersection(user.groups, userOrig.groups);
    const added = _.difference(user.groups, noChange);
    const deleted = _.difference(userOrig.groups, noChange);

    if (added.length) {
      await this.api
        .addUserToGroup({
          cognitoId: user.cognitoId,
          username: user.username,
          groups: added,
        })
        .toPromise()
        .then((data) => this.handleResult(data, 'addUserToGroup'));
    }

    if (deleted.length) {
      await this.api
        .removeUserFromGroup({
          username: user.username,
          groups: deleted,
        })
        .toPromise()
        .then((data) => this.handleResult(data, 'removeUserFromGroup'));
    }

    if (userOrig.enabled !== user.enabled) {
      const usr = { username: this.user.username };
      const apiRequest = user.enabled ? this.api.enableUser(usr) : this.api.disableUser(usr);
      await apiRequest.toPromise().then((data) => this.handleResult(data, 'enable/disableUser'));
    }

    // If avatar updated then remove the old one
    if (userOrig.avatarUrl && userOrig.avatarUrl !== user.avatarUrl) {
      Storage.remove(userOrig.avatarUrl, { level: 'public' });
    }
  }

  async updateAgent(user) {
    this.logService.log('updateAgent', user.username);

    const body = {
      cognitoId: user.cognitoId,
      username: user.displayName || user.username,
      avatarUrl: user.avatarUrl || null,
      cmsUserData: user.cmsUserData || null,
      userData: user.userData || null,
      keywords: user.keywords || ['NONE'],
      roles: user.groups || ['NONE'],
      locales: user.locales || ['NONE'],
      teamId: this.teamCtrl.value ? this.teamCtrl.value.id : '',
      voiceUsername: user.voiceUsername,
    };

    this.logService.log('updateAgent:body', body);

    await this.api.updateAgent(body).toPromise();
  }

  private updateUserInList(serverUser) {
    if (serverUser) {
      const sUid = this.getAttribute(serverUser, 'sub');

      for (let i = 0; i < this.serverUsers.length; i++) {
        if (this.getAttribute(this.serverUsers[i], 'sub') === sUid) {
          Object.assign(this.listUsers[i], this.serializeUser(serverUser));
          break;
        }
      }
    }
  }

  async onAvatarSelect(url) {
    this.logService.log('onAvatarSelect', url);

    this.user.avatarUrl = url;
  }

  onAddCms(cmsType: CMS_TYPE): void {
    const newCms = { type: cmsType, userId: '' };

    if (_.isArray(this.user.cmsUserData)) {
      return this.user.cmsUserData.push(newCms);
    }

    this.user.cmsUserData = [newCms];
  }

  onDeleteCms(index) {
    return this.user.cmsUserData.splice(index, 1);
  }

  handleResult(data, action) {
    if (data.statusCode !== 200) {
      this.errorMessage = data.message;
      this.logService.error(`${action}:error`, data.message);

      return;
    }
    this.errorMessage = null;
    this.logService.info(`${action}:data`, data);
  }

  toggleView(user?: UserListItemModel) {
    if (user) {
      const selectedUser = this.serverUsers.find((serverUser) => this.getAttribute(serverUser, 'sub') === user.uid);
      if (selectedUser) {
        this.selectedCognitoUser = selectedUser;
        this.isList = !this.isList;
        this.onSelectUser();
      }
    } else {
      this.reset();
      this.isList = !this.isList;
    }
  }

  private setUsers(serverUsers: any[] = []) {
    this.serverUsers = serverUsers;
    this.listUsers = serverUsers.map((data) => this.serializeUser(data));
  }

  private serializeUser(serverUser): UserListItemModel {
    if (!serverUser) {
      return undefined;
    }

    return {
      uid: this.getAttribute(serverUser, 'sub'),
      avatarUrl: this.getAttribute(serverUser, 'custom:avatarUrl'),
      username: serverUser.Username,
      isEnabledField: true,
      enabled: serverUser.Enabled,
      roles: serverUser.Groups,
      canBeDeleted: true,
      email: this.getAttribute(serverUser, 'email'),
      emailVerified: this.getAttribute(serverUser, 'email_verified'),
      phone: this.getAttribute(serverUser, 'phone_number'),
      phoneVerified: this.getAttribute(serverUser, 'phone_number_verified'),
    };
  }

  onSearch($event) {
    this.searchValue = $event;
  }
}
