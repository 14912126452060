import gql from 'graphql-tag';

export default gql`
  subscription subscribeToUpdateUserConversations($conversationId: ID!) {
    subscribeToUpdateUserConversations(conversationId: $conversationId) {
      __typename
      userId
      conversationId
      conversation {
        __typename
        id
        status
      }
      associated {
        __typename
        conversationId
        userId
        user {
          __typename
          cognitoId
          username
          registered
          avatarUrl
        }
        typing
        connected
      }
    }
  }
`;
