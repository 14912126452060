import gql from 'graphql-tag';

export default gql`
  mutation createMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      __typename
      id
      chatId
      attachmentId
      attachment {
        id
        key
        type
        size
        name
      }
      createdAt
      senderId
      content
    }
  }
`;
