<div class="team-members-list">
  <app-agent-input-autocomplete
    [placeholder]="'ADMIN_MANAGE_TEAM.MEMBERS.INPUT_PLACEHOLDER' | translate"
    [excludeMe]="true"
    [withTeam]="true"
    [excludeIds]="membersIds"
    (selectionChange)="addMember($event)"
  ></app-agent-input-autocomplete>
  <mat-card class="mt-2 mb-2" *ngIf="_members.length">
    <mat-card-content>
      <h6>{{ 'ADMIN_MANAGE_TEAM.MEMBERS.MEMBERS_LIST' | translate }}</h6>
      <div class="d-flex flex-column team-members-list_members">
        <div *ngFor="let member of _members" class="team-members-list_item">
          <app-user-template [user]="member" tooltipField="userData.status"></app-user-template>
          <button mat-button (click)="removeMember(member)">
            {{ 'ADMIN_MANAGE_TEAM.MEMBERS.REMOVE_BUTTON' | translate }}
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
