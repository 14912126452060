<div class="container-fluid chat-input-container">
  <div class="progress-spinner" *ngIf="uploadPercent < 100">
    <mat-progress-spinner color="primary" mode="determinate" [value]="uploadPercent"> </mat-progress-spinner>
    <span class="progress-spinner__value">{{ uploadPercent }}%</span>
  </div>
  <div class="row">
    <div class="col-sm-12 p-0">
      <quill-editor
        class="chat-input-editor quill-chat-no-border ql-picker-up"
        [styles]="editorStyles"
        [(ngModel)]="message"
        [modules]="quillModules"
        [placeholder]="'CHAT_INPUT_BLOCK.INPUT_PLACE_PLACEHOLDER' | translate"
        (onContentChanged)="onContentChanged($event)"
        (onEditorCreated)="onEditorCreated($event)"
        (paste)="onImagePaste($event)"
        [disabled]="conversation?.channel === 'VOICE'"
      >
      </quill-editor>
      <mat-icon id="input_attach_file_icon" class="chat-input-icon">attachment</mat-icon>
      <mat-icon id="input_enlarge_icon" class="chat-input-icon">{{
        isInputEnlarged ? 'unfold_less' : 'unfold_more'
      }}</mat-icon>
      <mat-icon id="input_send_icon" class="chat-input-send-button" [class.chat-input-send-button-active]="message">
        send
      </mat-icon>

      <input
        #hashTagInput
        id="typeahead-basic"
        type="text"
        placement="top"
        autocomplete="off"
        class="form-control hashtag-list"
        (blur)="onBlur($event)"
        (keydown)="onKeydown($event)"
        (selectItem)="onSelectItem($event)"
        [(ngModel)]="hashTag"
        [hidden]="hiddenHashTag"
        [ngbTypeahead]="search"
        [resultFormatter]="hashTagFormatter"
        #hashTagTypeaheadInstance="ngbTypeahead"
      />
    </div>
  </div>
</div>
