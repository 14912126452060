import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Storage } from '@aws-amplify/storage';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LogService } from '@cation/core/services/log/log.service';
import { ATTACHMENT_TYPE, mapTypeToAttachmentTypes } from '@cation/core/enums/attachment-type.enum';
import Attachment from '@cation/core/types/attachment';

@Component({
  selector: 'app-preview-attachment-dialog',
  templateUrl: './preview-attachment-dialog.component.html',
  styleUrls: ['./preview-attachment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewAttachmentDialogComponent {
  loaderId = 'preview-attachment-dialog-loader';
  isLoading;
  attachmentUrl;

  ATTACHMENT_TYPE = ATTACHMENT_TYPE;
  mapTypeToAttachmentTypes = mapTypeToAttachmentTypes;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Attachment,
    public dialogRef: MatDialogRef<PreviewAttachmentDialogComponent>,
    private ngxLoader: NgxUiLoaderService,
    private logService: LogService,
    public sanitizer: DomSanitizer
  ) {
    this.logService.debug('[PreviewAttachmentDialogComponent]', this.data);
    this.getAttachment();
  }

  async getAttachment() {
    this.isLoading = true;
    try {
      this.attachmentUrl = await Storage.get(this.data.key, { expires: 60, level: 'public' });
      this.logService.log('[getAttachment]', this.attachmentUrl);
    } catch (e) {
      this.logService.error('[getAttachment Error]', e);
    }
    this.isLoading = false;
  }
}
