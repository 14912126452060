<h1 mat-dialog-title class="text-center">{{ data.title | translate }}</h1>

<div mat-dialog-content class="d-flex flex-column justify-content-center">
  <p>{{ data.message | translate }}</p>
  <form class="ctn-form">
    <mat-form-field class="ctn-full-width">
      <input
        matInput
        cdkFocusInitial
        [placeholder]="data.placeholder | translate"
        [(ngModel)]="data.value"
        [pattern]="data.pattern"
        #dataValue="ngModel"
        name="dataValue"
        [type]="data.type == 'password' && !data.showPwdTxt ? 'password' : 'text'"
        [email]="data.type == 'email'"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="data.showPwdTxt = !data.showPwdTxt"
        [attr.aria-label]="'PROMPT_DIALOG.BUTTON_HIDE_PASSWORD_ARIA_LABEL' | translate"
        [attr.aria-pressed]="data.showPwdTxt"
        *ngIf="data.type == 'password'"
      >
        <mat-icon>{{ data.showPwdTxt ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <button
        mat-button
        *ngIf="data.value"
        matSuffix
        mat-icon-button
        [attr.aria-label]="'BUTTON.CLEAR' | translate"
        (click)="data.value = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint>{{ data.hint }}</mat-hint>
      <mat-error>{{ data.error || 'PROMPT_DIALOG.DEFAULT_ERROR_MESSAGE' | translate }}</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-button class="btn-save" [mat-dialog-close]="data.value" [disabled]="!data.value || dataValue.errors">
    {{ data.confirmBtnName || 'BUTTON.SAVE' | translate }}
  </button>
  <button mat-button mat-dialog-close class="btn-cancel">
    {{ 'BUTTON.CANCEL' | translate }}
  </button>
</div>
