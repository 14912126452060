import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { StatsFilterOptionEnum } from './enums/stats-filter-option.enum';

@Component({
  selector: 'app-widget-header',
  templateUrl: './widget-header.component.html',
  styleUrls: ['./widget-header.component.scss']
})
export class WidgetHeaderComponent {
  @Input() title: string;
  @Input() totalValue: number;
  @Input() showTotalValue = true;
  @Input() isChartTypeFilter = false;
  @Input() selectedChartTypeFilterValue: StatsFilterOptionEnum;
  @Input() chartTypeFilterOptions: StatsFilterOptionEnum[];
  @Input() isDateTypeFilter = false;
  @Input() selectedDateFilterValue: StatsFilterOptionEnum;
  @Input() dateFilterOptions: StatsFilterOptionEnum[];

  @Output() onChartTypeFilterChange = new EventEmitter<StatsFilterOptionEnum>();
  @Output() onDateFilterChange = new EventEmitter<StatsFilterOptionEnum>();

  constructor() {}

  onChartTypeSelectionChange($event: MatSelectChange) {
    this.onChartTypeFilterChange.emit($event.value);
  }

  onDateSelectionChange($event: MatSelectChange) {
    this.onDateFilterChange.emit($event.value);
  }
}
