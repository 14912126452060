import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { LogService } from '@cation/core/services/log/log.service';
import { AppSyncApiService } from '@cation/core/services/api/appsync-api.service';
import Team from '@cation/core/types/team';

@Component({
  selector: 'app-team-input-autocomplete',
  templateUrl: './team-input-autocomplete.component.html',
  styleUrls: ['./team-input-autocomplete.component.scss']
})
export class TeamInputAutocompleteComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() hint: string = 'ADMIN_MANAGE_USER.TEAM_SELECT.HINT';
  @Input() errorLabel: string;
  @Input() allOptionValue: { id: string };
  @Input() allOptionTitle: string;
  @Input() placeholder: string = 'ADMIN_MANAGE_USER.TEAM_SELECT.PLACEHOLDER';
  @Input() placeholderLabel: string = 'ADMIN_MANAGE_USER.TEAM_SELECT.INPUT_PLACEHOLDER';
  @Input() teamLeadId: string;
  @Input() required = false;

  _initialTeamId: string;
  @Input() set initialTeamId(initialTeamId: string) {
    this._initialTeamId = initialTeamId;
    if (this.teamCtrl) {
      this.setInitialTeam();
    }
  }

  @Output() selectionChange = new EventEmitter<Team>();
  @Output() teamsLoad = new EventEmitter<Team[]>();

  public isLoading = false;
  public isFinding = false;
  public teams: Team[] = [];
  /** control for the selected team leads */
  public teamCtrl: UntypedFormControl = new UntypedFormControl();
  /** control for the MatSelect filter keyword */
  public teamsFilterCtrl: UntypedFormControl = new UntypedFormControl();
  /** list of users filtered by search keyword */
  public filteredTeams: ReplaySubject<Team[]> = new ReplaySubject<Team[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(private logService: LogService, private appSyncApi: AppSyncApiService) {}

  async ngOnInit() {
    this.teamsLoad.emit(this.teams);

    await this.searchTeams('', false);

    if (this.initialTeamId) {
      this.setInitialTeam();
    }

    this.filteredTeams.next(this.teams.slice() || []);

    this.teamCtrl.valueChanges.subscribe(v => this.selectionChange.emit(v));

    this.teamsFilterCtrl.valueChanges
      .pipe(
        debounceTime(200),
        takeUntil(this._onDestroy)
      )
      .subscribe(async v => {
        await this.searchTeams(v);
        return this.filterTeams();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  get initialTeamId() {
    return this._initialTeamId;
  }

  setInitialTeam() {
    this.teamCtrl.setValue(this.teams.find(t => t.id === this.initialTeamId));
  }

  public trackItem(index, item) {
    return item ? item.id : undefined;
  }

  protected setInitialValue() {
    this.filteredTeams
      .pipe(
        take(1),
        takeUntil(this._onDestroy)
      )
      .subscribe(() => {
        this.singleSelect.compareWith = (a, b) => a && b && a.id === b.id;
      });
  }

  protected filterTeams() {
    let search = this.teamsFilterCtrl.value;
    if (!search) {
      this.filteredTeams.next(this.teams.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredTeams.next(this.teams.filter(team => team.name.toLowerCase().includes(search)));
  }

  async searchTeams(filter = '', isSilent = true) {
    this.isFinding = true;
    try {
      const teams = await this.appSyncApi.searchTeams(filter.toLowerCase(), isSilent);
      this.teams = !!this.teamLeadId ? teams.filter(team => team.teamLeadId === this.teamLeadId) : teams;

      this.logService.log('[searchTeams:teams]', this.teams);
    } catch (e) {
      this.logService.error('[searchTeams:error]', e);
    }
    this.isFinding = false;
  }

  resetTeam($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.teamCtrl.setValue(null);
  }
}
