import { Component, Input, OnInit } from '@angular/core';
import { IUserConversationStatistics } from '@cation/core/types/api';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-user-conversations-range-metric-chart',
  templateUrl: './user-conversations-range-metric-chart.component.html',
  styleUrls: ['./user-conversations-range-metric-chart.component.scss']
})
export class UserConversationsRangeMetricChartComponent implements OnInit {
  @Input() xAxisTicksCount: number = 10;
  @Input() statisticsField: 'points' | 'stars';

  multi: object[];
  xAxisTicks: string[] = [];
  _statistics: IUserConversationStatistics[];

  colorScheme = {
    domain: ['#4caf50', '#ff5722']
  };

  private conversationFeedbackPointsTitle = 'Feedback Points';
  private conversationFeedbackStarsTitle = 'Feedback Stars';
  private conversationsPointsTitle = 'Conversation Points';
  private conversationsStarsTitle = 'Conversation Stars';

  @Input() set statistics(statistics: IUserConversationStatistics[]) {
    this._statistics = statistics;
    this.xAxisTicks = [];

    if (!statistics || !statistics.length) {
      this.multi = [];
      this.xAxisTicks = [];
      return;
    }

    if (statistics.length > this.xAxisTicksCount) {
      const xAxisStep = Math.round(statistics.length / this.xAxisTicksCount);
      const xAxisTicks = new Set<string>();
      for (let i = 0; i - xAxisStep < statistics.length; i += xAxisStep) {
        const stats = statistics[i] || statistics[statistics.length - 1];
        xAxisTicks.add(stats.date);
        this.xAxisTicks = Array.from(xAxisTicks.values());
      }
    }

    const type = _.upperFirst(this.statisticsField);

    this.multi = statistics.map(item => ({
      name: item.date,
      series: [
        {
          name: this[`conversations${type}Title`],
          value: _.get(item, `countConversations${type}`, 0)
        },
        {
          name: this[`conversationFeedback${type}Title`],
          value: _.get(item, `countConversationFeedback${type}`, 0)
        }
      ]
    }));
  }

  constructor(private translate: TranslateService) {
    const translateKeys = [
      'MY_STATISTICS.FEEDBACK_POINTS_TITLE',
      'MY_STATISTICS.FEEDBACK_STARS_TITLE',
      'MY_STATISTICS.CONVERSATIONS_POINTS_TITLE',
      'MY_STATISTICS.CONVERSATIONS_STARS_TITLE'
    ];
    this.translate.stream(translateKeys).subscribe(values => {
      this.conversationFeedbackPointsTitle = values['MY_STATISTICS.FEEDBACK_POINTS_TITLE'];
      this.conversationFeedbackStarsTitle = values['MY_STATISTICS.FEEDBACK_STARS_TITLE'];
      this.conversationsPointsTitle = values['MY_STATISTICS.CONVERSATIONS_POINTS_TITLE'];
      this.conversationsStarsTitle = values['MY_STATISTICS.CONVERSATIONS_STARS_TITLE'];

      this.statistics = this._statistics;
    });
  }

  ngOnInit() {}
}
