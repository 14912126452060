<div class="text-center">
  <h1 mat-dialog-title class="d-inline-block">{{ 'CONVERSATION_VIEW.INVITE_AGENT_DIALOG.TITLE' | translate }}</h1>
  <button mat-icon-button [tabIndex]="-1" type="button" class="close" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <form>
    <ngx-ui-loader [fgsSize]="25" [loaderId]="loaderId"></ngx-ui-loader>
    <app-agent-input-autocomplete
      (selectionChange)="onSelectionChange($event)"
      [required]="true"
      [isOnlineOnly]="true"
      [excludeIds]="memberIds"
      [excludeMe]="true"
      (agentsLoad)="isAgentsLoading = false"
      [placeholder]="'CONVERSATION_VIEW.INVITE_AGENT_DIALOG.SELECT_AGENT.PLACEHOLDER' | translate"
      [placeholderLabel]="'CONVERSATION_VIEW.INVITE_AGENT_DIALOG.SELECT_AGENT.INPUT_PLACEHOLDER' | translate"
      [hint]="'CONVERSATION_VIEW.INVITE_AGENT_DIALOG.SELECT_AGENT.HINT' | translate"
      [errorLabel]="'CONVERSATION_VIEW.INVITE_AGENT_DIALOG.SELECT_AGENT.ERROR' | translate"
    >
    </app-agent-input-autocomplete>
    <mat-progress-bar class="m-2" *ngIf="isAgentsLoading" [mode]="'indeterminate'"></mat-progress-bar>
    <div class="text-end">
      <button mat-button (click)="onAgentInvite()">{{ 'BUTTON.INVITE' | translate }}</button>
    </div>
  </form>

  <div *ngIf="errorMessage" class="alert alert-danger mt-2">
    {{ errorMessage }}
  </div>
</div>
