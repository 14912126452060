import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import { SharedService } from '../services/shared.service';
import { animate, style, transition, trigger, state } from '@angular/animations';

@Component({
  selector: 'internal-chat-trigger-button',
  templateUrl: './trigger-button.component.html',
  styleUrls: ['./trigger-button.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ transform: 'rotate(90deg)' })),
      state('false', style({ transform: 'rotate(0)' })),
      transition('false <=> true', animate('300ms ease-out'))
    ])
  ]
})
export class TriggerButtonComponent {
  @Input() inline: boolean;
  @Input() isChatOpened: boolean;
  @Output() onToggleChat: EventEmitter<void> = new EventEmitter<void>();

  countUnreadMessages;

  constructor(private sharedService: SharedService) {
    this.sharedService.countUnreadMessages.subscribe(counts => {
      const count = _.reduce(counts, (result, value) => result + value, 0);

      this.countUnreadMessages = count > 9 ? '9+' : count;
    });
  }

  toggleInternalChat($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.onToggleChat.emit();
  }
}
