import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormControl } from '@angular/forms';
import * as _ from 'lodash';
import { listFadeInOut } from '@cation/core/animations';
import { ApiService } from '@cation/core/services/api/api.service';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { LogService } from '@cation/core/services/log/log.service';
import { enumToArray, parseJSON } from '@cation/core/util/common-util';
import { FEEDBACK_STATUS } from '@cation/core/enums/feedback-status.enum';
import User from '@cation/core/types/user';
import { IFeedBackComment, FeedBackStatus } from '@cation/core/types/api';
import { ConfirmationDialogComponent } from '@cation/core/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AddAchievementDialogComponent } from '@cation/core/components/add-achievement-dialog/add-achievement-dialog.component';
import { DialogService } from '@cation/core/services/dialog/dialog.service';
import { ActionButtonTypeEnum } from './enums/action-button-type.enum';
import { faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-review-feedback',
  templateUrl: './review-feedback.component.html',
  styleUrls: ['./review-feedback.component.scss'],
  animations: [listFadeInOut],
})
export class ReviewFeedbackComponent implements OnInit, OnDestroy {
  public faUser = faUser;
  public Array = Array;
  public maxStars = 5;
  public statuses: FEEDBACK_STATUS[] = enumToArray(FEEDBACK_STATUS);

  public teamIds: string | string[];
  public feedbackComments: IFeedBackComment[] = [];
  public totalComments = 0;
  public isLoadingComments = false;

  public selectedStatus = FEEDBACK_STATUS.PENDING;

  public errorMessage;
  public isLoading = true;

  public userCtrl: UntypedFormControl = new UntypedFormControl();

  private feedbackCommentsInterval: NodeJS.Timer;

  // TODO: Remove after using Redis on the backend side
  private processedFeedbackIdsTemp: { [key: string]: object } = {};

  public actionButtonsTypeEnum = ActionButtonTypeEnum;

  private loadIntersectionObserver: IntersectionObserver;

  @ViewChild('ctnContainer', { static: true }) ctnContainer: ElementRef;
  @ViewChild('loading', { static: true }) loading: ElementRef;

  constructor(
    private apiService: ApiService,
    public auth: AuthHelper,
    private logService: LogService,
    private dialog: MatDialog,
    private dialogService: DialogService
  ) {
    this.findFeedbackComments = this.findFeedbackComments.bind(this);
  }

  ngOnInit(): void {
    const processedFeedbackIds = sessionStorage.getItem('processedFeedbackIds');
    this.processedFeedbackIdsTemp = parseJSON(processedFeedbackIds) || {};
    this.createLoadIntersection();
    this.initLoadIntersection();
  }

  ngOnDestroy() {
    this.destroyFeedBackCommentsInterval();
    this.disconnectLoadIntersection();
  }

  setProcessedFeedbackIdsTemp(id, data) {
    this.processedFeedbackIdsTemp[id] = data;
    this.feedbackComments = this.feedbackComments.map((item) => ({
      ...item,
      ...this.processedFeedbackIdsTemp[item.id],
    }));
    const str = JSON.stringify(this.processedFeedbackIdsTemp);
    sessionStorage.setItem('processedFeedbackIds', str);
  }

  get pendingFeedbackComments() {
    return this.feedbackComments.filter((c) => c.status === 'PENDING');
  }

  startFeedBackCommentsInterval() {
    if (!this.feedbackCommentsInterval) {
      this.feedbackCommentsInterval = setInterval(() => this.findFeedbackComments(false, true), 10000);
    }
  }

  destroyFeedBackCommentsInterval() {
    if (this.feedbackCommentsInterval) {
      clearInterval(this.feedbackCommentsInterval);
      this.feedbackCommentsInterval = null;
    }
  }

  trackFeedbackComment(index, comment) {
    return comment ? comment.id : undefined;
  }

  onChangeFilters() {
    this.feedbackComments = [];
    this.totalComments = 0;

    this.findFeedbackComments();
  }

  onChangeUser(user) {
    this.userCtrl.setValue(user);
    this.onChangeFilters();
  }

  openCustomerHistory(conversationId) {
    this.dialogService.openCustomerHistory(conversationId);
  }

  onAddAchievement(user) {
    this.logService.debug('[ReviewFeedbackComponent onAddAchievement]', user);
    const dialog = this.dialog.open(AddAchievementDialogComponent, {
      data: { excludeAchievements: [], user },
    });

    dialog.afterClosed().subscribe(async (achievement) => {
      this.logService.log('[onAddAchievement achievement]', achievement);
    });
  }

  async findFeedbackComments(isLoadMore = false, silent: boolean = false) {
    if (!silent) {
      this.isLoadingComments = true;
    }

    this.destroyFeedBackCommentsInterval();

    try {
      const filter = Object.assign(
        { status: this.selectedStatus },
        { teamId: this.teamIds },
        this.userCtrl.value ? { agentId: this.userCtrl.value.cognitoId } : {},
        isLoadMore ? { from: this.feedbackComments.length } : {}
      );

      const { total, comments } = await this.apiService.findFeedbackComments(filter);
      const items = isLoadMore ? this.feedbackComments.concat(comments) : comments;

      this.totalComments = total;
      this.feedbackComments = items.map((item) => ({ ...item, ...this.processedFeedbackIdsTemp[item.id] }));

      this.logService.log('[findFeedbackComments]', this.feedbackComments);
    } catch (e) {
      this.errorMessage = e.message;
      this.logService.error('[findFeedbackComments Error]', e);
    }

    if (!silent) {
      this.isLoadingComments = false;
    }

    this.startFeedBackCommentsInterval();
  }

  async changeFeedbackCommentStatus(comment: IFeedBackComment, status: FeedBackStatus) {
    const oldStatus = comment.status;
    comment.status = status;
    const { agent, customer, ...rest } = comment;
    try {
      const result = await this.apiService.changeFeedbackComment({ ...rest, status });

      this.setProcessedFeedbackIdsTemp(comment.id, { status });
      this.logService.log('[changeFeedbackCommentStatus]', result);
      return result;
    } catch (e) {
      comment.status = oldStatus;
      this.setProcessedFeedbackIdsTemp(comment.id, { status: oldStatus });
      this.errorMessage = e.message;
      this.logService.error('[changeFeedbackCommentStatus Error]', e);
    }
    return undefined;
  }

  async approveAllPendingComments() {
    const pendingComments = this.pendingFeedbackComments;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'ADMIN_REVIEW_FEEDBACK.COMMENTS.APPROVE_ALL.DIALOG_TITLE',
        message: 'ADMIN_REVIEW_FEEDBACK.COMMENTS.APPROVE_ALL.DIALOG_MESSAGE',
        messageTranslateData: { count: pendingComments.length },
      },
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      this.logService.log('[approveAllPendingComments:value]', value);
      if (!value) {
        return;
      }
      const promises = pendingComments.map((c) => this.changeFeedbackCommentStatus(c, 'APPROVED'));

      await Promise.all(promises);
    });
  }

  async onAgentsLoaded(agents) {
    const teamIds = _.uniq<string>(_.map<User, string>(agents, 'teamId' as any).filter((id) => !!id));
    this.teamIds = teamIds.length === 1 ? teamIds[0] : teamIds;
    this.isLoading = false;

    await this.findFeedbackComments();
  }

  public actionButtonEvent($event: MouseEvent, comment: IFeedBackComment, buttonType: ActionButtonTypeEnum) {
    $event.stopPropagation();

    switch (buttonType) {
      case ActionButtonTypeEnum.addAchievement:
        this.onAddAchievement(comment.agent);
        break;
      case ActionButtonTypeEnum.approved:
        this.changeFeedbackCommentStatus(comment, ActionButtonTypeEnum.approved);
        break;
      case ActionButtonTypeEnum.rejected:
        this.changeFeedbackCommentStatus(comment, ActionButtonTypeEnum.rejected);
        break;
      default:
        console.error(`The ${buttonType} is not defined.`);
    }
  }

  private createLoadIntersection() {
    const options = {
      root: this.ctnContainer.nativeElement,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (
          this.isIntersecting(entry) &&
          this.totalComments < this.feedbackComments.length &&
          !this.isLoadingComments
        ) {
          this.findFeedbackComments(true);
        }
      });
    };

    this.loadIntersectionObserver = new IntersectionObserver(callback, options);
  }

  private initLoadIntersection() {
    this.loadIntersectionObserver.observe(this.loading.nativeElement);
  }

  private disconnectLoadIntersection() {
    if (this.loadIntersectionObserver) {
      this.loadIntersectionObserver.disconnect();
    }
  }

  private isIntersecting(entry: IntersectionObserverEntry): boolean {
    return entry.isIntersecting || entry.intersectionRatio > 0;
  }
}
