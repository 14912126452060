import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private title = '';
  get getTitle(): string {
    return this.title;
  }

  constructor(private titleService: Title) {}

  setTitle(newTitle: string) {
    this.title = newTitle;
    this.titleService.setTitle(newTitle);
  }

  updateTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
}
