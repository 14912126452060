import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@cation/core/modules/shared.module';
import { MaterialModule } from '@cation/core/modules/material.module';
import { ChatNoteComponent } from '@cation/core/components/chat-note/chat-note.component';
import { GuestHomeComponent } from '@cation/core/components/guest-home/guest-home.component';
import { ChatMessageComponent } from '@cation/core/components/chat-message/chat-message.component';
import { ChatCmsViewComponent } from '@cation/core/components/chat-cms-view/chat-cms-view.component';
import { AccessDeniedComponent } from '@cation/core/components/access-denied/access-denied.component';
import { CustomerInfoComponent } from '@cation/core/components/customer-info/customer-info.component';
import { AchievementsListComponent } from '@cation/core/components/achievements-list/achievements-list.component';
import { TransfersListTableComponent } from '@cation/core/components/transfers-list-table/transfers-list-table.component';
import { ChatConversationHistoryComponent } from '@cation/core/components/chat-conversation-history/chat-conversation-history.component';
import { PreviewAttachmentDialogComponent } from '@cation/core/components/preview-attachment-dialog/preview-attachment-dialog.component';
import { PreviewAuditMetaDataDialogComponent } from '@cation/core/components/preview-audit-meta-data-dialog/preview-audit-meta-data-dialog.component';
import { ChatCreateEditNoteDialogComponent } from '@cation/core/components/chat-create-edit-note-dialog/chat-create-edit-note-dialog.component';
import { ViewCustomerHistoryDialogComponent } from '@cation/core/components/view-customer-history-dialog/view-customer-history-dialog.component';
import { ImagePickerComponent } from '@cation/core/components/image-picker/image-picker.component';
import { S3ImageComponent } from '@cation/core/components/s3-image/s3-image.component';
import { SnackBarMessageComponent } from '@cation/core/components/snack-bar-message/snack-bar-message.component';
import { InlineEditComponent } from '@cation/core/components/inline-edit/inline-edit.component';
import { SimilarCasesViewComponent } from '@cation/core/components/similar-cases-view/similar-cases-view.component';
import { UserNotificationsDialogComponent } from '@cation/core/components/user-notifications-dialog/user-notifications-dialog.component';
import { UserTemplateComponent } from '@cation/core/components/user-template/user-template.component';
import { SnackBarErrorComponent } from '@cation/core/components/snack-bar-error/snack-bar-error.component';
import { AgentInputAutocompleteComponent } from '@cation/core/components/agent-input-autocomplete/agent-input-autocomplete.component';
import { MyStatistics2Component } from '@cation/core/components/my-statistics2/my-statistics2.component';
import { UserConversationsRangeMetricChartComponent } from '@cation/core/components/user-conversations-range-metric-chart/user-conversations-range-metric-chart.component';
import { UserConversationsSingleMetricChartComponent } from '@cation/core/components/user-conversations-single-metric-chart/user-conversations-single-metric-chart.component';
import { SingleTotalConversationsChartComponent } from '@cation/core/components/single-total-conversations-chart/single-total-conversations-chart.component';
import { MultipleTotalConversationsChartComponent } from '@cation/core/components/multiple-total-conversations-chart/multiple-total-conversations-chart.component';
import { AddAchievementDialogComponent } from '@cation/core/components/add-achievement-dialog/add-achievement-dialog.component';
import { ReviewUserDetailsViewComponent } from '@cation/core/components/review-user-details-view/review-user-details-view.component';
import { TeamInputAutocompleteComponent } from '@cation/core/components/team-input-autocomplete/team-input-autocomplete.component';
import { ReviewTeamDetailsViewComponent } from '@cation/core/components/review-team-details-view/review-team-details-view.component';
import { AverageConversationsDurationChartComponent } from '@cation/core/components/average-conversations-duration-chart/average-conversations-duration-chart.component';
import { UserInputAutocompleteComponent } from '@cation/core/components/user-input-autocomplete/user-input-autocomplete.component';
import { ConversationsStatisticsComponent } from '@cation/core/components/conversations-statistics/conversations-statistics.component';
import { ActivityInputAutocompleteComponent } from '@cation/core/components/activity-input-autocomplete/activity-input-autocomplete.component';
import {
  AppSubNavbarComponent,
  BaseModalOverlayComponent,
  BaseUnsubscribeComponent,
  ConfirmOverlayComponent,
  SearchFieldComponent,
  UserListItemComponent,
  UsersListComponent
} from '../components/index';

const modules = [
  AccessDeniedComponent,
  GuestHomeComponent,
  ChatNoteComponent,
  ChatMessageComponent,
  ChatCmsViewComponent,
  TransfersListTableComponent,
  ChatConversationHistoryComponent,
  ViewCustomerHistoryDialogComponent,
  PreviewAttachmentDialogComponent,
  PreviewAuditMetaDataDialogComponent,
  ChatCreateEditNoteDialogComponent,
  CustomerInfoComponent,
  AchievementsListComponent,
  ImagePickerComponent,
  S3ImageComponent,
  SnackBarMessageComponent,
  SnackBarErrorComponent,
  InlineEditComponent,
  SimilarCasesViewComponent,
  UserNotificationsDialogComponent,
  UserTemplateComponent,
  AgentInputAutocompleteComponent,
  UserInputAutocompleteComponent,
  MyStatistics2Component,
  UserConversationsRangeMetricChartComponent,
  UserConversationsSingleMetricChartComponent,
  SingleTotalConversationsChartComponent,
  MultipleTotalConversationsChartComponent,
  AverageConversationsDurationChartComponent,
  AddAchievementDialogComponent,
  ReviewUserDetailsViewComponent,
  TeamInputAutocompleteComponent,
  ReviewTeamDetailsViewComponent,
  AppSubNavbarComponent,
  SearchFieldComponent,
  UserListItemComponent,
  UsersListComponent,
  ConversationsStatisticsComponent,
  ActivityInputAutocompleteComponent,
  ConfirmOverlayComponent,
  BaseUnsubscribeComponent,
  BaseModalOverlayComponent
];

@NgModule({
  imports: [CommonModule, SharedModule, MaterialModule, NgbModule],
  declarations: [...modules],
  exports: modules
  // entryComponents: [
  //   UserNotificationsDialogComponent,
  //   ViewCustomerHistoryDialogComponent,
  //   PreviewAttachmentDialogComponent,
  //   PreviewAuditMetaDataDialogComponent,
  //   ChatCreateEditNoteDialogComponent,
  //   SnackBarMessageComponent,
  //   SnackBarErrorComponent,
  //   AddAchievementDialogComponent,
  //   ConfirmOverlayComponent
  // ]
})
export class ComponentsModule {}
