<div class="achievements py-2" [class.achievements-nowrap]="inline" [@listFadeInOut]="achievements.length">
  <div class="achievement-item-wrapper" *ngFor="let item of achievements; trackBy: trackAchievement">
    <div
      class="achievement-item"
      [class.achievement-item_selective]="selective"
      [class.achievement-item_inactive]="!selective && !item.isAchieved"
      (click)="onAchievementSelect.emit(item)"
    >
      <div class="front">
        <div class="d-flex w-100 justify-content-center align-items-center achievement-item__icon">
          <app-s3-image [image]="item.icon" imageStyle="width: 140px; height: 140px;"></app-s3-image>
        </div>
        <div class="achievement-item__title">{{ item.title }}</div>
        <div class="achievement-item__description">{{ item.description }}</div>
        <div *ngIf="!item.isAchieved && achievementProgress[item.id]" class="achievement-item__progress">
          <span class="achievement-item__progress__text">{{ achievementProgress[item.id] }} / {{ item.worth }}</span>
        </div>
      </div>
      <div class="back" *ngIf="!selective && item.isAchieved">
        <div class="d-flex flex-column h-100 justify-content-center align-items-center">
          <div class="achievement-item__title">
            {{ item?.achieved.createdAt | date: 'short' }}
          </div>
          <div class="achievement-item__description" *ngIf="item?.achieved.description">
            {{ item?.achieved.description }}
          </div>
          <div class="achievement-item__description" *ngIf="item?.achieved.extraPoints">
            {{ 'ADD_ACHIEVEMENT_DIALOG.FINISH_STEP.PLUS_POINTS' | translate: { value: item?.achieved.extraPoints } }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
