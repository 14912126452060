import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ApiService } from '@cation/core/services/api/api.service';

@Component({
  selector: 'app-conversations-statistics',
  templateUrl: './conversations-statistics.component.html',
  styleUrls: ['./conversations-statistics.component.scss']
})
export class ConversationsStatisticsComponent implements OnInit {
  @Input() userId: string;

  colorScheme = {
    domain: ['rgba(252,217,131,1)', 'rgba(72,143,48,1)']
  };

  totalAndDuration = {};

  statistics;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.setTotalAndDuration();
  }

  private async getUserConversationStatistics() {
    const statistics = await this.apiService.getUserConversationStatistics({
      userId: this.userId,
      startDate: new Date().toISOString().slice(0, 10),
      endDate: ''
    });

    this.statistics = statistics;
  }

  private async setTotalAndDuration() {
    await this.getUserConversationStatistics();

    this.totalAndDuration = [
      {
        name: 'Number of Conversations',
        value: _.get(this.statistics, 'countConversations', 0)
      },
      {
        name: 'Duration of Conversations',
        value: _.get(this.statistics, 'countConversationFeedbackStars', 0)
      }
    ];
  }
}
