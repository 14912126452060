import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Analytics } from 'aws-amplify';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { AppsyncService } from '@cation/core/services/appsync/appsync.service';
import { constants, deleteNote } from '@cation/core/util/chat-helper';
import * as mutations from '@cation/core/graphql/mutations';
import * as queries from '@cation/core/graphql/queries';
import Conversation from '@cation/core/types/conversation';
import Note from '@cation/core/types/note';
import { ChatCreateEditNoteDialogComponent } from '../chat-create-edit-note-dialog/chat-create-edit-note-dialog.component';

@Component({
  selector: 'app-chat-note',
  templateUrl: './chat-note.component.html',
  styleUrls: ['./chat-note.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatNoteComponent implements OnInit, OnChanges {
  loaderId = 'chat-note-dialog-loader';

  @Input() conversation: Conversation;
  @Input() note: Note;
  @Input() readOnly: boolean;

  createdAt;
  content = '';

  constructor(
    private dialog: MatDialog,
    private logService: LogService,
    private appsync: AppsyncService,
    private s3Service: S3Service,
    private ngxLoader: NgxUiLoaderService
  ) {}

  async ngOnInit() {
    this.createdAt = this.note.id.split('_')[0];
    this.loaderId += `-${this.note.id}`;
    this.content = await this.s3Service.updateAccessToImageUrls(this.note.content);
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'note') {
        const chng = changes[propName];
        if (chng.currentValue.content !== this.content) {
          this.content = await this.s3Service.updateAccessToImageUrls(chng.currentValue.content);
        }
      }
    }
  }

  deleteNote() {
    if (this.readOnly) {
      return;
    }
    this.logService.log('[deleteNote]');
    return this.appsync.hc().then(client => {
      return client
        .mutate({
          mutation: mutations.deleteConversationNote,
          variables: {
            id: this.note.id,
            conversationId: this.conversation.id
          },
          update: (proxy, { data: { deleteConversationNote: note } }) => {
            this.logService.log('[deleteNote - update fn]', note);
            const _options = {
              query: queries.getConversationNotes,
              variables: {
                conversationId: this.conversation.id,
                first: constants.notesFirst
              }
            };
            const prev = proxy.readQuery(_options);
            const data = deleteNote(prev, note);

            this.logService.log('[deleteNote data]', { _options, prev, data });

            proxy.writeQuery({ ..._options, data });
          }
        })
        .then(({ data }) => {
          this.logService.log('mutation deleteConversationNote complete', data);
          return data;
        })
        .catch(err => this.logService.log('Error deleting conversation note', err))
        .then(() => Analytics.record('Delete Conversation Note'));
    });
  }

  onOpenEditConversationNoteModal() {
    if (this.readOnly) {
      return;
    }
    const dialog = this.dialog.open(ChatCreateEditNoteDialogComponent, {
      height: 'auto',
      width: '50%',
      data: {
        conversation: this.conversation,
        noteId: this.note.id,
        content: this.content
      }
    });

    dialog.afterClosed().subscribe(async result => {
      this.logService.log('[onOpenAddConversationNoteModal result]', result);
    });
  }

  handleLoader = status => {
    if (status) {
      return this.ngxLoader.startLoader(this.loaderId);
    }
    return this.ngxLoader.stopLoader(this.loaderId);
  };

  async handleLink($event) {
    await this.s3Service.handleLink($event, this.handleLoader);
  }
}
