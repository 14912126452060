import { HostnameEnum } from './core/enums/hostname.enum';
import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Amplify } from 'aws-amplify';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ComponentsModule } from '@cation/core/modules/components.module';
import { environment } from '../environments/environment';
import { AdminModule } from './admin/admin.module';
import { TeamLeadModule } from './team-lead/team-lead.module';
import { AppNavbarComponent } from './app-navbar/app-navbar.component';
import { AppComponent } from './app.component';
import { ChatAppModule } from './chat-app/chat-app.module';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { RoutingModule } from './routing.module';
import { InternalChatModule } from './internal-chat/internal-chat.module';
import { UserNotificationService } from '@cation/core/services/user-notification/user-notification.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { AppSidebarComponent } from './app-sidebar/app-sidebar.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { OverlayContainer } from '@angular/cdk/overlay';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

Amplify.configure({
  ...environment.amplifyConfig,
  /**
   * TODO: Remove when AWS-Amplify will be fixed
   * https://github.com/aws-amplify/amplify-js/issues/3489#issuecomment-531987292
   */
  Analytics: { disabled: true }
});

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [AppComponent, AppNavbarComponent, AppSidebarComponent, FooterComponent, LoginComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ComponentsModule,
    ChatAppModule.forRoot(),
    TeamLeadModule.forRoot(),
    AdminModule.forRoot(),
    RoutingModule,
    NgbModule,
    MatBadgeModule,
    MatTooltipModule,
    MatButtonModule,
    MatIconModule,
    PickerModule,
    MatSlideToggleModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: environment.production
    }),
    AmplifyUIAngularModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InternalChatModule.forRoot(environment.internalChatConfig),
    MatMenuModule,
    FontAwesomeModule
  ],
  providers: [UserNotificationService, Title],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer) {
    const hostname = window.location.hostname;
    if (HostnameEnum.engageHub === hostname || HostnameEnum.yourBank === hostname) {
      overlayContainer.getContainerElement().classList.add('engageHub-theme');
    }
  }
}
