import gql from 'graphql-tag';

export default gql`
  mutation updateActiveTime($userId: ID!, $time: Float) {
    updateActiveTime(userId: $userId, time: $time) {
      __typename
      id
      name
      avatarUrl
      lastActiveTime
    }
  }
`;
