<div>
  <mat-icon>search</mat-icon>
  <input
    type="search"
    name="search"
    [placeholder]="'BUTTON.SEARCH' | translate"
    [attr.aria-label]="areaLabel"
    [ngModel]="value"
    (ngModelChange)="onModelChange($event)"
  />
</div>
