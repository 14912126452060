<div
  class="user-template"
  [class.user-template__reverse]="rightImage"
  [matTooltip]="displayTooltip ? tooltip : ''"
  *ngIf="user"
>
  <div class="tp-wrapper" [class.ps-2]="rightImage" [class.pe-2]="!rightImage" *ngIf="user?.userData?.avatarUrl">
    <div class="tp-box">
      <div class="front">
        <app-s3-image [isRounded]="true" [image]="user?.userData?.avatarUrl" [avatarName]="user?.userData.nickname">
        </app-s3-image>
      </div>
      <div class="back">
        <app-s3-image [isRounded]="true" [image]="user?.avatarUrl" [avatarName]="user?.username"></app-s3-image>
      </div>
    </div>
  </div>
  <div class="tp-wrapper" [class.ps-2]="rightImage" [class.pe-2]="!rightImage" *ngIf="!user?.userData?.avatarUrl">
    <div class="tp-box tp-box__no-cursor">
      <app-s3-image [isRounded]="true" [image]="user?.avatarUrl" [avatarName]="user?.username"></app-s3-image>
    </div>
  </div>

  <div class="d-flex flex-column justify-content-center user-template__info-column" *ngIf="displayUserName">
    <span class="user-template__name">{{ user?.username }}&nbsp;</span>
    <span class="user-template__status" *ngIf="user?.userData?.nickname">{{ user?.userData?.nickname }}</span>
  </div>
</div>
