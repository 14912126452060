import { Injectable } from '@angular/core';
import { FetchMoreOptions, FetchMoreQueryOptions, FetchPolicy } from 'apollo-client';
import * as _ from 'lodash';
import { AppsyncOperationService } from '@cation/core/services/appsync/appsync-operation.service';
import * as subscriptions from '@cation/core/graphql/subscriptions';
import * as queries from '@cation/core/graphql/queries';
import { ISubscriptionOptions } from '@cation/core/services/appsync/types';
import {
  getConversationQueuesQuery as ConversationQueuesQuery,
  getUsersConversationQueueHistoryQuery as QueueHistoryQuery
} from '@cation/core/graphql/operation-result-types';
import { constants } from '@cation/core/util/chat-helper';

@Injectable({
  providedIn: 'root'
})
export class AppsyncConversationQueueService extends AppsyncOperationService {
  getConversationQueues(observerOrNext) {
    const watchQueryOptions = {
      query: queries.getConversationQueues,
      fetchPolicy: 'cache-and-network' as FetchPolicy
    };

    const watchQuerySubscriptionOptions = { observerOrNext };

    return this.load<ConversationQueuesQuery>({ watchQueryOptions, watchQuerySubscriptionOptions });
  }

  getUsersConversationQueueHistory(userId, observerOrNext, updateQuery) {
    const watchQueryOptions = {
      query: queries.getUsersConversationQueueHistory,
      variables: { userId, first: constants.conversationFirst },
      fetchPolicy: 'cache-and-network' as FetchPolicy
    };

    const watchQuerySubscriptionOptions = { observerOrNext };

    const subscribeToMoreOptions = _.merge(
      { onError: () => this.forceReloadApp() },
      {
        document: subscriptions.subscribeToSaveConversationQueueHistory,
        variables: { userId },
        updateQuery
      }
    );

    return this.loadAndSubscribeMore<QueueHistoryQuery>({
      watchQueryOptions,
      watchQuerySubscriptionOptions,
      subscribeToMoreOptions
    });
  }

  loadMoreUsersConversationQueueHistory(observedQuery, nextToken: string, fetchMoreOptions) {
    return this.loadMore(observedQuery, nextToken, fetchMoreOptions);
  }

  subscribeToUpdateConversationQueueCount(params: ISubscriptionOptions): Promise<ZenObservable.Subscription> {
    const options = _.merge({ onComplete: () => null, onError: () => this.forceReloadApp() }, params);

    this.logService.log('[SubscriptionService subscribeToUpdateConversationQueueCount]');

    return this.subscription({
      query: subscriptions.subscribeToUpdateConversationQueueCount,
      realtimeResults: options.realtimeResults,
      onComplete: options.onComplete,
      onError: options.onError
    });
  }
}
