import { Injectable } from '@angular/core';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';

@Injectable({
  providedIn: 'root',
})
export class CannedResponseHelper {
  private allCannedResponses;

  constructor(
    private apiService: ApiService,
    private logService: LogService,
    private auth: AuthHelper,
  ) {
  }

  public async getAllCannedResponses() {
    if (!this.allCannedResponses) {
      const data: any = await this.apiService.getCannedResponses().toPromise();
      this.logService.debug('[CannedResponseHelper getAllCannedResponses]', data);

      if (!data || !data.Items) {
        return;
      }

      data.Items.forEach(item => {
        item.response = this.formatTemplate(item.response, this.auth.userProfile);
        if (item.shortResponse) {
          item.shortResponse = this.formatTemplate(item.shortResponse, this.auth.userProfile);
        }
      });

      data.Items.sort(function (a, b) {
        return a.title.localeCompare(b.title);
      });

      this.allCannedResponses = data.Items;
    }

    return this.allCannedResponses;
  }

  /**
   * Replace placeholders with given values
   * @param {*} format
   * @param {*} values
   */
  private formatTemplate(format, values) {
    if (values) {
      return format.replace(/{\w+}/g, token => {
        return values[ token.substring(1, token.length - 1) ] || token;
      });
    } else {
      return format;
    }
  }
}
