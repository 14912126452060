import { Route } from '@angular/router';
import { Role } from '@cation/core/auth/role.enum';
import { ChatComponent } from '../components/chat/chat.component';
import { ReviewUserComponent } from '../components/review-user/review-user.component';
import { ReviewFeedbackComponent } from '../components/review-feedback/review-feedback.component';
import { TeamsStatisticsComponent } from '../components/teams-statistics/teams-statistics.component';
import { RouteDataModel } from '@cation/core/models/route-data.model';

export const teamLeadRoutesConfig: Route[] = [
  {
    path: 'review/user',
    component: ReviewUserComponent,
    data: {
      roles: [Role.TEAM_LEAD],
      label: 'NAVBAR.REVIEW_USERS_LINK',
    } as RouteDataModel,
  },
  {
    path: 'review/feedback',
    component: ReviewFeedbackComponent,
    data: {
      roles: [Role.TEAM_LEAD],
      label: 'NAVBAR.REVIEW_FEEDBACK_LINK',
    } as RouteDataModel,
  },
  {
    path: 'teams/statistics',
    component: TeamsStatisticsComponent,
    data: {
      roles: [Role.TEAM_LEAD],
      label: 'NAVBAR.TEAMS_STATISTICS_LINK',
    } as RouteDataModel,
  },
  {
    path: 'current-conversations',
    component: ChatComponent,
    data: {
      roles: [Role.TEAM_LEAD],
      label: 'NAVBAR.CURRENT_CONVERSATIONS_LINK',
    } as RouteDataModel,
  },
];
