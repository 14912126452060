import gql from 'graphql-tag';

export default gql`
  mutation readMessage($conversationId: ID!, $createdAt: String!) {
    readMessage(conversationId: $conversationId, createdAt: $createdAt) {
      __typename
      conversationId
      createdAt
      attachment {
        id
        key
        type
        size
        name
      }
      id
      sender
      content
      isSent
      isRead
      isQuestion
    }
  }
`;
