import * as update from 'immutability-helper';
import {
  getUserChatsConnectionThroughUserQuery as ChatsQuery,
  getChatMessagesQuery as MessagesQuery
} from '../graphql/operation-result-types';
import { Chat, Message, UserChat } from '../types';

import * as _ from 'lodash';

export const constants = {
  chatFirst: 20,
  messageFirst: 20
};

/** MESSAGES */
export function unshiftMessage(data: MessagesQuery, message: Message): MessagesQuery {
  if (!data || !_.has(data, 'getMessages.messages')) {
    return {
      getMessages: {
        nextToken: null,
        __typename: 'MessageConnection',
        messages: []
      }
    };
  }

  if (data.getMessages.messages.some(m => m.id === message.id)) {
    return data;
  }

  return update(data, {
    getMessages: {
      messages: { $unshift: [message] }
    }
  });
}

export function unshiftMessages(data: MessagesQuery, messages, nextToken): MessagesQuery {
  if (!data || !_.has(data, 'getMessages.messages')) {
    return {
      getMessages: {
        nextToken: null,
        __typename: 'MessageConnection',
        messages: []
      }
    };
  }

  return update(data, {
    getMessages: {
      messages: { $push: messages },
      nextToken: { $set: nextToken }
    }
  });
}

/** CHATS */

export function addChat(data: ChatsQuery, uc: UserChat): ChatsQuery {
  if (!data || !_.has(data, 'me.chats.userChats')) {
    return {
      me: {
        chats: {
          nextToken: null,
          __typename: 'UserChatsConnection',
          userChats: []
        }
      }
    };
  }

  if (data.me.chats.userChats.some(_uc => uc.chatId === _uc.chatId)) {
    return data;
  }

  return update(data, {
    me: {
      chats: {
        userChats: {
          $push: [uc]
        }
      }
    }
  });
}

export function addChats(data: ChatsQuery, ucs: UserChat[], nextToken: string): ChatsQuery {
  if (!data || !_.has(data, 'me.chats.userChats')) {
    return {
      me: {
        chats: {
          nextToken: null,
          __typename: 'UserChatsConnection',
          userChats: []
        }
      }
    };
  }

  const newRecords = _.differenceBy(ucs, data.me.chats.userChats, 'chatId');

  return update(data, {
    me: {
      chats: {
        userChats: { $push: newRecords },
        nextToken: { $set: nextToken }
      }
    }
  });
}

export function updateUserChat(data: ChatsQuery, uc: UserChat): ChatsQuery {
  if (!data || !_.has(data, 'me.chats.userChats')) {
    return {
      me: {
        chats: {
          nextToken: null,
          __typename: 'UserChatsConnection',
          userChats: []
        }
      }
    };
  }

  const chatIndex = data.me.chats.userChats.findIndex(_uc => uc.chatId === _uc.chatId);

  if (chatIndex === -1) {
    return update(data, { me: { chats: { userChats: { $push: [uc] } } } });
  }

  const chat = data.me.chats.userChats[chatIndex];

  return update(data, { me: { chats: { userChats: { $splice: [[chatIndex, 1, _.merge(chat, uc)]] } } } });
}

export function addUserChat(data: ChatsQuery, uc: UserChat): ChatsQuery {
  if (!data || !_.has(data, 'me.chats.userChats')) {
    return {
      me: {
        chats: {
          nextToken: null,
          __typename: 'UserChatsConnection',
          userChats: []
        }
      }
    };
  }

  const chatIndex = data.me.chats.userChats.findIndex(_uc => uc.chatId === _uc.chatId);

  if (chatIndex !== -1) {
    return data;
  }

  return update(data, { me: { chats: { userChats: { $push: [uc] } } } });
}

export function deleteUserChat(data: ChatsQuery, uc: UserChat): ChatsQuery {
  if (!data || !_.has(data, 'me.chats.userChats')) {
    return {
      me: {
        chats: {
          nextToken: null,
          __typename: 'UserChatsConnection',
          userChats: []
        }
      }
    };
  }

  const chatIndex = data.me.chats.userChats.findIndex(_uc => uc.chatId === _uc.chatId);

  if (chatIndex === -1) {
    return data;
  }

  return update(data, { me: { chats: { userChats: { $splice: [[chatIndex, 1]] } } } });
}

export const delay = timeout => new Promise(resolve => setTimeout(resolve, timeout));

export const escapeStr = message =>
  message
    .replace(/[\\"]/g, '\\$&')
    .replace(/\u0000/g, '\\0')
    .replace(/\//gm, '\\/')
    .replace(/\n/g, '\\n')
    .replace(/\t/g, '\\t');

export const handleErrors = response => {
  if (!response.ok) {
    throw Error(`Error: ${response.status} ${response.statusText}`);
  }
  return response.json();
};
