import { DateRangeModel } from './models/date-range.model';
import { StatsFilterOptionEnum } from '../widget-header/enums/stats-filter-option.enum';
import { statsFilterOptionsMap } from './stats-filter-options.map';
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { StatsTypeEnum } from './enums/stats-type.enum';
import { IUserConversationStatistics } from '@cation/core/types/api';
import { forkJoin, Subscription } from 'rxjs';
@Component({
  selector: 'app-conversation-statistics-widget',
  templateUrl: './conversation-statistics-widget.component.html',
  styleUrls: ['./conversation-statistics-widget.component.scss'],
})
export class ConversationStatisticsWidgetComponent implements OnInit, OnDestroy {
  @Input() userId: string;

  isLoading = false;

  statsTypeEnum = StatsTypeEnum;

  singleStatistics: IUserConversationStatistics;
  rangeStatistics: IUserConversationStatistics[];

  conversationsPerHourStatistics: IUserConversationStatistics;
  conversationsStatistics: IUserConversationStatistics[];
  starsStatistics: IUserConversationStatistics[];

  initDateFilter = StatsFilterOptionEnum.sevenDays;
  selectedStarsDateFilterValue = this.initDateFilter;
  selectedConvDateFilterValue = this.initDateFilter;

  private subscriptions: Subscription[] = [];

  constructor(private apiService: ApiService, private logService: LogService) {}

  ngOnInit() {
    this.getStatistics(this.initDateFilter, [
      StatsTypeEnum.conversations,
      StatsTypeEnum.stars,
      StatsTypeEnum.conversationsPerHour,
    ]);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  async getUserConversationStatistics(dateRange: DateRangeModel) {
    this.isLoading = true;

    try {
      const { startDate, endDate } = dateRange;
      this.singleStatistics = await this.apiService.getUserConversationStatistics({
        startDate,
        endDate,
        userId: this.userId,
      });

      this.logService.log(
        '[ConversationStatisticsWidgetComponent getUserConversationStatistics:statistics]',
        this.singleStatistics
      );
    } catch (e) {
      this.logService.error('[ConversationStatisticsWidgetComponent getUserConversationStatistics:error]', e);
    }
    this.isLoading = false;
  }

  async getUserConversationStatisticsForRange(dateRange: DateRangeModel) {
    this.isLoading = true;

    try {
      const { startDate, endDate } = dateRange;
      const { items } = await this.apiService.getUserConversationStatisticsForRange({
        userId: this.userId,
        startDate,
        endDate,
      });

      this.rangeStatistics = items;

      this.logService.log(
        '[ConversationStatisticsWidgetComponent getUserConversationStatisticsForRange:statistics]',
        items
      );
    } catch (e) {
      this.logService.error('[ConversationStatisticsWidgetComponent getUserConversationStatisticsForRange:error]', e);
    }
    this.isLoading = false;
  }

  getStatistics(statsFilterOption: StatsFilterOptionEnum, statsTypes: StatsTypeEnum[]) {
    const dateRange: DateRangeModel = this.getStatsFilterValue(statsFilterOption);
    const sources = [this.getUserConversationStatistics(dateRange)];

    if (statsFilterOption !== StatsFilterOptionEnum.today) {
      sources.push(this.getUserConversationStatisticsForRange(dateRange));
    }

    const sub = forkJoin(sources).subscribe(() => {
      statsTypes.forEach((type) => {
        this.setStatistics(statsFilterOption, type);
      });
    });

    this.subscriptions.push(sub);
  }

  private getStatsFilterValue(key: StatsFilterOptionEnum): DateRangeModel {
    return statsFilterOptionsMap.get(key);
  }

  setStatistics(statsFilterOption: StatsFilterOptionEnum, statsType: StatsTypeEnum) {
    if (statsType === StatsTypeEnum.conversations) {
      if (statsFilterOption === StatsFilterOptionEnum.today) {
        this.conversationsStatistics = [this.singleStatistics];
      } else {
        this.conversationsStatistics = this.rangeStatistics;
      }
    }

    if (statsType === StatsTypeEnum.stars) {
      if (statsFilterOption === StatsFilterOptionEnum.today) {
        this.starsStatistics = [this.singleStatistics];
      } else {
        this.starsStatistics = this.rangeStatistics;
      }
    }

    if (statsType === StatsTypeEnum.conversationsPerHour) {
      this.conversationsPerHourStatistics = this.singleStatistics;
    }
  }

  onDataFilterChanged(statsFilterOption: StatsFilterOptionEnum, statsType: StatsTypeEnum) {
    switch (statsType) {
      case StatsTypeEnum.conversations:
        this.selectedConvDateFilterValue = statsFilterOption;
        break;
      case StatsTypeEnum.stars:
        this.selectedStarsDateFilterValue = statsFilterOption;
        break;
      default:
        console.error('Stats Filter option not defined');
        return [];
    }

    this.getStatistics(statsFilterOption, [statsType]);

    return undefined;
  }
}
