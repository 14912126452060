<div style="display: grid;" class="w-100 h-100">
  <ngx-charts-line-chart
    #chart
    [curve]="curve"
    [legend]="showLegend"
    [legendTitle]="'MY_STATISTICS.LEGEND_TITLE' | translate"
    [xAxis]="true"
    [yAxis]="true"
    [timeline]="false"
    [autoScale]="true"
    [results]="multi"
    [roundDomains]="true"
    [scheme]="colorScheme"
    [rotateXAxisTicks]="true"
    [xAxisTicks]="xAxisTicks"
    [yAxisTickFormatting]="onValueFormat"
  >
    <ng-template #tooltipTemplate *ngIf="metricField === 'conversationsDuration'" let-model="model">
      <div class="d-flex flex-column p-2">
        <span>{{ model.series }} &bull; {{ model.name }}</span>
        <span>{{ onValueFormat(model.value) }}</span>
      </div>
    </ng-template>
    <ng-template #seriesTooltipTemplate *ngIf="metricField === 'conversationsDuration'" let-model="model">
      <div class="d-flex flex-column series-tooltip-container justify-content-center align-items-left">
        <div *ngFor="let mod of model; let i = index">
          <div class="d-flex align-items-center p-0">
            <div class="series-tooltip__color" [style.background-color]="mod.color"></div>
            <span class="series-tooltip__item">{{ mod.series }} - {{ onValueFormat(mod.value) }}</span>
          </div>
        </div>
      </div>
    </ng-template>
  </ngx-charts-line-chart>
</div>
