<div class="text-center">
  <h1 mat-dialog-title class="d-inline-block">{{ data.name }}</h1>
  <button mat-icon-button [tabIndex]="-1" type="button" class="close" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<ngx-ui-loader *ngIf="isLoading" [fgsSize]="25" [loaderId]="loaderId"></ngx-ui-loader>
<div
  *ngIf="!isLoading"
  [ngSwitch]="mapTypeToAttachmentTypes[data.type]"
  class="content-container d-flex justify-content-center align-items-center"
>
  <img class="content" *ngSwitchCase="ATTACHMENT_TYPE.IMAGE" [src]="attachmentUrl" [alt]="data.name" />

  <embed
    *ngSwitchCase="ATTACHMENT_TYPE.PDF"
    [type]="data.type"
    [src]="sanitizer.bypassSecurityTrustResourceUrl(attachmentUrl)"
    class="pdf-container h-100"
    frameborder="0"
    width="100%"
    height="100%"
    allowfullscreen
  />

  <h3 *ngSwitchDefault>
    {{ 'PREVIEW_ATTACHMENT_DIALOG.TYPE_IS_NOT_SUPPORTED' | translate: { type: data.type } }}
  </h3>
</div>
