import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import * as _ from 'lodash';
import { LogService } from '@cation/core/services/log/log.service';
import { ApiService } from '@cation/core/services/api/api.service';
import { ApiHook } from '@cation/core/services/api/api-hook';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { Role } from '@cation/core/auth/role.enum';
import User from '@cation/core/types/user';
import Team from '@cation/core/types/team';

type Agent = User & { team: Team };

@Component({
  selector: 'app-agent-input-autocomplete',
  templateUrl: './agent-input-autocomplete.component.html',
  styleUrls: ['./agent-input-autocomplete.component.scss']
})
export class AgentInputAutocompleteComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() hint: string;
  @Input() errorLabel: string;
  @Input() placeholder: string;
  @Input() placeholderLabel: string;
  @Input() required = false;
  @Input() teamLeadId: string = null;
  @Input() initialAgentId: string;
  @Input() withTeam: boolean = false;
  @Input() excludeMe: boolean = true;
  @Input() isOnlineOnly: boolean = false;
  @Input() set excludeIds(excludeIds: string[]) {
    this._excludeIds = excludeIds;

    if (this.agents) {
      this.filteredAgents.next(this.agents.filter(a => !excludeIds.includes(a.cognitoId)));

      this.agentCtrl.setValue(null);
    }
  }
  @Input() roles: Role[] = [];

  @Output() selectionChange = new EventEmitter<Agent>();
  @Output() agentsLoad = new EventEmitter<Agent[]>();

  public isLoading = false;
  public isFinding = false;
  public agents: Agent[] = [];
  /** control for the selected team leads */
  public agentCtrl: UntypedFormControl = new UntypedFormControl();
  /** control for the MatSelect filter keyword */
  public agentsFilterCtrl: UntypedFormControl = new UntypedFormControl();
  /** list of users filtered by search keyword */
  public filteredAgents: ReplaySubject<Agent[]> = new ReplaySubject<Agent[]>(1);
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  private _excludeIds: string[] = [];

  constructor(
    private apiService: ApiService,
    public apiHook: ApiHook,
    private logService: LogService,
    private authHelper: AuthHelper
  ) {}

  async ngOnInit() {
    await this.searchAgents();

    this.filteredAgents.next(this.agents.slice() || []);

    this.agentCtrl.valueChanges.subscribe(v => this.selectionChange.emit(v));

    if (this.initialAgentId) {
      this.agentCtrl.setValue(this.agents.find(t => t.cognitoId === this.initialAgentId));
    }

    this.agentsFilterCtrl.valueChanges
      .pipe(
        debounceTime(200),
        takeUntil(this._onDestroy)
      )
      .subscribe(() => this.filterAgents());
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  public trackItem(index, item) {
    return item ? item.cognitoId : undefined;
  }

  protected setInitialValue() {
    this.filteredAgents
      .pipe(
        take(1),
        takeUntil(this._onDestroy)
      )
      .subscribe(() => {
        this.singleSelect.compareWith = (a, b) => a && b && a.cognitoId === b.cognitoId;
      });
  }

  protected filterAgents() {
    let search = this.agentsFilterCtrl.value;
    if (!search) {
      this.filteredAgents.next(this.agents.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredAgents.next(
      this.agents.filter(
        agent =>
          _.get(agent, 'userData.nickname', '')
            .toLowerCase()
            .includes(search) || agent.username.toLowerCase().includes(search)
      )
    );
  }

  async searchAgents() {
    try {
      const body = Object.assign(
        { isOnlineOnly: this.isOnlineOnly },
        this.roles && this.roles.length ? { roles: this.roles } : {},
        this.excludeMe ? { cognitoId: this.authHelper.userProfile.cognitoId } : {},
        this.teamLeadId ? { teamLeadId: this.teamLeadId } : {}
      );
      const agents = await this.apiService.getListAgents(body);
      this.agents = agents.filter(a => !this._excludeIds.includes(a.cognitoId));
      this.agentsLoad.emit(this.agents);
      this.logService.log('[AgentInputAutocompleteComponent searchAgents:agents]', this.agents);
    } catch (e) {
      this.logService.error('searchAgents:error', e);
    }
  }

  resetAgent($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.agentCtrl.setValue(null);
  }
}
