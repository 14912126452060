import { EventEmitter } from '@angular/core';
import { ApiService } from '@cation/core/services/api/api.service';
import { CMS_TYPE } from '@cation/core/enums/cms-type.enum';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { S3Service } from '@cation/core/services/s3/s3.service';
import * as _ from 'lodash';
import { IAnyObject, ICMS, IComment } from '../../interfaces/cms';

export default class Zendesk implements ICMS {
  type = CMS_TYPE.ZENDESK;

  id: string | number;
  name: string;
  title: string;
  description: string;
  comments: IComment[] = [];
  createdAt: number;

  isInitialized = false;
  isDataLoading = false;
  isCommentsLoading = false;

  onAddNewComment = new EventEmitter<IAnyObject>();

  constructor(data: IAnyObject, private api: ApiService, private auth: AuthHelper, private s3: S3Service) {
    if (data.lazyLoad) {
      this.id = data.id;
      this.name = `ZENDESK: #${data.id}`; // TODO:

      return;
    }

    this.updateData(data);
  }

  async init() {
    if (this.isInitialized) {
      return;
    }

    this.isDataLoading = true;

    const data = await this.api.getCmsById(this.id, CMS_TYPE.ZENDESK).toPromise();

    this.isDataLoading = false;

    this.updateData(data);
  }

  private updateData(data: IAnyObject) {
    this.id = data.id;
    this.name = `ZENDESK: #${data.id}`; // TODO:
    this.title = data.subject;
    this.description = data.description;

    this.isInitialized = true;

    this.getListComments();
  }

  async addComment(message) {
    try {
      const payload = { id: this.id, message, authorId: this.auth.cmsProfiles[CMS_TYPE.ZENDESK].id };
      const data = await this.api.addCommentToCms(payload, CMS_TYPE.ZENDESK).toPromise();
      const body = await this.s3.updateAccessToImageUrls(message);

      this.comments.push({
        id: Date.now(),
        body,
        createdAt: new Date().toISOString(),
        author: this.auth.cmsProfiles[CMS_TYPE.ZENDESK],
        attachments: []
      });

      this.onAddNewComment.emit();

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  changeStatus() {}

  async getListComments() {
    this.isCommentsLoading = true;

    try {
      const { comments, users } = (await this.api.getListCommentsByCms(this.id, CMS_TYPE.ZENDESK).toPromise()) as {
        comments: any[];
        users: any[];
      };

      const mappedUsers = _.keyBy(users, 'id');

      const updatedComments = [];

      for (const c of comments) {
        const body = await this.s3.updateAccessToImageUrls(c.html_body);

        const comment = {
          id: c.id,
          body,
          createdAt: c.created_at,
          author: mappedUsers[c.author_id],
          attachments: c.attachments
            ? c.attachments.map(at => ({
                type: at.content_type,
                name: at.file_name,
                url: at.content_url
              }))
            : []
        };
        updatedComments.push(comment);
      }

      this.comments = updatedComments;
    } catch (e) {
      console.error('[Zendesk getListComments:error]', e);
    }

    this.isCommentsLoading = false;
  }

  toJSON() {
    return '';
  }
}
