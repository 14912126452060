import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Analytics } from 'aws-amplify';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { AppsyncMessageService } from '@cation/core/services/appsync/appsync-message.service';
import { AppSyncApiService } from '@cation/core/services/api/appsync-api.service';
import { ApiService } from '@cation/core/services/api/api.service';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { Channel, OnlyTextChannels } from '@cation/core/enums/channel.enum';
import { getLocaleByI18nLocale } from '@cation/core/util/common-util';
import { ICMS } from '@cation/core/interfaces/cms';
import Message from '@cation/core/types/message';
import Conversation from '@cation/core/types/conversation';
import { AuthHelper } from '@cation/core/auth/auth-helper';

const EDITOR_STYLES = {
  collapsed: { maxHeight: '100px', overflow: 'auto' },
  expanded: { height: '50vh', overflow: 'auto' },
};

@Component({
  selector: 'app-chat-input',
  templateUrl: './chat-input.component.html',
  styleUrls: ['./chat-input.component.scss'],
})
export class ChatInputComponent implements OnInit {
  private _locale;
  private _message = '';
  private _conversation;
  private typing = true;
  private connected = 'true';

  public editorStyles: {} = EDITOR_STYLES.collapsed;
  public quillModules = {
    toolbar: {
      container: [
        [
          'bold',
          'italic',
          'underline',
          { list: 'bullet' },
          { color: [] },
          { background: [] },
          { header: 1 },
          { header: 2 },
          { header: [3, 4, 5, 6, false] },
          'link',
          'image',
          'video',
          'file',
          'send',
          'enlarge',
        ],
      ],
      handlers: {
        file: () => {},
        send: this.createNewMessage.bind(this),
        enlarge: this.toggleEnlargeInput.bind(this),
      },
    },
  };
  private editorInstance;
  uploadPercent = 100;

  @Input() currentCms?: ICMS;
  @Input() coreCms?: ICMS;

  @Input() senderId: string;

  @Input() set conversation(conv: Conversation) {
    this._conversation = conv;
    this._locale = conv.locale;
    this.isMainAgent = (conv.associated.find((a) => a.userId === this.senderId) || { role: '' }).role === 'AGENT';
  }

  @ViewChild('hashTagInput', { static: true }) private hashTagInput: ElementRef;
  @ViewChild('hashTagTypeaheadInstance', { static: true })
  private hashTagTypeaheadInstance: NgbTypeahead;
  public hiddenHashTag = true;
  private hashTagRetain = 0;
  public hashTag = '';
  private cannedResponses = [];
  public isMainAgent = false;
  public isInputEnlarged = false;

  constructor(
    private sharedService: SharedService,
    private authHelper: AuthHelper,
    private appSyncApiService: AppSyncApiService,
    private appsyncMessageService: AppsyncMessageService,
    private translate: TranslateService,
    private logService: LogService,
    private s3Service: S3Service,
    private apiService: ApiService
  ) {
    this.sendTypingAction = _.throttle(this.sendTypingAction, 1000);
    this.getUploadImageConfig = this.getUploadImageConfig.bind(this);
    this.createNewMessage = this.createNewMessage.bind(this);
    this.sendTypingStatus = _.throttle(this.sendTypingStatus, 1000);
  }

  ngOnInit(): void {
    this.sharedService.getPastedUserMessageEmitter().subscribe((text) => (this.message = text));
    this.sharedService.currentCannedResponses.subscribe((data) => (this.cannedResponses = data));

    this.translate.onLangChange.subscribe(({ lang }) => {
      this.locale = getLocaleByI18nLocale(lang);
    });

    this.sharedService.getGoToChatInputEmitter().subscribe(() => this.editorInstance?.focus());
  }

  get locale() {
    return this._locale;
  }

  set locale(value: string) {
    if (value !== this._locale) {
      this._locale = value;
    }
  }

  get conversation(): Conversation {
    return this._conversation;
  }

  get message(): string {
    return this._message;
  }

  set message(value: string) {
    this._message = value;
    if (value && !this.currentCms) {
      this.sendTypingAction();

      if (this.conversation.channel === Channel.EHUB) {
        this.sendTypingStatus();
      }
    }
  }

  getUploadImageConfig = () => {
    return Promise.resolve({
      key: `img/${this.conversation.id}/${uuid()}`,
      level: 'public',
      progressCallback: (progress) => (this.uploadPercent = Math.floor((progress.loaded * 100) / progress.total)),
    });
  };

  getUploadFileConfig = () => {
    return Promise.resolve({
      key: `file/${this.conversation.id}/${uuid()}`,
      level: 'public',
      progressCallback: (progress) => (this.uploadPercent = Math.floor((progress.loaded * 100) / progress.total)),
    });
  };

  public search = (text$: Observable<string>) => {
    const hashTagFilter = (v, term) => v.hashtag && v.hashtag.toLowerCase().includes(term.toLowerCase());
    const responseMap = (term) => this.cannedResponses.filter((v) => hashTagFilter(v, term)).slice(0, 5);

    return text$.pipe(debounceTime(200), distinctUntilChanged(), map(responseMap));
  };

  public onEditorCreated(editorInstance) {
    this.editorInstance = editorInstance;
    this.editorInstance.container.appendChild(this.hashTagInput.nativeElement);

    const uploadImageHandler = this.s3Service.makeQuillImageHandler(this.editorInstance, this.getUploadImageConfig);
    this.editorInstance.getModule('toolbar').addHandler('image', uploadImageHandler);

    const uploadFileHandler = this.s3Service.makeQuillFileHandler(this.editorInstance, this.getUploadFileConfig);
    this.editorInstance.getModule('toolbar').addHandler('file', uploadFileHandler);

    this.attachCustomButtonToEditor('file', 'input_attach_file_icon');
    this.attachCustomButtonToEditor('enlarge', 'input_enlarge_icon', 'position: absolute; right: 50px;');
    this.attachCustomButtonToEditor('send', 'input_send_icon', 'position: absolute; right: 12px;');

    this.editorInstance.keyboard.bindings[13].unshift({
      key: 13,
      shortKey: true,
      handler: this.createNewMessage,
    });
  }

  public onContentChanged(data) {
    const {
      delta: { ops },
    } = data;

    const range = this.editorInstance.getSelection();
    if (!range) {
      return;
    }
    const bounds = this.editorInstance.getBounds(range);

    const isHashTagOpen = _.some(ops, { insert: '#' });

    if (isHashTagOpen && this.hiddenHashTag) {
      this.hashTagRetain = _.get(ops, '[0].retain', 0);
      this.hiddenHashTag = false;
      this.hashTagInputFocus();
    }

    if (this.hiddenHashTag) {
      return;
    }

    setTimeout(() => this.hashTagInput.nativeElement.focus(), 0);

    this.hashTagInput.nativeElement.style.left = `${bounds.left}px`;
    this.hashTagInput.nativeElement.style.top = `${bounds.top + bounds.height}px`;
  }

  public hashTagFormatter = (item) => item.hashtag;

  public async onSelectItem($event) {
    $event.preventDefault();

    this.editorInstance.deleteText(this.hashTagRetain, 1, 'user');
    const content = await this.s3Service.updateAccessToImageUrls(this.byLocale($event.item, 'response'));
    this.editorInstance.clipboard.dangerouslyPasteHTML(this.hashTagRetain, content, 'user');

    setTimeout(() => {
      this.editorInstance.focus();
      this.editorInstance.setSelection(this.hashTagRetain + $event.item.response.length);
    });

    this.hashTag = '';
    this.hiddenHashTag = true;
  }

  private async sendTypingStatus() {
    const body = {
      conversationId: this.conversation.id,
      userId: this.authHelper.userProfile.cognitoId,
      agentName: this.authHelper.userProfile.username,
      channel: this.conversation.channel,
    };
    await this.apiService.sendTypingStatus(body);
  }

  private byLocale(obj, attr) {
    if (!obj) return '';
    return obj[attr + '_' + this.locale] || obj[attr];
  }

  private hashTagInputFocus(): void {
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      this.hashTagInput.nativeElement.dispatchEvent(inputEvent);
    });
  }

  public onBlur(e: Event): void {
    setTimeout(() => this.dismissPopup(e), 200);
  }

  public onImagePaste(e) {
    this.s3Service.uploadImageFromBuffer(e, this.editorInstance, this.getUploadImageConfig);
  }

  private dismissPopup(e): void {
    e.preventDefault();
    e.stopPropagation();
    this.hashTagTypeaheadInstance.dismissPopup();
    this.hiddenHashTag = true;
  }

  public onKeydown(e: KeyboardEvent): boolean {
    const dismiss = () => {
      this.dismissPopup(e);
      this.editorInstance.focus();
      this.editorInstance.setSelection(this.hashTagRetain + 1, 0);
    };

    if (' ' === e.key) {
      const result = this.hashTagTypeaheadInstance['_windowRef'].instance.getActive();
      this.hashTagTypeaheadInstance['_selectResult'](result);
      dismiss();
      return false;
    }
    if ('Escape' === e.key || ('Backspace' === e.key && !this.hashTag.length)) {
      dismiss();
    }

    return false;
  }

  sendTypingAction() {
    this.typing = true;
    this.updateUserConversation();
  }

  isValidMessage(text) {
    const aReg = /<\s*a[^>]*>/g;
    const imgReg = /<\s*img[^>]*>/g;
    const iframeReg = /<\s*iframe[^>]*>/g;

    return aReg.test(text) || imgReg.test(text) || iframeReg.test(text) || !!text;
  }

  createNewMessage() {
    let text = (this.message || '').trim();
    const textContent = this.editorInstance.getText(0).trim();
    if (OnlyTextChannels.includes(this.conversation.channel as Channel)) {
      text = textContent;
    }
    if (!this.isValidMessage(text) && !textContent) {
      return Promise.reject('Empty message');
    }

    if (this.currentCms) {
      this.message = '';
      return this.currentCms.addComment(text);
    }

    if (this.coreCms) {
      this.coreCms.addComment(`Agent[${this.authHelper.userProfile.username}]: ${text}`);
    }

    const createdAt = new Date().toISOString();
    const id = `${createdAt}_${uuid()}`;
    const message: Message = {
      conversationId: this.conversation.id,
      attachment: null,
      content: this.s3Service.removeSignFromImageUrls(text),
      createdAt,
      sender: this.senderId,
      isQuestion: null,
      isSent: false,
      isRead: true,
      id: id,
    };
    this.logService.log('new message', message);
    this.message = '';
    return this.appsyncMessageService
      .createNewMessage(this.conversation.id, message)
      .then(({ data }) => {
        this.logService.log('mutation complete', data);
        return data;
      })
      .catch((err) => this.logService.log('Error creating message', err))
      .then(() => Analytics.record('Chat MSG Sent'));
  }

  async updateUserConversation() {
    const input = {
      conversationId: this.conversation.id,
      userId: this.senderId,
      typing: this.typing,
      connected: this.connected,
    };
    await this.appSyncApiService.updateUserConversation(input);
  }

  private attachCustomButtonToEditor(containerId, iconId, styles = '') {
    const buttonContainer = this.editorInstance
      .getModule('toolbar')
      .container.querySelector(`button.ql-${containerId}`);
    buttonContainer.style = `${buttonContainer.style}; padding: 0; ${styles}`;
    const icon = document.getElementById(iconId);
    buttonContainer.appendChild(icon);
  }

  private toggleEnlargeInput() {
    this.isInputEnlarged = !this.isInputEnlarged;

    this.editorStyles = this.isInputEnlarged ? EDITOR_STYLES.expanded : EDITOR_STYLES.collapsed;
  }
}
