<div class="row my-statistics2-list-page px-3 pb-2">
  <mat-card class="my-statistics2-card w-100">
    <mat-card-header>
      <div mat-card-avatar class="d-flex justify-content-center align-items-center">
        <mat-icon>timeline</mat-icon>
      </div>
      <mat-card-title class="d-flex align-items-start justify-content-between">
        <div class="d-flex">{{ title | translate }}</div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="overflow-hidden h-100">
      <div *ngIf="isLoading" class="my-statistics-loader" [@fadeInOut]>
        <mat-spinner mode="indeterminate"></mat-spinner>
      </div>

      <mat-tab-group
        class="vertical-tab-group"
        [selectedIndex]="selectedTab.value"
        (selectedIndexChange)="changeSelectedIndexTab($event)"
      >
        <mat-tab [label]="'MY_STATISTICS.TOTAL_TAB_TITLE' | translate">
          <mat-tab-group
            class="mat-tab-group__filter"
            [selectedIndex]="filterSelectedTab[selectedTab.value].value"
            (selectedIndexChange)="changeSelectedFilterTab($event)"
            #totalTabs
          >
            <mat-tab [label]="'MY_STATISTICS.TODAY_TAB_TITLE' | translate">
              <div
                class="my-statistics-metrics row px-3 py-1"
                *ngIf="isAllowToRender(todayFilter)"
                [@listFadeInOut]="6"
              >
                <ng-container *ngTemplateOutlet="totalMetricsSet; context: getStatisticsContext()"></ng-container>
              </div>
            </mat-tab>
            <mat-tab [label]="'MY_STATISTICS.SINGLE_DATE_TAB_TITLE' | translate">
              <div class="d-flex px-3 mt-2">
                <mat-form-field class="statistics-date-picker p-0">
                  <input
                    type="date"
                    matInput
                    [max]="maxDate"
                    [(ngModel)]="statisticsDate"
                    [placeholder]="'CONVERSATION_SEARCH.DATE_PICKER_PLACEHOLDER' | translate"
                    required
                  />
                </mat-form-field>
              </div>
              <hr class="mt-0" />
              <div
                class="my-statistics-metrics row px-3 py-1"
                *ngIf="isAllowToRender(singleDateFilter)"
                [@listFadeInOut]="6"
              >
                <ng-container *ngTemplateOutlet="totalMetricsSet; context: getStatisticsContext()"></ng-container>
              </div>
            </mat-tab>
            <mat-tab [label]="'MY_STATISTICS.THIS_MONTH_TAB_TITLE' | translate">
              <div
                class="my-statistics-metrics row px-3 py-1"
                *ngIf="isAllowToRender(thisMonthFilter)"
                [@listFadeInOut]="3"
              >
                <ng-container
                  *ngTemplateOutlet="totalMetricsRangeSet; context: getStatisticsContext(true)"
                ></ng-container>
              </div>
            </mat-tab>
            <mat-tab [label]="'MY_STATISTICS.DATE_RANGE_TAB_TITLE' | translate">
              <div class="d-flex px-3 mt-2" *ngIf="isAllowToRender(dateRangeFilter)">
                <mat-form-field class="statistics-date-picker p-0">
                  <input
                    matInput
                    [placeholder]="'CONVERSATION_SEARCH.DATE_PICKER_PLACEHOLDER' | translate"
                    [satDatepicker]="picker"
                    [(ngModel)]="dateRange"
                    [max]="maxDate"
                    (keydown.enter)="onDateRangeChange()"
                    (dateChange)="onDateRangeChange()"
                  />
                  <sat-datepicker #picker [rangeMode]="true" [selectFirstDateOnClose]="true"></sat-datepicker>
                  <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
                </mat-form-field>
              </div>
              <hr class="mt-0" />
              <div
                class="my-statistics-metrics row px-3 py-1"
                *ngIf="isAllowToRender(dateRangeFilter)"
                [@listFadeInOut]="3"
              >
                <ng-container
                  *ngTemplateOutlet="totalMetricsRangeSet; context: getStatisticsContext(true)"
                ></ng-container>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>

        <mat-tab [label]="'MY_STATISTICS.BY_CHANNEL_TAB_TITLE' | translate">
          <mat-tab-group
            class="mat-tab-group__filter"
            [selectedIndex]="filterSelectedTab[selectedTab.value].value"
            (selectedIndexChange)="changeSelectedFilterTab($event)"
            #byChannelTabs
          >
            <mat-tab [label]="'MY_STATISTICS.TODAY_TAB_TITLE' | translate">
              <div
                class="my-statistics-metrics row px-3 py-1"
                *ngIf="isAllowToRender(todayFilter, 1)"
                [@listFadeInOut]="4"
              >
                <ng-container *ngTemplateOutlet="byChannelMetricsSet; context: getStatisticsContext()"></ng-container>
              </div>
            </mat-tab>
            <mat-tab [label]="'MY_STATISTICS.SINGLE_DATE_TAB_TITLE' | translate">
              <div class="d-flex px-3 mt-2">
                <mat-form-field class="statistics-date-picker p-0">
                  <input
                    type="date"
                    matInput
                    [max]="maxDate"
                    [(ngModel)]="statisticsDate"
                    [placeholder]="'CONVERSATION_SEARCH.DATE_PICKER_PLACEHOLDER' | translate"
                    required
                  />
                </mat-form-field>
              </div>
              <hr class="mt-0" />
              <div
                class="my-statistics-metrics row px-3 py-1"
                *ngIf="isAllowToRender(singleDateFilter, 1)"
                [@listFadeInOut]="4"
              >
                <ng-container *ngTemplateOutlet="byChannelMetricsSet; context: getStatisticsContext()"></ng-container>
              </div>
            </mat-tab>
            <mat-tab [label]="'MY_STATISTICS.THIS_MONTH_TAB_TITLE' | translate">
              <div
                class="my-statistics-metrics row px-3 py-1"
                *ngIf="isAllowToRender(thisMonthFilter, 1)"
                [@listFadeInOut]="4"
              >
                <ng-container
                  *ngTemplateOutlet="byChannelMetricsRangeSet; context: getStatisticsContext(true)"
                ></ng-container>
              </div>
            </mat-tab>
            <mat-tab [label]="'MY_STATISTICS.DATE_RANGE_TAB_TITLE' | translate">
              <div class="d-flex px-3 mt-2" *ngIf="isAllowToRender(dateRangeFilter, 1)">
                <mat-form-field class="statistics-date-picker p-0">
                  <input
                    matInput
                    [placeholder]="'CONVERSATION_SEARCH.DATE_PICKER_PLACEHOLDER' | translate"
                    [satDatepicker]="picker"
                    [(ngModel)]="dateRange"
                    [max]="maxDate"
                    (keydown.enter)="onDateRangeChange()"
                    (dateChange)="onDateRangeChange()"
                  />
                  <sat-datepicker #picker [rangeMode]="true" [selectFirstDateOnClose]="true"></sat-datepicker>
                  <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
                </mat-form-field>
              </div>
              <hr class="mt-0" />
              <div
                class="my-statistics-metrics row px-3 py-1"
                *ngIf="isAllowToRender(dateRangeFilter, 1)"
                [@listFadeInOut]="4"
              >
                <ng-container
                  *ngTemplateOutlet="byChannelMetricsRangeSet; context: getStatisticsContext(true)"
                ></ng-container>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>

        <mat-tab [label]="'MY_STATISTICS.BY_LOCALE_TAB_TITLE' | translate">
          <mat-tab-group
            class="mat-tab-group__filter"
            [selectedIndex]="filterSelectedTab[selectedTab.value].value"
            (selectedIndexChange)="changeSelectedFilterTab($event)"
            #byLocaleTabs
          >
            <mat-tab [label]="'MY_STATISTICS.TODAY_TAB_TITLE' | translate">
              <div
                class="my-statistics-metrics row px-3 py-1"
                *ngIf="isAllowToRender(todayFilter, 2)"
                [@listFadeInOut]="4"
              >
                <ng-container *ngTemplateOutlet="byLocaleMetricsSet; context: getStatisticsContext()"></ng-container>
              </div>
            </mat-tab>
            <mat-tab [label]="'MY_STATISTICS.SINGLE_DATE_TAB_TITLE' | translate">
              <div class="d-flex px-3 mt-2">
                <mat-form-field class="statistics-date-picker p-0">
                  <input
                    type="date"
                    matInput
                    [max]="maxDate"
                    [(ngModel)]="statisticsDate"
                    [placeholder]="'CONVERSATION_SEARCH.DATE_PICKER_PLACEHOLDER' | translate"
                    required
                  />
                </mat-form-field>
              </div>
              <hr class="mt-0" />
              <div
                class="my-statistics-metrics row px-3 py-1"
                *ngIf="isAllowToRender(singleDateFilter, 2)"
                [@listFadeInOut]="4"
              >
                <ng-container *ngTemplateOutlet="byLocaleMetricsSet; context: getStatisticsContext()"></ng-container>
              </div>
            </mat-tab>
            <mat-tab [label]="'MY_STATISTICS.THIS_MONTH_TAB_TITLE' | translate">
              <div
                class="my-statistics-metrics row px-3 py-1"
                *ngIf="isAllowToRender(thisMonthFilter, 2)"
                [@listFadeInOut]="4"
              >
                <ng-container
                  *ngTemplateOutlet="byLocaleMetricsRangeSet; context: getStatisticsContext(true)"
                ></ng-container>
              </div>
            </mat-tab>
            <mat-tab [label]="'MY_STATISTICS.DATE_RANGE_TAB_TITLE' | translate">
              <div class="d-flex px-3 mt-2" *ngIf="isAllowToRender(dateRangeFilter, 2)">
                <mat-form-field class="statistics-date-picker p-0">
                  <input
                    matInput
                    [placeholder]="'CONVERSATION_SEARCH.DATE_PICKER_PLACEHOLDER' | translate"
                    [satDatepicker]="picker"
                    [(ngModel)]="dateRange"
                    [max]="maxDate"
                    (keydown.enter)="onDateRangeChange()"
                    (dateChange)="onDateRangeChange()"
                  />
                  <sat-datepicker #picker [rangeMode]="true" [selectFirstDateOnClose]="true"></sat-datepicker>
                  <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
                </mat-form-field>
              </div>
              <hr class="mt-0" />
              <div
                class="my-statistics-metrics row px-3 py-1"
                *ngIf="isAllowToRender(dateRangeFilter, 2)"
                [@listFadeInOut]="4"
              >
                <ng-container
                  *ngTemplateOutlet="byLocaleMetricsRangeSet; context: getStatisticsContext(true)"
                ></ng-container>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>

<ng-template
  #totalMetricsSet
  let-statistics="statistics"
  let-stars="stars"
  let-points="points"
  let-totalAndDuration="totalAndDuration"
>
  <mat-card class="my-statistics-metrics__pie col-sm-12 col-lg-6 col-xl-4 mb-1">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.STARS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <div class="chart-parent">
        <ngx-charts-pie-chart
          [scheme]="colorScheme"
          [results]="stars"
          [gradient]="true"
          [legend]="true"
          [legendTitle]="'MY_STATISTICS.LEGEND_TITLE' | translate"
          [labels]="false"
          legendPosition="below"
        >
        </ngx-charts-pie-chart>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__pie col-sm-12 col-lg-6 col-xl-4 mb-1">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.POINTS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <div class="chart-parent">
        <ngx-charts-pie-chart
          [scheme]="colorScheme"
          [results]="points"
          [gradient]="true"
          [legend]="true"
          [legendTitle]="'MY_STATISTICS.LEGEND_TITLE' | translate"
          [labels]="false"
          legendPosition="below"
        >
        </ngx-charts-pie-chart>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__pie col-sm-12 col-lg-6 col-xl-4 mb-1">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.CONVERSATIONS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <div class="chart-parent">
        <ngx-charts-pie-chart
          [scheme]="colorScheme"
          [results]="totalAndDuration"
          [gradient]="true"
          [legend]="true"
          [legendTitle]="'MY_STATISTICS.LEGEND_TITLE' | translate"
          [labels]="false"
          legendPosition="below"
        >
        </ngx-charts-pie-chart>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="my-statistics-metrics__250 col-sm-12 col-xl-6 mb-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.STARS_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="h-100 w-100">
      <app-user-conversations-single-metric-chart [statistics]="statistics" metric="stars">
      </app-user-conversations-single-metric-chart>
    </mat-card-content>
  </mat-card>

  <mat-card class="my-statistics-metrics__250 col-sm-12 col-xl-6 mb-1">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.CONVERSATIONS_STARS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="h-100 w-100 d-flex align-items-center">
      <app-single-total-conversations-chart
        metric="stars"
        metricField="countConversations"
        colorSchemaName="material500"
        [statistics]="statistics"
      >
      </app-single-total-conversations-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__250 col-sm-12 col-xl-6 mb-1">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.FEEDBACK_STARS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="h-100 w-100 d-flex align-items-center">
      <app-single-total-conversations-chart
        metric="stars"
        metricField="countConversationFeedback"
        colorSchemaName="material500"
        [statistics]="statistics"
      >
      </app-single-total-conversations-chart>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #totalMetricsRangeSet let-statistics="statistics">
  <mat-card class="my-statistics-metrics__250 col-xl-6 col-sm-12 my-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.POINTS_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="h-100 w-100">
      <app-user-conversations-range-metric-chart [statistics]="statistics" statisticsField="points">
      </app-user-conversations-range-metric-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__250 col-xl-6 col-sm-12 my-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.STARS_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="h-100 w-100">
      <app-user-conversations-range-metric-chart [statistics]="statistics" statisticsField="stars">
      </app-user-conversations-range-metric-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__350 col-sm-12 my-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.STARS_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-multiple-total-conversations-chart metric="stars" colorSchemaName="green-red" [statistics]="statistics">
      </app-multiple-total-conversations-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__350 col-sm-12 my-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.NUMBER_CONVERSATIONS_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-multiple-total-conversations-chart
        metric="count-and-duration"
        metricField="countConversations"
        colorSchemaName="green-red"
        [showLegend]="false"
        [statistics]="statistics"
      >
      </app-multiple-total-conversations-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__350 col-sm-12 my-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.AVERAGE_CONVERSATIONS_DURATION_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-multiple-total-conversations-chart
        metric="count-and-duration"
        metricField="conversationsDuration"
        colorSchemaName="contrast"
        [statistics]="statistics"
      >
      </app-multiple-total-conversations-chart>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #byChannelMetricsSet let-statistics="statistics">
  <mat-card class="my-statistics-metrics__pie col-sm-12 mb-1">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.CONVERSATIONS_STARS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-single-total-conversations-chart
        metric="channel"
        colorSchemaName="green-red"
        [statistics]="statistics"
        [prepareValue]="prepareCountStarsForChart"
      >
      </app-single-total-conversations-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__pie col-sm-12 mb-1">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.CONVERSATIONS_POINTS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-single-total-conversations-chart
        metric="channel"
        colorSchemaName="green-red"
        [statistics]="statistics"
        [prepareValue]="prepareCountPointsForChart"
      >
      </app-single-total-conversations-chart>
    </mat-card-content>
  </mat-card>

  <mat-card class="my-statistics-metrics__pie col-sm-12 mb-1">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.NUMBER_CONVERSATIONS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-single-total-conversations-chart
        metric="channel"
        metricField="countConversations"
        colorSchemaName="green-red"
        [statistics]="statistics"
      >
      </app-single-total-conversations-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__pie col-sm-12 mb-1">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.DURATION_CONVERSATIONS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-single-total-conversations-chart
        *ngIf="statistics"
        metric="channel"
        metricField="conversationsDuration"
        colorSchemaName="material500"
        [statistics]="statistics"
      >
      </app-single-total-conversations-chart>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #byChannelMetricsRangeSet let-statistics="statistics">
  <mat-card class="my-statistics-metrics__350 col-sm-12 my-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.CONVERSATIONS_STARS_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-multiple-total-conversations-chart
        metric="channel"
        metricField="countConversationsStars"
        colorSchemaName="green-red"
        [statistics]="statistics"
      >
      </app-multiple-total-conversations-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__350 col-sm-12 my-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.CONVERSATIONS_POINTS_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-multiple-total-conversations-chart
        metric="channel"
        metricField="countConversationsPoints"
        colorSchemaName="material500"
        [statistics]="statistics"
      >
      </app-multiple-total-conversations-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__350 col-sm-12 my-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.NUMBER_CONVERSATIONS_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-multiple-total-conversations-chart
        metric="channel"
        metricField="countConversations"
        colorSchemaName="material500"
        [statistics]="statistics"
      >
      </app-multiple-total-conversations-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__350 col-sm-12 my-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.AVERAGE_CONVERSATIONS_DURATION_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-multiple-total-conversations-chart
        metric="channel"
        colorSchemaName="blue"
        metricField="conversationsDuration"
        [statistics]="statistics"
      >
      </app-multiple-total-conversations-chart>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #byLocaleMetricsSet let-statistics="statistics">
  <mat-card class="my-statistics-metrics__pie col-sm-12 mb-1">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.CONVERSATIONS_STARS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-single-total-conversations-chart
        metric="locale"
        colorSchemaName="green-red"
        [statistics]="statistics"
        [prepareValue]="prepareCountStarsForChart"
      >
      </app-single-total-conversations-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__pie col-sm-12 mb-1">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.CONVERSATIONS_POINTS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-single-total-conversations-chart
        metric="locale"
        colorSchemaName="green-red"
        [statistics]="statistics"
        [prepareValue]="prepareCountPointsForChart"
      >
      </app-single-total-conversations-chart>
    </mat-card-content>
  </mat-card>

  <mat-card class="my-statistics-metrics__pie col-sm-12 mb-1">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.NUMBER_CONVERSATIONS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-single-total-conversations-chart
        metric="locale"
        metricField="countConversations"
        colorSchemaName="green-red"
        [statistics]="statistics"
      >
      </app-single-total-conversations-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__pie col-sm-12 mb-1">
    <mat-card-header>
      <mat-card-title>{{ 'MY_STATISTICS.DURATION_CONVERSATIONS_TITLE' | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-single-total-conversations-chart
        *ngIf="statistics"
        metric="locale"
        metricField="conversationsDuration"
        colorSchemaName="material500"
        [statistics]="statistics"
      >
      </app-single-total-conversations-chart>
    </mat-card-content>
  </mat-card>
</ng-template>

<ng-template #byLocaleMetricsRangeSet let-statistics="statistics">
  <mat-card class="my-statistics-metrics__350 col-sm-12 my-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.CONVERSATIONS_STARS_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-multiple-total-conversations-chart
        metric="locale"
        metricField="countConversationsStars"
        colorSchemaName="green-red"
        [statistics]="statistics"
      >
      </app-multiple-total-conversations-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__350 col-sm-12 my-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.CONVERSATIONS_POINTS_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-multiple-total-conversations-chart
        metric="locale"
        metricField="countConversationsPoints"
        colorSchemaName="material500"
        [statistics]="statistics"
      >
      </app-multiple-total-conversations-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__350 col-sm-12 my-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.NUMBER_CONVERSATIONS_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-multiple-total-conversations-chart
        metric="locale"
        metricField="countConversations"
        colorSchemaName="material500"
        [statistics]="statistics"
      >
      </app-multiple-total-conversations-chart>
    </mat-card-content>
  </mat-card>
  <mat-card class="my-statistics-metrics__350 col-sm-12 my-1">
    <mat-card-header>
      <mat-card-title>
        {{ 'MY_STATISTICS.AVERAGE_CONVERSATIONS_DURATION_TITLE' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="w-100">
      <app-multiple-total-conversations-chart
        metric="locale"
        colorSchemaName="blue"
        metricField="conversationsDuration"
        [statistics]="statistics"
      >
      </app-multiple-total-conversations-chart>
    </mat-card-content>
  </mat-card>
</ng-template>
