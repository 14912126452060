import gql from 'graphql-tag';

export default gql`
  mutation updateTeam($input: TeamInput) {
    updateTeam(input: $input) {
      __typename
      id
      name
      icon
      description
      teamLeadId
      teamLead {
        __typename
        cognitoId
        username
        avatarUrl
      }
      members {
        __typename
        cognitoId
        avatarUrl
        username
        teamId
        userData
      }
    }
  }
`;
