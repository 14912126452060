import gql from 'graphql-tag';

export default gql`
  subscription subscribeToNewMessages($chatId: ID!) {
    subscribeToNewMessages(chatId: $chatId) {
      __typename
      chatId
      createdAt
      id
      senderId
      content
      attachmentId
      attachment {
        id
        key
        type
        size
        name
      }
    }
  }
`;
