<div class="agent-input-autocomplete">
  <mat-form-field class="ctn-full-width">
    <mat-select #singleSelect class="p-0" [required]="required" [formControl]="agentCtrl" [placeholder]="placeholder">
      <mat-option>
        <ngx-mat-select-search [formControl]="agentsFilterCtrl" [placeholderLabel]="placeholderLabel">
          <mat-icon ngxMatSelectSearchClear *ngIf="!isFinding">close</mat-icon>
          <mat-icon ngxMatSelectSearchClear *ngIf="isFinding">
            <mat-progress-spinner matSuffix mode="indeterminate" diameter="23"></mat-progress-spinner>
          </mat-icon>
        </ngx-mat-select-search>
      </mat-option>

      <mat-option
        class="agent-input-autocomplete__option"
        *ngFor="let agent of filteredAgents | async; trackBy: trackItem"
        [value]="agent"
      >
        <div class="d-flex flex-row">
          <div>
            <app-user-template [user]="agent" tooltipField="userData.status"></app-user-template>
          </div>
          <div *ngIf="withTeam && agent.team" class="d-flex justify-content-center align-items-center ps-1">
            (
            <div class="agent-input-autocomplete__icon" *ngIf="agent.team?.icon">
              <app-s3-image [image]="agent.team?.icon"></app-s3-image>
            </div>
            {{ agent.team?.name }}
            )
          </div>
        </div>
      </mat-option>
    </mat-select>
    <button
      mat-button
      *ngIf="agentCtrl.value"
      matSuffix
      mat-icon-button
      [attr.aria-label]="'BUTTON.CLEAR' | translate"
      (click)="resetAgent($event)"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-error *ngIf="errorLabel">{{ errorLabel }}</mat-error>
  </mat-form-field>

  <mat-progress-bar class="m-2" *ngIf="isLoading" [mode]="'indeterminate'" [@fadeInOut]></mat-progress-bar>
</div>
