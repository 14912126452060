<div class="card customer-info h-100" *ngIf="conversation">
  <mat-tab-group>
    <mat-tab [label]="'CUSTOMER_INFO_BLOCK.CUSTOMER_INFORMATION_TAB.LABEL' | translate">
      <div class="card-body p-2 small">
        <section>
          <b>{{ 'CUSTOMER_INFO_BLOCK.CUSTOMER_INFORMATION_TAB.LOCATION_LABEL' | translate }}</b
          >:
          <a
            href="http://maps.google.com/?q={{ location?.latitude }},{{ location?.longitude }}"
            target="_blank"
            *ngIf="location && location.latitude"
          >
            {{ location?.address }}
          </a>
          <span *ngIf="!location?.latitude || location.latitude === -1">
            {{ 'CUSTOMER_INFO_BLOCK.CUSTOMER_INFORMATION_TAB.NOT_AVAILABLE_MESSAGE' | translate }}
          </span>

          <br />

          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            class="border-0"
            [src]="
              'https://www.google.com/maps/embed/v1/view?key=' +
                googleMapApiKey +
                '&zoom=8&center=' +
                location?.latitude +
                ',' +
                location?.longitude | trustResourceUrl
            "
            allowfullscreen
            *ngIf="location?.latitude && location.latitude != -1"
          >
          </iframe>
          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            class="border-0"
            [src]="
              'https://www.google.com/maps/embed/v1/place?key=' + googleMapApiKey + '&zoom=8&q=' + location?.address
                | trustResourceUrl
            "
            allowfullscreen
            *ngIf="location?.address && (!location.latitude || location.latitude == -1)"
          >
          </iframe>
        </section>
      </div>
    </mat-tab>
    <mat-tab [label]="'CUSTOMER_INFO_BLOCK.CUSTOMER_HISTORY_TAB.LABEL' | translate">
      <div class="card-body p-2 small mat-elevation-z8 h-100">
        <ngx-ui-loader [fgsSize]="25" [loaderId]="loaderId"></ngx-ui-loader>
        <table mat-table [dataSource]="dataSource">
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'CUSTOMER_INFO_BLOCK.CUSTOMER_HISTORY_TAB.CREATED_AT_COLUMN_TITLE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="ps-2">
              {{ element.createdAt | date: 'dd MMM yyyy' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="intentsTriggered">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'CUSTOMER_INFO_BLOCK.CUSTOMER_HISTORY_TAB.INTENTS_COLUMN_TITLE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" [matTooltip]="element.intentsTriggered">
              {{ element.intentsTriggered }}
            </td>
          </ng-container>

          <ng-container matColumnDef="caseId">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'CUSTOMER_INFO_BLOCK.CUSTOMER_HISTORY_TAB.CASE_ID_COLUMN_TITLE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.caseId || '-' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="channel">
            <th mat-header-cell *matHeaderCellDef>
              {{ 'CUSTOMER_INFO_BLOCK.CUSTOMER_HISTORY_TAB.CHANNEL_COLUMN_TITLE' | translate }}
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.channel }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions" stickyEnd>
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element" class="px-2">
              <button
                *ngIf="element.id !== conversation.id"
                class="btn btn-sm btn-outline-primary btn-action p-0"
                (click)="openCustomerHistory(element.id)"
              >
                <mat-icon [matTooltip]="'BUTTON.VIEW' | translate">pageview</mat-icon>
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="loadMore">
            <td mat-footer-cell *matFooterCellDef colspan="5" class="text-center">
              <button [disabled]="isLoading" class="btn btn-sm btn-outline-primary" (click)="loadMoreHistory()">
                {{ 'BUTTON.LOAD_MORE' | translate }}
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="nextToken ? ['loadMore'] : []"></tr>
        </table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
