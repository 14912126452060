import gql from 'graphql-tag';

export default gql`
  query getUsersConversationQueueHistory($after: String, $first: Int, $userId: ID!) {
    getUsersConversationQueueHistory(after: $after, first: $first, userId: $userId) {
      __typename
      nextToken
      items {
        __typename
        startedAt
        finishedAt
        userId
        agentId
        conversationId
        agent {
          __typename
          avatarUrl
          cognitoId
          username
          userData
        }
        conversation {
          __typename
          id
          intentsTriggered
          name
          caseId
          createdAt
          createdBy
          history
          locale
          location
          sentiment
          keywords
          status
          channel
        }
      }
    }
  }
`;
