import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { listFadeInOut } from '@cation/core/animations';
import { ApiService } from '@cation/core/services/api/api.service';
import { ApiHook } from '@cation/core/services/api/api-hook';
import { LogService } from '@cation/core/services/log/log.service';

@Component({
  selector: 'ctn-gamification-settings',
  templateUrl: './gamification-settings.component.html',
  styleUrls: ['./gamification-settings.component.scss'],
  animations: [listFadeInOut]
})
export class GamificationSettingsComponent implements OnInit {
  starsWorth = {};
  starsCount = 0;

  levelsFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  levels: { id: string; name: string; points: number; icon: string }[] = [];
  autoApproveEmptyFeedback: boolean;
  teamMonthlyPointPool = 0;
  maxPointsAllowedInChallenge = 0;

  errorMessage = '';

  constructor(private apiService: ApiService, private logService: LogService, public apiHook: ApiHook) {}

  ngOnInit() {
    this.getSettings();
  }

  async getSettings() {
    const settings = await this.apiService.getGamificationSettings();

    this.logService.log('[GamificationSettingsComponent getSettings]', settings);

    _.map(settings.levels, (value, key) => this.addLevel(key, value.name, value.points, value.icon));

    this.starsWorth = settings.starsWorth;
    this.starsCount = Object.keys(settings.starsWorth).length;
    this.autoApproveEmptyFeedback = settings.autoApproveEmptyFeedback;
    this.teamMonthlyPointPool = settings.teamMonthlyPointPool;
    this.maxPointsAllowedInChallenge = settings.maxPointsAllowedInChallenge;
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  onAddLevel() {
    const level = _.size(this.levelsFormGroup.controls);
    const title = `level ${level}`;

    this.addLevel(`${level}`, title, 0, '');
  }

  addLevel(id: string, title: string, points: number, icon: string) {
    const nameCtrl = new UntypedFormControl(title, Validators.required);
    const pointsCtrl = new UntypedFormControl(points, Validators.required);
    const iconCtrl = new UntypedFormControl(icon, Validators.required);

    this.levels.push({ id, name: title, points, icon });

    this.levelsFormGroup.addControl(
      id,
      new UntypedFormGroup({
        name: nameCtrl,
        points: pointsCtrl,
        icon: iconCtrl
      })
    );
    this.onPointsChange(id);
  }

  onDeleteLevel() {
    const item = this.levels.pop();
    this.levelsFormGroup.removeControl(item.id);
  }

  async onSave() {
    const settings = [
      { key: 'starsWorth', value: this.starsWorth },
      { key: 'levels', value: this.levelsFormGroup.getRawValue() },
      { key: 'autoApproveEmptyFeedback', value: this.autoApproveEmptyFeedback },
      { key: 'teamMonthlyPointPool', value: this.teamMonthlyPointPool },
      { key: 'maxPointsAllowedInChallenge', value: this.maxPointsAllowedInChallenge }
    ];
    this.logService.log('[GamificationSettingsComponent onSave]', settings);

    await this.apiService.setGamificationSettings(settings);
  }

  onPointsChange(id: string) {
    const fieldId = +id;
    const field = this.levelsFormGroup.get(id).get('points');
    if (fieldId > 0) {
      const prevField = this.levelsFormGroup.get(`${fieldId - 1}`).get('points');

      if (field.value <= prevField.value) {
        field.setValue(prevField.value + 1);
      }
    }
    if (fieldId < _.size(this.levelsFormGroup.controls) - 1) {
      const nextField = this.levelsFormGroup.get(`${fieldId + 1}`).get('points');

      if (field.value >= nextField.value) {
        field.setValue(nextField.value - 1);
      }
    }
  }

  onLevelIconSelect(id: string, icon: string) {
    const field = this.levelsFormGroup.get(id).get('icon');
    field.setValue(icon);
  }
}
