import gql from 'graphql-tag';

export default gql`
  subscription subscribeToUpdateConversationQueueCount {
    subscribeToUpdateConversationQueueCount {
      __typename
      key
      count
      lastWaitingTime
      updatedAt
    }
  }
`;
