import { Component, Input, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { getUsersByTeamIdQuery as UsersQuery } from '@cation/core/graphql/operation-result-types';
import { AppsyncUserService } from '../services/appsync/appsync-user.service';
import { AppsyncChatService } from '../services/appsync/appsync-chat.service';
import { User } from '../types';
import { RouteService } from '../services/route.service';
import { SharedService } from '../services/shared.service';
import routes from '../routes';
import { LoggerService } from '../services/logger.service';

@Component({
  selector: 'internal-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class InternalUsersListComponent implements OnInit {
  @Input() users: User[] = [];

  me: User;

  searchName = '';
  searchNameChanged: Subject<string> = new Subject<string>();

  route = '';
  _routes = routes;

  selectedUsers = [];

  chats = [];

  countUnreadMessages = {};

  observedQuery;
  observableSubscription;
  subscription;

  constructor(
    private appsyncUserService: AppsyncUserService,
    private routeService: RouteService,
    private sharedService: SharedService,
    private appsyncChatService: AppsyncChatService,
    private loggerService: LoggerService
  ) {
    this.routeService.currentRoute.subscribe(value => (this.route = value));
    this.sharedService.selectedUsers.subscribe(value => (this.selectedUsers = value));
    this.sharedService.currentUser.subscribe(value => (this.me = value));
    this.sharedService.currentUserChats.subscribe(value => (this.chats = value));
    this.sharedService.countUnreadMessages.subscribe(counts => (this.countUnreadMessages = counts));

    this.searchNameChanged
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(searchName => {
        this.searchName = searchName;
        this.searchUsers();
      });
  }

  ngOnInit(): void {
    this.loadUsersAndSubscribe();
  }

  trackItem(index, item) {
    return item ? item.id : undefined;
  }

  findUnreadMessagesCounter(user) {
    if (user.id === this.me.id) {
      return 0;
    }

    const chat = this.chats.find(
      ({ associated }) => associated.length === 2 && associated.find(({ userId }) => userId === user.id)
    );

    if (chat) {
      return this.countUnreadMessages[chat.chatId];
    }
  }

  private createDirectChatInput(myId, agentId, chatId) {
    const firstUserId = myId > agentId ? myId : agentId;
    const secondUserId = myId > agentId ? agentId : myId;

    return {
      firstUserId,
      secondUserId,
      chatId
    };
  }

  private async createChat(agent: User) {
    const members = [this.me];
    if (agent.id !== this.me.id) {
      members.push(agent);
    }

    const chatName =
      agent.id === this.sharedService.userProfile.id
        ? agent.name
        : `${this.sharedService.userProfile.name}, ${agent.name}`;

    const input = {
      userId: this.sharedService.userProfile.id,
      chatId: uuid(),
      chatName,
      members
    };
    const directChatInput = this.createDirectChatInput(this.sharedService.userProfile.id, agent.id, input.chatId);

    try {
      const chat = await this.appsyncChatService.createChat(input);
      this.loggerService.warn('[UsersListComponent] createChat', chat);

      await this.appsyncChatService.createUserChats(chat, this.me);
      await this.appsyncChatService.createUserChats(chat, agent);
      await this.appsyncChatService.createDirectChat(directChatInput);
      this.loggerService.warn('[UsersListComponent] create user chats');
    } catch (error) {
      this.loggerService.error('[UsersListComponent] createChat error', error);
    }
  }

  async startNewChat() {
    const selectedUsers = this.sharedService.usersToChat;
    const currentUser = this.sharedService.userProfile;

    if (selectedUsers.length) {
      const members = [currentUser, ...selectedUsers];
      const input = {
        userId: currentUser.id,
        chatId: uuid(),
        chatName: `${currentUser.name}, ${selectedUsers.map(({ name }) => name).join(', ')}`,
        members
      };

      try {
        const chat = await this.appsyncChatService.createChat(input);
        this.loggerService.warn('[UsersListComponent] create chat');

        const promises = await members.map(member => this.appsyncChatService.createUserChats(chat, member));
        this.loggerService.warn('[UsersListComponent] create user chats');

        await Promise.all(promises);

        this.sharedService.setSelectedUsers([]);
      } catch (error) {
        this.loggerService.error('[UsersListComponent] createChat error');
      }
    }
  }

  private async checkExistingChats(agentId) {
    const associatedLength = agentId === this.me.id ? 1 : 2;

    let existingChat = this.chats.find(({ associated }) => {
      return associated.length === associatedLength && associated.find(({ userId }) => userId === agentId);
    });

    if (!existingChat) {
      const firstUserId = this.me.id > agentId ? this.me.id : agentId;
      const secondUserId = this.me.id > agentId ? agentId : this.me.id;
      const directChat = await this.appsyncChatService.getDirectChat(firstUserId, secondUserId);

      if (directChat) {
        existingChat = directChat.userChat;
      }
    }

    return existingChat;
  }

  async openChat(agent: User) {
    let existingChat = await this.checkExistingChats(agent.id);

    if (existingChat) {
      if (existingChat.associated.find(({ userId }) => userId === this.me.id).connected === 'false') {
        existingChat = await this.appsyncChatService.enterChat(existingChat);
      }

      this.sharedService.setCurrentChat(existingChat);

      return this.routeService.setRoute(routes.CHAT);
    }

    await this.createChat(agent);

    this.loggerService.info('[Chats]', this.sharedService.chats);
  }

  onSearchNameChange(searchName: string) {
    this.searchNameChanged.next(searchName);
  }

  async loadUsersAndSubscribe() {
    const observerOrNext = ({ data }) => {
      if (!data || !data.searchUsers) {
        return this.loggerService.info('[InternalUsersListComponent loadUsersAndSubscribe] no data');
      }

      this.users = this.searchName
        ? data.searchUsers.filter(u => u.name.toLowerCase().includes(this.searchName))
        : data.searchUsers;

      if (this.route === this._routes.CREATE_CHAT) {
        this.users = this.users.filter(user => user.id !== this.me.id);
      }

      this.loggerService.info('[InternalUsersListComponent loadUsersAndSubscribe:users]', this.users);
    };

    const updateQuery = (
      prev: UsersQuery,
      {
        subscriptionData: {
          data: { subscribeToUsers: user }
        }
      }
    ) => this.loggerService.warn('[loadUsersAndSubscribe updateQuery]', prev, user);

    const data = await this.appsyncUserService.loadUsersAndSubscribe(
      this.searchName.toLowerCase(),
      observerOrNext,
      updateQuery
    );

    this.observedQuery = data.observedQuery;
    this.observableSubscription = data.observableSubscription;
    this.subscription = data.subscription;
  }

  async searchUsers() {
    this.users = await this.appsyncUserService.searchUsers(this.searchName.toLowerCase());

    this.loggerService.info('[InternalUsersListComponent searchUsers:users]', this.users);
  }

  selectUser(user) {
    if (this.selectedUsers.find(({ id }) => id === user.id)) {
      return this.sharedService.setSelectedUsers(this.selectedUsers.filter(({ id }) => id !== user.id));
    }
    this.sharedService.setSelectedUsers([...this.selectedUsers, user]);
  }

  checkUserStatus(userId): boolean {
    return !!this.selectedUsers.find(({ id }) => id === userId);
  }

  onUserInfoClick($event: MouseEvent, user: User) {
    $event.preventDefault();
    $event.stopPropagation();

    switch (this.route) {
      case this._routes.USERS_LIST:
        this.openChat(user);
        break;
      case this._routes.CREATE_CHAT:
        this.selectUser(user);
        break;
      default:
        console.error('Please, add an action.');
    }
  }
}
