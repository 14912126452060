import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';
import { fadeInOut } from '@cation/core/animations';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { ISettings } from '@cation/core/types/api';
import * as _ from 'lodash';

@Component({
  selector: 'app-challenge-dialog',
  templateUrl: './challenge-dialog.component.html',
  styleUrls: ['./challenge-dialog.component.scss'],
  animations: [fadeInOut]
})
export class ChallengeDialogComponent implements OnInit {
  loaderId = 'challenge-dialog-loader';

  settings: ISettings;
  maxPoints: number;
  challengeTypes: string[] = ['MOST_POINTS', 'MOST_STARS', 'FIVE_STARS_TO_DATE'];

  challengeTypeFormGroup: UntypedFormGroup;
  datesFormGroup: UntypedFormGroup;
  opponentFormGroup: UntypedFormGroup;
  amountFormGroup: UntypedFormGroup;
  costFormGroup: UntypedFormGroup;

  public isLoading = false;
  public isCostLoading = false;
  public errorMessage;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {},
    public dialogRef: MatDialogRef<ChallengeDialogComponent>,
    private ngxLoader: NgxUiLoaderService,
    private formBuilder: UntypedFormBuilder,
    public authHelper: AuthHelper,
    private apiService: ApiService,
    private s3Service: S3Service,
    private logService: LogService
  ) {}

  async ngOnInit() {
    this.challengeTypeFormGroup = this.formBuilder.group({
      challengeType: ['MOST_POINTS', Validators.required]
    });

    this.opponentFormGroup = this.formBuilder.group({
      opponent: ['', Validators.required]
    });

    this.datesFormGroup = this.formBuilder.group({
      date: [
        {
          begin: moment()
            .add(1, 'days')
            .format('YYYY-MM-DD'),
          end: moment()
            .add(1, 'days')
            .format('YYYY-MM-DD')
        }
      ]
    });

    this.costFormGroup = this.formBuilder.group({
      points: [0, [Validators.required, Validators.min(1)]]
    });

    this.amountFormGroup = this.formBuilder.group({
      amount: [1, Validators.required]
    });

    this.isCostLoading = true;
    await this.authHelper.loadRewards();
    this.settings = await this.apiService.getGamificationSettings();
    this.maxPoints = _.min([this.settings.maxPointsAllowedInChallenge, this.authHelper.rewards.score || 0]);
    this.costForm.points.setValidators([Validators.required, Validators.min(1), Validators.max(this.maxPoints)]);
    this.costForm.points.setValue(1);
    this.isCostLoading = false;
  }

  get challengeTypeForm() {
    return this.challengeTypeFormGroup.controls;
  }

  get opponentForm() {
    return this.opponentFormGroup.controls;
  }

  get datesForm() {
    return this.datesFormGroup.controls;
  }

  get costForm() {
    return this.costFormGroup.controls;
  }

  get amountForm() {
    return this.amountFormGroup.controls;
  }

  getMinDate(plusDays: number, date = undefined) {
    const tomorrow = date ? new Date(date) : new Date();
    tomorrow.setDate(tomorrow.getDate() + plusDays);

    return tomorrow;
  }

  async onAddChallenge() {
    this.clearError();

    const type = this.challengeTypeForm.challengeType.value;

    const data = Object.assign({}, type === 'FIVE_STARS_TO_DATE' ? { amount: this.amountForm.amount.value } : {});

    const challenge = {
      initiatorId: this.authHelper.userProfile.cognitoId,
      opponentId: this.opponentForm.opponent.value.cognitoId,
      type,
      startDate: +this.formatDate(this.datesForm.date.value.begin, 0, 0, 'x'),
      finishDate: +this.formatDate(this.datesForm.date.value.end, 23, 59, 'x'),
      cost: this.costForm.points.value,
      data
    };
    this.logService.log('[ChallengeDialogComponent onAddChallenge:challenge]', challenge);

    this.ngxLoader.startLoader(this.loaderId);
    try {
      await this.apiService.addGamificationChallenge(challenge);
      this.dialogRef.close(challenge);
    } catch (e) {
      this.logService.error('[ChallengeDialogComponent onAddChallenge:error]', e);
      this.errorMessage = e.message;
    }
    this.ngxLoader.stopLoader(this.loaderId);
  }

  clearError() {
    this.errorMessage = null;
  }

  formatDate(value, h: number, m: number, format: string = 'DD MMM YYYY HH:mm (z Z)') {
    return this.prepareDate(value, h, m).format(format);
  }

  prepareDate(value, h: number, m: number) {
    return moment
      .utc(value)
      .hours(h)
      .minutes(m)
      .seconds(0)
      .milliseconds(0);
  }
}
