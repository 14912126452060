<div class="review-team-details-view">
  <ngx-loading [show]="isLoading"></ngx-loading>

  <section>
    <mat-tab-group
      class="overflow-hidden"
      [selectedIndex]="selectedTab.value"
      (selectedIndexChange)="selectedTab.setValue($event)"
    >
      <mat-tab [label]="'ADMIN_REVIEW_USER.STATISTICS_TITLE' | translate">
        <app-my-statistics2
          [isActive]="selectedTab.value === 0"
          [title]="'ADMIN_REVIEW_USER.STATISTICS_TITLE'"
          [userId]="team.id"
        >
        </app-my-statistics2>
      </mat-tab>
    </mat-tab-group>
  </section>

  <div class="alert alert-danger pt-2" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
</div>
