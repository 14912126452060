<ngx-loading [show]="isLoading"></ngx-loading>

<mat-card class="app-settings-page">
  <mat-card-content>
    <form class="ctn-form col-xl-12" #settingsForm="ngForm">
      <h4 class="ctn-h3 ctn-margin">
        <mat-icon>settings</mat-icon>
        {{ 'ADMIN_SETTINGS.TITLE' | translate }}
      </h4>

      <mat-form-field class="ctn-form-field">
        <mat-select
          required
          name="default-locale"
          [placeholder]="'ADMIN_SETTINGS.DEFAULT_LOCALE_SELECT_PLACEHOLDER' | translate"
          [(ngModel)]="defaultLocale"
        >
          <mat-option *ngFor="let locale of Locale" [value]="locale.code">
            {{ locale.name }} ({{ locale.code }})
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="ctn-form-field">
        <mat-select
          multiple
          required
          name="supported-locales"
          [placeholder]="'ADMIN_SETTINGS.SUPPORTED_LOCALES_SELECT_PLACEHOLDER' | translate"
          [(ngModel)]="supportedLocales"
        >
          <mat-option *ngFor="let locale of Locale" [value]="locale.code">
            {{ locale.name }} ({{ locale.code }})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <hr />
    </form>

    <div class="alert alert-danger pt-2" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
  </mat-card-content>

  <mat-card-actions class="pt-2">
    <button
      mat-raised-button
      color="primary"
      [title]="'BUTTON.SAVE' | translate"
      class="me-2"
      (click)="onSave()"
      [disabled]="settingsForm.invalid || settingsForm.untouched"
    >
      <mat-icon>save</mat-icon>
      {{ 'BUTTON.SAVE' | translate }}
    </button>
  </mat-card-actions>

  <mat-card-actions class="app-settings-page_actions">
    <h4 class="ctn-h3 ctn-margin ps-2">
      {{ 'ADMIN_SETTINGS.ACTIONS' | translate }}
    </h4>

    <div class="actions-container">
      <!-- System Status -->
      <mat-slide-toggle [checked]="isSystemOnline" (change)="onToggleSystemStatus($event)">
        {{
          isSystemOnline
            ? ('ADMIN_SETTINGS.SYSTEM_BTN_TOOLTIP_ONLINE' | translate)
            : ('ADMIN_SETTINGS.SYSTEM_BTN_TOOLTIP_OFFLINE' | translate)
        }}
      </mat-slide-toggle>

      <!-- Close all Conversations -->
      <div class="close-conversations">
        <button
          mat-raised-button
          color="primary"
          class="py-2"
          (click)="onConversationsClose()"
          [disabled]="isConversationsClosing"
        >
          {{ isConversationsClosing ? '' : ('ADMIN_SETTINGS.CLOSE_CONVERSATIONS' | translate) }}
          <mat-spinner *ngIf="isConversationsClosing" [color]="primary" [diameter]="30"></mat-spinner>
        </button>
        <span>{{ 'ADMIN_SETTINGS.LAST_TIME_CLOSED_IN' | translate }}: {{ lastTimeConversationsClosed }}</span>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
