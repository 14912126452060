<div
  [ngClass]="{
    'internal-message-balloon': true,
    'internal-message-balloon_author': isWrittenByCurrentUser
  }"
>
  <div *ngIf="userName" class="username">{{ userName }}</div>
  <div class="balloon-content">
    {{ message.content }}
    <div class="balloon-content_attachment" *ngIf="message.attachment" [title]="message.attachment.name">
      <button mat-icon-button class="small" [disabled]="isAttachmentLoading" (click)="saveFile($event)">
        <mat-icon title="Download file">cloud_download</mat-icon>
      </button>
      {{ message.attachment.name }}
      <mat-progress-bar *ngIf="isAttachmentLoading" mode="buffer"></mat-progress-bar>
    </div>
  </div>
</div>
