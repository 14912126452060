import { Component, Input, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { AppsyncUserService } from '../services/appsync/appsync-user.service';
import { AppsyncChatService } from '../services/appsync/appsync-chat.service';
import { AppsyncMessageService } from '../services/appsync/appsync-message.service';
import { SharedService } from '../services/shared.service';
import { LoggerService } from '../services/logger.service';
import { RouteService } from '../services/route.service';
import { unshiftMessage, updateUserChat } from '../chat-helper';
import {
  getUserChatsConnectionThroughUserQuery as ChatsQuery,
  getChatMessagesQuery as MessagesQuery
} from '../graphql/operation-result-types';
import routes from '../routes';
import { User, UserChat } from '../types';

@Component({
  selector: 'internal-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [style({ height: '0', opacity: 0 }), animate('300ms', style({ height: '*', opacity: 1 }))]),
      transition(':leave', [style({ height: '*', opacity: 1 }), animate('300ms', style({ height: '0', opacity: 0 }))])
    ])
  ]
})
export class ChatComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() name: string;
  @Input() avatarUrl: string;
  @Input() inline: boolean = false;

  isChatOpen = false;

  route: string;

  countUnreadMessages = {};
  emojiBarState = false;

  updateLastActiveTimeInterval;

  nextToken;
  subscription;
  observedQuery;

  private messagesData = {};
  private chatSubscriptions: { [chatId: string]: ZenObservable.Subscription[] } = {};

  @ViewChild('emojiElement', { static: true }) emojiElement;

  constructor(
    private appsyncUserService: AppsyncUserService,
    private appsyncChatService: AppsyncChatService,
    private appsyncMessageService: AppsyncMessageService,
    public sharedService: SharedService,
    private routeService: RouteService,
    private loggerService: LoggerService
  ) {
    this.getMessages = this.getMessages.bind(this);
    this.unsubscribeChat = this.unsubscribeChat.bind(this);
    this.routeService.currentRoute.subscribe(value => {
      this.route = value;

      if (this.emojiBarState) {
        this.sharedService.setCurrentEmojiBarState(false);
      }
    });
    this.sharedService.currentMessagesData.subscribe(messagesData => (this.messagesData = messagesData));
    this.sharedService.countUnreadMessages.subscribe(counts => (this.countUnreadMessages = counts));
    this.sharedService.currentEmojiBarState.subscribe(value => (this.emojiBarState = value));
    this.sharedService.onLeaveChat.subscribe(this.unsubscribeChat);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    const clickedInside = this.emojiElement && this.emojiElement.nativeElement.contains(targetElement);

    if (this.emojiBarState && !clickedInside) {
      this.sharedService.setCurrentEmojiBarState(false);
    }
  }

  toggleInternalChat() {
    if (this.emojiBarState) {
      this.sharedService.setCurrentEmojiBarState(false);
    }
    this.isChatOpen = !this.isChatOpen;
  }

  closeInternalChat($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.isChatOpen = false;
  }

  async ngOnInit() {
    this.sharedService.setCurrentEmojiElement(this.emojiElement);
    await this.createUser();
    await this.getAllChats();
    this.updateLastActiveTimeInterval = setInterval(() => this.updateLastActiveTime(), 10000);
  }

  ngOnDestroy() {
    clearInterval(this.updateLastActiveTimeInterval);
  }

  unsubscribeChat(chatId) {
    try {
      this.messagesData[chatId].observedQuery.subscriptionHandles.forEach(s => s.unsubscribe());
      this.messagesData[chatId].observableSubscription.unsubscribe();
      this.chatSubscriptions[chatId].forEach(s => s.unsubscribe());
    } catch (e) {
      this.loggerService.error('[Error unsubscribe]', e);
    }
    this.sharedService.setMessagesData({ ...this.messagesData, [chatId]: undefined });
  }

  async createUser() {
    const input = {
      id: this.id,
      name: this.name,
      avatarUrl: this.avatarUrl
    };

    try {
      const user = await this.appsyncUserService.createUser(input);
      this.sharedService.setCurrentUser({ id: this.id, name: this.name, avatarUrl: this.avatarUrl });

      this.loggerService.warn('[ChatComponent user]', user);
    } catch (e) {
      this.loggerService.error('[ChatComponent checkUser:error]', e);
    }
  }

  async getAllChats(retrying = 60) {
    const observerOrNext = async ({ data }) => {
      this.loggerService.info('[getAllChats data]', data);
      if (!data || !data.me) {
        return this.loggerService.info('[getAllChats no data]');
      }
      const chats = data.me.chats.userChats;

      chats.map(this.getMessages);
      chats.map(async userChat => {
        if (!this.chatSubscriptions[userChat.chatId]) {
          const subscription = await this.subscribeToUpdateUserChats(userChat);
          const subscriptionToNewChatName = await this.subscribeToUpdateChatName(userChat);

          this.chatSubscriptions[userChat.chatId] = [subscription, subscriptionToNewChatName];
        }
      });
      chats.forEach(chat => this.setCountUnreadMessages(chat));

      this.sharedService.setUserChats(chats);
      this.sharedService.setCurrentChatsData({ observedQuery: this.observedQuery, nextToken: data.me.chats.nextToken });

      this.nextToken = data.me.chats.nextToken;
      this.loggerService.info('[getAllChats chats]', chats);
    };

    const updateQuery = (
      prev: ChatsQuery,
      {
        subscriptionData: {
          data: { subscribeToNewUserChats: userChat }
        }
      }
    ) => {
      /**
       * TODO: update subscription flow to subscribe on new chat, but not on userChat
       * Untill this, code bellow will provide correct data
       */
      const correctUserChat = this.sharedService.chats.find(uc => uc.chatId === userChat.chatId);

      this.loggerService.info('[getAllChats updateQuery]', correctUserChat);
      // this.loggerService.info(JSON.stringify(userChat, null, 2));
      // this.loggerService.info(JSON.stringify(prev, null, 2));

      if (this.route !== routes.CHAT && userChat.chat.author.id === this.id) {
        this.sharedService.setCurrentChat(correctUserChat);
        this.routeService.setRoute(routes.CHAT);
      }

      return updateUserChat(prev, userChat);
    };

    const data = await this.appsyncChatService.getAllChats(this.id, observerOrNext, updateQuery, (error) => {
      if ((error as any).errorMessage.includes('Socket')) {
        setTimeout(() => this.getAllChats(retrying * 2), retrying)
      }
    });

    this.subscription = data.subscription;
    this.observedQuery = data.observedQuery;

    this.sharedService.setCurrentChatsData({ observedQuery: data.observedQuery });
  }

  private subscribeToUpdateUserChats(userChat: UserChat, retrying = 60) {
    const realtimeResults = ({ data: { subscribeToUpdateUserChats: userChat } }) => {
      if (this.route === routes.CHAT && this.sharedService.openedChat.chatId === userChat.chatId) {
        this.sharedService.setCurrentChat(userChat);
      }
    };

    const onError = (error) => {
      if (error.errorMessage.includes('Socket')) {
        setTimeout(() => this.subscribeToUpdateUserChats(userChat, retrying * 2), retrying);
      }
    }

    return this.appsyncChatService.subscribeToUpdateUserChats(userChat.chat, { realtimeResults, onError });
  }

  private subscribeToUpdateChatName(userChat: UserChat, retrying = 60) {
    const realtimeResults = ({ data: { subscribeToUpdateChatName: chat } }) => {
      if (this.sharedService.openedChat.chatId === chat.id) {
        this.sharedService.setCurrentChat({ ...this.sharedService.openedChat, chat });
      }
    };

    const onError = (error) => {
      if (error.errorMessage.includes('Socket')) {
        setTimeout(() => this.subscribeToUpdateChatName(userChat, retrying * 2), retrying);
      }
    }

    return this.appsyncChatService.subscribeToUpdateChatName(userChat, { realtimeResults, onError });
  }

  private setCountUnreadMessages(userChat: UserChat) {
    const currentUserChat = userChat.associated.find(({ userId }) => {
      return userId === this.id;
    });
    const count = this.sharedService.getMessagesData(userChat.chatId).messages.filter(({ senderId, createdAt }) => {
      const isMe = senderId === this.id;

      if (isMe) {
        return false;
      }

      if (!currentUserChat.lastReadMessageId) {
        return true;
      }

      const isUnread = createdAt > +new Date(currentUserChat.lastReadMessageId.split('_')[0]);

      return isUnread;
    });

    this.sharedService.setCountUnreadMessages({
      ...this.countUnreadMessages,
      [userChat.chatId]: count.length
    });
  }

  private async getMessages(userChat: UserChat, retrying = 60) {
    const chatId = userChat.chat.id;
    if (this.messagesData[chatId]) {
      return;
    }

    const observerOrNext = async ({ data }) => {
      this.loggerService.info('[getMessages subscribe]', data);
      if (!data) {
        return this.loggerService.info('[getMessages - no data]');
      }

      const newMessages = data.getMessages.messages;

      this.sharedService.setMessagesData({
        ...this.messagesData,
        [chatId]: {
          ...this.messagesData[chatId],
          messages: [...newMessages].reverse(),
          nextToken: data.getMessages.nextToken
        }
      });

      const updatedUserChat = this.sharedService.chats.find(chat => chat.chatId === chatId);
      this.setCountUnreadMessages(updatedUserChat || userChat);

      this.loggerService.info('[getMessages]: nextToken is now', data.getMessages.nextToken ? 'set' : 'null');
    };

    const updateQuery = (
      prev: MessagesQuery,
      {
        subscriptionData: {
          data: { subscribeToNewMessages: message }
        }
      }
    ) => {
      this.loggerService.info('[subscribeToNewMessages] - updateQuery:', {
        prev,
        message
      });

      return unshiftMessage(prev, message);
    };

    const onError = (error) => {
      if (error.errorMessage.includes('Socket')) {
        this.sharedService.setMessagesData({ ...this.messagesData, [chatId]: undefined });
        setTimeout(() => this.getMessages(userChat, retrying * 2), retrying);
      }
    }

    const { observableSubscription, observedQuery, subscription } = await this.appsyncMessageService.getMessages(
      userChat.chat,
      observerOrNext,
      updateQuery,
      onError
    );

    this.sharedService.setMessagesData({
      ...this.messagesData,
      [chatId]: {
        ...this.messagesData[chatId],
        observedQuery,
        subscription,
        observableSubscription
      }
    });
  }

  updateLastActiveTime() {
    const user = {
      id: this.id,
      name: this.name,
      avatarUrl: this.avatarUrl
    };
    const time = Date.now();

    this.appsyncUserService.updateActiveTime(user as User, time);
  }

  onEmojiClick($event) {
    this.sharedService.onSelectEmoji.emit($event);
  }

  onEmojiCompClick($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
  }
}
