import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'app-base-unsubscribe',
  template: ''
})
export class BaseUnsubscribeComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
    this.unsubscribeAll();
  }

  public unsubscribeAll(): void {
    for (const sub of this.subscriptions) {
      if (sub) {
        sub.unsubscribe();
      }
    }

    this.subscriptions = [];
  }

  public addSubscription(sub: Subscription): void {
    this.subscriptions.push(sub);
  }
}
