import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthHelper } from '@cation/core/auth/auth-helper';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  constructor(public auth: AuthHelper) {
  }

  ngOnInit() {
  }

}