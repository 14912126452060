import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { LogService } from '@cation/core/services/log/log.service';
import { AuthHelper } from './auth-helper';
import { Role } from './role.enum';
import { Auth } from '@aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private logService: LogService, private auth: AuthHelper, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.logService.log('AuthGuard#canActivate called');

    const roles = next.data['roles'] as Array<Role>;
    // let url: string = state.url;

    return Auth.currentAuthenticatedUser()
      .then(user => {
        this.logService.log('***USER***', user);
        return this.auth.init(user.signInUserSession).then(() => user);
      })
      .then(() => {
        this.logService.log('state.url', state.url);
        this.logService.log('user role', this.auth.getUserRole());

        if (this.auth.isGuest()) {
          this.logService.log('*** GUEST ***');
          this.router.navigate(['/guest']);
          return false;
        } else if (roles && !this.auth.isUserInRole(roles)) {
          this.logService.log('*** ACCESS DENIED ***');
          this.router.navigate(['/accessdenied']);
          return false;
        } else if (state.url === '/home') {
          this.logService.log('redirecting to home');
          if (this.auth.isTeamLead()) {
            this.logService.log('redirecting home to team-lead chat');
            this.router.navigate(['/team-lead/current-conversations']);
            return false;
          } else if (this.auth.isAdmin() || this.auth.isContentAdmin() || this.auth.isReportAdmin()) {
            this.logService.log('redirecting home to dashboard');
            this.router.navigate(['/admin/dashboard']);
            return false;
          } else if (this.auth.isAgent()) {
            this.logService.log('redirecting home to chat');
            this.router.navigate(['/chat']);
            return false;
          }
        }

        return true;
      })
      .catch(err => {
        this.logService.log('ERROR in AuthGuard', err);

        this.router.navigate(['/login']);
        return false;
      });
  }
}
