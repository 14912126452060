import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as shape from 'd3-shape';
import { IUserConversationStatistics } from '@cation/core/types/api';

@Component({
  selector: 'app-average-conversations-duration-chart',
  templateUrl: './average-conversations-duration-chart.component.html',
  styleUrls: ['./average-conversations-duration-chart.component.scss']
})
export class AverageConversationsDurationChartComponent implements OnInit {
  multi: object[];
  legend: boolean = false;
  animations: boolean = true;
  xAxis: boolean = true;
  yAxis: boolean = true;
  timeline: boolean = true;
  curve = shape.curveMonotoneX;

  colorScheme = {
    domain: ['rgba(5,179,226,1)']
  };

  @Input() set statistics(listStatistics: IUserConversationStatistics[]) {
    if (!listStatistics || !listStatistics.length) {
      this.multi = [];
      return;
    }

    const series = [];
    for (const statistics of listStatistics) {
      series.push({
        name: statistics.date,
        value: Math.round(statistics.conversationsDuration || 0 / statistics.countConversations || 0)
      });
    }
    this.multi = [{ name: 'Duration', series }];
  }

  constructor(private translate: TranslateService) {}

  ngOnInit() {}

  onValueFormat = value => {
    return !value
      ? '0'
      : moment
          .duration(value, 'second')
          .locale(this.translate.currentLang)
          .humanize();
  };
}
