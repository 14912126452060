import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { retry, share, switchMap, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { Locale as LocaleConst } from '@cation/core/enums/locale';
import { ConfirmationDialogComponent } from '@cation/core/dialogs/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'ctn-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss'],
})
export class AppSettingsComponent implements OnInit, OnDestroy {
  public Locale = LocaleConst;
  supportedLocales = [];
  isLoading = false;
  defaultLocale;
  errorMessage;
  isSystemOnline;
  isConversationsClosing;
  _lastTimeConversationsClosed;
  primary;

  private stopPolling = new Subject();

  constructor(private apiService: ApiService, private logService: LogService, private dialog: MatDialog) {}

  ngOnInit() {
    timer(1, 30000)
      .pipe(
        switchMap(() => {
          this.isLoading = true;
          return this.apiService.getAppSettings();
        }),
        retry(),
        share(),
        takeUntil(this.stopPolling)
      )
      .subscribe(({ isSystemOnline, defaultLocale, supportedLocales, closeConversations }) => {
        this.defaultLocale = defaultLocale;
        this.supportedLocales = supportedLocales;

        if (isSystemOnline === undefined) {
          this.isSystemOnline = true;
        } else {
          this.isSystemOnline = isSystemOnline;
        }

        if (!closeConversations) {
          this.isConversationsClosing = false;
        } else {
          this._lastTimeConversationsClosed = closeConversations.time;
          this.isConversationsClosing = closeConversations.status === 'IN_PROGRESS';
        }

        this.isLoading = false;
      });
  }

  ngOnDestroy() {
    this.stopPolling.next(undefined);
  }

  async onSave() {
    this.isLoading = true;
    try {
      const settings = [
        { key: 'defaultLocale', value: this.defaultLocale },
        { key: 'supportedLocales', value: this.supportedLocales },
      ];
      this.logService.log('[AppSettingsComponent onSave]', settings);

      await this.apiService.setAppSettings(settings).toPromise();
    } catch (e) {
      this.logService.error('[AppSettingsComponent onSave]', e);
      this.errorMessage = e.message;
    }
    this.isLoading = false;
  }

  onToggleSystemStatus(event) {
    event.source.checked = this.isSystemOnline;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: this.isSystemOnline ? 'ADMIN_SETTINGS.SYSTEM_CONFIRM_OFFLINE' : 'ADMIN_SETTINGS.SYSTEM_CONFIRM_ONLINE',
      },
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (!value) {
        return;
      }

      try {
        await this.apiService.setAppSettings([{ key: 'isSystemOnline', value: !this.isSystemOnline }]).toPromise();
        this.isSystemOnline = !this.isSystemOnline;
      } catch (error) {
        this.logService.error('[Toggle System Status error]', error);
      }
    });
  }

  async onConversationsClose() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'ADMIN_SETTINGS.CLOSE_CONVERSATIONS_CONFIRM',
      },
    });

    dialogRef.afterClosed().subscribe(async (value) => {
      if (!value) {
        return;
      }

      try {
        this.isConversationsClosing = true;
        const res = await this.apiService.closeAllConversations().toPromise();
        this._lastTimeConversationsClosed = res.time;
      } catch (error) {
        this.isConversationsClosing = false;
        this.logService.error('[Conversations Close error]', error);
      }
    });
  }

  get lastTimeConversationsClosed() {
    const date = this._lastTimeConversationsClosed
      ? moment(this._lastTimeConversationsClosed).format('DD MMM HH:mm')
      : '';
    return date;
  }
}
