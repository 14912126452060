import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import * as _ from 'lodash';
import { listFadeInOut } from '@cation/core/animations';
import { getI18nLocaleByLocale } from '@cation/core/util/common-util';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { LogService } from '@cation/core/services/log/log.service';
import { HotKey } from '@cation/core/enums/hotkey.enum';
import { HotKeys } from '@cation/core/util/hot-keys';
import { classChannelIcon } from '@cation/core/enums/channel.enum';
import Conversation from '@cation/core/types/conversation';
import User from '@cation/core/types/user';
import { ConversationService } from '@cation/core/services/conversation/conversation.service';

@Component({
  selector: 'app-chat-convo-list',
  templateUrl: './chat-convo-list.component.html',
  styleUrls: ['./chat-convo-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [listFadeInOut],
})
export class ChatConvoListComponent implements OnInit {
  nextToken: string;
  _user: User;
  classIcon = classChannelIcon;

  messagesData = {};
  countUnreadMessages = {};
  newConversations = [];

  translateYourMessage = 'You';

  hotkeyUp: Subscription;
  hotkeyDown: Subscription;

  videoChatStatus: boolean;

  @Input()
  set user(user: User) {
    this._user = user;
  }

  @Input() current: Conversation;
  @Input() conversations: Conversation[] = [];

  constructor(
    public conversationService: ConversationService,
    private sharedService: SharedService,
    private logService: LogService,
    private hotkeys: HotKeys,
    private translate: TranslateService
  ) {
    this.sharedService.currentNewConversations.subscribe((conversations) => (this.newConversations = conversations));
    this.sharedService.currentMessagesData.subscribe((messagesData) => (this.messagesData = messagesData));
    this.sharedService.currentCountUnreadMessages.subscribe((value) => (this.countUnreadMessages = value));
  }

  ngOnInit() {
    this.logService.debug('Subscribe to hot keys');
    this.hotkeyUp = this.hotkeys
      .on(HotKey.CONVERSATION_UP)
      .pipe(debounceTime(500))
      .subscribe(this.switchConversation.bind(this));
    this.hotkeyDown = this.hotkeys
      .on(HotKey.CONVERSATION_DOWN)
      .pipe(debounceTime(500))
      .subscribe(this.switchConversation.bind(this));
    this.translate.stream('CONVERSATION_VIEW.YOUR_MESSAGE').subscribe((value) => (this.translateYourMessage = value));

    // When video chat started for a chat then disable agent switching to other chats
    this.sharedService.videoChatStatus$.subscribe((status) => (this.videoChatStatus = status));
  }

  ngOnDestroy(): void {
    this.hotkeyUp.unsubscribe();
    this.hotkeyDown.unsubscribe();
  }

  private switchConversation(evt) {
    this.logService.debug('switchConversation', evt);

    if (!this.conversations || !this.conversations.length) {
      return;
    }

    let index = 0;

    if (this.current) {
      index = _.findIndex(this.conversations, { id: this.current.id });
      this.logService.debug('Current index - ', index);

      index = evt.key === 'ArrowUp' ? --index : ++index;
      if (index < 0) {
        index = this.conversations.length - 1;
      }
      if (index >= this.conversations.length) {
        index = 0;
      }
      this.logService.debug('Next index - ', index);
    }

    const convo = this.conversations[index];

    if (convo) {
      this.logService.debug('Switch to Conversation', convo.name);
      this.selectConversation(convo);
    }
  }

  public trackConversation(index, conversation) {
    return conversation ? conversation.id : undefined;
  }

  public selectConversation(conversation: Conversation) {
    if (this.videoChatStatus) return;

    const newConversations = this.newConversations.filter((item) => item !== conversation.id);
    this.sharedService.setCurrentConversation(conversation);
    this.sharedService.setNewConversations(newConversations);
    this.translate.use(getI18nLocaleByLocale(conversation.locale));
  }

  public prepareCountUnreadMessages(value) {
    if (isNaN(value) || value > 9) {
      return '9+';
    }
    return value;
  }

  getLastMessage(conversation: Conversation) {
    const messagesData = this.messagesData[conversation.id] || {};
    const messages = (messagesData.messages || []).filter((msg) => !msg.content.startsWith('COMMAND:'));
    const lastMessage = messages[messages.length - 1];

    if (!lastMessage) {
      return '';
    }

    return this.conversationService.parseMessageContentToString(lastMessage.content);
  }

  getSenderName(conversation: Conversation): string {
    const messagesData = this.messagesData[conversation.id] || {};
    const messages = messagesData.messages || [];
    const lastMessage = messages[messages.length - 1];

    if (lastMessage && lastMessage.sender !== this._user.cognitoId) {
      return `${conversation.author.username.split(' ')[0]}: `;
    }

    return '';
  }
}
