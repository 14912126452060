<div class="row my-statistics-list-page px-3 pb-2">
  <mat-card class="my-statistics-card w-100">
    <mat-card-header>
      <div mat-card-avatar class="d-flex justify-content-center align-items-center">
        <mat-icon>timeline</mat-icon>
      </div>
      <mat-card-title class="d-flex align-items-start justify-content-between">
        <div class="d-flex">{{ 'MY_STATISTICS.TITLE' | translate }}</div>
        <div class="d-flex" *ngIf="selectedTab.value < 4">
          <mat-form-field class="statistics-date-picker p-0">
            <mat-select name="filter-type" [placeholder]="'Filter Type' | translate" [(ngModel)]="selectedFilterType">
              <mat-option *ngFor="let filter of filters" [value]="filter">
                {{ filter }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="statistics-date-picker p-0" *ngIf="selectedFilterType === 'On Date'">
            <input
              type="date"
              matInput
              [max]="maxDate"
              [placeholder]="'CONVERSATION_SEARCH.DATE_PICKER_PLACEHOLDER' | translate"
              [(ngModel)]="statisticsDate"
              required
            />
          </mat-form-field>
          <mat-form-field class="statistics-date-picker p-0" *ngIf="selectedFilterType === 'Range'">
            <input
              matInput
              [placeholder]="'CONVERSATION_SEARCH.DATE_PICKER_PLACEHOLDER' | translate"
              [satDatepicker]="picker"
              [(ngModel)]="dateRange"
              [max]="maxDate"
              (keydown.enter)="onDateChange('dateRange')"
              (dateChange)="onDateChange('dateRange')"
            />
            <sat-datepicker #picker [rangeMode]="true" [selectFirstDateOnClose]="true"></sat-datepicker>
            <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
          </mat-form-field>
        </div>
        <div class="d-flex" *ngIf="selectedTab.value > 4">
          <mat-form-field class="statistics-date-picker p-0">
            <input
              matInput
              [placeholder]="'CONVERSATION_SEARCH.DATE_PICKER_PLACEHOLDER' | translate"
              [satDatepicker]="picker"
              [(ngModel)]="advancedDate"
              [max]="maxDate"
              (keydown.enter)="onDateChange('advancedDate')"
              (dateChange)="onDateChange('advancedDate')"
            />
            <sat-datepicker #picker [rangeMode]="true" [selectFirstDateOnClose]="true"></sat-datepicker>
            <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
          </mat-form-field>
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div *ngIf="isLoading" class="my-statistics-loader" [@fadeInOut]>
        <mat-spinner mode="indeterminate"></mat-spinner>
      </div>

      <mat-tab-group
        class="vertical-tab-group"
        [selectedIndex]="selectedTab.value"
        (selectedIndexChange)="selectedTab.setValue($event)"
      >
        <mat-tab [label]="'MY_STATISTICS.SIMPLE_STATISTICS_TAB_TITLE' | translate" [disabled]="true"></mat-tab>

        <mat-tab [label]="'MY_STATISTICS.TOTAL_POINTS_STARS_TAB_TITLE' | translate">
          <div class="my-statistics-metrics row px-3 py-1" *ngIf="isActive && selectedTab.value === 1">
            <mat-card class="my-statistics-metrics__pie col-sm-12">
              <mat-card-header>
                <mat-card-title>{{ 'MY_STATISTICS.POINTS_TITLE' | translate }}</mat-card-title>
              </mat-card-header>
              <mat-card-content class="w-100">
                <div class="chart-parent">
                  <ngx-charts-pie-chart
                    [scheme]="colorScheme"
                    [results]="points"
                    [gradient]="true"
                    [legend]="true"
                    [legendTitle]="'MY_STATISTICS.LEGEND_TITLE' | translate"
                    [labels]="false"
                    legendPosition="below"
                  >
                  </ngx-charts-pie-chart>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="my-statistics-metrics__pie col-sm-12">
              <mat-card-header>
                <mat-card-title>{{ 'MY_STATISTICS.STARS_TITLE' | translate }}</mat-card-title>
              </mat-card-header>
              <mat-card-content class="w-100">
                <div class="chart-parent">
                  <ngx-charts-pie-chart
                    [scheme]="colorScheme"
                    [results]="stars"
                    [gradient]="true"
                    [legend]="true"
                    [legendTitle]="'MY_STATISTICS.LEGEND_TITLE' | translate"
                    [labels]="false"
                    legendPosition="below"
                  >
                  </ngx-charts-pie-chart>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab [label]="'MY_STATISTICS.TOTAL_NUMBER_TAB_TITLE' | translate">
          <div class="my-statistics-metrics row px-3 py-1" *ngIf="isActive && selectedTab.value === 2">
            <mat-card class="my-statistics-metrics__pie col-sm-12">
              <mat-card-header>
                <mat-card-title>{{ 'MY_STATISTICS.TOTAL_NUMBER_TAB_TITLE' | translate }}</mat-card-title>
              </mat-card-header>
              <mat-card-content class="w-100">
                <app-single-total-conversations-chart
                  *ngIf="statistics"
                  metric="channel"
                  metricField="countConversations"
                  colorSchemaName="green-red"
                  [statistics]="statistics"
                >
                </app-single-total-conversations-chart>
              </mat-card-content>
            </mat-card>
            <mat-card class="my-statistics-metrics__pie col-sm-12">
              <mat-card-header>
                <mat-card-title></mat-card-title>
              </mat-card-header>
              <mat-card-content class="w-100">
                <app-single-total-conversations-chart
                  *ngIf="statistics"
                  metric="locale"
                  metricField="countConversations"
                  colorSchemaName="material500"
                  [statistics]="statistics"
                >
                </app-single-total-conversations-chart>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab [label]="'MY_STATISTICS.TOTAL_DURATION_TAB_TITLE' | translate">
          <div class="my-statistics-metrics row px-3 py-1" *ngIf="isActive && selectedTab.value === 3">
            <mat-card class="my-statistics-metrics__pie col-sm-12">
              <mat-card-header>
                <mat-card-title>{{ 'MY_STATISTICS.TOTAL_DURATION_TAB_TITLE' | translate }}</mat-card-title>
              </mat-card-header>
              <mat-card-content class="w-100">
                <app-single-total-conversations-chart
                  *ngIf="statistics"
                  metric="channel"
                  metricField="conversationsDuration"
                  colorSchemaName="blue"
                  [statistics]="statistics"
                >
                </app-single-total-conversations-chart>
              </mat-card-content>
            </mat-card>
            <mat-card class="my-statistics-metrics__pie col-sm-12">
              <mat-card-header>
                <mat-card-title></mat-card-title>
              </mat-card-header>
              <mat-card-content class="w-100">
                <app-single-total-conversations-chart
                  *ngIf="statistics"
                  metric="locale"
                  metricField="conversationsDuration"
                  colorSchemaName="material500"
                  [statistics]="statistics"
                >
                </app-single-total-conversations-chart>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>

        <mat-tab [label]="'MY_STATISTICS.ADVANCED_STATISTICS_TAB_TITLE' | translate" [disabled]="true"></mat-tab>

        <mat-tab [label]="'MY_STATISTICS.TOTAL_POINTS_STARS_TAB_TITLE' | translate">
          <div class="my-statistics-metrics row px-3 py-1" *ngIf="isActive && selectedTab.value === 5">
            <mat-card class="advanced-statistics-card col-xl-6 col-sm-12 my-1">
              <mat-card-header>
                <mat-card-title>
                  {{ 'MY_STATISTICS.POINTS_TITLE' | translate }}
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="h-100 w-100">
                <app-user-conversations-range-metric-chart [statistics]="rangeStatistics" statisticsField="points">
                </app-user-conversations-range-metric-chart>
              </mat-card-content>
            </mat-card>
            <mat-card class="advanced-statistics-card col-xl-6 col-sm-12 my-1">
              <mat-card-header>
                <mat-card-title>
                  {{ 'MY_STATISTICS.STARS_TITLE' | translate }}
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="h-100 w-100">
                <app-user-conversations-range-metric-chart [statistics]="rangeStatistics" statisticsField="stars">
                </app-user-conversations-range-metric-chart>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab [label]="'MY_STATISTICS.COUNT_CONVERSATIONS_BY_CHANNEL_TAB_TITLE' | translate">
          <div class="my-statistics-metrics row px-3 py-1" *ngIf="isActive && selectedTab.value === 6">
            <mat-card class="my-statistics-metrics__multiple col-sm-12">
              <mat-card-header>
                <mat-card-title>
                  {{ 'MY_STATISTICS.COUNT_CONVERSATIONS_BY_CHANNEL_TAB_TITLE' | translate }}
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="w-100">
                <app-multiple-total-conversations-chart
                  metric="channel"
                  metricField="countConversations"
                  colorSchemaName="material500"
                  [statistics]="rangeStatistics"
                >
                </app-multiple-total-conversations-chart>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab [label]="'MY_STATISTICS.COUNT_CONVERSATIONS_BY_LOCALE_TAB_TITLE' | translate">
          <div class="my-statistics-metrics row px-3 py-1" *ngIf="isActive && selectedTab.value === 7">
            <mat-card class="my-statistics-metrics__multiple col-sm-12">
              <mat-card-header>
                <mat-card-title>
                  {{ 'MY_STATISTICS.COUNT_CONVERSATIONS_BY_LOCALE_TAB_TITLE' | translate }}
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="w-100">
                <app-multiple-total-conversations-chart
                  metric="locale"
                  colorSchemaName="green-red"
                  metricField="countConversations"
                  [statistics]="rangeStatistics"
                >
                </app-multiple-total-conversations-chart>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab [label]="'MY_STATISTICS.CONVERSATIONS_DURATION_BY_CHANNEL_TAB_TITLE' | translate">
          <div class="my-statistics-metrics row px-3 py-1" *ngIf="isActive && selectedTab.value === 8">
            <mat-card class="my-statistics-metrics__multiple col-sm-12">
              <mat-card-header>
                <mat-card-title>
                  {{ 'MY_STATISTICS.CONVERSATIONS_DURATION_BY_CHANNEL_TAB_TITLE' | translate }}
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="w-100">
                <app-multiple-total-conversations-chart
                  metric="channel"
                  colorSchemaName="blue"
                  metricField="conversationsDuration"
                  [statistics]="rangeStatistics"
                >
                </app-multiple-total-conversations-chart>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab [label]="'MY_STATISTICS.CONVERSATIONS_DURATION_BY_LOCALE_TAB_TITLE' | translate">
          <div class="my-statistics-metrics row px-3 py-1" *ngIf="isActive && selectedTab.value === 9">
            <mat-card class="my-statistics-metrics__multiple col-sm-12">
              <mat-card-header>
                <mat-card-title>
                  {{ 'MY_STATISTICS.CONVERSATIONS_DURATION_BY_LOCALE_TAB_TITLE' | translate }}
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="w-100">
                <app-multiple-total-conversations-chart
                  metric="locale"
                  colorSchemaName="blue"
                  metricField="conversationsDuration"
                  [statistics]="rangeStatistics"
                >
                </app-multiple-total-conversations-chart>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
        <mat-tab [label]="'MY_STATISTICS.AVERAGE_CONVERSATIONS_DURATION_TAB_TITLE' | translate">
          <div class="my-statistics-metrics row px-3 py-1" *ngIf="isActive && selectedTab.value === 10">
            <mat-card class="my-statistics-metrics__multiple col-sm-12">
              <mat-card-header>
                <mat-card-title>
                  {{ 'MY_STATISTICS.AVERAGE_CONVERSATIONS_DURATION_TAB_TITLE' | translate }}
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="w-100">
                <app-average-conversations-duration-chart [statistics]="rangeStatistics">
                </app-average-conversations-duration-chart>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
