import { HostnameEnum } from './../../enums/hostname.enum';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlParserService {
  private clients = {
    isParlyEngage: false,
    isEngageHub: false
  };

  get isParlyEngage(): boolean {
    return this.clients.isParlyEngage;
  }

  get isEngageHub(): boolean {
    return this.clients.isEngageHub;
  }

  constructor() {}

  parseClient() {
    this.resetClient();
    const hostname = window.location.hostname;

    switch (hostname) {
      case HostnameEnum.engageHub:
      case HostnameEnum.yourBank:
        this.clients.isEngageHub = true;
        break;
      default:
        this.clients.isParlyEngage = true;
    }
  }

  private resetClient() {
    for (const [key] of Object.entries(this.clients)) {
      this.clients[key] = false;
    }
  }
}
