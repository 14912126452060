import { ApiService } from '@cation/core/services/api/api.service';
import { CMS_TYPE } from '@cation/core/enums/cms-type.enum';
import { EventEmitter } from '@angular/core';
import { IAnyObject, ICMS, IComment } from '../../interfaces/cms';

export default class Jira implements ICMS {
  type = CMS_TYPE.JIRA;

  id: string | number;
  name: string;
  title: string;
  description: string;
  comments: IComment[] = [];

  createdAt: number;

  isInitialized = false;
  isDataLoading = false;
  isCommentsLoading = false;

  onAddNewComment: EventEmitter<IAnyObject>;

  constructor(data: IAnyObject, private api: ApiService) {
    this.id = data.key;
    this.name = data.key;
    this.title = data.fields.summary;
    this.description = data.fields.description; // TODO: check
  }

  init() {

  }

  addComment() {

  }

  changeStatus() {

  }

  toJSON() {
    return '';
  }
}
