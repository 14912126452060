import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ObservableQuery } from 'apollo-client';
import { Analytics } from 'aws-amplify';
import { listFadeInOut } from '@cation/core/animations';
import { pushTeams } from '@cation/core/util/chat-helper';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { AppsyncTeamService } from '@cation/core/services/appsync/appsync-team.service';
import { PromptDialogComponent } from '@cation/core/dialogs/prompt-dialog/prompt-dialog.component';
import { AddEditTeamDialogComponent } from '@cation/admin/components/add-edit-team-dialog/add-edit-team-dialog.component';
import { getTeamsQuery as TeamsQuery } from '@cation/core/graphql/operation-result-types';
import Team from '@cation/core/types/team';

@Component({
  selector: 'ctn-manage-team',
  templateUrl: './manage-team.component.html',
  styleUrls: ['./manage-team.component.scss'],
  animations: [listFadeInOut]
})
export class ManageTeamComponent implements OnInit {
  public errorMessage;
  isLoading = false;

  teams: Team[] = [];
  nextToken: string;
  observedQuery: ObservableQuery<TeamsQuery>;

  constructor(
    private api: ApiService,
    private appsyncTeamService: AppsyncTeamService,
    private logService: LogService,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.loadTeams();
  }

  public trackTeam(index, item) {
    return item ? item.id : undefined;
  }

  public onAddTeam() {
    const dialogRef = this.dialog.open(AddEditTeamDialogComponent, {});

    dialogRef.afterClosed().subscribe(async team => {
      this.logService.log('prompt:value', team);
    });
  }

  public onEditTeam(team) {
    const dialogRef = this.dialog.open(AddEditTeamDialogComponent, { data: team });

    dialogRef.afterClosed().subscribe(async team => {
      this.logService.log('prompt:value', team);
    });
  }

  public async onDeleteTeam(team) {
    this.logService.log('onDeleteTeam', team.name);

    const dialogRef = this.dialog.open(PromptDialogComponent, {
      data: {
        title: 'Are you sure you want to delete the team?',
        message: `Type '${team.name}' to confirm`,
        pattern: new RegExp(`^${team.name}$`, 'g'),
        confirmBtnName: 'BUTTON.DELETE'
      }
    });

    dialogRef.afterClosed().subscribe(async value => {
      this.logService.log('prompt:value', value);
      if (!value) {
        return;
      }

      const data = await this.deleteTeam(team);
      this.logService.log('deleteTeam:data', data);
    });
  }

  private async loadTeams() {
    this.isLoading = true;
    const observerOrNext = async ({ data }) => {
      this.logService.log('[loadTeams subscribe]', data);
      if (!data) {
        return this.logService.log('[loadTeams - no data]');
      }

      this.teams = data.getTeams.teams;
      this.nextToken = data.getTeams.nextToken;

      this.isLoading = false;

      this.logService.log('[loadTeams]: nextToken is now', data.getTeams.nextToken ? 'set' : 'null');
    };

    const { observedQuery } = await this.appsyncTeamService.loadTeams(observerOrNext);
    this.observedQuery = observedQuery;
  }

  public loadMoreTeams(event = null) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    const updateQuery = (prev, data) => {
      const { fetchMoreResult } = data;

      if (!fetchMoreResult) {
        return prev;
      }

      const _res = pushTeams(prev as TeamsQuery, fetchMoreResult.getTeams.teams, fetchMoreResult.getTeams.nextToken);
      this.nextToken = fetchMoreResult.getTeams.nextToken;

      return _res;
    };

    return this.appsyncTeamService.loadMoreByObservedQuery(this.observedQuery, this.nextToken, { updateQuery });
  }

  public deleteTeam(team) {
    this.logService.log('[deleteTeam team]', team);

    return this.appsyncTeamService
      .deleteTeam(team)
      .then(({ data }) => {
        this.logService.log('mutation complete', data);
        return data;
      })
      .catch(err => this.logService.error('Error deleting team', err))
      .then(() => Analytics.record('Delete Team'));
  }
}
