import User from '@cation/core/types/user';
import Team from '@cation/core/types/team';

interface IStatisticsItem {
  level: string;
  memberId: string;
  points: number;
  weeklyPoints: number;
  yearlyPoints: number;
  monthlyPoints: number;
  prevWeeklyPoints: number;
  prevYearlyPoints: number;
  prevMonthlyPoints: number;
  monthlyPointPool: number;
}

export interface IStatisticsUser extends IStatisticsItem {
  member: User;
  type: 'USER';
}

export interface IStatisticsTeam extends IStatisticsItem {
  member: Team;
  type: 'TEAM';
}

export type IStatisticsMember = IStatisticsUser | IStatisticsTeam;

export type IStatisticsResponse = {
  items: IStatisticsMember[];
  lastKey: object;
};
