import { Injectable } from '@angular/core';
import { AWSAppSyncClient, AUTH_TYPE } from 'aws-appsync';
import { Auth } from '@aws-amplify/auth';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AppsyncService {
  // hc;
  _hc;

  constructor() {
    const client = new AWSAppSyncClient({
      url: environment.amplifyConfig.aws_appsync_graphqlEndpoint,
      region: environment.amplifyConfig.aws_project_region,
      disableOffline: true,
      auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: () => Auth.currentCredentials(),
        // type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        // jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
      },
      // complexObjectsCredentials: () => Auth.currentCredentials()
    });
    // this.hc = client.hydrated;
    this._hc = client;
  }

  hc() {
    return this._hc.hydrated();
  }
}
