<div class="profile-page-container">
  <ngx-loading [show]="isLoading"></ngx-loading>

  <mat-card class="mb-2">
    <mat-card-content>
      <div class="row m-0 justify-content-center p-0">
        <div class="d-flex flex-column col-lg-4 row m-0 p-0">
          <h5>{{ 'PROFILE.AVATAR_BLOCK.TITLE' | translate }}</h5>

          <div class="d-flex w-100 align-items-center m-0 p-0 my-auto">
            <div class="user__avatar">
              <app-image-picker
                class="w-100 h-100"
                [keyPrefix]="userProfile.cognitoId + '/internal-avatar'"
                [isRounded]="true"
                [image]="userProfile?.userData?.avatarUrl"
                (onImageSelect)="updateUserData('avatarUrl', $event)"
                [@fadeInOut]
              ></app-image-picker>
            </div>
            <div class="d-flex flex-column ps-2 justify-content-between user__edit-block">
              <ctn-inline-edit
                [maxLength]="15"
                name="nickname"
                [label]="'PROFILE.AVATAR_BLOCK.NICK_NAME_TITLE' | translate"
                [value]="userProfile?.userData?.nickname"
                (change)="updateUserData('nickname', $any($event.target).value)"
              >
              </ctn-inline-edit>
              <ctn-inline-edit
                [maxLength]="100"
                name="status"
                [label]="'PROFILE.AVATAR_BLOCK.STATUS_TITLE' | translate"
                [value]="userProfile?.userData?.status"
                (change)="updateUserData('status', $any($event.target).value)"
              >
              </ctn-inline-edit>
            </div>
          </div>
        </div>

        <div class="user__level-container col-lg-3 p-2">
          <h5>{{ 'PROFILE.MY_JOURNEY_BLOCK.TITLE' | translate }}</h5>

          <div *ngIf="level" class="user__level d-flex flex-column border rounded p-2" [@fadeInOut]>
            <div class="row p-2 d-flex justify-content-around align-items-center">
              <div class="d-flex justify-content-center align-items-center">
                <div class="user__level__icon">
                  <app-s3-image [image]="level.icon"></app-s3-image>
                </div>
                <span class="user__level__name ps-2">{{ level.name }}</span>
              </div>
              <span
                class="user__level__points"
                [innerHTML]="'PROFILE.MY_JOURNEY_BLOCK.EXP_POINTS' | translate: { points: points }"
              ></span>
            </div>
            <mat-progress-bar
              mode="determinate"
              [value]="((points - level.points) * 100) / (nextLevel.points - level.points)"
            >
            </mat-progress-bar>
            <div
              class="row p-2 d-flex justify-content-center align-items-center"
              *ngIf="nextLevel.points - points >= 0"
            >
              <span
                class="user__level__points"
                [innerHTML]="
                  'PROFILE.MY_JOURNEY_BLOCK.EXP_POINTS_TO_NEXT_LEVEL' | translate: { points: nextLevel.points - points }
                "
              ></span>
            </div>
          </div>
        </div>

        <div class="gamification-events col-lg-5 p-0 ps-2">
          <h5>{{ 'PROFILE.MY_TEAMS_ACTIVITY_BLOCK_TITLE' | translate }}</h5>
          <app-gamification-events-list [memberId]="userProfile?.teamId"></app-gamification-events-list>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-tab-group class="vertical-tab-group profile-tabs" (selectedIndexChange)="selectedIndex = $event">
    <mat-tab [label]="'PROFILE.TAB_MY_SKILLS' | translate">
      <div class="row px-3 mb-2">
        <mat-card class="skills-card col-lg-6">
          <mat-card-header>
            <div mat-card-avatar class="d-flex justify-content-center align-items-center">
              <mat-icon>language</mat-icon>
            </div>
            <mat-card-title>{{ 'PROFILE.LOCALES_TITLE' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <app-capabilities-list [capabilities]="localeCapabilities" [withReverse]="true" [noMargin]="true">
            </app-capabilities-list>
          </mat-card-content>
        </mat-card>

        <mat-card class="skills-card col-lg-6">
          <mat-card-header>
            <div mat-card-avatar class="d-flex justify-content-center align-items-center">
              <mat-icon>group_work</mat-icon>
            </div>
            <mat-card-title>{{ 'PROFILE.CAPABILITIES_TITLE' | translate }}</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <app-capabilities-list
              [capabilities]="keywordsCapabilities"
              [withTranslate]="false"
            ></app-capabilities-list>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab [label]="'PROFILE.TAB_MY_STATISTICS' | translate">
      <app-my-statistics2 [isActive]="selectedIndex === 1"></app-my-statistics2>
    </mat-tab>
    <mat-tab [label]="'PROFILE.TAB_AWARDS' | translate">
      <div class="row px-3 mb-2">
        <mat-card class="awards-card col-lg-6">
          <mat-card-header>
            <div mat-card-avatar class="d-flex justify-content-center align-items-center">
              <mat-icon>sports_esports</mat-icon>
            </div>
            <mat-card-title>{{ 'LIST_ACHIEVEMENTS.TITLE' | translate }}</mat-card-title>
            <mat-card-subtitle>{{ 'LIST_ACHIEVEMENTS.DESCRIPTION' | translate }}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <app-achievements-list [achievements]="achievements[0]" [achievementProgress]="achievementProgress">
            </app-achievements-list>
          </mat-card-content>
        </mat-card>

        <mat-card class="awards-card col-lg-6">
          <mat-card-header>
            <div mat-card-avatar class="d-flex justify-content-center align-items-center">
              <mat-icon>emoji_events</mat-icon>
            </div>
            <mat-card-title>{{ 'PROFILE.SPECIAL_AWARDS.TITLE' | translate }}</mat-card-title>
            <mat-card-subtitle>{{ 'PROFILE.SPECIAL_AWARDS.DESCRIPTION' | translate }}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content>
            <app-achievements-list [achievements]="achievements[1]" [achievementProgress]="achievementProgress">
            </app-achievements-list>
          </mat-card-content>
        </mat-card>
      </div>
    </mat-tab>
    <mat-tab [label]="'PROFILE.TAB_FEEDBACK' | translate">
      <app-feedback-comments-list></app-feedback-comments-list>
    </mat-tab>
    <mat-tab [label]="'PROFILE.TAB_LEAGUES' | translate">
      <app-league-statistics [isActive]="selectedIndex === 4"></app-league-statistics>
    </mat-tab>
    <mat-tab [label]="'PROFILE.TAB_CHALLENGES' | translate">
      <app-challenges-list [autoRefresh]="selectedIndex === 5" (onAddChallengeWager)="addChallengeWager()">
      </app-challenges-list>
    </mat-tab>
    <mat-tab [label]="'PROFILE.TAB_CHALLENGE_WAGERS' | translate">
      <app-challenge-wagers-list
        [autoRefresh]="selectedIndex === 6"
        [showChallenges]="true"
      ></app-challenge-wagers-list>
    </mat-tab>
    <mat-tab [label]="'PROFILE.TAB_POINTS_STATEMENT' | translate">
      <app-score-history-list [autoRefresh]="selectedIndex === 7"></app-score-history-list>
    </mat-tab>
    <!-- TODO: Hidden -->
    <!--    <mat-tab [label]="'PROFILE.TAB_MY_STATISTICS' | translate">-->
    <!--      <app-my-statistics [isActive]="selectedIndex === 7"></app-my-statistics>-->
    <!--    </mat-tab>-->
  </mat-tab-group>
</div>
