<div class="capabilities py-2" [class.capabilities_no-margin]="noMargin" [@listFadeInOut]="capabilities.length">
  <div class="capability-item-wrapper" *ngFor="let item of capabilities">
    <div
      class="capability-item"
      [class.capability-item_no-margin]="noMargin"
      [class.capability-item_inactive]="!item.isAvailable"
    >
      <div class="front" [class.side-background]="!withReverse">
        <div class="d-flex lex-column w-100 justify-content-center align-items-center">
          <img [src]="item.icon" draggable="false" alt="capability item icon" class="capability-item__icon" />
        </div>
      </div>
      <div class="back" [class.side-background]="!withReverse">
        <div class="d-flex flex-column h-100 justify-content-center align-items-center">
          <img
            *ngIf="withReverse"
            [src]="item.icon"
            alt="capability item icon"
            draggable="false"
            class="capability-item__icon capability-item__icon-back"
          />
          <div *ngIf="!withTranslate" class="capability-item__title capability-item__title-back">{{ item.name }}</div>
          <div *ngIf="withTranslate" class="capability-item__title capability-item__title-back">
            {{ item.name | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
