import gql from 'graphql-tag';

export default gql`
  mutation createConversationNote($input: ConversationNoteCreateInput!) {
    createConversationNote(input: $input) {
      __typename
      id
      conversationId
      createdBy
      content
      author {
        cognitoId
        username
        userData
      }
    }
  }
`;
