import { Component } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';

@Component({
  selector: 'app-sub-navbar',
  templateUrl: './app-sub-navbar.component.html',
  styleUrls: ['./app-sub-navbar.component.scss']
})
export class AppSubNavbarComponent {
  linksConfig: Route[] = [];

  constructor(private route: ActivatedRoute) {
    this.linksConfig = this.route.snapshot.data.navbarConfig;
  }
}
