import Achievement from '@cation/core/types/achievement';
import { ISettingsLevel } from './gamification-settings';

export interface IChallengeMember {
  type: 'USER' | 'TEAM';
  memberId: string;
  points: number;
  score: number;
  monthlyPointPool: number;
  weeklyPoints: number;
  monthlyPoints: number;
  yearlyPoints: number;
  prevWeeklyPoints: number;
  prevMonthlyPoints: number;
  prevYearlyPoints: number;

  level: ISettingsLevel;
  nextLevel: ISettingsLevel;

  achievements: Achievement[];
  achievementProgress: { [achievementId: string]: number };
}
