<div class="text-center">
  <h1 mat-dialog-title class="d-inline-block">{{ 'CMS_ACTIONS.ESCALATE_CASE_DIALOG.TITLE' | translate }}</h1>
  <button mat-icon-button [tabIndex]="-1" type="button" class="close" (click)="dialogRef.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="nextUserFormGroup">
      <form [formGroup]="nextUserFormGroup">
        <ng-template matStepLabel>
          {{ 'CMS_ACTIONS.ESCALATE_CASE_DIALOG.SELECT_AGENT_STEP.LABEL' | translate }}
        </ng-template>
        <mat-progress-bar class="m-2" *ngIf="isAgentsLoading" [mode]="'indeterminate'"></mat-progress-bar>
        <app-agent-input-autocomplete
          (selectionChange)="nextUserForm.nextUser.setValue($event)"
          [required]="true"
          [isOnlineOnly]="true"
          [placeholder]="'CMS_ACTIONS.ESCALATE_CASE_DIALOG.SELECT_AGENT_STEP.PLACEHOLDER' | translate"
          [placeholderLabel]="'CMS_ACTIONS.ESCALATE_CASE_DIALOG.SELECT_AGENT_STEP.INPUT_PLACEHOLDER' | translate"
          [hint]="'CMS_ACTIONS.ESCALATE_CASE_DIALOG.SELECT_AGENT_STEP.HINT' | translate"
          [errorLabel]="'CMS_ACTIONS.ESCALATE_CASE_DIALOG.SELECT_AGENT_STEP.ERROR' | translate"
        >
        </app-agent-input-autocomplete>
        <div>
          <button mat-button matStepperNext>{{ 'BUTTON.NEXT' | translate }}</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="descriptionFormGroup">
      <form [formGroup]="descriptionFormGroup">
        <ng-template matStepLabel>
          {{ 'CMS_ACTIONS.ESCALATE_CASE_DIALOG.DESCRIPTION_STEP.LABEL' | translate }}
        </ng-template>
        <mat-form-field class="w-100">
          <textarea
            matInput
            [placeholder]="'CMS_ACTIONS.ESCALATE_CASE_DIALOG.DESCRIPTION_STEP.TEXT_PLACEHOLDER' | translate"
            formControlName="description"
            required
          >
          </textarea>
        </mat-form-field>
        <div>
          <button mat-button matStepperPrevious>{{ 'BUTTON.BACK' | translate }}</button>
          <button mat-button matStepperNext>{{ 'BUTTON.NEXT' | translate }}</button>
        </div>
      </form>
    </mat-step>
    <mat-step class="position-relative">
      <ngx-ui-loader [fgsSize]="25" [loaderId]="loaderId"></ngx-ui-loader>
      <ng-template matStepLabel>
        {{ 'CMS_ACTIONS.ESCALATE_CASE_DIALOG.FINISH_STEP.LABEL' | translate }}
      </ng-template>
      <div class="d-flex row justify-content-center align-items-center">
        <h3>{{ data.conversation?.name }}</h3>
        <mat-icon>arrow_forward</mat-icon>
        <h3>{{ nextUserForm.nextUser.value?.username }}</h3>
      </div>
      <div class="d-flex row justify-content-center align-items-center">
        <h4>{{ descriptionForm.description.value }}</h4>
      </div>
      <div>
        <button mat-button matStepperPrevious>{{ 'BUTTON.BACK' | translate }}</button>
        <button mat-button (click)="stepper.reset(); errorMessage = ''">{{ 'BUTTON.RESET' | translate }}</button>
        <button mat-button (click)="escalate()">{{ 'BUTTON.ESCALATE' | translate }}</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

  <div *ngIf="errorMessage" class="alert alert-danger mt-2">
    {{ errorMessage }}
  </div>
</div>
