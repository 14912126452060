import gql from 'graphql-tag';

export default gql`
  mutation updateActiveTime($lastActiveTime: Float) {
    updateActiveTime(lastActiveTime: $lastActiveTime) {
      __typename
      cognitoId
    }
  }
`;
