import { NavItemTypeEnum } from '../enums/nav-item-type.enum';
import { Component } from '@angular/core';
import { RouteService } from '../services/route.service';
import routes from '../routes';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SharedService } from '../services/shared.service';
import { animate, style, transition, trigger, state } from '@angular/animations';

@Component({
  selector: 'internal-chat-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ width: 0, opacity: 0 }),
        animate('300ms ease', style({ width: '48px', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ width: '48px', opacity: 1 }),
        animate('300ms ease', style({ width: 0, opacity: 0 }))
      ])
    ])
  ]
})
export class NavbarComponent {
  _routes = routes;

  route = '';

  itemTypeEnum = NavItemTypeEnum;

  constructor(public routeService: RouteService, private sharedService: SharedService) {
    this.routeService.currentRoute.subscribe(value => (this.route = value));
    console.log(new PickerModule());
  }

  onNavbarItemClick(route) {
    this.setRoute(route);
  }

  private setRoute(route) {
    if (this.route === this._routes.CREATE_CHAT) {
      this.sharedService.setSelectedUsers([]);
    }

    this.routeService.setRoute(route);
  }
}
