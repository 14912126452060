<h1 mat-dialog-title class="text-center">{{ 'ADMIN_ADD_USER_VIEW.TITLE' | translate }}</h1>

<div mat-dialog-content>
  <div>{{ 'ADMIN_ADD_USER_VIEW.DESCRIPTION' | translate }}</div>

  <form class="ctn-form" #dialogForm="ngForm">
    <mat-form-field class="ctn-full-width pt-3">
      <input
        type="text"
        matInput
        cdkFocusInitial
        [placeholder]="'ADMIN_ADD_USER_VIEW.USERNAME_INPUT.PLACEHOLDER' | translate"
        [(ngModel)]="user.username"
        name="username"
        required="true"
      />
      <button
        mat-button
        *ngIf="user.username"
        matSuffix
        mat-icon-button
        [attr.aria-label]="'BUTTON.CLEAR' | translate"
        (click)="user.username = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint>{{ 'ADMIN_ADD_USER_VIEW.USERNAME_INPUT.HINT' | translate }}</mat-hint>
      <mat-error>{{ 'ADMIN_ADD_USER_VIEW.USERNAME_INPUT.ERROR' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field class="ctn-full-width pt-3">
      <input
        [type]="showPwdTxt ? 'text' : 'password'"
        matInput
        cdkFocusInitial
        [placeholder]="'ADMIN_ADD_USER_VIEW.PASSWORD_INPUT.PLACEHOLDER' | translate"
        [(ngModel)]="user.password"
        name="password"
        required="true"
      />
      <button
        matSuffix
        mat-icon-button
        (click)="showPwdTxt = !showPwdTxt"
        [attr.aria-label]="'ADMIN_ADD_USER_VIEW.PASSWORD_INPUT.BUTTON_HIDE_ARIA_LABEL' | translate"
        [attr.aria-pressed]="showPwdTxt"
      >
        <mat-icon>{{ showPwdTxt ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-hint>{{ 'ADMIN_ADD_USER_VIEW.PASSWORD_INPUT.HINT' | translate }}</mat-hint>
      <mat-error>{{ 'ADMIN_ADD_USER_VIEW.PASSWORD_INPUT.ERROR' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field class="ctn-full-width pt-3">
      <input
        type="email"
        matInput
        cdkFocusInitial
        [placeholder]="'ADMIN_ADD_USER_VIEW.EMAIL_INPUT.PLACEHOLDER' | translate"
        [(ngModel)]="user.email"
        name="email"
        required="true"
        email="email"
      />
      <button
        mat-button
        *ngIf="user.email"
        matSuffix
        mat-icon-button
        [attr.aria-label]="'BUTTON.CLEAR' | translate"
        (click)="user.email = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint>{{ 'ADMIN_ADD_USER_VIEW.EMAIL_INPUT.HINT' | translate }}</mat-hint>
      <mat-error>{{ 'ADMIN_ADD_USER_VIEW.EMAIL_INPUT.ERROR' | translate }}</mat-error>
    </mat-form-field>

    <mat-form-field class="ctn-full-width pt-3">
      <input
        type="text"
        matInput
        cdkFocusInitial
        [placeholder]="'ADMIN_ADD_USER_VIEW.PHONE_NUMBER_INPUT.PLACEHOLDER' | translate"
        [(ngModel)]="user.phoneNumber"
        name="phoneNumber"
        required="true"
      />
      <button
        mat-button
        *ngIf="user.phoneNumber"
        matSuffix
        mat-icon-button
        [attr.aria-label]="'BUTTON.CLEAR' | translate"
        (click)="user.phoneNumber = ''"
      >
        <mat-icon>close</mat-icon>
      </button>
      <mat-hint>{{ 'ADMIN_ADD_USER_VIEW.PHONE_NUMBER_INPUT.HINT' | translate }}</mat-hint>
      <mat-error>{{ 'ADMIN_ADD_USER_VIEW.PHONE_NUMBER_INPUT.ERROR' | translate }}</mat-error>
    </mat-form-field>

    <div *ngIf="errorMessage" class="alert alert-danger mt-2">
      {{ errorMessage }}
    </div>
  </form>
</div>

<div mat-dialog-actions class="d-flex justify-content-center">
  <button mat-button class="btn-save" (click)="addUser()" [disabled]="!dialogForm.valid">
    {{ 'BUTTON.SAVE' | translate }}
  </button>
  <button mat-button mat-dialog-close class="btn-cancel">
    {{ 'BUTTON.CANCEL' | translate }}
  </button>
</div>
