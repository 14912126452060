interface IObjectMetrics {
  [attr: string]: number | string;
}

export interface IUserConversationStatistics extends IObjectMetrics {
  userId: string;
  date: string;

  /** Count Conversations metrics */
  countConversations?: number;
  // countConversations[locale/keyword/channel]?: number;

  /** Conversations Duration metrics */
  conversationsDuration?: number;
  // conversationsDuration[locale/keyword/channel]?: number;

  /** Conversations Points / Starts metrics */
  points?: number;
  starts?: number;

  /** Count Conversations Stars metrics */
  countConversationsStars?: number;
  // countConversationsStars[locale/keyword/channel]?: number;

  /** Count Conversation Feedback Stars metrics */
  countConversationFeedbackStars?: number;
  // countConversationFeedbackStars[locale/keyword/channel]?: number;

  /** Count Conversation Feedback Points metrics */
  countConversationFeedbackPoints?: number;
  // countConversationFeedbackPoints[locale/keyword/channel]?: number;

  /** Count Conversations Points metrics */
  countConversationsPoints?: number;
  // countConversationsPoints[locale/keyword/channel]?: number;
}

export interface IUserConversationRangeStatistics {
  items: IUserConversationStatistics[];
  lastKey: object;
}
