<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
  <div *ngIf="data.message">{{ data.message | translate }}</div>
  <form class="ctn-form" #dialogForm="ngForm">
    <mat-form-field class="ctn-full-width pt-3  " *ngFor="let formField of data.formFields">
      <input
        matInput
        cdkFocusInitial
        placeholder="{{ formField.placeholder }}"
        [(ngModel)]="formField.value"
        pattern="{{ formField.pattern }}"
        #dataValue="ngModel"
        name="{{ formField.name }}"
        [type]="formField.type == 'password' && !formField.showPwdTxt ? 'password' : 'text'"
        [required]="formField.required"
        [email]="formField.type == 'email'"
      />
      <button
        mat-icon-button
        matSuffix
        (click)="formField.showPwdTxt = !formField.showPwdTxt"
        [attr.aria-label]="'PROMPT_DIALOG.BUTTON_HIDE_PASSWORD_ARIA_LABEL' | translate"
        [attr.aria-pressed]="formField.showPwdTxt"
        *ngIf="formField.type == 'password'"
      >
        <mat-icon>{{ formField.showPwdTxt ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <!--
      <button mat-button *ngIf="formField.value" matSuffix mat-icon-button [attr.aria-label]="'BUTTON.CLEAR' | translate"
        (click)="formField.value=''">
        <mat-icon>close</mat-icon>
      </button>
      -->
      <mat-hint>{{ formField.hint }}</mat-hint>
      <mat-error>{{ formField.error || 'PROMPT_DIALOG.DEFAULT_ERROR_MESSAGE' | translate }}</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'BUTTON.CANCEL' | translate }}</button>
  <button mat-button (click)="returnValues()" [disabled]="!dialogForm.valid">
    {{ 'BUTTON.OK' | translate }}
  </button>
</div>
