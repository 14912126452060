import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ObservableQuery } from 'apollo-client';
import * as _ from 'lodash';
import { listFadeInOut } from '@cation/core/animations';
import {
  addConversationByCognitoId,
  addConversationsByCognitoId,
  deleteNote,
  unshiftMessage,
  unshiftNote,
} from '@cation/core/util/chat-helper';
import {
  convertUserConversationToConversation,
  decodeMessage,
  getI18nLocaleByLocale,
} from '@cation/core/util/common-util';
import { AppsyncConversationService } from '@cation/core/services/appsync/appsync-conversation.service';
import { AppsyncMessageService } from '@cation/core/services/appsync/appsync-message.service';
import { AppsyncNoteService } from '@cation/core/services/appsync/appsync-note.service';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { CMS_TYPE } from '@cation/core/enums/cms-type.enum';
import { CmsService } from '@cation/core/services/cms';
import {
  getConversationNotesQuery as NotesQuery,
  getConversationMessagesQuery as MessagesQuery,
  getUserConversationConnectionByCognitoIdQuery as UserConvosQuery,
} from '@cation/core/graphql/operation-result-types';
import Conversation from '@cation/core/types/conversation';
import User from '@cation/core/types/user';
import { Channel, classChannelIcon } from '@cation/core/enums/channel.enum';

@Component({
  selector: 'app-convo-list',
  templateUrl: './convo-list.component.html',
  styleUrls: ['./convo-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [listFadeInOut],
})
export class ConvoListComponent {
  nextToken: string;
  _conversations: Conversation[] = [];
  _user: User;
  classIcon = classChannelIcon;

  observedQuery: ObservableQuery<UserConvosQuery>;
  observableSubscription: ZenObservable.Subscription;
  subscription: () => void;
  subscriptionsToUpdateUCs: ZenObservable.Subscription[] = [];
  subscriptionsToReadMessage: ZenObservable.Subscription[] = [];
  notesSubscriptions: {
    observableSubscription: ZenObservable.Subscription;
    newSubscription: () => void;
    deleteSubscription: () => void;
  }[] = [];
  messagesSubscriptions: {
    observableSubscription: ZenObservable.Subscription;
    subscription: () => void;
  }[] = [];

  messagesData = {};
  notesData = {};
  countUnreadMessages = {};

  conversationFilter = '';

  @Input()
  set user(user: User) {
    this._user = user;
    if (this._user) {
      this.getAllConvos();
    }
  }

  @Input() current: Conversation;
  @Output() onConvoClick = new EventEmitter<Conversation>();
  @Output() onConvosLoad = new EventEmitter<{ cognitoId: string; conversations: Conversation[] }>();
  @Output() onUpdateConversation = new EventEmitter<Conversation>();

  constructor(
    private appsyncNoteService: AppsyncNoteService,
    private appsyncMessageService: AppsyncMessageService,
    private appsyncConversationService: AppsyncConversationService,
    private sharedService: SharedService,
    private cmsService: CmsService,
    private logService: LogService,
    private translate: TranslateService,
    private s3Service: S3Service
  ) {
    this.loadNotes = this.loadNotes.bind(this);
    this.loadMessages = this.loadMessages.bind(this);
    this.subscribeToReadMessage = this.subscribeToReadMessage.bind(this);
    this.subscribeToUpdateUserConversations = this.subscribeToUpdateUserConversations.bind(this);

    this.sharedService.currentConversationFilter.subscribe((value) => (this.conversationFilter = value));
    this.sharedService.currentMessagesData.subscribe((messagesData) => (this.messagesData = messagesData));
    this.sharedService.currentCountUnreadMessages.subscribe((value) => (this.countUnreadMessages = value));
    this.sharedService.currentNotesData.subscribe((notesData) => (this.notesData = notesData));
  }

  public get conversations() {
    if (!this.conversationFilter) {
      return this._conversations;
    }

    return this._conversations.filter((c) => c.status === this.conversationFilter);
  }

  public trackConversation(index, conversation) {
    return conversation ? conversation.id : undefined;
  }

  private prepareCountUnreadMessages(value) {
    if (isNaN(value) || value > 9) {
      return '9+';
    }
    return value;
  }

  public selectConversation(conversation: Conversation) {
    this.onConvoClick.emit(conversation);
    this.translate.use(getI18nLocaleByLocale(conversation.locale));
  }

  private async loadMessages(conversation: Conversation) {
    const conversationId = conversation.id;
    if (this.messagesData[conversationId]) {
      return;
    }

    const observerOrNext = async ({ data }) => {
      this.logService.log('[loadMessages subscribe]', data);

      if (!data) {
        return this.logService.log('[loadMessages - no data]');
      }

      const newMessages =
        conversation.channel !== Channel.EMAIL
          ? data.allMessageConnection.messages
          : data.allMessageConnection.messages.map((msg) => ({
              ...msg,
              content: decodeMessage(msg.content),
            }));

      /** Update Latest message **/
      if (newMessages.length) {
        newMessages[0] = await this.s3Service
          .updateAccessToImageUrls(newMessages[0].content)
          .then((content) => ({ ...newMessages[0], content }));
      }

      const count = newMessages.filter((m) => !m.isRead && m.sender !== this._user.cognitoId).length;

      this.sharedService.setCountUnreadMessages({
        ...this.countUnreadMessages,
        [conversationId]: this.prepareCountUnreadMessages(count),
      });

      this.sharedService.setMessagesData({
        ...this.messagesData,
        [conversationId]: {
          ...this.messagesData[conversationId],
          messages: [...newMessages].reverse(),
          nextToken: data.allMessageConnection.nextToken,
        },
      });

      if (this.current) {
        this.selectConversation(this.current);
      }
      this.logService.log('[loadMessages]: nextToken is now', data.allMessageConnection.nextToken ? 'set' : 'null');
    };

    const { observableSubscription, observedQuery } = await this.appsyncMessageService.loadMessages(
      conversation,
      observerOrNext
    );

    const subscription = await this.subscribeToNewMessage(observedQuery, conversation);

    this.sharedService.setMessagesData({
      ...this.messagesData,
      [conversationId]: {
        ...this.messagesData[conversationId],
        observedQuery,
      },
    });

    this.messagesSubscriptions.push({ observableSubscription, subscription });
  }

  private subscribeToNewMessage = (observable, conversation: Conversation) => {
    const conversationId = conversation.id;
    const updateQuery = (
      prev: MessagesQuery,
      {
        subscriptionData: {
          data: { subscribeToNewMessage: message },
        },
      }
    ) => {
      if (conversation.channel === Channel.EMAIL) {
        message.content = decodeMessage(message.content);
      }

      this.logService.log('[subscribeToNewMessage] - updateQuery:', { prev, message, current: this.current });

      this.sharedService.setCountUnreadMessages({
        ...this.countUnreadMessages,
        [conversationId]: this.prepareCountUnreadMessages(this.countUnreadMessages[conversationId]),
      });

      return unshiftMessage(prev, message);
    };

    return this.appsyncMessageService.subscribeToNewMessage(observable, { conversationId }, updateQuery);
  };

  private async loadNotes(conversation: Conversation) {
    const conversationId = conversation.id;
    if (this.notesData[conversationId]) {
      return;
    }

    const observerOrNext = ({ data }) => {
      this.logService.log('[loadNotes subscribe]', data);
      if (!data) {
        return this.logService.log('[loadNotes - no data]');
      }

      const newNotes = data.getConversationNotes.notes;

      this.sharedService.setNotesData({
        ...this.notesData,
        [conversationId]: {
          ...this.notesData[conversationId],
          notes: newNotes,
          nextToken: data.getConversationNotes.nextToken,
        },
      });

      this.logService.log('[loadNotes nextToken]', data.getConversationNotes.nextToken ? 'set' : 'null');
    };

    const { observableSubscription, observedQuery } = await this.appsyncNoteService.loadNotes(
      conversation,
      observerOrNext
    );

    const newSubscription = await this.subscribeToNewNote(observedQuery, conversationId);
    const deleteSubscription = await this.subscribeToDeleteNote(observedQuery, conversationId);

    this.sharedService.setNotesData({
      ...this.notesData,
      [conversationId]: {
        ...this.notesData[conversationId],
        observedQuery,
      },
    });

    this.notesSubscriptions.push({ observableSubscription, newSubscription, deleteSubscription });
  }

  private subscribeToNewNote = (observable, conversationId: string) => {
    const updateQuery = (
      prev: NotesQuery,
      {
        subscriptionData: {
          data: { subscribeToNewConversationNote: note },
        },
      }
    ) => {
      this.logService.log('[subscribeToNewNote] - updateQuery:', { prev, note });

      return unshiftNote(prev, note);
    };

    return this.appsyncNoteService.subscribeToNewNote(observable, { conversationId }, updateQuery);
  };

  private subscribeToDeleteNote = (observable, conversationId: string) => {
    const updateQuery = (
      prev: NotesQuery,
      {
        subscriptionData: {
          data: { subscribeToDeleteConversationNote: note },
        },
      }
    ) => {
      this.logService.log('[subscribeToDeleteNote] - updateQuery:', { prev, note });

      return deleteNote(prev, note);
    };

    return this.appsyncNoteService.subscribeToDeleteNote(observable, { conversationId }, updateQuery);
  };

  private async getAllConvos() {
    const cognitoId = this._user.cognitoId;

    const observerOrNext = async ({ data }) => {
      this.logService.log('cl:Fetched convos data', data);
      if (!data || !data.getUserByCognitoId) {
        return this.logService.log('getUserConversationsConnection: no data');
      }
      const conversations = data.getUserByCognitoId.conversations.userConversations.map(
        convertUserConversationToConversation
      );
      // .filter(c => c.status !== 'Closed');

      const conversationIds = _.map(this._conversations, 'id');
      const newConversations = conversations.filter(({ id }) => !conversationIds.includes(id));

      newConversations.map(({ cms: list }) => {
        list.map((cms) => {
          const cmsInstance = this.cmsService.createCMS(cms.type as CMS_TYPE, { lazyLoad: true, id: cms.id });
          const key = `${cms.type}:${cms.id}`;

          this.sharedService.addCmsSystem(key, cmsInstance);
        });
      });
      newConversations.map(this.loadMessages);
      newConversations.map(this.loadNotes);

      this.subscriptionsToUpdateUCs = await Promise.all<ZenObservable.Subscription>(
        newConversations.map(this.subscribeToUpdateUserConversations)
      );

      this.subscriptionsToReadMessage = await Promise.all<ZenObservable.Subscription>(
        newConversations.map(this.subscribeToReadMessage)
      );

      this.onConvosLoad.emit({ cognitoId, conversations });

      this._conversations = conversations;
      this.nextToken = data.getUserByCognitoId.conversations.nextToken;
      this.logService.log('Fetched convos', conversations);
    };

    const updateQuery = (
      prev: UserConvosQuery,
      {
        subscriptionData: {
          data: { subscribeToNewUCs: userConvo },
        },
      }
    ) => {
      this.logService.log('[cl:getAllConvos updateQuery]', userConvo);
      // this.logService.log(JSON.stringify(userConvo, null, 2));
      // this.logService.log(JSON.stringify(prev, null, 2));

      return addConversationByCognitoId(prev, userConvo);
    };

    const data = await this.appsyncConversationService.getAllConversationsByUser(
      cognitoId,
      observerOrNext,
      updateQuery
    );

    this.observableSubscription = data.observableSubscription;
    this.subscription = data.subscription;
    this.observedQuery = data.observedQuery;
  }

  public loadMoreConversations(event = null) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    const updateQuery = (prev, options) => {
      const { fetchMoreResult } = options;

      if (!fetchMoreResult) {
        return prev;
      }

      const moreConversations = fetchMoreResult.getUserByCognitoId.conversations.userConversations.map(
        convertUserConversationToConversation
      );
      this.logService.log(
        '!!![loadMoreConversations]',
        moreConversations,
        this._conversations,
        fetchMoreResult.getUserByCognitoId.conversations.nextToken
      );

      const _res = addConversationsByCognitoId(
        prev as UserConvosQuery,
        moreConversations,
        fetchMoreResult.getUserByCognitoId.conversations.nextToken
      );
      this.nextToken = fetchMoreResult.getUserByCognitoId.conversations.nextToken;

      return _res;
    };

    return this.appsyncConversationService.loadMoreConversations(this.observedQuery, this.nextToken, { updateQuery });
  }

  private subscribeToUpdateUserConversations(conversation: Conversation) {
    const realtimeResults = ({ data: { subscribeToUpdateUserConversations: ucs } }) => {
      this.logService.log('[subscribeToUpdateUserConversations realtimeResults] complete', ucs);
      const index = _.findIndex(this._conversations, { id: ucs.conversation.id });
      let convo = this._conversations[index];

      if (convo) {
        convo = { ...convo, ...ucs.conversation };
        if (_.get(this.current, 'id') === convo.id) {
          this.selectConversation(convo);
        }
      }
    };

    return this.appsyncConversationService.subscribeToUpdateUserConversations(conversation, { realtimeResults });
  }

  private subscribeToReadMessage(conversation: Conversation) {
    const realtimeResults = ({ data: { subscribeToReadMessage: message } }) => {
      this.logService.log('[subscribeToReadMessage realtimeResults]', message);
    };

    return this.appsyncMessageService.subscribeToReadMessage(conversation, { realtimeResults });
  }

  getLastMessage(conversation: Conversation) {
    const messagesData = this.messagesData[conversation.id] || {};
    const messages = messagesData.messages || [];
    const lastMessage = messages[messages.length - 1];

    if (!lastMessage) {
      return '';
    }

    let text = lastMessage.content;

    if (lastMessage.sender === this._user.cognitoId) {
      text = `${this._user.username.split(' ')[0]}:&nbsp;${text}`;
    } else {
      text = `${conversation.author.username.split(' ')[0]}:&nbsp;${text}`;
    }

    return text;
  }
}
