import { EventEmitter, Injectable, Output, Directive } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { LoggerService } from './logger.service';
import UserChat from '../types/user-chat';

@Directive()
@Injectable()
export class SharedService {
  @Output() onSelectEmoji: EventEmitter<string> = new EventEmitter();
  @Output() onLeaveChat: EventEmitter<string> = new EventEmitter();

  constructor(private loggerService: LoggerService) {}

  private chat = new BehaviorSubject(null);
  currentChat = this.chat.asObservable();

  private userChats = new BehaviorSubject([]);
  currentUserChats = this.userChats.asObservable();

  private chatsData = new BehaviorSubject(null);
  currentChatsData = this.chatsData.asObservable();

  private user = new BehaviorSubject(null);
  currentUser = this.user.asObservable();

  private preparedUsers = new BehaviorSubject([]);
  selectedUsers = this.preparedUsers.asObservable();

  private messagesData = new BehaviorSubject({});
  currentMessagesData = this.messagesData.asObservable();

  private unreadMessages = new BehaviorSubject({});
  countUnreadMessages = this.unreadMessages.asObservable();

  private emojiBarState = new BehaviorSubject<boolean>(false);
  currentEmojiBarState = this.emojiBarState.asObservable();

  private emojiElement = new BehaviorSubject(null);
  currentEmojiElement = this.emojiElement.asObservable();

  setCountUnreadMessages(counts) {
    this.loggerService.info('[SharedService setCountUnreadMessages]', counts);
    this.unreadMessages.next(counts);
  }

  setCurrentEmojiBarState(state: boolean) {
    this.loggerService.info('[SharedService setCurrentEmojiBarState]', state);
    this.emojiBarState.next(state);
  }

  setCurrentEmojiElement(element) {
    this.loggerService.info('[SharedService setCurrentEmojiElement]', element);
    this.emojiElement.next(element);
  }

  setCurrentChatsData(data) {
    this.loggerService.info('[SharedService setCurrentChatsData]', data);
    this.chatsData.next(data);
  }

  setSelectedUsers(users) {
    this.loggerService.info('[SharedService setSelectedUsers]', users);
    this.preparedUsers.next(users);
  }

  setCurrentChat(chat: UserChat) {
    this.loggerService.info('[SharedService setCurrentChat]', chat);
    this.chat.next(chat);
  }

  setUserChats(chats) {
    this.loggerService.info('[SharedService setUserChats]');
    this.userChats.next(chats);
  }

  setCurrentUser(user) {
    this.loggerService.info('[SharedService] setCurrentUser');
    this.user.next(user);
  }

  setMessagesData(messagesData) {
    this.loggerService.info('[SharedService setMessagesData]', messagesData);
    this.messagesData.next(messagesData);
  }

  getMessagesData(chatId) {
    return _.merge({ messages: [], nextToken: null }, this.messagesData.value[chatId] || {});
  }

  get userProfile() {
    return this.user.value;
  }

  get chats() {
    return this.userChats.value;
  }

  get openedChat(): UserChat {
    return this.chat.value;
  }

  get usersToChat() {
    return this.preparedUsers.value;
  }
}
