<div #element class="image-picker__wrapper w-100 h-100">
  <div
    *ngIf="isUploading"
    class="image-picker__upload-progress"
    [class.image-picker__upload-progress_loaded]="image"
    [class.image-picker__upload-progress_rounded]="isRounded"
  >
    <mat-progress-spinner [diameter]="element.clientWidth" color="primary" mode="determinate" [value]="progressPercent">
    </mat-progress-spinner>
  </div>

  <div class="image-picker" *ngIf="image">
    <img
      alt="image"
      [src]="image"
      class="image-picker__icon"
      [class.image-picker__icon_rounded]="isRounded"
      (click)="fileInput.click()"
    />
    <div class="image-picker__icon-overlay">
      <button mat-mini-fab color="primary" (click)="fileInput.click()">
        <mat-icon>add_photo_alternate</mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="!image && !isUploading">
    <button type="button" mat-raised-button (click)="fileInput.click()">
      {{ 'IMAGE_PICKER.SET_IMAGE_BUTTON_TEXT' | translate }}
    </button>
  </div>
  <input hidden type="file" accept="image/*" #fileInput (change)="onSelect($event)" />
</div>
