<div class="card h-100">
  <div class="card-header p-2">
    <mat-form-field class="response-form-control">
      <mat-select
        #singleSelect
        class="p-0"
        (selectionChange)="onCannedResponseSelect()"
        [formControl]="cannedResponsesCtrl"
        [placeholder]="'CANNED_RESPONSE_BLOCK.SELECT_PLACEHOLDER' | translate"
      >
        <mat-option>
          <ngx-mat-select-search
            [formControl]="cannedResponsesFilterCtrl"
            [placeholderLabel]="'CANNED_RESPONSE_BLOCK.INPUT_PLACEHOLDER' | translate"
          >
          </ngx-mat-select-search>
        </mat-option>

        <mat-option *ngFor="let cannedResponse of filteredCannedResponses | async" [value]="cannedResponse">
          {{ byLocale(cannedResponse, 'title') }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button
      mat-icon-button
      color="primary"
      [title]="'CANNED_RESPONSE_BLOCK.PAST_RESPONSE_TITLE' | translate"
      (click)="onCannedResponseDoubleClick()"
    >
      <mat-icon>arrow_circle_up</mat-icon>
    </button>
  </div>

  <div class="response-container card-body p-2" (dblclick)="onCannedResponseDoubleClick()">
    <ngx-ui-loader [fgsSize]="25" [loaderId]="loaderId"></ngx-ui-loader>
    <div
      class="response-content h-100"
      (click)="handleLink($event)"
      [innerHTML]="byLocale(selectedCannedResponse, 'response') | trustHtml"
    ></div>
  </div>
</div>
