<div class="d-flex align-items-center">
  <div class="me-1 title">{{ title }}</div>
  <div *ngIf="showTotalValue" class="total">{{ totalValue || 0 }}</div>
</div>
<div class="d-flex align-items-center">
  <div *ngIf="isChartTypeFilter" class="widget-select chart-type-select">
    <mat-form-field>
      <mat-select
        [(ngModel)]="selectedChartTypeFilterValue"
        name="chartTypeFilter"
        (selectionChange)="onChartTypeSelectionChange($event)"
      >
        <mat-option *ngFor="let option of chartTypeFilterOptions" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div *ngIf="isDateTypeFilter" class="widget-select">
    <mat-form-field>
      <mat-select
        [(ngModel)]="selectedDateFilterValue"
        name="dateFilter"
        (selectionChange)="onDateSelectionChange($event)"
      >
        <mat-option *ngFor="let option of dateFilterOptions" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
