import { Injectable } from '@angular/core';
import { FetchPolicy } from 'apollo-client';
import { getConversationNotesQuery as NotesQuery } from '@cation/core/graphql/operation-result-types';
import { AppsyncOperationService } from '@cation/core/services/appsync/appsync-operation.service';
import Conversation from '@cation/core/types/conversation';
import { constants } from '@cation/core/util/chat-helper';
import * as queries from '@cation/core/graphql/queries';
import * as subscriptions from '@cation/core/graphql/subscriptions';

@Injectable({
  providedIn: 'root'
})
export class AppsyncNoteService extends AppsyncOperationService {
  loadNotes(conversation: Conversation, observerOrNext) {
    const watchQueryOptions = {
      query: queries.getConversationNotes,
      fetchPolicy: 'cache-and-network' as FetchPolicy,
      variables: {
        conversationId: conversation.id,
        first: constants.notesFirst
      }
    };

    const watchQuerySubscriptionOptions = { observerOrNext };

    return this.load<NotesQuery>({ watchQueryOptions, watchQuerySubscriptionOptions });
  }

  subscribeToNewNote(observable, variables: { conversationId: string }, updateQuery) {
    return this.subscribeToMore<NotesQuery>(observable, {
      document: subscriptions.subscribeToNewConversationNote,
      variables,
      updateQuery
    });
  }

  subscribeToDeleteNote(observable, variables: { conversationId: string }, updateQuery) {
    return this.subscribeToMore<NotesQuery>(observable, {
      document: subscriptions.subscribeToDeleteConversationNote,
      variables,
      updateQuery
    });
  }
}
