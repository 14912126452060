<ngx-loading [show]="apiHook.isLoading()"></ngx-loading>

<div class="header">
  <h1 mat-dialog-title>
    {{ 'ADMIN_MANAGE_CANNED_RESPONSE.TITLE' | translate }}
  </h1>

  <div class="me-4">
    <button mat-raised-button class="btn-save" (click)="onSaveLiterals()">
      {{ 'BUTTON.SAVE' | translate }}
    </button>
    <div class="btn-separator"></div>
    <button mat-raised-button class="btn-cancel" (click)="onClose($event)">
      {{ 'BUTTON.CANCEL' | translate }}
    </button>
  </div>
</div>

<div mat-dialog-content class="content-box">
  <div class="d-flex w-100 mt-2">
    <div class="d-flex mw-40 me-5">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ 'ADMIN_MANAGE_CANNED_RESPONSE.SELECT_LITERAL_PLACEHOLDER' | translate }}</mat-label>
        <mat-select [(ngModel)]="selectedLiteral" (selectionChange)="onSelectionChange()">
          <mat-option *ngFor="let literal of literals" [value]="literal">{{ literal.title }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="d-flex mt-2">
        <button
          mat-icon-button
          color="primary"
          (click)="addLiteralKey()"
          [title]="'ADMIN_MANAGE_CANNED_RESPONSE.ADD_BUTTON_TEXT' | translate"
        >
          <mat-icon>add_circle</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          (click)="editTitle()"
          [title]="'ADMIN_MANAGE_CANNED_RESPONSE.EDIT_BUTTON_TEXT' | translate"
        >
          <mat-icon>edit</mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          (click)="deleteLiteralKey()"
          [title]="'ADMIN_MANAGE_CANNED_RESPONSE.DELETE_BUTTON_TEXT' | translate"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'ADMIN_MANAGE_CANNED_RESPONSE.SELECT_LOCALE_PLACEHOLDER' | translate }}</mat-label>
      <mat-select [(ngModel)]="selectedLocale" (selectionChange)="onSelectionChange()">
        <mat-option *ngFor="let locale of Locale" [value]="locale">{{ locale.name }} ({{ locale.code }})</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <hr class="mt-0" />

  <div *ngIf="selectedLiteral" class="d-flex flex-column">
    <mat-form-field appearance="outline" class="mb-3">
      <mat-label>{{ 'ADMIN_MANAGE_CANNED_RESPONSE.SELECTED_LITERAL.NOTE' | translate }}</mat-label>
      <textarea
        matInput
        matTextareaAutosize
        matAutosizeMaxRows="5"
        [(ngModel)]="selectedLiteral.description"
        (ngModelChange)="onLiteralUpdate()"
      >
      </textarea>
      <mat-hint align="start"
        ><strong> {{ 'ADMIN_MANAGE_CANNED_RESPONSE.SELECTED_LITERAL.NOTE_TEXT' | translate }}</strong>
      </mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'ADMIN_MANAGE_CANNED_RESPONSE.SELECTED_LITERAL.LABEL' | translate }}</mat-label>
      <input matInput [(ngModel)]="selectedLiteral[localeSuffix('title', selectedLocale)]" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'ADMIN_MANAGE_CANNED_RESPONSE.SELECTED_LITERAL.SHORT_CODE' | translate }}</mat-label>
      <textarea
        matInput
        matTextareaAutosize
        matAutosizeMaxRows="5"
        [(ngModel)]="selectedLiteral.hashtag"
        (ngModelChange)="onLiteralUpdate()"
      >
      </textarea>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'ADMIN_MANAGE_CANNED_RESPONSE.SELECTED_LITERAL.SHORT_RESPONSE' | translate }}</mat-label>
      <textarea
        matInput
        matTextareaAutosize
        matAutosizeMaxRows="5"
        [(ngModel)]="selectedLiteral[localeSuffix('shortResponse', selectedLocale)]"
        (ngModelChange)="onLiteralUpdate()"
      >
      </textarea>
    </mat-form-field>
  </div>

  <div *ngIf="selectedLiteral" class="mb-1">
    <p class="text-muted text-center">
      {{ 'ADMIN_MANAGE_CANNED_RESPONSE.SELECTED_LITERAL.RESPONSE' | translate }}
    </p>
    <quill-editor
      [modules]="quillModules"
      [styles]="{ 'min-height': '250px' }"
      [(ngModel)]="selectedLiteral[localeSuffix('response', selectedLocale)]"
      (ngModelChange)="onLiteralUpdate()"
      (onEditorCreated)="onQuillEditorCreated($event)"
      (paste)="onImagePaste($event)"
    >
    </quill-editor>
    <mat-icon id="input_attach_file_icon">attachment</mat-icon>
  </div>
</div>
