<div class="convo-list">
  <div class="list-group mb-1" [@listFadeInOut]="conversations.length">
    <a
      href
      *ngFor="let convo of conversations; trackBy: trackConversation"
      class="d-flex justify-content-between align-items-center list-group-item list-group-item-action p-1 m-0 bg-light rounded"
      [class.unread]="countUnreadMessages[convo.id]"
      [class.closed]="convo?.status === 'Closed'"
      [class.active]="convo?.id === current?.id"
      (click)="selectConversation(convo); (false)"
    >
      <div class="conversation-left-content">
        <div class="social-avatar">
          <img
            *ngIf="convo.author.userData?.profilePicUrl"
            [src]="convo.author.userData?.profilePicUrl"
            class="avatar"
            alt="avatar"
          />
        </div>
        <div class="conversation-content-container ps-1">
          <div class="d-flex align-items-center">
            <img
              *ngIf="convo?.channel === 'EHUB'"
              src="../../../../assets/img/ehub.png"
              alt="ehub"
              class="channel-icon me-1"
            />
            <i
              *ngIf="convo?.channel !== 'EHUB'"
              [class]="classIcon[convo?.channel?.toLocaleLowerCase()] + ' icon me-1'"
            ></i>
            <span
              class="conversation-content conversation-name"
              [class.conversation-name-with-avatar]="convo.author.userData?.profilePicUrl"
            >
              {{ convo.name }}
            </span>
          </div>
          <span
            class="conversation-content conversation-message"
            [class.conversation-message-with-avatar]="convo.author.userData?.profilePicUrl"
            [innerHTML]="getLastMessage(convo)"
          >
          </span>
        </div>
      </div>
      <div *ngIf="countUnreadMessages[convo.id]" class="count-unread-messages-wrapper">
        <div class="count-unread-messages">
          {{ countUnreadMessages[convo.id] }}
        </div>
      </div>
    </a>
  </div>
  <div class="d-flex justify-content-center align-items-center">
    <button *ngIf="nextToken" type="button" class="btn btn-secondary" (click)="loadMoreConversations()">
      {{ 'BUTTON.LOAD_MORE' | translate }}
    </button>
  </div>
</div>
