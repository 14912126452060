export enum ATTACHMENT_TYPE {
  IMAGE = 'image',
  PDF = 'pdf',
}

export const mapTypeToAttachmentTypes = {
  'image/png': ATTACHMENT_TYPE.IMAGE,
  'image/jpg': ATTACHMENT_TYPE.IMAGE,
  'image/jpeg': ATTACHMENT_TYPE.IMAGE,
  'image/gif': ATTACHMENT_TYPE.IMAGE,
  'image/svg+xml': ATTACHMENT_TYPE.IMAGE,
  'application/pdf': ATTACHMENT_TYPE.PDF,
};
