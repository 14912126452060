import gql from 'graphql-tag';

export default gql`
  mutation disconnectConversation($id: ID!, $userId: ID!) {
    disconnectConversation(id: $id, userId: $userId) {
      __typename
      id
      name
      subject
      createdBy
      author {
        cognitoId
        username
        userData
      }
      status
      keywords
      lastQuestion
      intentsTriggered
      botSessionId
    }
  }
`;
