import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss']
})
export class PromptDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}

export interface PromptDialogData {
  title: string;
  message: string;
  placeholder: string;
  value: string;
  hint: string;
  required: boolean;
  pattern: string | RegExp;
  error: string;
}
