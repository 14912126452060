import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { LogService } from '@cation/core/services/log/log.service';

interface IOptions {
  element: any;
  keys: string;
}

@Injectable({ providedIn: 'root' })
export class HotKeys {
  defaults: Partial<IOptions> = {
    element: this.document
  };

  constructor(
    private eventManager: EventManager,
    private logService: LogService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  on(keys: string) {
    return this.listen({ keys });
  }

  listen(options: Partial<IOptions>) {
    const merged = { ...this.defaults, ...options };
    const event = `keydown.${merged.keys}`;

    return new Observable(observer => {
      this.logService.debug('[HotKeys observable]', event);
      const handler = e => {
        this.logService.debug('[HotKeys event]', e.key);
        e.preventDefault();
        observer.next(e);
      };

      const dispose = this.eventManager.addEventListener(merged.element, event, handler);

      return () => {
        dispose();
      };
    });
  }
}
