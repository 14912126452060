import gql from 'graphql-tag';

export default gql`
  query getConversationNotes($conversationId: ID!, $after: String, $first: Int) {
    getConversationNotes(conversationId: $conversationId, after: $after, first: $first) {
      __typename
      nextToken
      notes {
        __typename
        id
        conversationId
        content
        createdBy
        author {
          cognitoId
          username
          userData
        }
      }
    }
  }
`;
