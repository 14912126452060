import { Injectable, Inject } from '@angular/core';
import { AWSAppSyncClient, AUTH_TYPE } from 'aws-appsync';
import { Auth } from '@aws-amplify/auth';
import { AppSyncClientOptionsService } from './app-sync-client-options.service';

@Injectable()
export class AppSyncService {
  _hc;

  constructor(@Inject(AppSyncClientOptionsService) private options) {
    const client = new AWSAppSyncClient({
      url: options && options.url,
      region: options && options.region,
      disableOffline: true,
      auth: {
        type: AUTH_TYPE.AWS_IAM,
        credentials: () => Auth.currentCredentials(),
        // jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
      }
    });
    this._hc = client;
  }

  hc() {
    return this._hc.hydrated();
  }
}
