<div class="conversation-queue-page">
  <ngx-loading [show]="isLoading"></ngx-loading>

  <mat-card class="conversation-queue-card">
    <mat-card-header>
      <mat-card-title class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center ">
          <mat-icon class="me-2">queue</mat-icon>
          {{ 'ADMIN_CONVERSATION_QUEUE.TITLE' | translate }}
        </div>
        <strong>{{ count }}</strong>
      </mat-card-title>
    </mat-card-header>

    <mat-card-content class="w-100">
      <mat-tab-group [selectedIndex]="selectedTab.value" (selectedIndexChange)="selectedTab.setValue($event)">
        <mat-tab [label]="'ADMIN_CONVERSATION_QUEUE.METRICS_TAB_TITLE' | translate">
          <div class="statistics-metrics px-1">
            <mat-card class="statistics-metrics__pie col-sm-12 mb-1">
              <mat-card-header>
                <mat-card-title class="d-flex align-items-center">
                  <mat-icon class="me-2">language</mat-icon>
                  {{ 'ADMIN_CONVERSATION_QUEUE.LOCALES_TITLE' | translate }}
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="d-flex align-items-center">
                <div style="display: grid;" class="w-100 h-100">
                  <ngx-charts-advanced-pie-chart [scheme]="colorScheme" [results]="localeMetrics">
                  </ngx-charts-advanced-pie-chart>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="statistics-metrics__pie col-sm-12 mb-1">
              <mat-card-header>
                <mat-card-title class="d-flex align-items-center">
                  <mat-icon class="me-2">public</mat-icon>
                  {{ 'ADMIN_CONVERSATION_QUEUE.CHANNELS_TITLE' | translate }}
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="d-flex align-items-center">
                <div style="display: grid;" class="w-100 h-100">
                  <ngx-charts-advanced-pie-chart [scheme]="colorScheme" [results]="channelMetrics">
                  </ngx-charts-advanced-pie-chart>
                </div>
              </mat-card-content>
            </mat-card>

            <mat-card class="statistics-metrics__pie col-sm-12 mb-1">
              <mat-card-header>
                <mat-card-title class="d-flex align-items-center">
                  <mat-icon class="me-2">subtitles</mat-icon>
                  {{ 'ADMIN_CONVERSATION_QUEUE.KEYWORDS_TITLE' | translate }}
                </mat-card-title>
              </mat-card-header>
              <mat-card-content class="d-flex align-items-center">
                <div style="display: grid;" class="w-100 h-100">
                  <ngx-charts-advanced-pie-chart [scheme]="colorScheme" [results]="keywordsMetrics">
                  </ngx-charts-advanced-pie-chart>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>

        <mat-tab [label]="'ADMIN_CONVERSATION_QUEUE.CONVERSATIONS_TAB_TITLE' | translate">
          <div [@listFadeInOut]="conversations.length">
            <div
              class="border rounded p-2 align-items-center"
              *ngFor="let convo of conversations; trackBy: trackConversation"
            >
              <div class="d-flex align-items-center">
                <div class="social-avatar">
                  <img
                    *ngIf="convo.author.userData?.profilePicUrl"
                    [src]="convo.author.userData?.profilePicUrl"
                    class="avatar"
                    alt="avatar"
                  />
                </div>
                <div class="conversation-content-container ps-1">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center item-content">
                      <img
                        *ngIf="convo?.channel === 'EHUB'"
                        src="../../../../assets/img/ehub.png"
                        alt="ehub"
                        class="channel-icon me-1"
                      />
                      <i
                        *ngIf="convo?.channel !== 'EHUB'"
                        [class]="classIcon[convo?.channel?.toLocaleLowerCase()] + ' icon me-1'"
                        [matTooltip]="convo?.channel"
                      >
                      </i>
                      <span class="px-2">
                        <i
                          class="material-icons ctn-icon-middle"
                          [matTooltip]="'SENTIMENT.' + convo.sentiment | translate"
                        >
                          {{ mapSentimentToIcon[convo.sentiment] }}
                        </i>
                      </span>
                      <span
                        class="flag-icon flag-icon-{{ localeUtil.getRegion(convo.locale).toLowerCase() }}"
                        [matTooltip]="'LOCALE.' + localeUtil.getName(convo.locale) | translate"
                      >
                      </span>
                      <span class="ps-2">{{ convo.name }}</span>
                      <span class="ps-2">[ {{ convo.keywords }} ]</span>
                    </div>
                    <span>
                      <strong>{{ convo.waitingTime }}</strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="nextToken" class="d-flex flex-row justify-content-center align-items-center mb-2">
            <button mat-raised-button color="primary" (click)="loadMoreConversations()">
              {{ 'BUTTON.LOAD_MORE' | translate }}
            </button>
          </div>
        </mat-tab>

        <mat-tab [label]="'ADMIN_CONVERSATION_QUEUE.QUEUE_HISTORY_TAB_TITLE' | translate">
          <app-queue-history></app-queue-history>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>
