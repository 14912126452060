<internal-chat-main-frame
  *ngIf="isChatOpen"
  (clickOutside)="closeInternalChat($event)"
  @openClose
></internal-chat-main-frame>
<internal-chat-trigger-button (onToggleChat)="toggleInternalChat()" [inline]="inline" [isChatOpened]="isChatOpen">
</internal-chat-trigger-button>
<div #emojiElement class="emoji-wrapper" [class.emoji-wrapper_hidden]="!emojiBarState">
  <emoji-mart
    [emojiTooltip]="false"
    title="Pick your emoji..."
    [perLine]="7"
    [autoFocus]="true"
    [style]="{ width: '300px' }"
    (click)="onEmojiCompClick($event)"
    (emojiClick)="onEmojiClick($event)"
  ></emoji-mart>
</div>
