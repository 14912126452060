<div class="team-input-autocomplete">
  <mat-form-field class="ctn-full-width">
    <mat-select
      #singleSelect
      class="p-0"
      [required]="required"
      [formControl]="teamCtrl"
      [placeholder]="placeholder | translate"
    >
      <mat-option>
        <ngx-mat-select-search [formControl]="teamsFilterCtrl" [placeholderLabel]="placeholderLabel | translate">
          <mat-icon ngxMatSelectSearchClear *ngIf="!isFinding">close</mat-icon>
          <mat-icon ngxMatSelectSearchClear *ngIf="isFinding">
            <mat-progress-spinner matSuffix mode="indeterminate" diameter="23"></mat-progress-spinner>
          </mat-icon>
        </ngx-mat-select-search>
      </mat-option>

      <mat-option [value]="allOptionValue" *ngIf="allOptionValue && allOptionTitle">
        <div class="d-flex justify-content-center align-items-center ps-1">
          {{ allOptionTitle | translate }}
        </div>
      </mat-option>
      <mat-option *ngFor="let team of filteredTeams | async; trackBy: trackItem" [value]="team">
        <div class="d-flex justify-content-center align-items-center ps-1">
          <div class="team-input-autocomplete__icon me-2" *ngIf="team?.icon">
            <app-s3-image [image]="team?.icon"></app-s3-image>
          </div>
          {{ team?.name }}
          ({{ team.teamLead.username }})
        </div>
      </mat-option>
    </mat-select>
    <button
      mat-button
      *ngIf="teamCtrl.value"
      matSuffix
      mat-icon-button
      [attr.aria-label]="'BUTTON.CLEAR' | translate"
      (click)="resetTeam($event)"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-error *ngIf="errorLabel">{{ errorLabel | translate }}</mat-error>
  </mat-form-field>

  <mat-progress-bar class="m-2" *ngIf="isLoading" [mode]="'indeterminate'" [@fadeInOut]></mat-progress-bar>
</div>
