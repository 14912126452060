<p *ngIf="!items.length" class="empty-result">
  {{ 'GENERAL.NO_RESULTS_FOUND' | translate }}
</p>
<div class="d-flex justify-content-center">
  <div
    class="ctn-users"
    infinite-scroll
    [scrollWindow]="false"
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="150"
    (scrolled)="onScrollDown()"
  >
    <app-user-list-item
      *ngFor="let item of items"
      [userData]="item"
      (onUserClick)="onItemEvent($event)"
      (onDelete)="onDeleteEvent($event)"
    >
    </app-user-list-item>
  </div>
</div>
