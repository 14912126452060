import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

const colors = [
  'rgba(156, 39, 176, 0.15)',
  'rgba(181, 142, 255, 0.2)',
  'rgba(63,81,181, 0.1)',
  'rgba(205,220,57, 0.15)',
  'rgba(255,193,7, 0.3)',
  'rgba(121,85,72, 0.30)',
  'rgba(101,179,152, 0.2)',
  'rgba(255,64,129, 0.1)'
];

const textToColor = (text: string): string => {
  const charCodes = text
    .split('-')
    .slice(0, 5)
    .map(char => char[0].charCodeAt(0))
    .join('');
  const number = parseInt(charCodes, 10);

  return colors[number % colors.length];
};

@Pipe({
  name: 'pickColor'
})
export class PickColorPipe implements PipeTransform {
  @memo()
  transform(value: any, args?: any): any {
    if (!value) {
      return;
    }
    return textToColor(value);
  }
}
