<div class="incline-editor">
  <label *ngIf="label" class="incline-editor__label">{{ label }}</label>
  <div *ngIf="editing" class="incline-editor__content">
    <input
      #inlineEditControl
      class="incline-editor__input"
      [maxLength]="maxLength"
      [(ngModel)]="value"
      (blur)="onBlur($event)"
      [name]="name"
      [type]="type"
      [required]="required"
      [placeholder]="label"
    />
  </div>
  <div *ngIf="!editing" class="incline-editor__content" [title]="value">
    <div
      class="incline-editor__edit-control"
      title="Click to edit"
      tabindex="0"
      (click)="onEdit($event, value)"
      (focus)="onEdit($event, value)"
    >
      {{ value || 'Click to edit' }}&nbsp;
    </div>
  </div>
</div>
