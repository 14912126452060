import { Component, Input } from '@angular/core';
import { IUserConversationStatistics } from '@cation/core/types/api';
import { SingleChartModel } from '../models/single-chart.model';
import { StatsDataKeyEnum } from '../enums/stats-data-key.enum';
import { chartColorSchemas } from '@cation/core/constants/chart-color-schema';
import { StatsTypeEnum } from '../enums/stats-type.enum';

@Component({
  selector: 'app-conversations-per-hour-widget',
  templateUrl: './conversations-per-hour-widget.component.html',
  styleUrls: ['./conversations-per-hour-widget.component.scss'],
})
export class ConversationsPerHourWidgetComponent {
  private statistics: IUserConversationStatistics;
  @Input() set setStatistics(data: IUserConversationStatistics) {
    this.statistics = data;
    this.drawChart();
  }

  statsTypeEnum = StatsTypeEnum;

  chartSingleResult: SingleChartModel[];
  color = { domain: chartColorSchemas['single-purple-color'] };

  constructor() {}

  private singleSerialize(statistics: IUserConversationStatistics): SingleChartModel[] {
    const timeRanges = Array.from(Array(24).keys()).map((i) => `${i}`.padStart(2, '0'));
    const dataKey = StatsDataKeyEnum.conversationsHour;
    const result = [];

    if (!dataKey) {
      return undefined;
    }

    for (const time of timeRanges) {
      if (statistics[`${dataKey}${time}`]) {
        result.push({
          name: time,
          value: statistics[`${dataKey}${time}`] || 0,
        });
      }
    }

    return result;
  }

  private drawChart() {
    if (!this.statistics) {
      return;
    }

    this.chartSingleResult = this.singleSerialize(this.statistics);
  }
}
