import gql from 'graphql-tag';

export default gql`
  mutation deleteConversationNote($id: ID!, $conversationId: ID!) {
    deleteConversationNote(id: $id, conversationId: $conversationId) {
      __typename
      id
      conversationId
      createdBy
      content
      author {
        cognitoId
        username
        userData
      }
    }
  }
`;
