<div *ngIf="update">
  <ngb-alert type="primary" [dismissible]="false" class="alert">
    <div class="d-flex align-items-center justify-content-between">
      <span>New version available</span>
      <button class="btn btn-primary" (click)="activateUpdate()">Update</button>
    </div>
  </ngb-alert>
</div>

<!-- Conversations List -->
<div *ngIf="conversations && conversations.length; else dashboard" class="container-fluid">
  <div class="justify-content-center row">
    <div class="col-md-3 conversations-card-container ctn-full-height">
      <div class="conversations-card">
        <app-chat-convo-list
          [user]="me"
          [current]="conversation"
          [conversations]="conversations"
          href="#chat"
        ></app-chat-convo-list>
      </div>
    </div>

    <div class="col-md-9 ctn-full-height p-0" id="chat">
      <div *ngIf="!!conversation; else dashboard" [@fadeInOut]>
        <app-chat-message-view
          [senderId]="me?.cognitoId"
          (onUpdateConversation)="setNewConvo($event)"
          (onCloseConversation)="onCloseConversation()"
          (onAddCmsSystem)="addCmsSystem($event)"
          (onDetachCmsSystem)="detachCms($event)"
          [conversation]="conversation"
          [messagesData]="messagesData[conversation.id]"
          [notesData]="notesData[conversation.id]"
        >
        </app-chat-message-view>
      </div>
    </div>
  </div>
</div>

<ng-template #dashboard>
  <div class="ctn-full-height">
    <dashboard [me]="me"></dashboard>
  </div>
</ng-template>
