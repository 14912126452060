import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@cation/core/modules/shared.module';
import { MaterialModule } from '@cation/core/modules/material.module';
import { ComponentsModule } from '@cation/core/modules/components.module';
import { TeamLeadRoutingModule } from './team-lead-routing.module';
import { ReviewUserComponent } from './components/review-user/review-user.component';
import { ConvoListComponent } from './components/convo-list/convo-list.component';
import { ChatComponent } from './components/chat/chat.component';
import { MessageViewComponent } from './components/message-view/message-view.component';
import { ReviewFeedbackComponent } from './components/review-feedback/review-feedback.component';
import { TeamsStatisticsComponent } from '@cation/team-lead/components/teams-statistics/teams-statistics.component';

@NgModule({
  imports: [CommonModule, SharedModule, MaterialModule, ComponentsModule, TeamLeadRoutingModule, NgbModule],
  declarations: [
    ReviewUserComponent,
    ReviewFeedbackComponent,
    ConvoListComponent,
    MessageViewComponent,
    ChatComponent,
    TeamsStatisticsComponent
  ]
  // entryComponents: []
})
export class TeamLeadModule {
  static forRoot(): ModuleWithProviders<TeamLeadModule> {
    return {
      ngModule: TeamLeadModule,
      providers: []
    };
  }
}
