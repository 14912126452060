import { ApiService } from '@cation/core/services/api/api.service';
import { CMS_TYPE } from '@cation/core/enums/cms-type.enum';
import { EventEmitter } from '@angular/core';
import { IAnyObject, ICMS, IComment } from '../../interfaces/cms';

export default class ServiceNow implements ICMS {
  type = CMS_TYPE.SERVICE_NOW;

  id: string | number;
  name: string;
  title: string;
  description: string;
  comments: IComment[] = [];
  createdAt: number;

  isInitialized = false;
  isDataLoading = false;
  isCommentsLoading = false;
  onAddNewComment = new EventEmitter<IAnyObject>();

  constructor(data: IAnyObject, private api: ApiService) {
    if (data.lazyLoad) {
      this.id = data.id;
      this.name = 'SEERVICENOW: #...';
      return;
    }

    this.updateData(data);
  }

  async init() {
    if (this.isInitialized) {
      return;
    }

    const data = await this.api.getCmsById(this.id, CMS_TYPE.SERVICE_NOW);

    this.updateData(data);
  }

  private updateData(data: IAnyObject) {
    this.id = data.sys_id;
    this.name = data.number;
    this.title = data.short_description;
    this.description = data.description; // TODO: check

    this.isInitialized = true;
  }

  addComment() {

  }

  changeStatus() {

  }

  toJSON() {
    return '';
  }
}
