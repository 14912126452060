import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { LogService } from '@cation/core/services/log/log.service';
import User from '@cation/core/types/user';
import { faUser } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-admin-review-user',
  templateUrl: './review-user.component.html',
  styleUrls: ['./review-user.component.scss']
})
export class ReviewUserComponent implements OnInit {
  public faUser = faUser
  public selectedTab = new UntypedFormControl(0);
  public user: User;

  public achievements = [];
  public isLoading = true;

  constructor(public auth: AuthHelper, private logService: LogService) {}

  async ngOnInit() {}

  async onSelectUser(selectedUser) {
    this.logService.log('onSelectUser', selectedUser);

    this.user = selectedUser;
  }
}
