import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrustHtml } from '@cation/core/pipes/trust-html.pipe';
import { TrustResourceUrlPipe } from '@cation/core/pipes/trust-resource-url.pipe';
import { AOrAnPipe } from '@cation/core/pipes/a-or-an.pipe';
import { MomentAgoPipe } from '@cation/core/pipes/moment-ago.pipe';
import { PickColorPipe } from '@cation/core/pipes/pick-colour.pipe';
import { ReplaceUnderscoresPipe } from '@cation/core/pipes/replace-underscores.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [TrustHtml, TrustResourceUrlPipe, AOrAnPipe, MomentAgoPipe, PickColorPipe, ReplaceUnderscoresPipe],
  exports: [TrustHtml, TrustResourceUrlPipe, AOrAnPipe, MomentAgoPipe, PickColorPipe, ReplaceUnderscoresPipe]
})
export class CtnPipes {}
