<h1 mat-dialog-title>{{ 'ADMIN_ADD_ACHIEVEMENT.' + (achievement.id ? 'EDIT_TITLE' : 'ADD_TITLE') | translate }}</h1>

<div mat-dialog-content>
  <form class="ctn-form" #dialogForm="ngForm">
    <div class="ctn-full-width pt-2 d-flex justify-content-center">
      <div class="achievement__icon">
        <app-image-picker
          keyPrefix="achievements"
          [image]="achievement.icon"
          (onImageSelect)="onAchievementIconSelect($event)"
        ></app-image-picker>
      </div>
    </div>

    <mat-form-field class="ctn-full-width pt-3">
      <input
        type="text"
        matInput
        cdkFocusInitial
        [placeholder]="'ADMIN_ADD_ACHIEVEMENT.TITLE_INPUT_PLACEHOLDER' | translate"
        [(ngModel)]="achievement.title"
        name="title"
        required
      />
    </mat-form-field>

    <mat-form-field class="ctn-full-width pt-3">
      <input
        type="text"
        matInput
        [placeholder]="'ADMIN_ADD_ACHIEVEMENT.DESCRIPTION_INPUT_PLACEHOLDER' | translate"
        [(ngModel)]="achievement.description"
        name="description"
        required
      />
    </mat-form-field>

    <div *ngIf="errorMessage" class="alert alert-danger mt-2">
      {{ errorMessage }}
    </div>
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'BUTTON.CANCEL' | translate }}</button>
  <button
    mat-button
    (click)="saveAchievement()"
    [disabled]="!dialogForm.valid || dialogForm.untouched || !achievement.icon"
  >
    {{ 'BUTTON.SAVE' | translate }}
  </button>
</div>
