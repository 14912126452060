import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { UntypedFormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { LogService } from '@cation/core/services/log/log.service';
import { ApiService } from '@cation/core/services/api/api.service';

@Component({
  selector: 'app-bottom-sheet-find-similar-case-filter',
  templateUrl: './bottom-sheet-find-similar-case-filter.component.html'
})
export class BottomSheetFindSimilarCaseFilterComponent implements OnInit {
  date;

  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  keywordCtrl = new UntypedFormControl();
  filteredKeywords: Observable<string[]>;
  keywords: string[] = [];
  createdAt;
  allKeywords: string[] = [];

  @ViewChild('keywordInput', { static: true }) keywordInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { filters?: { keywords?: string[]; createdAt?: { from: string; to: string } } },
    private bottomSheetRef: MatBottomSheetRef<BottomSheetFindSimilarCaseFilterComponent>,
    private logService: LogService,
    private apiService: ApiService
  ) {
    this.init();
    this.filteredKeywords = this.keywordCtrl.valueChanges.pipe(
      startWith(),
      map(() => this._filter())
    );
  }

  async ngOnInit() {
    await this.getAllKeywords();
  }

  init() {
    if (!this.data.filters) {
      return;
    }

    const { keywords, createdAt } = this.data.filters;

    this.keywords = Array.from(keywords || []);
    this.createdAt = Object.assign(createdAt || {});
    this.keywordCtrl.setValue(this.keywords);
    if (createdAt && createdAt.from && createdAt.to) {
      this.date = { begin: createdAt.from, end: createdAt.to };
    }
  }

  async getAllKeywords() {
    const data: any = await this.apiService.getKeywords().toPromise();
    this.logService.log('[getAllKeywords data]', data);
    this.allKeywords = ['Any', ...data.map(e => e.PrimaryTopic)];
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        this.keywords.push(value.trim());
      }

      if (input) {
        input.value = '';
      }

      this.keywordCtrl.setValue(null);
    }
  }

  remove(keyword: string): void {
    const index = this.keywords.indexOf(keyword);

    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.keywords.push(event.option.viewValue);
    this.keywordInput.nativeElement.value = '';
    this.keywordCtrl.setValue(null);
  }

  private _filter(): string[] {
    return this.allKeywords.filter(keyword => !this.keywords.includes(keyword));
  }

  onDateChange() {
    this.createdAt = {
      from: moment(this.date.begin).format(),
      to: moment(this.date.end).format()
    };
  }

  onSetFilter() {
    this.logService.log('onSetFilter');

    this.bottomSheetRef.dismiss({ keywords: this.keywords, createdAt: this.createdAt });
  }
}
