import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IAnyObject } from '@cation/core/interfaces/cms';

@Component({
  selector: 'app-cms-list-table',
  templateUrl: './cms-list-table.component.html',
  styleUrls: ['./cms-list-table.component.scss']
})
export class CmsListTableComponent implements OnInit {
  public displayedColumns = ['id', 'title', 'status', 'created at'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource = new MatTableDataSource([]);

  @Output() onSelectTicket = new EventEmitter<IAnyObject>();

  constructor() {}

  @Input()
  set tickets(items) {
    this.dataSource = new MatTableDataSource(items);
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }
}
