import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import User from '@cation/core/types/user';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  constructor(public authHelper: AuthHelper) {}

  ngOnInit() {}
}
