<div class="ctn" (click)="onUserClickEvent($event)">
  <div class="ctn-main">
    <app-s3-image *ngIf="userData.avatarUrl" [isRounded]="true" [image]="userData.avatarUrl" class="user-avatar">
    </app-s3-image>

    <div class="ctn-user-name">
      <div class="d-flex">
        <span class="username" [title]="userData.username">{{ userData.username }}</span>
        <span *ngIf="userData.isEnabledField">
          <mat-icon *ngIf="userData.enabled" class="enabled">check_box</mat-icon>
          <mat-icon *ngIf="!userData.enabled" class="disabled">indeterminate_check_box</mat-icon>
        </span>
      </div>
      <p *ngIf="userData.nickname" class="nickname">({{ userData.nickname }})</p>
      <p *ngIf="userData?.roles?.length" class="roles">
        <span *ngFor="let role of userData.roles">{{ role | replaceUnderscores: ' ' | lowercase }}</span>
      </p>
    </div>

    <div class="close-icon">
      <mat-icon *ngIf="userData.canBeDeleted" (click)="onDeleteEvent($event)">close</mat-icon>
    </div>
  </div>

  <div class="ctn-info">
    <div *ngIf="userData.email" class="ctn-info-item">
      <span [title]="userData.email">{{ userData.email }}</span>
      <mat-icon *ngIf="userData.emailVerified">verified</mat-icon>
    </div>

    <div *ngIf="userData.phone" class="ctn-info-item">
      <span [title]="userData.phone">{{ userData.phone }}</span>
      <mat-icon *ngIf="userData.phoneVerified">verified</mat-icon>
    </div>

    <div class="d-flex">
      <div class="ctn-info-team">
        <div *ngIf="userData.teamName" class="ctn-info-item">
          <span [title]="userData.teamName">{{ userData.teamName }}</span>
        </div>

        <div *ngIf="userData.teamDescription" class="ctn-info-item">
          <span class="team-description" [title]="userData.teamDescription">{{ userData.teamDescription }}</span>
        </div>
      </div>

      <app-s3-image
        *ngIf="userData.teamImageUrl"
        [isRounded]="true"
        [image]="userData.teamImageUrl"
        class="team-img ms-1"
      >
      </app-s3-image>
    </div>
  </div>
</div>
