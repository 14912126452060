import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { fadeInOut, listFadeInOut } from '@cation/core/animations';
import { IChallengeItem } from '@cation/core/types/api';
import { SnackBarErrorComponent } from '@cation/core/components/snack-bar-error/snack-bar-error.component';
import { ChallengeDialogComponent } from '../challenge-dialog/challenge-dialog.component';
import { ChallengeWagerDialogComponent } from '../challenge-wager-dialog/challenge-wager-dialog.component';
import { MatSelectChange } from '@angular/material/select';
import { ChangeChallengeStatusModel } from '../models/challenge/change-challenge-status.model';

interface IChallengesData {
  [tab: string]: {
    items: IChallengeItem[];
    lastKey: object;
    isLoading: boolean;
  };
}

@Component({
  selector: 'app-challenges-list',
  templateUrl: './challenges-list.component.html',
  styleUrls: ['./challenges-list.component.scss'],
  animations: [fadeInOut, listFadeInOut],
})
export class ChallengesListComponent implements OnInit, OnDestroy {
  Object = Object;
  errorMessage;

  initLoading = true;

  tabs = ['PROPOSALS', 'ACTIVE', 'AVAILABLE', 'HISTORY'];
  selectedTab = 'HISTORY';
  challenges: IChallengesData = _.reduce(
    this.tabs,
    (res, tab) => ({
      ...res,
      [tab]: { items: [], lastKey: null, isLoading: false },
    }),
    {}
  );

  @Input() autoRefresh: boolean = false;

  private interval: NodeJS.Timer;

  @Output() onAddChallengeWager = new EventEmitter<void>();

  constructor(
    private apiService: ApiService,
    private logService: LogService,
    private authHelper: AuthHelper,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getAllGamificationChallenges();
  }

  ngOnDestroy() {
    this.destroyInterval();
  }

  startInterval() {
    if (!this.interval) {
      this.interval = setInterval(() => this.autoRefresh && this.getAllGamificationChallenges(true), 10000);
    }
  }

  destroyInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  getAllGamificationChallenges(isSilent = false) {
    this.destroyInterval();
    this.tabs.map((tab) => this.getGamificationChallenges(tab, false, isSilent));
    this.startInterval();
  }

  getOptionsByTab(tab, isLoadMore) {
    const startKey = isLoadMore ? this.challenges[tab].lastKey : null;
    const options = { startKey: JSON.stringify(startKey) };
    if (tab === 'HISTORY') {
      return Object.assign(options, {
        statuses: ['FINISHED', 'EXPIRED', 'REJECTED'],
        userId: this.authHelper.userProfile.cognitoId,
      });
    }
    if (tab === 'ACTIVE') {
      return Object.assign(options, { statuses: ['ACCEPTED'] });
    }
    if (tab === 'PROPOSALS') {
      return Object.assign(options, {
        statuses: ['PENDING'],
        userId: this.authHelper.userProfile.cognitoId,
      });
    }
    if (tab === 'AVAILABLE') {
      return Object.assign(options, {
        statuses: ['ACCEPTED'],
        excludeUserId: this.authHelper.userProfile.cognitoId,
      });
    }

    return undefined;
  }

  async getGamificationChallenges(tab: string, isLoadMore = false, isSilent = false) {
    if (!isSilent) {
      this.challenges[tab].isLoading = true;
    }

    try {
      const options = this.getOptionsByTab(tab, isLoadMore);

      const { items, lastKey } = await this.apiService.findGamificationChallenges(options);

      this.challenges[tab].items = _.orderBy(
        isLoadMore ? this.challenges[tab].items.concat(items) : items,
        (i) => i.id.split('_')[0],
        'desc'
      );
      this.challenges[tab].lastKey = lastKey;

      this.logService.log('[getGamificationChallenges]', { tab, items, lastKey });
      this.initLoading = false;
    } catch (e) {
      this.errorMessage = e.message;
      this.logService.error('[getGamificationChallenges Error]', e);
    }

    if (!isSilent) {
      this.challenges[tab].isLoading = false;
    }
  }

  toChallenge() {
    this.logService.debug('[ProfileComponent toChallenge]');
    const dialog = this.dialog.open(ChallengeDialogComponent, {
      width: '50%',
      autoFocus: true,
      disableClose: true,
      maxWidth: 570,
      minHeight: 325,
      maxHeight: '95vh',
      panelClass: 'challenge-dialog',
    });

    dialog.afterClosed().subscribe(async (result) => {
      this.logService.log('[toChallenge result]', result);
      if (result) {
        this.getGamificationChallenges('PROPOSALS');
      }
    });
  }

  toMakeWager(index: number) {
    this.logService.debug('[ProfileComponent toMakeWager]');
    const dialog = this.dialog.open(ChallengeWagerDialogComponent, {
      data: this.challenges[this.selectedTab].items[index],
    });

    dialog.afterClosed().subscribe(async (result) => {
      this.logService.log('[toMakeWager result]', result);
      if (result) {
        this.onAddChallengeWager.emit();
      }
    });
  }

  getDateFromId(id) {
    return id.split('_')[0];
  }

  async changeChallengeStatus({ tab, id, type, status }: ChangeChallengeStatusModel) {
    this.challenges[tab].isLoading = true;
    try {
      await this.apiService.changeGamificationChallengeStatus(id, { status, type });
      await Promise.all([this.getAllGamificationChallenges(), this.authHelper.loadRewards()]);
    } catch (e) {
      this.logService.error('[changeChallengeStatus]', e);
      this.showErrorMessage(e);
    }
    this.challenges[tab].isLoading = false;
  }

  showErrorMessage(e) {
    const message = e.error && e.error.errorCode ? `ERROR_MESSAGE.${e.error.errorCode}` : e.message;

    this.snackBar.openFromComponent(SnackBarErrorComponent, {
      duration: 5000,
      data: { message },
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: ['notification__error'],
    });
  }

  onTabChange($event: MatSelectChange) {
    this.selectedTab = $event.value;
  }
}
