import { Injectable } from '@angular/core';
import { FetchPolicy } from 'apollo-client';
import * as _ from 'lodash';
import { AppsyncOperationService } from '@cation/core/services/appsync/appsync-operation.service';
import { constants, escapeStr, unshiftMessage } from '@cation/core/util/chat-helper';
import * as subscriptions from '@cation/core/graphql/subscriptions';
import * as mutations from '@cation/core/graphql/mutations';
import Conversation from '@cation/core/types/conversation';
import * as queries from '@cation/core/graphql/queries';
import { getConversationMessagesQuery as MessagesQuery } from '@cation/core/graphql/operation-result-types';
import { ISubscriptionOptions } from './types';

@Injectable({
  providedIn: 'root'
})
export class AppsyncMessageService extends AppsyncOperationService {
  subscribeToNewMessage(observable, variables: { conversationId: string }, updateQuery) {
    return this.subscribeToMore<MessagesQuery>(observable, {
      document: subscriptions.subscribeToNewMessage,
      variables,
      updateQuery
    });
  }

  subscribeToReadMessage(
    conversation: Conversation,
    params: ISubscriptionOptions
  ): Promise<ZenObservable.Subscription> {
    const options = _.merge({ onComplete: () => null, onError: () => this.forceReloadApp() }, params);

    this.logService.log('[SubscriptionService subscribeToReadMessage]');

    return this.subscription({
      query: subscriptions.subscribeToReadMessage,
      variables: { conversationId: conversation.id },
      realtimeResults: options.realtimeResults,
      onComplete: options.onComplete,
      onError: options.onError
    });
  }

  loadMessages(conversation: Conversation, observerOrNext) {
    const watchQueryOptions = {
      query: queries.getConversationMessages,
      fetchPolicy: 'cache-and-network' as FetchPolicy,
      variables: {
        conversationId: conversation.id,
        first: constants.messageFirst
      }
    };

    const watchQuerySubscriptionOptions = { observerOrNext };

    return this.load<MessagesQuery>({ watchQueryOptions, watchQuerySubscriptionOptions });
  }

  createNewMessage(conversationId, message) {
    this.logService.log('[AppsyncOperationService createNewMessage]', { conversationId, message });

    const options = {
      mutation: mutations.createMessage,
      variables: { ...message, content: escapeStr(message.content) },

      optimisticResponse: () => ({
        createMessage: {
          ...message,
          __typename: 'Message'
        }
      }),

      update: (proxy, { data: { createMessage: _message } }) => {
        const _options = {
          query: queries.getConversationMessages,
          variables: {
            conversationId,
            first: constants.messageFirst
          }
        };

        const data = proxy.readQuery(_options);
        const _tmp = unshiftMessage(data, _message);
        proxy.writeQuery({ ..._options, data: _tmp });
      }
    };

    return this.mutate(options);
  }
}
