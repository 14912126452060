import { Injectable } from '@angular/core';
import { FetchPolicy } from 'apollo-client';
import { AppsyncOperationService } from './appsync-operation.service';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import * as _ from 'lodash';
import * as subscriptions from '../../graphql/subscriptions';
import { searchUsersQuery } from '../../graphql/operation-result-types';
import User from '../../types/user';

@Injectable()
export class AppsyncUserService extends AppsyncOperationService {
  registerUser(observerOrNext: (res) => any) {
    return this.watchQuery(
      {
        query: queries.getMe,
        fetchPolicy: 'no-cache' as FetchPolicy
      },
      observerOrNext
    );
  }

  updateActiveTime(user: User, time) {
    const { id } = user;
    return this.mutate<{ updateActiveTime: User }>({
      mutation: mutations.updateActiveTime,
      variables: { userId: id, time },

      optimisticResponse: () => ({
        updateActiveTime: {
          ...user,
          lastActiveTime: time,
          __typename: 'User'
        }
      }),

      update: (proxy, { data: { updateActiveTime: _user } }) => {
        this.loggerService.info('updateActiveTime update with:', _user);
        proxy.writeQuery({ query: queries.getMe, data: { me: { ..._user } } });
      }
    }).catch(err => this.loggerService.error('Error updating active time', err));
  }

  createUser(input) {
    return this.mutate<{ createUser: User }>({
      mutation: mutations.createUser,
      variables: { input },

      optimisticResponse: () => ({
        createUser: {
          ...input,
          lastActiveTime: Date.now(),
          __typename: 'User'
        }
      }),

      update: (proxy, { data: { createUser: _user } }) => {
        this.loggerService.info('createUser update with:', _user);
        proxy.writeQuery({ query: queries.getMe, data: { me: { ..._user } } });
      }
    }).catch(err => this.loggerService.error('Error registering user', err));
  }

  loadUsersAndSubscribe(name: string, observerOrNext, updateQuery) {
    const watchQueryOptions = {
      query: queries.searchUsers,
      variables: { name },
      fetchPolicy: 'cache-and-network' as FetchPolicy
    };

    const watchQuerySubscriptionOptions = { observerOrNext };

    const subscribeToMoreOptions = _.merge(
      {},
      {
        document: subscriptions.subscribeToUsers,
        updateQuery
      }
    );

    return this.loadAndSubscribeMore<searchUsersQuery>({
      watchQueryOptions,
      watchQuerySubscriptionOptions,
      subscribeToMoreOptions
    });
  }

  async searchUsers(name: string): Promise<User[]> {
    const options = { query: queries.searchUsers, variables: { name } };

    const {
      data: { searchUsers }
    } = await this.query<searchUsersQuery>(options);

    return searchUsers;
  }
}
