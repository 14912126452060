import { Component, Input, ElementRef, ViewChild, Output, EventEmitter, AfterViewInit, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ObservableQuery } from 'apollo-client';
import { Analytics } from 'aws-amplify';
import { EMPTY, from, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { AppsyncConversationService } from '@cation/core/services/appsync/appsync-conversation.service';
import { ConversationService } from '@cation/core/services/conversation/conversation.service';
import { AppsyncService } from '@cation/core/services/appsync/appsync.service';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { LogService } from '@cation/core/services/log/log.service';
import { ApiService } from '@cation/core/services/api/api.service';
import { getConversationHistory } from '@cation/core/util/shared-functions';
import { mapSentimentToIcon } from '@cation/core/constants/sentiments';
import { LocaleUtil } from '@cation/core/util/locale-util';
import { prepareConversation } from '@cation/core/util/common-util';
import { ICMS } from '@cation/core/interfaces/cms';
import {
  getConversationMessagesQuery as MessagesQuery,
  getConversationNotesQuery as NotesQuery,
} from '@cation/core/graphql/operation-result-types';
import * as mutations from '@cation/core/graphql/mutations';
import * as queries from '@cation/core/graphql/queries';
import { AppSyncApiService } from '@cation/core/services/api/appsync-api.service';
import Conversation from '@cation/core/types/conversation';
import Message from '@cation/core/types/message';
import Note from '@cation/core/types/note';
import { constants, pushMessages, pushNotes } from '@cation/core/util/chat-helper';
import { ChatCreateEditNoteDialogComponent } from '@cation/core/components/chat-create-edit-note-dialog/chat-create-edit-note-dialog.component';
import { BottomSheetActionFindSimilarCaseComponent } from './bottom-sheet-action-find-similar-case.component';
import { InviteAgentDialogComponent } from '../invite-agent-dialog/invite-agent-dialog.component';
import { OnlyTextChannels, Channel } from '@cation/core/enums/channel.enum';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { BaseUnsubscribeComponent } from '@cation/core/components/base-unsubscribe/base-unsubscribe.component';

@Component({
  selector: 'app-chat-message-view',
  templateUrl: './chat-message-view.component.html',
  styleUrls: ['./chat-message-view.component.scss'],
  animations: [
    trigger('openClosePanel', [
      state(
        'open',
        style({
          'max-width': '40%',
          opacity: 1,
        })
      ),
      state(
        'closed',
        style({
          'max-width': '0',
          opacity: 0,
        })
      ),
      transition('closed <=> open', [animate('.3s')]),
    ]),
  ],
})
export class ChatMessageViewComponent extends BaseUnsubscribeComponent implements OnInit, AfterViewInit {
  _conversation: Conversation;

  messages: Message[] = [];
  nextToken: string;
  observedQuery: ObservableQuery<MessagesQuery>;
  fetchingMore = false;
  completedFetching = false;

  notes: Note[] = [];
  notesNextToken: string;
  notesObservedQuery: ObservableQuery<MessagesQuery>;
  fetchingNotesMore = false;
  completedNotesFetching = false;

  lastMessage: Message;
  firstMessage: Message;
  subscription: () => void;

  _conversationHistory = [];
  runningLine = '';

  isMounted = false;
  isInitialized = false;
  isHistoryLoading = false;
  hiddenActionPanel = true;

  tabId = 'chat';
  currentCms: ICMS;
  cmsAddSubscription: Subscription;

  mapSentimentToIcon = mapSentimentToIcon;

  isMainAgent = false;
  isHideMessageAuthor = false;

  isSidePanelOpened = true;
  private localStorageKeys = {
    isSidePanelOpened: 'isSidePanelOpened',
  };

  enabledVideoBtn = true;
  showVideoChat = false;

  @ViewChild('scrollMe') myScrollContainer: ElementRef;
  @ViewChild('actionPanel', { static: true }) actionPanel: ElementRef;
  @ViewChild('nav', { static: true }) nav;

  @Input() senderId;

  @Output() onCloseConversation = new EventEmitter<void>();
  @Output() onAddCmsSystem = new EventEmitter<ICMS>();
  @Output() onDetachCmsSystem = new EventEmitter<ICMS>();
  @Output() onUpdateConversation = new EventEmitter<Conversation>();

  @Input()
  set conversation(convo: Conversation) {
    this.logService.debug('setConversation - ', convo);

    if (
      _.get(this._conversation, 'id') === convo.id &&
      _.get(this._conversation, 'status') === convo.status &&
      _.get(this._conversation, 'cms', []).length === convo.cms.length &&
      _.isEqual(this._conversation.associated, convo.associated)
    ) {
      return;
    }

    if (!convo) {
      this.runningLine = '';
      return;
    }

    if (_.get(this._conversation, 'id') !== convo.id) {
      this.isInitialized = false;
      this.nextToken = null;
      this.currentCms = null;
      this.hiddenActionPanel = true;
      this.messages = [];
      this._conversationHistory = [];
    }

    this._conversation = convo;
    this._conversation.cms = _.orderBy(convo.cms, 'isCore', 'desc');

    // temp fix - We need to find why role is missing. For now just adding it by registered flag
    console.log('temp-fix:before:', JSON.stringify(convo.associated));
    convo.associated.forEach(
      (associated) =>
        (associated.role = associated.role
          ? associated.role
          : associated.user.registered === 'true'
          ? 'AGENT'
          : 'AUTHOR')
    );
    console.log('temp-fix:after:', JSON.stringify(convo.associated));

    console.log(
      'convo.associated.find(a => a.userId === this.senderId)',
      convo.associated.find((a) => a.userId === this.senderId)
    );
    this.isMainAgent = (convo.associated.find((a) => a.userId === this.senderId) || { role: '' }).role === 'AGENT';
    console.log('this.isMainAgent', this.isMainAgent);

    this.isHideMessageAuthor = convo.associated.filter((a) => a.userId !== convo.createdBy).length === 1;

    if (this.subscription) {
      this.subscription();
    }

    this.getConversationHistory();
    this.getConversationById();
  }

  get conversation() {
    return this._conversation;
  }

  get conversationHistory() {
    return this._conversationHistory;
  }

  set conversationHistory(messages) {
    const historyMessages = messages.map((message, key) => ({
      ...message,
      isSent: true,
      content: message.message,
      id: `history-${key}`,
      createdAt: `${message.timestamp}_`,
    }));
    this._conversationHistory = historyMessages;

    const primaryTopics = messages.reduce((res, value) => {
      if (value.primaryTopic) {
        res.push(value.primaryTopic);
      }
      return res;
    }, []);

    this.runningLine = _.uniq(primaryTopics).join(', ');

    if (this._conversation.mailData && this._conversation.mailData.subject) {
      this.runningLine = 'Subject: ' + this._conversation.mailData.subject;
    }
    this.runningLine = this.runningLine || '';
  }

  @Input()
  set messagesData(data) {
    const {
      messages = [],
      nextToken,
      observedQuery,
    } = data || {
      messages: [],
      nextToken: null,
      observedQuery: undefined,
    };
    this.messages = messages;
    this.nextToken = nextToken;
    this.observedQuery = observedQuery;

    if (!this.isMounted) {
      return;
    }

    this.initMessages();
  }

  @Input()
  set notesData(data) {
    const {
      notes = [],
      nextToken,
      observedQuery,
    } = data || {
      notes: [],
      nextToken: null,
      observedQuery: undefined,
    };
    this.notes = notes;
    this.notesNextToken = nextToken;
    this.notesObservedQuery = observedQuery;

    if (this.notes.length < constants.notesFirst && this.notesNextToken) {
      this.loadMoreNotes();
    }
  }

  constructor(
    private appsync: AppsyncService,
    private appsyncConversationService: AppsyncConversationService,
    private appSyncApiService: AppSyncApiService,
    private conversationService: ConversationService,
    private sharedService: SharedService,
    private logService: LogService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private apiService: ApiService,
    public localeUtil: LocaleUtil,
    private bottomSheet: MatBottomSheet
  ) {
    super();
    this.loadMoreNotes = this.loadMoreNotes.bind(this);
    this.loadMoreMessages = this.loadMoreMessages.bind(this);
    this.cmsAddSubscription = this.sharedService.onCmsChanged.subscribe((key) => this.nav.select(key || 'chat'));
    this.addSubscription(this.cmsAddSubscription);
  }

  ngOnInit(): void {
    this.initLocalStorageValues();
  }

  ngAfterViewInit(): void {
    this.isMounted = true;
    this.initMessages();
    this.handleSelectionChange();
  }

  initMessages(): void {
    const clientMessages = this.messages.filter((m) => m.sender !== this.senderId).reverse();
    let lastQuestion = this.conversation.lastQuestion;

    for (let i = 0; i < clientMessages.length; i++) {
      if (clientMessages[i].isQuestion) {
        lastQuestion = clientMessages[i].content;
        break;
      }
    }

    if (lastQuestion && !this.isConversationClosed() && !this.isInitialized) {
      this.sharedService.searchAi(lastQuestion);
      this.isInitialized = true;
    }
  }

  openBottomSheet(): void {
    this.bottomSheet.open(BottomSheetActionFindSimilarCaseComponent, {
      data: { message: this.getSelectionText() },
    });
    this.clearSelection();
  }

  onOpenAddConversationNoteModal() {
    const dialog = this.dialog.open(ChatCreateEditNoteDialogComponent, {
      height: 'auto',
      width: '50%',
      data: { conversation: this.conversation, senderId: this.senderId },
    });

    dialog.afterClosed().subscribe(async (result) => {
      this.logService.log('[onOpenAddConversationNoteModal result]', result);
    });
  }

  handleSelectionChange() {
    if (!this.myScrollContainer) return;

    this.myScrollContainer.nativeElement.onmouseup = this.myScrollContainer.nativeElement.onselectionchange = () => {
      if (this.tabId !== 'chat') {
        return;
      }
      const text = this.getSelectionText();

      if (text) {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);

        range.insertNode(this.actionPanel.nativeElement);

        this.hiddenActionPanel = false;
      } else {
        this.hiddenActionPanel = true;
      }
    };
  }

  getSelectionText() {
    let text = '';
    if (typeof window.getSelection !== 'undefined') {
      const selection = window.getSelection();
      text = selection.toString();
    } else if (typeof (document as any).selection !== 'undefined') {
      // IE9
      if ((document as any).selection.type === 'Text') {
        text = (document as any).selection.createRange().text;
      }
    }
    /** Remove actionPanel content from selection */
    const actionPanelContent = this.actionPanel.nativeElement.textContent.trim();
    return text.startsWith(actionPanelContent) ? text.slice(actionPanelContent.length) : text;
  }

  clearSelection() {
    if (window.getSelection) {
      if (window.getSelection().empty) {
        // Chrome
        window.getSelection().empty();
      } else if (window.getSelection().removeAllRanges) {
        // Firefox
        window.getSelection().removeAllRanges();
      }
    } else if ((document as any).selection) {
      // IE?
      (document as any).selection.empty();
    }
  }

  openModal(content, size?: 'sm' | 'lg') {
    this.modalService
      .open(content, { size })
      .result.then(() => this.logService.info('[modalService Success]'))
      .catch((err) => this.logService.log('[modalService Error]', err));
  }

  public onIntersection({ target, visible }: { target: Element; visible: boolean }, message: Message): void {
    if (!visible) {
      return;
    }
    this.readMessage(message);
  }

  public messageAdded(isFirst = false, message: Message) {
    if (isFirst) {
      if (!this.firstMessage) {
        this.firstMessage = message;
      } else if (this.firstMessage.id !== message.id) {
        setTimeout(() => {
          this.completedFetching = this.fetchingMore;
          this.fetchingMore = false;
        });
      }
    } else {
      if (!this.lastMessage || this.lastMessage.id !== message.id) {
        setTimeout(() => this.scrollToBottom(), 1000);
      }
      this.lastMessage = message;
    }
  }

  private scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) {}
  }

  public fromMe(message): boolean {
    return message.sender === this.senderId;
  }

  public trackMessage(index, message) {
    return message ? message.id : undefined;
  }

  public trackNote(index, note) {
    return note ? note.id : undefined;
  }

  public isConversationClosed(): boolean {
    return this.conversation.status === 'Closed';
  }

  public getCmsInstance({ id, type }): ICMS {
    return this.sharedService.cmsSystems.get(`${type}:${id}`);
  }

  public get coreCms(): ICMS {
    const cms = _.find(this._conversation.cms, { isCore: true });
    return cms ? this.getCmsInstance(cms) : null;
  }

  public tabChange(event) {
    if (event.nextId === 'chat') {
      setTimeout(() => this.scrollToBottom(), 1000);
    }
    this.currentCms = null;
    this.tabId = event.nextId;
    this.hiddenActionPanel = true;
    if (!['chat', 'history'].includes(this.tabId)) {
      const [type, id] = this.tabId.split(':');
      const cms = _.find(this._conversation.cms, { id, type });
      this.currentCms = cms ? this.getCmsInstance(cms) : null;
    }
    if (this.tabId === 'chat') {
      setTimeout(() => this.handleSelectionChange());
    }
  }

  private readMessage(message: Message) {
    this.logService.log('[readMessage]', message);
    return this.appsync.hc().then((client) => {
      return client
        .mutate({
          mutation: mutations.readMessage,
          variables: {
            conversationId: message.conversationId,
            createdAt: message.createdAt,
          },

          optimisticResponse: () => ({
            readMessage: {
              ...message,
              isRead: true,
              __typename: 'Message',
            },
          }),

          update: (proxy, { data: { readMessage: _message } }) => {
            this.logService.info('[readMessage]', { proxy, message, _message });
          },
        })
        .then(({ data }) => {
          this.logService.log('mutation complete', data);
          return data;
        })
        .catch((err) => this.logService.log('Error reading message', err))
        .then(() => Analytics.record('Chat MSG Read'));
    });
  }

  public async changeCoreCms(cms) {
    const { id, type } = cms;

    await this.apiService.changeCoreCms({ id, type, conversationId: this.conversation.id }).toPromise();

    const coreCmsIndex = _.findIndex(this.conversation.cms, { isCore: true });
    const currentCmsIndex = _.findIndex(this.conversation.cms, { id, type });

    _.set(this.conversation.cms, `[${coreCmsIndex}].isCore`, false);
    _.set(this.conversation.cms, `[${currentCmsIndex}].isCore`, true);

    this._conversation.cms = _.orderBy(this.conversation.cms, 'isCore', 'desc');
  }

  public async detachCms(cms) {
    const { createdAt } = cms;
    await this.appsyncConversationService.detachCms(this.conversation.id, createdAt);
    this.onDetachCmsSystem.emit(cms);
  }

  public loadMoreMessages(event = null) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!this.nextToken) {
      return EMPTY;
    }
    const result = this.observedQuery.fetchMore({
      variables: { after: this.nextToken },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        const _res = pushMessages(
          prev as MessagesQuery,
          fetchMoreResult.allMessageConnection.messages,
          fetchMoreResult.allMessageConnection.nextToken
        );
        this.nextToken = fetchMoreResult.allMessageConnection.nextToken;
        this.completedFetching = false;
        this.fetchingMore = true;
        return _res;
      },
    });
    return from(result);
  }

  public loadMoreNotes(event = null) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!this.notesNextToken) {
      return EMPTY;
    }
    const result = this.notesObservedQuery.fetchMore({
      variables: { after: this.notesNextToken },
      updateQuery: (prev, options) => {
        const fetchMoreResult = options.fetchMoreResult as any;
        if (!fetchMoreResult) {
          return prev;
        }
        const _res = pushNotes(
          prev as any,
          fetchMoreResult.getConversationNotes.notes,
          fetchMoreResult.getConversationNotes.nextToken
        );
        this.notesNextToken = fetchMoreResult.getConversationNotes.nextToken;
        this.completedNotesFetching = false;
        this.fetchingNotesMore = true;
        return _res as any;
      },
    });
    return from(result);
  }

  private getConversationById() {
    return this.appsync.hc().then((client) => {
      return client
        .query({
          query: queries.getConversationById,
          fetchPolicy: 'no-cache',
          variables: { id: this._conversation.id },
        })
        .then(({ data: { getConversationById: conversation } }) => {
          this.onUpdateConversation.emit(_.merge(this._conversation, prepareConversation(conversation)));
        });
    });
  }

  public closeConversation() {
    this.onCloseConversation.emit();
  }

  async disconnectConversation() {
    this.logService.log('disconnectConversation');

    await this.appsyncConversationService.updateUserConversations(this.conversation.id, this.senderId);
    if (this.isMainAgent) {
      await this.appsyncConversationService.closeConversation(this.conversation.id, this.senderId);
    } else {
      await this.appsyncConversationService.deleteConversationLocal(this.conversation.id);
    }
    await this.conversationService.unsubscribeConversation(this.conversation.id);

    this.onCloseConversation.emit();
    await Analytics.record('Disconnect Conversation');
  }

  private getConversationHistory() {
    if (this.conversation.history && this.conversation.history.length) {
      this.conversationHistory = this.conversation.history;
      return undefined;
    }

    this.isHistoryLoading = true;

    return getConversationHistory(this.conversation.botSessionId)
      .then((data) => {
        this.logService.log('[getConversationHistory]', data);
        this.conversationHistory = data.messages;
      })
      .catch((error) => {
        this.logService.error('[getConversationHistory Error]', error);
      })
      .then(() => (this.isHistoryLoading = false));
  }

  searchAi(event) {
    event.preventDefault();
    event.stopPropagation();
    this.logService.log('searchAi', this.getSelectionText());
    this.sharedService.searchAi(this.getSelectionText());
    this.resetSelection();
  }

  resetSelection() {
    this.hiddenActionPanel = true;
    this.clearSelection();
  }

  onOpenInviteAgentModal() {
    const dialog = this.dialog.open(InviteAgentDialogComponent, {
      width: '40%',
      data: { conversation: this.conversation, onCloseConversation: this.onCloseConversation },
    });

    dialog.afterClosed().subscribe(async (result) => {
      this.logService.log('[onOpenPreviewAuditMetaDataDialogComponent result]', result);
    });
  }

  async endConversation() {
    this.logService.log('conversation - %j', this.conversation);

    try {
      await this.conversationService.closeConversation(this.conversation.id, this.senderId);
      if (OnlyTextChannels.includes(this.conversation.channel as Channel)) {
        const data = await this.apiService
          .disableHumanMode({
            userId: this.conversation.author.cognitoId,
            conversationId: this.conversation.id,
          })
          .toPromise();
        this.logService.log('disableHumanMode:data', data);
      }
      const input = {
        conversationId: this.conversation.id,
        userId: this.senderId,
        typing: false,
        connected: 'false',
      };
      await this.appSyncApiService.updateUserConversation(input);

      this.closeConversation();
    } catch (e) {
      this.logService.error('[close]', e, e.stack);
    }
  }

  private initLocalStorageValues(): void {
    const openedSidePanel = localStorage.getItem(this.localStorageKeys.isSidePanelOpened);

    if (openedSidePanel) {
      this.isSidePanelOpened = openedSidePanel === 'true';
    }
  }

  public togglePanel(): void {
    this.isSidePanelOpened = !this.isSidePanelOpened;
    localStorage.setItem(this.localStorageKeys.isSidePanelOpened, `${this.isSidePanelOpened}`);
  }

  public closeVideoChat(): void {
    this.showVideoChat = false;
  }

  public openVideoChat(): void {
    if (!this.showVideoChat) {
      this.showVideoChat = true;
    }
  }
}
