import { AppService } from '@cation/core/services/app/app.service';
import { ManageCannedResponsesDialogComponent } from '@cation/admin/components/manage-canned-responses-dialog/manage-canned-responses-dialog.component';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { TranslateService } from '@ngx-translate/core';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { SharedService } from '@cation/core/services/shared/shared.service';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { UrlParserService } from '@cation/core/services/url-parser/url-parser.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './app-navbar.component.html',
  styleUrls: ['./app-navbar.component.scss']
})
export class AppNavbarComponent implements OnInit {
  isLoggedIn = false;
  countActiveConversations = 0;
  countUnreadMessages = 0;
  currentTime = Date.now();

  constructor(
    private sharedService: SharedService,
    public translate: TranslateService,
    public auth: AuthHelper,
    public router: Router,
    private dialog: MatDialog,
    public urlParserService: UrlParserService,
    private appService: AppService
  ) {
    this.auth.isAuthenticated$.subscribe(isAuthenticated => {
      this.isLoggedIn = isAuthenticated;
    });
  }

  ngOnInit() {
    this.sharedService.currentCountActiveConversations.subscribe(count => (this.countActiveConversations = count));
    this.sharedService.currentCountUnreadMessages.subscribe((value: { [prop: string]: number }) => {
      const countUnreadMessages = Object.values(value).reduce((res: number, v: number) => res + v, 0);
      this.countUnreadMessages = this.prepareCountUnreadMessages(countUnreadMessages);
      this.updateTitle();
    });
  }

  public signOut() {
    if (this.countActiveConversations) {
      return alert('You have active conversations.\nPlease continue these conversations.');
    }

    Auth.signOut().then();
    setTimeout(() => location.reload(), 100);
  }

  prepareCountUnreadMessages(value) {
    if (isNaN(value) || value > 9) {
      return '9+';
    }
    return value;
  }

  private updateTitle() {
    let newTitle = this.appService.getTitle;

    if (this.countUnreadMessages) {
      newTitle = `(${this.countUnreadMessages}) ${newTitle}`;
    }

    this.appService.updateTitle(newTitle);
  }

  openManageCannedResponses($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.dialog.open(ManageCannedResponsesDialogComponent, { disableClose: true });
  }
}
