import { Injectable } from '@angular/core';
import { ObservableQuery } from 'apollo-client';
import * as _ from 'lodash';
import { Analytics } from 'aws-amplify';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarMessageComponent } from '@cation/core/components/snack-bar-message/snack-bar-message.component';
import { AppsyncConversationService } from '@cation/core/services/appsync/appsync-conversation.service';
import {
  addConversation,
  addConversations,
  deleteNote,
  unshiftMessage,
  unshiftNote
} from '@cation/core/util/chat-helper';
import { AppsyncMessageService } from '@cation/core/services/appsync/appsync-message.service';
import { AppsyncNoteService } from '@cation/core/services/appsync/appsync-note.service';
import { convertUserConversationToConversation, decodeMessage } from '@cation/core/util/common-util';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { CMS_TYPE } from '@cation/core/enums/cms-type.enum';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { Channel } from '@cation/core/enums/channel.enum';
import { CmsService } from '@cation/core/services/cms';
import {
  getConversationMessagesQuery as MessagesQuery,
  getConversationNotesQuery as NotesQuery,
  getUserConversationConnectionThroughUserQuery as ConvosQuery,
  getUserConversationConnectionThroughUserQuery as UserConvosQuery
} from '@cation/core/graphql/operation-result-types';
import Conversation from '@cation/core/types/conversation';
// import { subscribeToNewUserConversations } from '@cation/core/graphql/subscriptions';
// import { Observable } from 'zen-observable-ts';

@Injectable()
export class ConversationService {
  private nextToken: string;
  private currentConversation: Conversation;
  private conversations: Conversation[] = [];
  private observedQuery: ObservableQuery<UserConvosQuery>;
  private subscription: () => void;
  private conversationSubscriptions: { [conversationId: string]: ZenObservable.Subscription[] } = {};

  private messagesData = {};
  private notesData = {};
  private countUnreadMessages = {};
  private newConversations = [];
  private isOnline = false;
  private isInitialized = false;

  constructor(
    private appsyncConversationService: AppsyncConversationService,
    private appsyncMessageService: AppsyncMessageService,
    private appsyncNoteService: AppsyncNoteService,
    private apiService: ApiService,
    private authHelper: AuthHelper,
    private logService: LogService,
    private sharedService: SharedService,
    private cmsService: CmsService,
    private s3Service: S3Service,
    private snackBar: MatSnackBar
  ) {
    this.loadNotes = this.loadNotes.bind(this);
    this.loadMessages = this.loadMessages.bind(this);
    this.subscribeToReadMessage = this.subscribeToReadMessage.bind(this);
    this.subscribeToNewUCsByConversation = this.subscribeToNewUCsByConversation.bind(this);
    this.subscribeToUpdateUserConversations = this.subscribeToUpdateUserConversations.bind(this);

    this.sharedService.currentOnlineStatus.subscribe(isOnline => {
      if (this.isInitialized && isOnline && !this.isOnline) {
        this.findSuitableConversation();
      }
      this.isOnline = isOnline;
    });
    this.sharedService.currentConversation.subscribe(conversation => (this.currentConversation = conversation));
    this.sharedService.currentConversations.subscribe(conversations => (this.conversations = conversations));
    this.sharedService.currentNewConversations.subscribe(conversations => (this.newConversations = conversations));
    this.sharedService.currentMessagesData.subscribe(messagesData => (this.messagesData = messagesData));
    this.sharedService.currentCountUnreadMessages.subscribe(value => (this.countUnreadMessages = value));
    this.sharedService.currentNotesData.subscribe(notesData => (this.notesData = notesData));
  }

  init() {
    if (!this.authHelper.isAgent()) {
      return;
    }
    this.getAllConvos();
  }

  playNewMessageAudio() {
    const audio = new Audio();
    audio.src = '../../../../assets/audio/message.wav';
    audio.load();
    audio.play();
  }

  async findSuitableConversation() {
    await this.authHelper.updateActiveTime();
    await this.apiService.findSuitableConversation(this.authHelper.userProfile.cognitoId).toPromise();
  }

  private async getAllConvos() {
    const cognitoId = this.authHelper.userProfile.cognitoId;

    const observerOrNext = async ({ data }) => {
      this.logService.log('cs:Fetched convos data', data);
      if (!data || !data.me) {
        return this.logService.log('getUserConversationsConnection: no data');
      }
      const conversations = data.me.conversations.userConversations.map(convertUserConversationToConversation);

      const conversationIds = _.map(this.conversations, 'id');
      const newConversations = conversations.filter(({ id }) => !conversationIds.includes(id));
      // .filter(c => c.status !== 'Closed');
      newConversations.map(({ cms: list }) => {
        list.map(cms => {
          const cmsInstance = this.cmsService.createCMS(cms.type as CMS_TYPE, { lazyLoad: true, id: cms.id });
          const key = `${cms.type}:${cms.id}`;

          this.sharedService.addCmsSystem(key, cmsInstance);
        });
      });
      newConversations.map(this.loadMessages);
      newConversations.map(this.loadNotes);
      newConversations.map(async (conversation: Conversation) => {
        const subscriptionUpdate = await this.subscribeToUpdateUserConversations(conversation);
        const subscriptionNew = await this.subscribeToNewUCsByConversation(conversation);
        if (this.conversationSubscriptions[conversation.id]) {
          this.conversationSubscriptions[conversation.id].push(subscriptionUpdate, subscriptionNew);
        } else {
          this.conversationSubscriptions[conversation.id] = [subscriptionUpdate, subscriptionNew];
        }
      });
      newConversations.map(async (conversation: Conversation) => {
        const subscription = await this.subscribeToReadMessage(conversation);
        if (this.conversationSubscriptions[conversation.id]) {
          this.conversationSubscriptions[conversation.id].push(subscription);
        } else {
          this.conversationSubscriptions[conversation.id] = [subscription];
        }
      });

      const countActiveConversations = conversations.filter(c => c.status !== 'Closed').length;
      this.sharedService.setCountActiveConversations(countActiveConversations);

      const countUnreadMessages = {};
      conversations.forEach(({ status, id }) => {
        countUnreadMessages[id] = status !== 'Closed' ? this.countUnreadMessages[id] : 0;
      });
      this.sharedService.setCountUnreadMessages(countUnreadMessages);

      this.sharedService.setConversations(conversations);
      this.nextToken = data.me.conversations.nextToken;
      this.logService.log('Fetched convos', conversations);
    };

    const updateQuery = (
      prev: UserConvosQuery,
      {
        subscriptionData: {
          data: { subscribeToNewUCs: userConvo }
        }
      }
    ) => {
      this.logService.log('[cs:getAllConvos updateQuery]', userConvo);
      this.sharedService.setNewConversations(this.newConversations.concat(userConvo.conversation.id));
      // this.logService.log(JSON.stringify(userConvo, null, 2));
      // this.logService.log(JSON.stringify(prev, null, 2));

      return addConversation(prev, userConvo);
    };

    const data = await this.appsyncConversationService.getAllConversations(cognitoId, observerOrNext, updateQuery);

    // console.log('AMPLIFY_SUBSCRIPTION');
    // const observable: Observable<Object> = API.graphql(
    //   graphqlOperation(subscribeToNewUserConversations, { userId: cognitoId })
    // ) as Observable<Object>;
    // observable.subscribe(
    //   async (res: any) => console.log('AMPLIFY_SUBSCRIPTION:RES -', res),
    //   err => console.log('AMPLIFY_SUBSCRIPTION:ERR -', err),
    //   () => console.log('AMPLIFY_SUBSCRIPTION:COMPLETE')
    // );

    this.subscription = data.subscription;
    this.observedQuery = data.observedQuery;

    if (this.isOnline) {
      this.findSuitableConversation();
    }

    this.isInitialized = true;
  }

  private async loadMessages(conversation: Conversation) {
    const conversationId = conversation.id;
    if (this.messagesData[conversationId]) {
      return;
    }

    const observerOrNext = async ({ data }) => {
      this.logService.log('[loadMessages subscribe]', data);
      if (!data) {
        return this.logService.log('[loadMessages - no data]');
      }

      const newMessages =
        conversation.channel !== Channel.EMAIL
          ? data.allMessageConnection.messages
          : data.allMessageConnection.messages.map(msg => ({
              ...msg,
              content: decodeMessage(msg.content)
            }));

      /** Update Latest message **/
      if (newMessages.length) {
        newMessages[0] = await this.s3Service
          .updateAccessToImageUrls(newMessages[0].content)
          .then(content => ({ ...newMessages[0], content }));
      }

      const count = newMessages.filter(m => !m.isRead && m.sender !== this.authHelper.userProfile.cognitoId).length;

      this.sharedService.setCountUnreadMessages({
        ...this.countUnreadMessages,
        [conversationId]: count
      });

      this.sharedService.setMessagesData({
        ...this.messagesData,
        [conversation.id]: {
          ...this.messagesData[conversation.id],
          messages: [...newMessages].reverse(),
          nextToken: data.allMessageConnection.nextToken
        }
      });

      if (this.currentConversation) {
        this.sharedService.setCurrentConversation(this.currentConversation);
      }
      this.logService.log('[loadMessages]: nextToken is now', data.allMessageConnection.nextToken ? 'set' : 'null');
    };

    const { observableSubscription, observedQuery } = await this.appsyncMessageService.loadMessages(
      conversation,
      observerOrNext
    );

    await this.subscribeToNewMessage(observedQuery, conversation);

    this.sharedService.setMessagesData({
      ...this.messagesData,
      [conversationId]: {
        ...this.messagesData[conversationId],
        observedQuery,
        observableSubscription
      }
    });
  }

  private subscribeToNewMessage = (observable, conversation: Conversation) => {
    const conversationId = conversation.id;
    const updateQuery = (
      prev: MessagesQuery,
      {
        subscriptionData: {
          data: { subscribeToNewMessage: message }
        }
      }
    ) => {
      if (conversation.channel === Channel.EMAIL) {
        message.content = decodeMessage(message.content);
      }

      // if (message.content.startsWith('COMMAND:')) {
      //   console.log('Ignoring command message')
      //   return
      // }

      this.logService.log('[subscribeToNewMessage] - updateQuery:', {
        prev,
        message,
        current: this.currentConversation
      });

      this.sharedService.setCountUnreadMessages({
        ...this.countUnreadMessages,
        [conversationId]: this.countUnreadMessages[conversationId]
      });

      const isCurrentConversation = message.conversationId === _.get(this.currentConversation, 'id');
      const isFromMe = message.sender === this.authHelper.userProfile.cognitoId;

      if (!isFromMe) {
        this.playNewMessageAudio();
      }

      if (isCurrentConversation && !isFromMe && message.isQuestion) {
        this.sharedService.searchAi(message.content);
      }

      if (!isCurrentConversation && !isFromMe) {
        this.snackBar.openFromComponent(SnackBarMessageComponent, {
          duration: 5000,
          data: message,
          verticalPosition: 'top',
          horizontalPosition: 'end'
        });
      }

      return unshiftMessage(prev, message);
    };

    return this.appsyncMessageService.subscribeToNewMessage(observable, { conversationId }, updateQuery);
  };

  private async loadNotes(conversation: Conversation) {
    if (this.notesData[conversation.id]) {
      return;
    }

    const observerOrNext = ({ data }) => {
      this.logService.log('[loadNotes subscribe]', data);
      if (!data) {
        return this.logService.log('[loadNotes - no data]');
      }

      const newNotes = data.getConversationNotes.notes;

      this.sharedService.setNotesData({
        ...this.notesData,
        [conversation.id]: {
          ...this.notesData[conversation.id],
          notes: newNotes,
          nextToken: data.getConversationNotes.nextToken
        }
      });

      this.logService.log('[loadNotes nextToken]', data.getConversationNotes.nextToken ? 'set' : 'null');
    };

    const { observableSubscription, observedQuery } = await this.appsyncNoteService.loadNotes(
      conversation,
      observerOrNext
    );

    const newSubscription = await this.subscribeToNewNote(observedQuery, conversation.id);
    const deleteSubscription = await this.subscribeToDeleteNote(observedQuery, conversation.id);

    this.sharedService.setNotesData({
      ...this.notesData,
      [conversation.id]: {
        ...this.notesData[conversation.id],
        observedQuery,
        newSubscription,
        deleteSubscription,
        observableSubscription
      }
    });
  }

  private subscribeToNewNote = (observable, conversationId: string) => {
    const updateQuery = (
      prev: NotesQuery,
      {
        subscriptionData: {
          data: { subscribeToNewConversationNote: note }
        }
      }
    ) => {
      this.logService.log('[subscribeToNewNote] - updateQuery:', { prev, note });

      return unshiftNote(prev, note);
    };

    return this.appsyncNoteService.subscribeToNewNote(observable, { conversationId }, updateQuery);
  };

  private subscribeToDeleteNote = (observable, conversationId: string) => {
    const updateQuery = (
      prev: NotesQuery,
      {
        subscriptionData: {
          data: { subscribeToDeleteConversationNote: note }
        }
      }
    ) => {
      this.logService.log('[subscribeToDeleteNote] - updateQuery:', { prev, note });

      return deleteNote(prev, note);
    };

    return this.appsyncNoteService.subscribeToDeleteNote(observable, { conversationId }, updateQuery);
  };

  private subscribeToNewUCsByConversation(conversation: Conversation) {
    const realtimeResults = async ({ data: { subscribeToNewUCsByConversation: ucs } }) => {
      this.logService.log('[subscribeToNewUCsByConversation realtimeResults]', ucs);
      const index = _.findIndex(this.conversations, { id: ucs.conversation.id });
      let convo = this.conversations[index];

      if (convo) {
        convo = { ...convo, associated: ucs.associated };
        if (_.get(this.currentConversation, 'id') === convo.id) {
          this.sharedService.setCurrentConversation(convo);
        }
      }
      this.conversations.splice(index, 1, { ...convo, associated: ucs.associated });
      this.sharedService.setConversations(this.conversations);
    };

    return this.appsyncConversationService.subscribeToNewUCsByConversation(conversation.id, { realtimeResults });
  }

  private subscribeToUpdateUserConversations(conversation: Conversation) {
    const realtimeResults = ({ data: { subscribeToUpdateUserConversations: ucs } }) => {
      this.logService.log('[subscribeToUpdateUserConversations realtimeResults] complete', ucs);
      const index = _.findIndex(this.conversations, { id: ucs.conversation.id });
      let convo = this.conversations[index];

      if (convo) {
        convo = { ...convo, ...ucs.conversation, associated: ucs.associated };
        if (_.get(this.currentConversation, 'id') === convo.id) {
          this.sharedService.setCurrentConversation(convo);
        }
      }
    };

    return this.appsyncConversationService.subscribeToUpdateUserConversations(conversation, { realtimeResults });
  }

  private subscribeToReadMessage(conversation: Conversation) {
    const realtimeResults = ({ data: { subscribeToReadMessage: message } }) => {
      this.logService.log('[subscribeToReadMessage realtimeResults]', message);
    };

    return this.appsyncMessageService.subscribeToReadMessage(conversation, { realtimeResults });
  }

  public async escalateConversation(payload) {
    this.logService.log('[ESCALATE]', payload);
    try {
      const response = await this.apiService.transferConversation(payload).toPromise();
      this.unsubscribeConversation(payload.conversationId);
      await this.appsyncConversationService.deleteConversationLocal(payload.conversationId);
      this.logService.log('[ESCALATE Success]', response);
    } catch (error) {
      this.logService.error('[ESCALATE Error]', error);
      throw error;
    }
  }

  public unsubscribeConversation(conversationId) {
    try {
      this.messagesData[conversationId].observedQuery.subscriptionHandles.forEach(s => s.unsubscribe());
      this.notesData[conversationId].observedQuery.subscriptionHandles.forEach(s => s.unsubscribe());
      this.notesData[conversationId].newSubscription();
      this.notesData[conversationId].deleteSubscription();
      this.notesData[conversationId].observableSubscription.unsubscribe();
      this.messagesData[conversationId].observableSubscription.unsubscribe();
      this.conversationSubscriptions[conversationId].forEach(s => s.unsubscribe());
    } catch (e) {
      this.logService.error('[Error unsubscribe]', e);
    }
    this.sharedService.setMessagesData({ ...this.messagesData, [conversationId]: undefined });
    this.sharedService.setNotesData({ ...this.notesData, [conversationId]: undefined });
  }

  public loadMoreConversations(event = null) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    const updateQuery = (prev, options) => {
      const { fetchMoreResult } = options;

      if (!fetchMoreResult) {
        return prev;
      }

      const moreConversations = fetchMoreResult.me.conversations.userConversations.map(
        convertUserConversationToConversation
      );
      this.logService.log(
        '!!![loadMoreConversations]',
        moreConversations,
        this.conversations,
        fetchMoreResult.me.conversations.nextToken
      );

      const _res = addConversations(prev as ConvosQuery, moreConversations, fetchMoreResult.me.conversations.nextToken);
      this.nextToken = fetchMoreResult.me.conversations.nextToken;

      return _res;
    };

    return this.appsyncConversationService.loadMoreConversations(this.observedQuery, this.nextToken, { updateQuery });
  }

  public closeConversation(conversationId, senderId) {
    this.logService.log('closeConversation');
    return this.appsyncConversationService
      .closeConversation(conversationId, senderId)
      .then(({ data }) => {
        this.logService.log('mutation closeConversation complete', data);
        this.unsubscribeConversation(conversationId);
        return data;
      })
      .then(() => this.apiService.closeConversation(conversationId, senderId).toPromise())
      .catch(err => this.logService.log('Error closing conversation', err))
      .then(() => Analytics.record('Close Conversation'));
  }

  public parseMessageContentToString(content): string {
    const span = document.createElement('span');
    span.innerHTML = content;

    let text = span.textContent || span.innerText || '';

    const imgCount = span.getElementsByTagName('img').length;
    const videoCount = span.getElementsByTagName('iframe').length;

    if (imgCount) {
      text += imgCount > 1 ? ` (${imgCount} images have been attached).` : ' (1 image has been attached).';
    }

    if (videoCount) {
      text += videoCount > 1 ? ` (${videoCount} videos have been attached).` : ' (1 video has been attached).';
    }

    return text;
  }
}
