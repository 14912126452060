<div class="review-user-page">
  <ngx-loading [show]="isLoading"></ngx-loading>

  <mat-card class="ctn-card">
    <mat-card-content>
      <h4 class="ctn-h3 ctn-margin">
        <fa-icon [icon]="faUser"></fa-icon>
        {{ 'ADMIN_REVIEW_USER.TITLE' | translate }}
      </h4>

      <hr />

      <section class="d-flex align-items-center">
        <div class="ctn-user-avatar me-2" *ngIf="user?.userData?.avatarUrl || user?.avatarUrl">
          <app-user-template [user]="user" [displayUserName]="false" tooltipField="userData.status">
          </app-user-template>
        </div>

        <app-agent-input-autocomplete
          (selectionChange)="onSelectUser($event)"
          (agentsLoad)="isLoading = false"
          [withTeam]="true"
          [placeholder]="'ADMIN_REVIEW_USER.SELECT_USER_PLACEHOLDER' | translate"
          [placeholderLabel]="'ADMIN_REVIEW_FEEDBACK.SELECT_USER.INPUT_PLACEHOLDER' | translate"
        >
        </app-agent-input-autocomplete>
      </section>

      <app-review-user-details-view [user]="user"></app-review-user-details-view>
    </mat-card-content>
  </mat-card>
</div>
