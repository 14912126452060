import { Inject, Injectable } from '@angular/core';
import { Storage, StorageAccessLevel } from '@aws-amplify/storage';
import { v4 as uuid } from 'uuid';
import * as _ from 'lodash';
import { S3OptionsService } from './s3-options.service';
import { LoggerService } from './logger.service';

@Injectable()
export class S3Service {
  private readonly s3BucketUrl: string;

  private defaultUploadS3Config = {
    key: uuid(),
    level: 'public',
    progressCallback(progress) {
      this.loggerService.info(`Uploaded: ${progress.loaded}/${progress.total}`);
    }
  };

  private defaultGetS3Config = { expires: 60, level: 'public' as StorageAccessLevel };

  constructor(@Inject(S3OptionsService) private options, private loggerService: LoggerService) {
    this.s3BucketUrl = `https://s3.${options.region}.amazonaws.com/${options.bucket}`;
  }

  isBucketLink(url: string): boolean {
    const S3_URL_PATTERN = new RegExp(`^${this.s3BucketUrl}/(public|private|protected)/.*$`);
    return S3_URL_PATTERN.test(url);
  }

  async updateAccessToImageUrls(text: string): Promise<string> {
    const S3_URL_PATTERN = `<img src="${this.s3BucketUrl}/(public|private|protected)/(.*?)">`;

    const matches = [];
    let newText = text;

    _.replace(text, new RegExp(S3_URL_PATTERN, 'g'), (matchStr, level, keyWithParams) => {
      const [key] = keyWithParams.split('?');

      matches.push({ matchStr, key });

      return matchStr;
    });

    await Promise.all(
      matches.map(async ({ key, matchStr }) => {
        const image = await this.getFileByKey(key);
        newText = newText.replace(matchStr, `<img src="${image}">`);
      })
    );

    return newText;
  }

  removeSignFromImageUrls(text: string) {
    const S3_URL_PATTERN = `<img src="(${this.s3BucketUrl}/(public|private|protected)/.*?)">`;

    const newText = _.replace(text, new RegExp(S3_URL_PATTERN, 'g'), (matchStr, url) =>
      matchStr.replace(url, url.split('?')[0])
    );

    return newText;
  }

  removeSignFromUrl(url: string) {
    return url.split('?')[0];
  }

  getFileByKey(key: string, config = this.defaultGetS3Config) {
    return Storage.get(key, config);
  }

  getFileByUrl(url: string, config = this.defaultGetS3Config) {
    const newUrl = this.removeSignFromUrl(url);
    const S3_URL_PATTERN = new RegExp(`^${this.s3BucketUrl}/(public|private|protected)/(.*)$`);

    const [, , key] = newUrl.match(S3_URL_PATTERN);

    return this.getFileByKey(key, config);
  }

  async uploadFile(file, defaultConfig: any = this.defaultUploadS3Config) {
    const config = Object.assign({ contentType: file.type }, defaultConfig);

    const data: any = await Storage.put(config.key, file, config);

    return await this.getFileByKey(data.key, { expires: 60, level: config.level as StorageAccessLevel });
  }
}
