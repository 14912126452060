import gql from 'graphql-tag';

export default gql`
  query getTeams($after: String, $first: Int, $teamLeadId: ID) {
    getTeams(after: $after, first: $first, teamLeadId: $teamLeadId) {
      nextToken
      teams {
        id
        name
        icon
        description
        teamLeadId
        teamLead {
          cognitoId
          avatarUrl
          username
        }
        members {
          cognitoId
          avatarUrl
          username
          teamId
          userData
        }
      }
    }
  }
`;
