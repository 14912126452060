import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as _ from 'lodash';
import { fadeInOut } from '@cation/core/animations';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { IChallengeItem, ISettings } from '@cation/core/types/api';

@Component({
  selector: 'app-challenge-wager-dialog',
  templateUrl: './challenge-wager-dialog.component.html',
  styleUrls: ['./challenge-wager-dialog.component.scss'],
  animations: [fadeInOut],
})
export class ChallengeWagerDialogComponent implements OnInit {
  loaderId = 'challenge-wager-dialog-loader';

  settings: ISettings;
  maxPoints: number;

  voteFormGroup: UntypedFormGroup;
  opponentFormGroup: UntypedFormGroup;
  costFormGroup: UntypedFormGroup;

  public isLoading = false;
  public isCostLoading = false;
  public errorMessage;
  public isUsersLoading = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IChallengeItem,
    public dialogRef: MatDialogRef<ChallengeWagerDialogComponent>,
    private ngxLoader: NgxUiLoaderService,
    private formBuilder: UntypedFormBuilder,
    public authHelper: AuthHelper,
    private apiService: ApiService,
    private s3Service: S3Service,
    private logService: LogService
  ) {}

  async ngOnInit() {
    this.voteFormGroup = this.formBuilder.group({
      vote: [this.data.initiatorId, Validators.required],
    });

    this.opponentFormGroup = this.formBuilder.group({
      opponent: ['', Validators.required],
    });

    this.costFormGroup = this.formBuilder.group({
      points: [0, [Validators.required, Validators.min(1)]],
    });

    this.isCostLoading = true;
    await this.authHelper.loadRewards();
    this.settings = await this.apiService.getGamificationSettings();
    this.maxPoints = _.min([this.settings.maxPointsAllowedInChallenge, this.authHelper.rewards.score || 0]);
    this.costForm.points.setValidators([Validators.required, Validators.min(1), Validators.max(this.maxPoints)]);
    this.isCostLoading = false;
  }

  get voteForm() {
    return this.voteFormGroup.controls;
  }

  get opponentForm() {
    return this.opponentFormGroup.controls;
  }

  get costForm() {
    return this.costFormGroup.controls;
  }

  async onAddChallengeWager() {
    this.clearError();

    const wager = {
      initiatorId: this.authHelper.userProfile.cognitoId,
      opponentId: this.opponentForm.opponent.value.cognitoId,
      cost: this.costForm.points.value,
      challengeId: this.data.id,
      initiatorVoteId: this.voteForm.vote.value,
      opponentVoteId: this.voteForm.vote.value === this.data.initiatorId ? this.data.opponentId : this.data.initiatorId,
    };
    this.logService.log('[ChallengeDialogComponent onAddChallengeWager:challenge]', wager);

    this.ngxLoader.startLoader(this.loaderId);
    try {
      await this.apiService.addGamificationChallengeWager(wager);
      this.dialogRef.close(wager);
    } catch (e) {
      this.logService.error('[ChallengeDialogComponent onAddChallengeWager:error]', e);
      this.errorMessage = e.message;
    }
    this.ngxLoader.stopLoader(this.loaderId);
  }

  clearError() {
    this.errorMessage = null;
  }

  getDescriptionTranslate() {
    const userRef = this.voteForm.vote.value === this.data.initiatorId ? this.data.initiator : this.data.opponent;
    return {
      winner: _.get(userRef, 'userData.nickname', userRef.username),
      username: this.opponentForm.opponent.value.username,
    };
  }
}
