import { Component, ElementRef, AfterViewInit, Renderer2, OnDestroy, ViewChild, } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { unshiftMessages } from '../chat-helper';
import { SharedService } from '../services/shared.service';
import { AppsyncMessageService } from '../services/appsync/appsync-message.service';
import { AppsyncChatService } from '../services/appsync/appsync-chat.service';

@Component({
  selector: 'internal-chat-history',
  templateUrl: './chat-history.component.html',
  styleUrls: ['./chat-history.component.scss']
})
export class ChatHistoryComponent implements AfterViewInit, OnDestroy {
  messages = [];
  chat;
  me;

  nextToken: string | null;
  messagesData;
  completedFetching = false;

  @ViewChild('messagesHistory', { static: true })
  messagesHistory: ElementRef;

  subscriptions: Subscription[] = [];

  constructor(
    private sharedService: SharedService,
    private renderer: Renderer2,
    private appsyncMessageService: AppsyncMessageService,
    private appsyncChatService: AppsyncChatService
  ) {
    this.subscriptions.push(this.sharedService.currentUser.subscribe(user => (this.me = user)));
    this.subscriptions.push(this.sharedService.currentChat.subscribe(chat => (this.chat = chat)));
    this.subscriptions.push(
      this.sharedService.currentChat.subscribe(chat => {
        this.updateMessages(chat.chatId);
      })
    );
    this.subscriptions.push(
      this.sharedService.currentMessagesData.subscribe(() => {
        this.updateMessages(this.chat.chatId);
      })
    );
    this.subscriptions.push(this.sharedService.currentMessagesData.subscribe(data => (this.messagesData = data)));

    this.loadMoreMessages = this.loadMoreMessages.bind(this);
  }

  ngOnDestroy(): void {
    this.subscriptions.map(s => s.unsubscribe());
  }

  private scrollToBottom() {
    this.messagesHistory.nativeElement.scrollTop = this.messagesHistory.nativeElement.scrollHeight;
  }

  ngAfterViewInit() {
    const scrollHeight = this.messagesHistory.nativeElement.scrollHeight;
    const offsetHeight = this.messagesHistory.nativeElement.offsetHeight;

    if (scrollHeight > offsetHeight) {
      this.renderer.setProperty(this.messagesHistory.nativeElement, 'scrollTop', scrollHeight);
    }

    this.messagesHistory.nativeElement.classList.add('internal-chat-history_messages_scroll-smooth');
  }

  public trackItem(index, item) {
    return item ? item.id : undefined;
  }

  updateMessages(chatId) {
    const { messages } = this.sharedService.getMessagesData(chatId);

    if (messages.length) {
      const isScrollBottomAllowed =
        this.messages.length && this.messages[this.messages.length - 1].id !== messages[messages.length - 1].id;

      this.messages = messages;

      if (this.messagesHistory && isScrollBottomAllowed) {
        setTimeout(() => this.scrollToBottom(), 100);
      }

      const lastMessage = messages[messages.length - 1];

      if (lastMessage && lastMessage.senderId !== this.me.id) {
        this.readMessage(lastMessage);
      }
    }
  }

  async readMessage(message) {
    const input = {
      chatId: this.chat.chatId,
      userId: this.chat.userId,
      lastReadMessageId: message.id
    };

    await this.appsyncChatService.updateLastReadMessageId(input, this.chat);
  }

  loadMoreMessages(event) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const { nextToken, observedQuery } = this.messagesData[this.chat.chatId];

    const updateQuery = (prev, options) => {
      const { fetchMoreResult } = options;

      const moreMessages = fetchMoreResult.getMessages.messages;

      const _res = unshiftMessages(prev, moreMessages, fetchMoreResult.getMessages.nextToken);

      this.nextToken = fetchMoreResult.getMessages.nextToken;
      this.completedFetching = false;

      return _res;
    };

    return this.appsyncMessageService.loadMoreMessages(observedQuery, nextToken, { updateQuery });
  }
}
