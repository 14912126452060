import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { IUserConversationStatistics } from '@cation/core/types/api';
import { fadeInOut } from '@cation/core/animations';

@Component({
  selector: 'app-my-statistics',
  templateUrl: './my-statistics.component.html',
  styleUrls: ['./my-statistics.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInOut],
})
export class MyStatisticsComponent implements OnInit {
  @Input() isActive: boolean = false;

  points: object[];
  stars: object[];

  colorScheme = {
    domain: ['rgba(252,217,131,1)', 'rgba(72,143,48,1)'],
  };

  selectedTab = new UntypedFormControl(1);

  statistics: IUserConversationStatistics;
  rangeStatistics: IUserConversationStatistics[];
  rangeStatisticsLastKey: object;

  _statisticsDate = moment().format('YYYY-MM-DD');
  isLoading = false;
  maxDate = moment().format('YYYY-MM-DD');
  dateRange = {
    begin: moment().format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  };
  advancedDate = {
    begin: moment().add(-10, 'days').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  };
  private defaultFilter = {
    startDate: this.dateRange.begin,
    endDate: this.dateRange.end,
  };
  private defaultAdvancedFilter = {
    startDate: this.advancedDate.begin,
    endDate: this.advancedDate.end,
  };
  searchFilter = this.defaultFilter;
  advancedSearchFilter = this.defaultAdvancedFilter;
  filters: string[] = ['Latest', 'On Date', 'Range'];
  selectedFilterType = 'Latest';

  private conversationFeedbackPointsTitle = 'Feedback Points';
  private conversationFeedbackStarsTitle = 'Feedback Stars';
  private conversationPointsTitle = 'Conversation Points';
  private conversationStarsTitle = 'Conversation Stars';

  constructor(private apiService: ApiService, private logService: LogService, public translate: TranslateService) {
    const translateKeys = [
      'MY_STATISTICS.FEEDBACK_POINTS_TITLE',
      'MY_STATISTICS.FEEDBACK_STARS_TITLE',
      'MY_STATISTICS.CONVERSATIONS_POINTS_TITLE',
      'MY_STATISTICS.CONVERSATIONS_STARS_TITLE',
    ];
    this.translate.stream(translateKeys).subscribe((values) => {
      this.conversationFeedbackPointsTitle = values['MY_STATISTICS.FEEDBACK_POINTS_TITLE'];
      this.conversationFeedbackStarsTitle = values['MY_STATISTICS.FEEDBACK_STARS_TITLE'];
      this.conversationPointsTitle = values['MY_STATISTICS.CONVERSATIONS_POINTS_TITLE'];
      this.conversationStarsTitle = values['MY_STATISTICS.CONVERSATIONS_STARS_TITLE'];

      this.setPointsData();
      this.setStarsData();
    });
  }

  ngOnInit() {
    this.getUserConversationStatistics();
    this.getUserConversationStatisticsForRange();
  }

  set statisticsDate(date) {
    this._statisticsDate = moment(date).format('YYYY-MM-DD');
    this.getUserConversationStatistics();
  }

  get statisticsDate() {
    return this._statisticsDate;
  }

  async getUserConversationStatistics() {
    this.isLoading = true;
    try {
      let { startDate, endDate } = this.getFilterDates();
      this.statistics = await this.apiService.getUserConversationStatistics({ startDate, endDate });

      this.logService.log('[MyStatisticsComponent getUserConversationStatistics:statistics]', this.statistics);

      this.setPointsData();
      this.setStarsData();
    } catch (e) {
      this.logService.error('[MyStatisticsComponent getUserConversationStatistics:error]', e);
    }
    this.isLoading = false;
  }

  async getUserConversationStatisticsForRange() {
    this.isLoading = true;
    try {
      const { startDate, endDate } = this.advancedSearchFilter;
      const { items, lastKey } = await this.apiService.getUserConversationStatisticsForRange({ startDate, endDate });

      this.logService.log('[MyStatisticsComponent getUserConversationStatisticsForRange:statistics]', {
        items,
        lastKey,
      });
      this.rangeStatistics = items;
      this.rangeStatisticsLastKey = lastKey;
    } catch (e) {
      this.logService.error('[MyStatisticsComponent getUserConversationStatisticsForRange:error]', e);
    }
    this.isLoading = false;
  }

  setPointsData() {
    this.points = [
      {
        name: this.conversationPointsTitle,
        value: _.get(this.statistics, 'countConversationsPoints', 0),
      },
      {
        name: this.conversationFeedbackPointsTitle,
        value: _.get(this.statistics, 'countConversationFeedbackPoints', 0),
      },
    ];
  }

  setStarsData() {
    this.stars = [
      {
        name: this.conversationStarsTitle,
        value: _.get(this.statistics, 'countConversationsStars', 0),
      },
      {
        name: this.conversationFeedbackStarsTitle,
        value: _.get(this.statistics, 'countConversationFeedbackStars', 0),
      },
    ];
  }

  onDateChange(field: 'dateRange' | 'advancedDate') {
    if (+moment(this[field].begin) > +moment(this[field].end)) {
      return;
    }

    const filter = field === 'dateRange' ? this.searchFilter : this.advancedSearchFilter;
    const defaultFilter = field === 'dateRange' ? this.defaultFilter : this.defaultAdvancedFilter;

    try {
      filter.startDate = moment(this[field].begin).format('YYYY-MM-DD');
      filter.endDate = moment(this[field].end).format('YYYY-MM-DD');

      if (field === 'dateRange') {
        this.getUserConversationStatistics();
      } else {
        this.getUserConversationStatisticsForRange();
      }
    } catch (e) {
      filter.startDate = defaultFilter.startDate;
      filter.endDate = defaultFilter.endDate;
    }
  }

  getFilterDates() {
    if (this.selectedFilterType === 'Latest') {
      return { startDate: moment().format('YYYY-MM-DD'), endDate: '' };
    }
    if (this.selectedFilterType === 'On Date') {
      return { startDate: this.statisticsDate, endDate: '' };
    }
    if (this.selectedFilterType === 'Range') {
      return this.searchFilter;
    }

    return undefined;
  }
}
