export const enumToArray = enumObject => Object.keys(enumObject).map(key => enumObject[key]);

export const parseJSON = (item: string) => {
  try {
    return JSON.parse(item);
  } catch (e) {
    return false;
  }
};

export const getI18nLocaleByLocale = locale => {
  const formatted = {
    de: 'de',
    en: 'en',
    es: 'es',
    fr: 'fr',
    it: 'it',
    pl: 'pl',
    en_IE: 'en',
    en_GB: 'en',
    it_IT: 'it',
    es_ES: 'es',
    fr_FR: 'fr',
    de_DE: 'de',
    pl_PL: 'pl'
  };

  return formatted[locale] || 'en';
};

export const getLocaleByI18nLocale = locale => {
  const formatted = {
    de: 'de_DE',
    en: 'en_IE',
    es: 'es_ES',
    fr: 'fr_FR',
    it: 'it_IT',
    pl: 'pl_PL',
    en_IE: 'en_IE',
    en_GB: 'en_GB',
    it_IT: 'it_IT',
    es_ES: 'es_ES',
    fr_FR: 'fr_FR',
    de_DE: 'de_DE',
    pl_PL: 'pl_PL'
  };

  return formatted[locale] || 'en_IE';
};

export const convertUserConversationToConversation = userConversation => ({
  ...userConversation.conversation,
  author: userConversation.conversation.author
    ? {
        ...userConversation.conversation.author,
        userData: parseJSON(userConversation.conversation.author.userData) || {}
      }
    : {},
  history: parseJSON(userConversation.conversation.history) || [],
  location: parseJSON(userConversation.conversation.location) || {},
  associated: userConversation.associated
});

export const prepareConversation = conversation => ({
  ...conversation,
  author: conversation.author
    ? {
        ...conversation.author,
        userData: parseJSON(conversation.author.userData) || {}
      }
    : {},
  history: typeof conversation.history === 'string' ? parseJSON(conversation.history) : conversation.history,
  location: parseJSON(conversation.location) || {},
  mailData: parseJSON(conversation.mailData) || {}
});

export const prepareUserProfile = profile => ({
  ...profile,
  cmsUserData: (profile.cmsUserData || []).map(parseJSON),
  userData: parseJSON(profile.userData) || {}
});

export const decodeMessage = content => {
  try {
    if (content.match(/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/)) {
      return decodeURIMessage(atob(content));
    }
    return content;
  } catch (e) {
    return content;
  }
};

export const decodeURIMessage = content => {
  try {
    return decodeURIComponent(content);
  } catch (e) {
    return content;
  }
};
