import { trigger, transition, style, animate, query, stagger, state } from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]),
  transition(':leave', [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))])
]);

export const listFadeInOut = trigger('listFadeInOut', [
  transition('* <=> *', [
    query(':enter', [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))], {
      optional: true
    }),
    query(':leave', animate('200ms', style({ opacity: 0 })), { optional: true })
  ])
]);

export const openClose = trigger('openClose', [
  state(':enter', style({ height: '*', opacity: 1 })),
  state(':leave', style({ height: '0', opacity: 0 })),
  transition('open => closed', [animate('0.35s')]),
  transition('closed => open', [animate('0.35s')])
]);
