import gql from 'graphql-tag';

export default gql`
  subscription subscribeToUsers {
    subscribeToUsers {
      __typename
      id
      name
      avatarUrl
      lastActiveTime
    }
  }
`;
