<div class="mb-2 clearfix w-100">
  <div class="float-start chat-message" [class.float-end]="fromMe" (dblclick)="onDoubleClick()">
    <ngx-ui-loader [fgsSize]="25" [loaderId]="loaderId"></ngx-ui-loader>
    <div class="clearfix">
      <h6 *ngIf="!isHideMessageAuthor" class="mb-1 float-start text-muted">
        <small>
          {{ user?.username | translate }}
        </small>
      </h6>
      <div [ngClass]="{ 'float-end ms-5': fromMe || !isHideMessageAuthor, 'me-5': !fromMe && isHideMessageAuthor }">
        <small class="card-subtitle mb-0 text-muted">
          <ng-container *ngIf="message.isSent">
            {{ createdAt | momentAgo }}
          </ng-container>
          <ng-container *ngIf="!message.isSent">
            {{ 'CONVERSATION_VIEW.SENDING_ACTION' | translate }}
          </ng-container>
        </small>
      </div>
    </div>
    <div
      class="message-wrapper"
      [class.message-from-me]="fromMe"
      [ngStyle]="!fromMe && !fromClient ? { backgroundColor: message?.sender | pickColor } : {}"
    >
      <pre
        class="card-text mb-0 message-content"
        (click)="handleLink($event)"
        [class.select-text]="!fromHistory"
        [innerHTML]="content || '' | trustHtml"
      ></pre>
      <div *ngIf="message.attachment" class="attachment pt-2">
        <div class="d-flex w-100 align-items-center">
          <h6 class="d-inline-block attachment-name pe-1 m-0">{{ message.attachment.name }}</h6>
          <button *ngIf="canPreview" class="btn btn-outline-primary btn-sm p-0 px-1 save-btn" (click)="openFile()">
            <mat-icon [matTooltip]="'CONVERSATION_VIEW.VIEW_FILE_BUTTON_TOOLTIP' | translate">visibility</mat-icon>
          </button>
          <button
            class="btn btn-outline-primary btn-sm p-0 px-1 save-btn"
            [disabled]="isAttachmentLoading"
            (click)="saveFile()"
          >
            <mat-icon [matTooltip]="'CONVERSATION_VIEW.DOWNLOAD_FILE_BUTTON_TOOLTIP' | translate">
              cloud_download
            </mat-icon>
          </button>
        </div>
        <mat-progress-bar *ngIf="isAttachmentLoading" mode="buffer"></mat-progress-bar>
      </div>
    </div>
  </div>
</div>
