import * as moment from 'moment';
import { FeedbackCommentsDataModel } from './models/feedback-comments-date-range.model';
import { FeedbackCommentsFilterOptionEnum } from './enums/feedback-comments-filter-option.enum';

export const feedbackCommentsFilterOptionsMap = new Map<FeedbackCommentsFilterOptionEnum, FeedbackCommentsDataModel>([
  [FeedbackCommentsFilterOptionEnum.all, null],
  [
    FeedbackCommentsFilterOptionEnum.today,
    {
      createdAt: {
        from: moment
          .utc()
          .startOf('day')
          .format(),
        to: moment
          .utc()
          .endOf('day')
          .format()
      }
    }
  ],
  [
    FeedbackCommentsFilterOptionEnum.thisMonth,
    {
      createdAt: {
        from: moment
          .utc()
          .startOf('month')
          .format(),
        to: moment
          .utc()
          .endOf('month')
          .format()
      }
    }
  ]
]);
