<div class="ai-view card rounded">
  <ngx-ui-loader [fgsSize]="25" [loaderId]="loaderId"></ngx-ui-loader>
  <mat-tab-group [selectedIndex]="selectedTab.value" (selectedIndexChange)="selectedTab.setValue($event)">
    <mat-tab [label]="'AI_BLOCK.AI_ADVICE_LABEL' | translate">
      <div class="card-body p-0" (dblclick)="copyToClipboard()">
        <div class="ai-response p-2">
          <div [innerHTML]="searchResult?.message | trustHtml"></div>
          <div *ngIf="!!searchResult?.responseCard" class="attachments-list">
            <div *ngFor="let attachment of searchResult.responseCard?.genericAttachments" class="list-group mb-2">
              <a
                *ngIf="attachment.title"
                [href]="attachment.attachmentLinkUrl"
                target="_blank"
                class="me-1 mt-1 btn btn-outline-primary btn-sm"
              >
                <img [src]="attachment.imageUrl" [alt]="attachment.title" width="20" height="20" />
                {{ attachment.title }}
              </a>
              <div *ngIf="!!attachment?.buttons" class="m-0">
                <button
                  type="button"
                  class="me-1 mt-1 btn btn-outline-primary btn-sm"
                  *ngFor="let button of attachment.buttons"
                  (click)="getLexAnswer(button.value)"
                >
                  {{ button.text }}
                </button>
              </div>
            </div>
          </div>
          <div class="notification" [class.notification__visible]="showNotification">
            {{ 'AI_BLOCK.COPIED_TO_CLIPBOARD_NOTIFICATION' | translate }}
          </div>
        </div>
      </div>

      <div class="card-footer p-0 rounded">
        <div class="bg-white d-flex rounded">
          <input
            #inputElement
            type="text"
            class="input"
            (focus)="isInputOnFocus = true"
            (blur)="onBlur()"
            [placeholder]="inputPlaceholder"
            [required]="true"
            [formControl]="aiSearchConditionCtrl"
            (keyup.enter)="getLexAnswer()"
            (keyup.escape)="isShowAutocomplete = false"
          />
          <button mat-icon-button color="primary" (click)="getLexAnswer()">
            <mat-icon>search</mat-icon>
          </button>
        </div>
      </div>

      <div
        class="ai-autocomplete"
        *ngIf="isInputOnFocus && isShowAutocomplete && aiSearchConditionCtrl.value && utterances.length"
        [@listFadeInOut]="utterances.length"
      >
        <div class="ai-autocomplete__top-border"></div>
        <div class="ai-autocomplete__item" *ngFor="let item of utterances" (click)="getLexAnswer(item.utterance)">
          <span>{{ item.utterance }}</span>
        </div>
      </div>
    </mat-tab>
    <mat-tab [label]="'AI_BLOCK.SIMILAR_CASES_LABEL' | translate">
      <div class="card-body p-0" (dblclick)="copyToClipboard()">
        <div class="ai-response p-0">
          <mat-accordion [multi]="true">
            <mat-expansion-panel *ngFor="let item of similarCases | keyvalue" [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title [matTooltip]="getKeywords(item.value)">
                  <button
                    *ngIf="item.key !== _conversation.id"
                    mat-icon-button
                    color="primary"
                    class="p-0 m-1"
                    (click)="openCustomerHistory(item.key, $event)"
                  >
                    <mat-icon [matTooltip]="'BUTTON.VIEW' | translate">pageview</mat-icon>
                  </button>
                  {{ getKeywords(item.value) }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div *ngFor="let value of item.value" class="card mb-2">
                <div
                  class="card-body p-2 border rounded"
                  [class.border-primary]="value.metricType === 'AGENT_MESSAGE'"
                  [class.border-success]="value.metricType === 'USER_MESSAGE'"
                >
                  <div class="clearfix">
                    <div class="d-flex justify-content-center align-items-center mb-1 float-start">
                      <div
                        *ngIf="
                          value.metricType === 'AGENT_MESSAGE' &&
                          (value?.agent?.userData?.avatarUrl || value?.agent?.avatarUrl)
                        "
                        class="agent-avatar me-2"
                      >
                        <app-user-template
                          class="icon-20"
                          [user]="value?.agent"
                          [displayUserName]="false"
                          tooltipField="userData.status"
                        >
                        </app-user-template>
                      </div>

                      <small class="text-primary">
                        {{ value.metricType === 'AGENT_MESSAGE' ? value?.agent?.username : value?.customer?.username }}
                      </small>
                    </div>
                    <div class="float-end">
                      <small class="card-subtitle mb-0 text-muted">{{ value.createdAt | momentAgo }}</small>
                    </div>
                  </div>
                  <p class="card-text message-content mb-0" [innerHTML]="value.message || '' | trustHtml"></p>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>

      <div class="card-footer p-0 rounded">
        <div class="bg-white d-flex rounded">
          <div class="filter-actions-panel" [class.filter-actions__exists]="isFilterExist()">
            <button mat-icon-button color="primary" [matTooltip]="filtersTooltip" (click)="openBottomSheetFilter()">
              <mat-icon [matBadge]="isFilterExist() ? '*' : ''" matBadgeSize="small" matBadgeColor="accent">
                filter_list
              </mat-icon>
            </button>
            <button
              *ngIf="isFilterExist()"
              mat-icon-button
              color="primary"
              [matTooltip]="'BUTTON.CLEAR' | translate"
              (click)="clearFilter()"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
          <input
            #inputElement
            type="text"
            class="input"
            [class.input__with-filter]="isFilterExist()"
            [placeholder]="inputPlaceholder"
            [(ngModel)]="searchCondition"
            (keyup.enter)="getSimilarCases()"
          />
          <button mat-icon-button color="primary" (click)="getSimilarCases()">
            <mat-icon>send</mat-icon>
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
