import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import * as moment from 'moment';
import { LogService } from '@cation/core/services/log/log.service';
import { getUsersConversationQueueHistoryQuery as QueueHistoryQuery } from '@cation/core/graphql/operation-result-types';
import { addConversationQueueHistories, updateConversationQueueHistory } from '@cation/core/util/chat-helper';
import { AppsyncConversationQueueService } from '@cation/core/services/appsync/appsync-conversation-queue.service';
import { parseJSON } from '@cation/core/util/common-util';
import { LocaleUtil } from '@cation/core/util/locale-util';
import { classChannelIcon } from '@cation/core/enums/channel.enum';
import { mapSentimentToIcon } from '@cation/core/constants/sentiments';
import { listFadeInOut } from '@cation/core/animations';
import User from '@cation/core/types/user';
import ConversationQueueHistory from '@cation/core/types/conversationQueueHistory';

@Component({
  selector: 'app-queue-history',
  templateUrl: './queue-history.component.html',
  styleUrls: ['./queue-history.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [listFadeInOut],
})
export class QueueHistoryComponent implements OnInit, OnDestroy {
  public user: User;

  isLoading = false;

  _history: (ConversationQueueHistory & { waitingTime: string })[] = [];
  nextToken;
  observedQuery;
  subscription: () => void;

  classIcon = classChannelIcon;
  mapSentimentToIcon = mapSentimentToIcon;

  waitingTimeSubscription: Subscription;
  everySecond: Observable<number> = timer(0, 1000);

  constructor(
    private appsyncConversationQueueService: AppsyncConversationQueueService,
    private logService: LogService,
    public localeUtil: LocaleUtil
  ) {}

  async ngOnInit() {
    this.waitingTimeSubscription = this.everySecond.subscribe(() => (this.history = this._history));
  }

  ngOnDestroy(): void {}

  set history(history) {
    this._history = history;
  }

  get history() {
    return this._history;
  }

  public trackItem(index, item: ConversationQueueHistory) {
    return item ? `${item.userId}-${item.conversationId}-${item.startedAt}` : undefined;
  }

  private async getUsersConversationQueueHistory() {
    const observerOrNext = async ({ data }) => {
      this.logService.log('[getUsersConversationQueueHistory data]', data);
      if (!data || !data.getUsersConversationQueueHistory) {
        return this.logService.log('[getUsersConversationQueueHistory: no data]');
      }

      this.history = data.getUsersConversationQueueHistory.items.map((item) => this.prepareQueueHistoryItem(item));
      this.nextToken = data.getUsersConversationQueueHistory.nextToken;
      this.logService.log('[getUsersConversationQueueHistory conversations]', this.history);
    };

    const updateQuery = (prev: QueueHistoryQuery, { subscriptionData }) => {
      const {
        data: { subscribeToSaveConversationQueueHistory: conv },
      } = subscriptionData;
      this.logService.log('[subscribeToSaveConversationQueueHistory updateQuery]', conv);

      return updateConversationQueueHistory(prev, conv);
    };

    const data = await this.appsyncConversationQueueService.getUsersConversationQueueHistory(
      this.user.cognitoId,
      observerOrNext,
      updateQuery
    );
    this.observedQuery = data.observedQuery;
    this.subscription = data.subscription;
  }

  public loadMoreUsersConversationQueueHistory(event = null) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    const updateQuery = (prev, options) => {
      const { fetchMoreResult } = options;

      if (!fetchMoreResult) {
        return prev;
      }

      const moreConversations = fetchMoreResult.getUsersConversationQueueHistory.items.map((item) =>
        this.prepareQueueHistoryItem(item)
      );
      const nextToken = fetchMoreResult.getUsersConversationQueueHistory.nextToken;
      this.logService.log('[loadMoreUsersConversationQueueHistory]', moreConversations);

      const _res = addConversationQueueHistories(prev as QueueHistoryQuery, moreConversations, nextToken);
      this.nextToken = fetchMoreResult.getUsersConversationQueueHistory.nextToken;

      return _res;
    };

    return this.appsyncConversationQueueService.loadMoreUsersConversationQueueHistory(
      this.observedQuery,
      this.nextToken,
      { updateQuery }
    );
  }

  async onSelectUser(selectedUser) {
    this.logService.log('onSelectUser', selectedUser);

    if (this.subscription) {
      this.subscription();
    }

    this.history = [];
    this.nextToken = null;
    this.observedQuery = null;
    this.user = selectedUser;

    if (this.user) {
      this.getUsersConversationQueueHistory();
    }
  }

  prepareQueueHistoryItem(item: ConversationQueueHistory) {
    return Object.assign(
      {
        ...item,
        waitingTime: moment
          .utc(
            moment
              .duration(moment(+item.finishedAt || Date.now()).diff(+item.startedAt), 'millisecond')
              .asMilliseconds()
          )
          .format('HH:mm:ss'),
      },
      item.agent
        ? {
            agent: {
              ...item.agent,
              userData: parseJSON(item.agent.userData) || {},
            },
          }
        : {},
      item.conversation
        ? {
            conversation: {
              ...item.conversation,
              location: parseJSON(item.conversation.location) || {},
            },
          }
        : {}
    );
  }
}
