import gql from 'graphql-tag';

export default gql`
  query getUserChatsConnectionThroughUser($after: String, $first: Int) {
    me {
      id
      __typename
      chats(first: $first, after: $after) {
        __typename
        nextToken
        userChats {
          __typename
          userId
          chatId
          associated {
            __typename
            chatId
            userId
            user {
              __typename
              id
              name
              avatarUrl
            }
            typing
            connected
            lastReadMessageId
          }
          chat {
            __typename
            id
            name
            createdAt
            createdBy
            author {
              id
              name
            }
          }
        }
      }
    }
  }
`;
