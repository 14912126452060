import { Component, EventEmitter } from '@angular/core';
import { ModalOverlayDataModel } from '@cation/core/models/overlays';

@Component({
  selector: 'app-base-overlay',
  template: ''
})
export class BaseModalOverlayComponent {
  public data: ModalOverlayDataModel | null = null;
  public confirmEvent: EventEmitter<boolean> = new EventEmitter();
}
