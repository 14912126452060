import gql from 'graphql-tag';

export default gql`
  query getAllUsers {
    allUser {
      __typename
      cognitoId
      username
      userData
      keywords
      locales
      roles
      avatarUrl
    }
  }
`;
