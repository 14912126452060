import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpResponse, HttpInterceptor, HttpHandler } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';

import { RequestCache } from '@cation/core/services/request-cache/request-cache.service';
import { LogService } from '@cation/core/services/log/log.service';
import { environment } from '@cation/env/environment';

/**
 * If request is cacheable (e.g., package search) and
 * response is in cache return the cached response as observable.
 * If has 'x-refresh' header that is true,
 * then also re-run the package search, using response from next(),
 * returning an observable that emits the cached response first.
 *
 * If not in cache or not cacheable,
 * pass request through to next()
 */
@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCache, private logService: LogService) {
    this.logService.info('-------CachingInterceptor-------');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // console.log('CachingInterceptor:intercept');

    // continue if not cacheable.
    if (!isCacheable(req)) {
      this.logService.debug('[CachingInterceptor Not Cacheable]', req.url);
      return next.handle(req);
    }

    // console.log('Cacheable:', req.url);

    const cachedResponse = this.cache.get(req);

    // console.log('cachedResponse:', cachedResponse);

    // cache-then-refresh
    if (req.headers.get('x-refresh')) {
      console.log('x-refresh');
      const results$ = sendRequest(req, next, this.cache);
      return cachedResponse ? results$.pipe(startWith(cachedResponse)) : results$;
    }
    // cache-or-fetch
    return cachedResponse ? of(cachedResponse) : sendRequest(req, next, this.cache);
  }
}

/** Is this request cacheable? */
function isCacheable(req: HttpRequest<any>) {
  if (req.method === 'GET') {
    const cacheApi = environment.cacheApi;
    for (const path of cacheApi) {
      if (req.url.indexOf(environment.apiUrl + path) > -1) {
        return true;
      }
    }
  }

  return undefined;
}

/**
 * Get server response observable by sending request to `next()`.
 * Will add the response to the cache on the way out.
 */
function sendRequest(req: HttpRequest<any>, next: HttpHandler, cache: RequestCache): Observable<HttpEvent<any>> {
  console.log('*** sendRequest ***', req.url);

  return next.handle(req).pipe(
    tap((event) => {
      // There may be other events besides the response.
      if (event instanceof HttpResponse) {
        // console.log('adding to cache', req, event);
        cache.put(req, event); // Update the cache.
      }
    })
  );
}
