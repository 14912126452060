import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';

@Component({
  selector: 'app-transfers-list-table',
  templateUrl: './transfers-list-table.component.html',
  styleUrls: ['./transfers-list-table.component.scss']
})
export class TransfersListTableComponent implements OnInit {
  public displayedColumns = ['user', 'next user', 'description', 'created at'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  dataSource = new MatTableDataSource([]);

  constructor() {}

  @Input()
  set transfers(items) {
    this.dataSource = new MatTableDataSource(_.orderBy(items, 'createdAt', 'desc'));
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }
}
