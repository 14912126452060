import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AdvancedPieChartComponent } from '@swimlane/ngx-charts';
import * as moment from 'moment';
import { IUserConversationStatistics } from '@cation/core/types/api';
import { LocaleUtil } from '@cation/core/util/locale-util';
import { enumToArray } from '@cation/core/util/common-util';
import { Channel } from '@cation/core/enums/channel.enum';
import { chartColorSchemas } from '@cation/core/constants/chart-color-schema';

@Component({
  selector: 'app-single-total-conversations-chart',
  templateUrl: './single-total-conversations-chart.component.html',
  styleUrls: ['./single-total-conversations-chart.component.scss']
})
export class SingleTotalConversationsChartComponent implements OnInit {
  @Input() metric: 'channel' | 'locale' | 'stars' = 'channel';
  @Input() metricField: 'countConversations' | 'countConversationFeedback' | 'conversationsDuration' =
    'countConversations';
  @Input() prepareValue: (statistics: IUserConversationStatistics, item: string) => number;

  single: object[] = [];
  gradient: boolean = true;
  colorScheme = { domain: chartColorSchemas['green-red'] };

  private channels = enumToArray(Channel);
  private locales = ['en_IE', 'en_GB', 'it_IT', 'es_ES', 'fr_FR', 'de_DE', 'pl_PL'];
  private stars = [5, 4, 3, 2, 1];
  private _statistics: IUserConversationStatistics;

  @Input() set colorSchemaName(name: 'green-red' | 'blue' | 'material500') {
    this.colorScheme = { domain: chartColorSchemas[name] };
  }

  @Input() set statistics(statistics: IUserConversationStatistics) {
    if (!statistics) {
      this.single = [];
      return;
    }

    this._statistics = statistics;

    if (this.metric === 'stars') {
      this.single = this.generateStarsMetric();
      return;
    }

    const metricItems = this.metric === 'channel' ? this.channels : this.locales;

    const prepareValue =
      this.prepareValue || ((statistics, item: string) => statistics[`${this.metricField}${item}`] || 0);

    this.single = metricItems.reduce((res, item) => {
      const name = this.metric === 'locale' ? this.localeUtil.getName(item) : item;
      res.push({ name, value: prepareValue(statistics, item) });
      return res;
    }, []);
  }

  @ViewChild('chart', { static: true }) chart: AdvancedPieChartComponent;

  constructor(private localeUtil: LocaleUtil, private translate: TranslateService) {}

  ngOnInit() {
    this.translate.onLangChange.subscribe(() => {
      this.chart.update();

      if (this.metric !== 'locale') {
        return;
      }
      this.statistics = this._statistics;
    });
  }

  onValueFormat = value => {
    if (this.metricField !== 'conversationsDuration') {
      return value;
    }

    return !value
      ? '0'
      : moment
          .duration(value, 'second')
          .locale(this.translate.currentLang)
          .humanize();
  };

  generateStarsMetric() {
    return this.stars.reduce((res, item) => {
      res.push({
        name: `${item} ⭐️`,
        value: this._statistics[`${this.metricField}${item}Stars`] || 0
      });
      return res;
    }, []);
  }
}
