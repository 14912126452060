import gql from 'graphql-tag';

export default gql`
  mutation deleteConversationCms($input: ConversationDeleteCmsInput!) {
    deleteConversationCms(input: $input) {
      __typename
      conversationId
      createdAt
      id
      isCore
      type
    }
  }
`;
