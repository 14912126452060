import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Auth } from '@aws-amplify/auth';
import { TranslateService } from '@ngx-translate/core';
import { Dictionary } from 'lodash';
import * as _ from 'lodash';
import { AppsyncUserService } from '@cation/core/services/appsync/appsync-user.service';
import { AppSyncApiService } from '@cation/core/services/api/appsync-api.service';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { prepareUserProfile } from '@cation/core/util/common-util';
import { CMS_TYPE } from '@cation/core/enums/cms-type.enum';
import { IChallengeMember } from '@cation/core/types/api';
import User from '@cation/core/types/user';
import { Role } from './role.enum';

type IUser = User & { cmsUserData: { type: CMS_TYPE; userId: string }[] };

@Injectable({
  providedIn: 'root',
})
export class AuthHelper implements OnInit, OnDestroy {
  public signIn$: Subject<any> = new Subject<any>();
  public signOut$: Subject<any> = new Subject<any>();
  public isAuthenticated$: Subject<any> = new BehaviorSubject<boolean>(false);

  private updateActiveTimeInterval;
  private cognitoIdToken;

  private idToken = { sub: '' };
  private role: Role[];

  private emptyUser: IUser = {
    cognitoId: 'cognitoId',
    username: 'username',
    registered: 'true',
    userData: {},
    cmsUserData: [],
    avatarUrl: null,
    keywords: [],
    locales: [],
    roles: [],
    teamId: 'None',
  };

  public currentUserProfile$ = new BehaviorSubject<IUser>(this.emptyUser);

  private _cmsProfiles = {};
  private _rewards: IChallengeMember;

  public isRegistered = false;
  private isAuthProcess = false;
  private isOnline = false;

  private mapRoleToDescription: { [prop in Role]: string } = {
    [Role.GUEST]: 'Guest',
    [Role.ADMIN]: 'Administrator',
    [Role.AGENT]: 'Agent',
    [Role.CONTENT_ADMIN]: 'Content Administrator',
    [Role.REPORTS_ADMIN]: 'Report Administrator',
    [Role.TEAM_LEAD]: 'Team Lead',
  };

  constructor(
    private api: ApiService,
    private logService: LogService,
    private sharedService: SharedService,
    private translate: TranslateService,
    private appSyncApiService: AppSyncApiService,
    private appsyncUserService: AppsyncUserService
  ) {
    this.sharedService.currentOnlineStatus.subscribe((isOnline) => {
      this.isOnline = isOnline;
      if (!isOnline) {
        this.updateActiveTime(true);
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.translate
      .stream(['ROLE.GUEST', 'ROLE.ADMIN', 'ROLE.AGENT', 'ROLE.CONTENT_ADMIN', 'ROLE.REPORTS_ADMIN', 'ROLE.UNKNOWN'])
      .subscribe((values: Dictionary<string>) => {
        _.map(values, (value, key) => {
          this.mapRoleToDescription[key.substring(5) as Role] = value;
        });
      });
  }

  ngOnDestroy(): void {
    clearInterval(this.updateActiveTimeInterval);
  }

  async init(session?) {
    console.log('### INIT ####');
    session = session || (await Auth.currentSession().catch((err) => console.log('Ignoreing error', err)));

    console.log('AuthHelper:Session', session);

    if (session && session.isValid()) {
      this.cognitoIdToken = session.getIdToken();
      // this.cognitoAccessToken = session.getAccessToken();

      this.idToken = session.getIdToken().decodePayload();
      console.log('Decoded idToken', this.idToken);

      if (this.idToken['cognito:groups']) {
        const roles = [];
        this.idToken['cognito:groups'].forEach((r) => {
          roles.push(Role[r as keyof typeof Role]);
        });
        this.role = roles;
      } else {
        this.role = [Role.GUEST];
      }

      console.log('this.idToken.sub', this.idToken.sub);
      console.log('this.userProfile.cognitoId', this.userProfile.cognitoId);
      if (this.idToken.sub !== this.userProfile.cognitoId) {
        this.currentUserProfile$.next({
          cognitoId: this.idToken.sub,
          username: this.idToken['cognito:username'],
          registered: 'true',
          userData: {},
          cmsUserData: [],
          avatarUrl: null,
          keywords: [],
          locales: [],
          roles: [],
          teamId: 'None',
        });
        return this.setAuthData();
      }

      this.isAuthenticated$.next(true);
      return Promise.resolve();
    } else {
      this.role = null;
      this.isAuthenticated$.next(false);
      return Promise.resolve();
    }
  }

  clear() {
    this.cognitoIdToken = undefined;
    this.idToken = undefined;
    this.role = undefined;
  }

  getIdToken() {
    return this.cognitoIdToken;
  }

  getUserRole() {
    return this.role;
  }

  isUserInRole(inRole: Role[]) {
    if (!this.role) {
      return false;
    }
    for (const role of inRole) {
      if (this.role.includes(role)) {
        return true;
      }
    }

    return undefined;
  }

  isAuthenticated() {
    console.log('isAuthenticated', this.role && this.role.length > 0);
    return this.role && this.role.length > 0;
  }

  isGuest() {
    return this.role && this.role.includes(Role.GUEST);
  }

  isAgent() {
    return this.role && this.role.includes(Role.AGENT);
  }

  isAdmin() {
    return this.role && this.role.includes(Role.ADMIN);
  }

  isContentAdmin() {
    return this.role && this.role.includes(Role.CONTENT_ADMIN);
  }

  isReportAdmin() {
    return this.role && this.role.includes(Role.REPORTS_ADMIN);
  }

  isTeamLead() {
    return this.role && this.role.includes(Role.TEAM_LEAD);
  }

  getDisplayName() {
    // return this.idToken['name'];
    return this.idToken['cognito:username'];
  }

  getDisplayRole() {
    if (!this.role) {
      return this.getRoleDescription(Role.GUEST);
    }

    return this.role.map((r) => this.getRoleDescription(r)).join(', ');
  }

  getRoleDescription(role: Role) {
    return this.mapRoleToDescription[role] || 'Unknown role';
  }

  setUserProfile(profile, isSilent = false) {
    const data = prepareUserProfile(profile);

    if (_.isEqual(data, this.userProfile)) {
      return;
    }

    this.isRegistered = true;
    this.currentUserProfile$.next(data);

    if (!isSilent) {
      this.loadCmsProfiles();
      this.loadRewards();
    }
  }

  get userProfile() {
    return this.currentUserProfile$.value;
  }

  get cmsProfiles() {
    return this._cmsProfiles;
  }

  get rewards() {
    return this._rewards;
  }

  async loadCmsProfiles() {
    for (const cms of this.userProfile.cmsUserData) {
      const { type, userId } = cms as any;

      try {
        const user = await this.api.getCmsProfile(userId, type).toPromise();

        this.logService.groupInfo(
          `[loadCmsProfiles ${type} ${userId}]`,
          `%cSuccess`,
          'color: green; font-weight: bold;',
          user
        );

        this._cmsProfiles[type] = user;
      } catch (error) {
        this.logService.groupError(
          `[loadCmsProfiles ${type} ${userId}]`,
          `%cError`,
          'color: red; font-weight: bold;',
          error
        );
      }
    }
  }

  async loadRewards() {
    try {
      this._rewards = await this.api.getRewards();
    } catch (error) {
      this.logService.error('[AuthHelper loadRewards:error]', error);
    }
  }

  setAuthData() {
    if (this.isAuthProcess) {
      return undefined;
    }

    this.isAuthProcess = true;

    return Auth.currentSession()
      .then(async (session) => {
        this.logInfoToConsole(session);
        await this.register();
        await this.checkUser();
        this.updateActiveTimeInterval = setInterval(() => this.updateActiveTime(), 10000);
        this.isAuthenticated$.next(true);
      })
      .catch((e) => e)
      .then(() => (this.isAuthProcess = false));
  }

  private logInfoToConsole(session) {
    this.logService.log(`ID Token: <${session.idToken.jwtToken}>`);
    this.logService.log(`Access Token: <${session.accessToken.jwtToken}>`);
    this.logService.log('Decoded ID Token:');
    this.logService.log(JSON.stringify(session.idToken.payload, null, 2));
    this.logService.log('Decoded Acess Token:');
    this.logService.log(JSON.stringify(session.accessToken.payload, null, 2));
  }

  private register() {
    const onSubscribe = ({ data }) => {
      this.logService.log('register user, fetch cache', data);
      this.setUserProfile(data.me);
    };
    return this.appsyncUserService.registerUser(onSubscribe);
  }

  private async checkUser() {
    const { data } = await this.appsyncUserService.checkUser();

    this.logService.log('getMe - Got data', data);

    this.setUserProfile(data.me);
    return data.me;
  }

  public updateActiveTime(goOffline = false) {
    if (!this.isOnline && !goOffline) {
      return;
    }
    const lastActiveTime = goOffline ? Math.floor(Date.now() / 1000) - 30 : undefined;
    this.appSyncApiService
      .updateActiveTime({ lastActiveTime })
      .catch((err) => this.logService.error('[updateActiveTime Error]', err));
  }
}
