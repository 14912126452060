import gql from 'graphql-tag';

export default gql`
  query getUserNotifications($userId: ID!, $after: String, $first: Int) {
    getUserNotifications(userId: $userId, after: $after, first: $first) {
      __typename
      nextToken
      userNotifications {
        __typename
        id
        userId
        isRead
        icon
        title
        type
        data
        description
      }
    }
  }
`;
