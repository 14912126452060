import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import * as _ from 'lodash';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { fadeInOut, listFadeInOut } from '@cation/core/animations';
import { IChallengeItem, IChallengeWagerItem } from '@cation/core/types/api';
import { SnackBarErrorComponent } from '@cation/core/components/snack-bar-error/snack-bar-error.component';
import { ChallengeWagerDialogComponent } from '../challenge-wager-dialog/challenge-wager-dialog.component';
import { MatSelectChange } from '@angular/material/select';
import { mapChallengeStatusToIcon } from '../constants/chalenge';

interface IChallengeWagersData {
  [tab: string]: {
    items: IChallengeWagerItem[];
    lastKey: object;
    isLoading: boolean;
  };
}

@Component({
  selector: 'app-challenge-wagers-list',
  templateUrl: './challenge-wagers-list.component.html',
  styleUrls: ['./challenge-wagers-list.component.scss'],
  animations: [fadeInOut, listFadeInOut],
})
export class ChallengeWagersListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('challengeWagerList', { static: true }) challengeWagerList;
  @ViewChild('loading') loading: ElementRef;

  Object = Object;
  Date = Date;
  errorMessage;
  mapChallengeStatusToIcon = mapChallengeStatusToIcon;

  tabs = ['PROPOSALS', 'ACTIVE', 'AVAILABLE', 'HISTORY'];
  selectedTab = 'PROPOSALS';
  wagers: IChallengeWagersData = _.reduce(
    this.tabs,
    (res, tab) => ({
      ...res,
      [tab]: { items: [], lastKey: null, isLoading: false },
    }),
    {}
  );

  isLoadingChallenges = false;
  challengesLastKey;
  challenges: IChallengeItem[];

  @Input() autoRefresh: boolean = false;
  @Input() showChallenges: boolean = true;

  private interval: NodeJS.Timer;
  private loadIntersectionObserver: IntersectionObserver;

  constructor(
    private apiService: ApiService,
    private logService: LogService,
    public authHelper: AuthHelper,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.getAllGamificationChallengeWagers();
  }

  ngAfterViewInit(): void {
    this.createLoadIntersection();
    this.initLoadIntersection();
  }

  ngOnDestroy() {
    this.destroyInterval();
    this.disconnectLoadIntersection();
  }

  trackItem(index, item) {
    return item ? item.id : undefined;
  }

  getDateFromId(id) {
    return id.split('_')[0];
  }

  startInterval() {
    if (!this.interval) {
      this.interval = setInterval(() => this.autoRefresh && this.getAllGamificationChallengeWagers(true), 10000);
    }
  }

  destroyInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  public getAllGamificationChallengeWagers(isSilent = false) {
    this.destroyInterval();
    this.tabs.filter((t) => t !== 'AVAILABLE').map((tab) => this.getGamificationChallengeWagers(tab, false, isSilent));
    if (this.showChallenges) {
      this.getGamificationChallenges(false, isSilent);
    }
    this.startInterval();
  }

  getOptionsByTab(tab, isLoadMore) {
    const startKey = isLoadMore ? this.wagers[tab].lastKey : null;
    const options = {
      startKey: JSON.stringify(startKey),
      userId: this.authHelper.userProfile.cognitoId,
    };
    if (tab === 'HISTORY') {
      return Object.assign(options, {
        statuses: ['FINISHED', 'EXPIRED', 'REJECTED'],
      });
    }
    if (tab === 'ACTIVE') {
      return Object.assign(options, { statuses: ['ACCEPTED'] });
    }
    if (tab === 'PROPOSALS') {
      return Object.assign(options, {
        statuses: ['PENDING'],
      });
    }

    return undefined;
  }

  async getGamificationChallengeWagers(tab: string, isLoadMore = false, isSilent = false) {
    if (!isSilent) {
      this.wagers[tab].isLoading = true;
    }

    try {
      const options = this.getOptionsByTab(tab, isLoadMore);

      const { items, lastKey } = await this.apiService.findGamificationChallengeWagers(options);

      this.wagers[tab].items = _.orderBy(
        isLoadMore ? this.wagers[tab].items.concat(items) : items,
        (i) => i.id.split('_')[0],
        'desc'
      );
      this.wagers[tab].lastKey = lastKey;

      this.logService.log('[getGamificationChallengeWagers]', { tab, items, lastKey });
    } catch (e) {
      this.errorMessage = e.message;
      this.logService.error('[getGamificationChallengeWagers Error]', e);
    }

    if (!isSilent) {
      this.wagers[tab].isLoading = false;
    }
  }

  async getGamificationChallenges(isLoadMore = false, isSilent = false) {
    if (!isSilent) {
      this.isLoadingChallenges = true;
    }

    try {
      const startKey = isLoadMore ? this.challengesLastKey : null;
      const options = {
        startKey: JSON.stringify(startKey),
        statuses: ['ACCEPTED'],
        notStarted: true,
        excludeUserId: this.authHelper.userProfile.cognitoId,
      };

      const { items, lastKey } = await this.apiService.findGamificationChallenges(options);

      this.challenges = _.orderBy(
        isLoadMore ? this.challenges.concat(items) : items,
        (i) => i.id.split('_')[0],
        'desc'
      );
      this.challengesLastKey = lastKey;

      this.logService.log('[getGamificationChallenges]', { tab: 'AVAILABLE', items, lastKey });
    } catch (e) {
      this.errorMessage = e.message;
      this.logService.error('[getGamificationChallenges Error]', e);
    }

    if (!isSilent) {
      this.isLoadingChallenges = false;
    }
  }

  toMakeWager(index: number) {
    this.logService.debug('[ChallengeWagersListComponent toMakeWager]');
    const dialog = this.dialog.open(ChallengeWagerDialogComponent, {
      data: this.challenges[index],
    });

    dialog.afterClosed().subscribe(async (result) => {
      this.logService.log('[toMakeWager result]', result);
      if (result) {
        this.getAllGamificationChallengeWagers();
      }
    });
  }

  getChallengeTypeTranslateContext({ startDate, finishDate, data }) {
    return {
      startDate: moment(startDate).format('DD MMM HH:mm'),
      finishDate: moment(finishDate).format('DD MMM HH:mm'),
      amount: (data || {}).amount,
    };
  }

  async changeChallengeWagerStatus(tab: string, id: string, challengeId: string, status: string) {
    this.wagers[tab].isLoading = true;
    try {
      await this.apiService.changeGamificationChallengeWagerStatus(id, { status, challengeId });
      await Promise.all([this.getAllGamificationChallengeWagers(), this.authHelper.loadRewards()]);
    } catch (e) {
      this.logService.error('[changeChallengeWagerStatus]', e);
      this.showErrorMessage(e);
    }
    this.wagers[tab].isLoading = false;
  }

  showErrorMessage(e) {
    const message = e.error && e.error.errorCode ? `ERROR_MESSAGE.${e.error.errorCode}` : e.message;

    this.snackBar.openFromComponent(SnackBarErrorComponent, {
      duration: 5000,
      data: { message },
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: ['notification__error'],
    });
  }

  onTabChange($event: MatSelectChange) {
    this.challengeWagerList.nativeElement.scrollTop = 0;
    this.selectedTab = $event.value;
  }

  private createLoadIntersection() {
    const options = {
      root: this.challengeWagerList.nativeElement,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (this.isIntersecting(entry) && this.wagers[this.selectedTab].lastKey) {
          this.getGamificationChallengeWagers(this.selectedTab, true);
        }
      });
    };

    this.loadIntersectionObserver = new IntersectionObserver(callback, options);
  }

  private initLoadIntersection() {
    this.loadIntersectionObserver.observe(this.loading.nativeElement);
  }

  private disconnectLoadIntersection() {
    if (this.loadIntersectionObserver) {
      this.loadIntersectionObserver.disconnect();
    }
  }

  private isIntersecting(entry: IntersectionObserverEntry): boolean {
    return entry.isIntersecting || entry.intersectionRatio > 0;
  }
}
