import gql from 'graphql-tag';

export default gql`
  query getConversationsInQueue($after: String, $first: Int) {
    getConversationsInQueue(after: $after, first: $first) {
      __typename
      nextToken
      conversations {
        __typename
        id
        intentsTriggered
        name
        caseId
        createdAt
        createdBy
        history
        locale
        location
        sentiment
        keywords
        status
        channel
        lastQuestion
        author {
          cognitoId
          username
          userData
        }
      }
    }
  }
`;
