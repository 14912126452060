<div style="display: grid;" class="w-100 h-100">
  <ngx-charts-line-chart
    [scheme]="colorScheme"
    [legend]="legend"
    [legendTitle]="'MY_STATISTICS.LEGEND_TITLE' | translate"
    [xAxis]="xAxis"
    [yAxis]="yAxis"
    [yAxisTickFormatting]="onValueFormat"
    [curve]="curve"
    [timeline]="timeline"
    [results]="multi"
    [rotateXAxisTicks]="true"
  >
    <ng-template #tooltipTemplate let-model="model">
      <div class="d-flex flex-column p-2">
        <span>{{ onValueFormat(model.value) }}</span>
      </div>
    </ng-template>
    <ng-template #seriesTooltipTemplate let-model="model">
      <div class="d-flex flex-column p-2">
        <span>{{ onValueFormat(model[0].value) }}</span>
      </div>
    </ng-template>
  </ngx-charts-line-chart>
</div>
