import { Component, ViewChild, ViewEncapsulation, ElementRef } from '@angular/core';
import { v4 as uuid } from 'uuid';
import { SharedService } from '../services/shared.service';
import { AppsyncMessageService } from '../services/appsync/appsync-message.service';
import { LoggerService } from '../services/logger.service';
import { S3Service } from '../services/s3.service';

@Component({
  selector: 'internal-message-input',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MessageInputComponent {
  inputValue = '';
  emojiBarState = false;

  attachment?: { id: string; key: string; file: File };
  isUploading = false;
  progressPercent;

  @ViewChild('inputElement', { static: true }) inputElement;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  constructor(
    private sharedService: SharedService,
    private appsyncMessageService: AppsyncMessageService,
    private loggerService: LoggerService,
    private s3Service: S3Service
  ) {
    this.insertEmoji = this.insertEmoji.bind(this);
    this.sharedService.currentEmojiBarState.subscribe(state => (this.emojiBarState = state));
    this.sharedService.onSelectEmoji.subscribe(this.insertEmoji);
  }

  toggleEmojiBar($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.sharedService.setCurrentEmojiBarState(!this.emojiBarState);
  }

  insertEmoji(event) {
    event.$event.stopPropagation();

    const target = this.inputElement.nativeElement;
    if (target.setRangeText) {
      target.setRangeText(event.emoji.native);
      this.inputValue = target.value;
    } else {
      target.focus();
      document.execCommand('insertText', true /*no UI*/, event.emoji.native);
    }
    this.sharedService.setCurrentEmojiBarState(false);
  }

  async sendMessage() {
    const messageText = (this.inputValue || '').trim();

    if (messageText || this.attachment) {
      this.inputValue = '';

      const createdAt = new Date().toISOString();

      const message = Object.assign(
        {
          chatId: this.sharedService.openedChat.chatId,
          content: messageText,
          createdAt: Date.now(),
          id: `${createdAt}_${uuid()}`,
          senderId: this.sharedService.userProfile.id
        },
        this.attachment ? { attachmentId: this.attachment.id } : {}
      );

      this.loggerService.info('[MessageInputComponent new message]', message);

      const attachment = await this.createAttachment();

      const newMessage = await this.appsyncMessageService.createNewMessage(
        this.sharedService.openedChat.chatId,
        message,
        attachment
      );

      this.attachment = undefined;

      this.loggerService.info('[New Message is]', newMessage);
    }
  }

  async createAttachment() {
    if (!this.attachment) {
      return;
    }
    const { id, key, file } = this.attachment;
    const attachment = await this.appsyncMessageService.createAttachment({
      chatId: this.sharedService.openedChat.chatId,
      id,
      key,
      name: file.name,
      size: file.size,
      type: file.type
    });
    this.loggerService.info('[createAttachment:attachment]', attachment);
  }

  async onChangeAttachment(files: FileList) {
    this.loggerService.warn('[MessageInputComponent onChangeAttachment:files]', files);

    const file = files[0];

    if (!file) {
      return;
    }

    this.isUploading = true;
    this.progressPercent = 0;

    const id = uuid();
    const key = `internal-chat/${this.sharedService.openedChat.chatId}/${id}`;
    const config = {
      key,
      contentType: file.type,
      level: 'public',
      progressCallback: progress => {
        this.progressPercent = Math.floor((progress.loaded * 100) / progress.total);
      }
    };

    try {
      const res = await this.s3Service.uploadFile(file, config);
      this.loggerService.info('Storage.put:url', res);
      this.attachment = { id, key, file };
    } catch (e) {
      this.loggerService.error('Storage.put:error', e);
    }

    setTimeout(() => (this.isUploading = false), 1000);
  }

  onAddAttachment($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.click();
  }

  onDeleteAttachment($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.attachment = undefined;
  }
}
