import gql from 'graphql-tag';

export default gql`
  query getDirectChat($firstUserId: ID!, $secondUserId: ID!) {
    getDirectChat(firstUserId: $firstUserId, secondUserId: $secondUserId) {
      __typename
      firstUserId
      secondUserId
      chatId
      userChat {
        __typename
        userId
        chatId
        associated {
          __typename
          chatId
          userId
          user {
            __typename
            id
            name
            avatarUrl
          }
          typing
          connected
          lastReadMessageId
        }
        chat {
          __typename
          id
          name
          createdAt
          createdBy
          author {
            id
            name
          }
        }
      }
    }
  }
`;
