import gql from 'graphql-tag';

export default gql`
  subscription subscribeToNewMessageByUser($conversationId: ID!, $sender: ID) {
    subscribeToNewMessageByUser(conversationId: $conversationId, sender: $sender) {
      __typename
      conversationId
      attachment {
        key
        type
        size
        name
      }
      createdAt
      id
      sender
      content
      isSent
      isRead
    }
  }
`;
