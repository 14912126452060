import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Storage } from '@aws-amplify/storage';
import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { LogService } from '@cation/core/services/log/log.service';
import { mapTypeToAttachmentTypes } from '@cation/core/enums/attachment-type.enum';
import { AppsyncService } from '@cation/core/services/appsync/appsync.service';
import { SharedService } from '@cation/core/services/shared/shared.service';
import { PreviewAttachmentDialogComponent } from '@cation/core/components/preview-attachment-dialog/preview-attachment-dialog.component';
import Conversation from '@cation/core/types/conversation';
import Message from '@cation/core/types/message';
import User from '@cation/core/types/user';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatMessageComponent implements AfterViewInit, OnInit, OnChanges {
  loaderId = `chat-message-loader-${uuidv4()}`;

  @Input() conversation: Conversation;
  @Input() senderId: string;
  @Input() index: number;
  @Input() message: Message;
  @Input() fromMe: boolean;
  @Input() fromHistory: boolean = false;
  @Input() fromBot: boolean;
  @Input() isLast = false;
  @Input() isFirst = false;
  @Input() isHideMessageAuthor = false;
  @Output() added: EventEmitter<Message> = new EventEmitter();

  user: User;
  createdAt;
  content = '';
  isAttachmentLoading = false;

  canPreview = false;
  fromClient: boolean = false;

  constructor(
    private appsync: AppsyncService,
    private logService: LogService,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private s3Service: S3Service,
    private ngxLoader: NgxUiLoaderService,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    if (this.message.attachment) {
      this.canPreview = mapTypeToAttachmentTypes[this.message.attachment.type];
    }

    let { sender, content } = this.message;

    if (content && content.startsWith('COMMAND:')) {
      const commandPayload = content.split(':');
      const commandData = JSON.parse(atob(commandPayload[2]));
      console.log('commandData', commandData);

      switch (commandData.command) {
        case 'START_VIDEO_CHAT':
          console.log('START_VIDEO_CHAT');
          content = 'Start video chat';
          break;
        case 'END_VIDEO_CHAT':
          console.log('END_VIDEO_CHAT');
          content = 'End video chat';
          break;
      }
    }

    this.fromClient = sender === this.conversation.createdBy;

    this.content = await this.s3Service.updateAccessToImageUrls(content);

    const defaultUserData = {
      avatarUrl: '',
      cognitoId: sender,
      id: sender,
      registered: 'false',
      userData: {},
      cmsUserData: [],
      keywords: [],
      locales: [],
      roles: []
    };

    if (this.fromBot) {
      this.user = { ...defaultUserData, username: 'MESSAGE_AUTHOR_NAME.BOT' };

      this.cdr.detectChanges();

      return;
    }

    const associated = (this.conversation.associated || []).find(u => u.userId === this.message.sender);

    const username = this.fromMe ? 'MESSAGE_AUTHOR_NAME.AGENT' : 'MESSAGE_AUTHOR_NAME.USER';

    this.user = associated ? associated.user : { ...defaultUserData, username };

    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'message') {
        const chng = changes[propName];
        if (chng.currentValue.createdAt.includes('_')) {
          this.createdAt = chng.currentValue.createdAt.split('_')[0];
        } else {
          this.createdAt = chng.currentValue.createdAt;
        }
        this.cdr.detectChanges();
      }
    }
  }

  ngAfterViewInit() {
    if (!this.isFirst && !this.isLast) {
      return;
    }
    this.logService.log('message: ngAfterViewInit: ', this.message.id, this.isFirst, this.isLast);
    this.added.emit(this.message);
  }

  onDoubleClick() {
    this.logService.log('onDoubleClick', this.message);
    this.sharedService.searchAi(this.message.content);
  }

  async saveFile() {
    this.isAttachmentLoading = true;
    try {
      const { key, name } = this.message.attachment;

      const url: string = (await Storage.get(key, { expires: 60, level: 'public' })) as string;

      const response = await fetch(url);
      const blob = await response.blob();

      this.logService.info('[saveFile loaded]', { url, response, blob });

      saveAs(blob, name);
    } catch (error) {
      this.logService.error('[saveFile error]', error);
    }
    this.isAttachmentLoading = false;
  }

  openFile() {
    this.logService.info('[openFile]');
    const dialog = this.dialog.open(PreviewAttachmentDialogComponent, {
      height: '80%',
      width: '80%',
      data: this.message.attachment
    });

    dialog.afterClosed().subscribe(async result => {
      this.logService.log('[onOpenPreviewAttachmentDialog result]', result);
    });
  }

  handleLoader = status => {
    if (status) {
      return this.ngxLoader.startLoader(this.loaderId);
    }
    return this.ngxLoader.stopLoader(this.loaderId);
  };

  async handleLink($event) {
    await this.s3Service.handleLink($event, this.handleLoader);
  }
}
