import { Component, Input, OnInit } from '@angular/core';

import routes from '../routes';
import { User } from '../types';

@Component({
  selector: 'internal-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class InternalUserInfoComponent implements OnInit {
  @Input() user: User;
  @Input() route: string;
  @Input() checked: boolean;
  @Input() unreadMessages: string;

  isOnline: boolean = false;

  _routes = routes;

  ngOnInit() {
    if (this.user.lastActiveTime) {
      const timeDiff = (Date.now() / 1000 - this.user.lastActiveTime) / 60; // transform timeDiff from ms to min
      if (timeDiff < 15) this.isOnline = true; // Offline, if last activity was 15 or more minutes ago
    }
  }
}
