import { Component, Input } from '@angular/core';
import { listFadeInOut } from '@cation/core/animations';

interface ICapability {
  icon: string;
  name: string;
  isAvailable: boolean;
}

@Component({
  selector: 'app-capabilities-list',
  templateUrl: './capabilities-list.component.html',
  styleUrls: ['./capabilities-list.component.scss'],
  animations: [listFadeInOut]
})
export class CapabilitiesListComponent {
  @Input() capabilities: ICapability[];
  @Input() withReverse: boolean = false;
  @Input() noMargin: boolean = false;
  @Input() withTranslate: boolean = true;

  constructor() {}
}
