import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { FetchMoreOptions, FetchMoreQueryOptions } from 'apollo-client';
import { AppsyncOperationService } from './appsync-operation.service';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import * as subscriptions from '../../graphql/subscriptions';
import { escapeStr, constants, unshiftMessage } from '../../chat-helper';
import { getChatMessagesQuery as MessagesQuery } from '../../graphql/operation-result-types';
import { Attachment, Message, Chat } from '../../types';

@Injectable()
export class AppsyncMessageService extends AppsyncOperationService {
  getMessages(chat: Chat, observerOrNext, updateQuery, onError = error => {}) {
    const watchQueryOptions = {
      query: queries.getMessages,
      variables: { chatId: chat.id, first: constants.messageFirst }
    };

    const watchQuerySubscriptionOptions = { observerOrNext };

    const subscribeToMoreOptions = _.merge(
      {
        onError: (...args) =>
          this.loggerService.error('[AppsyncMessageService getMessages:subscribeToNewMessages]', args)
      },
      {
        document: subscriptions.subscribeToNewMessages,
        variables: { chatId: chat.id },
        updateQuery
      }
    );

    return this.loadAndSubscribeMore<MessagesQuery>({
      watchQueryOptions,
      watchQuerySubscriptionOptions,
      subscribeToMoreOptions
    });
  }

  loadMoreMessages(observedQuery, nextToken: string, fetchMoreOptions) {
    return this.loadMore(observedQuery, nextToken, fetchMoreOptions);
  }

  async createNewMessage(chatId, message, attachment = null): Promise<Message | undefined> {
    this.loggerService.info('[AppsyncMessageService createNewMessage]', { chatId, message, attachment });

    const input = {
      ...message,
      content: escapeStr(message.content)
    };

    const {
      data: { createMessage }
    } = await this.mutate<{ createMessage: Message }>({
      mutation: mutations.createMessage,
      variables: { input },

      optimisticResponse: () => ({
        createMessage: {
          ...message,
          attachmentId: input.attachmentId || null,
          attachment: attachment,
          __typename: 'Message'
        }
      }),

      update: (proxy, { data: { createMessage: _message } }) => {
        const _options = {
          query: queries.getMessages,
          variables: { chatId, first: constants.messageFirst }
        };

        const prev = proxy.readQuery<MessagesQuery>(_options);
        const data = unshiftMessage(prev, {
          ..._message,
          __typename: 'Message'
        });

        proxy.writeQuery({ ..._options, data });
      }
    });

    return createMessage;
  }

  async createAttachment(input): Promise<Attachment | undefined> {
    this.loggerService.info('[AppsyncMessageService createAttachment]', input);

    const {
      data: { createAttachment }
    } = await this.mutate<{ createAttachment: Attachment }>({
      mutation: mutations.createAttachment,
      variables: { input },

      optimisticResponse: () => ({
        createAttachment: {
          ...input,
          __typename: 'Attachment'
        }
      })
    });

    return createAttachment;
  }
}
