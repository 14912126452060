import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { SharedService } from '@cation/core/services/shared/shared.service';
import Conversation from '@cation/core/types/conversation';
import Message from '@cation/core/types/message';

@Component({
  selector: 'ctn-snack-bar-message',
  templateUrl: './snack-bar-message.component.html',
  styleUrls: ['./snack-bar-message.component.scss']
})
export class SnackBarMessageComponent {
  conversation: Conversation;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Message, private sharedService: SharedService) {
    this.sharedService.currentConversations.subscribe(conversations => {
      this.conversation = conversations.find(c => c.id === this.data.conversationId);
    });
  }
}
