import { Component } from '@angular/core';
import { SharedService } from '../services/shared.service';
import { RouteService } from '../services/route.service';
import { AppsyncChatService } from '../services/appsync/appsync-chat.service';
import { addChats } from '../chat-helper';
import routes from '../routes';

@Component({
  selector: 'internal-chats-list',
  templateUrl: './chats-list.component.html',
  styleUrls: ['./chats-list.component.scss']
})
export class ChatsListComponent {
  chats = [];
  nextToken: string | null;
  chatsData;
  completedFetching = false;
  countUnreadMessages;

  constructor(
    private sharedService: SharedService,
    private routeService: RouteService,
    private appsyncChatService: AppsyncChatService
  ) {
    this.sharedService.currentUserChats.subscribe(value => (this.chats = value));
    this.sharedService.currentChatsData.subscribe(data => (this.chatsData = data));
    this.sharedService.countUnreadMessages.subscribe(count => (this.countUnreadMessages = count));
    this.loadMoreChats = this.loadMoreChats.bind(this);
  }

  selectChat($event: MouseEvent, chat) {
    $event.preventDefault();
    $event.stopPropagation();

    this.sharedService.setCurrentChat(chat);
    this.routeService.setRoute(routes.CHAT);
  }

  loadMoreChats(event = null) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const { nextToken, observedQuery } = this.chatsData;

    const updateQuery = (prev, options) => {
      const { fetchMoreResult } = options;

      if (!fetchMoreResult) {
        return prev;
      }

      const moreChats = fetchMoreResult.me.chats.userChats;

      const _res = addChats(prev, moreChats, fetchMoreResult.me.chats.nextToken);
      this.nextToken = fetchMoreResult.me.chats.nextToken;
      this.completedFetching = false;

      return _res;
    };

    return this.appsyncChatService.loadMoreChats(observedQuery, nextToken, { updateQuery });
  }
}
