import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@cation/core/dialogs/confirmation-dialog/confirmation-dialog.component';
import { DialogValuesEnums } from '@cation/core/dialogs/enums/dialog-values.enum';
import { PromptDialogComponent } from '@cation/core/dialogs/prompt-dialog/prompt-dialog.component';
import { Locale as LocaleConst } from '@cation/core/enums/locale';
import { ApiHook } from '@cation/core/services/api/api-hook';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import { S3Service } from '@cation/core/services/s3/s3.service';
import { v4 as uuid } from 'uuid';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ctn-manage-canned-responses-dialog',
  templateUrl: './manage-canned-responses-dialog.component.html',
  styleUrls: ['./manage-canned-responses-dialog.component.scss'],
})
export class ManageCannedResponsesDialogComponent implements OnInit {
  @ViewChild('fileUploadInput') fileUploadInput: ElementRef;

  public Locale = [{ code: 'en', name: 'English - Default' }].concat(LocaleConst);
  public literals;
  public selectedLiteral;
  public selectedLocale = this.Locale[0];
  public formType;

  private updatedLiterals = new Set();
  private deletedLiterals = new Set();

  public quillModules = {
    toolbar: {
      container: [
        [
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'code-block',
          { header: 1 },
          { header: 2 },
          { list: 'ordered' },
          { list: 'bullet' },
          { script: 'sub' },
          { script: 'super' },
          { indent: '-1' },
          { indent: '+1' },
          { direction: 'rtl' },
          { size: ['small', false, 'large', 'huge'] },
          { header: [1, 2, 3, 4, 5, 6, false] },
          { color: [] },
          { background: [] },
          { font: [] },
          { align: [] },
          'clean',
          'link',
          'image',
          'video',
          'file',
        ],
      ],
      handlers: { file: () => {} },
    },
  };
  private quillInstance;
  uploadPercent = 100;

  constructor(
    private api: ApiService,
    public apiHook: ApiHook,
    private dialog: MatDialog,
    private logService: LogService,
    private s3Service: S3Service,
    public dialogRef: MatDialogRef<ManageCannedResponsesDialogComponent>
  ) {}

  ngOnInit() {
    // REASON: to avoid ExpressionChangedAfterItHasBeenCheckedError when dialog opens over an elements,
    // which contains apiHook.isLoading() - dependent components
    setTimeout(() => this.getCannedResponses());
  }

  async getCannedResponses() {
    this.logService.log('getCannedResponses');
    const data: any = await this.api.getCannedResponses().toPromise();
    this.logService.log('getCannedResponses:data', data);
    this.literals = data.Items;
    this.selectedLiteral = this.literals[0];
    this.onSelectionChange();
  }

  localeSuffix(name, locale) {
    return locale.code === 'en' ? name : name + '_' + locale.code;
  }

  addLiteralKey() {
    const dialogRef = this.dialog.open(PromptDialogComponent, {
      data: {
        title: 'ADMIN_MANAGE_CANNED_RESPONSE.ADD_DIALOG.TITLE',
        message: 'ADMIN_MANAGE_CANNED_RESPONSE.ADD_DIALOG.MESSAGE',
        placeholder: 'ADMIN_MANAGE_CANNED_RESPONSE.ADD_DIALOG.PLACEHOLDER',
      },
    });

    dialogRef.afterClosed().subscribe((value) => {
      this.logService.log('prompt:value', value);
      if (value) {
        this.selectedLiteral = { cannedResponseId: uuid(), title: value, hashtag: value, response: '' };
        this.literals.push(this.selectedLiteral);
      }
    });
  }

  editTitle() {
    const dialogRef = this.dialog.open(PromptDialogComponent, {
      data: {
        title: 'ADMIN_MANAGE_CANNED_RESPONSE.EDIT_DIALOG.TITLE',
        message: 'ADMIN_MANAGE_CANNED_RESPONSE.EDIT_DIALOG.MESSAGE',
        placeholder: 'ADMIN_MANAGE_CANNED_RESPONSE.EDIT_DIALOG.PLACEHOLDER',
        value: this.selectedLiteral.title,
      },
    });

    dialogRef.afterClosed().subscribe((value) => {
      this.logService.log('prompt:value', value);
      if (value) {
        this.selectedLiteral.title = value;
        this.updatedLiterals.add(this.selectedLiteral);
      }
    });
  }

  deleteLiteralKey() {
    const index = this.literals.indexOf(this.selectedLiteral);

    this.deletedLiterals.add(this.literals[index]);
    if (this.updatedLiterals.has(this.literals[index])) {
      this.updatedLiterals.delete(this.literals[index]);
    }

    this.literals.splice(index, 1);
    this.selectedLiteral = this.literals[0];
  }

  /** Adding this tracker to avoid ngFor reload everytime when key press event of text area */
  literalTracker(index: number, obj: any): any {
    return index;
  }

  addLiteralValue() {
    this.logService.log('addLiteralValue');

    const literal = this.localeSuffix('literal', this.selectedLocale);

    if (!this.selectedLiteral[literal]) {
      this.logService.log('There are no items; Adding a new one');
      this.selectedLiteral[literal] = [''];
    } else if (this.selectedLiteral[literal][this.selectedLiteral[literal].length - 1] !== '') {
      this.logService.log('Last one is not empty; adding new empty literal');
      this.selectedLiteral[literal].push('');
    } else {
      this.logService.log('Last one is already empty; Not adding new literal');
    }
  }

  onLiteralUpdate() {
    this.updatedLiterals.add(this.selectedLiteral);
  }

  async onSelectionChange() {
    this.selectedLiteral[this.localeSuffix('response', this.selectedLocale)] =
      await this.s3Service.updateAccessToImageUrls(
        this.selectedLiteral[this.localeSuffix('response', this.selectedLocale)],
        false
      );
  }

  async onSaveLiterals() {
    if (this.updatedLiterals.size > 0) {
      this.logService.log('put', Array.from(this.updatedLiterals.values()));
      const items = Array.from(this.updatedLiterals.values()).map((item) => {
        const next = item;
        Object.keys(item)
          .filter((k) => k.startsWith('response'))
          .map((k) => (next[k] = this.s3Service.removeSignFromImageUrls(item[k])));
        return next;
      });

      const data: any = await this.api.putCannedresponses(items).toPromise();
      this.logService.log('putCannedresponses:data', data);

      this.updatedLiterals.clear();
      this.dialogRef.close();
    }

    if (this.deletedLiterals.size > 0) {
      this.logService.log('delete', Array.from(this.deletedLiterals.values()));

      const data: any = await this.api.deleteCannedresponses(Array.from(this.deletedLiterals.values())).toPromise();
      this.logService.log('deleteCannedresponses:data', data);

      this.deletedLiterals.clear();
      this.dialogRef.close();
    }
  }

  onClose($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    if (this.updatedLiterals.size > 0 || this.deletedLiterals.size > 0) {
      this.showConfirmationDialog();
    } else {
      this.dialogRef.close();
    }
  }

  getUploadImageConfig = () => {
    return Promise.resolve({
      key: `img/canned-response/${this.selectedLiteral.cannedResponseId}/${uuid()}`,
      level: 'public',
      progressCallback: (progress) => (this.uploadPercent = Math.floor((progress.loaded * 100) / progress.total)),
    });
  };

  getUploadFileConfig = () => {
    return Promise.resolve({
      key: `file/canned-response/${this.selectedLiteral.cannedResponseId}/${uuid()}`,
      level: 'public',
      progressCallback: (progress) => (this.uploadPercent = Math.floor((progress.loaded * 100) / progress.total)),
    });
  };

  onQuillEditorCreated(quillInstance) {
    this.logService.log('onQuillEditorCreated');
    this.quillInstance = quillInstance;

    const uploadImageHandler = this.s3Service.makeQuillImageHandler(this.quillInstance, this.getUploadImageConfig);
    this.quillInstance.getModule('toolbar').addHandler('image', uploadImageHandler);

    const uploadFileHandler = this.s3Service.makeQuillFileHandler(this.quillInstance, this.getUploadFileConfig);
    this.quillInstance.getModule('toolbar').addHandler('file', uploadFileHandler);

    const button = this.quillInstance.getModule('toolbar').container.querySelector('button.ql-file');

    button.style = `${button.style}; padding: 0;`;
    const attachFileIcon = document.getElementById('input_attach_file_icon');
    button.appendChild(attachFileIcon);
  }

  public onImagePaste(e) {
    this.s3Service.uploadImageFromBuffer(e, this.quillInstance, this.getUploadImageConfig);
  }

  private showConfirmationDialog() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'CONFIRMATION_MESSAGE.UNSAVED_CHANGES_TITLE',
        message: 'CONFIRMATION_MESSAGE.UNSAVED_CHANGES_MESSAGE',
      },
    });

    dialogRef.afterClosed().subscribe((value) => {
      if (value === DialogValuesEnums.ok) {
        this.dialogRef.close();
      }
    });
  }
}
