import gql from 'graphql-tag';

export default gql`
  mutation closeConversation($id: ID!, $userId: ID) {
    closeConversation(id: $id, userId: $userId) {
      __typename
      id
      name
      subject
      createdBy
      author {
        cognitoId
        username
        userData
      }
      status
      keywords
      lastQuestion
      intentsTriggered
      botSessionId
    }
  }
`;
