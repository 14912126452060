import gql from 'graphql-tag';

export default gql`
  subscription subscribeToDeleteConversationNote($conversationId: ID!) {
    subscribeToDeleteConversationNote(conversationId: $conversationId) {
      __typename
      id
      conversationId
      createdBy
      content
      author {
        cognitoId
        username
        userData
      }
    }
  }
`;
