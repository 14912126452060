<div class="internal-users-list">
  <ul>
    <li *ngFor="let user of users; trackBy: trackItem">
      <internal-user-info
        [user]="user"
        (click)="onUserInfoClick($event, user)"
        [route]="route"
        [checked]="checkUserStatus(user.id)"
        [unreadMessages]="findUnreadMessagesCounter(user)"
      >
      </internal-user-info>
    </li>
  </ul>
  <p *ngIf="!users.length" class="empty-result">No results found</p>
</div>

<div class="ctn-actions">
  <action-button
    *ngIf="route === _routes.CREATE_CHAT"
    class="btn-start"
    [type]="'button'"
    [label]="'Start'"
    (clickEvent)="startNewChat()"
  >
  </action-button>

  <div class="ctn-search">
    <mat-icon>search</mat-icon>
    <form>
      <input
        matInput
        autocomplete="off"
        type="search"
        name="search-name"
        aria-label="Search input"
        placeholder="Search"
        [(ngModel)]="searchName"
        (ngModelChange)="onSearchNameChange($event)"
      />
    </form>
  </div>
</div>
