import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-statistics-total-button',
  templateUrl: './statistics-total-button.component.html',
  styleUrls: ['./statistics-total-button.component.scss']
})
export class StatisticsTotalButtonComponent implements OnInit {
  @Input() label: string;
  @Input() matIcon: string;
  @Input() value: string;
  @Input() disable = false;
  @Input() active = false;

  @Output() onClick = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  clickEvent($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.onClick.emit();
  }
}
