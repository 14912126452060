import { NavItemTypeEnum } from '../enums/nav-item-type.enum';
import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'internal-nav-item',
  templateUrl: './nav-item.component.html',
  styleUrls: ['./nav-item.component.scss']
})
export class InternalNavItemComponent {
  @Input() itemType: string;
  @Input() isActive: boolean;

  @Output() onClick = new EventEmitter();

  itemTypeEnum = NavItemTypeEnum;

  onClickEvent($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    this.onClick.emit();
  }
}
