<nav class="navbar navbar-expand-md no-select">
  <a class="navbar-brand" routerLink="/">
    <ng-template [ngIf]="urlParserService.isParlyEngage">
      <img class="logo" src="assets/img/logo-with-name.svg" alt="parly engage" width="170px" />
    </ng-template>
    <ng-template [ngIf]="urlParserService.isEngageHub">
      <img class="logo" src="assets/img/logo-with-name.svg" alt="parly engage" height="32px" />
    </ng-template>
  </a>

  <div class="navbar-nav-container">
    <!-- Menu -->
    <ul class="navbar-nav navbar-menu">
      <li [routerLinkActive]="['active']" *ngIf="auth.isAgent()">
        <a class="nav-link position-relative" routerLink="/chat">
          <span>{{ 'NAVBAR.CHAT_LINK' | translate }}</span>
          <span class="sr-only">(current)</span>
          <span *ngIf="prepareCountUnreadMessages(countUnreadMessages)" class="unreadMessage-badge">
            {{ prepareCountUnreadMessages(countUnreadMessages) }}
          </span>
        </a>
      </li>

      <li *ngIf="auth.isAdmin()" [routerLinkActive]="['active']">
        <a class="nav-link" routerLink="/admin/conversation/search">
          {{ 'NAVBAR.CONVERSATIONS_LINK' | translate }}
        </a>
      </li>

      <li *ngIf="auth.isAdmin() || auth.isTeamLead() || auth.isContentAdmin()" [routerLinkActive]="['active']">
        <a class="nav-link" (click)="openManageCannedResponses($event)">
          {{ 'NAVBAR.MANAGE_CONTENT_LINK' | translate }}
        </a>
      </li>

      <li *ngIf="auth.isAdmin()" [routerLinkActive]="['active']">
        <a class="nav-link" routerLink="/admin/reports/conversation">
          {{ 'NAVBAR.REPORTS_LINK' | translate }}
        </a>
      </li>

      <li *ngIf="auth.isTeamLead()" [routerLinkActive]="['active']">
        <a class="nav-link" routerLink="/team-lead/review/user">
          {{ 'NAVBAR.MY_TEAMS_LINK' | translate }}
        </a>
      </li>

      <li *ngIf="auth.isAdmin()" [routerLinkActive]="['active']">
        <a class="nav-link" routerLink="/admin/manage/user">
          {{ 'NAVBAR.TEAM_ADMIN_LINK' | translate }}
        </a>
      </li>

      <li *ngIf="auth.isAdmin()" [routerLinkActive]="['active']">
        <a class="nav-link" routerLink="/admin/gamification/settings">
          {{ 'NAVBAR.GAMIFICATION_LINK' | translate }}
        </a>
      </li>
    </ul>

    <!-- Profile -->
    <ul class="navbar-nav ms-auto d-flex align-items-center" *ngIf="isLoggedIn">
      <li class="profile-container dropdown" [routerLinkActive]="['active']" ngbDropdown placement="bottom">
        <button
          class="h-100 d-flex justify-content-center align-items-center"
          type="button"
          id="dropdownPoints"
          ngbDropdownToggle
        >
          <!-- User Name -->
          <span class="user-name ps-2 pe-2"> {{ auth.getDisplayName() }} </span>

          <!-- User Avatar -->
          <div *ngIf="auth.userProfile?.userData?.avatarUrl || auth.userProfile?.avatarUrl">
            <app-user-template
              class="icon-40"
              [user]="auth.userProfile"
              [displayUserName]="false"
              tooltipField="userData.status"
            >
            </app-user-template>
          </div>
        </button>

        <!-- Profile dropdown menu -->
        <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="dropdownProfile">
          <div class="d-flex align-items-center justify-content-between m-2">
            <!-- Role name -->
            <span class="role-name"> {{ auth.getDisplayRole() }} </span>

            <!-- Reward level icon-->
            <div class="ms-2 level-icon" *ngIf="auth.rewards?.level?.icon" [matTooltip]="auth.rewards?.level?.name">
              <app-s3-image [fitContain]="true" [image]="auth.rewards?.level?.icon"></app-s3-image>
            </div>
          </div>

          <div role="separator" class="dropdown-divider"></div>

          <a class="dropdown-item" routerLink="/profile">
            <mat-icon>person</mat-icon>
            {{ 'NAVBAR.PROFILE_LINK' | translate }}
          </a>

          <a class="dropdown-item" routerLink="/admin/settings" *ngIf="auth.isAdmin()">
            <mat-icon>settings</mat-icon>
            {{ 'NAVBAR.SETTINGS_LINK' | translate }}
          </a>

          <button type="button" class="dropdown-item" (click)="signOut()">
            <i class="fa fa-sign-out" aria-hidden="true"></i>
            {{ 'NAVBAR.LOGOUT_LINK' | translate }}
          </button>
        </div>
      </li>
    </ul>

    <!-- Languages -->
    <button type="button" mat-icon-button mat-button class="languages-btn" [matMenuTriggerFor]="languagesMenu">
      <mat-icon>language</mat-icon>
    </button>
    <mat-menu #languagesMenu="matMenu">
      <button
        *ngFor="let lang of translate.getLangs()"
        mat-menu-item
        [class.selected]="lang === translate.currentLang"
        (click)="translate.use(lang)"
      >
        {{ lang }}
      </button>
    </mat-menu>
  </div>
</nav>
