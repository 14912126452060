import gql from 'graphql-tag';

export default gql`
  mutation updateConversationNote($input: ConversationNoteUpdateInput!) {
    updateConversationNote(input: $input) {
      __typename
      id
      conversationId
      createdBy
      content
      author {
        cognitoId
        username
        userData
      }
    }
  }
`;
