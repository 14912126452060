<app-number-of-conversations-widget
  [setStatistics]="conversationsStatistics"
  [selectedDateFilterValue]="selectedConvDateFilterValue"
  (onDataFilterChanged)="onDataFilterChanged($event, statsTypeEnum.conversations)"
>
</app-number-of-conversations-widget>
<app-conversations-per-hour-widget [setStatistics]="conversationsPerHourStatistics">
</app-conversations-per-hour-widget>
<app-conversations-stars-widget
  [setStatistics]="starsStatistics"
  [selectedDateFilterValue]="selectedStarsDateFilterValue"
  (onDataFilterChanged)="onDataFilterChanged($event, statsTypeEnum.stars)"
>
</app-conversations-stars-widget>
