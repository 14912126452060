/**
 * Routes are defined by this way, because it's impossible to use any kind of URL
 * routes to route internal chat. We need this chat to be accessible from all places of application,
 * so we cant bind chat routing to URL
 */

const routes = {
  USERS_LIST: 'USERS_LIST',
  CHATS_LIST: 'CHATS_LIST',
  CREATE_CHAT: 'CREATE_CHAT',
  CHAT: 'CHAT'
};

export default routes;
