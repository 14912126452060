import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { fadeInOut, listFadeInOut } from '@cation/core/animations';
import { IChallengeItem } from '@cation/core/types/api';
import { AuthHelper } from '@cation/core/auth/auth-helper';
import * as moment from 'moment';
import { ChangeChallengeStatusModel } from '../models/challenge/change-challenge-status.model';
import { mapChallengeStatusToIcon } from '../constants/chalenge';

@Component({
  selector: 'app-challenges-table',
  templateUrl: './challenges-table.component.html',
  styleUrls: ['./challenges-table.component.scss'],
  animations: [fadeInOut, listFadeInOut]
})
export class ChallengesTableComponent implements OnInit, OnDestroy {
  tab = '';
  @Input() set selectedTab(value: string) {
    if (this.tab !== value) {
      this.tab = value;
      this.ctnContainer.nativeElement.scrollTop = 0;
    }
  }

  @Input() challenges: IChallengeItem[] = [];
  @Input() isLoadMore: boolean;
  @Input() isLoading: boolean;

  @Input() set scrollToTop(value) {
    if (value && this.ctnContainer) {
      this.ctnContainer.nativeElement.scrollTop = 0;
    }
  }

  @Output() onMakeWager = new EventEmitter<number>();
  @Output() onChangeChallengeStatus = new EventEmitter<ChangeChallengeStatusModel>();
  @Output() onLoadMore = new EventEmitter();

  Date = Date;

  mapChallengeStatusToIcon = mapChallengeStatusToIcon;

  @ViewChild('ctnContainer', { static: true }) ctnContainer: ElementRef;
  @ViewChild('loading', { static: true }) loading: ElementRef;

  private loadIntersectionObserver: IntersectionObserver;

  constructor(protected authHelper: AuthHelper) {}

  ngOnInit() {
    this.createLoadIntersection();
    this.initLoadIntersection();
  }

  ngOnDestroy() {
    this.disconnectLoadIntersection();
  }

  trackItem(index, item) {
    return item ? item.id : undefined;
  }

  getChallengeTypeTranslateContext({ startDate, finishDate, data }) {
    return {
      startDate: moment(startDate).format('DD MMM'),
      finishDate: moment(finishDate).format('DD MMM'),
      amount: (data || {}).amount
    };
  }

  makeWager($event: MouseEvent, index: number) {
    $event.preventDefault();
    $event.stopPropagation();

    this.onMakeWager.emit(index);
  }

  changeChallengeStatus($event: MouseEvent, selectedTab, id, type, status) {
    $event.preventDefault();
    $event.stopPropagation();

    this.onChangeChallengeStatus.emit({
      tab: selectedTab,
      id,
      type,
      status
    });
  }

  private createLoadIntersection() {
    const options = {
      root: this.ctnContainer.nativeElement,
      rootMargin: '0px',
      threshold: 1.0
    };

    const callback = entries => {
      entries.forEach(entry => {
        if (this.isIntersecting(entry) && this.isLoadMore && !this.isLoading) {
          this.onLoadMore.emit();
        }
      });
    };

    this.loadIntersectionObserver = new IntersectionObserver(callback, options);
  }

  private initLoadIntersection() {
    this.loadIntersectionObserver.observe(this.loading.nativeElement);
  }

  private disconnectLoadIntersection() {
    if (this.loadIntersectionObserver) {
      this.loadIntersectionObserver.disconnect();
    }
  }

  private isIntersecting(entry: IntersectionObserverEntry): boolean {
    return entry.isIntersecting || entry.intersectionRatio > 0;
  }
}
