<section>
  <app-widget-header
    [title]="statsTypeEnum.stars | translate"
    [totalValue]="totalValue"
    [isDateTypeFilter]="true"
    [selectedDateFilterValue]="selectedDateFilterValue"
    [dateFilterOptions]="dateFilterOptions"
    (onDateFilterChange)="onDateFilterChange($event)"
  >
  </app-widget-header>

  <div class="ctn-content">
    <div *ngIf="!chartMultiResult" class="loader">{{ 'GENERAL.LOADING' | translate }}...</div>
    <div *ngIf="chartMultiResult && !chartMultiResult.length" class="no-statistics">
      {{ 'WIDGET.NO_STATISTICS' | translate }}
    </div>

    <div class="ctn-widget-chart" [ngClass]="'bar' + chartMultiResult?.length">
      <ngx-charts-bar-vertical-stacked
        [scheme]="colorScheme"
        [results]="chartMultiResult"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="true"
        [gradient]="true"
      >
      </ngx-charts-bar-vertical-stacked>
    </div>
  </div>
</section>
