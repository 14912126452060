import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@cation/core/services/api/api.service';
import { LogService } from '@cation/core/services/log/log.service';
import IAchievement from '@cation/core/types/achievement';

@Component({
  selector: 'app-ctn-add-edit-achievement',
  templateUrl: './add-edit-achievement.component.html',
  styleUrls: ['./add-edit-achievement.component.scss']
})
export class AddEditAchievementComponent {
  public achievement: Partial<IAchievement> = { worth: 0 };
  public errorMessage;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAchievement,
    private dialogRef: MatDialogRef<AddEditAchievementComponent>,
    private apiService: ApiService,
    private logService: LogService
  ) {
    if (data && data.id) {
      this.achievement = data || {};
    }
  }

  async saveAchievement() {
    try {
      const data = await this.apiService.saveAchievement(this.achievement);
      this.logService.log('[AddEditAchievementComponent saveAchievement:data]', data);
      this.dialogRef.close(this.achievement);
    } catch (e) {
      this.logService.error('[AddEditAchievementComponent saveAchievement:error]', e);
      this.errorMessage = e.message;
    }
  }

  async onAchievementIconSelect(icon) {
    this.logService.log('[AddEditTeamDialogComponent onAchievementIconSelect:icon]', icon);
    this.achievement.icon = icon;
  }
}
